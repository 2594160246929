import app from 'new-ui/app';
import { ACTION_TYPES, USER_INTENT_TYPES, QUESTIONS } from 'new-ui/constants';

import { useTranslation } from 'utils/modifiedTranslation';

const EBOOK_URL = 'https://www.trialjectory.com/wp-content/uploads/2020/01/5_steps_finding_treatment.pdf';

const consultWithUs = () => (!app.coniguration?.data?.lastConsultWithUs ? ACTION_TYPES.CONSULT_WITH_US : null);

const shareWithDoctor = () => (!app.coniguration?.data?.lastShareWithDoctor ? ACTION_TYPES.SHARE_WITH_DOCTOR : null);

const nextAppointment = () => (!app.coniguration?.data?.lastNextAppointment ? ACTION_TYPES.NEXT_APPOINTMENT : null);

const editQuestion = (q) => {
  app.editQuestion(q, async () => {
    app.setPopup(false);
    app.showPreloader();
    await app.update({ rematch: true });
    app.renderPage();
    app.hidePreloader();
  });
};

export const removeUserIntentData = () => {
  delete app.coniguration.data.lastConsultWithUs;
  delete app.coniguration.data.lastShareWithDoctor;
  delete app.coniguration.data.lastNextAppointment;
  delete app.coniguration.data.lastEbook;
};

const addTreatments = (t) => {
  if (app?.user?.missing_critical_info?.treatmentHistory) {
    return {
      type: ACTION_TYPES.MISSING_DATA,
      data: {
        title: t('action.missing_treatments'),
        subtitle: t('action.missing_treatments_subtitle'),
        buttonText: t('action.missing_treatments_button'),
        action: () => {
          editQuestion(QUESTIONS.TREATMENTS);
        },
      },
    };
  }
  return null;
};

const addBiomarkers = (t) => {
  if (app?.user?.missing_critical_info?.biomarkers) {
    return {
      type: ACTION_TYPES.MISSING_DATA,
      data: {
        title: t('action.missing_biomarkers'),
        subtitle: t('action.missing_biomarkers_subtitle'),
        buttonText: t('action.missing_biomarkers_button'),
        action: () => {
          editQuestion(QUESTIONS.BIOMARKERS);
        },
      },
    };
  }
  return null;
};

const missingData = (t) => {
  let action;
  action = addTreatments(t);
  if (action) return action;

  action = addBiomarkers(t);
  if (action) return action;

  return action;
};

const eBook = () => ({
  type: ACTION_TYPES.EBOOK,
  data: {
    action: () => {
      window.open(EBOOK_URL, '_blank');
      app.setConfiguration({
        lastEbook: new Date().toISOString(),
      });
      app.renderPage();
    },
  },
});

const defaultFlow = () => {
  const { trials } = app;
  const sponsored = trials?.filter((trial) => trial.isSponsored);
  if (sponsored?.length) return ACTION_TYPES.CONSULT_WITH_US;
  return ACTION_TYPES.DOCTOR_FLOW;
};

const eBookFlow = () => (!app.coniguration?.data?.lastEbook ? eBook() : defaultFlow());

export default () => () => {
  const { trials } = app;
  const sponsored = trials?.filter((trial) => trial.isSponsored);
  const { t } = useTranslation();

  let action;

  // delete app?.coniguration?.data?.userIntent;

  const userIntentType = app?.coniguration?.data?.userIntent?.type;

  if (!userIntentType) {
    return ACTION_TYPES.USER_INTENT;
  }

  if (userIntentType === USER_INTENT_TYPES.TREATMENT) {
    if (sponsored?.length) {
      action = consultWithUs(t);
      if (action) return action;
    } else {
      action = shareWithDoctor(t);
      if (action) return action;
    }
    action = missingData(t);
    if (action) return action;

    action = nextAppointment();
    if (action) return action;

    return eBookFlow();
  }

  if (userIntentType === USER_INTENT_TYPES.RESEARCH) {
    action = nextAppointment();
    if (action) return action;

    action = missingData(t);
    if (action) return action;

    return eBookFlow();
  }

  if (userIntentType === USER_INTENT_TYPES.EDUCATE) {
    if (!app.coniguration?.data?.lastEbook) action = eBook();
    if (action) return action;

    action = missingData(t);
    if (action) return action;

    return defaultFlow();
  }

  if (userIntentType === USER_INTENT_TYPES.OTHER) {
    action = missingData(t);
    if (action) return action;

    return eBookFlow();
  }

  return null;
};
