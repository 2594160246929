import React from 'react';
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
import _, { isArray } from 'lodash';
import {
  PUBLIC_ROUTES, LOCAL_STORAGE_KEYS, MOBILE_WIDTH, ITEM_TYPES,
  CALL_SUPPORTED_INDICATIONS, SORT_TYPES,
  URI,
  PAGES,
  // POPUP_TYPES,
} from 'new-ui/constants';
import setJoinedAt from 'functions/setJoinedAt';
import { authenticationService } from 'components/Authentication/api';

import xIcon from 'new-ui/assets/icons/overlay-x.svg';
import { conditionsChecker, indicationChooser } from 'components/Questionnaire/functions';
import { render } from 'react-dom';
import Lottie from 'lottie-react';
import app from './app';
import NumberTypes from './Components/QuestionRenderer/renderers/NumberInput/numberTypes';
import { conditionProfileFromIndication } from './functions/conditionProfileFromCondition';
import { CancerFieldsByIndication, subTypeQuestionByType, treatmentForIndicationQuestionIds } from './api/data/cancerTypes';
import { addPatient } from './Search';
import { allCancerTypes } from "../utils/constants/cancerTypes";

export const isMobileWidth = () => document.body.clientWidth <= MOBILE_WIDTH;

/* eslint-disable no-continue */
/**
 *
 * @param {*} trials
 * @description filter trials into type, distance, phase groups
 */
export const getTrialsGroups = (trials) => {
  const types = {};
  const typesParticipants = {};
  const distances = {
    50: [],
    100: [],
    200: [],
    none: [],
  };
  const phases = {
    1: [],
    2: [],
    3: [],
    4: [],
  };
  trials.forEach((trial) => {
    const trialCopy = { ...trial };
    trial.groups.forEach((group) => {
      if (!types[group]) {
        types[group] = [];
        typesParticipants[group] = 0;
      }
      types[group].push(trialCopy);
      typesParticipants[group] += trial.enrollment;
    });

    if (trial.nearest_facility_distance) {
      if (trial.nearest_facility_distance <= 50) distances[50].push(trialCopy);
      if (trial.nearest_facility_distance <= 100) distances[100].push(trialCopy);
      if (trial.nearest_facility_distance <= 200) distances[200].push(trialCopy);
      distances.none.push(trialCopy);
    }

    trial._phases.forEach((phase) => {
      if (phase && phases[phase]) phases[phase].push(trialCopy);
    });
  });
  return {
    type: types,
    distance: distances,
    phase: phases,
    typesParticipants,
  };
};

export const classExtractor = (html, base) => {
  const d = document.createElement('div');
  d.innerHTML = html;
  const classes = [];
  const iterate = (de, fn) => {
    fn(de);
    const children = Array.from(de.children);
    children.forEach((c) => {
      iterate(c, fn);
    });
  };
  iterate(d, (el) => {
    const classname = el.getAttribute('classname') || el.className;
    classname.split(' ').forEach((cl) => {
      if (!classes.includes(cl)) classes.push(cl);
    });
  });
  let css = '';
  classes.forEach((cl) => {
    if (!cl) return;
    css += `${base ? `.${base} ` : ''}.${cl}{}\n\n`;
  });
  return css;
};

function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export const getSearchObject = () => {
  const pairs = window.location.search.substring(1).split('&');
  const obj = {};
  let pair;
  let i;

  for (i in pairs) {
    if (pairs[i] === '') continue;

    pair = pairs[i].split('=');
    obj[decodeURIComponent(pair[0])] = pair[1].indexOf('__') === -1 ? [decodeURIComponent(pair[1])] : pair[1].split('__').map((c) => decodeURIComponent(c));
  }

  return !isEmpty(obj) ? obj : false;
};

export const hasFilters = () => (!!getSearchObject());

export const getSearchString = (o = {}) => {
  let s = '';
  let i = 0;
  for (const x in o) {
    if (o[x]) {
      if (Array.isArray(o[x]) && !o[x].length) continue;
      s += (i === 0 ? '?' : '&');
      s += `${x}=${Array.isArray(o[x]) ? o[x].join('__') : o[x]}`;
      i += 1;
    }
  }
  return s;
};

export const getFilteredResults = (
  trials = [],
  search = getSearchObject() || {},
  selectMode = false,
) => trials.map((a) => ({ ...a })).filter((trial) => {
  if (!trial.isNew && search.new) return false;

  if (search.type) {
    let typeFlag = false;
    search.type.forEach((t) => {
      if (trial.groups.indexOf(t) !== -1) typeFlag = true;
    });
    if (selectMode && typeFlag) return true;
    if (!typeFlag) return false;
  }

  if (search.phase) {
    let phaseFlag = false;
    search.phase.forEach((t) => {
      if (trial._phases.indexOf(Number(t)) !== -1) phaseFlag = true;
    });
    if (selectMode && phaseFlag) return true;
    if (!phaseFlag) return false;
  }

  if (search.distance && search.distance[0] !== 'none') {
    let distanceFlag = false;
    if (
      Number(search.distance[0]) >= trial.nearest_facility_distance
    ) distanceFlag = true;
    if (selectMode && distanceFlag) return true;
    if (!distanceFlag) return false;
  }

  return true;
}).sort((a, b) => {
  if (search?.sort?.[0] === SORT_TYPES.RECENT) {
    return +(!!b.isNew) - +(!!a.isNew);
  }
  return 0;
});

export const objectSome = (object = {}, some = () => {}) => {
  let flag = false;
  Object.keys(object).forEach((k) => {
    if (some(object[k])) flag = true;
  });
  return flag;
};

export const string2obj = (jsonString = '') => {
  let o;
  try {
    o = JSON.parse(jsonString);
  } catch (e) {
    return null;
  }
  return o;
};

export const getItemType = (item = {}) => (item?.trialType === 'drug' ? ITEM_TYPES.TREATMENTS : ITEM_TYPES.TRIALS);

export const isPublicRoute = () => {
  // const isPublic = PUBLIC_ROUTES.indexOf(window.location.pathname) !== -1;
  let isPublic = false;
  for (const x in PUBLIC_ROUTES) if (window.location.pathname.startsWith(PUBLIC_ROUTES[x])) isPublic = true;
  return isPublic;
};

export const isSuperUser = () => localStorage.getItem(LOCAL_STORAGE_KEYS.SUPER_USER) === 'true';

// const deg2rad = (deg) => deg * (Math.PI / 180);

// const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
//   const R = 6371; // Radius of the earth in km
//   const dLat = deg2rad(lat2 - lat1); // deg2rad below
//   const dLon = deg2rad(lon2 - lon1);
//   const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
//     + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2))
//     * Math.sin(dLon / 2) * Math.sin(dLon / 2);
//   const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
//   const d = R * c; // Distance in km
//   return d;
// };

export const capitalizeFirstLetter = (o)=>{
  if (typeof o === 'string') return o.charAt(0).toUpperCase() + o.slice(1);
  return o;
};

export const getDrugName = (code)=>{
  if (!app?.drugs?.length) return code;
  const drug = app.drugs.find((a)=>a.code === code);
  return drug ? drug.name : code;
};

export const setProviderTheme = () => {
  document.body.classList.add('provider-mode');
};

export const objectIterator = (obj, cb) => {
  for (const key in obj) {
    obj[key] = cb(obj[key]);
    if (obj[key] !== null && typeof obj[key] === 'object') {
      objectIterator(obj[key], cb);
    }
  }
};

export const isChildOf = (child, parent) => {
  let c = child;
  while (c.parentElement) {
    if (c.parentElement === parent) return true;
    c = c.parentElement;
  }
};

window.__isChild = isChildOf;

export const DOM = (name, props = {}, root) => {
  const tag = name.match(/^[a-zA-Z0-9]+/);
  const o = props;
  const el = document.createElement(tag ? tag[0] : 'div');
  let attr;
  name.split(/(?=\.)|(?=#)|(?=\[)/).forEach((c) => {
    c[0] === '#' && (el.id = c.substr(1));
    c[0] === '.' && (el.classList.add(c.substr(1)));
    c[0] === '[' && (attr = c.substr(1, c.length - 2).split('='), el.setAttribute(attr[0], attr[1]));
  });
  root && (el.root = root, root[name] = el);
  for (const p in o.style) el.style[p] = o.style[p]; delete o.style;
  if (o.name && root) root[`$${o.name}`] = el;
  for (const x in o) x[0] === '/' ? el.appendChild(DOM(x.substr(1), o[x], root || el)) : el[x] = o[x];
  return el;
};

window.DOM = DOM;

export const $ = (q) => {
  const a = Array.from(document.querySelectorAll(q));
  if (!a || !a.length) return null;
  if (a.length === 1) return a[0];
  return a;
};

export const createPopup = (settings = {}) => {
  let $popup = $('.generic-popup');
  if ($popup) $popup.remove();
  $popup = DOM('.generic-popup', {
    '/.generic-popup-header': {
      '/.generic-popup-header-close': {
        onclick: () => {
          if (settings.onbeforeclose) settings.onbeforeclose();
          $popup.remove();
        },
        '/img': {
          src: xIcon,
        },
      },
    },
    '/.generic-popup-body': {
      name: 'body',
    },
  });
  if (settings.className) {
    if (isArray(settings.className)) {
      settings.className.forEach((c)=>{
        $popup.classList.add(c);
      });
    } else $popup.classList.add(settings.className);
  }
  document.body.appendChild($popup);
  return $popup;
};

window._createPopup = createPopup;

export const getMissingCriticalDataKeys = (user = {}) => {
  if (!user?.missing_critical_info) return [];
  return Object.keys(user.missing_critical_info).reduce((a, b) => {
    if (user.missing_critical_info[b]) a.push(b);
    return a;
  }, []);
};

export const getMissingCriticalFromTrials = (trials = []) => trials.reduce((a, b) => {
  if (b.missed_questions && b.missed_questions.length) {
    b.missed_questions.forEach((mq) => {
      if (a.indexOf(mq) === -1) a.push(mq);
    });
  }
  return a;
}, []);

export const getMissingCriticalQuestions = (user = {}, trials = []) => {
  let missingCriticalQuestions = [];
  try {
    missingCriticalQuestions = _.uniq(getMissingCriticalDataKeys(user).concat(getMissingCriticalFromTrials(trials)));
  } catch (e) {
    console.error('error collecting missing critical', e);
  }
  return missingCriticalQuestions;
};

export const getTopGroups = (trials = [], num = 3) => Object.fromEntries(Object.entries(trials.reduce((a, b) => {
  b.groups.forEach((g) => {
    if (!a[g]) a[g] = 1;
    else a[g]++;
  });
  return a;
}, {})).sort((a, b) => b[1] - a[1]).slice(0, num));

export const nctNumberToNctId = (nctNumber) => (typeof nctNumber === 'string'
  ? nctNumber
    .trim()
    .split('-')[0]
    .trim()
    .toUpperCase()
  : null);

// eslint-disable-next-line max-len
export const randomString = () => Math.random().toString(36).substring(2, 10) + Math.random().toString(36).substring(2, 10);

export const nctToUrl = (nct) => nct.replace(/\//g, '_');
export const urlToNct = (url) => url.replace(/_/g, '/');

export const sendEmail = (recipient = '', subject = '', body = '') => {
  const mailtoUrl = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  window.location.href = mailtoUrl;
};

export const isMobile = () => {
  try {
    document.createEvent('TouchEvent');
    return true;
  } catch (e) {
    return false;
  }
};

export const isCrmChatAllowed = ()=>{
  if (!app.user) {
    return false;
  }
  const hasSponsored = (app.trials || []).filter((t) => t.isSponsored).length > 0;
  const condition = app.user.personal?.condition;
  const availableDiseases = app?.coniguration?.data?.crmChatDiseases || [];

  return availableDiseases.includes(condition) && hasSponsored;
};

export const isCallUsMode = (indication = app.user.personal.condition) => {
  // return true;
  // const a = 1;
  const hasSponsored = (app.trials || []).filter((t) => t.isSponsored).length > 0;
  return CALL_SUPPORTED_INDICATIONS[indication] && hasSponsored;
};

export const getIndicationDetailsTitle = (indication = app.user?.personal.condition, subDisease = app.user?.condition_profile?.type_of_ca) => {
  if (indication === allCancerTypes.GENERAL) {
    return window.t(`quest_selector.cancer_types.${indication}.${subDisease}`);
  }
  return window.t(`quest_selector.cancer_types.${indication}`);
};

export const getIndicationIntercomTitle = (indication = app.user?.personal.condition, subDisease = app.user?.condition_profile?.type_of_ca) => {
  if (indication === allCancerTypes.GENERAL) {
    return window.t(`quest_selector.cancer_types.${indication}.${subDisease}`);
  }
  return window.t(`intercom.condition.title.${indication}`);
};

export const copyToClipboard = async (text) => {
  try {
    await navigator.clipboard.writeText(text);
    return true;
  } catch (err) {
    return false;
  }
};

const setTokens = (tj_access_token, tj_refresh_token) => {
  localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, tj_access_token);
  localStorage.setItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, tj_refresh_token);
};

const setSession = (data) => {
  localStorage.setItem(LOCAL_STORAGE_KEYS.MASKED_EMAIL, data?.maskedEmail);
  localStorage.setItem(LOCAL_STORAGE_KEYS.MASKED_PHONE, data?.maskedPhone);
  localStorage.setItem(LOCAL_STORAGE_KEYS.ENC, JSON.stringify(data?.enc));
};

export const getLoggedUser = () => {
  let user;
  try {
    user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER));
  } catch (e) {}
  return user;
};

window.getLoggedUser = getLoggedUser;

export const setUser = async (data) => {
  setTokens(data.access_token, data.refresh_token);
  localStorage.setItem(LOCAL_STORAGE_KEYS.USER, data.user);
  const user = JSON.parse(data?.user);
  setSession(user);
  if (data?.personal) user.personal = data.personal;
  setJoinedAt();

  const responseData = {
    info: {
      user_wix_id: user.user_wix_id,
      email: user.email,
      family_name: user.family_name || null,
      phone: user.phone || null,
      user_type: user.user_type || null,
      country: user.country || null,
    },
    personal: {
      user_wix_id: user.user_wix_id,
    },
    condition_profile: {
      user_type: user?.user_type || null,
    },
  };

  const setUserData = (data) => ({ type: 'SET_USER_DATA', payload: data });

  const userAuthenticationSuccess = (user, justSignedUp) => ({
    type: 'SET_AUTHENTICATION_DATA',
    payload: {
      user,
      justSignedUp: justSignedUp || false,
    },
  });

  app.store.dispatch(setUserData(responseData));
  app.store.dispatch(userAuthenticationSuccess(user));

  return user;
};

export const hasGenderQuestion = ()=>{
  return app.questions.find((a)=>a.id === 'gender');
};

window.__hasGenderQuestion = hasGenderQuestion;

/**
 * signup
 * minimal requirement are: email, indication, recaptchToken
 * @param {*} data object
 */
export const signup = async (data) => {
  const {
    email,
    indication,
    recaptchaToken,
    password = `E${randomString()}!`,
    onsignup,
    onerror,
    info = {},
    condition_profile = {},
    personal = {},
    set_on_app,
  } = data;
  const tempData = {
    info: {
      ...info ?? {},
    },
    personal: {
      condition: indication,
      ...personal ?? {},
    },
    condition_profile,
  };

  // delete tempData.info.user_wix_id;
  // delete tempData.condition_profile.user_wix_id;

  const tempUser = await authenticationService.createTempUser(tempData);

  console.log('signup :: tempuser', tempUser);

  tempUser.personal.reached_required_question = true;
  tempUser.personal.is_done = 'Y';
  tempUser.personal.is_internal = 0;

  // tempUser.condition_profile.qstack = [];

  const d = {
    personal: {
      ...tempUser.personal,
    },
    condition_profile: tempUser.condition_profile,
    info: {
      user_wix_id: null,
      email,
      password,
      country: null,
      family_name: null,
      phone: '',
      partner: null,
      user_type: null,
      sign_up_source: window.location.pathname.includes('ai_profile') ? 'file_upload' : 'manual',
      ...info,
    },
    profileDetails: {},
    statistic: {
      cameFrom: window.location.href,
      urlParameters: window.location.pathname,
    },
    recaptchaToken,
  };

  let result;

  try {
    result = await authenticationService.signup(d);
    console.log('signup :: result', result);

    if (!result.data.access_token || !result.data.refresh_token || !result.data.user) {
      console.error('Wrong response from the profile server');
      return result;
    }

    await setUser({
      ...result.data,
      personal: tempUser.personal,
    });

    const user = await app.getUser();

    if (set_on_app) app.user = user;

    if (onsignup) onsignup(user);

    return result.success ? user : false;
  } catch (e) {
    if (onerror) onerror(e);
  }
};

/**
 * login
 * minimal requirement are: email, password
 * @param {*} data object
 */
export const login = async (data) => {
  const { email, password, recaptchToken } = data;

  const result = await authenticationService.login({
    info: {
      email,
      password,
    },
    recaptchToken,
    personal: {
      profile_id: null,
    },
  });

  await setUser({
    ...result.data,
  });

  const user = await app.getUser();

  return result.success ? user : false;
};

export const getBiomarkerTitle = (biomarker, all_biomarkers = app.biomarkers || [], t = window.t) => {
  const f = all_biomarkers.filter((a) => (a.code === biomarker));
  return (f && f.length) ? t(f[0].name) : biomarker;
};

export const filterQuestionsByCondition = async ({ questionsIds = [], user = {} }) => {
  const { condition_profile = {}, personal = {} } = user;
  const { condition } = personal;
  let filteredQuestions = questionsIds;
  const allQuestions = await indicationChooser(condition);
  filteredQuestions = filteredQuestions.filter((question) => {
    const questionCondition = allQuestions.find((q) => q.id === question)?.conditions;
    const shouldbeShown = conditionsChecker(
      condition_profile,
      questionCondition,
    );
    return !!shouldbeShown;
  });
  return filteredQuestions;
};

window.getBiomarkerTitle = getBiomarkerTitle;

export const getInitialConditionProfileByIndication = async (indication, condition_profile = {}) => {
  if (Array.isArray(condition_profile?.biomarkers)) {
    condition_profile.biomarkers = condition_profile.biomarkers.filter((a) => a !== 'none');
  }

  const conditionProfile = await conditionProfileFromIndication(indication);
  for (const attr in condition_profile) {
    if (attr !== 'qstack') conditionProfile[attr] = condition_profile[attr];
  }
  if (condition_profile.qstack) {
    condition_profile.qstack.forEach((q) => {
      if (conditionProfile.qstack.indexOf(q === -1)) conditionProfile.qstack.push(q);
    });
  }
  return conditionProfile;
};

export const createEmptyUser = async (indication, _condition_profile = {}, _info = {}, _personal = {})=>{
  const conditionProfile = await getInitialConditionProfileByIndication(indication);
  conditionProfile.qstack.forEach((prop)=>{
    if (conditionProfile[prop] === undefined) conditionProfile[prop] = null;
  });

  const condition_profile = {
    ...conditionProfile,
    ..._condition_profile,
  };

  const info = {
    condition: indication,
    country: null,
    email: null,
    family_name: null,
    partner: null,
    phone: null,
    user_type: null,
    user_wix_id: null,
    ..._info,
  };

  const personal = {
    patient_id: null,
    user_wix_id: null,
    profile_id: null,
    condition: indication,
    dob: null,
    gender: null,
    ..._personal,
  };

  const o = {
    condition_profile,
    info,
    personal,
  };

  return o;
};

export const getAboutCounter = (user = app.user)=>{
  let c = 0;
  let total = 4;
  // console.log('getAboutCounter');
  if (user?.info.user_type) c++;
  if (user?.personal.dob) c++;
  if (hasGenderQuestion() && user?.personal.gender) c++;
  if (user?.condition_profile.race?.length) c++;

  if (!hasGenderQuestion()) total -= 1;

  return [c, total];
};

window.__getAboutCounter = getAboutCounter;

export function isCurrentTimeInESTWorkingRange() {
  // Tmp remove time limitation
  return true;
  // const now = new Date();
  // const utc = now.getTime() + now.getTimezoneOffset() * 60000; // Convert to UTC
  // const estOffset = -5 * 60; // Eastern Standard Time (New York) offset in minutes
  // const estTime = new Date(utc + 60000 * estOffset); // Convert to EST

  // const startHour = 8;
  // const endHour = 17;
  // const currentHour = estTime.getHours();
  // const currentMinutes = estTime.getMinutes();
  // const dayOfWeek = estTime.getDay();

  // return (
  //   (currentHour > startHour || (currentHour === startHour && currentMinutes >= 30))
  //   && (currentHour < endHour || (currentHour === endHour && currentMinutes <= 30))
  //   && (dayOfWeek !== 0 && dayOfWeek !== 6)
  // );
}

window.__getInitialConditionProfileByIndication = getInitialConditionProfileByIndication;

window._username = ()=>{
  const a = new Date();
  return `${a.toISOString().replaceAll('-', '').replaceAll(':', '').replaceAll('.', '')}@leal.health`;
};

/**
 * Find nested value in object
 *
 * @param {Object} anObject An object with nested objects
 * @param {String} path Keys separated by . to get nested value in object
 *
 * @example <caption>Example usage</caption>
 *  const anObject = { a: 1, b: {c: 2, d: {e: 3}}};
 *  const path = 'b.d.e''
 *  deepFind(anObject, path) // 3
 */
export const deepFind = (anObject, path = '') => {
  let res;
  const pathArr = path.split('.');
  pathArr.forEach((somekey) => {
    res = res ? res[somekey] : anObject && anObject[somekey];
  });

  return res;
};

export const getAge = (dob) => {
  const date_of_birth = new Date(dob);
  const diff_ms = Date.now() - date_of_birth.getTime();
  const age_dt = new Date(diff_ms);

  return Math.abs(age_dt.getUTCFullYear() - 1970);
};

/**
 * callOnceEvery
 * @description callback function can be called only once every ms, calling this function more than once during that period will be ignored
 * for instance if you want a certain popup to be called only once every 7 days (()=>{}, 'key_name', 6.048e+8)
 * @param {*} callback function to call
 * @param {*} key special key (string no spaces)
 * @param {*} ms milliseconds time
 */
export const callOnceEvery = (callback, key = '', ms = 0)=>{
  const storageKey = `call_every_${key}`;
  const time = localStorage.getItem(storageKey);
  const callAndCreate = ()=>{
    callback();
    const nextCallTime = new Date(new Date().getTime() + ms);
    localStorage.setItem(storageKey, nextCallTime.toISOString());
  };
  if (time) {
    const dueDate = new Date(time);
    const now = new Date();
    if (now <= dueDate) return;
  }
  callAndCreate();
};

export const debounce = (func, timeout = 300)=>{
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
};

export const getOverlay = ()=>{
  const $overlay = DOM('.generic-overlay', {
    '/.generic-overlay-content': {
      name: 'content',
    },
  });
  $overlay.add = ()=>{
    document.body.appendChild($overlay);
  };
  $overlay.show = ()=>{
    $overlay.classList.add('generic-overlay-visible');
  };
  $overlay.hide = ()=>{
    $overlay.classList.remove('generic-overlay-visible');
  };
  $overlay.append = ($dom)=>{
    $overlay.$content.appendChild($dom);
  };
  $overlay.remove = ()=>{
    if ($overlay.parentElement) $overlay.parentElement.removeChild($overlay);
  };
  return $overlay;
};

export const getLottie = (LottieAnimation)=>{
  const $lottie = DOM('.lottie');
  render(
    <Lottie animationData={LottieAnimation} loop />,
    $lottie,
  );
  return $lottie;
};

window.__getLottie = getLottie;

export const waitFor = (tester, onsuccess, ontimeout = ()=>{}, maxTimeout = 10000, ms = 50)=>{
  if (tester()) return onsuccess();
  let timer;
  // eslint-disable-next-line prefer-const
  timer = setInterval(()=>{
    if (tester()) {
      clearInterval(timer);
      onsuccess();
    }
  }, ms);
  setTimeout(()=>{
    clearInterval(timer);
    ontimeout();
  }, maxTimeout);
};

window.waitFor = waitFor;

const getAllSystemQuestions = async (id)=>{
  const qs = {};
  const a = await app.getIndications();
  const b = a.map((v)=>v.value);
  for (let i = 0; i < b.length; i++) {
    const indication = b[i];
    // eslint-disable-next-line
    qs[indication] = await app.getQuestions(indication, true);
    if (id) qs[indication] = qs[indication].find((a)=>a.id === id);
  }
  return qs;
};

window.__getAllSystemQuestions = getAllSystemQuestions;

export const questionsNormalizer = (questionsArray = [], condition)=>{
  const fieldsByIndication = CancerFieldsByIndication;
  let qs = questionsArray.map((q)=>{
    if (q === 'type') return fieldsByIndication[condition]?.diseaseType;
    if (q === 'disease_stage') return fieldsByIndication[condition]?.patientStage;
    if (q === 'received_treatment') return treatmentForIndicationQuestionIds[condition];
    return q;
  }).filter((a)=>a);
  if (questionsArray.includes('subtype') && subTypeQuestionByType[condition]) qs = qs.concat(subTypeQuestionByType[condition]);
  return qs;
};

export const isEmptyObject = (o)=>{
  if (o === undefined || o === null || JSON.stringify(o) === '{}') return true;
  return false;
};

export const getNumberType = (type) => {
  switch (type) {
    case NumberTypes.int:
      return {
        step: 1,
        unit: 'number_input.number_type.mm',
      };
    case NumberTypes.intWithoutUnit:
      return {
        step: 1,
        unit: '',
      };
    case NumberTypes.fraction:
      return {
        step: 0.1,
        unit: 'number_input.number_type.cm',
      };
    case NumberTypes.fractionWithoutUnit:
      return {
        step: 0.1,
        unit: '',
      };
    case NumberTypes.uML:
      return {
        step: 0.1,
        unit: 'U/ml',
      };
    case NumberTypes.gDL:
      return {
        step: 1,
        unit: 'g/dL',
      };
    case NumberTypes.cellUl:
      return {
        step: 1,
        unit: 'cells/uL',
      };
    case NumberTypes.uLCube:
      return {
        step: 1,
        unit: '10³/uL',
      };
    case NumberTypes.percentage:
      return {
        step: 1,
        unit: '%',
      };
    default:
      // default should be fraction
      return {
        step: 0.1,
        unit: 'number_input.number_type.cm',
      };
  }
};

export default {
  getTrialsGroups,
  getSearchObject,
  getSearchString,
  getFilteredResults,
  objectSome,
};

export const gotoLogin = ()=>{
  window.location = URI[PAGES.LOGIN];
};

export const addUserPopup = ({
  onSuccess = (user)=>{
    console.log('add user success', user);
  },
  title,
  subtitle,
} = {})=>{
  addPatient({
    caregiver: null,
    defaultEmail: '',
    emailRequired: true,
    onBeforeSignup: async (user)=>{
      app.setShowPreloader(true);
    },
    onSignup: async (user, setError)=>{
      app.setShowPreloader(false);
      onSuccess(user);
      app.setPopup(false);
    },
    indicationTitle: window.t('add_user.indication_input_title'),
    nameTitle: window.t('add_user.name_input_title'),
    emailTitle: window.t('add_user.email_input_title'),
    buttonTitle: window.t('add_user.button_title'),
    title: title || window.t('add_user.title'),
    subtitle,
    doLogin: true,
  });
};
