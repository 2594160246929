import React from 'react';
import PropTypes from 'prop-types';
import Preloader from 'new-ui/Components/Preloader';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: 400,
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  overlapping: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    minHeight: 0,
    backgroundColor: 'rgba(239, 239, 239, 0.5)',
    zIndex: 2
  },
  inner: {
    position: 'absolute',
    top: '50%',
  },
}));

const Spinner = ({ error, message, overlapping }) => {
  const classes = useStyles();

  if (error) {
    return `Opps... an error ${error}`;
  }

  return (
    <div className={`${classes.root} ${overlapping ? classes.overlapping : ''}`}>
      <div className={classes.inner}>
        <Preloader isVisible />
      </div>
      <p>{message}</p>
    </div>
  );
};

Spinner.propTypes = {
  error: PropTypes.object,
  message: PropTypes.string,
  overlapping: PropTypes.bool
};

Spinner.defaultProps = {
  error: null,
  message: null,
  overlapping: false
};

export default Spinner;
