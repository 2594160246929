/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import surveyPopupIcon from 'new-ui/assets/icons/survey_popup.svg';
import { useTranslation } from 'react-i18next';

import app from 'new-ui/app';
import { getDrugsTranslatedLabel } from 'new-ui/functions/getDrugsTranslatedLabel';
import { GA_CATEGORY_NAME } from '../GoogleAnalytics/inContextEvents';
import { profileTreatmentSectionEvents } from '../GoogleAnalytics/profileSectionEvents';

const {
  surveyStarted,
  surveyExit,
  surveyIntroPageDisplayed,
} = profileTreatmentSectionEvents;

export const SurveyPopup = ({
  lastTreatment, conditionName, setDrawerContent, lastTreatmentDrug,
}) => {
  const { t } = useTranslation();
  const [patientsLikeYou, setPatientsLikeYou] = useState();

  useEffect(() => {
    app.sendGoogleAnalyticsEvent(
      GA_CATEGORY_NAME,
      surveyIntroPageDisplayed.eventName,
      surveyIntroPageDisplayed.getMetaData(),
    );
  }, []);

  useEffect(() => {
    const { count, drugName } = localStorage.getItem('patients_like_you_count') || {};
    if (count && drugName === getDrugsTranslatedLabel(t, lastTreatment.drugs_received)) {
      setPatientsLikeYou(count);
    } else {
      const patientsCount = Math.floor(Math.random() * 500) + 100;
      setPatientsLikeYou(patientsCount);
      localStorage.setItem('patients_like_you_count', {
        count: patientsCount,
        drugName: getDrugsTranslatedLabel(t, lastTreatment.drugs_received),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastTreatment]);

  const handleNo = () => {
    app.sendGoogleAnalyticsEvent(
      GA_CATEGORY_NAME,
      surveyExit.eventName,
      surveyExit.getMetaData(),
    );
    setDrawerContent(null);
  };
  const handleYes = () => {
    app.sendGoogleAnalyticsEvent(
      GA_CATEGORY_NAME,
      surveyStarted.eventName,
      surveyStarted.getMetaData(),
    );
    // app.intercom.updateUserAttributes({
    //   last_treatment: lastTreatment.drugs_received.join(' + '),
    //   last_treatment_updating_date: new Date().toLocaleDateString(),
    //   last_treatment_status: 'survey populated',
    // });
    app.setPopup('treatment_survey', { lastTreatment: lastTreatmentDrug });
  };

  return (
    <div className="survey_popup_wrapper">
      <img src={surveyPopupIcon} alt="Survey Popup Icon" />
      <p className="survey_popup_body1">
        There are currently
        {' '}
        {patientsLikeYou}
        {' '}
        {conditionName}
        {' '}
        patients like you on the platform who are taking
        {' '}
        {getDrugsTranslatedLabel(t, lastTreatment.drugs_received)}
        {' '}
        as you or are considering it.
      </p>
      <div className="survey_popup_body2_wrapper">
        <p>Can you share your own experience regarding the intensity and side effects of this treatment?</p>
        <span>(3 questions which take 1 minute to answer)</span>
      </div>
      <div className="survey_popup_buttons_wrapper">
        <button className="survey_popup_no_btn" type="button" onClick={handleNo}>
          {t('No')}
        </button>
        <button className="survey_popup_yes_btn" type="button" onClick={handleYes}>
          {t('Yes, share my experience')}
        </button>
      </div>
    </div>
  );
};
