const AuthenticationType = {
  EMAIL: 'EMAIL',
  PHONE: 'PHONE',
  QUICK_SIGNUP: 'quick_signup',
  MAGIC_LINK: 'MAGIC_LINK',
  GOOGLE: 'GOOGLE',
  FACEBOOK: 'FACEBOOK',
  MODAL_SIGNUP: 'modal_signup',
};

export default AuthenticationType;
