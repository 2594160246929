import React, { useState, useRef } from "react";
import "./style.css";
import PatientSearch from "../PatientSearch";
// import ShareButton from "../ShareButton";
import ManageButton from "../ManageButton";
import ArchiveCard from "../ArchiveCard";
import ManageColleagueCard from "../ManageColleagueCard";
import Popover from '@material-ui/core/Popover';
import PopoverWrapper from "../PopoverWrapper";

export const PatientsHeader = ({ patient_count, onSearchPatient }) => {
  const [popoverContent, setPopoverContent] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const manageButtonRef = useRef(null);

  const handleClickOpen = (content) => {
    setAnchorEl(manageButtonRef.current);
    setPopoverContent(content);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const onManageButtonClick = () => {
    handleClickOpen(
      <ManageColleagueCard 
        onClose={handleClose} 
        onArchiveClick={onArchiveButtonClick} 
      />
    );
  };

  const onArchiveButtonClick = () => {
    handleClickOpen(<ArchiveCard onClose={handleClose} />);
  };

  return (
    <div className="patients-header">
      <div className="heading">
        <h2 className="patient-count">{patient_count} Patients</h2>
        <PatientSearch onChange={onSearchPatient} />
      </div>
      <div className="actions" style={{display: "none"}}>
        <ManageButton ref={manageButtonRef} aria-describedby={id} onClick={onManageButtonClick} />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          PaperProps={{
            style: {
              width: '578px',
              marginTop: '8px',
              borderRadius: '14px',
            },
          }}
        >
          <PopoverWrapper onClose={handleClose}>
            {popoverContent}
          </PopoverWrapper>
        </Popover>
      </div>
    </div>
  );
};

export default PatientsHeader;
