import geoConstants from '../constants/geo';

const setGeoLocation = geoObj => ({
  type: geoConstants.SET_USER_GEO_LOCATION,
  payload: geoObj
});

export const geoActions = {
  setGeoLocation
};
