/**
 * Find nested value in object
 *
 * @param {Object} anObject An object with nested objects
 * @param {String} path Keys separated by . to get nested value in object
 *
 * @example <caption>Example usage</caption>
 *  const anObject = { a: 1, b: {c: 2, d: {e: 3}}};
 *  const path = 'b.d.e''
 *  deepFind(anObject, path) // 3
 */
const deepFind = (anObject, path = '') => {
  let res;
  const pathArr = path.split('.');
  pathArr.forEach(somekey => {
    res = res ? res[somekey] : anObject && anObject[somekey];
  });

  return res;
};

export default deepFind;
