import { UPDATE_SETTINGS } from '../actions/appSettings';
import APP_SETTINGS from '../constants/appSettings';

const initialState = {
  ...APP_SETTINGS,
};

export function appSettings(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_SETTINGS:
      return {
        ...state,
        ...(payload && Object.keys(payload) ? payload : {}),
      };
    default:
      return state;
  }
}
