import React, { useState, useRef } from 'react';

import PropTypes from 'prop-types';
import { Tag } from 'new-ui/Components/Tags';
import { useTranslation } from 'utils/modifiedTranslation';
import { ReactComponent as ArrowDown } from 'new-ui/assets/icons/arrow-circle-black.svg';
import XIcon from 'new-ui/assets/icons/x.svg';
import CustomCheckbox from 'new-ui/Pages/Feed/Checkbox';
import CustomRadio from 'new-ui/Pages/Feed/Radio';

import './Select.css';
import { isArray } from 'lodash';
import addIcon from './icons/AddIcon.svg';

const isEmptyValue = (value)=>{
  if (!value) return true;
  if (value?.length === 0) return true;
  if (value?.length === 1 && value[0] === 'none') return true;
  return false;
};

const Select = (props) => {
  const {
    options = [],
    onChange,
    onClear,
    multi = false,
    nullValue,
    disabled = false,
    placeholder = '',
    autocomplete = false,
    autocompletePlaceholder = '',
    isTags = false,
    defaultOpen = false,
    withCheckbox = false,
    withRadio = false,
  } = props;

  let { value } = props;
  if (!value) value = null;
  if (value && typeof value === 'string') value = [value];

  // if(isTags) debugger;

  const { t } = useTranslation();
  const [showOptions, setShowOptions] = useState(defaultOpen);
  const $autocomplete = useRef();
  const $options = useRef();
  // eslint-disable-next-line no-unused-vars
  const [tags, setTags] = useState([]);
  const [showTags, setShowTags] = useState(!isEmptyValue(value));

  const hideOptions = (e) => {
    if (e?.target?.classList?.contains('select-autocomplete')) return;
    document.removeEventListener('click', hideOptions);
    setShowOptions(false);
  };

  const eachOptionElements = (cb) => {
    const $allOptions = $options.current.querySelectorAll('.select-option');
    $allOptions.forEach(cb);
  };

  const hideOptionElemets = () => {
    eachOptionElements(($option) => {
      if ($option.classList.contains('select-unfiltered')) return;
      $option.classList.add('option-hidden');
    });
  };

  const showOptionElemets = () => {
    eachOptionElements(($option) => {
      $option.classList.remove('option-hidden');
    });
  };

  const toggleOptions = () => {
    if (showOptions === false) document.addEventListener('click', hideOptions);
    setShowOptions(!showOptions);
    // console.log(, $autocomplete);
    if (autocomplete && !showOptions) {
      setTimeout(() => {
        $autocomplete.current.focus();
        $autocomplete.current.onkeyup = () => {
          const v = $autocomplete.current.value;
          if (!v) {
            showOptionElemets();
            return;
          }
          hideOptionElemets();
          eachOptionElements(($option) => {
            const text = $option.innerText.toLowerCase();
            if (text.includes(v.toLowerCase())) $option.classList.remove('option-hidden');
          });
        };
      }, 10);
    }
  };

  const getSelectedOptions = (v = value || []) => {
    if (!v?.length) return null;
    const selecteds = [];
    options.map((o) => {
      if (v.indexOf(o.value) !== -1) selecteds.push({ ...o });
      return o;
    });
    return selecteds;
  };

  const isNullSelected = (v = value || []) => {
    if (v?.length === 1 && v[0] === nullValue) return 1;
  };

  const getTitle = () => {
    const selecteds = getSelectedOptions(value);
    if (!(selecteds && selecteds.length)) return '';
    return multi
      ? selecteds.length === 1
        ? selecteds[0].title
        : selecteds.map((o) => o.title).join(', ')
      : selecteds[0]?.title;
  };

  const selectOption = (option) => {
    let newValue;
    if (multi) {
      newValue = [...value];
      if (!option.value) {
        return onChange([]);
      }
      if (newValue.indexOf(option.value) === -1) newValue.push(option.value);
      else newValue.splice(newValue.indexOf(option.value), 1);
      setTags(newValue);
    } else newValue = option.value;
    onChange(newValue);
    toggleTags(newValue);
    setShowOptions(false);
  };

  const toggleTags = (value)=>{
    const showTags = isTags && !isEmptyValue(value);
    console.log('onchange showtags', showTags);
    setShowTags(showTags);
  };

  const isOptionSelected = (option) => {
    if (isArray(value)) return !!value.find((a)=>a === option.value);
    return option.value === value;
  };

  const autocompleteClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const isSelected = () => getSelectedOptions()?.length && !isNullSelected();

  const clearSelection = () => {
    if (onClear) onClear();
    toggleTags([]);
    onChange([]);
  };

  const handleAddMoreTag = () => {
    setShowTags(false);
    toggleOptions();
  };

  return (
    <div className={`select ${disabled ? 'select-disabled' : ''} ${isSelected() ? 'select-value-value' : ''}`}>
      <div
        style={{ display: (isTags && showTags) ? 'none' : 'block' }}
        className={`select-box ${showOptions ? 'select-box-visible' : ''}`}
      >
        <div className="select-value" onClick={toggleOptions}>
          <div className={`select-value-title ${!getTitle() && placeholder ? 'select-placeholder' : ''}`} onClick={toggleOptions}>
            {getTitle() || placeholder || ''}
          </div>
          <div className="select-value-icon" onClick={isSelected() ? clearSelection : null}>
            {isSelected() ? <img alt="clear" src={XIcon} /> : <ArrowDown />}
          </div>
        </div>
        <div className="select-options">
          {autocomplete ? (
            <input
              type="text"
              className="select-autocomplete"
              ref={$autocomplete}
              onClick={autocompleteClick}
              placeholder={autocompletePlaceholder || t('select.start_typing')}
            />
          ) : null}
          <div className="select-options-items" ref={$options}>
            {options.map((option, id) => (
              <div
                key={id}
                className={`select-option ${isOptionSelected(option) ? 'select-option-selected' : ''} ${option.unfiltered ? 'select-unfiltered' : ''} ${option.className || ''} ${(withCheckbox || withRadio) ? 'select-with-checkbox' : ''}`}
                value={option.value}
                onClick={()=> {
                  selectOption(option);
                }}
              >
                {withCheckbox && (
                  <CustomCheckbox 
                    checked={isOptionSelected(option)} 
                    onChange={() => selectOption(option)}
                  />
                )}
                {withRadio && (
                  <CustomRadio 
                    name={'radio'} 
                    value={option.value.toString()} 
                    checked={isOptionSelected(option)} 
                    onChange={() => selectOption(option)} 
                  />
                )}
                {t(option.title) || t(option.label)}
              </div>
           
           ))}
          </div>
        </div>
      </div>
      <div
        style={{ display: (isTags && showTags) ? 'flex' : 'none' }}
        className="select-tags-box"
      >
        {/* <Tags items={getTagsItems()} /> */}
        {
          isArray(value) && value.map((item, k)=>{
            if (item === 'none') return null;
            return (
              <Tag
                title={options.find((a) => a.value === item)?.title}
                value={item.value}
                key={k}
                action={()=>{
                  const _v = [...value];
                  _v.splice(_v.indexOf(item), 1);
                  if (isTags) {
                    setShowTags(!isEmptyValue(_v));
                  }
                  onChange(_v);
                }}
              />
            );
          })
        }
        <div onClick={handleAddMoreTag} className="add-tag">
          <img src={addIcon} alt="add icon" />
          <span
            className="select-add-tag"
          >
            {t('search.search_biomarkers_tag_selector_button')}
          </span>
        </div>
      </div>
    </div>
  );
};

Select.propTypes = {
  value: PropTypes.any,
  nullValue: PropTypes.string,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  options: PropTypes.any,
  multi: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  autocomplete: PropTypes.bool,
  autocompletePlaceholder: PropTypes.string,
  isTags: PropTypes.bool,
  defaultOpen: PropTypes.bool,
  withCheckbox: PropTypes.bool,
  withRadio: PropTypes.bool 
};

export default Select;
