import React, { useRef } from 'react';
import './IntakeLocation.css';
import { useTranslation } from 'utils/modifiedTranslation';
import Geosuggest from 'react-geosuggest';
import PropTypes from 'prop-types';
import Form from 'new-ui/Components/Form';
import Button from 'new-ui/Components/Button';

const IntakeLocation = (props)=>{
  const {
    user,
    onselect,
  } = props;
  const { t } = useTranslation();
  const geosuggestEl = useRef(null);

  let data = {
    distance: user?.personal?.travel_limit,
  };

  const inputs = {
    distance: {
      type: 'range',
      title: t('personalize.distance_from_me'),
      single: true,
      options: [
        { title: '50mi', value: '50' },
        { title: '100mi', value: '100' },
        { title: '200mi', value: '200' },
        { title: 'Any', value: 'none' },
      ],
    },
  };

  const geoSuggestChange = (geoLocation) => {
    if (!geoLocation) return;
    const { location, label } = geoLocation;
    user.info.country = {
      location,
      label,
    };
    onselect(user);
  };

  const formChange = (d) => {
    user.personal.travel_limit = d.distance;
  };

  return (
    <div className="intake-location">
      <div className="intake-question-title">
        {t('intake.location_title')}
      </div>
      <div>
        <div>
          {t('questionnaire.questions.global.preferred_location.title')}
        </div>
        <div>
          <div className="intake-location-holder">
            <Geosuggest
              ref={geosuggestEl}
              inputClassName="geo-suggest"
              suggestsClassName="geo-suggest-suggests"
              initialValue={user?.info?.country?.label || ''}
              onSuggestSelect={geoSuggestChange}
              autoComplete="on"
              country={['us', 'ca', 'il', 'tr']}
              placeholder={t('change_location.geo_suggest.placeholder')}
            />
          </div>
        </div>
      </div>
      <div className="intake-location-distance">
        <div>
          {t('questionnaire.questions.global.travel_limit.title')}
        </div>
        <div className="set-distance">
          <Form inputs={inputs} data={data} onChange={formChange} />
        </div>
      </div>
      <div>
        <Button
          title={t('general.save')}
          action={()=>{
            onselect(user);
          }}
        />
      </div>
    </div>
  );
};

IntakeLocation.propTypes = {
  q: PropTypes.any,
  user: PropTypes.any,
  onselect: PropTypes.func,
};

export default IntakeLocation;
