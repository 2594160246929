import React, { useState } from 'react';
import app from 'new-ui/app';

import PropTypes from 'prop-types';
import Biomarkers from 'new-ui/Components/Biomarkers';
import { PopupButton, PopupContent, PopupTitle } from 'new-ui/Components/Popup';

const BiomarkersPopup = (props) => {
  const {
    onSelect = (biomarkers) => { console.log('BiomarkersPopup :: biomarkers', biomarkers); },
    biomarkers = [],
  } = props;
  const [_biomarkers, setBiomarkers] = useState(biomarkers);
  return (
    <div className="biomarkers-popup">
      <PopupTitle title="Biomarkers" />
      <PopupContent>
        <Biomarkers
          biomarkers={_biomarkers}
          onChange={(biomarkers) => {
            setBiomarkers(biomarkers);
          }}
        />
      </PopupContent>
      {
        _biomarkers?.length ? (
          <PopupButton
            title="Save"
            action={() => {
              onSelect(_biomarkers);
              app.setPopup(false);
            }}
          />
        ) : null
      }
    </div>
  );
};

BiomarkersPopup.propTypes = {
  onSelect: PropTypes.any,
  biomarkers: PropTypes.any,
};

export default BiomarkersPopup;
