import React, { useState } from 'react';
import './SwitchBox.css';

const SwitchBox = (props)=>{
  const {
    onChange,
    text1,
    text2,
    className,
    initValue = false,
  } = props;
  const [value, setValue] = useState(initValue);
  return (
    <div
      onClick={()=>{
        const n = !value;
        setValue(n);
        onChange(n);
      }}
      className={`switch-box ${value ? 'switch-box-on' : ''} ${className || ''}`}
    >
      <div className="switch-box-start">{text1}</div>
      <div className="switch-box-button-rail">
        <div className="switch-box-button" />
      </div>
      <div className="switch-box-end">{text2}</div>
    </div>
  );
};

export default SwitchBox;
