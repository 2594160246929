import { REDUX_ACTIONS } from './constants';

const initialState = {
  open: false
};

export const chatBot = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case REDUX_ACTIONS.OPEN_CHATBOT:
      return {
        ...state,
        open: payload
      };
    default:
      return state;
  }
};
