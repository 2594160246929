import TREATMENT_FINDER_CONSTANTS from '../components/TreatmentFinder/constants';

const { colorPalette } = TREATMENT_FINDER_CONSTANTS;

export default (allGroups, groupsInTreatment) => {
  const { groupLabels, groupDescriptions, groupColors } = allGroups.reduce(
    (acc, curr) => {
      if (!groupsInTreatment.includes(curr.label) || acc.groupLabels.includes(curr.label)) {
        return acc;
      }

      acc.groupLabels.push(curr.label);
      acc.groupDescriptions[curr.label] = curr.description;
      if (acc.currentColorIndex > colorPalette.length - 1) {
        acc.currentColorIndex = 0;
      }
      acc.groupColors[curr.label] = colorPalette[acc.currentColorIndex];
      acc.currentColorIndex += 1;

      return acc;
    },
    {
      groupLabels: [],
      groupDescriptions: {},
      groupColors: {
        fallback: colorPalette[0]
      },
      currentColorIndex: 0
    }
  );

  return {
    groupLabels,
    groupDescriptions,
    groupColors
  };
};
