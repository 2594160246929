import send from './send';
import { eventsKeys } from '../constants';

export default (params = {}) => {
  const { currentSection, nextSection, actionType } = params || {};

  if (!nextSection || !actionType || currentSection === nextSection) {
    return;
  }

  send(eventsKeys.USER_IS_MOVED_TO_THE_NEXT_SECTION, {
    currentSection,
    nextSection,
    actionType,
  });
};
