import React from 'react';
import './ConnectWithOncologist.css';
import PropTypes from 'prop-types';

import { useTranslation } from 'utils/modifiedTranslation';

import {
  PopupTitle, PopupProperty, PopupContent,
} from 'new-ui/Components/Popup';
import app from 'new-ui/app';

const ConnectWithOncologist = (props) => {
  const { trial = {} } = props;
  const { t } = useTranslation();
  app.sendGoogleAnalyticsEvent('Contact_Oncologist', `contact_site_${trial._id}`);
  return (
    <div>
      <PopupTitle title={t('connect_with_oncologist.connect_me_with_the_study_oncologist')} />
      <PopupContent>
        <PopupProperty title={`${t('connect_with_oncologist.contact_name')}`} value={trial.contact_name} />
        <PopupProperty title={`${t('connect_with_oncologist.email')}`} value={trial.contact_email} href={`mailto:${trial.contact_email}`} />
        <PopupProperty title={`${t('connect_with_oncologist.phone')}`} value={trial.conatct_phone} />
      </PopupContent>
    </div>
  );
};

ConnectWithOncologist.propTypes = {
  trial: PropTypes.object,
};

export default ConnectWithOncologist;
