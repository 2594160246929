import { useLocation } from 'react-router-dom';

const useRouteChecker = () => {
  const location = useLocation();

  const isDashboardFinance = location.pathname === '/dashboard/finance';
  const isPublicFinance = location.pathname === '/finance';

  return { isDashboardFinance, isPublicFinance };
};

export {
    useRouteChecker
}