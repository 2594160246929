import React, { useState, useEffect, useRef } from 'react';
import './SearchSelect.css';

import { useTranslation } from 'utils/modifiedTranslation';
import PropTypes from 'prop-types';
import searchIcon from 'new-ui/assets/icons/status_2.svg';
import Button from 'new-ui/Components/Button';

import Tags from 'new-ui/Components/Tags';
import MultipleOptionSelect from '../MultipleOptionSelect';
import { QuestionTitle } from '../..';
import InfoNote from 'new-ui/Components/InfoNote';

const SearchSelect = (props) => {
  const { t } = useTranslation();
  const {
    q,
    onselect = () => {},
    initValue = [],
    buttonText = t('general.save'),
    showTitle = false,
  } = props;
  const controller = {};
  const [init, setInit] = useState(false);
  const [search, setSearch] = useState('');
  const [allOptions, setAllOptions] = useState(null);
  const [selecteds, setSelecteds] = useState(initValue || []);
  const $input = useRef();
  useEffect(() => {
    const fetch = async () => {
      const allOptions = q.options.map((a) => ({
        label: t(a.label),
        value: a.value,
      }));
      setAllOptions(allOptions);
      setInit(true);
    };
    fetch();
    // eslint-disable-next-line
  }, []);

  useEffect(()=>{
    if (controller?.setValue) controller.setValue(selecteds);
    // eslint-disable-next-line
  }, [selecteds]);

  const renderResults = () => {
    const results = allOptions.filter((a) => a.label.toLowerCase().indexOf(search.toLowerCase()) !== -1);
    return (
      <MultipleOptionSelect
        q={{
          options: results,
        }}
        autoChange
        initValue={selecteds}
        controller={controller}
        onselect={(v) => {
          setSelecteds(v);
          setSearch('');
          $input.current.value = '';
          $input.current.focus();
          // onselect(v);
        }}
      />
    );
  };

  const getTagsItems = ()=>{
    const s = [...selecteds];
    return s.map((selected)=>{
      return {
        title: allOptions.filter((a) => a.value === selected)[0].label,
        value: selected,
        action: (value)=>{
          const v = [...selecteds];
          v.splice(v.indexOf(value), 1);
          setSelecteds(v);
        },
      };
    });
  };

  return (
    init ? (
      <div className="search-select">
        { showTitle ? <QuestionTitle q={q} /> : null}
        <div className={`search-select-input ${search ? 'search-select-input-value' : ''}`}>
          <input
            ref={$input}
            placeholder="Start typing name..."
            onChange={(ev) => {
              setSearch(ev.currentTarget.value);
            }}
          />
          <img alt="icon" src={searchIcon} />
        </div>
        {
          selecteds?.length ? (
            <div className="search-select-selecteds">
              <Tags items={getTagsItems()} />
            </div>
          ) : null
        }
        {
          search ? (
            <div className="search-results">
              {renderResults()}
            </div>
          ) : null
        }
        {(selecteds.length > 1) && <InfoNote
          info={t('intake.note_description')}
        />}
        {
          selecteds.length ? (
            <Button
              title={buttonText}
              action={() => {
                onselect(selecteds);
              }}
            />
          ) : null
        }
      </div>
    ) : null
  );
};

SearchSelect.propTypes = {
  q: PropTypes.any,
  user: PropTypes.any,
  onselect: PropTypes.func,
  initValue: PropTypes.any,
  buttonText: PropTypes.string,
  showTitle: PropTypes.bool,
};

export default SearchSelect;
