import React from "react";
import "./style.css";
import closeIcon from 'new-ui/assets/icons/close.svg';

const PopoverWrapper = ({ children, onClose }) => {
  return (
    <div className="popover-wrapper">
      <div className="popover-header">
        <img onClick={onClose} className="close-icon" src={closeIcon} alt="icon" />
      </div>
      <div className="popover-content">
        {children}
      </div>
    </div>
  );
};

export default PopoverWrapper;