import React from 'react';

export const getDrugCommercialName = (drugName) => {
  const drugsParts = drugName.split('+');
  return (
    <span>
      {drugsParts.map((part, index) => (
        <React.Fragment key={index}>
          {part.trim()}
          {index !== drugsParts.length - 1 ? <span className="drug-commercial-name"> + </span> : null}
        </React.Fragment>
      ))}
    </span>
  );
};
