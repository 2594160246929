export default {
  ACCESS_TOKEN: 'access_token',
  REFRESH_TOKEN: 'refresh_token',
  USER: 'user',
  SUPER_USER: 'superuser',
  JOINED_AT: 'joined_at',
  LANGUAGE: 'lang',
  MASKED_EMAIL: 'masked_email',
  MASKED_PHONE: 'masked_phone',
  ENC: 'enc',
  LANG: 'lang',
  ACTIONS_SEEN: 'actions_seen',
  SHOULD_CREATE_EMAILS_TOKEN: 'shouldCreateEmailsToken',
};
