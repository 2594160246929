import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import serverDown from 'utils/static/icons/serverDown.svg';
import GLOBAL from 'utils/constants/global';

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '0px 1%',
    height: '100vh'
  },
  errorMessage: {
    height: '30px',
    color: '#53BBBB',
    fontFamily: 'Roboto',
    fontSize: '24px',
    lineHeight: '30px',
    textAlign: 'center'
  },
  solutionMessage: {
    height: '32px',
    color: '#324158',
    fontFamily: '18px',
    lineHeight: '32px',
    textAlign: 'center'
  },
  link: {
    textDecoration: 'underline'
  },
  footer: {
    height: '20px',
    width: '143px',
    color: '#617984',
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: '100px',
    paddingTop: '10%'
  }
};

function ServerDown(props) {
  const {
    footer,
    landingPageUrl,
    serverDown: { errorMessage, solutionMessage }
  } = GLOBAL;
  const { serverStatus } = props;

  return (
    <div style={styles.container}>
      <img src={serverDown} alt="serverDown" />
      <p style={styles.errorMessage}>{serverStatus.reasonMessage || errorMessage}</p>
      <p style={styles.solutionMessage}>
        {serverStatus.solutionMessage ? (
          serverStatus.solutionMessage
        ) : (
          <>
            {solutionMessage.textWithoutLink}{' '}
            <a href={landingPageUrl} style={styles.link}>
              {solutionMessage.textWithLink}
            </a>
            .
          </>
        )}
      </p>
      <p style={styles.footer}>© {footer.copyright.year} by LEAL Health</p>
    </div>
  );
}

ServerDown.propTypes = {
  serverStatus: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    error: PropTypes.string,
    reasonMessage: PropTypes.string,
    solutionMessage: PropTypes.string
  }).isRequired
};

const mapStateToProps = state => ({
  serverStatus: state.serverStatus
});

export default connect(mapStateToProps)(ServerDown);
