import React, { useRef, useState, useEffect } from 'react';

import XIcon from 'new-ui/assets/icons/x.svg';
import MapIcon from 'new-ui/assets/icons/map2.svg';
import Geosuggest from 'react-geosuggest';

import PropTypes from 'prop-types';
import { useTranslation } from 'utils/modifiedTranslation';

import './LocationInput.css';

const LocationInput = (props) => {
  const { value, onChange } = props;
  const { t } = useTranslation();
  const [showIcon, setShowIcon] = useState(false);
  const geosuggestEl = useRef(null);
  useEffect(() => {
    setShowIcon(!value);
  }, [value]);
  const geoSuggestChange = (geoLocation) => {
    if (!geoLocation) return setShowIcon(true);
    const { location, label } = geoLocation;
    onChange({
      location,
      label,
    });
    setShowIcon(false);
  };
  return (
    <div className={`location-input ${value ? 'location-input-value' : ''}`}>
      <div
        role="button"
        className={`geo-clear-button ${showIcon ? 'map-icon' : 'close-icon'}`}
        onClick={
          () => {
            geosuggestEl.current.clear();
            setShowIcon(true);
            onChange(null);
          }
        }
      >
        <img alt="close" src={showIcon ? MapIcon : XIcon} />
      </div>
      <Geosuggest
        ref={geosuggestEl}
        inputClassName={`geo-suggest ${value ? 'geo-suggest-value' : ''}`}
        suggestsClassName="geo-suggest-suggests"
        initialValue={value && typeof value === 'object' ? value.label : value}
        onSuggestSelect={geoSuggestChange}
        autoComplete="on"
        country={['us', 'ca', 'il', 'tr']}
        placeholder={t('change_location.geo_suggest.placeholder')}
      />
    </div>
  );
};

LocationInput.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
};

export default LocationInput;
