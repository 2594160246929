import {
  LOCATION,
  MONTH_PICKER,
  MULTIPLE_OPTION_SELECT,
  SINGLE_OPTION_SELECT,
  OPTIONS_FROM_SERVER,
  TEXT_INPUT,
} from 'components/Questionnaire/constants/questionTypes';
import dateType from 'utils/constants/dateTypes';
import GLOBAL_ENUMS from 'components/Questionnaire/constants/globalEnums';
import i from 'components/Questionnaire/constants/icons';
import SECTIONS from 'components/Questionnaire/constants/questionSections';
import ConditionTypes from 'components/Questionnaire/constants/conditions';
import { QUESTION_VIEW_TYPES } from './questionViewTypes';

const { OPTIONS_LIST_WITH_SHOW_MORE } = QUESTION_VIEW_TYPES;
const {
  EQUAL_TO,
} = ConditionTypes;

export const userType = {
  q: 'Q01',
  id: GLOBAL_ENUMS.user_type,
  type: SINGLE_OPTION_SELECT,
  section: SECTIONS.ABOUT_ME,
  bgIcon: i.medical_report,
  order: 0,
  answerResetable: true,
  title: 'questionnaire.questions.global.user_type.title',
  info: {
    title: '',
    content: '',
  },
  options: [
    {
      label: 'questionnaire.questions.global.user_type.options.patient',
      value: 'patient',
    },
    {
      label: 'questionnaire.questions.global.user_type.options.caregiver',
      value: 'caregiver',
    },
    {
      label: 'questionnaire.questions.global.user_type.options.doctor',
      value: 'doctor',
    },
  ],
  conditions: [],
};

export const diseaseStatus = {
  q: 'Q02',
  id: GLOBAL_ENUMS.disease_status,
  type: SINGLE_OPTION_SELECT,
  section: SECTIONS.MY_CANCER,
  bgIcon: i.medical_profile,
  isRequired: true,
  order: 0,
  title: 'questionnaire.questions.global.disease_status.title',
  info: {
    title: '',
    content: '',
  },
  options: [
    {
      label: 'questionnaire.questions.global.disease_status.options.new_disease',
      value: 'new_disease',
    },
    {
      label: 'questionnaire.questions.global.disease_status.options.refractory_disease',
      value: 'refractory_disease',
    },
    {
      label: 'questionnaire.questions.global.disease_status.options.recurrence',
      value: 'recurrence',
    },
    {
      label: 'questionnaire.questions.global.disease_status.options.partial_response_stable',
      value: 'partial_response_stable',
    },
    {
      label: 'questionnaire.questions.global.disease_status.options.remission',
      value: 'remission',
    },
  ],
  conditions: [],
};

export const race = {
  q: 'Q03',
  id: GLOBAL_ENUMS.race,
  type: MULTIPLE_OPTION_SELECT,
  section: SECTIONS.ABOUT_ME,
  bgIcon: i.medical_profile,
  order: 0,
  title: 'questionnaire.questions.global.race.title',
  subtitle: 'questionnaire.questions.global.race.subtitle',
  info: {
    title: '',
    content: '',
  },
  options: [
    {
      label: 'questionnaire.questions.global.race.options.native_american',
      value: 'native_american',
    },
    {
      label: 'questionnaire.questions.global.race.options.asian',
      value: 'asian',
    },
    {
      label: 'questionnaire.questions.global.race.options.african_american',
      value: 'african_american',
    },
    {
      label: 'questionnaire.questions.global.race.options.hispanic_latino',
      value: 'hispanic_latino',
    },
    {
      label: 'questionnaire.questions.global.race.options.pacific_islander',
      value: 'pacific_islander',
    },
    {
      label: 'questionnaire.questions.global.race.options.white',
      value: 'white',
    },
    {
      label: 'questionnaire.questions.global.race.options.other',
      value: 'other',
    },
    {
      label: 'questionnaire.questions.global.race.options.decline_to_answer',
      value: 'decline_to_answer',
      exclusive: true,
    },
  ],
  conditions: [],
};

export const preferredLocation = {
  q: 'Q04',
  id: GLOBAL_ENUMS.preferred_location,
  type: LOCATION,
  section: SECTIONS.ABOUT_ME,
  bgIcon: i.globe,
  order: 0,
  title: 'questionnaire.questions.global.preferred_location.title',
  info: {
    title: 'questionnaire.questions.global.preferred_location.info.title',
    content: 'questionnaire.questions.global.preferred_location.info.content',
  },
  options: [],
  conditions: [],
};

export const travelLimit = {
  q: 'Q05',
  id: GLOBAL_ENUMS.travel_limit,
  type: SINGLE_OPTION_SELECT,
  section: SECTIONS.ABOUT_ME,
  bgIcon: i.globe,
  order: 0,
  title: 'questionnaire.questions.global.travel_limit.title',
  info: {
    title: '',
    content: '',
  },
  options: [
    {
      label: 'questionnaire.questions.global.travel_limit.options.50',
      value: '50',
    },
    {
      label: 'questionnaire.questions.global.travel_limit.options.100',
      value: '100',
    },
    {
      label: 'questionnaire.questions.global.travel_limit.options.200',
      value: '200',
    },
    {
      label: 'questionnaire.questions.global.travel_limit.options.none',
      value: 'none',
    },
  ],
  conditions: [],
};

export const lookingFor = {
  q: 'Q06',
  id: GLOBAL_ENUMS.looking_for,
  type: SINGLE_OPTION_SELECT,
  section: SECTIONS.ABOUT_ME,
  bgIcon: i.medical_profile,
  order: 0,
  title: 'questionnaire.questions.global.looking_for.title',
  info: {
    title: 'questionnaire.questions.global.looking_for.info.title',
    content: 'questionnaire.questions.global.looking_for.info.content',
  },
  options: [
    {
      label: 'questionnaire.questions.global.looking_for.options.new_disease',
      value: 'new_disease',
    },
    {
      label: 'questionnaire.questions.global.looking_for.options.refractory_disease',
      value: 'refractory_disease',
    },
    {
      label: 'questionnaire.questions.global.looking_for.options.recurrence',
      value: 'recurrence',
    },
    {
      label: 'questionnaire.questions.global.disease_status.options.partial_response_stable',
      value: 'partial_response_stable',
    },
    {
      label: 'questionnaire.questions.global.looking_for.options.remission',
      value: 'remission',
    },
  ],
  conditions: [],
};

export const insurance = {
  q: 'Q08',
  id: GLOBAL_ENUMS.insurances,
  type: OPTIONS_FROM_SERVER,
  viewType: OPTIONS_LIST_WITH_SHOW_MORE,
  section: SECTIONS.ABOUT_ME,
  bgIcon: i.medical_profile,
  order: 0,
  title: 'questionnaire.questions.global.insurances.title',
  subtitle: 'questionnaire.questions.global.insurances.subtitle',
  placeholder: 'questionnaire.questions.global.insurances.placeholder',
  info: {
    title: '',
    content: '',
  },
  conditions: [
    {
      ifId: GLOBAL_ENUMS.have_insurance,
      type: EQUAL_TO,
      ifValue: 'have_insurance_yes',
    },
  ],
};

export const haveInsurance = {
  q: 'Q09',
  id: GLOBAL_ENUMS.have_insurance,
  type: SINGLE_OPTION_SELECT,
  section: SECTIONS.ABOUT_ME,
  bgIcon: i.medical_profile,
  order: 0,
  title: 'questionnaire.questions.global.have_insurance.title',
  info: {
    title: '',
    content: '',
  },
  options: [
    {
      label: 'questionnaire.questions.insurances.have_insurance',
      value: 'have_insurance_yes',
    },
    {
      label: 'questionnaire.questions.insurances.dont_have',
      value: 'have_insurance_dont_have',
    },
    {
      label: 'questionnaire.questions.insurances.not_relevant',
      value: 'have_insurance_not_relevant',
    },
    {
      label: 'questionnaire.questions.insurances.international_patient',
      value: 'have_insurance_international_patient',
    },
    {
      label: 'questionnaire.questions.insurances.other',
      value: 'have_insurance_other',
    },
  ],
  conditions: [],
};

export const dateOfBirth = {
  q: 'Q07',
  id: GLOBAL_ENUMS.dob,
  type: MONTH_PICKER,
  section: SECTIONS.ABOUT_ME,
  bgIcon: i.medical_profile,
  order: 0,
  title: 'questionnaire.questions.global.date_of_birth.title',
  info: {
    title: '',
    content: '',
  },
  conditions: [],
  dateType: {
    type: dateType.dob,
  },
};

export const gender = {
  q: 'Q2',
  id: GLOBAL_ENUMS.gender,
  type: SINGLE_OPTION_SELECT,
  section: SECTIONS.ABOUT_ME,
  bgIcon: i.medical_profile,
  order: 0,
  title: 'questionnaire.questions.gender.title',
  info: {
    title: '',
    content: '',
  },
  options: [
    {
      label: 'questionnaire.questions.gender.options.male',
      value: 'male',
    },
    {
      label: 'questionnaire.questions.gender.options.female',
      value: 'female',
    },
    {
      label: 'questionnaire.questions.global.gender.options.other',
      value: 'other',
    },
  ],
  conditions: [],
};

export const initialDiagnosisDate = (q = 'Q3') => ({
  q,
  id: GLOBAL_ENUMS.initial_diagnosis_date,
  type: MONTH_PICKER,
  section: SECTIONS.ABOUT_ME,
  bgIcon: i.cancer_research,
  order: 0,
  title: 'questionnaire.questions.aml.initial_diagnosis_date.title',
  info: {
    title: '',
    content: '',
  },
  options: [],
  conditions: [],
  dateType: {
    type: dateType.currentDate,
  },
});

export const fullName = {
  q: 'Q91',
  id: GLOBAL_ENUMS.family_name,
  type: TEXT_INPUT,
  section: SECTIONS.ABOUT_ME,
  isRequired: true,
  bgIcon: i.cancer_research,
  order: 0,
  title: 'questionnaire.questions.global.full_name.title',
  subtitle: '',
  placeholder: 'questionnaire.questions.global.full_name.placehoder',
  helpText: 'questionnaire.questions.global.full_name.help_text',
  info: {
    title: '',
    content: '',
  },
  options: [],
  conditions: [],
};

export const pdL1ExpressionByQNumber = (qNumber) => ({
  q: qNumber,
  id: GLOBAL_ENUMS.pd_l1_expression,
  type: SINGLE_OPTION_SELECT,
  section: SECTIONS.MY_CANCER,
  bgIcon: i.molecular,
  order: 0,
  title: 'questionnaire.questions.global.pd_l1_expression.title',
  info: {
    title: '',
    content: '',
  },
  options: [
    {
      label: 'questionnaire.questions.global.pd_l1_expression.options.pd_l1_high',
      value: 'pd_l1_high',
    },
    {
      label: 'questionnaire.questions.global.pd_l1_expression.options.pd_l1_low',
      value: 'pd_l1_low',
    },
    {
      label: 'questionnaire.questions.global.pd_l1_expression.options.not_sure',
      value: 'not_sure',
    },
  ],
  conditions: [
    {
      ifId: GLOBAL_ENUMS.pd_l1_carrier_status,
      type: EQUAL_TO,
      ifValue: 'yes',
    },
  ],
});
