import LOCAL_STORAGE_KEYS from 'utils/constants/localStorageKeys';
import authenticationConstants from '../constants';

export function getInitialState(defaultAccessToken, defaultRefreshToken, defaultUser) {
  const state = {
    isFormFetching: false,
    isVerificationCodeFetching: false,
    error: null,
    loggedIn: false,
    justSignedUp: false,
    user: null,
    formValidity: {
      requiredPhoneLength: 0,
      isPhoneValid: true,
      isEmailValid: true,
      isPasswordValid: true,
      isRepeatPasswordValid: true,
    },
    phoneConfirmationResponse: {},
  };
  const accessToken = defaultAccessToken || localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
  const refreshToken = defaultRefreshToken || localStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
  const user = defaultUser || localStorage.getItem(LOCAL_STORAGE_KEYS.USER);

  if (accessToken && refreshToken && user) {
    state.loggedIn = true;
    state.user = JSON.parse(user);
  }

  return state;
}

const initialState = getInitialState();

export function authentication(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case authenticationConstants.SEND_FORM_REQUEST:
      return {
        ...state,
        isFormFetching: true,
        error: null,
      };
    case authenticationConstants.SEND_FORM_SUCCESS:
      return {
        ...state,
        isFormFetching: false,
      };

    case authenticationConstants.SEND_VERIFICATION_CODE_REQUEST:
      return {
        ...state,
        isVerificationCodeFetching: true,
        error: null,
      };
    case authenticationConstants.SEND_VERIFICATION_CODE_SUCCESS:
      return {
        ...state,
        isVerificationCodeFetching: false,
      };

    case authenticationConstants.USER_AUTHENTICATION_FAILURE:
      return {
        ...state,
        isFormFetching: false,
        isVerificationCodeFetching: false,
        error: payload,
      };
    case authenticationConstants.SET_AUTHENTICATION_DATA:
      return {
        ...state,
        error: null,
        loggedIn: true,
        user: payload?.user || null,
        justSignedUp: payload?.justSignedUp || false,
      };

    case authenticationConstants.SET_FORM_VALIDITY:
      return {
        ...state,
        formValidity: payload,
      };
    case authenticationConstants.SET_PHONE_CONFIRMATION_RESPONSE:
      return {
        ...state,
        phoneConfirmationResponse: payload,
      };

    case authenticationConstants.RESET_FORM_VALIDITY:
      return {
        ...state,
        formValidity: {
          requiredPhoneLength: 0,
          isPhoneValid: true,
          isEmailValid: true,
          isPasswordValid: true,
          isRepeatPasswordValid: true,
        },
      };
    case authenticationConstants.RESET_LOGGED_IN:
      return {
        ...state,
        loggedIn: false,
      };
    case authenticationConstants.RESET_PHONE_CONFIRMATION_RESPONSE:
      return {
        ...state,
        phoneConfirmationResponse: {},
      };

    case authenticationConstants.LOGOUT:
      return {
        isFormFetching: false,
        isVerificationCodeFetching: false,
        error: null,
        loggedIn: false,
        user: null,
        formValidity: {
          requiredPhoneLength: 0,
          isPhoneValid: true,
          isEmailValid: true,
          isPasswordValid: true,
          isRepeatPasswordValid: true,
        },
        phoneConfirmationResponse: {},
      };
    case authenticationConstants.SOFT_LOGOUT:
      return {
        isFormFetching: false,
        isVerificationCodeFetching: false,
        error: null,
        loggedIn: false,
        user: null,
        formValidity: {
          requiredPhoneLength: 0,
          isPhoneValid: true,
          isEmailValid: true,
          isPasswordValid: true,
          isRepeatPasswordValid: true,
        },
        phoneConfirmationResponse: {},
      };
    default:
      return state;
  }
}
