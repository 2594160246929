import {
  SINGLE_OPTION_SELECT,
  MULTIPLE_OPTION_SELECT,
  NUMBER_INPUT,
  MONTH_PICKER,
  TREATMENT_HISTORY,
  MULTIPLE_DROP_DOWN_SELECT,
  BIOMARKERS,
  OPTIONS_FROM_SERVER,
} from 'components/Questionnaire/constants/questionTypes';
import { Profile as ID } from 'components/Questionnaire/cancers/mpn/MpnProfile.enum';
import ConditionTypes from 'components/Questionnaire/constants/conditions';
import i from 'components/Questionnaire/constants/icons';
import {
  preferredLocation,
  travelLimit,
  userType,
  diseaseStatus,
  haveInsurance,
  insurance,
  initialDiagnosisDate,
} from 'components/Questionnaire/constants/globalQuestions';
import SECTIONS from 'components/Questionnaire/constants/questionSections';
import GLOBAL_ENUMS from 'components/Questionnaire/constants/globalEnums';
import dateType from 'utils/constants/dateTypes';
import numberType from 'utils/constants/numberTypes';
// import { takingStateOptions } from 'components/Questionnaire/constants/globalOptions';
import { QUESTION_VIEW_TYPES } from '../../constants/questionViewTypes';
import { treatmentCyclesOptions } from '../../constants/globalOptions';

const { biomarkers: BIOMARKERS_QUESTION_ID } = GLOBAL_ENUMS;
const { OPTIONS_LIST_WITH_SHOW_MORE } = QUESTION_VIEW_TYPES;

const {
  EQUAL_TO,
  NOT_EQUAL_TO_OR_SKIPPED,
  CONTAIN_ONE_OF,
  AGE_LESS_THAN,
  DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
  CONTAINS_ONE_OF_OR_SKIPPED,
} = ConditionTypes;

const notSureOption = {
  label: 'questionnaire.questions.mpn.not_sure_option.label',
  value: 'not_sure',
};

const yesAndNoOptions = [
  {
    label: 'questionnaire.questions.mpn.yes_and_no_options.yes',
    value: 'yes',
  },
  {
    label: 'questionnaire.questions.mpn.yes_and_no_options.no',
    value: 'no',
  },
];

const Questions = [
  diseaseStatus,
  {
    q: 'Q4',
    id: ID.type_of_mpn,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.mpn.3_type_of_mpn.title',

    info: {
      title: 'questionnaire.questions.mpn.3_type_of_mpn.info.title',
      content: 'questionnaire.questions.mpn.3_type_of_mpn.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.mpn.3_type_of_mpn.options.myelofibrosis',
        value: 'myelofibrosis',
      },
      {
        label: 'questionnaire.questions.mpn.3_type_of_mpn.options.essential_thrombocythemia',
        value: 'essential_thrombocythemia',
      },
      {
        label: 'questionnaire.questions.mpn.3_type_of_mpn.options.polycythemia_vera',
        value: 'polycythemia_vera',
      },
      {
        label: 'questionnaire.questions.mpn.3_type_of_mpn.options.cmml',
        value: 'cmml',
      },
    ],
    conditions: [],
    isRequired: true,
  },
  {
    q: 'Q7',
    id: ID.risk_group,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.mpn.5_risk_group.title',

    info: {
      title: 'questionnaire.questions.mpn.5_risk_group.info.title',
      content: 'questionnaire.questions.mpn.5_risk_group.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.mpn.5_risk_group.options.low_risk',
        value: 'low_risk',
      },
      {
        label: 'questionnaire.questions.mpn.5_risk_group.options.intermediate_risk',
        value: 'intermediate_risk',
      },
      {
        label: 'questionnaire.questions.mpn.5_risk_group.options.high_risk',
        value: 'high_risk',
      },
    ],
    conditions: [],
    isRequired: true,
  },
  {
    q: 'Q13',
    id: ID.treatment_for_mpn,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.mpn.17_treatment_for_mpn.title',

    info: {
      title: 'questionnaire.questions.mpn.17_treatment_for_mpn.info.title',
      content: 'questionnaire.questions.mpn.17_treatment_for_mpn.info.content',
    },
    options: yesAndNoOptions,
    conditions: [],
    isRequired: true,
  },
  {
    q: 'Q61',
    id: ID.category_of_cmml,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.mpn.category_of_cmml.title',
    info: {
      title: 'questionnaire.questions.mpn.category_of_cmml.info.title',
      content: 'questionnaire.questions.mpn.category_of_cmml.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.mpn.category_of_cmml.options.cmml_0',
        value: '0',
      },
      {
        label: 'questionnaire.questions.mpn.category_of_cmml.options.cmml_1',
        value: '1',
      },
      {
        label: 'questionnaire.questions.mpn.category_of_cmml.options.cmml_2',
        value: '2',
      },
      {
        label: 'questionnaire.questions.mpn.category_of_cmml.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: ID.type_of_mpn,
        type: EQUAL_TO,
        ifValue: 'cmml',
      },
    ],
    isRequired: true,
  },
  {
    q: 'Q4a',
    id: ID.type_of_mf,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.mpn.4_type_of_mf.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.mpn.4_type_of_mf.options.primary_mf',
        value: 'primary_mf',
      },
      {
        label: 'questionnaire.questions.mpn.4_type_of_mf.options.post_pv_mf',
        value: 'post_pv_mf',
      },
      {
        label: 'questionnaire.questions.mpn.4_type_of_mf.options.post_et_mf',
        value: 'post_et_mf',
      },
    ],
    conditions: [
      {
        ifId: ID.type_of_mpn,
        type: EQUAL_TO,
        ifValue: 'myelofibrosis',
      },
    ],
  },
  {
    q: 'Q62',
    id: ID.type_of_cmml,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.mpn.62_type_of_cmml.title',
    info: {
      title: 'questionnaire.questions.mpn.62_type_of_cmml.info.title',
      content: 'questionnaire.questions.mpn.62_type_of_cmml.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.mpn.4_type_of_cmml.options.proliferative_cmml',
        value: 'proliferative_cmml',
      },
      {
        label: 'questionnaire.questions.mpn.4_type_of_cmml.options.dysplatic_cmml',
        value: 'dysplatic_cmml',
      },
      {
        label: 'questionnaire.questions.mpn.4_type_of_cmml.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: ID.type_of_mpn,
        type: EQUAL_TO,
        ifValue: 'cmml',
      },
    ],
  },
  {
    q: 'Q7a',
    id: ID.intermediate_risk_subgroup,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.mpn.6_intermediate_risk_subgroup.title',
    info: {
      title: 'questionnaire.questions.mpn.6_intermediate_risk_subgroup.info.title',
      content: 'questionnaire.questions.mpn.6_intermediate_risk_subgroup.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.mpn.6_intermediate_risk_subgroup.options.intermediate_1',
        value: 'intermediate_1',
      },
      {
        label: 'questionnaire.questions.mpn.6_intermediate_risk_subgroup.options.intermediate_2',
        value: 'intermediate_2',
      },
      notSureOption,
    ],
    conditions: [
      {
        ifId: ID.type_of_mpn,
        type: CONTAIN_ONE_OF,
        ifValue: ['myelofibrosis', 'cmml'],
      },
      {
        ifId: ID.risk_group,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['low_risk', 'high_risk'],
      },
    ],
  },
  {
    q: 'Q18',
    id: ID.has_biopsy,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.mpn.17_treatment_for_mpn.title',

    info: {
      title: 'questionnaire.questions.mpn.17_treatment_for_mpn.info.title',
      content: 'questionnaire.questions.mpn.17_treatment_for_mpn.info.content',
    },
    options: yesAndNoOptions,
    conditions: [],
    isRequired: true,
  },
  {
    q: 'Q80',
    id: ID.ngs,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.molecular,
    order: 0,
    title: 'questionnaire.questions.mpn.41_ngs.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.mpn.41_ngs.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.mpn.41_ngs.options.yes_waiting_for_the_results',
        value: 'yes_waiting_for_the_results',
      },
      {
        label: 'questionnaire.questions.mpn.41_ngs.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.mpn.41_ngs.options.no_but_planning',
        value: 'no_but_planning',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q18a',
    id: BIOMARKERS_QUESTION_ID,
    type: BIOMARKERS,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.molecular,
    order: 0,
    title: 'questionnaire.questions.mpn.8_biomarkers.title',
    subtitle: 'questionnaire.questions.mpn.8_biomarkers.subtitle',
    placeholder: 'questionnaire.questions.biomarkers.placeholder',
    info: {
      title: '',
      content: '',
    },
    conditions: [
      {
        ifId: ID.has_biopsy,
        type: CONTAINS_ONE_OF_OR_SKIPPED,
        ifValue: ['yes'],
      },
    ],
  },
  {
    q: 'Q5',
    id: ID.percentage_of_blasts,
    type: NUMBER_INPUT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.mpn.9_percentage_of_blasts.title',

    info: {
      title: 'questionnaire.questions.mpn.9_percentage_of_blasts.info.title',
      content: 'questionnaire.questions.mpn.9_percentage_of_blasts.info.content',
    },
    options: [],
    conditions: [],
    numberType: numberType.percentage,
  },
  {
    q: 'Q10',
    id: ID.splenomegaly,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: '',
    order: 0,
    title: 'questionnaire.questions.mpn.10_splenomegaly.title',

    info: {
      title: 'questionnaire.questions.mpn.10_splenomegaly.info.title',
      content: 'questionnaire.questions.mpn.10_splenomegaly.info.content',
    },
    options: [...yesAndNoOptions, notSureOption],
    conditions: [],
    isRequired: true,
  },
  {
    q: 'Q11',
    id: ID.splenic_radiation,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: null,
    order: 0,
    title: 'questionnaire.questions.mpn.11_splenic_radiation.title',

    info: {
      title: '',
      content: '',
    },
    options: [...yesAndNoOptions, notSureOption],
    conditions: [],
  },
  {
    q: 'Q11a',
    id: ID.splenic_radiation_date,
    type: MONTH_PICKER,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.mpn.12_splenic_radiation_date.title',

    info: {
      title: '',
      content: '',
    },
    options: [],
    conditions: [
      {
        ifId: ID.splenic_radiation,
        type: EQUAL_TO,
        ifValue: 'yes',
      },
    ],
    dateType: {
      type: dateType.stopTreatment,
    },
  },
  {
    q: 'Q8',
    id: ID.platelet_count,
    type: NUMBER_INPUT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.mpn.13_platelet_count.title',

    info: {
      title: 'questionnaire.questions.mpn.13_platelet_count.info.title',
      content: 'questionnaire.questions.mpn.13_platelet_count.info.content',
    },
    options: [],
    conditions: [],
    instruction: 'questionnaire.questions.mpn.13_platelet_count.instruction',
    numberType: numberType.intWithoutUnit,
  },
  {
    q: 'Q9',
    id: ID.received_allogenic_transplant,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.mpn.14_received_allogenic_transplant.title',
    info: {
      title: 'questionnaire.questions.mpn.14_received_allogenic_transplant.info.title',
      content: 'questionnaire.questions.mpn.14_received_allogenic_transplant.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.mpn.14_received_allogenic_transplant.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.mpn.14_received_allogenic_transplant.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.mpn.14_received_allogenic_transplant.options.candidate',
        value: 'candidate',
      },
      {
        label: 'questionnaire.questions.mpn.14_received_allogenic_transplant.options.scheduled',
        value: 'scheduled',
      },
    ],
    conditions: [
      {
        ifId: ID.type_of_mpn,
        type: CONTAIN_ONE_OF,
        ifValue: ['myelofibrosis', 'cmml'],
      },
    ],
    isRequired: true,
  },
  {
    q: 'Q9a',
    id: ID.allogenic_treatment_date,
    type: MONTH_PICKER,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.mpn.15_allogenic_treatment_date.title',
    info: {
      title: 'questionnaire.questions.mpn.15_allogenic_treatment_date.info.title',
      content: 'questionnaire.questions.mpn.15_allogenic_treatment_date.info.content',
    },
    options: [
      {
        label: '',
        value: '',
      },
    ],
    conditions: [
      {
        ifId: ID.received_allogenic_transplant,
        type: EQUAL_TO,
        ifValue: 'yes',
      },
    ],
    dateType: {
      type: dateType.stopTreatment,
    },
  },
  {
    q: 'Q9b',
    id: ID.allogenic_transplant_outcome,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.mpn.16_allogenic_transplant_outcome.title',
    info: {
      title: 'questionnaire.questions.mpn.16_allogenic_transplant_outcome.info.title',
      content: 'questionnaire.questions.mpn.16_allogenic_transplant_outcome.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.mpn.16_allogenic_transplant_outcome.options.remission',
        value: 'remission',
      },
      {
        label: 'questionnaire.questions.mpn.16_allogenic_transplant_outcome.options.no_response',
        value: 'no_response',
      },
      {
        label: 'questionnaire.questions.mpn.16_allogenic_transplant_outcome.options.relapse',
        value: 'relapse',
      },
    ],
    conditions: [
      {
        ifId: ID.received_allogenic_transplant,
        type: EQUAL_TO,
        ifValue: 'yes',
      },
    ],
  },
  {
    q: 'Q60',
    id: ID.treatments,
    type: TREATMENT_HISTORY,
    section: SECTIONS.TREATMENT,
    bgIcon: '',
    order: 0,
    title: 'questionnaire.questions.mpn.18_treatments.title',

    requiredSubQuestionsId: [ID.drugs_received],
    footer: 'questionnaire.questions.mpn.18_treatments.footer',
    info: {
      title: '',
      content: '',
    },
    submitButtonLabel: 'questionnaire.questions.mpn.18_treatments.button.submit',
    options: [
      {
        iconText: 'A',
        label: 'questionnaire.questions.mpn.18_treatments.options.treatment_name',
        value: null,
      },
      {
        iconText: 'D',
        label: 'questionnaire.questions.mpn.18_treatments.options.when_treatment_stopped',
        value: null,
      },
      {
        iconText: 'E',
        label: 'questionnaire.questions.mpn.18_treatments.options.reason_treatment_stopped',
        value: null,
      },
    ],
    subQuestions: [
      {
        q: 'Q60a',
        id: ID.drugs_received,
        type: OPTIONS_FROM_SERVER,
        viewType: OPTIONS_LIST_WITH_SHOW_MORE,
        section: SECTIONS.TREATMENT,
        bgIcon: i.treatment,
        order: 0,
        titleIconText: 'A',
        title: 'questionnaire.questions.mpn.18_treatments.sub_questions.drugs_received.title',
        subtitle: 'questionnaire.questions.mpn.18_treatments.sub_questions.drugs_received.subtitle',
        placeholder: 'questionnaire.questions.drugs_received.placeholder',
        info: {
          title: '',
          content: '',
        },
        questionProps: {
          isSearchableByOptionGroupName: true,
        },
      },
      {
        q: 'Q60f',
        id: ID.cycles,
        type: SINGLE_OPTION_SELECT,
        section: SECTIONS.TREATMENT,
        title: 'questionnaire.questions.mpn.18_treatments.sub_questions.cycles.title',
        disableNextOnOptionSelect: false,
        options: [...treatmentCyclesOptions],
      },
      {
        q: 'Q60b',
        id: ID.treatment_end_date,
        type: MONTH_PICKER,
        section: SECTIONS.TREATMENT,
        bgIcon: i.treatment,
        order: 0,
        titleIconText: 'B',
        title: 'questionnaire.questions.mpn.18_treatments.sub_questions.treatment_end_date.title',
        subtitle: '',
        info: {
          title: '',
          content: '',
        },
        options: [],
        conditions: [],
        dateType: {
          type: dateType.stopTreatment,
        },
      },
      {
        q: 'Q60c',
        id: ID.treatment_stop_reason,
        type: SINGLE_OPTION_SELECT,
        section: SECTIONS.TREATMENT,
        bgIcon: i.treatment,
        order: 0,
        titleIconText: 'C',
        title: 'questionnaire.questions.mpn.18_treatments.sub_questions.treatment_stop_reason.title',
        subtitle: '',
        info: {
          title: '',
          content: '',
        },
        disableNextOnOptionSelect: false,
        options: [
          {
            label:
              'questionnaire.questions.mpn.18_treatments.sub_questions.treatment_stop_reason.options.treatment_ongoing',
            value: 'treatment_ongoing',
          },
          {
            label:
              'questionnaire.questions.mpn.18_treatments.sub_questions.treatment_stop_reason.options.end_of_protocol',
            value: 'end_of_protocol',
          },
          {
            label: 'questionnaire.questions.mpn.18_treatments.sub_questions.treatment_stop_reason.options.progression',
            value: 'progression',
          },
          {
            label: 'questionnaire.questions.mpn.18_treatments.sub_questions.treatment_stop_reason.options.no_response',
            value: 'no_response',
          },
          {
            label: 'questionnaire.questions.mpn.18_treatments.sub_questions.treatment_stop_reason.options.relapse',
            value: 'relapse',
          },
          {
            label: 'questionnaire.questions.mpn.18_treatments.sub_questions.treatment_stop_reason.options.remission',
            value: 'remission',
          },
          {
            label: 'questionnaire.questions.mpn.18_treatments.sub_questions.treatment_stop_reason.options.toxicity',
            value: 'toxicity',
          },
          {
            label: 'questionnaire.questions.mpn.18_treatments.sub_questions.treatment_stop_reason.options.other_medical_conditions',
            value: 'other_medical_conditions',
          },
          {
            label: 'questionnaire.questions.mpn.18_treatments.sub_questions.treatment_stop_reason.options.dosing_issues',
            value: 'dosing_issues',
          },
          {
            label: 'questionnaire.questions.mpn.18_treatments.sub_questions.treatment_stop_reason.options.scheduling',
            value: 'scheduling',
          },
          {
            label: 'questionnaire.questions.mpn.18_treatments.sub_questions.treatment_stop_reason.options.travel_limitation',
            value: 'travel_limitation',
          },
          {
            label: 'questionnaire.questions.mpn.18_treatments.sub_questions.treatment_stop_reason.options.financial_reasons_or_insurance',
            value: 'financial_reasons_or_insurance',
          },
          {
            label: 'questionnaire.questions.mpn.18_treatments.sub_questions.treatment_stop_reason.options.other',
            value: 'other',
          },
        ],
        conditions: [],
      },
    ],
    // shouldAutoOpenSubQuestions: [{
    //   ifId: ID.treatment_for_mpn,
    //   type: EQUAL_TO,
    //   ifValue: 'yes',
    // }],
    conditions: [
      {
        ifId: ID.treatment_for_mpn,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'no',
      },
    ],
    isRequired: true,
    // title: '',
    // info: {
    //   title: '',
    //   content: '',
    // },
    // footer: '',
    // type: TREATMENT_HISTORY,
    // section: SECTIONS.TREATMENT,
    // bgIcon: '',
    // order: 0,
    // subQuestionsIds: [
    //   ID.drugs_received,
    //   ID.treatment_stop_reason,
    // ],
    // options: [],
    // subQuestionsOptions: {
    //   treatment_stop_reason: [
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.no_response',
    //       value: 'no_response',
    //     },
    //     {
    //       label:
    //         'questionnaire.questions.treatment_stop_reason.options.end_of_protocol',
    //       value: 'end_of_protocol',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.toxicity',
    //       value: 'toxicity',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.progression',
    //       value: 'progression',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.relapse',
    //       value: 'relapse',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.remission',
    //       value: 'remission',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.other',
    //       value: 'other',
    //     },
    //   ],
    //   taking_state: takingStateOptions,
    // },
    // conditions: [
    //   {
    //     ifId: ID.treatment_for_mpn,
    //     type: NOT_EQUAL_TO_OR_SKIPPED,
    //     ifValue: 'no',
    //   },
    // ],
  },
  {
    q: 'Q15',
    id: ID.supportive_care_type,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.mpn.19_supportive_care_type.title',
    subtitle: 'questionnaire.questions.mpn.19_supportive_care_type.subtitle',
    info: {
      title: 'questionnaire.questions.mpn.19_supportive_care_type.info.title',
      content: 'questionnaire.questions.mpn.19_supportive_care_type.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.mpn.19_supportive_care_type.options.none',
        value: 'none',
        exclusive: true,
      },
      {
        label: 'questionnaire.questions.mpn.19_supportive_care_type.options.erythropoietin',
        value: 'erythropoietin',
      },
      {
        label: 'questionnaire.questions.mpn.19_supportive_care_type.options.transfusions',
        value: 'transfusions',
      },
      {
        label: 'questionnaire.questions.mpn.19_supportive_care_type.options.deferoxamine_deferasirox',
        value: 'deferoxamine_deferasirox',
      },
      {
        label: 'questionnaire.questions.mpn.19_supportive_care_type.options.platelet_transfusion',
        value: 'platelet_transfusion',
      },
      {
        label: 'questionnaire.questions.mpn.19_supportive_care_type.options.antifibrolytic_agent',
        value: 'antifibrolytic_agent',
      },
      {
        label: 'questionnaire.questions.mpn.19_supportive_care_type.options.rasburicase_allopurinol',
        value: 'rasburicase_allopurinol',
      },
      {
        label: 'questionnaire.questions.mpn.19_supportive_care_type.options.other',
        value: 'other',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q15a',
    id: ID.number_of_blood_transfusions,
    type: NUMBER_INPUT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.mpn.20_number_of_blood_transfusions.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: '',
        value: '',
      },
    ],
    conditions: [
      {
        ifId: ID.supportive_care_type,
        type: CONTAIN_ONE_OF,
        ifValue: ['transfusions'],
      },
    ],
    numberType: numberType.intWithoutUnit,
  },
  {
    q: 'Q15b',
    id: ID.last_blood_transfusion_received_date,
    type: MONTH_PICKER,
    section: SECTIONS.TREATMENT,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.mpn.21_last_blood_transfusion_received_date.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: '',
        value: '',
      },
    ],
    conditions: [
      {
        ifId: ID.supportive_care_type,
        type: CONTAIN_ONE_OF,
        ifValue: ['transfusions'],
      },
    ],
    dateType: {
      type: dateType.stopTreatment,
    },
  },
  {
    q: 'Q16',
    id: ID.current_treatment_systemic_steroids,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.mpn.22_current_treatment_systemic_steroids.title',
    subtitle: 'questionnaire.questions.mpn.22_current_treatment_systemic_steroids.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.mpn.22_current_treatment_systemic_steroids.options.none',
        value: 'none',
      },
      {
        label: 'questionnaire.questions.mpn.22_current_treatment_systemic_steroids.options.under_10_mg_daily',
        value: 'under_10_mg_daily',
      },
      {
        label: 'questionnaire.questions.mpn.22_current_treatment_systemic_steroids.options.over_10_mg_daily',
        value: 'over_10_mg_daily',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q16a',
    id: ID.systemic_steroids_treatment_end_date,
    type: MONTH_PICKER,
    section: SECTIONS.TREATMENT,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.mpn.23_systemic_steroids_treatment_end_date.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: '',
        value: '',
      },
    ],
    conditions: [
      {
        ifId: ID.current_treatment_systemic_steroids,
        type: CONTAIN_ONE_OF,
        ifValue: ['under_10_mg_daily', 'over_10_mg_daily'],
      },
    ],
    dateType: {
      type: dateType.stopTreatment,
    },
  },
  {
    q: 'Q17',
    id: ID.ecog,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.mpn.24_ecog.title',

    info: {
      title: 'questionnaire.questions.mpn.24_ecog.info.title',
      content: 'questionnaire.questions.mpn.24_ecog.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.mpn.24_ecog.options.0',
        value: '0',
      },
      {
        label: 'questionnaire.questions.mpn.24_ecog.options.1',
        value: '1',
      },
      {
        label: 'questionnaire.questions.mpn.24_ecog.options.2',
        value: '2',
      },
      {
        label: 'questionnaire.questions.mpn.24_ecog.options.3',
        value: '3',
      },
      {
        label: 'questionnaire.questions.mpn.24_ecog.options.4',
        value: '4',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q45',
    id: ID.gvhd,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.mpn.25_gvhd.title',

    info: {
      title: 'questionnaire.questions.mpn.25_gvhd.info.title',
      content: 'questionnaire.questions.mpn.25_gvhd.info.content',
    },
    options: [...yesAndNoOptions, notSureOption],
    conditions: [
      {
        ifId: ID.received_allogenic_transplant,
        type: EQUAL_TO,
        ifValue: 'yes',
      },
    ],
  },
  {
    q: 'Q46',
    id: ID.other_medical_condition,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.mpn.26_other_medical_condition.title',
    subtitle: 'questionnaire.questions.mpn.26_other_medical_condition.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.mpn.26_other_medical_condition.options.none',
        value: 'none',
        exclusive: true,
      },
      {
        label: 'questionnaire.questions.mpn.26_other_medical_condition.options.liver',
        value: 'liver',
      },
      {
        label: 'questionnaire.questions.mpn.26_other_medical_condition.options.heart',
        value: 'heart',
      },
      {
        label: 'questionnaire.questions.mpn.26_other_medical_condition.options.kidney',
        value: 'kidney',
      },
      {
        label: 'questionnaire.questions.mpn.26_other_medical_condition.options.autoimmune',
        value: 'autoimmune',
      },
      {
        label: 'questionnaire.questions.mpn.26_other_medical_condition.options.mental',
        value: 'mental',
      },
      {
        label: 'questionnaire.questions.mpn.26_other_medical_condition.options.allergies',
        value: 'allergies',
      },
      {
        label: 'questionnaire.questions.mpn.26_other_medical_condition.options.chronic_infection_or_inflammation',
        value: 'chronic_infection_or_inflammation',
      },
      {
        label: 'questionnaire.questions.mpn.26_other_medical_condition.options.gastrointestinal',
        value: 'gastrointestinal',
      },
      {
        label: 'questionnaire.questions.mpn.26_other_medical_condition.options.other_cancer_type',
        value: 'other_cancer_type',
      },
      {
        label: 'questionnaire.questions.mpn.26_other_medical_condition.options.anemia',
        value: 'anemia',
      },
      {
        label: 'questionnaire.questions.mpn.26_other_medical_condition.options.thyroid',
        value: 'thyroid',
      },
      {
        label: 'questionnaire.questions.mpn.26_other_medical_condition.options.hypertension',
        value: 'hypertension',
      },
      {
        label: 'questionnaire.questions.mpn.26_other_medical_condition.options.epilepsy',
        value: 'epilepsy',
      },
      {
        label: 'questionnaire.questions.mpn.26_other_medical_condition.options.diabetes',
        value: 'diabetes',
      },
      {
        label: 'questionnaire.questions.mpn.26_other_medical_condition.options.organ_transplant',
        value: 'organ_transplant',
      },
      {
        label: 'questionnaire.questions.mpn.26_other_medical_condition.options.hsct',
        value: 'hsct',
      },
      {
        label: 'questionnaire.questions.mpn.26_other_medical_condition.options.extreme_obesity',
        value: 'extreme_obesity',
      },
      {
        label: 'questionnaire.questions.mpn.26_other_medical_condition.options.stroke',
        value: 'stroke',
      },
      {
        label: 'questionnaire.questions.mpn.26_other_medical_condition.options.lung',
        value: 'lung',
      },
      {
        label: 'questionnaire.questions.mpn.26_other_medical_condition.options.neuropathy',
        value: 'neuropathy',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q48',
    id: ID.omc_liver,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.mpn.27_omc_liver.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.mpn.27_omc_liver.options.elevated_alt_ast',
        value: 'elevated_alt_ast',
      },
      {
        label: 'questionnaire.questions.mpn.27_omc_liver.options.elevated_bilirubin',
        value: 'elevated_bilirubin',
      },
      {
        label: 'questionnaire.questions.mpn.27_omc_liver.options.cirrhosis',
        value: 'cirrhosis',
      },
      {
        label: 'questionnaire.questions.mpn.27_omc_liver.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['liver'],
      },
    ],
  },
  {
    q: 'Q49',
    id: ID.omc_heart,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.mpn.28_omc_heart.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.mpn.28_omc_heart.options.class_at_least_nyha_ii',
        value: 'class_ii',
      },
      {
        label: 'questionnaire.questions.mpn.28_omc_heart.options.condition_at_least_grade_2',
        value: 'condition_at_least_grade_2',
      },
      {
        label: 'questionnaire.questions.mpn.28_omc_heart.options.cardiac_ventricular_arrhythmia',
        value: 'cardiac_ventricular_arrhythmia',
      },
      {
        label: 'questionnaire.questions.mpn.28_omc_heart.options.heart_failure',
        value: 'heart_failure',
      },
      {
        label: 'questionnaire.questions.mpn.28_omc_heart.options.lvef_under_50_percent',
        value: 'lvef_under_50_percent',
      },
      {
        label: 'questionnaire.questions.mpn.28_omc_heart.options.qtcf_abnormal',
        value: 'qtcf_abnormal',
      },
      {
        label: 'questionnaire.questions.mpn.28_omc_heart.options.angina',
        value: 'angina',
      },
      {
        label: 'questionnaire.questions.mpn.28_omc_heart.options.myocardial_infarction_less_than_6_months',
        value: 'myocardial_infarction_less_than_6_months',
      },
      {
        label: 'questionnaire.questions.mpn.28_omc_heart.options.myocardial_infarction_more_than_6_months',
        value: 'myocardial_infarction_more_than_6_months',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['heart'],
      },
    ],
  },
  {
    q: 'Q50',
    id: ID.omc_stroke_date,
    type: MONTH_PICKER,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.mpn.29_omc_stroke_date.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: '',
        value: '',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['stroke'],
      },
    ],
    dateType: {
      type: dateType.stopTreatment,
    },
  },
  {
    q: 'Q51',
    id: ID.omc_kidney,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.mpn.30_omc_kidney.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.mpn.30_omc_kidney.options.low_glomerular_filtration_rate',
        value: 'low_glomerular_filtration_rate',
      },
      {
        label: 'questionnaire.questions.mpn.30_omc_kidney.options.dialysis',
        value: 'dialysis',
      },
      {
        label: 'questionnaire.questions.mpn.30_omc_kidney.options.kidney_stones',
        value: 'kidney_stones',
      },
      {
        label: 'questionnaire.questions.mpn.30_omc_kidney.options.creatinine_high',
        value: 'creatinine_high',
      },
      {
        label: 'questionnaire.questions.mpn.30_omc_kidney.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['kidney'],
      },
    ],
  },
  {
    q: 'Q52',
    id: ID.omc_autoimmune,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.mpn.31_omc_autoimmune.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.mpn.31_omc_autoimmune.options.congenital_or_acquired_immunosuppression',
        value: 'congenital_or_acquired_immunosuppression',
      },
      {
        label: 'questionnaire.questions.mpn.31_omc_autoimmune.options.hepatitis',
        value: 'hepatitis',
      },
      {
        label: 'questionnaire.questions.mpn.31_omc_autoimmune.options.granulomatous_disease',
        value: 'granulomatous_disease',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['autoimmune'],
      },
    ],
  },
  {
    q: 'Q53',
    id: ID.omc_mental,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.mpn.32_omc_mental.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.mpn.32_omc_mental.options.dementia',
        value: 'dementia',
      },
      {
        label: 'questionnaire.questions.mpn.32_omc_mental.options.neuropsychyatric',
        value: 'neuropsychyatric',
      },
      {
        label: 'questionnaire.questions.mpn.32_omc_mental.options.depression',
        value: 'depression',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['mental'],
      },
    ],
  },
  {
    q: 'Q54',
    id: ID.omc_allergy,
    type: MULTIPLE_DROP_DOWN_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.mpn.33_omc_allergy.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.mpn.33_omc_allergy.options.interferon',
        value: 'interferon',
      },
      {
        label: 'questionnaire.questions.mpn.33_omc_allergy.options.metformin',
        value: 'metformin',
      },
      {
        label: 'questionnaire.questions.mpn.33_omc_allergy.options.vitd',
        value: 'vitd',
      },
      {
        label: 'questionnaire.questions.mpn.33_omc_allergy.options.penicillin',
        value: 'penicillin',
      },
      {
        label: 'questionnaire.questions.mpn.33_omc_allergy.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['allergies'],
      },
    ],
  },
  {
    q: 'Q55',
    id: ID.omc_chronic_infection_inflammation,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.mpn.34_omc_chronic_infection_inflammation.title',
    subtitle: '',
    // 'questionnaire.questions.mpn.34_omc_chronic_infection_inflammation.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.mpn.34_omc_chronic_infection_inflammation.options.hepatitis',
        value: 'hepatitis',
      },
      {
        label: 'questionnaire.questions.mpn.34_omc_chronic_infection_inflammation.options.hiv',
        value: 'hiv',
      },
      {
        label: 'questionnaire.questions.mpn.34_omc_chronic_infection_inflammation.options.other',
        value: 'other',
      },
      {
        label: 'questionnaire.questions.mpn.34_omc_chronic_infection_inflammation.options.uncontrolled_infection',
        value: 'uncontrolled_infection',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['chronic_infection_or_inflammation'],
      },
    ],
  },
  {
    q: 'Q58',
    id: ID.omc_gastro_problem,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.mpn.35_omc_gastro_problem.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.mpn.35_omc_gastro_problem.options.gastrointestinal_bleeding',
        value: 'gastrointestinal_bleeding',
      },
      {
        label: 'questionnaire.questions.mpn.35_omc_gastro_problem.options.crohns',
        value: 'crohns',
      },
      {
        label: 'questionnaire.questions.mpn.35_omc_gastro_problem.options.colitis',
        value: 'colitis',
      },
      {
        label: 'questionnaire.questions.mpn.35_omc_gastro_problem.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['gastrointestinal'],
      },
    ],
  },
  {
    q: 'Q59',
    id: ID.omc_lung_disease,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.mpn.36_omc_lung_disease.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.mpn.36_omc_lung_disease.options.interstitial_lung_disease',
        value: 'interstitial_lung_disease',
      },
      {
        label: 'questionnaire.questions.mpn.36_omc_lung_disease.options.pneumonitis',
        value: 'pneumonitis',
      },
      {
        label: 'questionnaire.questions.mpn.36_omc_lung_disease.options.dyspnea',
        value: 'dyspnea',
      },
      {
        label: 'questionnaire.questions.mpn.36_omc_lung_disease.options.copd',
        value: 'copd',
      },
      {
        label: 'questionnaire.questions.mpn.36_omc_lung_disease.options.tuberculosis',
        value: 'tuberculosis',
      },
      {
        label: 'questionnaire.questions.mpn.36_omc_lung_disease.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['lung'],
      },
    ],
  },
  {
    q: 'Q56',
    id: ID.omc_abnormal_blood_results,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.mpn.37_omc_abnormal_blood_results.title',
    subtitle: 'questionnaire.questions.mpn.37_omc_abnormal_blood_results.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.mpn.37_omc_abnormal_blood_results.options.none',
        value: 'none',
        exclusive: true,
      },
      {
        label: 'questionnaire.questions.mpn.37_omc_abnormal_blood_results.options.low_wbc',
        value: 'low_wbc',
      },
      {
        label: 'questionnaire.questions.mpn.37_omc_abnormal_blood_results.options.low_hemoglobin',
        value: 'low_hemoglobin',
      },
      {
        label: 'questionnaire.questions.mpn.37_omc_abnormal_blood_results.options.low_neutrophil_count',
        value: 'low_neutrophil_count',
      },
      {
        label: 'questionnaire.questions.mpn.37_omc_abnormal_blood_results.options.low_platelet_count',
        value: 'low_platelet_count',
      },
      {
        label: 'questionnaire.questions.mpn.37_omc_abnormal_blood_results.options.low_potassium',
        value: 'low_potassium',
      },
      {
        label: 'questionnaire.questions.mpn.37_omc_abnormal_blood_results.options.low_magnesium',
        value: 'low_magnesium',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q47',
    id: ID.other_medications,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.mpn.38_other_medications.title',
    subtitle: 'questionnaire.questions.mpn.38_other_medications.subtitle',
    info: {
      title: 'questionnaire.questions.mpn.38_other_medications.info.title',
      content: 'questionnaire.questions.mpn.38_other_medications.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.mpn.38_other_medications.options.cyp3a4_inhibitors',
        value: 'cyp3a4_inhibitors',
      },
      {
        label: 'questionnaire.questions.mpn.38_other_medications.options.cyp3a4_inducers',
        value: 'cyp3a4_inducers',
      },
      {
        label: 'questionnaire.questions.mpn.38_other_medications.options.immunosuppressive_medications',
        value: 'immunosuppressive_medications',
      },
      {
        label: 'questionnaire.questions.mpn.38_other_medications.options.anticonvulsants',
        value: 'anticonvulsants',
      },
      {
        label: 'questionnaire.questions.mpn.38_other_medications.options.none',
        value: 'none',
        exclusive: true,
      },
    ],
    conditions: [],
  },
  initialDiagnosisDate('Q3'),
  userType,
  {
    q: 'Q1',
    id: ID.dob,
    type: MONTH_PICKER,
    section: SECTIONS.ABOUT_ME,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.mpn.0_dob.title',
    info: {
      title: '',
      content: '',
    },
    conditions: [],
    dateType: {
      type: dateType.dob,
    },
  },
  {
    q: 'Q2',
    id: ID.gender,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.ABOUT_ME,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.mpn.1_gender.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.mpn.1_gender.options.female',
        value: 'female',
      },
      {
        label: 'questionnaire.questions.mpn.1_gender.options.male',
        value: 'male',
      },
      {
        label: 'questionnaire.questions.global.gender.options.other',
        value: 'other',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q3',
    id: ID.is_pregnant,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.ABOUT_ME,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.mpn.39_is_pregnant.title',

    info: {
      title: 'questionnaire.questions.mpn.39_is_pregnant.info.title',
      content: 'questionnaire.questions.mpn.39_is_pregnant.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.mpn.39_is_pregnant.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.mpn.39_is_pregnant.options.no',
        value: 'no',
      },
    ],
    conditions: [
      {
        ifId: ID.gender,
        type: CONTAIN_ONE_OF,
        ifValue: ['female'],
      },
      {
        type: AGE_LESS_THAN,
        ifValue: 50,
      },
    ],
  },
  {
    q: 'Q14',
    id: ID.is_breastfeeding,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.ABOUT_ME,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.mpn.40_is_breastfeeding.title',

    info: {
      title: 'questionnaire.questions.mpn.40_is_breastfeeding.info.title',
      content: 'questionnaire.questions.mpn.40_is_breastfeeding.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.mpn.40_is_breastfeeding.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.mpn.40_is_breastfeeding.options.no',
        value: 'no',
      },
    ],
    conditions: [
      {
        ifId: ID.gender,
        type: CONTAIN_ONE_OF,
        ifValue: ['female'],
      },
      {
        type: AGE_LESS_THAN,
        ifValue: 50,
      },
    ],
  },
  {
    q: 'Q03',
    id: GLOBAL_ENUMS.race,
    type: MULTIPLE_OPTION_SELECT,
    bgIcon: i.medical_profile,
    section: SECTIONS.ABOUT_ME,
    order: 0,
    title: 'questionnaire.questions.global.race.title',
    subtitle: 'questionnaire.questions.global.race.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.global.race.options.native_american',
        value: 'native_american',
      },
      {
        label: 'questionnaire.questions.global.race.options.asian',
        value: 'asian',
      },
      {
        label: 'questionnaire.questions.global.race.options.african_american',
        value: 'african_american',
      },
      {
        label: 'questionnaire.questions.global.race.options.hispanic_latino',
        value: 'hispanic_latino',
      },
      {
        label: 'questionnaire.questions.global.race.options.pacific_islander',
        value: 'pacific_islander',
      },
      {
        label: 'questionnaire.questions.global.race.options.white',
        value: 'white',
      },
      {
        label: 'questionnaire.questions.global.race.options.other',
        value: 'other',
      },
      {
        label: 'questionnaire.questions.global.race.options.decline_to_answer',
        value: 'decline_to_answer',
        exclusive: true,
      },
    ],
    conditions: [],
  },
  haveInsurance,
  insurance,
  preferredLocation,
  travelLimit,
];

export default Questions;
