import { REDIRECT_TO_ACTIONS } from '../../components/AppRoutes/constants';

const setAfter = ({ eventName, routePath }) => ({
  type: REDIRECT_TO_ACTIONS.SET_AFTER,
  payload: {
    eventName,
    routePath
  }
});

const resetAfter = eventName => ({
  type: REDIRECT_TO_ACTIONS.RESET_AFTER,
  payload: eventName
});

export const redirectToActions = {
  setAfter,
  resetAfter
};
