import React from 'react';
import Button from 'new-ui/Components/Button';
import PropTypes from 'prop-types';
import ebookImage from '../assets/images/ebook.svg';
import './EbookDownload.css';

const EbookDownload = () => {
  const openPDF = () => {
    window.open('/assets/pdf/ngs.pdf', '_blank');
  };

  return (
    <div className="ebook-download">
      <h3>The Importance of NGS Testing</h3>
      <div className="ebook-download-section">
        <img src={ebookImage} alt="ebook" />
        <div>
          <p className="subtitle">What is It? Who Needs It? And Why!</p>
          <p className="paragraph"> Developed for cancer patients who want to understand their genetic make-up and how it can impact their cancer treatment.</p>
          <div>
            <Button
              className="download-button"
              backgroundColor="#fff"
              title="Free Ebook Download"
              color="#FF8C69"
              action={openPDF}
              width="unset"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

EbookDownload.propTypes = {
  isUploadPage: PropTypes.bool,
};

export default EbookDownload;
