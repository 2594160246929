import * as React from "react";

const DoctorIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="113" height="120" {...props} viewBox="0 0 113 120" fill="none">
    <g clipPath="url(#clip0_23667_19845)">
      <path d="M78.0138 28.1868C70.6044 27.2068 66.7626 22.8553 65.4689 21.0519C64.0576 19.0918 63.4303 17.1316 63.7047 15.6811C56.8442 18.8958 40.8887 20.1111 34.8906 19.9934V16.0732C43.6329 16.1908 59.7845 14.1914 63.1951 11.4864C63.9792 10.8592 65.0768 10.82 65.9393 11.3296C67.3506 12.1921 67.9778 14.2698 67.5466 16.4652C67.5074 17.6805 70.8789 23.2865 78.5234 24.3058L78.0138 28.1868Z" fill="#213332"/>
      <path d="M56.1388 62.8422C43.5154 62.8422 34.2243 50.9637 32.4602 32.5776C31.3233 20.8951 33.2835 12.2313 38.223 6.74289C42.3001 2.27377 48.3374 0 56.1388 0C63.9401 0 70.2518 2.31297 74.3289 6.8605C79.2684 12.3489 81.111 21.0127 79.8565 32.5776C77.8179 51.2382 68.7229 62.8422 56.178 62.8422H56.1388ZM56.1388 3.92029C49.4351 3.92029 44.3779 5.76282 41.1241 9.36949C36.9293 13.9954 35.322 21.64 36.3805 32.1856C37.9486 48.4156 45.75 58.9219 56.178 58.9219C66.6059 58.9219 74.1721 48.6508 75.9754 32.1464C77.1123 21.7576 75.5834 14.113 71.4279 9.4871C68.1348 5.80203 62.9993 3.92029 56.178 3.92029H56.1388Z" fill="#213332"/>
      <path d="M56.1385 120C54.9232 120 53.904 119.177 53.6295 118.001L40.771 63.4696V54.4921H44.6913V63.2343L56.1777 111.689L68.2522 62.8423L67.8994 54.0609L71.8197 53.9041L72.1725 63.2343L58.6083 118.001C58.3339 119.138 57.3146 119.961 56.0993 119.961L56.1385 120Z" fill="#213332"/>
      <path d="M56.4912 82.8356C50.4932 82.8356 45.0048 78.9937 43.123 73.5445L46.8473 72.29C48.1802 76.2103 52.1789 78.9545 56.5304 78.9545C60.8819 78.9545 64.8022 76.0143 66.0959 71.8196L69.8202 72.9957C68.0169 78.7977 62.5285 82.8748 56.4912 82.8748V82.8356Z" fill="#213332"/>
      <path d="M36.8505 80.3658L32.9302 80.1306L33.7142 66.5272C33.7142 66.0176 33.9887 65.508 34.3807 65.1551L41.4372 58.9219L44.0246 61.8621L37.5953 67.5465L36.8505 80.3658Z" fill="#213332"/>
      <path d="M75.4261 80.3659L74.6813 67.5466L68.9185 62.4502L71.5058 59.51L77.8567 65.116C78.2487 65.4688 78.484 65.9393 78.5232 66.4881L79.3072 80.0915L75.3869 80.3267L75.4261 80.3659Z" fill="#213332"/>
      <path d="M3.92029 117.961H0V89.5002C0.784057 82.9533 4.93956 77.5825 10.8592 75.2696L34.9298 65.7825L36.3803 69.4283L12.3097 78.9154C7.72297 80.7188 4.54753 84.8743 3.92029 89.7746V118.001V117.961Z" fill="#213332"/>
      <path d="M112.512 118.04H108.592V89.461C108.004 84.8351 104.789 80.6796 100.242 78.8371L76.1714 69.35L77.6219 65.7041L101.692 75.1912C107.612 77.5042 111.768 82.875 112.512 89.2258V118.001V118.04Z" fill="#213332"/>
      <path d="M23.6002 87.9713C22.3065 74.9167 33.3225 67.4682 33.7929 67.1545L35.9491 70.4084C35.5571 70.6828 26.4228 76.9161 27.4813 87.5792L23.561 87.9713H23.6002Z" fill="#213332"/>
      <path d="M30.735 117.491H28.7357V113.571H30.735C31.6759 113.571 32.5384 113.14 33.1656 112.434C33.7929 111.728 34.0281 110.787 33.8321 109.847L30.9702 94.5182C30.735 91.7348 28.5397 89.7355 25.9915 89.7355C23.4433 89.7355 21.2087 91.7348 21.0127 94.3222L18.1509 109.886C17.9941 110.787 18.2293 111.728 18.8174 112.434C19.4054 113.14 20.2679 113.571 21.2087 113.571H23.4041V117.491H21.2087C19.131 117.491 17.1316 116.55 15.7987 114.943C14.4658 113.336 13.917 111.219 14.309 109.18L17.1708 93.8126C17.5237 89.4219 21.4047 85.8152 26.0307 85.8152C30.6566 85.8152 34.5377 89.4219 34.9297 94.0086L37.7523 109.141C38.1444 111.219 37.5955 113.336 36.2234 114.943C34.8905 116.55 32.8912 117.491 30.8134 117.491H30.735Z" fill="#213332"/>
      <path d="M87.3046 101.339H83.3844V85.8543C83.3844 82.6396 82.5219 79.4642 80.9146 76.72L76.6807 69.5067L80.0521 67.5073L84.286 74.7207C86.2462 78.0921 87.3046 81.934 87.3046 85.8543V101.339Z" fill="#213332"/>
      <path d="M85.3445 116.511C80.4049 116.511 76.4062 112.512 76.4062 107.573C76.4062 102.633 80.4049 98.6345 85.3445 98.6345C90.2841 98.6345 94.2828 102.633 94.2828 107.573C94.2828 112.512 90.2841 116.511 85.3445 116.511ZM85.3445 102.555C82.5611 102.555 80.3265 104.829 80.3265 107.573C80.3265 110.317 82.5611 112.591 85.3445 112.591C88.1279 112.591 90.3625 110.356 90.3625 107.573C90.3625 104.789 88.1279 102.555 85.3445 102.555Z" fill="#213332"/>
    </g>
    <defs>
      <clipPath id="clip0_23667_19845">
        <rect width="113" height="120" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);
export default DoctorIcon;
