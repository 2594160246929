import React, { useState, useEffect } from 'react';
import './MultiPages.css';

import Pager from 'new-ui/Components/Pager';
import { useTranslation } from 'utils/modifiedTranslation';

import PropTypes from 'prop-types';
import Button from 'new-ui/Components/Button';
import { THEMES } from 'new-ui/constants';
import editIcon from 'new-ui/assets/icons/edit_2.svg';
import deleteIcon from 'new-ui/assets/icons/delete_icon.svg';
import moment from 'moment';
import { CLIENT_IM_NOT_SURE } from 'new-ui/Components/Intake';
import { procedureForIndicationQuestionIds, treatmentForIndicationQuestionIds } from 'components/Questionnaire/constants/treatmentForIndicationQuestionIds';
import QuestionRenderer, { QUESTION_TYPES } from '../..';
import { BulletButton } from '../SingleOptionSelect';

export const MULTI_PAGES_QUESTIONS = {
  TREATMENTS: 'treatments',
  PROCEDURES: 'procedures',
};

export const ALL_MULTI_PAGES_QUESTIONS = [
  MULTI_PAGES_QUESTIONS.TREATMENTS,
  MULTI_PAGES_QUESTIONS.PROCEDURES,
];

export const MULTI_PAGES_SUBQUESTIONS = {
  [MULTI_PAGES_QUESTIONS.TREATMENTS]: {
    DRUGS_RECEIVED: 'drugs_received',
    TREATMENT_ADMIN: 'treatment_admin',
    TREATMENT_END_DATE: 'treatment_end_date',
    TREATMENT_STOP_REASON: 'treatment_stop_reason',
  },
  [MULTI_PAGES_QUESTIONS.PROCEDURES]: {
    PROCEDURES_RECEIVED: 'procedures_received',
    PROCEDURE_DATE: 'procedure_date',
    PROCEDURE_OUTCOME: 'procedure_outcome',
  },
};

export const MULTI_PAGES_QUESTIONS_TITLE = {
  [MULTI_PAGES_QUESTIONS.TREATMENTS]: MULTI_PAGES_SUBQUESTIONS[MULTI_PAGES_QUESTIONS.TREATMENTS].DRUGS_RECEIVED,
  [MULTI_PAGES_QUESTIONS.PROCEDURES]: MULTI_PAGES_SUBQUESTIONS[MULTI_PAGES_QUESTIONS.PROCEDURES].PROCEDURES_RECEIVED,
};

const MULTI_PAGES_QUESTIONS_ADD_BUTTON = {
  [MULTI_PAGES_QUESTIONS.TREATMENTS]: 'add_another_treatment',
  [MULTI_PAGES_QUESTIONS.PROCEDURES]: 'add_another_procedure',
};

const MULTI_PAGES_DID_NOT_RECEIVE = {
  [MULTI_PAGES_QUESTIONS.TREATMENTS]: 'did_not_receive_treatment',
  [MULTI_PAGES_QUESTIONS.PROCEDURES]: 'did_not_receive_procedure',
};

export const getSubquestion = (q, id)=> q.subQuestions.find((a)=>a.id === id);

export const getPropertyTitle = (item, id, q, t = window.t)=>{
  // console.log('getPropertyTitle item', item);
  // console.log('getPropertyTitle id', id);
  // console.log('getPropertyTitle q', q);
  const subQuestion = getSubquestion(q, id);
  const value = item[id];
  // console.log('getPropertyTitle subQuestion', subQuestion);
  // console.log('getPropertyTitle value', value);
  switch (subQuestion.type) {
    default:
    case QUESTION_TYPES.MULTIPLE_OPTION_SEARCH_SELECT:
    case QUESTION_TYPES.MULTIPLE_OPTION_SELECT:
    case QUESTION_TYPES.SINGLE_OPTION_SELECT:
      return Array.isArray(value) ? value.map(
        (option)=>t(subQuestion.options.filter((o)=>o.value === option)[0]?.label),
      ).join(', ') : t(
        subQuestion.options.filter((o)=>o.value === value)[0]?.label,
      );
    case QUESTION_TYPES.MONTH_PICKER:
      return value ? `Received last ${moment(new Date(value)).format('MM/YYYY')}` : '';
  }
};

const MultiPages = (props) => {
  const {
    q,
    user,
    onselect,
  } = props;
  const { t } = useTranslation();

  let page = 0;

  const pager = {};

  // console.log('MultiPages', q, user);

  const [items, setItems] = useState(user?.condition_profile[q.id] ? [...user?.condition_profile[q.id]] : []);
  const [item, setItem] = useState(null);

  const TITLE_QUESTION = MULTI_PAGES_QUESTIONS_TITLE[q.id];
  const ADD_BUTTON_TEXT = MULTI_PAGES_QUESTIONS_ADD_BUTTON[q.id];
  const DID_NOT_RECEIVE_TEXT = MULTI_PAGES_DID_NOT_RECEIVE[q.id];
  const QUESTIONS = Object.keys(MULTI_PAGES_SUBQUESTIONS[q.id]).map((a)=>MULTI_PAGES_SUBQUESTIONS[q.id][a]);

  window.__items = items;

  useEffect(() => {
    if (!items.length) createItem();
    // eslint-disable-next-line
  }, []);

  useEffect(()=>{
    if (!items.length) createItem();
    // eslint-disable-next-line
  }, [items]);

  const createItem = () => {
    const item = {};
    items.push(item);
    item.id = items.length;
    QUESTIONS.forEach((q)=>item[q] = null);
    setItems([...items]);
    setItem(items.length - 1);
  };

  pager.onSetPage = (p) => { page = p; };

  const renderBullet = (item, id)=>{
    return item[id]
      ? <li key={id}>{ getPropertyTitle(item, id, q, t)}</li>
      : null;
  };

  const Chooser = () => {
    return (
      <div className="multi-pages-selector">
        { items.map((item, itemId) => (
          <div key={itemId} className="multi-pages-selector-treatment">
            <div className="multi-pages-selector-header">
              <div className="multi-pages-selector-title">{ getPropertyTitle(item, TITLE_QUESTION, q) }</div>
            </div>
            <div className="multi-pages-selector-body"><ul>{ QUESTIONS.filter((a)=>a !== TITLE_QUESTION).map((id)=>renderBullet(item, id)) }</ul></div>
            <div className="multi-pages-selector-item-menu">
              <Button icon={editIcon} title="Edit" action={() => { setItem(itemId); }} theme={THEMES.SMALL_GREY} />
              <Button
                icon={deleteIcon}
                title="Delete"
                action={
                  ()=>{
                    const _items = [...items];
                    _items.splice(itemId, 1);
                    onselect(_items, true);
                    setItems(_items);
                  }
                }
                theme={THEMES.SMALL_GREY}
              />
            </div>
          </div>
        ))}
        <div className="multi-pages-selector-menu flex">
          <Button action={createItem} title={t(`intake.${ADD_BUTTON_TEXT}`)} theme={THEMES.ORANGE} />
          <Button action={()=>{ onselect(items); }} title={t('general.done')} />
        </div>
      </div>
    );
  };

  const Editor = () => {
    const subQuestions = q.subQuestions.filter((a) => QUESTIONS.includes(a.id));
    const pages = [];
    page = 1;
    const getQuestion = (subQuestion)=>{
      return (
        <QuestionRenderer
          q={subQuestion}
          initValue={items[item][subQuestion.id]}
          showTitle
          onselect={(v) => {
            const newItem = {
              ...items[item],
              [subQuestion.id]: v,
            };
            items[item] = newItem;
            const _items = [...items];
            setItems(_items);
            if (page === subQuestions.length) {
              onselect(_items, true);
              setItem(null);
              return;
            }
            page++;
            pager.gotoPage(page);
            onselect(_items, true, true);
          }}
        />
      );
    };
    subQuestions.forEach((subQuestion, idx) => {
      const question = getQuestion(subQuestion);
      pages.push(
        items.length === 1 && !idx ? (
          <div>
            {question}
            <div className="multi-pages-submenu">
              <BulletButton
                label={t(`intake.${DID_NOT_RECEIVE_TEXT}`)}
                action={
                  ()=>{
                    console.log(1, q);
                    const data = q.id === MULTI_PAGES_QUESTIONS.TREATMENTS ? treatmentForIndicationQuestionIds : procedureForIndicationQuestionIds;
                    const forQuestion = data[user.personal.condition];
                    if (forQuestion) user.condition_profile[forQuestion] = 'no';
                    onselect(null, false, false, [forQuestion]);
                  }
                }
              />
              <BulletButton
                label={t('intake.im_not_sure')}
                action={
                  ()=>{
                    onselect(CLIENT_IM_NOT_SURE);
                  }
                }
              />
            </div>
          </div>
        ) : question,
      );
    });
    // console.log('subQuestions', subQuestions);
    return (
      <Pager
        pages={pages}
        controller={pager}
        mandatory={[1]}
        showArrows
        lastPageAction={()=>{
          setItem(null);
        }}
      />
    );
  };

  const render = () => {
    if (item !== null) return Editor();
    return Chooser();
  };

  return (
    <div className="multi-pages">
      {render()}
    </div>
  );
};

MultiPages.propTypes = {
  q: PropTypes.any,
  user: PropTypes.any,
  onselect: PropTypes.func,
};

export default MultiPages;
