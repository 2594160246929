/* eslint-disable arrow-parens */
import React, { useState } from 'react';
import { useTranslation } from 'utils/modifiedTranslation';
import { authenticationActions } from 'components/Authentication/actions';
import { useDispatch } from 'react-redux';
import { GA_GENERAL_CATEGORY, GA_GENERAL_POPUPS_EVENTS } from 'new-ui/Components/GoogleAnalytics/generalEvents';
import './PhoneNumber.css';

import { PopupTitle, PopupContent, PopupButton } from 'new-ui/Components/Popup';

import PropTypes from 'prop-types';
import Form, { FORM_INPUT_TYPES } from 'new-ui/Components/Form';
import app from 'new-ui/app';
import phone from 'phone';

const PhoneNumber = props => {
  const { closePopup } = props;
  const { user } = app;
  const [updateUser] = useState(user);
  const [inputLength, setInputLength] = useState(0);
  const [userPhone, setUserPhone] = useState('');
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const formChange = newData => {
    if (!newData.phone.startsWith('+')) {
      newData.phone = `+${newData.phone}`;
    }
    form.data.phone = newData.phone;
    setUserPhone(newData.phone);
    setInputLength(newData.phone.length);
  };

  const form = {
    inputs: {
      phone: {
        type: FORM_INPUT_TYPES.PHONE,
        placeholder: 'Your Phone',
      },
    },
    data: { phone: app.user?.info?.phone },
  };

  const save = async () => {
    if (updateUser.info?.phone && !updateUser.info?.phone.startsWith('+')) {
      updateUser.info.phone = `+${userPhone}`;
    }
    updateUser.info.phone = phone(userPhone).phoneNumber;
    app.setShowPreloader(true);
    app.sendGoogleAnalyticsEvent(GA_GENERAL_CATEGORY, GA_GENERAL_POPUPS_EVENTS.PHONE_NUBMER_UPDATE);
    await dispatch(authenticationActions.updateUser(updateUser, true, app.user)); // user should be returned here currently without missing_critical
    await app.update({ rematch: true });
    app.intercom.updateUserAttributes({ phone: userPhone });
    app.sendGoogleAnalyticsEvent(GA_GENERAL_CATEGORY, GA_GENERAL_POPUPS_EVENTS.PHONE_NUBMER_UPDATE_SUCCESS);
    closePopup();
    app.setShowPreloader(false);
    app.renderPage();
  };

  return (
    <div className="edit-phone">
      <PopupTitle title={t(`${updateUser.info.phone ? 'phone_popup.title.update' : 'phone_popup.title.add'}`)} />
      <PopupContent>
        <div>
          <Form form={form} onChange={formChange} />
          <PopupButton title={t(`${updateUser.info.phone ? 'phone_popup.btn.update' : 'phone_popup.btn.add'}`)} action={save} disabled={inputLength < 10} />
        </div>
      </PopupContent>
      <PopupContent />
    </div>
  );
};

PhoneNumber.propTypes = {
  closePopup: PropTypes.func,
};
export default PhoneNumber;
