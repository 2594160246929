import configs from 'configs';
import { authHeaders } from 'functions/authHeader';
import request from 'functions/api/request';

export const getProfileTitles = async (condition) => {
  const requestOptions = {
    url: `${configs.beApiUrl}/public/profileTitles/${condition}`,
    headers: {
      ...await authHeaders(),
    },
  };

  try {
    const r = await request.get(requestOptions);
    return r;
  } catch (e) {
    console.log('upload file error', e);
  }
};

window.__getProfileTitles = getProfileTitles;
