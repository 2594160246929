import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Navigation, Pagination, A11y, EffectCube,
} from 'swiper';
import 'swiper/swiper-bundle.min.css';
import { useTranslation } from 'utils/modifiedTranslation';
import Avatar1 from './images/1.svg';
import Avatar2 from './images/2.svg';
import Avatar3 from './images/3.svg';
import IsolationImg from './images/Isolation_Mode.svg';
import './Slider.css';

export const Slider = () => {
  const { t } = useTranslation();

  const comments = [{
    name: t('upload.comments.slide1.name'),
    condition: t('upload.comments.slide1.condition'),
    comment: t('upload.comments.slide1.comment'),
    avatar: Avatar1,
  }, {
    name: t('upload.comments.slide2.name'),
    condition: t('upload.comments.slide2.condition'),
    comment: t('upload.comments.slide2.comment'),
    avatar: Avatar2,
  }, {
    name: t('upload.comments.slide3.name'),
    condition: t('upload.comments.slide3.condition'),
    comment: t('upload.comments.slide3.comment'),
    avatar: Avatar3,
  }];

  return (
    <div className="slider-wrapper">
      <div className="slider-image-block">
        <img src={IsolationImg} alt="Isolation" />
      </div>
      <div className="swiper-block">
        <Swiper
          modules={[Navigation, Pagination, A11y, EffectCube]}
          spaceBetween={50}
          slidesPerView={3}
          navigation
          pagination={{ clickable: true }}
          effect="cube"
        >
          {comments.map((comment, key)=>{
            return (
              <SwiperSlide key={key}>
                <div><img src={comment.avatar} alt="avatar" /></div>
                <div className="name">{comment.name}</div>
                <div className="condition">{comment.condition}</div>
                <div className="comment">{comment.comment}</div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};
