import React, { useState } from 'react';
import './MonthPicker.css';

import PropTypes from 'prop-types';
import DateInput from 'new-ui/Components/Form/Date';
import moment from 'moment';
import Button from 'new-ui/Components/Button';
import { useTranslation } from 'utils/modifiedTranslation';
import { QuestionTitle } from '../..';

const MonthPicker = (props) => {
  const {
    q,
    onselect,
    showTitle = false,
    initValue = null,
    autoChange = false,
  } = props;
  const [value, setValue] = useState(initValue);
  const { t } = useTranslation();
  return (
    <div className="month-picker">
      { showTitle ? <QuestionTitle q={q} /> : null}
      <DateInput
        value={value}
        inlineMode
        floatMode
        placeholder="MM/YYYY"
        hideOnSelect
        formatter={(date) => moment(date).format('MM/YYYY')}
        valueFormatter={(date) => date.toISOString()}
        onChange={(date) => {
          setValue(date);
        }}
      />
      {
        value && !autoChange ? (
          <Button title={t('general.save')} action={() => { onselect(value); }} />
        ) : null
      }
    </div>
  );
};

MonthPicker.propTypes = {
  q: PropTypes.any,
  user: PropTypes.any,
  onselect: PropTypes.func,
  initValue: PropTypes.any,
  showTitle: PropTypes.bool,
  autoChange: PropTypes.bool,
};

export default MonthPicker;
