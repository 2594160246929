import React from 'react';
import PropTypes from 'prop-types';
import './TextWidget.css';

const TextWidget = (props) => {
  const { text } = props;
  return (
    <div className="text-widget">
      {text}
    </div>
  );
};

TextWidget.propTypes = {
  text: PropTypes.string,
};

export default TextWidget;
