/* eslint-disable import/no-cycle */
import { batch } from 'react-redux';
import omit from 'lodash/omit';
import {
  PRISTINE, REMOVING, UPLOADING, UPLOADED,
} from 'utils/constants/fileStatuses';
import { BIOMARKERS_DOCUMENT_SOURCE } from 'modules/userDocuments/constants';
import {
  uploadUserDocuments,
  removeUserDocuments,
} from 'modules/userDocuments/api';
import { BACKDROP_TYPES } from 'modules/backdrop/constants';
import { showBackdrop, hideBackdrop } from 'modules/backdrop/actions';
import { checkIsAuthenticated } from 'functions/checkIsAuthenticated';
import { authenticationActions } from 'components/Authentication/actions';
import REDUX_ACTIONS from './constants/reduxActions';
import GLOBAL_ENUMS from './constants/globalEnums';
import { conditionProfileValueToFormValue } from './functions/conditionProfileValueToFormValue';
import { mergeFormQuestionValues } from './functions/mergeFormQuestionValues';
import { mapFormToConditionProfile } from './functions/mapFormToConditionProfile';
import { indicationChooser } from './functions';

const { biomarkers: BIOMARKERS_QUESTION_ID } = GLOBAL_ENUMS;
const {
  QUESTIONNAIRE_SET_FILES,
  QUESTIONNAIRE_ADD_FILES,
  QUESTIONNAIRE_REMOVE_FILES,
  QUESTIONNAIRE_UPDATE_FILES,
  QUESTIONNAIRE_SET_PSTACK,
  QUESTIONNAIRE_UPDATE_BETWEEN_QUESTIONS,
} = REDUX_ACTIONS;

const fileActionByQuestionIds = {
  [BIOMARKERS_QUESTION_ID]: {
    uploadFiles: uploadUserDocuments,
    removeFiles: removeUserDocuments,
  },
};

const fileSourceByQuestionIds = {
  [BIOMARKERS_QUESTION_ID]: BIOMARKERS_DOCUMENT_SOURCE,
};

export const setUploadedQuestionnaireFiles = (documentsData) => {
  const filesByQuestionIds = {};
  Object.keys(fileSourceByQuestionIds).forEach((questionId) => {
    filesByQuestionIds[questionId] = documentsData
      .filter(({ source }) => source === fileSourceByQuestionIds[questionId])
      .map((documentData) => ({
        ...documentData,
        id: documentData.fileName,
        name: documentData.originFileName,
        status: UPLOADED,
      }));
  });
  return {
    type: QUESTIONNAIRE_SET_FILES,
    payload: filesByQuestionIds,
  };
};

export const resetUploadedQuestionnaireFiles = () => ({
  type: QUESTIONNAIRE_SET_FILES,
  payload: {},
});

export const setQuestionnairePstack = (payload) => ({
  type: QUESTIONNAIRE_SET_PSTACK,
  payload,
});

export const setUpdateBetweenQuestions = (payload) => ({
  type: QUESTIONNAIRE_UPDATE_BETWEEN_QUESTIONS,
  payload,
});

export const uploadQuestionnaireFiles = ({ shouldShowBackdrop = false } = {}) => (dispatch, getState) => {
  const {
    profile,
    questionnaire: {
      files: filesByQuestionIds,
      allQuestions,
    } = {},
  } = getState();

  const updateFilesActionPayloads = [];

  const uploadFilesPromises = Object.keys(filesByQuestionIds).map(async (questionId) => {
    const filesToUpload = [];

    const { uploadFiles } = fileActionByQuestionIds[questionId] ?? {};

    const uploadingFileDataByIds = {};

    filesByQuestionIds[questionId].forEach((file) => {
      if (file.status === PRISTINE) {
        uploadingFileDataByIds[file.id] = { ...file, status: UPLOADING };
        filesToUpload.push(file);
      }
    });

    if (!uploadFiles || !filesToUpload.length) {
      return;
    }

    const fileIdsToUpload = filesToUpload.map(({ id }) => id);

    updateFilesActionPayloads.push({
      questionId,
      fileDataByIds: uploadingFileDataByIds,
    });

    try {
      const { personal: { profile_id: profileId } = {} } = profile;

      const {
        data: {
          documentsData: uploadedDocumentsData,
          analyzingResult: uploadedDocumentsAnalyzingResult,
        },
      } = await uploadFiles(
        {
          profileId,
          files: filesToUpload.map(({ file }) => file),
          source: fileSourceByQuestionIds[questionId],
          shouldWaitForDocumentsAnalyzing: true,
        },
        { timeout: 60 * 60 * 1000 },
      );

      const fileDataByIds = {};
      uploadedDocumentsData.forEach((documentsData) => {
        const { id: initFileId } = filesToUpload.find(({ name }) => name === documentsData.originFileName) ?? {};
        if (!initFileId) {
          // should be invariant
          return;
        }
        fileDataByIds[initFileId] = {
          ...omit(documentsData, ['file']),
          id: documentsData.fileName,
          name: documentsData.originFileName,
          status: UPLOADED,
        };
      });

      batch(() => {
        if (uploadedDocumentsAnalyzingResult?.[questionId]) {
          const {
            profile: {
              personal: {
                condition,
              },
              condition_profile: conditionProfile,
            },
            questionnaire: {
              optionsFromServer,
              form,
            } = {},
          } = getState();

          const currentFormValue = form[questionId];

          const question = indicationChooser(condition).find(({ id }) => id === questionId);

          const formValueFromFiles = conditionProfileValueToFormValue({
            conditionProfileValue: uploadedDocumentsAnalyzingResult[questionId],
            question,
            optionsFromServer,
            condition,
            dispatch,
          });

          // update questionnaire.form with question values from files
          dispatch({
            type: REDUX_ACTIONS.FORM_FIELD_VALUE_SET,
            payload: {
              id: questionId,
              value: mergeFormQuestionValues(
                question.type,
                currentFormValue,
                formValueFromFiles,
              ),
            },
          });

          // update profile with question values from files
          const conditionProfileToUpdate = mapFormToConditionProfile({
            [questionId]: mergeFormQuestionValues(
              question.type,
              conditionProfileValueToFormValue({
                conditionProfileValue: conditionProfile[questionId],
                question,
                optionsFromServer,
                condition,
                dispatch,
              }),
              formValueFromFiles,
            ),
          }, allQuestions);

          dispatch(authenticationActions.setUserData({ condition_profile: conditionProfileToUpdate }));
        }

        dispatch({
          type: QUESTIONNAIRE_UPDATE_FILES,
          payload: {
            questionId,
            fileDataByIds,
          },
        });
      });
    } catch (ex) {
      console.error('files uploading error:', ex);

      const {
        questionnaire: {
          files: actualFilesByQuestionIds,
        } = {},
      } = getState();

      const fileDataByIds = {};
      fileIdsToUpload.forEach((fileId) => {
        const file = actualFilesByQuestionIds[questionId].find(({ id }) => id === fileId);
        if (!file) {
          return;
        }
        fileDataByIds[fileId] = { ...file, status: PRISTINE };
      });

      dispatch({
        type: QUESTIONNAIRE_UPDATE_FILES,
        payload: {
          questionId,
          fileDataByIds,
        },
      });
    }
  });

  batch(() => {
    if (shouldShowBackdrop && updateFilesActionPayloads.length) {
      dispatch(showBackdrop({ type: BACKDROP_TYPES.SIMPLE_BACKDROP }));
    }

    updateFilesActionPayloads.forEach((payload) => {
      dispatch({
        type: QUESTIONNAIRE_UPDATE_FILES,
        payload,
      });
    });
  });

  return Promise.all(uploadFilesPromises).finally(() => {
    if (shouldShowBackdrop) {
      dispatch(hideBackdrop({ minDisplayTime: 1000 }));
    }
  });
};

// for adding files (before upload)
export const addQuestionnaireFiles = ({ questionId, files }) => async (dispatch, getState) => {
  dispatch({
    type: QUESTIONNAIRE_ADD_FILES,
    payload: {
      questionId,
      files: files.map((file) => ({
        id: `${Math.random()}_${file.name}`,
        name: file.name,
        status: PRISTINE,
        file, // file itself
      })),
    },
  });

  const {
    authentication, profile,
  } = getState();
  const isAuthenticated = checkIsAuthenticated({ authentication, profile });

  if (!isAuthenticated) {
    return;
  }

  dispatch(uploadQuestionnaireFiles({ shouldShowBackdrop: true }));
};

export const removeQuestionnaireFiles = ({ questionId, fileIds }) => async (dispatch, getState) => {
  const {
    authentication,
    profile = {},
    questionnaire: {
      files: currentFiles,
    } = {},
  } = getState();
  const { personal: { profile_id: profileId } = {} } = profile;

  // for uploaded file its id is its fileName in storage (S3 bucket)
  const uploadedFileIdsToRemove = (currentFiles[questionId] ?? [])
    .filter(({ id, status }) => (
      status === UPLOADED && fileIds.includes(id)
    ))
    .map(({ id }) => id);

  const isAuthenticated = checkIsAuthenticated({ authentication, profile });

  const { removeFiles } = fileActionByQuestionIds[questionId] ?? {};

  // remove not uploaded files
  dispatch({
    type: QUESTIONNAIRE_REMOVE_FILES,
    payload: {
      questionId,
      fileIds: (currentFiles[questionId] ?? [])
        .filter(({ id, status }) => (
          status === PRISTINE && fileIds.includes(id)
        ))
        .map(({ id }) => id),
    },
  });

  if (!isAuthenticated || !profileId || !removeFiles || !uploadedFileIdsToRemove.length) {
    // logically a not authenticated user cannot have uploaded files
    return;
  }

  const dispatchFilesStatusesUpdate = (fileIdsToUpdate, status) => {
    const {
      questionnaire: {
        files: filesByQuestionIds,
      } = {},
    } = getState();

    const fileDataByIds = {};
    fileIdsToUpdate.forEach((fileId) => {
      const file = (filesByQuestionIds[questionId] ?? []).find(({ id }) => id === fileId);
      if (!file) {
        return;
      }
      fileDataByIds[fileId] = { ...file, status };
    });
    dispatch({
      type: QUESTIONNAIRE_UPDATE_FILES,
      payload: {
        questionId,
        fileDataByIds,
      },
    });
  };

  try {
    dispatchFilesStatusesUpdate(uploadedFileIdsToRemove, REMOVING);

    const { data: removedFileIds } = await removeFiles(profileId, uploadedFileIdsToRemove);

    dispatch({
      type: QUESTIONNAIRE_REMOVE_FILES,
      payload: {
        questionId,
        fileIds: removedFileIds,
      },
    });
  } catch (ex) {
    console.error('files removing error:', ex);
    // just reset status to UPLOADED
    dispatchFilesStatusesUpdate(uploadedFileIdsToRemove, UPLOADED);
  }
};
