import userConstants from '../constants';

const initialState = {
  isFetching: false,
  error: null,
};

export function userGet(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case userConstants.GET_USER_REQUEST:
      return {
        isFetching: true,
        error: null,
      };
    case userConstants.GET_USER_SUCCESS:
      return {
        isFetching: false,
        error: null,
      };
    case userConstants.GET_USER_FAILURE:
      return {
        isFetching: false,
        error: payload,
      };
    default:
      return state;
  }
}
