import { ACTIONS } from './constants';

const initialState = {
  isProcessing: false,
  hasErrors: false,
  isSuccess: false
};

export const geneticTesting = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case ACTIONS.ORDER_GENETIC_TESTING_START:
      return {
        ...state,
        isProcessing: true
      };
    case ACTIONS.ORDER_GENETIC_TESTING_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        isSuccess: true
      };
    case ACTIONS.ORDER_GENETIC_TESTING_ERROR:
      return {
        ...state,
        isProcessing: false,
        hasErrors: true,
        isSuccess: false
      };
    case ACTIONS.ORDER_GENETIC_TESTING_RESET_STATE:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
