import { FB_EVENTS } from 'functions/analytics/constants/fbKeys';
import AuthenticationType from '../../../utils/constants/AuthenticationType';
import signUpEmail from './signUpEmail';
import signUpSMS from './signUpSMS';
import signUpGoogle from './signUpGoogle';
import signUpFacebook from './signUpFacebook';
import sendFbPixelRegistration from './sendFbPixelRegistration';
import { sendFbEventToConversionApi } from './sendFbEventToConversionApi';

export default (type) => {
  const events = {
    [AuthenticationType.EMAIL]: signUpEmail,
    [AuthenticationType.PHONE]: signUpSMS,
    [AuthenticationType.GOOGLE]: signUpGoogle,
    [AuthenticationType.FACEBOOK]: signUpFacebook,
  };

  // Send to conversions api
  const eventID = `${FB_EVENTS.REGISTRATION_EVENT}.${Date.now()}`;
  sendFbEventToConversionApi({
    eventName: FB_EVENTS.REGISTRATION_EVENT,
    customData: { method: type || AuthenticationType.EMAIL },
    eventID,
  }).catch((e) => console.error('Can\'t send facebook event', e));
  // Send to fb pixel
  sendFbPixelRegistration(type || AuthenticationType.EMAIL, eventID);
  // Send to gtag
  return (events[type] && events[type]()) || events[AuthenticationType.EMAIL]();
};
