/**
 * Conditional className helper
 * Each argument should be string of className or object 
 * Object arg example {
 *  'class-2': condition1Boolean,
 *  'class-1': condition2Boolean,
 * }
 * 
 * @param {...(string|object) classes
 * @returns {string}
*/
export const conditionalClasses = (...classes) => classes.map(cl => typeof cl === 'string' ? cl :
  Object.entries(cl).map(([clName, condition]) => condition ? clName : '')
  .join(' ')
).join(' ');
