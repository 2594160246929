import mainConstants from '../constants/main';
import LOCAL_STORAGE_KEYS from '../constants/localStorageKeys';
import { getFallbackLng } from '../../components/AppTranslationWrapper/functions';

const initialState = {
  isNewProfile: false,
  redirectPathOnceQuestionnaireCompleted: null,
  cameFrom: null,
  urlParameters: null,
  lastOpenedUrl: '',
  isNewUser: !localStorage.getItem(LOCAL_STORAGE_KEYS.JOINED_AT),
  isMobile: false,
  lang: getFallbackLng(),
};

export function main(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case mainConstants.SET_IS_NEW_PROFILE:
      return {
        ...state,
        isNewProfile: payload,
      };
    case mainConstants.SET_REDIRECT_PATH_ONCE_QUESTIONNAIRE_COMPLETED:
      return {
        ...state,
        redirectPathOnceQuestionnaireCompleted: payload,
      };
    case mainConstants.SET_CAME_FROM:
      return {
        ...state,
        cameFrom: payload || null,
      };
    case mainConstants.SET_URL_PARAMETERS:
      return {
        ...state,
        urlParameters: payload || null,
      };
    case mainConstants.SET_LANG:
      return {
        ...state,
        lang: payload,
      };
    case mainConstants.SET_IS_MOBILE:
      return {
        ...state,
        isMobile: payload,
      };
    case mainConstants.CLEAR_URL_PARAMETERS_AND_CAME_FROM:
      return {
        ...state,
        urlParameters: null,
        cameFrom: null,
      };
    case mainConstants.SET_LAST_OPENED_URL:
      return {
        ...state,
        lastOpenedUrl: payload,
      };
    default:
      return state;
  }
}
