/* eslint-disable react/prop-types */
import React, { useRef } from 'react';
import 'new-ui/Components/ItemBox/ItemBox.css';
import './TreatmentItem.css';

import { useHistory } from 'react-router-dom';
import IconLine from 'new-ui/Components/IconLine';
import { ItemButton, ItemTags, ItemHeader } from 'new-ui/Components/ItemBox/ItemBox';
import PropTypes from 'prop-types';
import app from 'new-ui/app';
import { nctToUrl } from 'new-ui/functions';
import { ROUTES, PAGES } from 'new-ui/constants';
import { useTranslation } from 'utils/modifiedTranslation';
// import lealRoleChatIcon from '../../assets/icons/property.svg';

const ITEM_URL = ROUTES[PAGES.TREATMENT];

const TreatmentItem = (props) => {
  const {
    isFull,
    listItem,
    item,
    number = 0,
    popupItem,
    action,
    data,
    title,
    footer,
    isSearchPage,
    type,
    // friendlyTitle,
  } = props;

  const history = useHistory();
  const $item = useRef(null);
  const { t } = useTranslation();
  const navigate = (route) => {
    history.push(route);
  };

  const gotoTreatment = () => {
    if (isFull) return;
    if (action) action(item);
    else navigate(`${ITEM_URL}/${nctToUrl(item.nct_number)}`);
  };

  return (
    <div className={`item-box-container ${!app.user && 'public-item'} ${number === 0 ? 'collapsed' : ''} ${item.isSponsored ? 'sponsored' : ''}`} ref={$item}>
      <div className={`item-box ${isFull ? 'full-item' : 'list-item'} ${popupItem ? 'popup-item' : ''}`}>
        <div className="item-box-info">
          <div className="item-box-content">

            <div>
              <ItemTags
                item={item}
                number={isFull ? null : number}
                data={data}
                isSearchPage={isSearchPage}
              />

              <ItemHeader
                item={item}
                isFull={isFull}
                action={gotoTreatment}
                title={title}
                isSearchPage={isSearchPage}
              />

            </div>
            {type === 'list' && (
              <>
                <div className="info-title">{t('soc_page.info')}</div>
              </>
            )}
            {/* {
              // friendlyTitle ? (
              //   <div id="friendlyTitle" className="treatment-item-freindly-title-margin">
              //     <div className="treatment-item-freindly-container">
              //       <div id="friendlyTitleIcon" className="treatment-item-freindly-title-icon"><img src={lealRoleChatIcon} alt="Leal Role" /></div>
              //       {friendlyTitle}
              //     </div>

              //     <div className="treatment-item-freindly-title-margin" />
              //   </div>
              // ) : null
            } */}
            <div className="details bottom">
              {item.treatment_administration_type?.length ? <IconLine icon="dot" title={`${item.treatment_administration_type?.join(', ')}`} /> : null}
              {item.treatment_settings?.length ? <IconLine icon="hospital" title={`${item.treatment_settings?.join(', ')}`} /> : null}
            </div>
          </div>
        </div>
        {footer || (
          <ItemButton
            popupItem={popupItem}
            listItem={listItem}
            action={gotoTreatment}
            item={item}
            $item={$item}
            width="unset"
          />
        )}
      </div>
    </div>
  );
};

TreatmentItem.propTypes = {
  isFull: PropTypes.bool,
  listItem: PropTypes.bool,
  item: PropTypes.object,
  number: PropTypes.number,
  popupItem: PropTypes.bool,
  title: PropTypes.string,
  action: PropTypes.func,
  data: PropTypes.object,
  footer: PropTypes.any,
  isSearchPage: PropTypes.bool,
  type: PropTypes.string,
};

export default TreatmentItem;
