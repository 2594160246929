
export const clinical_trial_phases = [
    { id: 1, value: '1', label: 'Phase 1' },
    { id: 2, value: '2', label: 'Phase 2' },
    { id: 3, value: '3', label: 'Phase 3' },
    { id: 4, value: '4', label: 'Phase 4' },
];

export const treatment_types = [
    { id: 1, value: 'clinical_trials', label: 'Clinical trial' },
    { id: 2, value: 'standard_of_care', label: 'Standard of care' },
];

export const administration_methods = [
    { id: 1, value: 'oral_treatment', label: 'Oral treatment (by mouth)' },
    { id: 2, value: 'injection_muscle', label: 'Injection into the muscle' },
    { id: 3, value: 'iv_infusion', label: 'IV infusion' }, 
];

export const interventional_types = [
    { 
        id: 1, 
        value: 'chemotherapy', 
        label: 'Chemotherapy', 
        categories: [], 
        color: '#E2DAFF' 
    },
    { 
        id: 2, 
        value: 'targeted_therapy', 
        label: 'Targeted Therapy', 
        categories: ['less_toxic', 'less_aggressive', 'targeted_to_mutation'], 
        color: '#FBDBE0' 
    },
    { 
        id: 3, 
        value: 'immunotherapy', 
        label: 'Immunotherapy', 
        categories: ['less_toxic','less_aggressive'],
        color: '#F5FAEA' 
    },
    { 
        id: 4, 
        value: 'hormonal_therapy', 
        label: 'Hormonal Therapy', 
        categories: ['less_toxic', 'less_aggressive'],
        color: '#FEE9ED'
    },
    { 
        id: 5, 
        value: 'gene_therapy', 
        label: 'Gene therapy', 
        categories: ['targeted_to_mutation'],
        color: '#CEEBFB'
    },
    { 
        id: 6, 
        value: 'imaging', 
        label: 'Imaging', 
        categories: [],
        color: '#F4E5FF'
    },
    { 
        id: 7, 
        value: 'medical_device', 
        label: 'Medical Device', 
        categories: [],
        color: '#D3D6D6' 
    },
    { 
        id: 8, 
        value: 'vaccine', 
        label: 'Vaccine', 
        categories: [],
        color: '#D5F3F0' 
    },
    { 
        id: 9, 
        value: 'oncolytic_virus_therapy', 
        label: 'Oncolytic Virus Therapy', 
        categories: [],
        color: '#FFE8E1' 
    },
    { 
        id: 10, 
        value: 'radiation', 
        label: 'Radiation', 
        categories: [],
        color: '#FDF6E2'
    },
    { 
        id: 11, 
        value: 'cell_therapy', 
        label: 'Cell Therapy', 
        categories: [],
        color: '#E6F5FE' 
    },
    { 
        id: 12, 
        value: 'diagnostics', 
        label: 'Diagnostics', 
        categories: [],
        color: '#F2F2F7' 
    },
    { 
        id: 13, 
        value: 'surgery', 
        label: 'Surgery', 
        categories: [],
        color: '#E9CBFF' 
    },
    { 
        id: 14, 
        value: 'transplantation', 
        label: 'Transplantation', 
        categories: [],
        color: '#ABE7E2' 
    },
    { 
        id: 15, 
        value: 'general', 
        label: 'General', 
        categories: [],
        color: '#FDF6E2' 
    },
];