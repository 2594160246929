import _ from 'lodash';
import { flattenAnswers } from './flattenAnswers';

export function checkIfContainsOneOf(profile, condition) {
  const clonedProfile = flattenAnswers(profile);
  // check if an array | string contains one of the values in the ifValue
  const { ifId, ifValue } = condition;
  const profileValue = clonedProfile[ifId];
  // This is commented so we can specify if it was skipped by passing undefined
  // if(!_.hasIn(profile, ifId)){
  //     // console.log(ifId, 'not found');
  //     return false
  // }
  if (_.isArray(profileValue)) {
    // const flattenValues = profileValue.map(({ value }) => value );
    // console.log("check if contains","ifId:",ifId, "profileValue:",profileValue);
    if (_.isArray(ifValue)) {
      const checks = ifValue.map(value => {
        return _.includes(profileValue, value);
        // return profileValue.includes( value );
      });
      // console.log("check if", profileValue, "contains:",ifValue, "Result::", checks);
      return _.some(checks, check => check === true);
    }

    return _.includes(profileValue, ifValue);
  }
  // In case the profile value is not array
  // console.log("Check when ifId is not array. ifId:",ifId, "ifValue:", ifValue);
  return _.some(ifValue, value => value === profileValue);
}
