/* eslint-disable no-case-declarations */
/* eslint-disable no-param-reassign */
// import _, { flatten } from 'lodash';
// import REDUX_ACTIONS from 'components/Questionnaire/constants/reduxActions';
import {
  MONTH_PICKER, NUMBER_INPUT, PROCEDURES, TEXT_INPUT, TREATMENT_HISTORY, COMPOSITE, SIMPLE_COMPOSITE, OPTIONS_FROM_SERVER,
} from '../constants/questionTypes';
import formatMonthPickerValue from './formatMonthPickerValue';
import { tryInferOptions } from './tryInferOptions';

// const sortByLatest = (a, b) => b.isLatest - a.isLatest;
// const compareTreatmentEndDate = (a, b) => new Date(b.treatment_end_date) - new Date(a.treatment_end_date);

// it works for all types except TRIPLE_QUESTION_DROP_DOWN type
export const conditionProfileValueToFormValue = ({
  conditionProfileValue,
  question,
  optionsFromServer,
  condition,
  dispatch,
}) => {
  const { type: questionType } = question;
  switch (questionType) {
    // case TREATMENT_HISTORY:
    //   const newCombinations = [];

    //   const treatmentValues = (conditionProfileValue ?? [])
    //   // compareTreatmentEndDate for old profiles
    //     .sort(compareTreatmentEndDate)
    //     .sort(sortByLatest)
    //     .map((treatment, index) => {
    //       if (index === 0) {
    //         return {
    //           ...treatment,
    //           isLatest: true,
    //         };
    //       }
    //       return {
    //         ...treatment,
    //         isLatest: false,
    //       };
    //     })
    //     .map((treatment) => {
    //       const treatmentDrugs = treatment?.drugs_received ?? [];
    //       const drugOptions = flatten(Object.values(optionsFromServer.drugs_received.options));
    //       const takingStateOptions = question.subQuestionsOptions.taking_state || [];
    //       const drugCombinationsOptions = optionsFromServer?.drugs_combinations?.groupedOptions?.[condition] || [];
    //       const treatmentAdminOptions = question.subQuestionsOptions.treatment_admin || [];
    //       const treatmentStopReasonOptions = question.subQuestionsOptions.treatment_stop_reason || [];
    //       const isCombination = drugCombinationsOptions.find(
    //         ({ combinations }) => _.isEqual(combinations.sort(), treatmentDrugs.sort()),
    //       );

    //       let drugs_received = isCombination
    //         ? [isCombination]
    //         : treatmentDrugs.length === 1
    //           ? treatmentDrugs.map((drug) => [...drugOptions, {
    //             label: 'The combination I received is not listed',
    //             value: 'not_listed_combination',
    //           }].find(({ value }) => value === drug))
    //           : [];
    //         // in case its unsupported combination we add to store for represent it in ui
    //       if (treatmentDrugs.length > 0 && drugs_received.length === 0) {
    //         const newCombination = {
    //           combinations: treatmentDrugs,
    //           conditions: null,
    //           indication: condition,
    //           label: treatmentDrugs.map((l) => `questionnaire.questions.drugs_received.${l}`),
    //           value: treatmentDrugs.join('+'),
    //         };
    //         newCombinations.push(newCombination);
    //         drugs_received = [newCombination];
    //       }

    //       treatment = {
    //         ...treatment,
    //         drugs_received,
    //       };

    //       if (treatment.isLatest) {
    //         treatment = {
    //           ...treatment,
    //           taking_state: takingStateOptions.find(({ value }) => value === treatment.taking_state),
    //           treatment_admin: treatmentAdminOptions.find(({ value }) => value === treatment.treatment_admin),
    //           treatment_stop_reason: treatmentStopReasonOptions.find(
    //             ({ value }) => value === treatment.treatment_stop_reason,
    //           ),
    //         };
    //       }
    //       return treatment;
    //     });
    //   const drugCombinationsFromServer = optionsFromServer?.drugs_combinations || {};
    //   const conditionCombinations = drugCombinationsFromServer?.groupedOptions?.[condition] || [];
    //   const cloneOptionsFromServer = {
    //     ...optionsFromServer,
    //     drugs_combinations: {
    //       ...drugCombinationsFromServer,
    //       groupedOptions: {
    //         ...drugCombinationsFromServer?.groupedOptions,
    //         [condition]: [
    //           ...conditionCombinations,
    //           ...newCombinations,
    //         ],
    //       },
    //     },
    //   };
    //   dispatch({
    //     type: REDUX_ACTIONS.GET_QUESTION_OPTIONS_SUCCESS,
    //     payload: cloneOptionsFromServer,
    //   });
    //   return treatmentValues;
    case MONTH_PICKER:
      return formatMonthPickerValue(conditionProfileValue, true);
    case COMPOSITE:
    case SIMPLE_COMPOSITE:
    case PROCEDURES:
    case TREATMENT_HISTORY:
      const treatmentsAdditionalFields = ['taking_state', 'updated_taking_state_date', 'survey_answered', 'date_of_adding'];
      const { subQuestions } = question;
      return (conditionProfileValue ?? []).map((subQuestionConditionProfileValue) => {
        const subQuestionFormValue = {};
        subQuestions.forEach((subQuestion) => {
          subQuestionFormValue[subQuestion.id] = conditionProfileValueToFormValue({
            conditionProfileValue: subQuestionConditionProfileValue[subQuestion.id],
            question: subQuestion,
            optionsFromServer,
            condition,
            dispatch,
          });
        });
        if (questionType !== PROCEDURES) {
          treatmentsAdditionalFields.forEach((additionalField) => {
            subQuestionFormValue[additionalField] = subQuestionConditionProfileValue[additionalField];
          });
        }
        return subQuestionFormValue;
      });
    case NUMBER_INPUT:
      return typeof conditionProfileValue === 'number' ? String(conditionProfileValue) : conditionProfileValue;
    case TEXT_INPUT:
      return conditionProfileValue;
    default: {
      if (conditionProfileValue === null) {
        return null;
      }

      const options = tryInferOptions(question, optionsFromServer);
      if (question.type === OPTIONS_FROM_SERVER && typeof conditionProfileValue === 'string') {
        conditionProfileValue = conditionProfileValue.split(',');
      }
      if (Array.isArray(conditionProfileValue)) {
        return options.filter((option) => conditionProfileValue.includes(option.value));
      }
      return options.find((option) => String(option.value) === String(conditionProfileValue));
    }
  }
};
