import React from 'react';
import './WhatsNext.css';
import PropTypes from 'prop-types';

const WhatsNext = (props) => {
  const { content } = props;
  return (
    <div className="whats-next">
      <h2>{content.title}</h2>
      <div className="steps">
        {
          content.steps.map((step, i) => (
            <div key={i} className="step">
              <div className="step-number">
                <span>{i + 1}</span>
              </div>
              <div className="step-content" dangerouslySetInnerHTML={{ __html: step }} />
            </div>
          ))
        }
      </div>
    </div>
  );
};

WhatsNext.propTypes = {
  content: PropTypes.any,
};

export default WhatsNext;
