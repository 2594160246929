import React, { useState } from "react";

const ImageWrapper = ({ imgSrc, alt, defaultImgSrc }) => {
  const [defaultImageTried, setDefaultImageTried] = useState(false);

  const handleImageError = (event) => {
    if (defaultImgSrc && !defaultImageTried) {
      setDefaultImageTried(true);
      event.currentTarget.src = defaultImgSrc;
    }
  };

  return (
    <div className="img-wrapper">
      <img src={imgSrc || defaultImgSrc} alt={alt} onError={handleImageError} />
    </div>
  );
};

export default ImageWrapper;