import GLOBAL_ENUMS from './globalEnums';

const { biomarkers: BIOMARKERS_QUESTION_ID } = GLOBAL_ENUMS;

export default {
  biomarkers: BIOMARKERS_QUESTION_ID,
  drugs_received: 'drugs_received',
  drugs_combinations: 'drugs_combinations',
  insurances: 'insurances',
};
