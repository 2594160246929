import React, { useEffect } from 'react';
import './Popup.css';
import XIcon from 'new-ui/assets/icons/x.svg';
import PropTypes from 'prop-types';

import Button from '../Button';

const Popup1 = (props) => {
  const {
    isVisible,
    closePopup,
    children,
    className,
    theme,
    isFullHeight = false,
    popupData = {},
  } = props;
  useEffect(() => {
    document.body.style.overflow = isVisible ? 'hidden' : '';
    return () => {
      document.body.style.overflow = '';
    };
  }, [isVisible]);
  return (
    <div className={`${isVisible ? 'popup-visible' : ''} ${isFullHeight ? 'popup-full-height' : ''} ${theme ? `popup-theme-${theme}` : ''}`}>
      <div className="popup-overlay" onClick={popupData.preventOverlayClose ? null : closePopup} />
      <div
        className={`popup ${className || ''}`}
      >
        <div className="popup-header middle">
          <img alt="close" src={XIcon} className="close" onClick={closePopup} />
        </div>
        <div className="popup-content">
          {children}
        </div>
      </div>
    </div>
  );
};

Popup1.propTypes = {
  isVisible: PropTypes.any,
  closePopup: PropTypes.func,
  children: PropTypes.object,
  className: PropTypes.string,
  theme: PropTypes.string,
  isFullHeight: PropTypes.bool,
  popupData: PropTypes.any,
};

export default Popup1;

export const PopupTitle = (props) => {
  const { title, children } = props;
  return (
    <div className="popup-title popup-padding">
      {children || title}
    </div>
  );
};

PopupTitle.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
};

export const PopupSubtitle = (props) => {
  const { subtitle } = props;
  return (
    <div className="popup-subtitle popup-padding">
      {subtitle}
    </div>
  );
};

PopupSubtitle.propTypes = {
  subtitle: PropTypes.string,
};

export const PopupContent = (props) => {
  const { children } = props;
  return (
    <div className="popup-content-wrapper popup-padding">
      {children}
    </div>
  );
};

PopupContent.propTypes = {
  children: PropTypes.any,
};

export const PopupFooter = (props) => {
  const { children, className } = props;
  return (
    <div className={`popup-footer popup-padding ${className || ''}`}>
      {children}
    </div>
  );
};

PopupFooter.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

export const PopupProperty = (props) => {
  const {
    title, value, href, sideButton, name, className, onClick,
  } = props;
  const data = {};
  if (name) data.name = name;
  if (onClick) data.onClick = onClick;
  return (
    <div className={`popup-property ${className || ''} ${onClick ? 'cursor-pointer' : ''}`} {...data}>
      <div className="popup-property-main">
        <div className="popup-property-title">{title + (value ? ':' : '')}</div>
        {value && (
          <div className="popup-property-value">
            {
              href ? <a href={href}>{value}</a> : `${value}`
            }
          </div>
        )}
      </div>
      { sideButton ? (
        <div className="popup-property-side">
          {sideButton.icon && <img alt="icon" src={sideButton.icon} />}
          {sideButton.title && (
            <span
              className="popup-property-button"
              onClick={sideButton.action}
            >
              {sideButton.title}
            </span>
          )}
        </div>
      ) : null}
    </div>
  );
};

PopupProperty.propTypes = {
  title: PropTypes.string,
  value: PropTypes.any,
  href: PropTypes.string,
  sideButton: PropTypes.any,
  name: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.any,
  onClick: PropTypes.any,
};

export const PopupButton = (props) => {
  const {
    title,
    action,
    disabled,
    isVisible = true,
    icon,
    forwardIcon,
  } = props;
  return isVisible
    ? (
      <PopupFooter>
        <Button forwardIcon={forwardIcon} icon={icon} disabled={disabled} title={title} action={action} />
      </PopupFooter>
    ) : null;
};

PopupButton.propTypes = {
  title: PropTypes.string,
  forwardIcon: PropTypes.any,
  icon: PropTypes.any,
  action: PropTypes.func,
  disabled: PropTypes.bool,
  isVisible: PropTypes.any,
};
