import React, { useState } from 'react';
import './style.css';
import { EVENT_TYPES } from '../../options';

const EventActions = ({ onTypeSelect }) => {
  return (
    <div className='event-actions'>
      <span className='label'>Add new:</span>
      <ul>
        {Object.keys(EVENT_TYPES).map((type, index) => (
          <li key={index}>
            <HoverableActionButton type={type} onTypeSelect={onTypeSelect} />
          </li>
        ))}
      </ul>
    </div>
  );
};

const HoverableActionButton = ({ type, onTypeSelect }) => {
  const [isHovered, setIsHovered] = useState(false);
  const backgroundColor = isHovered ? EVENT_TYPES[type].hover_color : EVENT_TYPES[type].color;

  return (
    <div
      className='action-button'
      style={{ backgroundColor }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => onTypeSelect(type)}
    >
      {React.cloneElement(EVENT_TYPES[type].icon, { width: 34, height: 34 })}
      {EVENT_TYPES[type].label}
    </div>
  );
};

export default EventActions;