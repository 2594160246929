import { indicationChooser, isIndicationFromBackend } from 'components/Questionnaire/functions';
import { updateQStack, infoAttributes } from 'components/Questionnaire/actions';
import globalEnums from 'components/Questionnaire/constants/globalEnums';
// import { mapFormToConditionProfile } from 'components/Questionnaire/functions/mapFormToConditionProfile';

export const conditionProfileFromIndication = async (indication) => {
  let conditionProfile = {};
  const allQuestions = await indicationChooser(indication);
  const qstack = updateQStack({
    allQuestions,
    form: {},
  });

  if (isIndicationFromBackend(indication)) {
    conditionProfile = {
      [globalEnums.tnm]: [],
      [globalEnums.treatments.questionId]: [],
      [globalEnums.procedures.questionId]: [],
      // ...mapFormToConditionProfile({}, allQuestions),
      qstack: qstack.map((q) => q.id),
    };

    delete conditionProfile.preferred_location;
  } else {
    conditionProfile = {
      [globalEnums.treatments.questionId]: [],
      [globalEnums.procedures.questionId]: [],
      // ...mapFormToConditionProfile(form, allQuestions),
      qstack: qstack.map((q) => q.id),
    };
  }
  // Removes personal / info attributes from the condition_profile
  infoAttributes.forEach((a) => delete conditionProfile[a]);
  return conditionProfile;
  // return {
  //   allQuestions, qstack, qstackIds: qstack.map((q) => q.id), conditionProfile,
  // };
};

window._conditionProfileFromIndication = conditionProfileFromIndication;
