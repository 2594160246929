import ReactGA from 'react-ga4';

/**
   *
   * @param {string} category - the category of event
   * @param {string} eventName - the name of event
   * @param {object} metaData - event parameters key-value object will be shown in GA
   */
const sendGoogleAnalyticsEvent = (category, eventName, metaData) => {
  ReactGA.event(eventName, {
    category,
    ...metaData,
  });
}

export default sendGoogleAnalyticsEvent;