import * as queryString from 'query-string';

const cachedResult = {
  prevLocationSearch: null,
  shouldHideNavRes: null
};

export const shouldHideNav = () => {
  const locationSearch = window.location?.search;
  if (cachedResult.prevLocationSearch === locationSearch && cachedResult.shouldHideNav) {
    return cachedResult.shouldHideNav;
  }
  const { hideNav = null } = queryString.parse(locationSearch);
  const shouldHideNavRes = hideNav && hideNav === 'true';

  cachedResult.prevLocationSearch = locationSearch;
  cachedResult.shouldHideNavRes = locationSearch;

  return shouldHideNavRes;
};
