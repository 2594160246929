/* eslint-disable comma-dangle */
import { createTempHeader } from 'functions/authHeader';
import { GA_UPLOAD_EXPLAIN_CHAT_EVENTS } from 'new-ui/Components/GoogleAnalytics/generalEvents';
import app from 'new-ui/app';
import configs from 'configs';
import { v4 as uuidv4 } from 'uuid';

const apiUrl = configs.beApiUrl;

/**
 * Sends a message to the chat API and returns the response.
 *
 * @param {string} prompt - The prompt for the message
 * @return {Promise<Object>} The response from the chat API
 */
export const sendMessage = async prompt => {
  if (!localStorage.getItem('tempV4')) {
    localStorage.setItem('tempV4', uuidv4());
  }
  const payload = JSON.stringify({
    prompt,
    userWixId: localStorage.getItem('tempV4'),
  });
  app.sendGoogleAnalyticsEvent(
    GA_UPLOAD_EXPLAIN_CHAT_EVENTS.UPLOAD_EXPLAIN_CHAT_USER_SEND_MESSAGE_TO_CHAT,
    'send msg to explain upload chat'
  );
  const tempHeaders = await createTempHeader();
  try {
    const response = await fetch(`${apiUrl}/chatUploadExplain/chatUploadExplainSession`, {
      method: 'POST',
      headers: tempHeaders.headers,
      body: payload,
    });

    if (!response.ok) {
      throw new Error(`Error sending message: ${response.statusText}`);
    }

    const ans = await response.json();
    return ans;
  } catch (error) {
    console.error('Error sending message:', error);
  }
};
/**
 * Updates the chat score for a user.
 *
 * @param {string} userWixId - The Wix ID of the user.
 * @param {number} score - The new score for the user.
 * @return {Promise<void>} A promise that resolves when the score is updated.
 */
