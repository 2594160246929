/* eslint-disable no-unused-vars */
import Form, { FORM_INPUT_TYPES } from 'new-ui/Components/Form';
import './Login.css';
import React, { useEffect, useState } from 'react';
import Button from 'new-ui/Components/Button';

const LoginPopup = ()=>{
  // const [showVerification, setShowVerification] = useState();
  const [error, setError] = useState();
  const [form, setForm] = useState();
  useEffect(()=>{
    const _form = {
      data: {
        email: '',
      },
      inputs: {
        email: {
          type: FORM_INPUT_TYPES.INPUT,
        },
      },
    };

    setForm(_form);
  }, []);

  return (
    <div className="login-popup">
      {
        form ? (
          <>
            <div className="login-popup-header">Login</div>
            <div className="login-popup-form">
              <Form form={form} />
            </div>
            <div className="login-popup-error-handler">
              {error}
            </div>
            <div className="login-popup-actions">
              <Button
                title="Next"
                action={()=>{

                }}
              />
            </div>
          </>
        ) : null
      }
    </div>
  );
};

export default LoginPopup;
