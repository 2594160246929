import send from './send';
import { eventsKeys, processableQuestions } from '../constants';

export default (params = {}) => {
  const { questionId } = params || {};

  if (!questionId || !processableQuestions.includes(questionId)) {
    return;
  }

  send(eventsKeys.CLICK_NEXT_ON_A_QUESTION, {
    questionId
  });
};
