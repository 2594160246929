import { BACKDROP_CONSTANTS } from './constants';

const initialState = {
  isShown: false,
  shownAt: null,
  type: null,
};

export function backdrop(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case BACKDROP_CONSTANTS.SHOW_BACKDROP: {
      return { ...payload, isShown: true };
    }
    case BACKDROP_CONSTANTS.HIDE_BACKDROP: {
      return {
        isShown: false,
        shownAt: null,
        type: null,
      };
    }
    default:
      return state;
  }
}
