export default {
  CLICK_DOWNLOAD_AND_SHARE_ON_MINISITE: 'click download and share on minisite',
  CLICK_NEXT_ON_A_QUESTION: 'click next on some question',
  DOWNLOAD_PDF_FROM_POPUP: 'download pdf from popup',
  MATCHES_PAGE_CHANGE_LOCATION: 'matches page change location',
  MATCHES_PAGE_EMAIL_US: 'matches page email us',
  MATCHES_PAGE_POPUP_SCHEDULE_TIME: 'matches page popup schedule time',
  MATCHES_PAGE_RETURNING_USER_UPDATE_PROFILE_FROM_BANNER: 'matches page returning user update profile from banner',
  MATCHES_PAGE_RETURNING_USER_UPDATE_PROFILE_FROM_POPUP: 'matches page returning user update profile from popup',
  MATCHES_PAGE_SCHEDULE_TIME: 'matches page schedule time',
  MATCHES_PAGE_SHARE_WITH_DOCTOR: 'matches page share with doctor',
  MATCHES_PAGE_SORT_RANK: 'matches page sort rank',
  MATCHES_PAGE_SORT_TREATMENT_TYPE: 'matches page sort treatment type',
  MATCHES_PAGE_SPEAK_WITH_TRIAL_ONCOLOGIST: 'matches page speak with trial oncologist',
  MATCHES_PAGE_TRIAL_STUDY_DETAILS: 'matches page trial study details',
  MATCHES_PAGE_USER_UPDATE_PROFILE: 'matches page user update profile',
  OPEN_CALENDLY_TO_SCHEDULE_CALL_ON_MINISITE: 'open calendly to schedule call on minisite',
  SEE_MORE_MODAL_OPEN_TRIAL: 'see more modal open trial',
  SEE_MORE_MODAL_VIEW_FAQ: 'see more modal view faq',
  SEND_TO_DOCTOR_FROM_POPUP: 'send to doctor from popup',
  SIDE_MENU_LEARN_MORE: 'side menu learn more',
  SIDE_MENU_SENT_TO_DOCTOR: 'side menu sent to doctor',
  SIDE_MENU_TREATMENT_FINDER: 'side menu treatment finder',
  SIDE_MENU_VIDEO: 'side menu video',
  SIGN_UP_VIEW_YOUR_TRIALS: 'sign up view your trials',
  SIGN_UP_SMS: 'sign up sms',
  SIGN_UP_EMAIL: 'sign up email',
  SIGN_UP_GOOGLE: 'sign up google',
  SIGN_UP_FACEBOOK: 'sign up facebook',
  SPONSORED_MATCH_POPUP_SCHEDULE: 'popup-schedule',
  TRIAL_POPUP_SEE_MORE: 'trial popup see more',
  TRIAL_POPUP_SPEAK_WITH_ONCOLOGIST: 'trial popup speak with oncologist',
  TRIALS_LIST_TRIAL_ITEM: 'trials list trial item',
  USER_IS_MOVED_TO_THE_NEXT_SECTION: 'user is moved to the next section',
  VIEW_QUESTIONS_POPUP_SPEAK_WITH_ONCOLOGIST: 'view questions popup speak with oncologist',
  WHAT_NEXT_POPUP_DOCTOR: 'what next popup doctor',
  WHAT_NEXT_POPUP_LEARN_MORE: 'what next popup learn more',
  WHAT_NEXT_POPUP_TREATMENT_FINDER: 'what next popup treatment finder',
  BANNER_CLICk: 'banner_click',
  BROSHURE_DOWNLOADED: 'brochure_downloaded',
  OPEN_FORM: 'open_form',
  FORM_SENT: 'form_sent',
  QUICK_SIGNUP_BUTTON_VIEWED: 'Quick signup button viewed',
  QUICK_SIGNUP_STARTED: 'Quick signup started',
  QUICK_SIGNUP_SUCCEED: 'Signup succeed',
  QUICK_SIGNUP_LOGIN_PAGE_VIEWED: 'Login page viewed',
  QUICK_SIGNUP_LOGIN_SUCCEED: 'Login succeed',
  QUICK_SIGNUP_MODAL_VIEWED: 'quick signup - show after 4 questions',
};
