import configs from '../../configs';

export const CRM_CHAT_KEY = configs.crmChat.key;

export const DEFAULT_CRM_CHAT_SETTINGS = {
  webWidget: {
    chat: {
      suppress: true,
      connectOnPageLoad: false,
    },
    contactForm: {
      suppress: true,
    },
    zIndex: 8,
    offset: {
      horizontal: '10px',
      vertical: '15px',
    },
  },
};
