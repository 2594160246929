import { menuConstants } from './constants';

const { MENU } = menuConstants;

export const clearMatchesMenu = () => ({ type: menuConstants.CLEAR_MATCHES_MENU });

export const toggleMatchesMenu = (triggers, shouldOpen) => (dispatch) => {
  const toggleTrigger = (trigger) => {
    const update = {
      type: menuConstants.TOGGLE_MATCHES_MENU_TRIGGER,
      payload: {
        [trigger]: shouldOpen,
      },
    };

    dispatch(update);
  };

  if (Array.isArray(triggers)) {
    triggers.forEach((trigger) => toggleTrigger(trigger));
  } else {
    toggleTrigger(triggers);
  }
};

export const MATCHES_MENU = MENU;
