export const LOCATION = 'LOCATION';
export const MESSAGE = 'MESSAGE';
export const MONTH_PICKER = 'MONTH_PICKER';
export const MULTIPLE_DROP_DOWN_SELECT = 'MULTIPLE_DROP_DOWN_SELECT';
export const MULTIPLE_OPTION_SELECT = 'MULTIPLE_OPTION_SELECT';
export const NUMBER_INPUT = 'NUMBER_INPUT';
export const OPTIONS_FROM_SERVER = 'OPTIONS_FROM_SERVER';
export const MULTIPLE_OPTION_SEARCH_SELECT = 'MULTIPLE_OPTION_SEARCH_SELECT';
export const PROCEDURES = 'PROCEDURES';
export const COMPOSITE = 'COMPOSITE';
export const SIMPLE_COMPOSITE = 'SIMPLE_COMPOSITE';
export const SINGLE_OPTION_SELECT = 'SINGLE_OPTION_SELECT';
export const SIMPLE_OPTION_SELECT = 'SIMPLE_OPTION_SELECT';
export const TREATMENT_HISTORY = 'TREATMENT_HISTORY';
export const TRIPLE_QUESTION_DROP_DOWN = 'TRIPLE_QUESTION_DROP_DOWN';
export const BIOMARKERS = 'BIOMARKERS';
export const TEXT_INPUT = 'TEXT_INPUT';
export const SUB_STAGE_SELECT = 'SUB_STAGE_SELECT';
