import React from "react";

import Avatar1 from '../assets/images/avatars/1.svg';
import Avatar2 from '../assets/images/avatars/2.svg';
import Avatar3 from '../assets/images/avatars/3.svg';

import './Testimonials.css';

const comments = [{
  name: 'Seona F.',
  condition: 'Breast Cancer, Stage III, ER/PR+ BRCA II Mutation',
  comment: '"As a patient, it is important that you become a part of your care team."',
  avatar: Avatar1,
}, {
  name: 'Paul B.',
  condition: 'Prostate Cancer, Stage II',
  comment: '"LEAL gave me the confidence to speak with my doctors and explore additional treatments."',
  avatar: Avatar2,
}, {
  name: 'Casey R.',
  condition: 'Acute Myeloid Leukemia',
  comment: '"Having a choice is powerful. And I want to be powerful against my cancer."',
  avatar: Avatar3,
}];

const Testimonials = () => {
  return (
    <div className='testimonials'>
      <h3 className='title'>Join others taking the first step in their cancer journey.</h3>
      <div className='comments'>
        {comments.map(({name, condition, comment, avatar}, key) => (
          <div className='comment' key={key}>
            <img src={avatar} alt={name} />
            <p className='name'>{name}</p>
            <p className='condition'>{condition}</p>
            <p className='comment'>{comment}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;