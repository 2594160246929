import React, { useEffect, useState } from 'react';
import './Range.css';
import PropTypes from 'prop-types';

const Range = (props) => {
  const {
    value, options, onChange,
  } = props;

  const [currentValue, setValue] = useState(value);

  const partLength = 100 / (options.length - 1);
  const values = options.map((option) => String(option.value));

  useEffect(() => {
    //console.log(currentValue);
  }, [currentValue]);

  const getBar = () => {
    if (!currentValue) return;
    let from;
    let to;
    if (typeof currentValue === 'object') {
      from = values.indexOf(String(currentValue.from));
      to = values.indexOf(String(currentValue.to));
    } if (typeof currentValue === 'string') {
      from = 0;
      to = values.indexOf(String(currentValue));
    }
    return { from, to, length: to - from };
  };

  const isTitleSelected = (option) => {
    if (!currentValue) return false;
    if (typeof currentValue === 'object') {
      if (currentValue.from === option.value || currentValue.to === option.value) return true;
    } else if (String(currentValue) === option.value) return true;
  };

  const renderBar = () => {
    const bar = getBar();
    if (!bar) return null;
    const left = `${bar.from * partLength}%`;
    const width = `${bar.length * partLength}%`;
    return (
      <div className="range-options-bar-full" style={{ left, width }}>
        {/* <div className="range-options-handle range-handle-start" /> */}
        <div className={`range-options-handle range-handle-end ${width === '0%' ? 'range-handle-left' : ''} ${width === '100%' ? 'range-handle-right' : ''}`} />
      </div>
    );
  };

  return (
    <div className="range">
      <div className="range-options">
        <div className="range-options-titles">
          {
            options.map((option, key) => (
              <div
                key={key}
                onClick={() => {
                  setValue(option.value);
                  onChange(option.value);
                }}
                className={`range-options-title ${isTitleSelected(option) ? 'range-selected' : ''}`}
              >
                {option.title}
              </div>
            ))
          }
        </div>
        <div className="range-options-bar">
          {renderBar()}
        </div>
      </div>
    </div>
  );
};

Range.propTypes = {
  value: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
};

export default Range;
