import React from 'react';
import { useTranslation } from 'utils/modifiedTranslation';
import peopleIcon from 'new-ui/assets/icons/people.svg';
import './CommunityTag.css';

const CommunityTag = () => {
  const { t } = useTranslation();
  return (
    <div className="community-tag">
      <img alt="people" src={peopleIcon} />
      {t('patients_like_you.leal_community')}
    </div>
  );
};

export default CommunityTag;
