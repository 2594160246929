import React from 'react';
import './Playground.css';
import SearchForm from 'new-ui/Components/SearchForm';

const Playground = ()=>{
  return (
    <div className="playground">
      <SearchForm onChange={(user)=>{
        console.log('SearchForm :: onChange :: ', user);
      }}
      />
    </div>
  );
};

export default Playground;
