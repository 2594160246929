import React, { useEffect, useState } from 'react';
import './style.css';
import { useTranslation } from 'utils/modifiedTranslation';
import NeutralIcon from 'new-ui/assets/icons/sentiment_neutral_v2.svg';
import SatisfiedIcon from 'new-ui/assets/icons/sentiment_satisfied_v2.svg';
import DissatisfiedIcon from 'new-ui/assets/icons/sentiment_very_dissatisfied_v2.svg';
import Select from 'new-ui/Components/Form/Select';

const aggressionTypes = [
  { id:1, value: 'not_aggressive', label: 'Not Intense', icon: NeutralIcon, key: 'not_intense' },
  { id:2, value: 'somewhat_aggresive', label: 'Somewhat Intense', icon: SatisfiedIcon, key: 'somewhat_intense' },
  { id:3, value: 'very_aggressive', label: 'Very Intense', icon: DissatisfiedIcon, key: 'very_intense' }
];

const TreatmentIntensity = ({ sideEffects, onChangeAggression, onChangeSideEffect, reset }) => {
  const { t } = useTranslation();

  const [selectedSideEffects, setSelectedSideEffects] = useState([]);
  const [selectedIntensity, setSelectedIntensity] = useState(null);

  const handleSideEffectChange = (newValue) => {
    setSelectedSideEffects(newValue);
    onChangeSideEffect(newValue);
  };
  const handleClearSideEffect = () => {
    setSelectedSideEffects([]);
  };
  const handleIntensityClick = (index, value) => {
    setSelectedIntensity(index);
    onChangeAggression(value);
  };

  useEffect(() => {
    if (reset) {
      setSelectedSideEffects([]);
      setSelectedIntensity(null);
    }
  }, [reset]);


  return (
    <div className="intensity-container">
      <h2 className='title'>{t('treatment_experience.aggression_description')}</h2>
      <ul className='intensity-list'>
        {aggressionTypes.map((item, index) => (
          <li key={index} className={`intensity-item ${selectedIntensity === index ? 'selected' : ''}`} onClick={() => handleIntensityClick(index, item.value)}>
            <img src={item.icon} alt='icon' />
            <span className='label'>{t(`treatment_experience.${item.key}`)}</span>
          </li>
        ))}
      </ul>
      <div className='effect-select'>
          <h2 className='title'>{t('treatment_experience.side_effects_description')}</h2>
          <Select
              options={sideEffects}
              value={selectedSideEffects}
              onChange={handleSideEffectChange}
              onClear={handleClearSideEffect}
              placeholder={t('experience_widget.side_effect.placeholder')}
              autocomplete={true}
              autocompletePlaceholder="Start typing..."
              isTags={false}
              multi={true}
              withCheckbox={true}
          />
      </div>
    </div>
  );
};

export default TreatmentIntensity;