import React from 'react';

import app from 'new-ui/app';

import Button from 'new-ui/Components/Button';

import { THEMES, POPUP_TYPES } from 'new-ui/constants';
import { useTranslation } from 'utils/modifiedTranslation';
import PropTypes from 'prop-types';

export const PROFILE_ACTIONS = {
  MISSING_CRITICAL: 'MISSING_CRITICAL',
  CONSULT_WITH_US: 'CONSULT_WITH_US',
  USER_INTENT: 'USER_INTENT',
  NEXT_APPOINTMENT: 'NEXT_APPOINTMENT',
  TREATMENTS: 'TREATMENTS',
  SHARE_WITH_DOCTOR: 'SHARE_WITH_DOCTOR',
};

export const profileActionsData = {
  [PROFILE_ACTIONS.MISSING_CRITICAL]: {
    condition: () => app.hasTrialsMissingCritical(),
    name: 'missing_critical',
    action: () => {
      app.setPopup(POPUP_TYPES.MISSING_CRITICAL_INFORMATION);
    },
  },
  [PROFILE_ACTIONS.CONSULT_WITH_US]: {
    condition: () => app.trials.some((a) => a.isSponsored),
    name: 'consult_with_us',
    action: () => {
      app.setPopup(POPUP_TYPES.CONSULT_WITH_US);
    },
  },
  [PROFILE_ACTIONS.USER_INTENT]: {
    condition: () => !app.coniguration?.data?.userIntent,
    name: 'user_intent',
    action: () => {
      app.setPopup(POPUP_TYPES.USER_INTENT);
    },
  },
  [PROFILE_ACTIONS.NEXT_APPOINTMENT]: {
    condition: () => !app?.coniguration?.data?.lastNextAppointment
      || (app?.coniguration?.data?.lastNextAppointment
        && (new Date().getTime() - new Date(app?.coniguration?.data?.lastNextAppointment).getTime()) > 2.592e+9), // last appointment date passed more than 30 days ago
    name: 'next_scan',
    action: () => {
      app.setPopup(POPUP_TYPES.NEXT_APPOINTMENT);
    },
  },
  [PROFILE_ACTIONS.TREATMENTS]: {
    condition: () => !app.user?.condition_profile?.treatments?.length,
    name: 'treatments',
    action: () => {
      app.editQuestion('treatments', async () => {
        app.setShowPreloader(true);
        await app.update({ rematch: true });
        app.renderPage();
        app.setShowPreloader(false);
        app.setPopup(POPUP_TYPES.MEDICAL_PROFILE);
      });
    },
  },
  [PROFILE_ACTIONS.SHARE_WITH_DOCTOR]: {
    condition: () => true,
    name: 'share_with_doctor',
    action: () => {
      app.setPopup(POPUP_TYPES.SHARE_WITH_DOCTOR);
    },
  },
};

const forEachProfileActions = (fn = () => { }) => {
  for (const actionName in PROFILE_ACTIONS) {
    fn(profileActionsData[actionName]);
  }
};

export const getProfileActions = () => {
  const actions = [];
  forEachProfileActions((action) => {
    if (action.condition()) {
      actions.push(<MedicalProfileAction key={action.name} name={action.name} action={action.action} />);
    }
  });
  return actions;
};

export const getUnreadActionsNum = () => {
  if (!app.trials || !app.trials.length) return 0;
  const all = Object.keys(PROFILE_ACTIONS);
  let n = all.length;
  forEachProfileActions((action) => {
    if (!action.condition()) n--;
  });
  return n;
};

window.__getUnreadActionsNum = getUnreadActionsNum;

export const MedicalProfileAction = (props) => {
  const { name, action } = props;
  const { t } = useTranslation();
  return (
    <div className="action space-between">
      <div>{t(`medical_profile.actions.${name}.text`)}</div>
      <Button
        theme={THEMES.ORANGE}
        action={action}
        title={t(`medical_profile.actions.${name}.button`)}
      />
    </div>
  );
};

MedicalProfileAction.propTypes = {
  name: PropTypes.string,
  action: PropTypes.func,
};
