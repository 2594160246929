const canUseDOM = () => {
  if (
    typeof window === 'undefined'
    || !window.document
    || !window.document.createElement
  ) {
    return false;
  }
  return true;
};

const insertScript = (defer, chatKey, onLoaded) => {
  const script = document.createElement('script');
  if (defer) {
    script.defer = true;
  } else {
    script.async = true;
  }
  script.id = 'ze-snippet';
  script.src = `https://static.zdassets.com/ekr/snippet.js?key=${chatKey}`;
  script.addEventListener('load', () => {
    if (typeof onLoaded === 'function') {
      onLoaded();
    }
  });
  document.body.appendChild(script);
};

const updateSettings = (settings) => {
  if (!window.zE) {
    return;
  }
  try {
    window.zE('webWidget', 'updateSettings', { ...settings });
  } catch (ex) {
    console.warn('ZD webWidget', ex);
  }
};

const showLauncher = () => {
  updateSettings(
    {
      chat: {
        suppress: false,
      },
    },
  );
};

const hideLauncher = () => {
  updateSettings(
    {
      chat: {
        suppress: true,
      },
    },
  );
};

export default {
  canUseDOM,
  insertScript,
  updateSettings,
  showLauncher,
  hideLauncher,
};
