import configs from '../configs';

const {
  publicServer: {
    webappUrl,
    sponsorAssetsPath,
    providerAssetsPath,
  },
} = configs;

export const getSponsorsAssetUrl = (fileName) => (
  `${webappUrl}${sponsorAssetsPath}${fileName}`
);

export const getProvidersAssetUrl = (fileName) => (
  `${webappUrl}${providerAssetsPath}${fileName}`
);
