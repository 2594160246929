import { OPENED_VIA, REDUX_ACTIONS } from './constants';

const initialState = {
  open: false,
  openedVia: null,
  dialogName: null,
  dialogData: null,
  props: {},
  theme: null
};

export function dialog(state = initialState, action) {
  switch (action.type) {
    case REDUX_ACTIONS.DIALOG_DATA_SET:
      return {
        ...state,
        dialogData: action?.payload
      };
    case REDUX_ACTIONS.DIALOG_DATA_CLEAR:
      return {
        ...state,
        open: state.openedVia === OPENED_VIA.REDUX_ACTION ? false : state.open,
        openedVia: state.openedVia === OPENED_VIA.REDUX_ACTION ? null : state.openedVia,
        dialogData: null,
        theme: null
      };
    case REDUX_ACTIONS.DIALOG_TOGGLE:
      return {
        ...state,
        open: !state.open,
        openedVia: action?.payload?.openedVia || null,
        dialogName: action?.payload?.dialogName || null,
        theme: action?.payload?.theme || null,
        props: action?.payload?.props || {}
      };
    default:
      return state;
  }
}
