export const Profile = {
  type_of_cmml: 'type_of_cmml',
  dob: 'dob',
  gender: 'gender',
  race: 'race',
  disease_status: 'disease_status',
  type_of_mpn: 'type_of_mpn',
  category_of_cmml: 'category_of_cmml',
  percentage_of_blasts: 'percentage_of_blasts',
  treatments: 'treatments',
  risk_group: 'risk_group',
  type_of_mf: 'type_of_mf',
  intermediate_risk_subgroup: 'intermediate_risk_subgroup',
  has_biopsy: 'has_biopsy',
  splenomegaly: 'splenomegaly',
  splenic_radiation: 'splenic_radiation',
  splenic_radiation_date: 'splenic_radiation_date',
  platelet_count: 'platelet_count',
  received_allogenic_transplant: 'received_allogenic_transplant',
  allogenic_treatment_date: 'allogenic_treatment_date',
  allogenic_transplant_outcome: 'allogenic_transplant_outcome',
  treatment_for_mpn: 'treatment_for_mpn',
  drugs_received: 'drugs_received',
  cycles: 'cycles',
  treatment_end_date: 'treatment_end_date',
  treatment_stop_reason: 'treatment_stop_reason',
  supportive_care_type: 'supportive_care_type',
  number_of_blood_transfusions: 'number_of_blood_transfusions',
  last_blood_transfusion_received_date: 'last_blood_transfusion_received_date',
  current_treatment_systemic_steroids: 'current_treatment_systemic_steroids',
  systemic_steroids_treatment_end_date: 'systemic_steroids_treatment_end_date',
  ecog: 'ecog',
  ngs: 'ngs',
  gvhd: 'gvhd',
  other_medical_condition: 'other_medical_condition',
  omc_liver: 'omc_liver',
  omc_heart: 'omc_heart',
  omc_stroke_date: 'omc_stroke_date',
  omc_kidney: 'omc_kidney',
  omc_autoimmune: 'omc_autoimmune',
  omc_mental: 'omc_mental',
  omc_allergy: 'omc_allergy',
  omc_chronic_infection_inflammation: 'omc_chronic_infection_inflammation',
  omc_gastro_problem: 'omc_gastro_problem',
  omc_lung_disease: 'omc_lung_disease',
  omc_abnormal_blood_results: 'omc_abnormal_blood_results',
  other_medications: 'other_medications',
  is_pregnant: 'is_pregnant',
  is_breastfeeding: 'is_breastfeeding',
  have_insurance: 'have_insurance',
  insurances: 'insurances',
};
