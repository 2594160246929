import every from 'lodash/every';
import { isNull } from 'lodash/lang';
import isObject from 'lodash/isObject';
import globalEnums from '../constants/globalEnums';
import formatMonthPickerValue from './formatMonthPickerValue';
import getFormValue from './getFormValue';
import { tripleSingleSelectQuestions } from '../constants/trippleSelectQuestions';
import {
  BIOMARKERS,
  COMPOSITE, LOCATION,
  MESSAGE, MONTH_PICKER,
  MULTIPLE_DROP_DOWN_SELECT,
  MULTIPLE_OPTION_SEARCH_SELECT,
  MULTIPLE_OPTION_SELECT, NUMBER_INPUT,
  OPTIONS_FROM_SERVER, PROCEDURES,
  SIMPLE_COMPOSITE,
  SIMPLE_OPTION_SELECT,
  SINGLE_OPTION_SELECT,
  TREATMENT_HISTORY,
  TRIPLE_QUESTION_DROP_DOWN,
} from '../constants/questionTypes';
import { findQuestion } from './findQuestion';

export const mapFormToConditionProfile = (form, questions) => {
  if (!Array.isArray(questions) && questions !== false) {
    throw new Error('Questions must be an array');
  }
  const conditionProfile = {};
  for (const questionId in form) {
    const question = findQuestion(questions, questionId);

    let formValue = form[questionId];

    switch (question?.type) {
      case COMPOSITE:
      case PROCEDURES:
      case TREATMENT_HISTORY:
      case SIMPLE_COMPOSITE:
        formValue = (formValue ?? []).map((form) => mapFormToConditionProfile(form, Array.isArray(question.subQuestions) ? question.subQuestions : false));
        break;
      case TRIPLE_QUESTION_DROP_DOWN:
      case BIOMARKERS:
      case SIMPLE_OPTION_SELECT:
      case SINGLE_OPTION_SELECT:
      case MULTIPLE_OPTION_SEARCH_SELECT:
      case MULTIPLE_OPTION_SELECT:
      case MULTIPLE_DROP_DOWN_SELECT:
      case OPTIONS_FROM_SERVER:
        formValue = getFormValue(formValue, true);
        break;
      case MONTH_PICKER:
        formValue = formatMonthPickerValue(formValue)?.format() ?? null;
        break;
      case NUMBER_INPUT:
        formValue = isNull(formValue) ? formValue : Number(formValue);
        break;
      case LOCATION:
      case MESSAGE:
      default:
        break;
    }
    if ([globalEnums.treatments.questionId, globalEnums.procedures.questionId].includes(questionId)) {
      formValue = (formValue ?? []).map((i) => mapFormToConditionProfile(i, false));

      // filter out empty records or records that don't have required fields
      if (questionId === globalEnums.treatments.questionId) {
        // const drugReceived = globalEnums.treatments.subQuestionIds.drugs_received;
        // formValue = formValue.filter(
        //   (treatment) => Array.isArray(treatment?.[drugReceived]) && treatment?.[drugReceived].length,
        // );
        // formValue.forEach((treatment) => {
        //   if (treatment) {
        //     // eslint-disable-next-line no-param-reassign
        //     treatment[drugReceived] = treatment?.[drugReceived].length === 1
        //       ? treatment?.[drugReceived][0].split('+')// in case it is a combination drug1+drug2
        //       : treatment?.[drugReceived];
        //   }
        // });
        formValue = formValue.filter((treatment) => (
          Array.isArray(treatment?.[globalEnums.treatments.subQuestionIds.drugs_received])
          && treatment?.[globalEnums.treatments.subQuestionIds.drugs_received].length
        ));
      }
      if (questionId === globalEnums.procedures.questionId) {
        formValue = formValue.filter(
          (procedure) => Array.isArray(procedure?.[globalEnums.procedures.subQuestionIds.procedures_received])
            && procedure?.[globalEnums.procedures.subQuestionIds.procedures_received].length,
        );
      }
    } else if (Array.isArray(formValue) && every(formValue, 'value')) {
      formValue = getFormValue(formValue);
    } else if (['taking_state', 'updated_taking_state_date', 'survey_answered', 'date_of_adding'].includes(questionId)) {
      formValue = form[questionId];
    }
    if (isObject(formValue) && !Array.isArray(formValue) && formValue !== null) {
      if (formValue.month || formValue.year) {
        formValue = formatMonthPickerValue(formValue)?.format() || null;
      } else if (formValue?.value) {
        formValue = formValue?.value;
      }
    }

    if (tripleSingleSelectQuestions.includes(questionId)) {
      formValue = Array.isArray(formValue) && formValue.length ? formValue[0] : null;
    }

    conditionProfile[questionId] = formValue;
  }

  return conditionProfile;
};
