export default [
  {
    label: 'date_picker.month.january',
    value: 0
  },
  {
    label: 'date_picker.month.february',
    value: 1
  },
  {
    label: 'date_picker.month.march',
    value: 2
  },
  {
    label: 'date_picker.month.april',
    value: 3
  },
  {
    label: 'date_picker.month.may',
    value: 4
  },
  {
    label: 'date_picker.month.june',
    value: 5
  },
  {
    label: 'date_picker.month.july',
    value: 6
  },
  {
    label: 'date_picker.month.august',
    value: 7
  },
  {
    label: 'date_picker.month.september',
    value: 8
  },
  {
    label: 'date_picker.month.october',
    value: 9
  },
  {
    label: 'date_picker.month.november',
    value: 10
  },
  {
    label: 'date_picker.month.december',
    value: 11
  }
];
