import React from 'react';
import './style.css';
import { TREATMENT_GROUPS } from 'new-ui/constants';
import { interventional_types } from 'new-ui/TreatmentOptions/options';
import app from 'new-ui/app';
import { PAGES } from 'new-ui/constants';


const generateTypesMap = (options) => {
  return options.reduce((acc, { label, color }) => {
    acc[label.toLowerCase()] = color;
    return acc;
  }, {});
}

const itemsMap = generateTypesMap(interventional_types);

const getBackgroundColor = (option) => {
  return itemsMap[option.toLowerCase()] || 'white';
};

function sortArrayAlphabetically(arr) {
  return arr.sort();
}

const filterOptions = (options) => {
  const isSearchPage = app.isCurrentPage(PAGES.SEARCH);

  const majorCategories = options.filter(item => TREATMENT_GROUPS.includes(item));
  const otherCategories = options.filter(item => !TREATMENT_GROUPS.includes(item));

  const sortedMajorCategories = sortArrayAlphabetically(majorCategories);
  const sortedOtherCategories = sortArrayAlphabetically(otherCategories);

  if (isSearchPage) {
    return [...sortedMajorCategories, ...sortedOtherCategories];
  } else {
    return sortedMajorCategories;
  }
};

const InterventionalTypes = ({ options }) => {
  return (
    <ul className='interventional-list'>
      {filterOptions(options).map(option => (
        <li key={option} style={{ backgroundColor: getBackgroundColor(option) }}>
          {option}
        </li>
      ))}
    </ul>
  );
};

export default InterventionalTypes;