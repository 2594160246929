import React, { useState } from 'react';
import './SearchDoctor.css';

import { LOCAL_STORAGE_KEYS, POPUP_TYPES } from 'new-ui/constants';

import { PopupTitle, PopupContent, PopupButton } from 'new-ui/Components/Popup';
import Form, { FORM_INPUT_TYPES } from 'new-ui/Components/Form';

// import { useTranslation } from 'utils/modifiedTranslation';

import app from 'new-ui/app';
import { getDoctors } from 'new-ui/api/doctor';
import { useTranslation } from 'utils/modifiedTranslation';

const capitalizeFirstLetters = (firstName, lastName) => {
  const capitalizedFirstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
  const capitalizedLastName = lastName.charAt(0).toUpperCase() + lastName.slice(1);

  return `${capitalizedFirstName} ${capitalizedLastName}`;
};

const SearchDoctor = () => {
  const { t } = useTranslation();
  const [results, setResults] = useState([]);

  const getDoctorName = () => document.querySelector('.search-doctor input').value;

  const form = {
    inputs: {
      name: {
        type: FORM_INPUT_TYPES.INPUT,
        placeholder: t('doctor.add_your_doc_textbox_placement'),
        icon: '', // this accepts icon
      },
    },
    data: {
      name: '',
    },
  };

  const onChange = async () => {
    const { name } = form.data;

    if (typeof name === 'string' && !!name.length) {
      const response = await getDoctors({ name, limit: 50 });
      setResults(response.data);
    } else if (typeof name === 'string' && !name.length) {
      setResults([]);
    }
  };

  const addDoctor = (name) => {
    app.setPopup(POPUP_TYPES.ADD_DOCTOR, {
      doctor: {
        firstName: name,
      },
    });
  };

  return (
    <div className="search-doctor">
      <PopupTitle title={t('doctor.add_your_doc_header')} />
      <PopupContent>
        <div className="title">{t('doctor.add_your_doc_textbox_title')}</div>
        <div className="input">
          <Form form={form} onChange={onChange} />
        </div>
        <div className="results">
          {results && results.map((result, i) => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <div
              key={i}
              onClick={() => {
                localStorage.setItem(
                  LOCAL_STORAGE_KEYS.DOCTOR_ADDED,
                  JSON.stringify({
                    email: result.email,
                    firstName: result.first_name,
                    clinic: result.clinic_name,
                  }),
                );
                app.setPopup(POPUP_TYPES.SEND_TO_DOCTOR, { result });
              }}
              className="result"
            >
              <p className="name">
                {capitalizeFirstLetters(
                  result?.first_name.toLowerCase(),
                  result?.last_name?.toLowerCase() || '',
                )}
              </p>
              <p className="clinic">{result?.state || ''}</p>
            </div>
          ))}
          {results && !!results.length && (
            <span
              role="button"
              tabIndex={-1}
              onClick={() => addDoctor(getDoctorName())}
              onKeyDown={() => addDoctor(getDoctorName())}
              className="cant-find"
            >
              {t('doctor.add_your_doc_cant_find')}
            </span>
          )}
        </div>
      </PopupContent>
      {
        !results.length ? (
          <PopupButton
            title={t('doctor.box_add_doc_button')}
            action={
              () => addDoctor(getDoctorName())
            }
          />
        ) : null
      }

    </div>
  );
};

export default SearchDoctor;
