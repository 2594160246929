import configs from 'configs';
import { authHeaders } from 'functions';
import request from 'functions/api/request';

/**
 * @param jobId string
 * @param profileId string
 * @param documents Array.<{ name: string, storageName: string}>
 * @param source string
 * @return {Promise<void>}
 */
export const addUserDocuments = async ({
  jobId, profileId, files, source,
}) => {
  const documents = files.map(({ name, storageName }) => ({
    fileName: storageName,
    originFileName: name,
    source,
  }));

  const requestOptions = {
    url: `${configs.beApiUrl}/profile/user/documents`,
    data: {
      jobId,
      profileId,
      documents,
    },
    headers: {
      ...await authHeaders(),
    },
  };

  try {
    await request.post(requestOptions);
  } catch (e) {
    console.error('Add documents error', e);
  }
};

export const uploadUserDocuments = async (
  {
    profileId, files, source, shouldWaitForDocumentsAnalyzing = false,
  },
  { timeout } = {},
) => {
  const formData = new FormData();

  formData.append('parameters', JSON.stringify({ profileId, source, shouldWaitForDocumentsAnalyzing }));

  files.filter((file) => !!file).forEach((file, index) => {
    formData.append(`file_${file.lastModified}_${index}`, file, file.name);
  });

  const requestOptions = {
    url: `${configs.beApiUrl}/profile/userDocuments`,
    data: formData,
    headers: {
      ...await authHeaders(),
    },
    timeout,
  };
  return request.post(requestOptions);
};

export const removeUserDocuments = async (profileId, fileNames) => {
  const requestOptions = {
    url: `${configs.beApiUrl}/profile/userDocuments`,
    data: { profileId, fileNames },
    headers: {
      ...await authHeaders(),
    },
  };
  return request.del(requestOptions);
};
