/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React from 'react';
import './IconLine.css';
import PropTypes from 'prop-types';

const IconLine = (props) => {
  const {
    icon, title, last, onClick, className = '', inline,
  } = props;
  const properties = {};
  if (onClick) properties.onClick = onClick;
  return (
    <div className={`icon-line middle ${last ? 'no-margin' : ''} ${onClick ? 'cursor' : ''} ${className} ${inline ? 'inline' : ''}`} {...properties}>
      <div>
        <img src={require(`../../assets/icons/${icon || 'bullet'}.svg`)} alt="" />
      </div>
      <span>{title}</span>
    </div>
  );
};

IconLine.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  last: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  isBullet: PropTypes.bool,
  inline: PropTypes.bool,
};

export default IconLine;
