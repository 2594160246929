export const QuestionIndex = {
  treatment_search: 0,
  last_treatment: 1,
  dialod: 2,
  atantionDialog: 3,
  prev_treatment: 4,
};

export const DIALOG_QUESTION_TYPES = {
  stillTaking: 'stillTaking',
  stopReason: 'stopReason',
  metastatic: 'metastatic',
  treatmentAdmin: 'treatmentAdmin',
  receiveCombinations: 'receiveCombinations',
  combinations: 'combinations',
  notListedCombinations: 'notListedCombinations',
  canCreateCombination: 'canCreateCombination',
  canNotCreateCombination: 'canNotCreateCombination',
};

export const OTHER_TREATMENTS_QUESTION_TYPES = {
  systemic: 'systemic',
  neoadjuvant: 'neoadjuvant',
  adjuvant: 'adjuvant',
  maintenance: 'maintenance',
};

export const advancedMetastaticDiseaseOptions = [
  {
    label: 'questionnaire.questions.treatment_for_advanced_metastatic_disease.options.yes',
    value: 'yes',
  },
  {
    label: 'questionnaire.questions.treatment_for_advanced_metastatic_disease.options.no',
    value: 'no',
  },
  {
    label: 'questionnaire.questions.treatments.options.i_dont_know',
    value: 'i_dont_know',
  },
];

export const DIALOG_TYPES = {
  flow: 'flow',
  attention: 'attention',
  not_listed_combination: 'not_listed_combination',
};

export const metastaticId = 'treatment_for_advanced_metastatic_disease';
export const treatmentAdminId = 'treatment_admin';

export const flowQuestions = [
  {
    type: DIALOG_QUESTION_TYPES.stillTaking,
    questionTitle: 'questionnaire.questions.treatment.dialog.title.still_taking',
  },
  {
    type: DIALOG_QUESTION_TYPES.stopReason,
    questionTitle: 'questionnaire.questions.treatment.dialog.title.stop_reason',
  },
  {
    type: DIALOG_QUESTION_TYPES.metastatic,
    questionTitle: 'questionnaire.questions.treatment.dialog.title.metastatic',
  },
  {
    type: DIALOG_QUESTION_TYPES.treatmentAdmin,
    questionTitle: 'questionnaire.questions.treatment.dialog.title.treatmentAdmin',
  },
];

export const attentionQuestions = [
  {
    type: DIALOG_QUESTION_TYPES.receiveCombinations,
    questionTitle: 'questionnaire.questions.treatment.dialog.title.receiveCombinations',
  },
  {
    type: DIALOG_QUESTION_TYPES.combinations,
    questionTitle: 'questionnaire.questions.treatment.dialog.title.combinations',
  },
];

export const notListedQuestions = [
  {
    type: DIALOG_QUESTION_TYPES.notListedCombinations,
    questionTitle: 'questionnaire.questions.treatment.dialog.title.notListedCombinations',
    questionSecontTitle: 'questionnaire.questions.treatment.dialog.subtitle.notListedCombinations',
  },
  {
    type: DIALOG_QUESTION_TYPES.canCreateCombination,
    questionTitle: 'questionnaire.questions.treatment.dialog.title.canCreateCombination',
  },
  {
    type: DIALOG_QUESTION_TYPES.canNotCreateCombination,
    questionTitle: 'questionnaire.questions.treatment.dialog.title.canNotCreateCombination',
  },
];

export const dontRememberButtonType = {
  any: 'any',
  all: 'all',
};

export const dontRememberButtons = [
  {
    type: dontRememberButtonType.all,
    label: 'questionnaire.questions.treatment.main.dont_remember_all_btn',
    canRender: (optionListValues) => !optionListValues.length,
  },
  // {
  //   type: dontRememberButtonType.any,
  //   label: 'questionnaire.questions.treatment.main.dont_remember_any_btn',
  //   canRender: (optionListValues) => !!optionListValues.length,
  // },
];
