import React, { useState, useEffect } from 'react';

import app from 'new-ui/app';
import {
  ROUTES, PAGES, THEMES, INTERCOM_EVENTS,
} from 'new-ui/constants';

import Lottie from 'lottie-react';
import LottieAnimation from 'new-ui/assets/lottie/NGS.json';

import { useTranslation } from 'utils/modifiedTranslation';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import useRecaptcha from 'utils/hooks/useRecaptcha';
import FilesUploader, { UPLOADER_STATES } from 'new-ui/Components/FilesUploader';
import Overlay from 'new-ui/Components/Overlay';
import Form, { FORM_VALIDATORS, FORM_INPUT_TYPES } from 'new-ui/Components/Form';
import Button from 'new-ui/Components/Button';
import Biomarkers from 'new-ui/Components/Biomarkers';
import { getSearchApi } from 'new-ui/Search/api';
import {
  getBiomarkerTitle,
  getInitialConditionProfileByIndication,
  // randomString,
  signup,
} from 'new-ui/functions';
import {
  // getBiomarkers,
  getIndications,
} from 'new-ui/api';

import ArrowCirlce from 'new-ui/assets/icons/arrow-circle-right.svg';
import InfoIcon from 'new-ui/assets/icons/info.svg';
import Preloader from 'new-ui/Components/Preloader';
import NGSLogo from './assets/NGS_logo.svg';

import './NGS.css';
import { uploadNGS } from './api';
import Marketing from './Marketing/Marketing';
import NGSHeader from './NGSHeader/NGSHeader';

import {
  STORAGE_SEARCH_BIOMARKERS,
  STORAGE_SEARCH_INDICATION,
} from 'new-ui/Search/SearchParameters';
import { authenticationActions } from '../../components/Authentication/actions';
import { addUserDocuments } from '../../modules/userDocuments/api';
import { waitUntil } from '../../components/MedicalFiles/utils';
import { getQstack } from 'new-ui/Components/Intake/functions';
import { deduplicateList } from '../../functions/deduplicateList';
import getLatestDate from '../../functions/getLatestDate';
import moment from 'moment';

export const ACCEPTED_FILE_TYPES = [
  'jpg',
  'jpeg',
  'png',
  'tif',
  'tiff',
  'pdf',
];

const GA = {
  category: 'NGS',
  events: {
    pageview: 'page_view',
    button: 'button_click',
    general: 'general',
    form: 'form_filled',
  },
};

let form;
let user = null;
// let all_biomarkers;
let indications;
const uploader = {};

let indication;

const NGS = () => {
  let setMobileMenu = null;
  const [uploaderState, setUploaderState] = useState(UPLOADER_STATES.SELECT);
  const [showForm, setShowForm] = useState(false);
  const [isManualBiomarkers, setIsManualBiomarkers] = useState(false);
  const [showBiomarkers, setShowBiomarkers] = useState(false);
  const [formDisabled, setFormDisabled] = useState(true);
  const [formLoading, setFormLoading] = useState(false);
  const [signupError, setSignupError] = useState(null);
  const [signupComplete, setSignupComplete] = useState(false);
  const [userUpdateComplete, setUserUpdateComplete] = useState(false);
  const [biomarkers, setBiomarkers] = useState([]);
  const [job, setJob] = useState(null);
  const [formData, setFormData] = useState({
    email: '',
    indication: '',
  });
  const [results, setResults] = useState(null);
  const { recaptcha, getRecaptchaTokenAsync } = useRecaptcha();
  const { t } = useTranslation();
  const history = useHistory();

  // init
  useEffect(() => {
    localStorage.removeItem(STORAGE_SEARCH_BIOMARKERS);
    localStorage.removeItem(STORAGE_SEARCH_INDICATION);

    document.body.classList.add('ngs-page');

    app.setCustomHeader(<NGSHeader />);

    // const uid = randomString();

    // app.intercom.bootPublic({
    //   // user_id: uid,
    //   // email: `visitor_${uid}@leal.visitor`,
    // });

    const init = async () => {
      // all_biomarkers = await getBiomarkers();
      indications = await getIndications();
      form = {
        inputs: {
          email: {
            type: FORM_INPUT_TYPES.INPUT,
            required: true,
            icon: 'email',
            title: 'Email',
            placeholder: '',
          },
          indication: {
            title: 'Select type',
            placeholder: 'Select type',
            required: true,
            type: FORM_INPUT_TYPES.SELECT,
            autocomplete: true,
            options: indications.map((i) => ({
              title: i.title.indexOf('disease_name') !== -1 ? t(i.title) : i.title,
              value: i.value,
            })),
          },
        },
        data: {
          email: '',
          indication: [],
        },
      };
    };

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => () => {
    document.body.classList.remove('ngs-page');
    app.setCustomHeader(null);
  }, []);

  useEffect(() => {
    Array.from(document.querySelectorAll('.intercom-button')).forEach((a) => {
      a.classList[(
        uploaderState === UPLOADER_STATES.SELECT
        || uploaderState === UPLOADER_STATES.SUCCESS
        || uploaderState === UPLOADER_STATES.ERROR
      ) ? 'remove' : 'add']('hidden');
    });
    app.sendGoogleAnalyticsEvent(GA.category, GA.events.pageview, {
      name: uploaderState,
    });
    if (uploaderState !== UPLOADER_STATES.SELECT) {
      app.setCustomHeader(<img alt="NGS Logo" src={NGSLogo} />);
    }
  }, [uploaderState]);

  useEffect(() => {
    const updateUser = async () => {
      console.log('here', user);
      await app.store.dispatch(authenticationActions.updateUser(user, true));

      setUserUpdateComplete(true);

      await addUserDocuments({
        jobId: job.id,
        profileId: user.personal.profile_id,
        files: uploader.files,
        source: 'ngs',
      });
    };

    if (!!job && signupComplete && uploaderState === UPLOADER_STATES.SUCCESS) {
      updateUser();
    }
  }, [job, signupComplete, uploaderState]);

  const back = () => {
    uploader.setFiles([...uploader.files.filter((a) => !a.error)]);
    uploader.setState(UPLOADER_STATES.MULTI);
    app.sendGoogleAnalyticsEvent(GA.category, GA.events.general, {
      name: 'back_to_file_select_due_to_error',
    });
  };

  const header = () => {
    switch (uploaderState) {
      default:
      case UPLOADER_STATES.SELECT:
        return <p className="ngs-select-title-mobile">Upload your NGS file</p>;
      case UPLOADER_STATES.MULTI:
        return <div className="files-uploader-preview-title">Upload your NGS files</div>;
      case UPLOADER_STATES.UPLOADING:
        return (
          <>
            <div className="ngs-title">Generating your biomarkers report</div>
            <div className="ngs-analyze">
              <Lottie animationData={LottieAnimation} loop />
            </div>
            <div className="height-10" />
          </>
        );
      case UPLOADER_STATES.ERROR:
        return (
          <div className="ngs-upload-error">
            Something went wrong with uploading your NGS files, please try again or contact support
          </div>
        );
      case UPLOADER_STATES.SUCCESS:
        return <div className="ngs-title">Generating your biomarkers report</div>;
    }
  };

  const errorFooter = () => (
    <div>
      <Button title="Upload another file" action={back} />
      <div className="height-10" />
      <Button
        theme={THEMES.ORANGE}
        title="Answer Manually"
        action={() => {
          setShowBiomarkers(true);
        }}
      />
    </div>
  );

  const successFooter = () => (
    <div className="success-footer">
      <div className="ngs-analyze-found">
        {results !== null ? (
          <div>
            <div className="ngs-analyze-found-info">
              <img src={InfoIcon} alt="info" />
            </div>
            There are
            {' '}
            <b>{results}</b>
            {' '}
            targeted therapy treatments for you based on your biomarkers
          </div>
        ) : 'Searching for results...'}
      </div>
      {signupComplete && userUpdateComplete ? <Button title="Show my treatment options" action={gotoSearch} /> : null}
    </div>
  );

  const isErrored = (files = uploader.files) => {
    const erroredFiles = files.filter((a) => a.error);
    return erroredFiles.length === files.length;
  };

  const footer = () => {
    switch (uploaderState) {
      default:
      case UPLOADER_STATES.SELECT:
        return (
          <div>
            <Button
              className="answer-manually-button"
              title="Answer Manually"
              theme={THEMES.ORANGE}
              action={() => {
                setShowBiomarkers(true);
              }}
            />
          </div>
        );
      case UPLOADER_STATES.UPLOADING:
        return null;
      case UPLOADER_STATES.ERROR:
        return errorFooter();
      case UPLOADER_STATES.SUCCESS:
        return isErrored() ? errorFooter() : successFooter();
    }
  };

  const filerenderer = (file) => {
    // console.log('filerenderer', file);
    switch (uploaderState) {
      default:
      case UPLOADER_STATES.SELECT:
      case UPLOADER_STATES.UPLOADING:
      case UPLOADER_STATES.ERROR:
        return null;
      case UPLOADER_STATES.SUCCESS:
        return file.biomarkers && file.biomarkers.length ? (
          <>
            <div className="ngs-analyze-subtitle">
              in this document we identified the following biomarkers:
            </div>
            <div className="ngs-analyze-files-biomarkers">
              {
                file.biomarkers.map((biomarker, key) => (
                  <React.Fragment key={key}>
                    <div className="ngs-analyze-files-biomarker">
                      <div className="ngs-analyze-files-biomarker-name">{getBiomarkerTitle(biomarker)}</div>
                    </div>
                  </React.Fragment>
                ))
              }
            </div>
          </>
        ) : null;
    }
  };

  const fetchResults = async (biomarkers) => {
    let userId;
    try {
      userId = JSON.parse(localStorage.getItem('user')).user_wix_id;
    } catch (e) {
    }
    const r = await getSearchApi({
      biomarkers: biomarkers.length ? biomarkers : ['none'],
      condition: indication || null,
      country: null,
      distance: null,
      diseaseSubType: null,
      diseaseType: null,
      diseaseStatus: null,
      // isDefault: true,
      lang: 'en',
      patientStage: null,
      patientStageMet: null,
      patientStageMibc: null,
      patientStageNmibc: null,
      chromosomalAlterions: null,
      receivedAllogenicTransplant: null,
      receivedTreatment: null,
      numTreatmentLines: null,
      userId,
    });
    setResults((r?.trials?.result?.length || 0) + (r?.trials?.treatments?.length || 0));
  };

  const uploadError = () => {
    console.log('upload error');
    uploader.setState(UPLOADER_STATES.ERROR);
    app.sendGoogleAnalyticsEvent(GA.category, GA.events.general, { name: 'upload_error' });
  };

  const upload = async () => {
    const files = [...uploader.files];
    uploader.setState(UPLOADER_STATES.UPLOADING);

    uploadNGS(files, getRecaptchaTokenAsync, async (r) => {
      console.log('upload complete', r);

      if (r?.status === 'error') {
        app.intercom.sendEvent(INTERCOM_EVENTS.MEDICAL_FILES.ERROR);
        uploadError();
        return;
      }

      const uploadedFiles = r?.files;
      const conditionProfile = r?.profile?.condition_profile ?? {};
      const biomarkers = conditionProfile?.biomarkers ?? [];

      files.forEach((file) => {
        const uploadedFile = uploadedFiles.find((r) => r.name === file.name);

        if (!uploadedFile) {
          console.log('error: no matching file in analyzer result set', file, uploadedFiles);
          return;
        }

        file.storageName = uploadedFile?.storageName ?? '';
        file.biomarkers = uploadedFile?.profile?.condition_profile?.biomarkers ?? [];

        if (uploadedFile?.error) {
          file.error = uploadedFile.error;
          app.intercom.sendEvent(INTERCOM_EVENTS.NGS.FILE_NOT_READABLE);
        } else if (!file.biomarkers || !file.biomarkers.length) {
          file.error = "The NGS file doesn't contain any biomarkers";
          app.intercom.sendEvent(INTERCOM_EVENTS.NGS.NO_BIOMARKERS);
        }
      });

      await waitUntil(() => !!user, 200);

      user.condition_profile.biomarkers = biomarkers;

      await setUser(user);

      setJob(r);
      setBiomarkers(biomarkers);
      fetchResults(biomarkers);
      uploader.setFiles(files);
      uploader.setState(UPLOADER_STATES.SUCCESS);
      app.sendGoogleAnalyticsEvent(GA.category, GA.events.general, { name: 'upload_success' });

      app.intercom.updateUserAttributes({
        file_types: deduplicateList(r?.files?.map(({ type }) => type) ?? []),
        last_document_date: getLatestDate(r?.files?.map(({ documentDate }) => documentDate) ?? []),
        last_upload_date: moment().format('YYYY-MM-DD'),
      }, user?.personal?.user_wix_id);

      app.intercom.sendEvent(INTERCOM_EVENTS.MEDICAL_FILES.SUCCEED, {
        filesQuantity: r?.files?.length ?? 0,
      });
    }, () => {
      console.log('upload error');
      uploader.setState(UPLOADER_STATES.ERROR);
      app.sendGoogleAnalyticsEvent(GA.category, GA.events.general, { name: 'upload_error' });
      app.intercom.sendEvent(INTERCOM_EVENTS.MEDICAL_FILES.ERROR);
    });

    // setTimeout(() => {
    //   files[0].error = "We're sorry, but the document/photo you've provided does not contains biomarker.Would you like to upload more file?";
    //   files[1].biomarkers = ['braf'];
    //   uploader.setFiles(files);
    //   uploader.setState(UPLOADER_STATES.SUCCESS);
    // }, 3000);
  };

  const handleForm = async () => {
    const { email, indication } = formData;
    setFormLoading(false);
    app.sendGoogleAnalyticsEvent(GA.category, GA.events.form, {
      email,
      indication,
    });
    user = null;
    setSignupError(null);
    if (isManualBiomarkers) {
      setFormLoading(true);
    }
    const condition_profile = await getInitialConditionProfileByIndication(indication, {
      biomarkers,
    });
    signup({
      email,
      indication,
      recaptchaToken: await getRecaptchaTokenAsync(),
      condition_profile,
      onsignup: (newUser) => {
        user = newUser;
        setSignupComplete(true);
        console.log('user signup success', newUser);
        app.intercom.updateUserAttributes({
          email: formData.email,
          condition: formData.indication,
        }, user.info.user_wix_id);
        app.sendGoogleAnalyticsEvent(GA.category, GA.events.general, { name: 'user_signup_success' });
        if (isManualBiomarkers) return gotoSearch();
      },
      onerror: (e) => {
        setSignupError(true);
        setShowForm(true);
        setFormLoading(false);
        app.sendGoogleAnalyticsEvent(GA.category, GA.events.general, { name: 'user_signup_error' });
      },
    });
    if (isManualBiomarkers) return;
    setShowForm(false);
    // upload();
  };

  const setUser = async (user) => {
    // app.setShowPreloader(true);
    const questions = await app.getQuestions(user?.personal?.condition, true);
    app.user = user;
    const qstack = await getQstack(app.user, questions);
    app.user.condition_profile.qstack = qstack;
    await app.updateUser(app.user);
    // app.setShowPreloader(false);
  };

  const gotoSearch = async () => {
    if (app.isIntakeFlow(indication)) { // todo: remove
      history.push(`${ROUTES[PAGES.RESULTS]}?signup=1&rematch=1`);
      return;
    }
    app.sendGoogleAnalyticsEvent(GA.category, GA.events.button, { name: 'goto_search_button' });
    localStorage.setItem(STORAGE_SEARCH_INDICATION, formData.indication || null);
    localStorage.setItem(STORAGE_SEARCH_BIOMARKERS, JSON.stringify(biomarkers));
    history.push(`${ROUTES[PAGES.SEARCH]}?source=ngs`);
  };

  const SelectStateHeader = () => {
    if (uploaderState !== UPLOADER_STATES.SELECT) {
      return null;
    }

    return (
      <div className="ngs-progress-section">
        <img alt="NGS Logo" src={NGSLogo} />
        <div className="ngs-title">Precise treatment options in minutes</div>
        <div className="ngs-progress">
          <div className="container">
            <div className="item">
              <div className="index active">01</div>
              <p className="subtitle">Take a picture & Upload your NGS report</p>
            </div>
            <div className="item">
              <div className="index">02</div>
              <p className="subtitle">Instantly receive identified list of biomarkers</p>
            </div>
          </div>
          <div className="container">
            <div className="item">
              <div className="index">03</div>
              <p className="subtitle">Review precise & personalises treatment options</p>
            </div>
            <div className="item">
              <div className="index">04</div>
              <p className="subtitle">Start treatment</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`ngs ${uploaderState.toLowerCase()}`}>
      <div className="ngs-middle">
        <SelectStateHeader />
        <FilesUploader
          isMulti
          openMobileMenu={(setState) => {
            setMobileMenu = setState;
          }}
          onstatechange={setUploaderState}
          header={header()}
          footer={footer()}
          filerenderer={filerenderer}
          uploader={uploader}
          fileTypes={ACCEPTED_FILE_TYPES}
          fileTypesError="We're sorry, the file you provided is not valid for NGS reading, the acceptable file types are: PDF, JPEG, PNG & TIFF"
          onfilesselected={() => {
            app.sendGoogleAnalyticsEvent(GA.category, GA.events.general, { name: 'file_selected' });
          }}
          onselect={() => {
            app.sendGoogleAnalyticsEvent(GA.category, GA.events.button, { name: 'files_selection_next_button' });
            upload();
            setShowForm(true);
          }}
          onFileError={() => {
            app.intercom.sendEvent(INTERCOM_EVENTS.NGS.WRONG_FORMAT);
          }}
        />
        {recaptcha}
      </div>
      {showForm ? (
        <Overlay
          className="ngs-overlay"
          onclose={() => {
            setShowForm(false);
          }}
        >
          <div className="ngs-form-page">
            {recaptcha}
            <div className="ngs-subtitle">Add your email to save and see your results</div>
            <div className="ngs-form">
              <Form
                form={{
                  ...form,
                  data: formData,
                }}
                onChange={
                  (data) => {
                    indication = data.indication;
                    setFormData({ ...data });
                    const isEmailInvalid = !FORM_VALIDATORS.EMAIL(data.email);
                    setFormDisabled(isEmailInvalid || data.indication.length === 0);
                  }
                }
              />
            </div>
            {signupError ? (
              <div className="ngs-form-error">
                Oops, Registration error. Please contact support
              </div>
            ) : null}
            <div className="ngs-footer ngs-form-footer">

              {formLoading ? <div className="ngs-form-preloader"><Preloader inline isVisible /></div> : null}
              <Button
                disabled={formLoading || formDisabled}
                title="Get Results"
                forwardIcon={ArrowCirlce}
                action={handleForm}
              />
              <div className="ngs-privacy">
                By clicking "Save" I agree to the Terms and Privacy Policy.
                <br />
                This site is protected by reCAPTCHA and the Google Privacy Policy and Term of Service apply.
              </div>
            </div>
          </div>
        </Overlay>
      ) : null}
      {showBiomarkers ? (
        <Overlay
          className="ngs-overlay"
          onclose={() => {
            setShowBiomarkers(false);
          }}
        >
          <div className="ngs-biomarkers">
            <div>
              Select Biomarkers
            </div>
            <div className="height-20" />
            <Biomarkers
              onChange={(v) => {
                setBiomarkers(v);
              }}
              staticOptions
            />
            <div className="ngs-footer">
              <Button
                disabled={!biomarkers.length}
                forwardIcon={ArrowCirlce}
                title="Next"
                action={() => {
                  app.sendGoogleAnalyticsEvent(GA.category, GA.events.button, { name: 'manual_biomarkers_next_button' });
                  if (user) return gotoSearch();
                  setIsManualBiomarkers(true);
                  setShowBiomarkers(false);
                  setShowForm(true);
                }}
              />
              <Button
                title="Upload file"
                theme={THEMES.ORANGE}
                action={() => {
                  setMobileMenu(true);
                  setShowBiomarkers(false);
                }}
              />
            </div>
          </div>
        </Overlay>
      ) : null}
      {uploaderState === UPLOADER_STATES.SELECT && <Marketing />}
    </div>
  );
};

export default NGS;

export const isNGSSource = () => {
  const params = new URLSearchParams(window.location.search);
  return params.get('source') === 'ngs';
};
