import {
  matchesSponsoredSteps,
  matchesNoSponsoredSteps,
  questionnaireManualOpenSteps,
  questionnaireMissedAnswerSteps
} from './steps';

export const REDUX_ACTIONS = {
  OPEN_CHATBOT: 'OPEN_CHATBOT'
};

export const SCENARIO_ID = {
  QUESTIONNAIRE_MANUAL_OPEN: 'QUESTIONNAIRE_MANUAL_OPEN',
  QUESTIONNAIRE_MISSED_ANSWER: 'QUESTIONNAIRE_MISSED_ANSWER',
  MATCHES_SPONSORED: 'MATCHES_SPONSORED',
  MATCHES_NO_SPONSORED: 'MATCHES_NO_SPONSORED'
};

export const SCENARIO_MAPPING = {
  [SCENARIO_ID.QUESTIONNAIRE_MANUAL_OPEN]: props => questionnaireManualOpenSteps(props),
  [SCENARIO_ID.QUESTIONNAIRE_MISSED_ANSWER]: props => questionnaireMissedAnswerSteps(props),
  [SCENARIO_ID.MATCHES_SPONSORED]: props => matchesSponsoredSteps(props),
  [SCENARIO_ID.MATCHES_NO_SPONSORED]: props => matchesNoSponsoredSteps(props)
};
