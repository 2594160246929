import React, { useState } from 'react';
import './AddDoctor.css';

import Form, { FORM_INPUT_TYPES } from 'new-ui/Components/Form';
import { PopupTitle, PopupContent, PopupButton } from 'new-ui/Components/Popup';

import { LOCAL_STORAGE_KEYS, POPUP_TYPES } from 'new-ui/constants';

import { addDoctor } from 'new-ui/api/doctor';

import { useTranslation } from 'utils/modifiedTranslation';

const AddDoctor = (props) => {
  // eslint-disable-next-line react/prop-types
  const { clinic, doctor: { firstName: name } = {} } = props;

  const [error, setError] = useState(null);
  const [doctor, setDoctor] = useState(null);
  const { t } = useTranslation();

  const form = {
    inputs: {
      firstName: {
        type: FORM_INPUT_TYPES.INPUT,
        icon: 'user',
        placeholder: t('doctor.add_your_doc_firstname'),
        title: t('doctor.add_your_doc_firstname'),
        required: true,
      },
      email: {
        type: FORM_INPUT_TYPES.INPUT,
        icon: 'email',
        placeholder: t('doctor.add_your_doc_email'),
        title: t('doctor.add_your_doc_email'),
        required: true,
      },
    },
    data: {
      firstName: name || '', // can come from props
      email: '',
    },
  };

  const onChange = () => {
    const { data } = form;
    if (data.firstName && data.email) {
      setDoctor(data);
    }
  };

  const handleAddDoctor = async () => {
    const email = document.querySelector('.add-doctor .email input').value;
    try {
      const response = await addDoctor({ email, firstName: doctor.firstName });
      if (response.success) {
        localStorage.setItem(
          LOCAL_STORAGE_KEYS.DOCTOR_ADDED,
          JSON.stringify({ email, firstName: doctor.firstName, clinic }),
        );
        window.app.setPopup(POPUP_TYPES.GENERAL, {
          title: t('doctor.share_doc_heading'),
          contents: [t('action.send_to_doctor'), t('action.subtitle_send_to_doctor')], // can also be JSX
          button: {
            title: t('action.send_to_doctor_button'),
            action: () => {
              window.app.setPopup(POPUP_TYPES.SEND_TO_DOCTOR, { email, firstName: doctor.firstName, clinic });
            },
          },
        });
      }
    } catch (ex) {
      setError(t('error.something_went_wrong'));
    }
  };

  return (
    <div className="add-doctor">
      <PopupTitle title={t('doctor.add_your_doc_header')} />
      <PopupContent>
        <Form form={form} onChange={onChange} />
        {error && <div className="error">{error}</div>}
      </PopupContent>
      {
        doctor && (
          <PopupButton
            title={t('doctor.add_your_doc_next')}
            action={() => {
              handleAddDoctor();
            }}
          />
        )
      }
    </div>
  );
};

export default AddDoctor;
