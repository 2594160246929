import REDUX_ACTIONS from './constants/reduxActions';

const {
  QUESTIONNAIRE_SET_FILES,
  QUESTIONNAIRE_ADD_FILES,
  QUESTIONNAIRE_REMOVE_FILES,
  QUESTIONNAIRE_UPDATE_FILES,
  QUESTIONNAIRE_SET_PSTACK,
  QUESTIONNAIRE_UPDATE_BETWEEN_QUESTIONS,
} = REDUX_ACTIONS;

const initialState = {
  currentIndex: 1,
  form: {},
  files: {},
  optionsFromServer: {},
  isFetching: false,
  isFetchingOptionsFromServer: false,
  isQuestionnaireNavigationDisabled: false,
  qstack: [],
  pstack: null,
  allQuestions: [],
  completed: false,
  submitted: false,
  isLast: false,
  isSidebarOpen: false,
  diseases: [],
  updateBetweenQuestions: true,
  showIntro: false,
  questionnaireStep: 'next',
  skippedQuestion: null,
};

export function questionnaire(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case REDUX_ACTIONS.IS_LAST_QUESTION:
      return {
        ...state,
        isLast: true,
      };
    case REDUX_ACTIONS.QUESTIONNAIRE_STEP:
      return {
        ...state,
        questionnaireStep: payload,
      };
    case REDUX_ACTIONS.IS_NOT_LAST_QUESTION:
      return {
        ...state,
        isLast: false,
      };
    case REDUX_ACTIONS.IS_FETCHING_SET:
      return {
        ...state,
        isFetching: payload,
      };
    case REDUX_ACTIONS.QUESTIONNAIRE_NAVIGATION_DISABLED:
      return {
        ...state,
        isQuestionnaireNavigationDisabled: payload,
      };
    case REDUX_ACTIONS.FORM_FIELD_VALUE_SET:
      return {
        ...state,
        form: {
          ...state.form,
          [payload.id]: payload.value,
        },
      };
    case REDUX_ACTIONS.QUESTIONNAIRE_STATE_SET:
      return {
        ...state,
        ...payload,
      };
    case REDUX_ACTIONS.QUESTIONNAIRE_SKIP_STEP:
      return {
        ...state,
        skippedQuestion: state.qstack[state.currentIndex].id,
      };
    case REDUX_ACTIONS.GET_QUESTION_OPTIONS_REQUEST:
      return {
        ...state,
        isFetchingOptionsFromServer: true,
      };
    case REDUX_ACTIONS.GET_QUESTION_OPTIONS_SUCCESS:
      return {
        ...state,
        isFetchingOptionsFromServer: false,
        optionsFromServer: {
          ...state.optionsFromServer,
          ...payload,
        },
      };
    case REDUX_ACTIONS.SET_SIDEBAR_VISIBILITY:
      return {
        ...state,
        isSidebarOpen: payload,
      };
    case QUESTIONNAIRE_SET_FILES:
      return {
        ...state,
        files: payload,
      };
    case QUESTIONNAIRE_SET_PSTACK:
      return {
        ...state,
        pstack: payload,
      };
    case QUESTIONNAIRE_UPDATE_BETWEEN_QUESTIONS:
      return {
        ...state,
        updateBetweenQuestions: payload,
      };
    case QUESTIONNAIRE_ADD_FILES:
      return {
        ...state,
        files: {
          ...(state.files ?? {}),
          [payload.questionId]: [
            ...(state.files[payload.questionId] ?? []),
            ...payload.files,
          ],
        },
      };
    case QUESTIONNAIRE_REMOVE_FILES:
      return {
        ...state,
        files: {
          ...(state.files ?? {}),
          [payload.questionId]: (state.files[payload.questionId] ?? []).filter(({ id }) => !payload.fileIds.includes(id)),
        },
      };
    case QUESTIONNAIRE_UPDATE_FILES:
      return {
        ...state,
        files: {
          ...(state.files ?? {}),
          [payload.questionId]: (state.files[payload.questionId] ?? []).map((file) => (
            payload.fileDataByIds[file.id] ?? file
          )),
        },
      };
    case REDUX_ACTIONS.GET_ACTIVE_DISEASE:
      return {
        ...state,
        diseases: payload,
      };
    default:
      return state;
  }
}
