import React, { forwardRef } from 'react';
import './style.css';

const Tooltip = forwardRef(({ visible, children }, ref) => {
  return (
    <div ref={ref} className={`patient-tooltip tooltip-${visible ? 'visible' : ''}`}>
      {children}
    </div>
  );
});

export default Tooltip;