import configs from 'configs';
import { authHeaders } from 'functions';
import request from 'functions/api/request';

export const getDoctors = async (data) => {
  let response = null;

  const requestOptions = {
    url: `${configs.beApiUrl}/user/search-doctor`,
    qs: {
      name: data?.name,
      limit: data?.limit || null,
    },
    headers: await authHeaders(),
    json: true,
  };

  try {
    response = await request.get(requestOptions);
  } catch (ex) {
    console.error('Error occurred, while getting user profile', ex);
    throw ex;
  }

  return response;
};

export const addDoctor = async (data) => {
  let response = null;

  const requestOptions = {
    url: `${configs.beApiUrl}/user/add-doctor`,
    data,
    headers: await authHeaders(),
    json: true,
  };

  try {
    response = await request.post(requestOptions);
  } catch (ex) {
    console.error('Error occurred, while getting user profile', ex);
    throw ex;
  }

  return response;
};
