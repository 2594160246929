import { TRIAL_FINDER_ACTIONS as TFA } from './constants';

const initialState = {
  isFetchingCancerTypes: false,
  isFetching: false,
  error: null,
  locations: [],
  trials: [],
  cancerTypes: []
};

export const trialFinder = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case TFA.TRIAL_FINDER_FETCH_TRIALS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        locations: [...(payload?.locations || [])],
        trials: [...(payload?.trials || [])]
      };
    case TFA.FETCH_CANCER_TYPES_START:
      return {
        ...state,
        isFetchingCancerTypes: true,
      }
    case TFA.FETCH_CANCER_TYPES_SUCCESS:
      return {
        ...state,
        isFetchingCancerTypes: false,
        error: null,
        cancerTypes: [...(payload?.cancerTypes || [])]
      };
    case TFA.TRIAL_FINDER_FETCH_TRIALS_START:
      return {
        ...state,
        isFetching: true
      };
    case TFA.TRIAL_FINDER_FETCH_TRIALS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: payload
      };
    case TFA.RESET_TRIAL_FINDER_STORE:
      return {
        ...state,
        locations: [],
        trials: [],
      }
    default:
      return state;
  }
};
