export const Profile = {
  user_type: 'user_type',
  dob: 'dob',
  gender: 'gender',
  disease_status: 'disease_status',
  type_of_bladder_cancer: 'type_of_bladder_cancer',
  patient_phase: 'patient_phase',
  patient_grade: 'patient_grade',
  patient_stage: 'patient_stage',
  patient_stage_nmibc: 'patient_stage_nmibc',
  patient_stage_mibc: 'patient_stage_mibc',
  patient_stage_met: 'patient_stage_met',
  tnm: 'tnm',
  disease_location: 'disease_location',
  metastasis_location_brain_leptomeningeal: 'metastasis_location_brain_leptomeningeal',
  brain_metastasis_active: 'brain_metastasis_active',
  disease_resectable: 'disease_resectable',
  had_surgery_for_bladder_cancer: 'had_surgery_for_bladder_cancer',
  scheduled_for_surgery: 'scheduled_for_surgery',
  procedures: 'procedures',
  procedures_received: 'procedures_received',
  procedure_date: 'procedure_date',
  procedure_outcome: 'procedure_outcome',
  disease_measurable: 'disease_measurable',
  biopsy_metastatic_disease: 'biopsy_metastatic_disease',
  pd_l1_carrier_status: 'pd_l1_carrier_status',
  pd_l1_expression: 'pd_l1_expression',
  ngs: 'ngs',
  treatment_for_bladder_cancer: 'treatment_for_bladder_cancer',
  treatment_for_advanced_metastatic_disease: 'treatment_for_advanced_metastatic_disease',
  drugs_received: 'drugs_received',
  cycles: 'cycles',
  treatments: 'treatments',
  treatment_admin: 'treatment_admin',
  treatment_end_date: 'treatment_end_date',
  treatment_stop_reason: 'treatment_stop_reason',
  current_treatment_systemic_steroids: 'current_treatment_systemic_steroids',
  systemic_steroids_treatment_end_date: 'systemic_steroids_treatment_end_date',
  ecog: 'ecog',
  karnofsky_score: 'karnofsky_score',
  other_medical_condition: 'other_medical_condition',
  creatinine_level: 'creatinine_level',
  omc_liver: 'omc_liver',
  omc_heart: 'omc_heart',
  omc_myocardial_infarction_date: 'omc_myocardial_infarction_date',
  omc_stroke_date: 'omc_stroke_date',
  omc_kidney: 'omc_kidney',
  omc_autoimmune: 'omc_autoimmune',
  omc_mental: 'omc_mental',
  omc_allergy: 'omc_allergy',
  omc_chronic_infection_inflammation: 'omc_chronic_infection_inflammation',
  omc_gastro_problem: 'omc_gastro_problem',
  omc_lung_disease: 'omc_lung_disease',
  omc_abnormal_blood_results: 'omc_abnormal_blood_results',
  other_medications: 'other_medications',
  bone_metastasis_complications: 'bone_metastasis_complications',
  is_pregnant: 'is_pregnant',
  is_breastfeeding: 'is_breastfeeding',
  triple_question_drop_down: 'triple_question_drop_down',
  tnm_m: 'tnm_m',
  tnm_n: 'tnm_n',
  tnm_t: 'tnm_t',
  have_insurance: 'have_insurance',
  insurances: 'insurances',
};
