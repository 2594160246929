import Cookies from 'js-cookie';
import request from 'functions/api/request';
import configs from 'configs';

const isPixelIdExist = !!configs.metaPixelId;
const apiUrl = configs.beApiUrl;
const FBP_COOKIE = '_fbp';
const FBC_COOKIE = '_fbc';

export const sendFbEventToConversionApi = async ({
  eventName,
  eventID,
  customData = {},
}) => {
  if (!isPixelIdExist) {
    return;
  }

  const fbp = Cookies.get(FBP_COOKIE) || null;
  const fbc = Cookies.get(FBC_COOKIE) || null;

  if (!fbp) {
    return;
  }

  const dataToSend = {
    sourceUrl: window?.location?.href || '',
    customData,
    eventName,
    eventID,
    fbCookies: { fbp, fbc },
  };

  const requestOptions = {
    url: `${apiUrl}/advertisements/pixel/event`,
    data: dataToSend,
    json: true,
  };

  try {
    await request.post(requestOptions);
  } catch (ex) {
    console.error('Unable to send fb event to server', ex);
    throw ex;
  }
};
