import React from 'react';
import EbookDownload from './EbookDownload/EbookDownload';
import Testimonials from './Testimonials/Testimonials';
import './Marketing.css';

const Marketing = () => {
  return (
    <div className='ngs-marketing'>
      <EbookDownload />
      <Testimonials />
    </div>
  );
};

export default Marketing;