export const Profile = {
  dob: 'dob',
  gender: 'gender',
  race: 'race',
  disease_status: 'disease_status',
  melanoma_subtype: 'melanoma_subtype',
  melanoma_subtype_mucosal: 'melanoma_subtype_mucosal',
  disease_resectable: 'disease_resectable',
  types_of_recurrence: 'types_of_recurrence',
  current_stage: 'current_stage',
  tnm_t: 'tnm_t',
  tnm_n: 'tnm_n',
  tnm_m: 'tnm_m',
  sentinel_lymph_node_status: 'sentinel_lymph_node_status',
  metastasis_locations: 'metastasis_locations',
  metastasis_location_brain_leptomeningeal: 'metastasis_location_brain_leptomeningeal',
  brain_metastasis_active: 'brain_metastasis_active',
  have_biopsy: 'have_biopsy',
  leptomeningeal: 'leptomeningeal',
  braf_mutation: 'braf_mutation',
  disease_measurable: 'disease_measurable',
  primary_tumor_size_mm: 'primary_tumor_size_mm',
  metastasis_size_mm: 'metastasis_size_mm',
  treatment_for_melanoma: 'treatment_for_melanoma',
  treatment_for_advanced_metastatic_disease: 'treatment_for_advanced_metastatic_disease',
  treatments: 'treatments',
  drugs_received: 'drugs_received',
  cycles: 'cycles',
  treatment_admin: 'treatment_admin',
  treatment_end_date: 'treatment_end_date',
  treatment_stop_reason: 'treatment_stop_reason',
  radiation_treatment: 'radiation_treatment',
  radiation_end_date: 'radiation_end_date',
  current_treatment_systemic_steroids: 'current_treatment_systemic_steroids',
  systemic_steroids_treatment_end_date: 'systemic_steroids_treatment_end_date',
  ecog: 'ecog',
  pd_l1_carrier_status: 'pd_l1_carrier_status',
  pd_l1_expression: 'pd_l1_expression',
  ngs: 'ngs',
  karnofsky: 'karnofsky',
  other_medical_condition: 'other_medical_condition',
  omc_liver: 'omc_liver',
  omc_heart: 'omc_heart',
  omc_stroke_date: 'omc_stroke_date',
  omc_kidney: 'omc_kidney',
  omc_autoimmune: 'omc_autoimmune',
  omc_mental: 'omc_mental',
  omc_allergy: 'omc_allergy',
  omc_chronic_infection_inflammation: 'omc_chronic_infection_inflammation',
  omc_gastro_problem: 'omc_gastro_problem',
  omc_lung_disease: 'omc_lung_disease',
  omc_retinal_disease: 'omc_retinal_disease',
  omc_abnormal_blood_results: 'omc_abnormal_blood_results',
  other_medications: 'other_medications',
  is_pregnant: 'is_pregnant',
  is_breastfeeding: 'is_breastfeeding',
  triple_question_drop_down: 'triple_question_drop_down',
  have_insurance: 'have_insurance',
  insurances: 'insurances',
};
