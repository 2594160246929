import React, { useState } from "react";
import "./style.css";
import SearchIcon from 'new-ui/assets/icons/status.svg';

const PatientSearch = ({ onChange }) => {
  const [searchValue, setSearchValue] = useState("");

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchValue(value);
    onChange(value);
  };

  return (
    <div className="patient-search">
      <input
        type="text"
        placeholder="Type to search"
        value={searchValue}
        onChange={handleInputChange}
      />
      <img alt="icon" src={SearchIcon} />
    </div>
  );
};

export default PatientSearch;