import React, { useState, useRef } from 'react';
import './ChangeLocation.css';

import app from 'new-ui/app';

import XIcon from 'new-ui/assets/icons/x.svg';
import Geosuggest from 'react-geosuggest';
import { useTranslation } from 'utils/modifiedTranslation';
import { authenticationActions } from 'components/Authentication/actions';

import {
  PopupTitle, PopupContent, PopupButton,
} from 'new-ui/Components/Popup';
import PropTypes from 'prop-types';

import Form from 'new-ui/Components/Form';

const ChangeLocation = (props) => {
  const { closePopup } = props;
  const { user } = app;

  const [updateUser, setUpdateUser] = useState(user);

  const { t } = useTranslation();
  const { dispatch } = app.store;
  const geosuggestEl = useRef(null);

  let data = {
    distance: app.user?.personal?.travel_limit,
  };

  const inputs = {
    distance: {
      type: 'range',
      title: t('personalize.distance_from_me'),
      single: true,
      options: [
        { title: '50mi', value: '50' },
        { title: '100mi', value: '100' },
        { title: '200mi', value: '200' },
        { title: 'Any', value: 'none' },
      ],
    },
  };

  const save = async () => {
    updateUser.personal.travel_limit = data.distance;
    app.setShowPreloader(true);
    await dispatch(authenticationActions.updateUser(updateUser, true, app.user)); // user should be returned here currently without missing_critical
    app.setShowPreloader(false);
    await app.update({ rematch: true });
    closePopup();
    app.renderPage();
  };

  const geoSuggestChange = (geoLocation) => {
    if (!geoLocation) return;
    const u = JSON.parse(JSON.stringify(user));
    const { location, label } = geoLocation;
    u.info.country = {
      location,
      label,
    };
    setUpdateUser(u);
  };

  const formChange = (newData) => {
    data = newData;
    // console.log('formChange', data);
  };

  return (
    <div className="change-location">
      <PopupTitle title={t('change_location.title')} />
      <PopupContent>
        {t('change_location.what_is_your_preferred_trial_location')}
        <div className="geo-suggest-wrapper">
          <div role="button" className="geo-clear-button" onClick={() => geosuggestEl.current.clear()}><img alt="close" src={XIcon} /></div>
          <Geosuggest
            ref={geosuggestEl}
            inputClassName="geo-suggest"
            suggestsClassName="geo-suggest-suggests"
            initialValue={app?.user?.info?.country?.label || ''}
            onSuggestSelect={geoSuggestChange}
            autoComplete="on"
            country={['us', 'ca', 'il', 'tr']}
            placeholder={t('change_location.geo_suggest.placeholder')}
          />
        </div>
        <div className="set-distance">
          <Form inputs={inputs} data={data} onChange={formChange} />
        </div>
      </PopupContent>
      <PopupContent />
      <PopupButton action={save} title={t('change_location.save_location')} />
    </div>
  );
};

ChangeLocation.propTypes = {
  closePopup: PropTypes.func,
};

export default ChangeLocation;
