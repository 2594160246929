import React, { useState } from "react";
import "./style.css";
import SearchIcon from 'new-ui/assets/icons/search_text.svg';
import CloseIcon from 'new-ui/assets/icons/close.svg';

const IndicationChoose = ({ onChange, options, initValue = '' }) => {
  const [inputValue, setInputValue] = useState(initValue);
  const [suggestions, setSuggestions] = useState([]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    if (value) {
      const filteredSuggestions = options.filter(item =>
        item.title.toLowerCase().includes(value.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
    onChange(value);
  };

  const handleSearchClick = () => {
    onChange(inputValue);
  };

  const handleCloseClick = () => {
    setInputValue('');
    setSuggestions([]);
    onChange('');
  };

  const handleSuggestionClick = (suggestion) => {
    setInputValue(suggestion.title);
    setSuggestions([]);
    onChange(suggestion.value);
  };

  return (
    <div className="indications-form">
      <input
        type="text"
        placeholder="Start type your indication"
        value={inputValue}
        onChange={handleInputChange}
      />
      {inputValue ? (
        <img
          alt="close icon"
          src={CloseIcon}
          className="close-icon"
          onClick={handleCloseClick}
        />
      ) : (
        <img
          alt="search icon"
          src={SearchIcon}
          className="search-icon"
          onClick={handleSearchClick}
        />
      )}
      {suggestions.length > 0 && (
        <ul className="suggestions-list">
          {suggestions.map((suggestion, index) => (
            <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
              {suggestion.title}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default IndicationChoose;
