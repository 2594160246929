import React from 'react';
import PropTypes from 'prop-types';

import Button from '../Button';

const PopupButton = (props) => {
  const {
    title,
    action,
  } = props;
  return (
    <div className="popup-footer popup-padding">
      <Button title={title} action={action} />
    </div>
  );
};

PopupButton.propTypes = {
  title: PropTypes.string,
  action: PropTypes.func,
};

export default PopupButton;
