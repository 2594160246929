import React from 'react';
import './style.css';
import SvgCircle from './circle';

const TreatmentIntensity = ({ options }) => {

  function transformArray(original) {
    let start = 0;

    return original.map(item => {
        const newItem = {
            ...item,
            start: start,
            end: start + item.percent
        };

        start = newItem.end;
        return newItem;
    });
  };

  options = transformArray(options);

  return (
    <div className="svg-circles">
      {options.map((item, index) => {
        return (
          <div key={index} className='circle-box'>
             <SvgCircle 
              startPoint={item.start}
              endPoint={item.end} 
              radius={50} 
              strokeWidth={25} 
              size={160}
              color="#0C9BED" 
              backgroundColor="#F2F2F2" 
            />
            <h2 className='title'>{item.title}</h2>
          </div>
        );
      })}
    </div>
  );
};

export default TreatmentIntensity;