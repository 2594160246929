import React, { useState, useEffect } from 'react';
import Form, { FORM_INPUT_TYPES, FORM_VALIDATORS } from 'new-ui/Components/Form';
import {
  PopupTitle, PopupContent, PopupFooter,
} from 'new-ui/Components/Popup';
import Button from 'new-ui/Components/Button';
import LinkButton from 'new-ui/Components/LinkButton';
import { useTranslation } from 'utils/modifiedTranslation';
import { sendContactUs } from 'new-ui/api';
import { MAIN_WEBSITE_LINKS } from 'new-ui/constants';
import app from 'new-ui/app';
import './ContactUs.css';

const ContactUs = () => {
  const { t } = useTranslation();
  const [isFormValid, setIsFormValid] = useState(false);
  const [form, setForm] = useState(null);
  useEffect(() => {
    const f = {
      inputs: {
        name: {
          title: t('signup.form.full_name.title'),
          type: FORM_INPUT_TYPES.INPUT,
        },
        email: {
          title: t('signup.form.email.title'),
          type: FORM_INPUT_TYPES.INPUT,
          email: true,
        },
        phone: {
          title: t('signup.form.phone.title'),
          type: FORM_INPUT_TYPES.PHONE,
        },
        message: {
          title: t('dialog.share_trials_email.form.input.message'),
          type: FORM_INPUT_TYPES.TEXT,
        },
      },
      data: {
        name: '',
        email: '',
        phone: '',
        message: '',
      },
    };
    setForm(f);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const send = async () => {
    const r = await sendContactUs(form.data);
    console.log(r);
    app.setPopup(false);
    app.alert([
      <div key="alert">
        {t('contact_us.sent_text')}
      </div>,
    ]);
  };

  const onChange = () => {
    if (!form.data.phone.startsWith('+')) form.data.phone = `+${form.data.phone}`;
    const showSendButton = !!(FORM_VALIDATORS.EMAIL(form.data.email));
    setIsFormValid(showSendButton);
  };

  const gotoPrivacyPolicy = () => {
    window.open(MAIN_WEBSITE_LINKS.PRIVACY_POLICY, '_blank').focus();
  };

  return (
    <div className="contact-us">
      <PopupTitle title={t('contact_us.popup_title')} />
      <PopupContent>
        <Form form={form} onChange={onChange} />
      </PopupContent>
      <PopupFooter>
        <Button title={t('contact_us.send')} action={send} disabled={!isFormValid} />
        <div className="privacy">
          {t('contact_us.privacy_text')}
          <div className="privacy-button">
            <LinkButton text={t('contact_us.privacy_policy')} action={gotoPrivacyPolicy} />
          </div>
        </div>
      </PopupFooter>
    </div>
  );
};

export default ContactUs;
