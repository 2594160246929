import getDomain from '../../functions/getDomain';

export const TRIAL_ACTION = {
  GET_TRIALS_REQUEST: 'GET_TRIALS_REQUEST',
  GET_TRIALS_SUCCESS: 'GET_TRIALS_SUCCESS',
  GET_TRIALS_FAILURE: 'GET_TRIALS_FAILURE',
  TRIALS_CHANGE_PAGE: 'TRIALS_CHANGE_PAGE',
  TRIALS_CHANGE_FILTERS: 'TRIALS_CHANGE_FILTERS',
  TRIALS_CLEAR_FILTER: 'TRIALS_CLEAR_FILTER'
};

export const TRIAL_FILTER = {
  CANCER_TYPES: 'cancerTypes',
  NCT_IDS: 'nctIds'
};

export const HEAD_IMAGE = `${getDomain()}/assets/trialjectory-phone.png`;
