// import { LOCAL_STORAGE_KEYS } from 'new-ui/constants';

// eslint-disable-next-line arrow-body-style
export default () => () => {
  // *** temporary hiding for production release
  // if (
  //   !localStorage.getItem(LOCAL_STORAGE_KEYS.DOCTOR_ADDED)
  //   && !localStorage.getItem(LOCAL_STORAGE_KEYS.DOCTOR_SHARED)
  // ) {
  //   return 'SEARCH_DOCTOR';
  // } if (
  //   !localStorage.getItem(LOCAL_STORAGE_KEYS.DOCTOR_SHARED)
  //   && !!localStorage.getItem(LOCAL_STORAGE_KEYS.DOCTOR_ADDED)
  // ) {
  //   return 'SEND_TO_DOCTOR';
  // }

  return 'SHARE_WITH_DOCTOR';
};
