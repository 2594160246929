import React, { useState } from 'react';
import './style.css';
import app from 'new-ui/app';

const CustomCheckbox = ({
  label, onChange, checked, GA,
}) => {
  const [isChecked, setIsChecked] = useState(checked || false);

  const handleCheckboxChange = (e) => {
    const newValue = e.target.checked;
    setIsChecked(newValue);
    if (onChange) {
      onChange(newValue);
    }
    if (isChecked) {
      app.sendGoogleAnalyticsEvent(GA.category, GA.events.unselectCategory, { name: 'Financial support category unselected', metaDate: label });
    } else {
      app.sendGoogleAnalyticsEvent(GA.category, GA.events.selectCategory, { name: 'Financial support category selected', metaDate: label });
    }
  };

  return (
    <label className="custom-checkbox">
      <input
        type="checkbox"
        checked={isChecked}
        onChange={handleCheckboxChange}
      />
      <span className={`checkmark ${isChecked ? 'checkmark-checked' : ''}`} />
      {label}
    </label>
  );
};

export default CustomCheckbox;
