import dateType from 'utils/constants/dateTypes';
import ConditionTypes from 'components/Questionnaire/constants/conditions';
import i from 'components/Questionnaire/constants/icons';
import {
  dateOfBirth,
  diseaseStatus,
  preferredLocation,
  travelLimit,
  userType,
  race,
  haveInsurance,
  insurance,
  initialDiagnosisDate,
} from 'components/Questionnaire/constants/globalQuestions';
import SECTIONS from 'components/Questionnaire/constants/questionSections';
import numberType from 'utils/constants/numberTypes';
import { customPaths } from 'utils/constants/customPaths';
// import { takingStateOptions } from 'components/Questionnaire/constants/globalOptions';

import { QUESTION_VIEW_TYPES } from 'components/Questionnaire/constants/questionViewTypes';
import {
  MONTH_PICKER,
  NUMBER_INPUT,
  SINGLE_OPTION_SELECT,
  TREATMENT_HISTORY,
  MULTIPLE_OPTION_SELECT,
  BIOMARKERS,
  OPTIONS_FROM_SERVER,
} from '../../constants/questionTypes';
import GLOBAL_ENUMS from '../../constants/globalEnums';
import { treatmentCyclesOptions } from '../../constants/globalOptions';
import { Profile as ID } from './AmlProfile.enum';

const { OPTIONS_LIST_WITH_SHOW_MORE } = QUESTION_VIEW_TYPES;

const { biomarkers: BIOMARKERS_QUESTION_ID } = GLOBAL_ENUMS;

const {
  CONTAIN_ONE_OF,
  NOT_EQUAL_TO_OR_SKIPPED,
  EQUAL_TO,
  AGE_LESS_THAN,
  DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
  CONTAINS_ONE_OF_OR_SKIPPED,
} = ConditionTypes;

const Questions = [
  diseaseStatus,
  {
    q: 'Q5',
    id: ID.type_of_aml,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.aml.type_of_aml.title',
    info: {
      title: 'questionnaire.questions.aml.type_of_aml.info.title',
      content: 'questionnaire.questions.aml.type_of_aml.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.aml.type_of_aml.options.aml',
        value: 'aml',
      },
      {
        label: 'questionnaire.questions.aml.type_of_aml.options.apl',
        value: 'apl',
      },
      {
        label: 'questionnaire.questions.aml.type_of_aml.options.bpdcn',
        value: 'bpdcn',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q7i',
    id: ID.risk_group,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.aml.risk_group.title',
    info: {
      title: 'questionnaire.questions.aml.risk_group.info.title',
      content: 'questionnaire.questions.aml.risk_group.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.aml.risk_group.options.favorable',
        value: 'favorable',
      },
      {
        label: 'questionnaire.questions.aml.risk_group.options.intermediate',
        value: 'intermediate',
      },
      {
        label: 'questionnaire.questions.aml.risk_group.options.poor',
        value: 'poor',
      },
      {
        label: 'questionnaire.questions.aml.risk_group.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: ID.type_of_aml,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['apl', 'bpdcn'],
      },
    ],
  },
  {
    q: 'Q14',
    id: ID.treatment_for_aml,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.aml.treatment_for_aml.title',
    info: {
      title: 'questionnaire.questions.aml.treatment_for_aml.info.title',
      content: 'questionnaire.questions.aml.treatment_for_aml.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.aml.treatment_for_aml.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.aml.treatment_for_aml.options.no',
        value: 'no',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q6',
    id: ID.aml_origin,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.aml.aml_origin.title',
    info: {
      title: 'questionnaire.questions.aml.aml_origin.info.title',
      content: 'questionnaire.questions.aml.aml_origin.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.aml.aml_origin.options.primary',
        value: 'primary',
      },
      {
        label: 'questionnaire.questions.aml.aml_origin.options.secondary',
        value: 'secondary',
      },
      {
        label: 'questionnaire.questions.aml.aml_origin.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q7ii',
    id: ID.risk_group_apl,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.aml.risk_group_apl.title',
    info: {
      title: 'questionnaire.questions.aml.risk_group_apl.info.title',
      content: 'questionnaire.questions.aml.risk_group_apl.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.aml.risk_group_apl.options.low',
        value: 'low',
      },
      {
        label: 'questionnaire.questions.aml.risk_group_apl.options.high',
        value: 'high',
      },
      {
        label: 'questionnaire.questions.aml.risk_group_apl.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: ID.type_of_aml,
        type: EQUAL_TO,
        ifValue: 'apl',
      },
    ],
  },
  {
    q: 'Q8',
    id: ID.percentage_of_bm_blasts,
    type: NUMBER_INPUT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.aml.percentage_of_bm_blasts.title',
    label: 'questionnaire.questions.aml.percentage_of_bm_blasts.placeholder',
    info: {
      title: 'questionnaire.questions.aml.percentage_of_bm_blasts.info.title',
      content: 'questionnaire.questions.aml.percentage_of_bm_blasts.info.content',
    },
    conditions: [],
    numberType: numberType.percentage,
  },
  {
    q: 'Q9',
    id: ID.blast_count,
    type: NUMBER_INPUT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.aml.blast_count.title',
    label: 'questionnaire.questions.aml.blast_count.placeholder',
    info: {
      title: '',
      content: '',
    },
    options: [],
    conditions: [],
    numberType: numberType.intWithoutUnit,
  },
  {
    q: 'Q10',
    id: ID.wbc_count,
    type: NUMBER_INPUT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.aml.wbc_count.title',
    label: 'questionnaire.questions.aml.wbc_count.placeholder',
    info: {
      title: '',
      content: '',
    },
    options: [],
    conditions: [],
    numberType: numberType.intWithoutUnit,
  },
  {
    q: 'Q11',
    id: ID.has_biopsy,
    path: customPaths.biopsy,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.aml.has_biopsy.title',
    info: {
      title: 'questionnaire.questions.aml.has_biopsy.info.title',
      content: 'questionnaire.questions.aml.has_biopsy.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.aml.has_biopsy.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.aml.has_biopsy.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.aml.has_biopsy.options.biopsy_possible',
        value: 'biopsy_possible',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q80',
    id: ID.ngs,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.molecular,
    order: 0,
    title: 'questionnaire.questions.aml.ngs.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.aml.ngs.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.aml.ngs.options.yes_waiting_for_the_results',
        value: 'yes_waiting_for_the_results',
      },
      {
        label: 'questionnaire.questions.aml.ngs.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.aml.ngs.options.no_but_planning',
        value: 'no_but_planning',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q12',
    id: BIOMARKERS_QUESTION_ID,
    type: BIOMARKERS,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.molecular,
    order: 0,
    title: 'questionnaire.questions.biomarkers.title',
    subtitle: 'questionnaire.questions.aml.biomarkers.subtitle',
    placeholder: 'questionnaire.questions.biomarkers.placeholder',
    info: {
      title: '',
      content: '',
    },
    conditions: [
      {
        ifId: ID.has_biopsy,
        type: CONTAINS_ONE_OF_OR_SKIPPED,
        ifValue: ['yes'],
      },
    ],
  },
  {
    q: 'Q13i',
    id: ID.received_transplant,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.aml.received_transplant.title',
    info: {
      title: 'questionnaire.questions.aml.received_transplant.info.title',
      content: 'questionnaire.questions.aml.received_transplant.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.aml.received_transplant.options.allogeneic',
        value: 'allogeneic',
      },
      {
        label: 'questionnaire.questions.aml.received_transplant.options.autologous',
        value: 'autologous',
      },
      {
        label: 'questionnaire.questions.aml.received_transplant.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.aml.received_transplant.options.candidate_no_match',
        value: 'candidate_no_match',
      },
      {
        label: 'questionnaire.questions.aml.received_transplant.options.scheduled_related',
        value: 'scheduled_related',
      },
      {
        label: 'questionnaire.questions.aml.received_transplant.options.scheduled_unrelated',
        value: 'scheduled_unrelated',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q13ii',
    id: ID.transplantation_treatment_date,
    type: MONTH_PICKER,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.aml.transplantation_treatment_date.title',
    info: {
      title: 'questionnaire.questions.aml.transplantation_treatment_date.info.title',
      content: 'questionnaire.questions.aml.transplantation_treatment_date.info.content',
    },
    options: [],
    conditions: [
      {
        ifId: ID.received_transplant,
        type: CONTAIN_ONE_OF,
        ifValue: ['allogeneic', 'autologous'],
      },
    ],
    dateType: {
      type: dateType.currentDate,
    },
  },
  {
    q: 'Q13iii',
    id: ID.transplant_outcome,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.aml.transplant_outcome.title',
    info: {
      title: 'questionnaire.questions.aml.transplant_outcome.info.title',
      content: 'questionnaire.questions.aml.transplant_outcome.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.aml.transplant_outcome.options.remission',
        value: 'remission',
      },
      {
        label: 'questionnaire.questions.aml.transplant_outcome.options.no_response',
        value: 'no_response',
      },
      {
        label: 'questionnaire.questions.aml.transplant_outcome.options.relapse',
        value: 'relapse',
      },
    ],
    conditions: [
      {
        ifId: ID.received_transplant,
        type: CONTAIN_ONE_OF,
        ifValue: ['allogeneic', 'autologous'],
      },
    ],
  },
  {
    q: 'Q60',
    id: ID.treatments,
    type: TREATMENT_HISTORY,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    // shouldAutoOpenSubQuestions: [{
    //   ifId: ID.treatment_for_aml,
    //   type: EQUAL_TO,
    //   ifValue: 'yes',
    // }],
    title: 'questionnaire.questions.aml.treatments.title',
    footer: 'questionnaire.questions.aml.treatments.footer',
    requiredSubQuestionsId: [ID.drugs_received],
    submitButtonLabel: 'questionnaire.questions.global.treatments.button.submit',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        iconText: 'A',
        label: 'questionnaire.questions.aml.treatments.options.treatment_name',
        value: null,
      },
      {
        iconText: 'B',
        label: 'questionnaire.questions.aml.treatments.options.treatment_stopped',
        value: null,
      },
      {
        iconText: 'C',
        label: 'questionnaire.questions.aml.treatments.options.treatment_stop_reason',
        value: null,
      },
    ],
    subQuestions: [
      {
        q: 'Q60a',
        id: ID.drugs_received,
        type: OPTIONS_FROM_SERVER,
        viewType: OPTIONS_LIST_WITH_SHOW_MORE,
        section: SECTIONS.TREATMENT,
        bgIcon: i.treatment,
        order: 0,
        titleIconText: 'A',
        title: 'questionnaire.questions.aml.treatments.sub_quest.drugs_received.title',
        subtitle: 'questionnaire.questions.aml.treatments.sub_quest.drugs_received.subtitle',
        placeholder: 'questionnaire.questions.drugs_received.placeholder',
        info: {
          title: '',
          content: '',
        },
        questionProps: {
          isSearchableByOptionGroupName: true,
        },
      },
      {
        q: 'Q60f',
        id: ID.cycles,
        type: SINGLE_OPTION_SELECT,
        section: SECTIONS.TREATMENT,
        title: 'questionnaire.questions.aml.treatments.sub_quest.cycles.title',
        disableNextOnOptionSelect: false,
        options: [...treatmentCyclesOptions],
      },
      {
        q: 'Q60b',
        id: ID.treatment_end_date,
        type: MONTH_PICKER,
        section: SECTIONS.TREATMENT,
        bgIcon: i.treatment,
        order: 0,
        titleIconText: 'B',
        title: 'questionnaire.questions.aml.treatments.sub_quest.treatment_end_date.title',
        info: {
          title: '',
          content: '',
        },
        options: [],
        conditions: [],
        dateType: {
          type: dateType.stopTreatment,
        },
      },
      {
        q: 'Q60c',
        id: ID.treatment_stop_reason,
        type: SINGLE_OPTION_SELECT,
        section: SECTIONS.TREATMENT,
        bgIcon: i.treatment,
        order: 0,
        titleIconText: 'C',
        title: 'questionnaire.questions.aml.treatments.sub_quest.treatment_stop_reason.title',
        info: {
          title: '',
          content: '',
        },
        disableNextOnOptionSelect: false,
        options: [
          {
            label: 'questionnaire.questions.aml.treatments.sub_quest.treatment_stop_reason.options.treatment_ongoing',
            value: 'treatment_ongoing',
          },
          {
            label: 'questionnaire.questions.aml.treatments.sub_quest.treatment_stop_reason.options.end_of_protocol',
            value: 'end_of_protocol',
          },
          {
            label: 'questionnaire.questions.aml.treatments.sub_quest.treatment_stop_reason.options.progression',
            value: 'progression',
          },
          {
            label: 'questionnaire.questions.aml.treatments.sub_quest.treatment_stop_reason.options.no_response',
            value: 'no_response',
          },
          {
            label: 'questionnaire.questions.aml.treatments.sub_quest.treatment_stop_reason.options.relapse',
            value: 'relapse',
          },
          {
            label: 'questionnaire.questions.aml.treatments.sub_quest.treatment_stop_reason.options.remission',
            value: 'remission',
          },
          {
            label: 'questionnaire.questions.aml.treatments.sub_quest.treatment_stop_reason.options.toxicity',
            value: 'toxicity',
          },
          {
            label: 'questionnaire.questions.aml.treatments.sub_quest.treatment_stop_reason.options.other_medical_conditions',
            value: 'other_medical_conditions',
          },
          {
            label: 'questionnaire.questions.aml.treatments.sub_quest.treatment_stop_reason.options.dosing_issues',
            value: 'dosing_issues',
          },
          {
            label: 'questionnaire.questions.aml.treatments.sub_quest.treatment_stop_reason.options.scheduling',
            value: 'scheduling',
          },
          {
            label: 'questionnaire.questions.aml.treatments.sub_quest.treatment_stop_reason.options.travel_limitation',
            value: 'travel_limitation',
          },
          {
            label: 'questionnaire.questions.aml.treatments.sub_quest.treatment_stop_reason.options.financial_reasons_or_insurance',
            value: 'financial_reasons_or_insurance',
          },
          {
            label: 'questionnaire.questions.aml.treatments.sub_quest.treatment_stop_reason.options.other',
            value: 'other',
          },
        ],
        conditions: [],
      },
    ],
    conditions: [
      {
        ifId: ID.treatment_for_aml,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'no',
      },
    ],
    // title: '',
    // info: {
    //   title: '',
    //   content: '',
    // },
    // footer: '',
    // type: TREATMENT_HISTORY,
    // section: SECTIONS.TREATMENT,
    // bgIcon: i.treatment,
    // order: 0,
    // options: [],
    // subQuestionsOptions: {
    //   treatment_stop_reason: [
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.no_response',
    //       value: 'no_response',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.end_of_protocol',
    //       value: 'end_of_protocol',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.toxicity',
    //       value: 'toxicity',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.progression',
    //       value: 'progression',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.relapse',
    //       value: 'relapse',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.remission',
    //       value: 'remission',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.other',
    //       value: 'other',
    //     },
    //   ],
    //   taking_state: takingStateOptions,
    // },
    // subQuestionsIds: [
    //   ID.drugs_received,
    //   ID.treatment_stop_reason,
    // ],
    // conditions: [
    //   {
    //     ifId: ID.treatment_for_aml,
    //     type: NOT_EQUAL_TO_OR_SKIPPED,
    //     ifValue: 'no',
    //   },
    // ],
  },
  {
    q: 'Q19',
    id: ID.supportive_care_type,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.aml.supportive_care_type.title',
    info: {
      title: 'questionnaire.questions.aml.supportive_care_type.info.title',
      content: 'questionnaire.questions.aml.supportive_care_type.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.aml.supportive_care_type.options.none',
        value: 'none',
        exclusive: true,
      },
      {
        label: 'questionnaire.questions.aml.supportive_care_type.options.esa',
        value: 'esa',
      },
      {
        label: 'questionnaire.questions.aml.supportive_care_type.options.transfusions',
        value: 'transfusions',
      },
      {
        label: 'questionnaire.questions.aml.supportive_care_type.options.platelet_transfusion',
        value: 'platelet_transfusion',
      },
      {
        label: 'questionnaire.questions.aml.supportive_care_type.options.antifibrolytic_agent',
        value: 'antifibrolytic_agent',
      },
      {
        label: 'questionnaire.questions.aml.supportive_care_type.options.steroids',
        value: 'steroids',
      },
      {
        label: 'questionnaire.questions.aml.supportive_care_type.options.leukapheresis',
        value: 'leukapheresis',
      },
      {
        label: 'questionnaire.questions.aml.supportive_care_type.options.allopurinol_rasburicase',
        value: 'allopurinol_rasburicase',
      },
      {
        label: 'questionnaire.questions.aml.supportive_care_type.options.radiation_bpdcn',
        value: 'radiation_bpdcn',
      },
      {
        label: 'questionnaire.questions.aml.supportive_care_type.options.other',
        value: 'other',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q16',
    id: ID.current_treatment_systemic_steroids,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.aml.current_treatment_systemic_steroids.title',
    subtitle: 'questionnaire.questions.aml.current_treatment_systemic_steroids.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.aml.current_treatment_systemic_steroids.options.under_10_mg_daily',
        value: 'under_10_mg_daily',
      },
      {
        label: 'questionnaire.questions.aml.current_treatment_systemic_steroids.options.over_10_mg_daily',
        value: 'over_10_mg_daily',
      },
      {
        label: 'questionnaire.questions.aml.current_treatment_systemic_steroids.options.none',
        value: 'none',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q16a',
    id: ID.systemic_steroids_treatment_end_date,
    type: MONTH_PICKER,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.aml.systemic_steroids_treatment_end_date.title',
    info: {
      title: '',
      content: '',
    },
    options: [],
    conditions: [
      {
        ifId: ID.current_treatment_systemic_steroids,
        type: EQUAL_TO,
        ifValue: 'under_10_mg_daily',
      },
    ],
    dateType: {
      type: dateType.stopTreatment,
    },
  },
  {
    q: 'Q61',
    id: ID.aml_health_problems,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.aml.aml_health_problems.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.aml.aml_health_problems.options.none',
        value: 'none',
        exclusive: true,
      },
      {
        label: 'questionnaire.questions.aml.aml_health_problems.options.cns',
        value: 'cns',
      },
      {
        label: 'questionnaire.questions.aml.aml_health_problems.options.bleeding',
        value: 'bleeding',
      },
      {
        label: 'questionnaire.questions.aml.aml_health_problems.options.extramedullary',
        value: 'extramedullary',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q17',
    id: ID.ecog,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.aml.ecog.title',
    info: {
      title: 'questionnaire.questions.aml.ecog.info.title',
      content: 'questionnaire.questions.aml.ecog.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.aml.ecog.options.0',
        value: '0',
      },
      {
        label: 'questionnaire.questions.aml.ecog.options.1',
        value: '1',
      },
      {
        label: 'questionnaire.questions.aml.ecog.options.2',
        value: '2',
      },
      {
        label: 'questionnaire.questions.aml.ecog.options.3',
        value: '3',
      },
      {
        label: 'questionnaire.questions.aml.ecog.options.4',
        value: '4',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q45',
    id: ID.gvhd,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.aml.gvhd.title',
    info: {
      title: 'questionnaire.questions.aml.gvhd.info.title',
      content: 'questionnaire.questions.aml.gvhd.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.aml.gvhd.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.aml.gvhd.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.aml.gvhd.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: ID.received_transplant,
        type: CONTAIN_ONE_OF,
        ifValue: ['allogeneic'],
      },
    ],
  },
  {
    q: 'Q46',
    id: ID.other_medical_condition,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.aml.other_medical_condition.title',
    subtitle: 'questionnaire.questions.aml.other_medical_condition.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.aml.other_medical_condition.options.no_other_health_issues',
        value: 'no_other_health_issues',
        exclusive: true,
      },
      {
        label: 'questionnaire.questions.aml.other_medical_condition.options.liver',
        value: 'liver',
      },
      {
        label: 'questionnaire.questions.aml.other_medical_condition.options.heart',
        value: 'heart',
      },
      {
        label: 'questionnaire.questions.aml.other_medical_condition.options.kidney',
        value: 'kidney',
      },
      {
        label: 'questionnaire.questions.aml.other_medical_condition.options.autoimmune',
        value: 'autoimmune',
      },
      {
        label: 'questionnaire.questions.aml.other_medical_condition.options.mental',
        value: 'mental',
      },
      {
        label: 'questionnaire.questions.aml.other_medical_condition.options.allergies',
        value: 'allergies',
      },
      {
        label: 'questionnaire.questions.aml.other_medical_condition.options.chronic_infection_or_inflammation',
        value: 'chronic_infection_or_inflammation',
      },
      {
        label: 'questionnaire.questions.aml.other_medical_condition.options.gastrointestinal',
        value: 'gastrointestinal',
      },
      {
        label: 'questionnaire.questions.aml.other_medical_condition.options.other_cancer_type',
        value: 'other_cancer_type',
      },
      {
        label: 'questionnaire.questions.aml.other_medical_condition.options.anemia',
        value: 'anemia',
      },
      {
        label: 'questionnaire.questions.aml.other_medical_condition.options.thyroid',
        value: 'thyroid',
      },
      {
        label: 'questionnaire.questions.aml.other_medical_condition.options.hypertension',
        value: 'hypertension',
      },
      {
        label: 'questionnaire.questions.aml.other_medical_condition.options.epilepsy',
        value: 'epilepsy',
      },
      {
        label: 'questionnaire.questions.aml.other_medical_condition.options.diabetes',
        value: 'diabetes',
      },
      {
        label: 'questionnaire.questions.aml.other_medical_condition.options.organ_transplant',
        value: 'organ_transplant',
      },
      {
        label: 'questionnaire.questions.aml.other_medical_condition.options.extreme_obesity',
        value: 'extreme_obesity',
      },
      {
        label: 'questionnaire.questions.aml.other_medical_condition.options.stroke',
        value: 'stroke',
      },
      {
        label: 'questionnaire.questions.aml.other_medical_condition.options.lung',
        value: 'lung',
      },
      {
        label: 'questionnaire.questions.aml.other_medical_condition.options.neuropathy',
        value: 'neuropathy',
      },
      {
        label: 'questionnaire.questions.aml.other_medical_condition.options.bleeding',
        value: 'bleeding',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q48',
    id: ID.omc_liver,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.aml.omc_liver.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.aml.omc_liver.options.elevated_alt_ast',
        value: 'elevated_alt_ast',
      },
      {
        label: 'questionnaire.questions.aml.omc_liver.options.elevated_bilirubin',
        value: 'elevated_bilirubin',
      },
      {
        label: 'questionnaire.questions.aml.omc_liver.options.cirrhosis',
        value: 'cirrhosis',
      },
      {
        label: 'questionnaire.questions.aml.omc_liver.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['liver'],
      },
    ],
  },
  {
    q: 'Q49',
    id: ID.omc_heart,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.aml.omc_heart.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.aml.omc_heart.options.class_at_least_nyha_ii',
        value: 'class_at_least_nyha_ii',
      },
      {
        label: 'questionnaire.questions.aml.omc_heart.options.condition_at_least_grade_2',
        value: 'condition_at_least_grade_2',
      },
      {
        label: 'questionnaire.questions.aml.omc_heart.options.cardiac_ventricular_arrhythmia',
        value: 'cardiac_ventricular_arrhythmia',
      },
      {
        label: 'questionnaire.questions.aml.omc_heart.options.heart_failure',
        value: 'heart_failure',
      },
      {
        label: 'questionnaire.questions.aml.omc_heart.options.lvef_under_50_percent',
        value: 'lvef_under_50_percent',
      },
      {
        label: 'questionnaire.questions.aml.omc_heart.options.lvef_under_40_percent',
        value: 'lvef_under_40_percent',
      },
      {
        label: 'questionnaire.questions.aml.omc_heart.options.qtcf_abnormal',
        value: 'qtcf_abnormal',
      },
      {
        label: 'questionnaire.questions.aml.omc_heart.options.angina',
        value: 'angina',
      },
      {
        label: 'questionnaire.questions.aml.omc_heart.options.myocardial_infarction_less_than_6_months',
        value: 'myocardial_infarction_less_than_6_months',
      },
      {
        label: 'questionnaire.questions.aml.omc_heart.options.myocardial_infarction_more_than_6_months',
        value: 'myocardial_infarction_more_than_6_months',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['heart'],
      },
    ],
  },
  {
    q: 'Q50',
    id: ID.omc_stroke_date,
    type: MONTH_PICKER,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.aml.omc_stroke_date.title',
    info: {
      title: '',
      content: '',
    },
    options: [],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['stroke'],
      },
    ],
    dateType: {
      type: dateType.currentDate,
    },
  },
  {
    q: 'Q51',
    id: ID.omc_kidney,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.aml.omc_kidney.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.aml.omc_kidney.options.low_glomerular_filtration_rate',
        value: 'low_glomerular_filtration_rate',
      },
      {
        label: 'questionnaire.questions.aml.omc_kidney.options.dialysis',
        value: 'dialysis',
      },
      {
        label: 'questionnaire.questions.aml.omc_kidney.options.kidney_stones',
        value: 'kidney_stones',
      },
      {
        label: 'questionnaire.questions.aml.omc_kidney.options.creatinine_high',
        value: 'creatinine_high',
      },
      {
        label: 'questionnaire.questions.aml.omc_kidney.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['kidney'],
      },
    ],
  },
  {
    q: 'Q52',
    id: ID.omc_autoimmune,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.aml.omc_autoimmune.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.aml.omc_autoimmune.options.congenital_or_acquired_immunosuppression',
        value: 'congenital_or_acquired_immunosuppression',
      },
      {
        label: 'questionnaire.questions.aml.omc_autoimmune.options.hepatitis',
        value: 'hepatitis',
      },
      {
        label: 'questionnaire.questions.aml.omc_autoimmune.options.granulomatous_disease',
        value: 'granulomatous_disease',
      },
      {
        label: 'questionnaire.questions.aml.omc_autoimmune.options.rheumatoid_arthritis',
        value: 'rheumatoid_arthritis',
      },
      {
        label: 'questionnaire.questions.aml.omc_autoimmune.options.scleroderma',
        value: 'scleroderma',
      },
      {
        label: 'questionnaire.questions.aml.omc_autoimmune.options.lupus',
        value: 'lupus',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['autoimmune'],
      },
    ],
  },
  {
    q: 'Q53',
    id: ID.omc_mental,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.aml.omc_mental.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.aml.omc_mental.options.dementia',
        value: 'dementia',
      },
      {
        label: 'questionnaire.questions.aml.omc_mental.options.neuropsychyatric',
        value: 'neuropsychyatric',
      },
      {
        label: 'questionnaire.questions.aml.omc_mental.options.depression',
        value: 'depression',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['mental'],
      },
    ],
  },
  {
    q: 'Q54',
    id: ID.omc_allergy,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.aml.omc_allergy.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.aml.omc_allergy.options.interferon',
        value: 'interferon',
      },
      {
        label: 'questionnaire.questions.aml.omc_allergy.options.metformin',
        value: 'metformin',
      },
      {
        label: 'questionnaire.questions.aml.omc_allergy.options.vitd',
        value: 'vitd',
      },
      {
        label: 'questionnaire.questions.aml.omc_allergy.options.penicillin',
        value: 'penicillin',
      },
      {
        label: 'questionnaire.questions.aml.omc_allergy.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['allergies'],
      },
    ],
  },
  {
    q: 'Q55',
    id: ID.omc_chronic_infection_inflammation,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.aml.omc_chronic_infection_inflammation.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.aml.omc_chronic_infection_inflammation.options.hepatitis',
        value: 'hepatitis',
      },
      {
        label: 'questionnaire.questions.aml.omc_chronic_infection_inflammation.options.hiv',
        value: 'hiv',
      },
      {
        label: 'questionnaire.questions.aml.omc_chronic_infection_inflammation.options.meningitis',
        value: 'meningitis',
      },
      {
        label: 'questionnaire.questions.aml.omc_chronic_infection_inflammation.options.septicemia',
        value: 'septicemia',
      },
      {
        label: 'questionnaire.questions.aml.omc_chronic_infection_inflammation.options.other',
        value: 'other',
      },
      {
        label: 'questionnaire.questions.aml.omc_chronic_infection_inflammation.options.uncontrolled_infection',
        value: 'uncontrolled_infection',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['chronic_infection_or_inflammation'],
      },
    ],
  },
  {
    q: 'Q58',
    id: ID.omc_gastro_problem,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.aml.omc_gastro_problem.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.aml.omc_gastro_problem.options.gastrointestinal_bleeding',
        value: 'gastrointestinal_bleeding',
      },
      {
        label: 'questionnaire.questions.aml.omc_gastro_problem.options.crohns',
        value: 'crohns',
      },
      {
        label: 'questionnaire.questions.aml.omc_gastro_problem.options.colitis',
        value: 'colitis',
      },
      {
        label: 'questionnaire.questions.aml.omc_gastro_problem.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['gastrointestinal'],
      },
    ],
  },
  {
    q: 'Q59',
    id: ID.omc_lung_disease,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.aml.omc_lung_disease.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.aml.omc_lung_disease.options.interstitial_lung_disease',
        value: 'interstitial_lung_disease',
      },
      {
        label: 'questionnaire.questions.aml.omc_lung_disease.options.pneumonitis',
        value: 'pneumonitis',
      },
      {
        label: 'questionnaire.questions.aml.omc_lung_disease.options.dyspnea',
        value: 'dyspnea',
      },
      {
        label: 'questionnaire.questions.aml.omc_lung_disease.options.tuberculosis',
        value: 'tuberculosis',
      },
      {
        label: 'questionnaire.questions.aml.omc_lung_disease.options.copd',
        value: 'copd',
      },
      {
        label: 'questionnaire.questions.aml.omc_lung_disease.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['lung'],
      },
    ],
  },
  {
    q: 'Q56',
    id: ID.omc_abnormal_blood_results,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.aml.omc_abnormal_blood_results.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.aml.omc_abnormal_blood_results.options.none',
        value: 'none',
      },
      {
        label: 'questionnaire.questions.aml.omc_abnormal_blood_results.options.low_wbc',
        value: 'low_wbc',
      },
      {
        label: 'questionnaire.questions.aml.omc_abnormal_blood_results.options.low_hemoglobin',
        value: 'low_hemoglobin',
      },
      {
        label: 'questionnaire.questions.aml.omc_abnormal_blood_results.options.low_neutrophil_count',
        value: 'low_neutrophil_count',
      },
      {
        label: 'questionnaire.questions.aml.omc_abnormal_blood_results.options.low_platelet_count',
        value: 'low_platelet_count',
      },
      {
        label: 'questionnaire.questions.aml.omc_abnormal_blood_results.options.low_potassium',
        value: 'low_potassium',
      },
      {
        label: 'questionnaire.questions.aml.omc_abnormal_blood_results.options.low_magnesium',
        value: 'low_magnesium',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q18',
    id: ID.blast_platelet_count,
    type: NUMBER_INPUT,
    numberType: numberType.intWithoutUnit,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.aml.blast_platelet_count.title',
    info: {
      title: 'questionnaire.questions.aml.blast_platelet_count.info.title',
      content: 'questionnaire.questions.aml.blast_platelet_count.info.content',
    },
    instruction: 'questionnaire.questions.aml.blast_platelet_count.intuition',
    options: [],
    conditions: [],
  },
  {
    q: 'Q47',
    id: ID.other_medications,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.aml.other_medications.title',
    info: {
      title: 'questionnaire.questions.aml.other_medications.info.title',
      content: 'questionnaire.questions.aml.other_medications.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.aml.other_medications.options.cyp3a4_inhibitors',
        value: 'cyp3a4_inhibitors',
      },
      {
        label: 'questionnaire.questions.aml.other_medications.options.cyp3a4_inducers',
        value: 'cyp3a4_inducers',
      },
      {
        label: 'questionnaire.questions.aml.other_medications.options.immunosuppressive_medications',
        value: 'immunosuppressive_medications',
      },
      {
        label: 'questionnaire.questions.aml.other_medications.options.anticonvulsants',
        value: 'anticonvulsants',
      },
      {
        label: 'questionnaire.questions.aml.other_medications.options.anticoagulant',
        value: 'anticoagulant',
      },
      {
        label: 'questionnaire.questions.aml.other_medications.options.none',
        value: 'none',
      },
    ],
    conditions: [],
  },
  initialDiagnosisDate(),
  userType,
  dateOfBirth,
  {
    q: 'Q2',
    id: ID.gender,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.ABOUT_ME,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.aml.gender.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.aml.gender.options.male',
        value: 'male',
      },
      {
        label: 'questionnaire.questions.aml.gender.options.female',
        value: 'female',
      },
      {
        label: 'questionnaire.questions.global.gender.options.other',
        value: 'other',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q3',
    id: ID.is_pregnant,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.ABOUT_ME,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.aml.is_pregnant.title',
    info: {
      title: 'questionnaire.questions.aml.is_pregnant.info.title',
      content: 'questionnaire.questions.aml.is_pregnant.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.aml.is_pregnant.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.aml.is_pregnant.options.no',
        value: 'no',
      },
    ],
    conditions: [
      {
        ifId: ID.gender,
        type: EQUAL_TO,
        ifValue: 'female',
      },
      {
        type: AGE_LESS_THAN,
        ifValue: 50,
      },
    ],
  },
  {
    q: 'Q4',
    id: ID.is_breastfeeding,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.ABOUT_ME,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.aml.is_breastfeeding.title',
    info: {
      title: 'questionnaire.questions.aml.is_breastfeeding.info.title',
      content: 'questionnaire.questions.aml.is_breastfeeding.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.aml.is_breastfeeding.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.aml.is_breastfeeding.options.no',
        value: 'no',
      },
    ],
    conditions: [
      {
        ifId: ID.gender,
        type: EQUAL_TO,
        ifValue: 'female',
      },
      {
        type: AGE_LESS_THAN,
        ifValue: 50,
      },
    ],
  },
  race,
  haveInsurance,
  insurance,
  preferredLocation,
  travelLimit,
];

export default Questions;
