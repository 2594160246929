import download from 'downloadjs';
import jwtDecode from 'jwt-decode';

import getUrlWithQueryString from 'functions/getUrlWithQueryString';
import configs from 'configs';
import { invoke } from 'functions/api/invokeRequest';
import LOCAL_STORAGE_KEYS from 'utils/constants/localStorageKeys';
import analytics from 'functions/analytics';
import logout from 'functions/clearAuthDataInLocalStorage';
import { authenticationActions } from 'components/Authentication/actions';

class MatchesApi {
  constructor(dispatch) {
    const accessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
    const user = localStorage.getItem(LOCAL_STORAGE_KEYS.USER);

    if (accessToken) {
      let userId = jwtDecode(accessToken).user_wix_id;
      if (!userId) {
        if (dispatch) {
          dispatch(authenticationActions.logout());
        }
        return logout();
      }
      if (userId && userId.startsWith('auth0|')) userId = userId.substr(6);
      this.userId = userId;
    }

    if (user) {
      this.email = JSON.parse(user).email;
    }

    this.createPrintIframe();
  }

  createPrintIframe = () => {
    const existedIframe = document.getElementById('iframe_for_print');
    if (existedIframe) {
      this._printIframe = existedIframe;
    } else {
      const iframe = document.createElement('iframe');
      iframe.id = 'iframe_for_print';
      document.body.appendChild(iframe);
      iframe.style.display = 'none';
      this._printIframe = iframe;
    }
  }

  // getUserProfile() {
  //   return invoke("/user/profile");
  // }

  getFormattedProfile(pdf, lang, setLink = false) {
    return invoke('/profile/details', {
      format: pdf ? 0 : 1,
      pdf: pdf ? 1 : 0,
      lang,
      setLink,
    });
  }

  static contactOncologist(nct_ids) {
    return invoke('/trial/contact/oncologist', null, {
      trial: {
        nct_id: nct_ids.join(','),
      },
      patient: {
        user_wix_id: this.userId,
        email: this.email,
      },
    });
  }

  apply(nct_id, isTest) {
    return invoke('/trial/apply/single', null, {
      trial: { nct_id },
      patient: {
        user_wix_id: this.userId,
        email: this.email,
        isTest,
      },
    });
  }

  applyTrials(nct_ids, isTest) {
    return invoke('/trial/apply/group', null, {
      trials: nct_ids.map((nctId) => ({ nct_id: nctId })),
      patient: {
        user_wix_id: this.userId,
        email: this.email,
        isTest,
      },
    });
  }

  sendDoctorEmail(values, trials, params) {
    return new Promise((resolve) => {
      invoke('/user/profile', {
        lang: params?.lang || null,
      }).then((result) => {
        const country = result.info.country || {};
        const lat = (country.location && country.location.lat) || null;
        const lng = (country.location && country.location.lng) || null;

        const body = {
          user: this.userId,
          firstName: values.first_name,
          lastName: values.last_name,
          doctorName: values.doctorName || '',
          email: values.doctorEmail || '',
          clinicName: values.clinicName || '',
          subjectLine: values.subjectLine || '',
          msg: values.msg || '',
          trials,
          info: result.info,
          trialsUrl: getUrlWithQueryString(`${configs.beApiUrl}/profile/pdf/matches`, {
            profileId: result.personal.profile_id,
            providerCode: result.personal.providerCode,
            lat,
            lng,
            country: country.name || undefined,
          }),
        };
        const params = {
          id: this.userId,
          email: 1,
        };
        return invoke('/profile/trials', params, body).then(() => {
          resolve();
        });
      });
    });
  }

  explainFnFp = (data) => invoke('/user/explain/fnfp', {}, data);

  downloadTrialsPDF = ({
    trials, cancerType, providerCode, pdfFor = null,
  }, cb) => {
    analytics.downloadPdfFromPopup();

    const body = {
      trials,
      cancerType,
      providerCode,
      pdfFor: pdfFor ? String(pdfFor).toLowerCase() : null,
    };

    const params = {
      pdf: 1,
    };

    invoke('/profile/trials', params, body)
      .then((data) => {
        download(data, 'Treatment Match List.pdf', 'application/pdf');
        cb();
      })
      .catch((error) => console.error(error));
  };

  downloadProfilePDF = (lang, onDownload) => {
    this.getFormattedProfile(true, lang)
      .then((data) => {
        download(data, 'Medical_profile.pdf', 'application/pdf');
        onDownload();
      })
      .catch((error) => console.error(error));
  };

  printProfilePDF = (lang, onDownload) => {
    this.getFormattedProfile(true, lang)
      .then((data) => {
        const file = new Blob([data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        this._printIframe.src = fileURL;
        this._printIframe.onload = () => {
          setTimeout(() => {
            this._printIframe.focus();
            this._printIframe.contentWindow.print();
            this._printIframe.onload = () => {};
          }, 1);
        };
        onDownload();
      })
      .catch((error) => console.error(error));
  };

  static downloadSponsorPDF = (nctNumber, queryParams) => {
    const { conditions, ...restQueryParams } = queryParams;
    return invoke('/profile/pdf/sponsor', {
      ...restQueryParams,
      conditions: JSON.stringify(conditions),
    })
      .then((data) => download(data, `Sponsor - ${nctNumber}.pdf`, 'application/pdf'))
      .catch((error) => {
        console.error(error);
        return false;
      });
  };

  sendLearnMoreResult = (data, params) => invoke(
    '/profile/learnmore',
    {
      lang: params?.lang || null,
    },
    data,
  );

  sendContactMeEmail = (data) => invoke('/profile/contactme', null, data);

  sendEmailUs = () => {
    try {
      return invoke('/profile/emailus', null, {
        user_wix_id: this.userId,
      });
    } catch (error) {
      console.error('Error sending email us', error);
    }
  };
}

export default MatchesApi;
