import { ROUTE_NAME, ROUTE_PATH } from '../components/AppRoutes/constants';

export default response => {
  return response.text().then(text => {
    const data = text.startsWith('{') ? JSON.parse(text) : text;
    if (!response.ok) {
      switch (response.status) {
        case 401:
          // Unauthorized, redirect the user to the login page
          window.location.replace(`${window.location.origin}${ROUTE_PATH[ROUTE_NAME.LOGIN]}`);
          break;
        case 404:
          console.error('404:', response);
          break;
        case 419:
          console.log('status 419!', response);
          break;
        default:
          console.log('Something wrong:', response);
      }

      const error = {
        code: response.status,
        message: (data && data.message) || response.statusText,
        errorName: (data && data.error) || null
      };

      return Promise.reject(error);
    }

    return data;
  });
};
