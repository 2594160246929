import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  label: {
    fontSize: '16px',
    letterSpacing: '0',
    lineHeight: '36px',
  },
  checkbox: {
    '& svg': {
      width: '25px !important',
      height: '25px !important',
    },
  },
  labelText: {
    color: 'red',
  },
}));
