/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import './SeeAllOptions.css';

import { PopupTitle, PopupContent, PopupFooter } from 'new-ui/Components/Popup';

import { useTranslation } from 'utils/modifiedTranslation';

import app from 'new-ui/app';

import { PAGES, URI } from 'new-ui/constants';
import { OpenInNewTab } from 'new-ui/Components/OpenInNewTab';

const SeeAllOptions = () => {
  const { user, coniguration } = app;
  const { t } = useTranslation();
  return (
    <div className="see-all-options">
      <PopupTitle title="Your results" />
      <PopupContent>
        <div>{t('popup.see_all_options.content_first')}</div>
        <div>
          {t('popup.see_all_options.content_third', {
            PARTNER_NAME: coniguration?.providerSpecific?.name,
            INDICATION_NAME: user.personal.condition_name,
          })}
        </div>
        <div className="info">{t('popup.see_all_options.content_second')}</div>
      </PopupContent>
      <PopupFooter>
        <a
          className="button see-all-options-button"
          href={`${URI[PAGES.RESULTS]}?detach=1&rematch=1`}
          target="_blank"
          onClick={() => {
            OpenInNewTab();
          }}
        >
          {t('popup.see_all_options_yes_button')}
        </a>
      </PopupFooter>
    </div>
  );
};

export default SeeAllOptions;
