import React from 'react';
import './Footer.css';

import app from 'new-ui/app';
import ProviderFooter from './ProviderFooter';

const DashboardFooter = () => (
  <footer>
    { app.provider && <ProviderFooter />}
  </footer>
);

export default DashboardFooter;
