import React from 'react';
import './ErrorMessage.css';
import PropTypes from 'prop-types';
import Button from 'new-ui/Components/Button';
import { useTranslation } from 'utils/modifiedTranslation';

const ErrorMessage = ({
  message, setUploaderState, uploader,
})=>{
  const { t } = useTranslation();

  return (
    <div className="error-message-wrapper">
      <div className="oops">{t('upload.error_oops_text')}</div>
      <div className="message-content">{message || ''}</div>
      <div>
        <Button
          title={t('upload.error_btn_re_upload_text')}
          action={()=>{
            setUploaderState('SELECT');
            uploader.setState('SELECT');
          }}
        />
      </div>
    </div>
  );
};

ErrorMessage.propTypes = {
  message: PropTypes.string,
  setUploaderState: PropTypes.func,
  uploader: PropTypes.func,
};

export default ErrorMessage;
