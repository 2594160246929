import React, { useState } from 'react';
import './ShareWithDoctor.css';

import { useTranslation } from 'utils/modifiedTranslation';

import app from 'new-ui/app';

import Form, { FORM_INPUT_TYPES as INPUT_TYPES } from 'new-ui/Components/Form';
import PropTypes from 'prop-types';

import MatchesApi from 'components/Matches/api';
import { clearTrialsForPDF } from 'components/ShareDoctor/functions';

import {
  PopupTitle, PopupContent,
} from 'new-ui/Components/Popup';
import { INTERCOM_EVENTS, ITEM_TYPES, LOCAL_STORAGE_KEYS } from 'new-ui/constants';

const ShareWithDoctor = (props) => {
  const {
    closePopup, doctor = {}, source, items = app.trials ?? [], type = ITEM_TYPES.TRIALS,
  } = props;
  const { t } = useTranslation();
  const [sentSuccess, setSentSuccess] = useState(false);

  const api = new MatchesApi();

  const inputs = {
    doctorName: {
      title: t('share_with_doctor.doctor_name'),
      type: INPUT_TYPES.INPUT,
      required: false,
    },
    doctorEmail: {
      title: t('share_with_doctor.doctor_email'),
      type: INPUT_TYPES.INPUT,
      email: true,
    },
  };

  for (const x in inputs) {
    if (!('required' in inputs[x])) {
      inputs[x].required = true;
    }
  }

  const data = {
    doctorName: doctor.first_name || '',
    doctorEmail: doctor?.email || '',
    clinicName: doctor?.clinic || '',
    subjectLine: t('share_with_doctor.subject_line'),
    msg: `${t(`share_with_doctor.message_text_${type}`)}
${app?.user?.info?.family_name || ''}
    `,
  };

  const send = () => {
    app.setShowPreloader(true);
    if (data.doctorName) {
      data.first_name = data.doctorName.split(' ').slice(0, -1).join(' ') || '';
      data.last_name = data.doctorName.split(' ').slice(-1).join(' ') || '';
    }
    api.sendDoctorEmail(data, clearTrialsForPDF(items), { lang: 'en' }).then(() => {
      localStorage.setItem(LOCAL_STORAGE_KEYS.DOCTOR_SHARED, 1);
      if (source) app.intercom.sendEvent(INTERCOM_EVENTS.SHARE_WITH_DOCTOR.POPUP_SUCCESS, { source });
      setSentSuccess(true);
      setTimeout(async () => {
        app.setShowPreloader(false);
        app.setConfiguration({
          lastShareWithDoctor: new Date().toISOString(),
        });
        app.renderPage();
        closePopup();
      }, 3000);
    });
  };

  return (
    <div className="share-with-doctor">
      <PopupTitle title={t('share_with_doctor.title')} />
      <PopupContent>
        {!sentSuccess ? <Form inputs={inputs} data={data} popupMode submit={{ title: t('share_with_doctor.send'), action: send }} />
          : (
            <div>
              {t('share_with_doctor.successfully_sent')}
            </div>
          )}
      </PopupContent>
    </div>
  );
};

ShareWithDoctor.propTypes = {
  closePopup: PropTypes.func,
  doctor: PropTypes.func,
  source: PropTypes.string,
  type: PropTypes.string,
  items: PropTypes.array,
  showDownload: PropTypes.bool,
};

export default ShareWithDoctor;
