export default {
  SEND_FORM_REQUEST: 'SEND_FORM_REQUEST',
  SEND_FORM_SUCCESS: 'SEND_FORM_SUCCESS',

  SEND_VERIFICATION_CODE_REQUEST: 'SEND_VERIFICATION_CODE_REQUEST',
  SEND_VERIFICATION_CODE_SUCCESS: 'SEND_VERIFICATION_CODE_SUCCESS',

  SET_AUTHENTICATION_DATA: 'SET_AUTHENTICATION_DATA',
  USER_AUTHENTICATION_FAILURE: 'USER_AUTHENTICATION_FAILURE',

  GET_USER_REQUEST: 'GET_USER_REQUEST',
  GET_USER_SUCCESS: 'GET_USER_SUCCESS',
  GET_USER_FAILURE: 'GET_USER_FAILURE',

  UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',

  CREATE_USER_REQUEST: 'CREATE_USER_REQUEST',
  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
  CREATE_USER_FAILURE: 'CREATE_USER_FAILURE',

  LOGOUT: 'USERS_LOGOUT',
  SOFT_LOGOUT: 'SOFT_LOGOUT',

  SET_FORM_VALIDITY: 'SET_FORM_VALIDITY',
  SET_PHONE_CONFIRMATION_RESPONSE: 'SET_PHONE_CONFIRMATION_RESPONSE',
  RESET_FORM_VALIDITY: 'RESET_FORM_VALIDITY',
  RESET_LOGGED_IN: 'RESET_LOGGED_IN',
  RESET_PHONE_CONFIRMATION_RESPONSE: 'RESET_PHONE_CONFIRMATION_RESPONSE',

  SET_CONDITION: 'SET_CONDITION',
  SET_USER_DATA: 'SET_USER_DATA',
  SET_USER_WIX_ID: 'SET_USER_WIX_ID',
  SET_PROFILE_ID: 'SET_PROFILE_ID',
  SET_TRIALS_COUNT: 'SET_TRIALS_COUNT',
  SET_PATIENT_ID: 'SET_PATIENT_ID',
  SET_NEW_PROFILE: 'SET_NEW_PROFILE',
  SET_CONDITION_PROFILE: 'SET_CONDITION_PROFILE',
  CLEAR_DATA: 'CLEAR_DATA',
  SOFT_CLEAR_DATA: 'SOFT_CLEAR_DATA',

  SET_SUPER_USER: 'SET_SUPER_USER',

  SET_PROFILE_DETAILS:'SET_PROFILE_DETAILS',
};
