import ConditionTypes from '../constants/conditions';
import { checkIfEqualTo } from './checkIfEqualTo';
import { checkIfNotEqualTo } from './checkIfNotEqualTo';
import { checkIfContainsOneOf } from './checkIfContainsOneOf';
import { checkIfDoesntContainsOneOf } from './checkIfDoesntConainsOneOf';
import { ageLessThan } from './ageLessThan';
import { checkIfSkipped } from './checkIfSkipped';
import { checkIfStateValueIsTrue } from './checkIfStateValueIsTrue';

export function conditionCheckByType(profile, condition, state) {
  switch (condition.type) {
    case ConditionTypes.EQUAL_TO:
      return checkIfEqualTo(profile, condition);
    case ConditionTypes.NOT_EQUAL_TO_OR_SKIPPED:
      return checkIfNotEqualTo(profile, condition, state);
    case ConditionTypes.CONTAIN_ONE_OF:
      return checkIfContainsOneOf(profile, condition);
    case ConditionTypes.CONTAINS_ONE_OF_OR_SKIPPED:
      return checkIfContainsOneOf(profile, condition) || checkIfSkipped(profile, condition);
    case ConditionTypes.DOESNT_CONTAIN_ONE_OF_OR_SKIPPED:
      return checkIfDoesntContainsOneOf(profile, condition);
    case ConditionTypes.AGE_LESS_THAN:
      return ageLessThan(profile, condition);
    case ConditionTypes.IS_SKIPPED:
      return checkIfSkipped(profile, condition);
    case ConditionTypes.IS_STATE_VALUE_TRUE:
      return checkIfStateValueIsTrue(state, condition);
    default:
      return new Error("Condition type couldn't be found");
  }
}
