import {
  MONTH_PICKER,
  SINGLE_OPTION_SELECT,
  NUMBER_INPUT,
  MULTIPLE_OPTION_SELECT,
  BIOMARKERS,
} from 'components/Questionnaire/constants/questionTypes';
import { Profile as ID } from 'components/Questionnaire/cancers/mds/MdsProfile.enum';
import ConditionTypes from 'components/Questionnaire/constants/conditions';
import i from 'components/Questionnaire/constants/icons';
import dateType from 'utils/constants/dateTypes';
import numberType from 'utils/constants/numberTypes';
import {
  preferredLocation,
  travelLimit,
  userType,
  diseaseStatus,
  race,
  haveInsurance,
  insurance,
  initialDiagnosisDate,
} from 'components/Questionnaire/constants/globalQuestions';
import SECTIONS from 'components/Questionnaire/constants/questionSections';
import questionOptions from 'components/Questionnaire/constants/questionOptions';
import GLOBAL_ENUMS from '../../constants/globalEnums';

const { biomarkers: BIOMARKERS_QUESTION_ID } = GLOBAL_ENUMS;

const noneOption = questionOptions.NONE;
const {
  NOT_EQUAL_TO_OR_SKIPPED,
  EQUAL_TO,
  CONTAIN_ONE_OF,
  AGE_LESS_THAN,
  DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
  CONTAINS_ONE_OF_OR_SKIPPED,
} = ConditionTypes;

const stopReasonConditions = [
  {
    label: 'questionnaire.questions.mds.stop_reason_conditions.treatment_ongoing',
    value: 'treatment_ongoing',
  },
  {
    label: 'questionnaire.questions.mds.stop_reason_conditions.remission',
    value: 'remission',
  },
  {
    label: 'questionnaire.questions.mds.stop_reason_conditions.no_response',
    value: 'no_response',
  },
  {
    label: 'questionnaire.questions.mds.stop_reason_conditions.relapse',
    value: 'relapse',
  },
  {
    label: 'questionnaire.questions.mds.stop_reason_conditions.toxicity',
    value: 'toxicity',
  },
];
const Questions = [
  diseaseStatus,
  {
    q: 'Q4',
    id: ID.type_of_mds,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.mds.3_type_of_mds.title',

    info: {
      title: 'questionnaire.questions.mds.3_type_of_mds.info.title',
      content: 'questionnaire.questions.mds.3_type_of_mds.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.mds.3_type_of_mds.options.mds_mld',
        value: 'mds_mld',
      },
      {
        label: 'questionnaire.questions.mds.3_type_of_mds.options.mds_sld',
        value: 'mds_sld',
      },
      {
        label: 'questionnaire.questions.mds.3_type_of_mds.options.mds_rs',
        value: 'mds_rs',
      },
      {
        label: 'questionnaire.questions.mds.3_type_of_mds.options.mds_eb',
        value: 'mds_eb',
      },
      {
        label: 'questionnaire.questions.mds.3_type_of_mds.options.5q',
        value: '5q',
      },
      {
        label: 'questionnaire.questions.mds.3_type_of_mds.options.mds_u',
        value: 'mds_u',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q7',
    id: ID.risk_group,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.mds.6_risk_group.title',

    info: {
      title: 'questionnaire.questions.mds.6_risk_group.info.title',
      content: 'questionnaire.questions.mds.6_risk_group.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.mds.6_risk_group.options.very_low_risk',
        value: 'very_low_risk',
      },
      {
        label: 'questionnaire.questions.mds.6_risk_group.options.low_risk',
        value: 'low_risk',
      },
      {
        label: 'questionnaire.questions.mds.6_risk_group.options.intermediate_risk',
        value: 'intermediate_risk',
      },
      {
        label: 'questionnaire.questions.mds.6_risk_group.options.high_risk',
        value: 'high_risk',
      },
      {
        label: 'questionnaire.questions.mds.6_risk_group.options.very_high_risk',
        value: 'very_high_risk',
      },
    ],
    conditions: [],
    isRequired: true,
  },
  {
    q: 'Q13',
    id: ID.treatment_for_mds,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.mds.10_treatment_for_mds.title',

    info: {
      title: 'questionnaire.questions.mds.10_treatment_for_mds.info.title',
      content: 'questionnaire.questions.mds.10_treatment_for_mds.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.mds.10_treatment_for_mds.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.mds.10_treatment_for_mds.options.no',
        value: 'no',
      },
    ],
    conditions: [],
    isRequired: true,
  },
  {
    q: 'Q4a',
    id: ID.type_of_mds_rs,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.mds.4_type_of_mds_rs.title',

    info: {
      title: 'questionnaire.questions.mds.4_type_of_mds_rs.info.title',
      content: 'questionnaire.questions.mds.4_type_of_mds_rs.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.mds.4_type_of_mds_rs.options.mds_rs_sld',
        value: 'mds_rs_sld',
      },
      {
        label: 'questionnaire.questions.mds.4_type_of_mds_rs.options.mds_rs_mld',
        value: 'mds_rs_mld',
      },
    ],
    conditions: [
      {
        ifId: ID.type_of_mds,
        type: EQUAL_TO,
        ifValue: 'mds_rs',
      },
    ],
  },
  {
    q: 'Q4b',
    id: ID.type_of_mds_eb,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.mds.5_type_of_mds_eb.title',

    info: {
      title: 'questionnaire.questions.mds.5_type_of_mds_eb.info.title',
      content: 'questionnaire.questions.mds.5_type_of_mds_eb.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.mds.5_type_of_mds_eb.options.mds_eb1',
        value: 'mds_eb1',
      },
      {
        label: 'questionnaire.questions.mds.5_type_of_mds_eb.options.mds_eb2',
        value: 'mds_eb2',
      },
    ],
    conditions: [
      {
        ifId: ID.type_of_mds,
        type: EQUAL_TO,
        ifValue: 'mds_eb',
      },
    ],
  },
  {
    q: 'Q5',
    id: ID.percentage_of_bm_blasts,
    type: NUMBER_INPUT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.mds.8_percentage_of_bm_blasts.title',
    label: 'questionnaire.questions.mds.8_percentage_of_bm_blasts.placeholder',
    info: {
      title: 'questionnaire.questions.mds.8_percentage_of_bm_blasts.info.title',
      content: 'questionnaire.questions.mds.8_percentage_of_bm_blasts.info.content',
    },
    options: [],
    conditions: [],
    numberType: numberType.percentage,
  },
  {
    q: 'Q7a',
    id: ID.intermediate_risk_subgroup,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.mds.7_intermediate_risk_subgroup.title',

    info: {
      title: 'questionnaire.questions.mds.7_intermediate_risk_subgroup.info.title',
      content: 'questionnaire.questions.mds.7_intermediate_risk_subgroup.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.mds.7_intermediate_risk_subgroup.options.intermediate_1',
        value: 'intermediate_1',
      },
      {
        label: 'questionnaire.questions.mds.7_intermediate_risk_subgroup.options.intermediate_2',
        value: 'intermediate_2',
      },
      {
        label: 'questionnaire.questions.mds.7_intermediate_risk_subgroup.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: ID.risk_group,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['very_low_risk', 'low_risk', 'high_risk', 'very_high_risk'],
      },
    ],
    isRequired: true,
  },
  {
    q: 'Q8',
    id: ID.clinical_classification,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.mds.9_clinical_classification.title',

    info: {
      title: 'questionnaire.questions.mds.9_clinical_classification.info.title',
      content: 'questionnaire.questions.mds.9_clinical_classification.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.mds.9_clinical_classification.options.none',
        value: 'none',
      },
      {
        label: 'questionnaire.questions.mds.9_clinical_classification.options.primary',
        value: 'primary',
      },
      {
        label: 'questionnaire.questions.mds.9_clinical_classification.options.secondary',
        value: 'secondary',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q9',
    id: ID.received_allogenic_transplant,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.mds.12_received_allogenic_transplant.title',

    info: {
      title: 'questionnaire.questions.mds.12_received_allogenic_transplant.info.title',
      content: 'questionnaire.questions.mds.12_received_allogenic_transplant.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.mds.12_received_allogenic_transplant.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.mds.12_received_allogenic_transplant.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.mds.12_received_allogenic_transplant.options.candidate_no_match',
        value: 'candidate_no_match',
      },
      {
        label: 'questionnaire.questions.mds.12_received_allogenic_transplant.options.scheduled_related',
        value: 'scheduled_related',
      },
      {
        label: 'questionnaire.questions.mds.12_received_allogenic_transplant.options.scheduled_unrelated',
        value: 'scheduled_unrelated',
      },
    ],
    conditions: [
      {
        ifId: ID.risk_group,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['very_low_risk', 'low_risk'],
      },
    ],
    isRequired: true,
  },
  {
    q: 'Q9a',
    id: ID.allogenic_transplant_date,
    type: MONTH_PICKER,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.mds.13_allogenic_transplant_date.title',

    info: {
      title: 'questionnaire.questions.mds.13_allogenic_transplant_date.info.title',
      content: 'questionnaire.questions.mds.13_allogenic_transplant_date.info.content',
    },
    options: [],
    conditions: [
      {
        ifId: ID.received_allogenic_transplant,
        type: EQUAL_TO,
        ifValue: 'yes',
      },
    ],
    dateType: {
      type: dateType.stopTreatment,
    },
  },
  {
    q: 'Q9b',
    id: ID.allogenic_transplant_outcome,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.mds.14_allogenic_transplant_outcome.title',

    info: {
      title: 'questionnaire.questions.mds.14_allogenic_transplant_outcome.info.title',
      content: 'questionnaire.questions.mds.14_allogenic_transplant_outcome.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.mds.14_allogenic_transplant_outcome.options.remission',
        value: 'remission',
      },
      {
        label: 'questionnaire.questions.mds.14_allogenic_transplant_outcome.options.no_response',
        value: 'no_response',
      },
      {
        label: 'questionnaire.questions.mds.14_allogenic_transplant_outcome.options.relapse',
        value: 'relapse',
      },
    ],
    conditions: [
      {
        ifId: ID.received_allogenic_transplant,
        type: EQUAL_TO,
        ifValue: 'yes',
      },
    ],
  },
  {
    q: 'Q9c',
    id: ID.treatment_after_transplant_relapse,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.mds.15_treatment_after_transplant_relapse.title',
    subtitle: 'questionnaire.questions.mds.15_treatment_after_transplant_relapse.subtitle',
    info: {
      title: 'questionnaire.questions.mds.15_treatment_after_transplant_relapse.info.title',
      content: 'questionnaire.questions.mds.15_treatment_after_transplant_relapse.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.mds.15_treatment_after_transplant_relapse.options.none',
        value: 'none',
        exclusive: true,
      },
      {
        label: 'questionnaire.questions.mds.15_treatment_after_transplant_relapse.options.2nd_transplant',
        value: '2nd_transplant',
      },
      {
        label: 'questionnaire.questions.mds.15_treatment_after_transplant_relapse.options.lymphocyte_infusion',
        value: 'lymphocyte_infusion',
      },
      {
        label: 'questionnaire.questions.mds.15_treatment_after_transplant_relapse.options.azacitidine',
        value: 'azacitidine',
      },
      {
        label: 'questionnaire.questions.mds.15_treatment_after_transplant_relapse.options.decitabine',
        value: 'decitabine',
      },
      {
        label: 'questionnaire.questions.mds.15_treatment_after_transplant_relapse.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.allogenic_transplant_outcome,
        type: CONTAIN_ONE_OF,
        ifValue: ['no_response', 'relapse'],
      },
    ],
  },
  {
    q: 'Q9d',
    id: ID.second_allogenic_transplant_outcome,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.mds.16_second_allogenic_transplant_outcome.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.mds.16_second_allogenic_transplant_outcome.options.remission',
        value: 'remission',
      },
      {
        label: 'questionnaire.questions.mds.16_second_allogenic_transplant_outcome.options.no_response',
        value: 'no_response',
      },
      {
        label: 'questionnaire.questions.mds.16_second_allogenic_transplant_outcome.options.relapse',
        value: 'relapse',
      },
    ],
    conditions: [
      {
        ifId: ID.treatment_after_transplant_relapse,
        type: EQUAL_TO,
        ifValue: '2nd_transplant',
      },
    ],
  },
  {
    q: 'Q9e',
    id: ID.number_of_azacitidine_treatment_cycles,
    type: NUMBER_INPUT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.mds.17_number_of_azacitidine_treatment_cycles.title',
    info: {
      title: 'questionnaire.questions.mds.17_number_of_azacitidine_treatment_cycles.info.title',
      content: 'questionnaire.questions.mds.17_number_of_azacitidine_treatment_cycles.info.content',
    },
    options: [],
    conditions: [
      {
        ifId: ID.treatment_after_transplant_relapse,
        type: CONTAIN_ONE_OF,
        ifValue: ['azacitidine', 'decitabine'],
      },
    ],
    numberType: numberType.intWithoutUnit,
  },
  {
    q: 'Q9g',
    id: ID.treatment_after_transplant_relapse_outcome,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.mds.19_treatment_after_transplant_relapse_outcome.title',

    info: {
      title: 'questionnaire.questions.mds.19_treatment_after_transplant_relapse_outcome.info.title',
      content: 'questionnaire.questions.mds.19_treatment_after_transplant_relapse_outcome.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.mds.19_treatment_after_transplant_relapse_outcome.options.treatment_ongoing',
        value: 'treatment_ongoing',
      },
      {
        label: 'questionnaire.questions.mds.19_treatment_after_transplant_relapse_outcome.options.remission',
        value: 'remission',
      },
      {
        label: 'questionnaire.questions.mds.19_treatment_after_transplant_relapse_outcome.options.no_response',
        value: 'no_response',
      },
      {
        label: 'questionnaire.questions.mds.19_treatment_after_transplant_relapse_outcome.options.relapse',
        value: 'relapse',
      },
      {
        label: 'questionnaire.questions.mds.19_treatment_after_transplant_relapse_outcome.options.toxicity',
        value: 'toxicity',
      },
    ],
    conditions: [
      {
        ifId: ID.treatment_after_transplant_relapse,
        type: CONTAIN_ONE_OF,
        ifValue: ['2nd_transplant', 'lymphocyte_infusion', 'azacitidine', 'decitabine', 'other'],
      },
    ],
  },
  {
    q: 'Q10',
    id: ID.treatment_nontransplant_candidate,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.mds.20_treatment_nontransplant_candidate.title',
    subtitle: 'questionnaire.questions.mds.20_treatment_nontransplant_candidate.subtitle',
    info: {
      title: 'questionnaire.questions.mds.20_treatment_nontransplant_candidate.info.title',
      content: 'questionnaire.questions.mds.20_treatment_nontransplant_candidate.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.mds.20_treatment_nontransplant_candidate.options.none',
        value: 'none',
        exclusive: true,
      },
      {
        label: 'questionnaire.questions.mds.20_treatment_nontransplant_candidate.options.azacitidine',
        value: 'azacitidine',
      },
      {
        label: 'questionnaire.questions.mds.20_treatment_nontransplant_candidate.options.decitabine',
        value: 'decitabine',
      },
      {
        label: 'questionnaire.questions.mds.20_treatment_nontransplant_candidate.options.chemotherapy_other',
        value: 'chemotherapy_other',
      },
      {
        label: 'questionnaire.questions.mds.20_treatment_nontransplant_candidate.options.hydroxyurea',
        value: 'hydroxyurea',
      },
      {
        label: 'questionnaire.questions.mds.20_treatment_nontransplant_candidate.options.venetoclax_navitoclax',
        value: 'venetoclax_navitoclax',
      },
      {
        label: 'questionnaire.questions.mds.20_treatment_nontransplant_candidate.options.not_yet',
        value: 'not_yet',
      },
    ],
    conditions: [
      {
        ifId: ID.risk_group,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['very_low_risk', 'low_risk'],
      },
      {
        ifId: ID.treatment_for_mds,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'no',
      },
    ],
  },
  {
    q: 'Q10c',
    id: ID.chemotherapy_other,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.mds.21_chemotherapy_other.title',
    subtitle: 'questionnaire.questions.mds.21_chemotherapy_other.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.mds.21_chemotherapy_other.options.temozolomide',
        value: 'temozolomide',
      },
      {
        label: 'questionnaire.questions.mds.21_chemotherapy_other.options.dacarbazine',
        value: 'dacarbazine',
      },
      {
        label: 'questionnaire.questions.mds.21_chemotherapy_other.options.procarbazine',
        value: 'procarbazine',
      },
      {
        label: 'questionnaire.questions.mds.21_chemotherapy_other.options.mec',
        value: 'mec',
      },
      {
        label: 'questionnaire.questions.mds.21_chemotherapy_other.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.treatment_nontransplant_candidate,
        type: EQUAL_TO,
        ifValue: 'chemotherapy_other',
      },
    ],
  },
  {
    q: 'Q10d',
    id: ID.number_of_azacitidine_treatment_cycles,
    type: NUMBER_INPUT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.mds.22_number_of_azacitidine_treatment_cycles.title',

    info: {
      title: 'questionnaire.questions.mds.22_number_of_azacitidine_treatment_cycles.info.title',
      content: 'questionnaire.questions.mds.22_number_of_azacitidine_treatment_cycles.info.content',
    },
    options: [],
    conditions: [
      {
        ifId: ID.treatment_nontransplant_candidate,
        type: CONTAIN_ONE_OF,
        ifValue: ['azacitidine', 'decitabine'],
      },
    ],
    numberType: numberType.intWithoutUnit,
  },
  {
    q: 'Q10b',
    id: ID.nontransplant_candidate_treatment_outcome,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.mds.24_nontransplant_candidate_treatment_outcome.title',

    info: {
      title: 'questionnaire.questions.mds.24_nontransplant_candidate_treatment_outcome.info.title',
      content: 'questionnaire.questions.mds.24_nontransplant_candidate_treatment_outcome.info.content',
    },
    options: stopReasonConditions,
    conditions: [
      {
        ifId: ID.treatment_nontransplant_candidate,
        type: CONTAIN_ONE_OF,
        ifValue: ['azacitidine', 'decitabine', 'chemotherapy_other'],
      },
    ],
    numberType: numberType.int,
  },
  {
    q: 'Q10a',
    id: ID.chemotherapy_treatment_last_date,
    type: MONTH_PICKER,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.mds.25_chemotherapy_treatment_last_date.title',

    info: {
      title: 'questionnaire.questions.mds.25_chemotherapy_treatment_last_date.info.title',
      content: 'questionnaire.questions.mds.25_chemotherapy_treatment_last_date.info.content',
    },
    options: [],
    conditions: [
      {
        ifId: ID.treatment_nontransplant_candidate,
        type: CONTAIN_ONE_OF,
        ifValue: ['azacitidine', 'decitabine', 'chemotherapy_other'],
      },
    ],
    dateType: {
      type: dateType.stopTreatment,
    },
  },
  {
    q: 'Q11',
    id: ID.treatment_for_low_risk_mds,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.mds.26_treatment_for_low_risk_mds.title',
    subtitle: 'questionnaire.questions.mds.26_treatment_for_low_risk_mds.subtitle',
    info: {
      title: 'questionnaire.questions.mds.26_treatment_for_low_risk_mds.info.title',
      content: 'questionnaire.questions.mds.26_treatment_for_low_risk_mds.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.mds.26_treatment_for_low_risk_mds.options.none',
        value: 'none',
        exclusive: true,
      },
      {
        label: 'questionnaire.questions.mds.26_treatment_for_low_risk_mds.options.lenalidomide',
        value: 'lenalidomide',
      },
      {
        label: 'questionnaire.questions.mds.26_treatment_for_low_risk_mds.options.epoetin_alfa',
        value: 'epoetin_alfa',
      },
      {
        label: 'questionnaire.questions.mds.26_treatment_for_low_risk_mds.options.darbepoetin_alfa',
        value: 'darbepoetin_alfa',
      },
      {
        label: 'questionnaire.questions.mds.26_treatment_for_low_risk_mds.options.g_csf',
        value: 'g_csf',
      },
      {
        label: 'questionnaire.questions.mds.26_treatment_for_low_risk_mds.options.atg',
        value: 'atg',
      },
      {
        label: 'questionnaire.questions.mds.26_treatment_for_low_risk_mds.options.cyclosporine',
        value: 'cyclosporine',
      },
      {
        label: 'questionnaire.questions.mds.26_treatment_for_low_risk_mds.options.romiplostim',
        value: 'romiplostim',
      },
      {
        label: 'questionnaire.questions.mds.26_treatment_for_low_risk_mds.options.eltrombopag',
        value: 'eltrombopag',
      },
      {
        label: 'questionnaire.questions.mds.26_treatment_for_low_risk_mds.options.oprelvekin',
        value: 'oprelvekin',
      },
      {
        label: 'questionnaire.questions.mds.26_treatment_for_low_risk_mds.options.azacitidine',
        value: 'azacitidine',
      },
      {
        label: 'questionnaire.questions.mds.26_treatment_for_low_risk_mds.options.decitabine',
        value: 'decitabine',
      },
      {
        label: 'questionnaire.questions.mds.26_treatment_for_low_risk_mds.options.decitabine_cedazuridine',
        value: 'decitabine_cedazuridine',
      },
    ],
    conditions: [
      {
        ifId: ID.risk_group,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['very_high_risk', 'high_risk'],
      },
      {
        ifId: ID.treatment_for_mds,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'no',
      },
    ],
    isRequired: true,
  },
  {
    q: 'Q11c',
    id: ID.number_of_azacitidine_treatment_cycles,
    type: NUMBER_INPUT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.mds.27_number_of_azacitidine_treatment_cycles.title',

    info: {
      title: 'questionnaire.questions.mds.27_number_of_azacitidine_treatment_cycles.info.title',
      content: 'questionnaire.questions.mds.27_number_of_azacitidine_treatment_cycles.info.content',
    },
    options: [],
    conditions: [
      {
        ifId: ID.treatment_for_low_risk_mds,
        type: CONTAIN_ONE_OF,
        ifValue: ['azacitidine', 'decitabine'],
      },
    ],
    numberType: numberType.intWithoutUnit,
  },
  {
    q: 'Q11a',
    id: ID.treatment_received_last_date,
    type: MONTH_PICKER,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.mds.29_treatment_received_last_date.title',

    info: {
      title: 'questionnaire.questions.mds.29_treatment_received_last_date.info.title',
      content: 'questionnaire.questions.mds.29_treatment_received_last_date.info.content',
    },
    options: [],
    conditions: [
      {
        ifId: ID.treatment_for_mds,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'no',
      },
      {
        ifId: ID.treatment_after_transplant_relapse,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['none'],
      },
      {
        ifId: ID.treatment_nontransplant_candidate,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['azacitidine', 'decitabine', 'chemotherapy_other'],
      },
    ],
    dateType: {
      type: dateType.stopTreatment,
    },
  },
  {
    q: 'Q11b',
    id: ID.treatment_for_low_risk_mds_treatment_outcome,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.mds.30_treatment_for_low_risk_mds_treatment_outcome.title',

    info: {
      title: '',
      content: '',
    },
    options: stopReasonConditions,
    conditions: [
      {
        ifId: ID.treatment_for_low_risk_mds,
        type: CONTAIN_ONE_OF,
        ifValue: [
          'lenalidomide',
          'epoetin_alfa',
          'darbepoetin_alfa',
          'g_csf',
          'atg',
          'cyclosporine',
          'romiplostim',
          'eltrombopag',
          'oprelvekin',
          'azacitidine',
          'decitabine',
        ],
      },
    ],
  },
  {
    q: 'Q13a',
    id: ID.number_of_lines_of_treatment,
    type: NUMBER_INPUT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.mds.31_number_of_lines_of_treatment.title',

    info: {
      title: 'questionnaire.questions.mds.31_number_of_lines_of_treatment.info.title',
      content: 'questionnaire.questions.mds.31_number_of_lines_of_treatment.info.content',
    },
    options: [],
    numberType: numberType.int,
    conditions: [
      {
        ifId: ID.treatment_for_mds,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'no',
      },
      {
        ifId: ID.treatment_after_transplant_relapse,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['none'],
      },
    ],
  },
  {
    q: 'Q18',
    id: ID.has_biopsy,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.mds.32_has_biopsy.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.mds.32_has_biopsy.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.mds.32_has_biopsy.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.mds.32_has_biopsy.options.biopsy_possible',
        value: 'biopsy_possible',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q80',
    id: ID.ngs,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.molecular,
    order: 0,
    title: 'questionnaire.questions.mds.57_ngs.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.mds.57_ngs.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.mds.57_ngs.options.yes_waiting_for_the_results',
        value: 'yes_waiting_for_the_results',
      },
      {
        label: 'questionnaire.questions.mds.57_ngs.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.mds.57_ngs.options.no_but_planning',
        value: 'no_but_planning',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q18a',
    id: BIOMARKERS_QUESTION_ID,
    type: BIOMARKERS,
    section: SECTIONS.TREATMENT,
    bgIcon: i.molecular,
    order: 0,
    title: 'questionnaire.questions.mds.33_biomarkers.title',
    subtitle: 'questionnaire.questions.mds.33_biomarkers.subtitle',
    placeholder: 'questionnaire.questions.biomarkers.placeholder',
    info: {
      title: '',
      content: '',
    },
    options: null,
    conditions: [
      {
        ifId: ID.has_biopsy,
        type: CONTAINS_ONE_OF_OR_SKIPPED,
        ifValue: ['yes'],
      },
    ],
  },
  {
    q: 'Q14',
    id: ID.received_investigational_therapy,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.mds.34_received_investigational_therapy.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.mds.34_received_investigational_therapy.options.none',
        value: 'none',
      },
      {
        label: 'questionnaire.questions.mds.34_received_investigational_therapy.options.parp_inhibitor',
        value: 'parp_inhibitor',
      },
      {
        label: 'questionnaire.questions.mds.34_received_investigational_therapy.options.pd1_or_pdl1_inhibitor',
        value: 'pd1_or_pdl1_inhibitor',
      },
      {
        label: 'questionnaire.questions.mds.34_received_investigational_therapy.options.immunotherapy_other',
        value: 'immunotherapy_other',
      },
      {
        label: 'questionnaire.questions.mds.34_received_investigational_therapy.options.daratumumab',
        value: 'daratumumab',
      },
      {
        label: 'questionnaire.questions.mds.34_received_investigational_therapy.options.vegf_inhibitors',
        value: 'vegf_inhibitors',
      },
      {
        label: 'questionnaire.questions.mds.34_received_investigational_therapy.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.treatment_for_mds,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'yes',
      },
    ],
  },
  {
    q: 'Q15',
    id: ID.supportive_care_type,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.mds.35_supportive_care_type.title',
    subtitle: 'questionnaire.questions.mds.35_supportive_care_type.subtitle',
    info: {
      title: 'questionnaire.questions.mds.35_supportive_care_type.info.title',
      content: 'questionnaire.questions.mds.35_supportive_care_type.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.mds.35_supportive_care_type.options.none',
        value: 'none',
        exclusive: true,
      },
      {
        label: 'questionnaire.questions.mds.35_supportive_care_type.options.erythropoietin',
        value: 'erythropoietin',
      },
      {
        label: 'questionnaire.questions.mds.35_supportive_care_type.options.transfusions',
        value: 'transfusions',
      },
      {
        label: 'questionnaire.questions.mds.35_supportive_care_type.options.deferoxamine_deferasirox',
        value: 'deferoxamine_deferasirox',
      },
      {
        label: 'questionnaire.questions.mds.35_supportive_care_type.options.platelet_transfusion',
        value: 'platelet_transfusion',
      },
      {
        label: 'questionnaire.questions.mds.35_supportive_care_type.options.antifibrolytic_agent',
        value: 'antifibrolytic_agent',
      },
      {
        label: 'questionnaire.questions.mds.35_supportive_care_type.options.other',
        value: 'other',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q15a',
    id: ID.number_of_blood_transfusions,
    type: NUMBER_INPUT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.mds.36_number_of_blood_transfusions.title',

    info: {
      title: '',
      content: '',
    },
    options: [],
    conditions: [
      {
        ifId: ID.supportive_care_type,
        type: EQUAL_TO,
        ifValue: 'transfusions',
      },
    ],
    numberType: numberType.intWithoutUnit,
  },
  {
    q: 'Q15b',
    id: ID.last_blood_transfusion_received_date,
    type: MONTH_PICKER,
    section: SECTIONS.TREATMENT,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.mds.37_last_blood_transfusion_received_date.title',

    info: {
      title: '',
      content: '',
    },
    options: [],
    conditions: [
      {
        ifId: ID.supportive_care_type,
        type: EQUAL_TO,
        ifValue: 'transfusions',
      },
    ],
    dateType: {
      type: dateType.stopTreatment,
    },
  },
  {
    q: 'Q16',
    id: ID.current_treatment_systemic_steroids,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.mds.38_current_treatment_systemic_steroids.title',
    subtitle: 'questionnaire.questions.mds.38_current_treatment_systemic_steroids.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.mds.38_current_treatment_systemic_steroids.options.none',
        value: 'none',
      },
      {
        label: 'questionnaire.questions.mds.38_current_treatment_systemic_steroids.options.under_10_mg_daily',
        value: 'under_10_mg_daily',
      },
      {
        label: 'questionnaire.questions.mds.38_current_treatment_systemic_steroids.options.over_10_mg_daily',
        value: 'over_10_mg_daily',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q16a',
    id: ID.systemic_steroids_treatment_end_date,
    type: MONTH_PICKER,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.mds.39_systemic_steroids_treatment_end_date.title',

    info: {
      title: '',
      content: '',
    },
    options: [],
    conditions: [
      {
        ifId: ID.current_treatment_systemic_steroids,
        type: CONTAIN_ONE_OF,
        ifValue: ['under_10_mg_daily', 'over_10_mg_daily'],
      },
    ],
    dateType: {
      type: dateType.stopTreatment,
    },
  },
  // Health part
  // TODO: check changes
  {
    q: 'Q17',
    id: ID.ecog,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.mds.40_ecog.title',
    info: {
      title: 'questionnaire.questions.mds.40_ecog.info.title',
      content: 'questionnaire.questions.mds.40_ecog.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.mds.40_ecog.options.0',
        value: '0',
      },
      {
        label: 'questionnaire.questions.mds.40_ecog.options.1',
        value: '1',
      },
      {
        label: 'questionnaire.questions.mds.40_ecog.options.2',
        value: '2',
      },
      {
        label: 'questionnaire.questions.mds.40_ecog.options.3',
        value: '3',
      },
      {
        label: 'questionnaire.questions.mds.40_ecog.options.4',
        value: '4',
      },
    ],
    conditions: [],
    isRequired: true,
  },
  {
    q: 'Q45',
    id: ID.gvhd,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.mds.41_gvhd.title',

    info: {
      title: 'questionnaire.questions.mds.41_gvhd.info.title',
      content: 'questionnaire.questions.mds.41_gvhd.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.mds.41_gvhd.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.mds.41_gvhd.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.mds.41_gvhd.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: ID.received_allogenic_transplant,
        type: EQUAL_TO,
        ifValue: 'yes',
      },
    ],
  },
  {
    q: 'Q46',
    id: ID.other_medical_condition,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.mds.42_other_medical_condition.title',
    subtitle: 'questionnaire.questions.mds.42_other_medical_condition.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.mds.42_other_medical_condition.options.none',
        value: 'none',
        exclusive: true,
      },
      {
        label: 'questionnaire.questions.mds.42_other_medical_condition.options.liver',
        value: 'liver',
      },
      {
        label: 'questionnaire.questions.mds.42_other_medical_condition.options.heart',
        value: 'heart',
      },
      {
        label: 'questionnaire.questions.mds.42_other_medical_condition.options.kidney',
        value: 'kidney',
      },
      {
        label: 'questionnaire.questions.mds.42_other_medical_condition.options.autoimmune',
        value: 'autoimmune',
      },
      {
        label: 'questionnaire.questions.mds.42_other_medical_condition.options.mental',
        value: 'mental',
      },
      {
        label: 'questionnaire.questions.mds.42_other_medical_condition.options.allergies',
        value: 'allergies',
      },
      {
        label: 'questionnaire.questions.mds.42_other_medical_condition.options.chronic_infection_or_inflammation',
        value: 'chronic_infection_or_inflammation',
      },
      {
        label: 'questionnaire.questions.mds.42_other_medical_condition.options.gastrointestinal',
        value: 'gastrointestinal',
      },
      {
        label: 'questionnaire.questions.mds.42_other_medical_condition.options.other_cancer_type',
        value: 'other_cancer_type',
      },
      {
        label: 'questionnaire.questions.mds.42_other_medical_condition.options.anemia',
        value: 'anemia',
      },
      {
        label: 'questionnaire.questions.mds.42_other_medical_condition.options.thyroid',
        value: 'thyroid',
      },
      {
        label: 'questionnaire.questions.mds.42_other_medical_condition.options.hypertension',
        value: 'hypertension',
      },
      {
        label: 'questionnaire.questions.mds.42_other_medical_condition.options.epilepsy',
        value: 'epilepsy',
      },
      {
        label: 'questionnaire.questions.mds.42_other_medical_condition.options.diabetes',
        value: 'diabetes',
      },
      {
        label: 'questionnaire.questions.mds.42_other_medical_condition.options.organ_transplant',
        value: 'organ_transplant',
      },
      {
        label: 'questionnaire.questions.mds.42_other_medical_condition.options.extreme_obesity',
        value: 'extreme_obesity',
      },
      {
        label: 'questionnaire.questions.mds.42_other_medical_condition.options.stroke',
        value: 'stroke',
      },
      {
        label: 'questionnaire.questions.mds.42_other_medical_condition.options.lung',
        value: 'lung',
      },
      {
        label: 'questionnaire.questions.mds.42_other_medical_condition.options.neuropathy',
        value: 'neuropathy',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q48',
    id: ID.omc_liver,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.mds.43_omc_liver.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.mds.43_omc_liver.options.elevated_alt_ast',
        value: 'elevated_alt_ast',
      },
      {
        label: 'questionnaire.questions.mds.43_omc_liver.options.elevated_bilirubin',
        value: 'elevated_bilirubin',
      },
      {
        label: 'questionnaire.questions.mds.43_omc_liver.options.cirrhosis',
        value: 'cirrhosis',
      },
      {
        label: 'questionnaire.questions.mds.43_omc_liver.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['liver'],
      },
    ],
  },
  {
    q: 'Q49',
    id: ID.omc_heart,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.mds.44_omc_heart.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.mds.44_omc_heart.options.class_at_least_nyha_ii',
        value: 'class_at_least_nyha_ii',
      },
      {
        label: 'questionnaire.questions.mds.44_omc_heart.options.condition_at_least_grade_2',
        value: 'condition_at_least_grade_2',
      },
      {
        label: 'questionnaire.questions.mds.44_omc_heart.options.cardiac_ventricular_arrhythmia',
        value: 'cardiac_ventricular_arrhythmia',
      },
      {
        label: 'questionnaire.questions.mds.44_omc_heart.options.heart_failure',
        value: 'heart_failure',
      },
      {
        label: 'questionnaire.questions.mds.44_omc_heart.options.lvef_under_50_percent',
        value: 'lvef_under_50_percent',
      },
      {
        label: 'questionnaire.questions.mds.44_omc_heart.options.qtcf_abnormal',
        value: 'qtcf_abnormal',
      },
      {
        label: 'questionnaire.questions.mds.44_omc_heart.options.angina',
        value: 'angina',
      },
      {
        label: 'questionnaire.questions.mds.44_omc_heart.options.myocardial_infarction',
        value: 'myocardial_infarction',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['heart'],
      },
    ],
  },
  {
    q: 'Q50',
    id: ID.omc_stroke_date,
    type: MONTH_PICKER,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.mds.45_omc_stroke_date.title',
    info: {
      title: '',
      content: '',
    },
    options: [],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['stroke'],
      },
    ],
    dateType: {
      type: dateType.stopTreatment,
    },
  },
  {
    q: 'Q51',
    id: ID.omc_kidney,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.mds.46_omc_kidney.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.mds.46_omc_kidney.options.low_glomerular_filtration_rate',
        value: 'low_glomerular_filtration_rate',
      },
      {
        label: 'questionnaire.questions.mds.46_omc_kidney.options.dialysis',
        value: 'dialysis',
      },
      {
        label: 'questionnaire.questions.mds.46_omc_kidney.options.kidney_stones',
        value: 'kidney_stones',
      },
      {
        label: 'questionnaire.questions.mds.46_omc_kidney.options.creatinine_high',
        value: 'creatinine_high',
      },
      {
        label: 'questionnaire.questions.mds.46_omc_kidney.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['kidney'],
      },
    ],
  },
  {
    q: 'Q52',
    id: ID.omc_autoimmune,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.mds.47_omc_autoimmune.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.mds.47_omc_autoimmune.options.congenital_or_acquired_immunosuppression',
        value: 'congenital_or_acquired_immunosuppression',
      },
      {
        label: 'questionnaire.questions.mds.47_omc_autoimmune.options.hepatitis',
        value: 'hepatitis',
      },
      {
        label: 'questionnaire.questions.mds.47_omc_autoimmune.options.granulomatous_disease',
        value: 'granulomatous_disease',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['autoimmune'],
      },
    ],
  },
  {
    q: 'Q53',
    id: ID.omc_mental,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.mds.48_omc_mental.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.mds.48_omc_mental.options.dementia',
        value: 'dementia',
      },
      {
        label: 'questionnaire.questions.mds.48_omc_mental.options.neuropsychyatric',
        value: 'neuropsychyatric',
      },
      {
        label: 'questionnaire.questions.mds.48_omc_mental.options.depression',
        value: 'depression',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['mental'],
      },
    ],
  },
  {
    q: 'Q54',
    id: ID.omc_allergy,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.mds.49_omc_allergy.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.mds.49_omc_allergy.options.interferon',
        value: 'interferon',
      },
      {
        label: 'questionnaire.questions.mds.49_omc_allergy.options.metformin',
        value: 'metformin',
      },
      {
        label: 'questionnaire.questions.mds.49_omc_allergy.options.vitd',
        value: 'vitd',
      },
      {
        label: 'questionnaire.questions.mds.49_omc_allergy.options.penicillin',
        value: 'penicillin',
      },
      {
        label: 'questionnaire.questions.mds.49_omc_allergy.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['allergies'],
      },
    ],
  },
  {
    q: 'Q55',
    id: ID.omc_chronic_infection_inflammation,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.mds.50_omc_chronic_infection_inflammation.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.mds.50_omc_chronic_infection_inflammation.options.hepatitis',
        value: 'hepatitis',
      },
      {
        label: 'questionnaire.questions.mds.50_omc_chronic_infection_inflammation.options.hiv',
        value: 'hiv',
      },
      {
        label: 'questionnaire.questions.mds.50_omc_chronic_infection_inflammation.options.other',
        value: 'other',
      },
      {
        label: 'questionnaire.questions.mds.50_omc_chronic_infection_inflammation.options.elevated_wbc',
        value: 'elevated_wbc',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['chronic_infection_or_inflammation'],
      },
    ],
  },
  {
    q: 'Q58',
    id: ID.omc_gastrointestinal,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.mds.51_omc_gastrointestinal.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.mds.51_omc_gastrointestinal.options.gastrointestinal_bleeding',
        value: 'gastrointestinal_bleeding',
      },
      {
        label: 'questionnaire.questions.mds.51_omc_gastrointestinal.options.crohns',
        value: 'crohns',
      },
      {
        label: 'questionnaire.questions.mds.51_omc_gastrointestinal.options.colitis',
        value: 'colitis',
      },
      {
        label: 'questionnaire.questions.mds.51_omc_gastrointestinal.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['gastrointestinal'],
      },
    ],
  },
  {
    q: 'Q59',
    id: ID.omc_lung_disease,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.mds.52_omc_lung_disease.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.mds.52_omc_lung_disease.options.interstitial_lung_disease',
        value: 'interstitial_lung_disease',
      },
      {
        label: 'questionnaire.questions.mds.52_omc_lung_disease.options.pneumonitis',
        value: 'pneumonitis',
      },
      {
        label: 'questionnaire.questions.mds.52_omc_lung_disease.options.dyspnea',
        value: 'dyspnea',
      },
      {
        label: 'questionnaire.questions.mds.52_omc_lung_disease.options.tuberculosis',
        value: 'tuberculosis',
      },
      {
        label: 'questionnaire.questions.mds.52_omc_lung_disease.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['lung'],
      },
    ],
  },
  {
    q: 'Q56',
    id: ID.omc_abnormal_blood_results,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.mds.53_omc_abnormal_blood_results.title',
    subtitle: 'questionnaire.questions.mds.53_omc_abnormal_blood_results.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.mds.53_omc_abnormal_blood_results.options.none',
        value: 'none',
        exclusive: true,
      },
      {
        label: 'questionnaire.questions.mds.53_omc_abnormal_blood_results.options.low_wbc',
        value: 'low_wbc',
      },
      {
        label: 'questionnaire.questions.mds.53_omc_abnormal_blood_results.options.low_hemoglobin',
        value: 'low_hemoglobin',
      },
      {
        label: 'questionnaire.questions.mds.53_omc_abnormal_blood_results.options.low_neutrophil_count',
        value: 'low_neutrophil_count',
      },
      {
        label: 'questionnaire.questions.mds.53_omc_abnormal_blood_results.options.low_platelet_count',
        value: 'low_platelet_count',
      },
      {
        label: 'questionnaire.questions.mds.53_omc_abnormal_blood_results.options.low_potassium',
        value: 'low_potassium',
      },
      {
        label: 'questionnaire.questions.mds.53_omc_abnormal_blood_results.options.low_magnesium',
        value: 'low_magnesium',
      },
      {
        label: 'questionnaire.questions.mds.53_omc_abnormal_blood_results.options.clotting_coagulation_platelets',
        value: 'clotting_coagulation_platelets',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q47',
    id: ID.other_medications,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.mds.54_other_medications.title',
    info: {
      title: 'questionnaire.questions.mds.54_other_medications.info.title',
      content: 'questionnaire.questions.mds.54_other_medications.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.mds.54_other_medications.options.cyp3a4_inhibitors',
        value: 'cyp3a4_inhibitors',
      },
      {
        label: 'questionnaire.questions.mds.54_other_medications.options.cyp3a4_inducers',
        value: 'cyp3a4_inducers',
      },
      {
        label: 'questionnaire.questions.mds.54_other_medications.options.immunosuppressive_medications',
        value: 'immunosuppressive_medications',
      },
      {
        label: 'questionnaire.questions.mds.54_other_medications.options.anticonvulsants',
        value: 'anticonvulsants',
      },
      {
        label: 'questionnaire.questions.mds.54_other_medications.options.none',
        value: 'none',
        exclusive: true,
      },
    ],
    conditions: [],
  },
  initialDiagnosisDate('Q3'),
  userType,
  {
    q: 'Q1',
    id: ID.dob,
    type: MONTH_PICKER,
    section: SECTIONS.ABOUT_ME,
    bgIcon: i.user,
    order: 0,
    title: 'questionnaire.questions.mds.0_dob.title',
    info: {
      title: '',
      content: '',
    },
    options: [],
    conditions: [],
    dateType: {
      type: dateType.dob,
    },
  },
  {
    q: 'Q2',
    id: ID.gender,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.ABOUT_ME,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.mds.1_gender.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.mds.1_gender.options.female',
        value: 'female',
      },
      {
        label: 'questionnaire.questions.mds.1_gender.options.male',
        value: 'male',
      },
      {
        label: 'questionnaire.questions.global.gender.options.other',
        value: 'other',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q3',
    id: ID.is_pregnant,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.ABOUT_ME,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.mds.55_is_pregnant.title',
    info: {
      title: 'questionnaire.questions.mds.55_is_pregnant.info.title',
      content: 'questionnaire.questions.mds.55_is_pregnant.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.mds.55_is_pregnant.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.mds.55_is_pregnant.options.no',
        value: 'no',
      },
    ],
    conditions: [
      {
        ifId: ID.gender,
        type: EQUAL_TO,
        ifValue: 'female',
      },
      {
        type: AGE_LESS_THAN,
        ifValue: 50,
      },
    ],
  },
  {
    q: 'Q4',
    id: ID.is_breastfeeding,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.ABOUT_ME,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.mds.56_is_breastfeeding.title',
    info: {
      title: 'questionnaire.questions.mds.56_is_breastfeeding.info.title',
      content: 'questionnaire.questions.mds.56_is_breastfeeding.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.mds.56_is_breastfeeding.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.mds.56_is_breastfeeding.options.no',
        value: 'no',
      },
    ],
    conditions: [
      {
        ifId: ID.gender,
        type: EQUAL_TO,
        ifValue: 'female',
      },
      {
        type: AGE_LESS_THAN,
        ifValue: 50,
      },
    ],
  },
  race,
  haveInsurance,
  insurance,
  preferredLocation,
  travelLimit,
];

export default Questions;
