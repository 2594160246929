import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';

import ServerDown from 'components/ServerDown';
import clearAuthDataInLocalStorage from 'functions/clearAuthDataInLocalStorage';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });

    clearAuthDataInLocalStorage();

    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.error) {
      return <ServerDown />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
