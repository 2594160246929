import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from 'new-ui/constants';
import './LinkButton.css';

const LinkButton = (props) => {
  const {
    action, text, className, color, title, href,
  } = props;
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      style={{
        color: color || COLORS.primaryButton,
      }}
      className={`link-button ${className || ''}`}
      onClick={action}
      href={href}
      target={href ? '_blank' : ''}
      rel="noreferrer"
    >
      {title || text}
    </a>
  );
};

LinkButton.propTypes = {
  action: PropTypes.func,
  className: PropTypes.string,
  color: PropTypes.string,
  text: PropTypes.any,
  title: PropTypes.string,
  href: PropTypes.string,
};

export default LinkButton;
