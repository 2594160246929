/**
 * Combines string URL with query parameters
 *
 * @param {string} url string URL without query parameters
 * @param {object} params key-pair values which you need to build as query parameters
 * @returns {string} url with query parameters
 */
export default function getUrlWithQueryString(url, params = {}) {
  params = Object.keys(params).reduce((acc, key) => Object.assign(acc, { [key]: encodeURI(params[key]) }), {});

  const query = new URLSearchParams(params);

  try {
    url = new URL(url);
    url.search = query;
  } catch (ex) {
    url = `${url}?${query.toString()}`;
  }

  return url.toString();
}
