import { PRISTINE } from 'utils/constants/fileStatuses';

export const selectOptionsFromServer = (state) => (state.questionnaire?.optionsFromServer);

export const selectQuestionnaireFilesToUpload = (state) => {
  const {
    questionnaire: {
      files: filesByQuestionIds,
    } = {},
  } = state;

  const filesToUpload = [];
  Object.keys(filesByQuestionIds).forEach((questionId) => {
    filesByQuestionIds[questionId].forEach((file) => {
      if (file.status === PRISTINE) {
        filesToUpload.push({ ...file, questionId });
      }
    });
  });
  return filesToUpload;
};
