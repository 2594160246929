import React from 'react';
import './style.css';
import { Button } from '@material-ui/core';
import { searchActive } from 'utils/static/icons';
import app from 'new-ui/app';
import { useTranslation } from 'utils/modifiedTranslation';

const FinanceCategoryCards = ({
  options, onSeeAllClick, onCheckEligibilityClick, GA,
}) => {
  const { t } = useTranslation();
  return (
    <div className="finance-cards">
      {options.map((option, index) => (
        <div className="finance-card" key={index}>
          <div className="icon"><img src={option.icon} alt="icon" /></div>
          <div>
            <div className="title">{option.label}</div>
            <div className="count">
              <span>{option.program_count}</span>
              {' '}
              {t('general.programs')}
            </div>
          </div>
          <div className="description">{option.description}</div>
          <div className="actions">
            <Button
              onClick={() => {
                app.sendGoogleAnalyticsEvent(GA.category, GA.events.seeAllCategory, { name: 'Financial support category see all clicked', metaDate: option.label });

                onSeeAllClick(option);
              }}
              className="see-all-btn"
            >
              <span className="btn-title">{t('general.see_all')}</span>
            </Button>
            <Button
              onClick={() => {
                app.sendGoogleAnalyticsEvent(GA.category, GA.events.checkEligibility, { name: 'Financial support category check eligibility clicked', metaDate: option.label });
                onCheckEligibilityClick(option);
              }}
              className="check-btn"
            >
              <img className="btn-icon" src={searchActive} alt="plus circle" />
              <span className="btn-title">{t('general.check_availability')}</span>
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FinanceCategoryCards;
