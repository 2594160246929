import React, { useEffect, useState } from 'react';
import './SearchForm.css';
import app from 'new-ui/app';
import {
  createEmptyUser, getNumberType, isEmptyObject, randomString,
} from 'new-ui/functions';
import Intake from 'new-ui/Components/Intake';
import { INTAKE_BIOMARKERS_QUESTION, INTAKE_LOCATION_QUESTION } from 'new-ui/Components/Intake/questions';
import { INDICATIONS, SUBTYPE_FIELD_BY_CONDITION } from 'new-ui/constants';
import HCPSelect from 'new-ui/Components/HCP/Select';
import { useTranslation } from 'utils/modifiedTranslation';
import useForceUpdate from 'use-force-update';
import { render } from 'react-dom';
import { QUESTION_TYPES } from 'new-ui/Components/QuestionRenderer';
import SearchIcon from 'new-ui/assets/icons/search_text.svg';
import { getSearchParamsApi } from 'new-ui/api';
import SwitchBox from 'new-ui/Components/SwitchBox';
import { treatmentForIndicationQuestionIds } from 'new-ui/api/data/cancerTypes';
import NotificationIcon from 'new-ui/assets/icons/notification.svg';
import LocationInput from '../Form/LocationInput';
import Button from '../Button';
import Checkbox from '../Form/Checkbox';
import Preloader from '../Preloader';
import InfoNote from 'new-ui/Components/InfoNote';
import { BoxTreatmentsQuestion } from './BoxTreatments';
import { getQstack } from '../Intake/functions';

const hcpQuestions = {
  breast_2: [
    'disease_status',
    'molecular_subtype',
    'her2_levels',
    'patient_stage',
    'treatment_for_advanced_metastatic_disease',
    'treatment_for_breast_cancer',
    'received_fam_trastuzumab',
    'treatments',
    'biomarkers',
  ],
  lung: [
    'disease_status',
    'type_of_lung_cancer',
    'type_of_nsclc',
    'current_stage',
    'has_biopsy',
    'treatment_for_advanced_metastatic_disease',
    'treatments',
    'biomarkers',
    'braf_mutation',
    'egft_mut',
    'kras_mut',
    'erbb2_her2_mut',
    'met_mut',
    'pd_l1_carrier_status',
    'pd_l1_expression',
  ],
  crc_2: [
    'disease_status',
    'current_stage',
    'treatment_for_advanced_metastatic_disease',
    'treatment_for_crc_cancer',
    'treatments',
    'biomarkers',
    'kras_mut',
    'msi',
    'kras_mut',
  ],
  prostate: [
    'disease_status',
    'crpc',
    'current_stage',
    'gleason_score',
    'psa_level',
    'had_procedure_for_prostate_cancer',
    // 'procedures',
    'treatment_for_prostate_cancer',
    'treatment_for_advanced_metastatic_disease',
    'treatments',
    'biomarkers',
  ],
  melanoma_2: [
    'disease_status',
    'melanoma_subtype',
    'current_stage',
    'treatment_for_advanced_metastatic_disease',
    'treatment_for_melanoma',
    'treatments',
    'biomarkers',
    'braf_mutation',
  ],
  bladder_2: [
    'disease_status',
    'patient_phase',
    'patient_grade',
    'had_surgery_for_bladder_cancer',
    // 'procedures',
    'treatment_for_bladder_cancer',
    'treatments',
    'biomarkers',
  ],
  rcc_kidney: [
    'disease_status',
    'type_of_rcc',
    'patient_stage',
    'had_procedure_for_kidney_cancer',
    // 'procedures',
    'treatment_for_kidney_cancer',
    'treatment_for_advanced_metastatic_disease',
    'treatments',
    'biomarkers',
  ],
  gi_tract: [
    'disease_status',
    'type_of_gi',
    'type_of_esophageal_gej',
    'current_stage',
    'treatment_for_advanced_metastatic_disease',
    'treatment_for_gi_tract',
    'treatments',
    'biomarkers',
  ],
  mesothelioma: [
    'disease_status',
    'type_of_mesothelioma_cancer',
    'histological_type_of_mesothelioma',
    'patient_stage',
    'disease_location',
    'treatment_for_advanced_metastatic_disease',
    'treatment_for_mesothelioma_cancer',
    'treatments',
    'biomarkers',
  ],
  // general: [
  //   'disease_status',
  //   'type_of_pancreatic',
  //   'patient_stage',
  //   'treatment_for_advanced_metastatic_disease',
  //   'treatment_for_cancer',
  //   'treatments',
  //   'biomarkers',
  // ],
  ovarian: [
    'disease_status',
    'type_of_ovarian_cancer',
    'current_stage',
    'treatment_for_ovarian_cancer',
    'treatment_for_advanced_metastatic_disease',
    'treatments',
    'biomarkers',
    'msi',
  ],
  uterine: [
    'disease_status',
    'type_of_uterine_cancer',
    'current_stage',
    'treatment_for_uterine_cancer',
    'treatments',
    'had_procedure_for_uterine_cancer',
    // 'procedures',
    'biomarkers',
    'msi',
  ],
  hnscc: [
    'disease_status',
    'type_of_hnscc',
    'current_stage',
    'treatment_for_hnscc',
    'treatment_for_advanced_metastatic_disease',
    'treatments',
    'had_procedure',
    // 'procedures',
    'biomarkers',
  ],
  brain: [
    'disease_status',
    'brain_cancer_type',
    'brain_radiation',
    'treatment_for_brain_cancer',
    'treatments',
    'biomarkers',
  ],
  mds: [
    'disease_status',
    'risk_group',
    'intermediate_risk_subgroup',
    'percentage_of_bm_blasts',
    'treatment_for_mds',
    'treatment_nontransplant_candidate',
    'received_allogenic_transplant',
    'allogenic_transplant_date',
    'allogenic_transplant_outcome',
    'treatment_after_transplant_relapse',
    'treatment_after_transplant_relapse_outcome',
    'biomarkers',
  ],
  mpn: [
    'disease_status',
    'type_of_mpn',
    'risk_group',
    'intermediate_risk_subgroup',
    'splenomegaly',
    'platelet_count',
    'treatment_for_mpn',
    'treatments',
    'received_allogenic_transplant',
    'biomarkers',
  ],
  mm: [
    'disease_status',
    'has_mprotein',
    'has_serum_flc',
    'chromosomal_alterions',
    'received_transplant',
    'treatment_for_mm',
    'treatments',
  ],
  nhl: [
    'disease_status',
    'type_of_lymphoma',
    'type_of_bcell_lymphoma',
    'type_of_marginal_cell_lymphoma',
    'type_of_aids_related_lymphoma',
    'current_stage',
    'disease_location',
    'current_stage_follicular_lymphoma',
    'treatment_for_lymphoma',
    'treatments',
    'procedure_candidate',
    'had_procedure_for_lymphoma',
    'procedures_received',
    'biomarkers',
  ],
  cll: [
    'disease_status',
    'type_of_disease',
    'cll_characteristics',
    'platelet_count',
    'lymphocyte_count',
    'treatment_for_cll',
    'treatments',
    'received_transplant',
    'biomarkers',
  ],
  aml: [
    'type_of_aml',
    'disease_status',
    'risk_group',
    'received_transplant',
    'treatment_for_aml',
    'treatments',
    'biomarkers',
  ],
  hcc: [
    'disease_status',
    'type_of_liver',
    'current_stage',
    'number_lesions',
    'hcc_related_health_problems',
    'treatment_for_advanced_metastatic_disease',
    'treatment_for_liver',
    'treatments',
    'biomarkers',
  ],
};

const _all = ['ecog', 'location'];

Object.keys(hcpQuestions).forEach((i)=>{
  hcpQuestions[i] = hcpQuestions[i].concat([..._all]);
});

window._hcpQuestions = hcpQuestions;

export const hcpGeneralQuestions = {
  general: [
    'hcp_type',
    'hcp_subtype',
    'hcp_stage',
    'biomarkers',
    'location',
    'treatments',
  ],
  ...hcpQuestions,
};

window.hcpGeneralQuestions = hcpGeneralQuestions;

export const hcpOtherQuestions = {
  general: [],
  breast_2: [],
  lung: [],
  crc_2: [],
  prostate: [],
  melanoma_2: [],
  bladder_2: [],
  rcc_kidney: [],
  gi_tract: [],
  mesothelioma: [],
  ovarian: [],
  uterine: [],
  hnscc: [],
  brain: [],
  mds: [],
  mpn: [],
  mm: [],
  nhl: [],
  cll: [],
  aml: [],
  hcc: [],
};

let user; let indications; let searchParamsData;

export const GENERAL_CONDITION = 'general';

window._hcpUser = user;

export const HCP_TREATMENTS_QUESTION = {
  id: 'treatments',
  type: 'HCP_TREATMENTS',
  isQuestionAnswered: (user)=>{
    return !isEmptyObject(user?.condition_profile?.treatments);
  },
  getQuestionValue: (user)=>{
    return ``;
  },
  setQuestionValue: ()=>{
    return; // value on the user is set on the renderer
  },
};

const HCP_MOCK = 'HCP_MOCK';

export const HCP_TYPE_QUESTION = {
  id: 'hcp_type',
  type: HCP_MOCK,
  title: 'general.type',
  isQuestionAnswered: (user)=>{ return true; },
  getQuestionValue: (user)=>{ return ``; },
  setQuestionValue: ()=>{ return; },
};

export const HCP_SUBTYPE_QUESTION = {
  id: 'hcp_type',
  type: HCP_MOCK,
  title: 'general.subtype',
  isQuestionAnswered: (user)=>{ return true; },
  getQuestionValue: (user)=>{ return ``; },
  setQuestionValue: ()=>{ return; },
};

export const HCP_STAGE_QUESTION = {
  id: 'hcp_type',
  type: HCP_MOCK,
  title: 'general.stage',
  isQuestionAnswered: (user)=>{ return true; },
  getQuestionValue: (user)=>{ return ``; },
  setQuestionValue: ()=>{ return; },
};

const ALL_GENERAL_QUESTIONS = [
  INTAKE_LOCATION_QUESTION,
  INTAKE_BIOMARKERS_QUESTION,
  HCP_TYPE_QUESTION,
  HCP_SUBTYPE_QUESTION,
  HCP_STAGE_QUESTION,
];

export const modifyString = (str = '')=>{
  return str.replace('questionnaire.', 'q_hcp.');
};

const mockQuestion = (props)=>{
  const { q } = props;
  return (
    <div>
      <div className="hcp-input-title">{window.t(q.title)}</div>
      <div>
        <HCPSelect
          user={user}
          disabled
          placeholder={window.t(q.placeholder)}
          showAutofill={false}
          options={[]}
          initValue={null}
        />
      </div>
    </div>
  );
};

const BoxBiomarkersQuestion = (props)=>{
  const { onselect } = props;
  const { t } = useTranslation();
  return (
    <div className="box-question-biomarkers">
      <div className="hcp-input-title">{t('general.biomarkers')}</div>
      <CheckEligibility />
      <div>
        <HCPSelect
          user={user}
          showAutofill
          renderOnSelect={false}
          useTags
          isMulti
          onselect={(v)=>{
            const fetch = async ()=>{
              user.condition_profile.biomarkers = v || [];
              if(user.personal.condition != GENERAL_CONDITION){
                const questions = await app.getQuestions(user.personal.condition, true);
                const qstack = getQstack(user, questions);
                user.condition_profile.qstack = qstack;
              }
              onselect(user.condition_profile.biomarkers);
              if(window._accordion) window._accordion.render();
            }
            fetch();
          }}
          options={app.biomarkers.map((b)=>{
            return {
              title: t(b.name),
              value: b.code,
            };
          })}
          initValue={user.condition_profile.biomarkers || ['none']}
        />
      </div>
    </div>
  );
};

// const TYPE_SWITCHER = {
//   [QUESTION_TYPES.SINGLE_OPTION_SELECT]: 'SINGLE_OPTION_SELECT_BOX',
// };

const BoxLocationQuestion = (props)=>{
  const { user, onselect } = props;
  const { t } = useTranslation();
  return (
    <div className="box-question-location">
      <div className="hcp-input-title">{t('general.treatment_location')}</div>
      <div>
        <LocationInput
          value={user?.info?.country}
          onChange={(country)=>{
            user.info.country = country;
            onselect(country);
          }}
        />
      </div>
    </div>
  );
};

export const renderSearchAccordion = ()=>{
  if (window._accordions?.search?.render) window._accordions.search.render();
};

const CheckEligibility = ()=>{
  return (
    <div className="intake-question-eligibility">
      <img src={NotificationIcon} alt="" />
      {' '}
      {window.t('general.missing_eligibility_critiria')}
    </div>
  );
};

const BoxSelectQuestion = (props)=>{
  const { q, user, onselect } = props;
  const { t } = useTranslation();
  let isMulti = false;

  if (q.type === QUESTION_TYPES.MULTIPLE_OPTION_SELECT) isMulti = true;

  return (
    <div className="hcp-input">
      <div className="hcp-input-title">
        {
          t(q.title.replace('questionnaire.', 'q_hcp.'))
        }
      </div>
      <CheckEligibility />
      <HCPSelect
        q={q}
        user={user}
        onselect={onselect}
        initValue={user?.condition_profile[q.id]}
        placeholder={t(q.title.replace('questionnaire.', 'q_hcp.'))}
        options={q?.options ? q.options.map((o)=>{
          o.title = t(o.label.replace('questionnaire.', 'q_hcp.'));
          return o;
        }) : []}
        isMulti={isMulti}
        useTags={false}
      />
    </div>
  );
};

const BoxNumberQuestion = (props)=>{
  const { q, user, onselect } = props;
  const [value, setValue] = useState(q.getQuestionValue ? q.getQuestionValue(user) : (user?.condition_profile[q.id] || ''));
  const { t } = useTranslation();
  const numberType = getNumberType(q.numberType);
  return (
    <div className="hcp-input">
      <div className="hcp-input-title space-between">
        <div>
          {
            t(q.title.replace('questionnaire.', 'q_hcp.'))
          }
        </div>
        <div className="hcp-number-unit">{numberType.unit}</div>
      </div>
      <CheckEligibility />
      <input
        className="hcp-number-input"
        type="number"
        step={numberType.step}
        defaultValue={value}
        onChange={(ev)=>{
          // user.condition_profile[q.id] = ev.target.value;
          onselect(ev.target.value);
        }}
      />
    </div>
  );
};

export const createGeneralUser = async (existingUser)=>{
  const user = existingUser || await createEmptyUser(INDICATIONS.AML);
  user.personal.condition = GENERAL_CONDITION;
  user.info.condition = GENERAL_CONDITION;
  user.condition_profile.biomarkers = ['none'];
  return user;
};

let initWithUser;

const SearchForm = (props) => {
  const {
    user: _user,
    onChange,
    onLoad,
    onBeforeChange,
    initEnabled,
    initDisabled,
    searchHeader,
    onBeforeRender,
    onAccordionRender,
  } = props;

  initWithUser = !!_user;

  const [init, setInit] = useState(false);
  const { t } = useTranslation();
  const update = useForceUpdate();
  window._update = update;

  useEffect(()=>{
    user = _user;
    const fetch = async ()=>{
      console.log('SearchForm :: main fetch');
      indications = indications || await app.getIndications();
      searchParamsData = app?.cache?.searchParamsData || (await getSearchParamsApi());
      app.cache.searchParamsData = searchParamsData;
      if (!user) user = await createGeneralUser();
      setInit(true);
    };
    fetch();
  }, []);

  useEffect(()=>{
    if (!init) return;
    console.log('init true', user, indications);
    if (onLoad) onLoad(user);
    const fetch = async ()=>{
      let allQuestions;
      const indication = user?.personal?.condition;

      if (indication && indication !== GENERAL_CONDITION) {
        allQuestions = await app.getQuestions(indication, true);
      } else {
        allQuestions = ALL_GENERAL_QUESTIONS;
      }

      setIntake({
        user,
        allQuestions,
      });
    };
    fetch();
  }, [init]);

  useEffect(()=>{
    if (!init || !_user) return;
    user = _user;
    console.log('reload user', user, indications);
    initWithUser = false;
    if (onLoad) onLoad(user);
    const fetch = async ()=>{
      let allQuestions;
      const indication = user?.personal?.condition;

      if (indication && indication !== GENERAL_CONDITION) {
        allQuestions = await app.getQuestions(indication, true);
      } else {
        allQuestions = ALL_GENERAL_QUESTIONS;
      }

      setIntake({
        user,
        allQuestions,
      });
    };
    fetch();
  }, [_user]);

  const setIntake = (data)=>{
    render(<div>
      { searchHeader || null }
      <div className="indication-selector">
        <div className="hcp-input-title">
          {t('general.condition')}
        </div>
        <HCPSelect
          key={randomString()}
          disabled={isDisabled()}
          options={getOptions()}
          className="hcp-select-indication"
          icon={SearchIcon}
          placeholder={t('hcp.indication_input_title')}
          hideOnSelect
          initValue={getIndicationValue(user)}
          initLabel={user.personal.condition_name}
          onselect={(value, label)=>{
            if (onBeforeChange) onBeforeChange();

            const labelData = searchParamsData.indicationLabelsData.find((l)=>l.label === label);
            // if(!labelData) return;

            localStorage.removeItem('last-hcp-label');
            localStorage.setItem('last-hcp-label', label);

            user.personal.condition = value;
            user.personal.condition_name = label;

            const fetch = async ()=>{
              const indication = user?.personal?.condition;
              if (!indication) {
                user = await createGeneralUser(user);
                setIntake({
                  user,
                  allQuestions: ALL_GENERAL_QUESTIONS,
                });
                onChange(user);
                return;
              }
              user = await createEmptyUser(indication);
              user.personal.condition_name = label;
              user.condition_profile.biomarkers = ['none'];

              debugger;

              const subtypeField = SUBTYPE_FIELD_BY_CONDITION[value];

              if(subtypeField && labelData.autoSelectType) {
                user.condition_profile[subtypeField] = labelData.autoSelectType;
              }

              if(subtypeField && labelData.subType) {
                user.condition_profile[subtypeField] = labelData.subType;
              }

              const $holder = document.querySelector('.intake-holder');
              render(<div className="intake-preloader"><Preloader inline isVisible /></div>, $holder);
              questions = await app.getQuestions(indication, true);
              // setQuestions(_questions);

              let questions;
              let allQuestions;

              if (indication) {
                questions = hcpGeneralQuestions[indication];
                allQuestions = await app.getQuestions(indication, true);
              } else {
                questions = hcpGeneralQuestions.general;
                allQuestions = ALL_GENERAL_QUESTIONS;
              }

              const data = {
                user,
                allQuestions: allQuestions.filter((q)=>{
                  return questions.includes(q.id);
                }),
              };
              console.log('SET DATA ', data);
              setIntake(data);
              onChange(user);
              // setUser(_user);
              // app.setShowPreloader(false);
            };

            fetch();
          }}
        />
      </div>
      <Intake
        id="search"
        onAccordionRender={onAccordionRender}
        user={data.user}
        flatMode
        preventNotSure
        expandAll
        key={randomString()}
        onBeforeRender={onBeforeRender}
        customData={
          getCustomData(data)
        }
        customOnSelect={async ()=>{
          onChange(user);
        }}
        customRenderers={
          {
            [QUESTION_TYPES.SINGLE_OPTION_SELECT]: BoxSelectQuestion,
            [QUESTION_TYPES.MULTIPLE_OPTION_SELECT]: BoxSelectQuestion,
            [QUESTION_TYPES.NUMBER_INPUT]: BoxNumberQuestion,
            [QUESTION_TYPES.TREATMENT_HISTORY]: BoxTreatmentsQuestion,
            [QUESTION_TYPES.COMPOSITE]: BoxTreatmentsQuestion,
            [QUESTION_TYPES.BIOMARKERS]: BoxBiomarkersQuestion,
            [QUESTION_TYPES.HCP_TREATMENTS]: BoxTreatmentsQuestion,
            [INTAKE_LOCATION_QUESTION.type]: BoxLocationQuestion,
            [HCP_MOCK]: mockQuestion,
          }
        }
      />
    </div>, document.querySelector('.intake-holder'));
  };

  const getOptions = ()=>{
    return searchParamsData.indicationLabelsData;
  };

  const getCustomData = (data)=>{
    console.log('getCustomData', data);
    let indication = data.user?.personal?.condition;
    if (indication === GENERAL_CONDITION) indication = null;
    const customData = {
      sections: ['general', 'other'],
      user: data.user,
      questions: [],
      allQuestions: data.allQuestions.map((q)=>{
        q.preventTitle = true;
        q.className = 'hcp-question';
        if (q.id === 'treatments') q.show = true;
        return q;
      }),
      questionsData: {
        general: hcpGeneralQuestions[indication || 'general'],
        other: hcpOtherQuestions[indication || 'general'],
      },
    };
    console.log('customData', customData, data);
    window._customData = customData;
    return customData;
  };

  const isGeneralCondition = ()=>{
    return user?.personal?.condition === GENERAL_CONDITION;
  };

  const getIndicationValue = (user)=>{
    const v = isGeneralCondition() ? null : user?.personal?.condition;
    console.log('getIndictionValue', v);
    return v;
  };

  const isDisabled = ()=>{
    if (initDisabled) return true;
    if (initEnabled) return false;
    return initWithUser;
  };

  return init ? (
    <>
      <div className="hcp-form">
        <>
          <div className="intake-holder" />
        </>
      </div>
    </>
  ) : null;
};

export default SearchForm;
