import app from 'new-ui/app';
import PropTypes from 'prop-types';
import { PopupButton, PopupContent, PopupTitle } from 'new-ui/Components/Popup';
import { LOCAL_STORAGE_KEYS, POPUP_TYPES } from 'new-ui/constants';
import React from 'react';
import './SendToDoctor.css';
import MatchesApi from 'components/Matches/api';
import { clearTrialsForPDF } from 'components/ShareDoctor/functions';
import { useTranslation } from 'utils/modifiedTranslation';

const SendToDoctor = ({ closePopup, trials = app.trials }) => {
  const { t } = useTranslation();
  const { email, firstName, doctorClinicFromList } = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_KEYS.DOCTOR_ADDED),
  ) ?? {};
  const api = new MatchesApi();

  const data = {
    doctorName: firstName || '',
    doctorEmail: email || '',
    clinicName: doctorClinicFromList || '',
    subjectLine: t('share_with_doctor.subject_line'),
    msg: `${t('share_with_doctor.message_text')}
${email}
    `,
  };

  const send = () => {
    app.setShowPreloader(true);
    api.sendDoctorEmail(data, clearTrialsForPDF(trials), { lang: 'en' }).then(() => {
      localStorage.setItem(LOCAL_STORAGE_KEYS.DOCTOR_SHARED, 1);
      setTimeout(() => {
        app.setShowPreloader(false);
        closePopup();
      }, 3000);
    });
  };
  return (
    <div className="add-doctor">
      <PopupTitle title="Add your doctor's details" />
      <PopupContent>
        <p className="to-and-from">
          To:
          {' '}
          {firstName}
        </p>
        <p className="to-and-from">
          Email:
          {' '}
          {email}
        </p>
        <p
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
          role="button"
          tabIndex={-1}
          onClick={() => app.setPopup(POPUP_TYPES.SHARE_WITH_DOCTOR)}
          onKeyDown={() => app.setPopup(POPUP_TYPES.SHARE_WITH_DOCTOR)}
          className="someone-else"
        >
          Send to someone else
        </p>
        <p className="to-and-from">
          Are you worried about sharing trial information with your doctor?
          We get it, but you shouldn`t be. You have to remember that your doctor wants
          the best for you and is rooting for you to succeed. Presenting your oncologist
          with information about your trial matches will ensure an informed and
          productive discussion about your treatment plan.
        </p>
      </PopupContent>
      <PopupButton
        title="Next"
        action={send}
      />
    </div>
  );
};

SendToDoctor.propTypes = {
  closePopup: PropTypes.func,
  trials: PropTypes.array,
};

export default SendToDoctor;
