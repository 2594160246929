import React, { useEffect, useState } from 'react';

import app from 'new-ui/app';
import { PAGES, URI } from 'new-ui/constants';

import { useDispatch, useSelector } from 'react-redux';
import useRecaptcha from 'utils/hooks/useRecaptcha';
import { authenticationActions } from 'components/Authentication/actions';
import { useTranslation } from 'utils/modifiedTranslation';

import Form, { FORM_INPUT_TYPES, FORM_VALIDATORS } from 'new-ui/Components/Form';
import Button from 'new-ui/Components/Button';

import { useHistory } from 'react-router-dom';

const loginForm = {
  inputs: {
    email: {
      type: FORM_INPUT_TYPES.INPUT,
      icon: 'email',
      placeholder: 'Your email',
    },
    password: {
      type: FORM_INPUT_TYPES.PASSWORD,
      placeholder: 'Your password',
    },
  },
  data: {
    email: '',
    password: '',
  },
};

const EmailLogin = () => {
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);
  const { t } = useTranslation();
  const { error } = useSelector((state) => ({
    error: state?.authentication?.error,
  }));
  const [showError, setError] = useState(null);

  loginForm.inputs.email.placeholder = t('login.your_email');
  loginForm.inputs.password.placeholder = t('login.your_password');

  useEffect(() => {
    if (params.get('email') && document.querySelector('.form-part.email input')) {
      document.querySelector('.form-part.email input').value = params.get('email');
      loginForm.data.email = params.get('email');
    }
  }, [params]);

  useEffect(() => {
    setError(error ? t(error) : null);
    app.setShowPreloader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const dispatch = useDispatch();
  const { recaptcha, getRecaptchaTokenAsync } = useRecaptcha();

  const login = async () => {
    const email = loginForm?.data?.email;
    const password = loginForm?.data?.password;

    if (!FORM_VALIDATORS.EMAIL(email)) {
      setError(t('login.invalid_email_address'));
      return false;
    }

    if (!password) {
      setError(t('login.invalid_password'));
      return false;
    }

    setError(null);

    app.showPreloader();

    dispatch(
      authenticationActions.requestLogin({
        info: {
          email: email.toLowerCase(),
          password,
        },
        recaptchaToken: await getRecaptchaTokenAsync(),
      }, null, new URLSearchParams(window.location.search).get('redirect')),
    );
  };

  return (
    <div className="email-login">
      {recaptcha}
      <div className="login-form">
        <div className="header">
          <h1>
            {t('login.quick_login_with_email_and_password')}
          </h1>
        </div>
        <Form
          form={loginForm}
          onenter={login}
        />
        <div className="action">
          <span onClick={() => {
            history.push(URI[PAGES.FORGOT_PASSWORD]);
          }}
          >
            {t('login.forgot_password')}
          </span>
        </div>
        {showError ? <div className="login-error">{showError}</div> : null}
        <div className="login-button-holder">
          <Button action={login} title={t('login.enter')} />
        </div>
      </div>
    </div>
  );
};

export default EmailLogin;
