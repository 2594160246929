import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

const OptionRadio = ({ checked, onChange, label }) => {
  return (
    <label className={`option-radio ${checked ? 'checked':''}`}>
      <input
        type="radio"
        checked={checked}
        onChange={onChange}
      />
      <span className="option-radio-mask"></span>
      <span className='option-radio-label'>{label}</span>
    </label>
  );
};

OptionRadio.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
};

export default OptionRadio;