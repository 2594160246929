import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './style.css';
import ArrowRight from 'new-ui/assets/icons/keyboard_arrow_right.svg';
import DoubleArrowRight from 'new-ui/assets/icons/keyboard_double_arrow_right.svg';

const Pagination = ({ totalItems, perPage, onPageChange }) => {
  const location = useLocation();

  const totalPages = Math.ceil(totalItems / perPage);
  const query = new URLSearchParams(location.search);
  const initialPage = parseInt(query.get('page')) || 1;

  const [currentPage, setCurrentPage] = useState(initialPage);
  const [displayedPageRange, setDisplayedPageRange] = useState({ startPage: 1, endPage: Math.min(totalPages, 3) });

  useEffect(() => {
    if (initialPage !== currentPage) {
      setCurrentPage(initialPage);
    }
    adjustDisplayedRange(initialPage);
    // eslint-disable-next-line
  }, [location.search, initialPage]);

  const updateQuery = (page) => {
    if (page >= 1 && page <= totalPages) {
      const newQuery = new URLSearchParams(location.search);
      newQuery.set('page', page);
      window.history.replaceState(null, '', `?${newQuery.toString()}`);
      setCurrentPage(page);
      if (onPageChange) {
        onPageChange(page);
      }
      adjustDisplayedRange(page);
    }
  };

  const handlePreviousPage = () => {
    const newPage = currentPage - 1;
    if (newPage >= 1) {
      updateQuery(newPage);
    }
  };

  const handleNextPage = () => {
    const newPage = currentPage + 1;
    if (newPage <= totalPages) {
      updateQuery(newPage);
    }
  };

  const adjustDisplayedRange = (page) => {
    let startPage, endPage;
    if (totalPages <= 3) {
      startPage = 1;
      endPage = totalPages;
    } else if (page <= 2) {
      startPage = 1;
      endPage = 3;
    } else if (page >= totalPages - 1) {
      startPage = totalPages - 2;
      endPage = totalPages;
    } else {
      startPage = page - 1;
      endPage = page + 1;
    }
    setDisplayedPageRange({ startPage, endPage });
  };

  const pageNumbers = [];
  for (let i = displayedPageRange.startPage; i <= displayedPageRange.endPage; i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="pagination">
      <div className='arrows arrows-left'>
        {currentPage > 1 && (
          <button className='arrow' onClick={() => updateQuery(1)}>
            <img src={DoubleArrowRight} alt="First Page" />
          </button>
        )}
        {currentPage > 1 && (
          <button className='arrow' onClick={handlePreviousPage}>
            <img src={ArrowRight} alt="Previous Page" />
          </button>
        )}
      </div>

      {pageNumbers.map(pageNumber => (
        <button
          key={pageNumber}
          className={pageNumber === currentPage ? 'active' : ''}
          onClick={() => updateQuery(pageNumber)}
        >
          {pageNumber}
        </button>
      ))}

      <div className='arrows arrows-right'>
        {currentPage < totalPages && (
          <button className='arrow' onClick={handleNextPage}>
            <img src={ArrowRight} alt="Next Page" />
          </button>
        )}
        {currentPage < totalPages && (
          <button className='arrow' onClick={() => updateQuery(totalPages)}>
            <img src={DoubleArrowRight} alt="Last Page" />
          </button>
        )}
      </div>
    </div>
  );
};

export default Pagination;