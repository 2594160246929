import matchesConstants, { FILTERS } from './constants';

const defaultFilters = {
  [FILTERS.TREATMENT_TYPES]: [],
  [FILTERS.TRIAL_PHASES]: [],
  [FILTERS.TRIAL_VIEW]: ''
};

export const initialState = {
  isFetching: false,
  error: null,
  mobileView: matchesConstants.MAP_VIEW, // map | list
  trial_groups: [],
  trial_group_descriptions: {},
  trial_groups_colors: {},
  result_trials: [],
  result: null,
  filters: {
    ...defaultFilters
  }
};

export function matches(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case matchesConstants.GET_MATCHES_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null
      };
    case matchesConstants.GET_MATCHES_SUCCESS:
      return {
        ...state,
        ...payload,
        result_trials: [...payload.result],
        isFetching: false,
        error: null
      };
    case matchesConstants.GET_MATCHES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: payload
      };
    case matchesConstants.CLEAR_MATCHES:
      return {
        ...initialState,
        mobileView: state.mobileView,
      };
    case matchesConstants.TOGGLE_MATCHES_VIEW:
      return {
        ...state,
        mobileView: payload
      };
    case matchesConstants.CHANGE_MATCHES_FILTERS: {
      const itemKey = Object.keys(payload).pop();

      if (Array.isArray(state.filters[itemKey])) {
        return {
          ...state,
          filters: {
            ...state.filters,
            [itemKey]: [
              ...(state.filters[itemKey].includes(payload[itemKey])
                ? state.filters[itemKey].filter(item => item !== payload[itemKey])
                : [...state.filters[itemKey], payload[itemKey]])
            ]
          }
        };
      }

      return {
        ...state,
        filters: {
          ...state.filters,
          [itemKey]: payload[itemKey]
        }
      };
    }
    case matchesConstants.UPDATE_TRIALS_RESULT:
      return {
        ...state,
        result_trials: [...payload]
      };
    case matchesConstants.FLUSH_MATCHES_FILTERS:
      return {
        ...state,
        filters: {
          ...defaultFilters
        },
        result_trials: [...(state.result || [])]
      };
    case matchesConstants.INITIAL_SORT_SET:
      return {
        ...state,
        filters: {
          ...state?.filters,
          [FILTERS.TRIAL_VIEW]: payload
        }
      };
    default:
      return state;
  }
}
