import userConstants from 'components/Authentication/constants';
import { SET_PROVIDER } from './constants';

export function provider(state = null, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_PROVIDER:
      return payload;
    case userConstants.LOGOUT:
      return null;
    default:
      return state;
  }
}
