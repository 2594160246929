import { trialActions as TA } from './constants';

const initialState = {
  isFetching: false,
  error: null,
  trial: null,
  trials: []
};

export const publicTrial = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case TA.GET_TRIAL_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null
      };
    case TA.GET_TRIAL_SUCCESS:
      return {
        ...state,
        trial: { ...payload },
        isFetching: false,
        error: null
      };
    case TA.GET_TRIAL_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: payload
      };
    default:
      return state;
  }
};
