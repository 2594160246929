const GA_CATEGORY_NAME = 'Profile section';

const profileTreatmentSectionEvents = {
  treatmentQuestionPresented: {
    eventName: 'treatment question viewed',
    getMetaData: () => ({
      source: 'profile treatment section',
    }),
  },
  addTreatmentButtonClicked: {
    eventName: 'add treatment clicked',
    getMetaData: () => ({
      location: 'profile treatment section',
    }),
  },
  profileTabWithoutTakingStateOpened: {
    eventName: 'profile viewed',
    getMetaData: () => ({
      treatmentSectionState: 'status is unknow',
    }),
  },
  profileTabWithoutOutcomeOpened: {
    eventName: 'profile viewed',
    getMetaData: () => ({
      treatmentSectionState: 'outcome is unknow',
    }),
  },
  treatmentTakingStatusClicked: {
    eventName: 'treatment status clicked',
    getMetaData: (newStatus) => ({
      newStatus,
    }),
  },
  treatmentStopReasonClicked: {
    eventName: 'treatment outcome clicked',
    getMetaData: (outcome) => ({
      outcome,
    }),
  },
  treatmentEditClicked: {
    eventName: 'treatment edit clicked',
    getMetaData: () => ({
      source: 'profile treatment section',
    }),
  },
  treatmentEdited: {
    eventName: 'treatment was editted',
    getMetaData: () => ({
      source: 'profile treatment section',
    }),
  },
  treatmentDeleted: {
    eventName: 'treatment was deleted',
    getMetaData: () => ({
      source: 'profile treatment section',
    }),
  },
  surveyNotReportedViewed: {
    eventName: 'profile viewed',
    getMetaData: () => ({
      treatment_section_state: 'treatment survey is not reported',
    }),
  },
  surveyHelpOtherClicked: {
    eventName: 'treatment survey clicked',
    getMetaData: () => ({
      source: 'profile treatment section',
    }),
  },
  surveyIntroPageDisplayed: {
    eventName: 'treatment survey intro viewed',
    getMetaData: () => ({
      source: 'profile treatment section',
    }),
  },
  surveyStarted: {
    eventName: 'treatment survey started',
    getMetaData: () => ({
      source: 'profile treatment section',
    }),
  },
  surveyAnswered: {
    eventName: 'treatment survey answered',
    getMetaData: () => ({
      source: 'profile treatment section',
    }),
  },
  surveyExit: {
    eventName: 'treatment survey intro exit',
    getMetaData: () => ({
      source: 'profile treatment section',
    }),
  },
  profileCriticalInformationPresented: {
    eventName: 'Profile critical information presented',
    getMetaData: (missingCriticalInfo) => ({
      source: 'profile medical info',
      missingCriticalInfo,
    }),
  },
  profileCriticalInformationClicked: {
    eventName: 'Profile critical information clicked',
    getMetaData: () => ({
      source: 'profile medical info',
    }),
  },
  profileCriticalInformationAnswered: {
    eventName: 'Profile critical information answered',
    getMetaData: () => ({
      source: 'profile medical info',
    }),
  },
};

export {
  GA_CATEGORY_NAME,
  profileTreatmentSectionEvents,
};
