import Aml from 'components/Questionnaire/cancers/aml/Aml.questionnaire';
import Bladder from 'components/Questionnaire/cancers/bladder/Bladder.questionnaire';
import Brain from 'components/Questionnaire/cancers/brain/Brain.questionnaire';
import Breast2 from 'components/Questionnaire/cancers/breast_2/Breast2.questionnaire';
import Crc2 from 'components/Questionnaire/cancers/crc_2/Crc2.questionnaire';
import Crc from 'components/Questionnaire/cancers/crc/Crc.questionnaire';
import Lung from 'components/Questionnaire/cancers/lung/Lung.questionnaire';
import Mds from 'components/Questionnaire/cancers/mds/Mds.questionnaire';
import Melanoma2 from 'components/Questionnaire/cancers/melanoma_2/Melanoma_2.questionnaire';
import Melanoma from 'components/Questionnaire/cancers/melanoma/Melanoma.questionnaire';
import Mm from 'components/Questionnaire/cancers/mm/Mm.questionnaire';
import Mpn from 'components/Questionnaire/cancers/mpn/Mpn.questionnaire';
import Nhl from 'components/Questionnaire/cancers/nhl/Nhl.questionnaire';
import Prostate from 'components/Questionnaire/cancers/prostate/Prostate.questionnaire';
import Uterine from 'components/Questionnaire/cancers/uterine/Uterine.questionnaire';
import Hnscc from 'components/Questionnaire/cancers/hnscc/Hnscc.questionnaire';
import Bladder2 from 'components/Questionnaire/cancers/bladder_2/Bladder2.questionnaire';
import Cll from 'components/Questionnaire/cancers/cll/Cll.questionnaire';
import GiTract from 'components/Questionnaire/cancers/gi_tract/GiTract.questionnaire';
import Ovarian from 'components/Questionnaire/cancers/ovarian/Ovarian.questionnaire';
import Hcc from 'components/Questionnaire/cancers/hcc/Hcc.questionnaire';

import { fullName } from '../constants/globalQuestions';

const questionsToExclude = [
  'primary_tumor_size_mm',
  'serum_mprotein_level',
  // 'lymphocyte_count',
  'blast_count',
  'metastasis_size_mm',
];

const [
  AmlQuestions,
  HnsccQuestions,
  BladderQuestions,
  BrainQuestions,
  Breast2Questions,
  Crc2Questions,
  CrcQuestions,
  LungQuestions,
  MdsQuestions,
  Melanoma2Questions,
  MelanomaQuestions,
  MmQuestions,
  MpnQuestions,
  NhlQuestions,
  ProstateQuestions,
  UterineQuestions,
  Bladder2Questions,
  CllQuestions,
  GiTractQuestions,
  OvarianQuestions,
  HccQuestions,
] = [
  Aml,
  Hnscc,
  Bladder,
  Brain,
  Breast2,
  Crc2,
  Crc,
  Lung,
  Mds,
  Melanoma2,
  Melanoma,
  Mm,
  Mpn,
  Nhl,
  Prostate,
  Uterine,
  Bladder2,
  Cll,
  GiTract,
  Ovarian,
  Hcc,
].map((q) => [
  ...q.filter(({ id }) => !questionsToExclude.includes(id)),
  fullName,
]);

const TestQuestions = {
  aml: AmlQuestions,
  hnscc: HnsccQuestions,
  bladder: BladderQuestions,
  brain: BrainQuestions,
  breast_2: Breast2Questions,
  crc_2: Crc2Questions,
  crc: CrcQuestions,
  lung: LungQuestions,
  mds: MdsQuestions,
  melanoma_2: Melanoma2Questions,
  melanoma: MelanomaQuestions,
  mm: MmQuestions,
  mpn: MpnQuestions,
  nhl: NhlQuestions,
  prostate: ProstateQuestions,
  uterine: UterineQuestions,
  bladder_2: Bladder2Questions,
  cll: CllQuestions,
  gi_tract: GiTractQuestions,
  hcc: HccQuestions,
};

window.__questions = TestQuestions;

export default TestQuestions;

export {
  AmlQuestions,
  HnsccQuestions,
  BladderQuestions,
  BrainQuestions,
  Breast2Questions,
  Crc2Questions,
  CrcQuestions,
  LungQuestions,
  MdsQuestions,
  Melanoma2Questions,
  MelanomaQuestions,
  MmQuestions,
  MpnQuestions,
  NhlQuestions,
  ProstateQuestions,
  UterineQuestions,
  Bladder2Questions,
  CllQuestions,
  GiTractQuestions,
  OvarianQuestions,
  HccQuestions,
};
