import React from 'react';
import './Popup.2.css';
import { render } from 'react-dom';
import { DOM } from 'new-ui/functions';
import { POPUPS } from 'new-ui/Popups';
import XIcon from 'new-ui/assets/icons/x.svg';

const $popups = DOM('.popups2');

document.body.appendChild($popups);

window.popups = {
  all: [],
};

class Popup {
  constructor(s = {}) {
    const DEFAULTS = {
      type: 'default',
      className: '',
      speed: 0.333, // sec.
      includeHeader: true,
      onClose: () => {},
      data: {
        theme: '',
        CSS: {}, // css object - not required
        endCSS: {}, // css object after animation - not required
      },
    };

    this.settings = Object.assign(DEFAULTS, s);

    this.styles = [];

    this.create();

    window.popups[this.settings.type] = this;
    window.popups.all.push(this);
  }

  create() {
    this.$popup = DOM('.popup2');

    this.createOverlay();
    this.createCSS();

    this.$popup.classList.add(this.settings.type);

    if (this.settings.data.theme) this.$popup.classList.add(`${this.settings.data.theme}-popup-theme`);

    if (this.settings.includeHeader) {
      this.$popup.$header = DOM('.popup2-header.middle', {
        '/img.close': {
          src: XIcon,
          onclick: () => {
            this.hide();
            this.settings.onClose();
          },
        },
      });

      this.$popup.appendChild(this.$popup.$header);
    }

    const Content = POPUPS[this.settings.type];
    this.$popup.$content = DOM('.popup2-content');
    this.$popup.appendChild(this.$popup.$content);
    render(<Content
      {...this.settings.data}
      closePopup={() => {
        this.hide();
      }}
    />, this.$popup.$content);
  }

  createOverlay() {
    const $overlay = DOM('.popups2-overlay');

    $overlay.show = () => {
      $overlay.classList.add('visible');
      setTimeout(() => {
        $overlay.classList.add('animate-in');
      }, 10);
    };

    $overlay.hide = () => {
      $overlay.classList.remove('animate-in');
      setTimeout(() => {
        $overlay.classList.remove('visible');
      }, 333);
    };

    this.$overlay = $overlay;

    $popups.appendChild($overlay);
  }

  createCSS() {
    if (this.settings?.data?.CSS) this.appendStyle(this.settings.data.CSS, this.settings.type);
    if (this.settings?.data?.endCSS) this.appendStyle(this.settings.data.CSS, `${this.settings.type}.animate-in`);
  }

  appendStyle(cssObject, className) {
    let css = '';
    Object.keys(cssObject).forEach((p) => {
      css += `${p}: ${cssObject[p]};`;
    });
    const styleTag = document.createElement('style');
    styleTag.textContent = `
        @media only screen and (min-width: 800px) { 
          .popup2.${className}{
            ${css}
          }
        }`;
    this.styles.push(styleTag);
    document.head.appendChild(styleTag);
  }

  show() {
    this.$overlay.show();
    $popups.appendChild(this.$popup);
    this.$popup.classList.add('visible');
    setTimeout(() => {
      this.$popup.classList.add('animate-in');
    }, 10);
    this.$overlay.onclick = () => {
      this.hide();
    };
  }

  hide() {
    this.$overlay.hide();
    this.$popup.classList.remove('animate-in');
    setTimeout(() => {
      this.$popup.classList.remove('visible');
      if ($popups.contains(this.$popup))$popups.removeChild(this.$popup);
    }, this.settings.speed * 1000);
  }
}

window.popups.hideAll = () => {
  window.popups.all.forEach((p) => {
    p.hide();
  });
};

export default Popup;
