export default values => {
  if (!values) {
    return null;
  }

  if (Array.isArray(values) && values?.length) {
    return values.map(val => val?.value);
  }

  if (values?.value) {
    return values?.value;
  }

  return values;
};
