export const Profile = {
  // global questions from "user_personal_record" table
  user_type: 'user_type',
  dob: 'dob',
  gender: 'gender',

  // all the fields from api table
  current_stage: 'current_stage',
  race: 'race',
  disease_status: 'disease_status',
  type_of_hnscc: 'type_of_hnscc',
  patient_stage: 'patient_stage',
  triple_question_drop_down: 'triple_question_drop_down',
  tnm_t: 'tnm_t',
  tnm_n: 'tnm_n',
  tnm_m: 'tnm_m',
  disease_location: 'disease_location',
  metastasis_location_brain_leptomeningeal: 'metastasis_location_brain_leptomeningeal',
  brain_metastasis_active: 'brain_metastasis_active',
  disease_measurable: 'disease_measurable',
  primary_tumor_size_mm: 'primary_tumor_size_mm',
  metastasis_size_mm: 'metastasis_size_mm',
  has_biopsy: 'has_biopsy',
  pd_l1_carrier_status: 'pd_l1_carrier_status',
  pd_l1_expression: 'pd_l1_expression',
  ngs: 'ngs',
  disease_resectable: 'disease_resectable',
  procedures: 'procedures',
  had_procedure: 'had_procedure',

  treatments: 'treatments',
  treatment_for_advanced_metastatic_disease: 'treatment_for_advanced_metastatic_disease',
  current_treatment_systemic_steroids: 'current_treatment_systemic_steroids',
  systemic_steroids_treatment_end_date: 'systemic_steroids_treatment_end_date',
  ecog: 'ecog',
  other_medical_condition: 'other_medical_condition',
  omc_liver: 'omc_liver',
  omc_heart: 'omc_heart',
  omc_stroke_date: 'omc_stroke_date',
  omc_kidney: 'omc_kidney',
  omc_autoimmune: 'omc_autoimmune',
  omc_mental: 'omc_mental',
  omc_allergy: 'omc_allergy',
  omc_chronic_infection_inflammation: 'omc_chronic_infection_inflammation',
  omc_gastro_problem: 'omc_gastro_problem',
  omc_lung_disease: 'omc_lung_disease',
  omc_abnormal_blood_results: 'omc_abnormal_blood_results',
  other_medications: 'other_medications',
  is_pregnant: 'is_pregnant',
  is_breastfeeding: 'is_breastfeeding',
  have_insurance: 'have_insurance',
  insurances: 'insurances',

  // additional questions which are not listed in api table
  drugs_received: 'drugs_received',
  cycles: 'cycles',
  procedures_received: 'procedures_received',
  procedure_date: 'procedure_date',
  procedure_outcome: 'procedure_outcome',
  treatment_for_hnscc: 'treatment_for_hnscc',
  treatment_end_date: 'treatment_end_date',
  treatment_stop_reason: 'treatment_stop_reason',
  treatment_admin: 'treatment_admin',
};
