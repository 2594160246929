import _ from 'lodash';
import { getAge } from '../../../functions/getAge';

export function ageLessThan(profile, condition) {
  // Check in store if the condition equals
  const ifId = 'dob';
  const { ifValue } = condition;

  if (!_.hasIn(profile, ifId) || _.isNull(profile[ifId])) {
    // In case the ifId is not in profile
    // console.log(ifId, 'not exist');
    return true;
  }
  const age = getAge(profile[ifId]);
  // const checkIf = _.isEqual(profile[ifId], ifValue);
  const checkIf = age <= ifValue;

  // console.log(`Check if patient age (${age}) is less than ${ifValue}. It is ${checkIf}`);
  return checkIf;
}
