import React from 'react'; 
import { CapsuleIcon, DoctorIcon, MicroscopeIcon } from './icons';
import PillIcon from 'new-ui/assets/icons/pill.svg';
import NotebookIcon from 'new-ui/assets/icons/notebook.svg';
import HandIcon from 'new-ui/assets/icons/hand.svg';

const EVENT_TYPES = {
  'treatment': {
    color: 'rgba(46, 196, 182, 0.20)', 
    hover_color: 'rgba(46, 196, 182, 0.80)',
    key: 'treatment',
    label: 'events_widget.options.type.treatment',
    icon: <CapsuleIcon />
  },
  'clinic_visit': {
    color: 'rgba(244, 208, 111, 0.20)', 
    hover_color: 'rgba(244, 208, 111, 0.80)',
    key: 'clinic_visit',
    label: 'events_widget.options.type.clinic_visit',
    icon: <DoctorIcon />
  },
  'scan': {
    color: 'rgba(110, 68, 255, 0.20)', 
    hover_color: 'rgba(110, 68, 255, 0.80)',
    key: 'scan',
    label: 'events_widget.options.type.scan',
    icon: <MicroscopeIcon />
  }
};

const STATUSES = [
  {
    key: 'questionnaire.questions.global.disease_status.options.partial_response_stable',
    label: "I'm still in treatment, and it's either reducing or stabilizing my disease.",
    icon: PillIcon
  },
  {
    key: 'questionnaire.questions.global.disease_status.options.refractory_disease',
    label: "My cancer isn't responding to the latest treatment.",
    icon: NotebookIcon
  },
  {
    key: 'questionnaire.questions.global.disease_status.options.remission',
    label: "I've finished treatment and currently show no signs of cancer.",
    icon: HandIcon
  }
];

const REPEAT_OPTIONS = [
  { label: 'events_widget.options.repeat.no_repeat', value: 'no_repeat' },
  { label: 'events_widget.options.repeat.daily', value: 'daily' },
  { label: 'events_widget.options.repeat.weekly', value: 'weekly' },
  { label: 'events_widget.options.repeat.every_2_weeks', value: 'every_2_weeks' },
  { label: 'events_widget.options.repeat.every_3_weeks', value: 'every_3_weeks' },
  { label: 'events_widget.options.repeat.monthly', value: 'monthly' }
];

export {
  EVENT_TYPES,
  STATUSES,
  REPEAT_OPTIONS
};