import { authHeaders } from '../authHeader';
import configs from '../../configs';

const baseURL = configs.beApiUrl;

export const invoke = async (path, params, data) => {
  const url = new URL(baseURL + path);
  if (params) url.search = new URLSearchParams(params);

  const headers = await authHeaders();
  const request = {
    method: data ? 'POST' : 'GET',
    headers
  };

  if (data) request.body = JSON.stringify(data);
  const response = await fetch(url, request);

  if (response.ok) {
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
      return response.json();
    }

    return response.blob();
  }

  if (response.status === 404) {
    console.error(url);
    return undefined; // not found
  }

  throw new Error(response.statusText);
};
