/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable max-len */
/* eslint-disable prefer-template */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable quotes */
/* eslint-disable comma-dangle */
/* eslint-disable object-curly-newline */
/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable arrow-parens */
/* eslint-disable react/button-has-type */
/* eslint-disable-next-line jsx-a11y/alt-text */
/* eslint-disable-line(mode) */
/* eslint-disable-next-line */

import React, { useEffect, useState } from 'react';
import app from 'new-ui/app';
import { getMissingCriticalFromTrials } from 'new-ui/functions';
import { useLocation } from 'react-router-dom';
import { CHAT_ROLES } from 'new-ui/constants';
import GenericChat from '../../Components/GenericChat';
import {
  setUserIntent,
  formmatedTrials,
  handleSendMessage,
  hoursDiff,
  isMoreThan29DaysApart,
  updateChatScoreUsingThumb,
  socTreatmentsCategoriesFormatter,
  returnChatUserOpenSentence,
} from './functions';
import AnswerPicker from './AnswerPicker/AnswerPicker';
import ActionPanel from './ActionPanel/ActionPanel';
import NextScanChat from './Forms/NextScan';

let userChatMode = '-1';
let socTreatments = [];
let showUpdateIntentPicker = true;

const setUserChatMode = mode => {
  userChatMode = mode;
};
const setSocTreatments = treatments => {
  socTreatments = treatments;
};

const setShowUpdateIntentPicker = state => {
  showUpdateIntentPicker = state;
};

const missingCritical = getMissingCriticalFromTrials(app?.trials);

const ChatPopup = (props) => {

  const isFullMode = {props}
  const location = useLocation();
  const [init, setInit] = useState(null);
  const [loading, setLoading] = useState(false);
  const [chatHistory, setChatHistory] = useState(app.chatHistory || []);
  const [showChatNextScan, setShowChatNextScan] = useState(false);
  const [isReturnUser] = useState(app?.coniguration?.data?.chat_return_user);
  const [lastTimeProfileUpdate] = useState(app.user.personal.profile_updated_at);
  // eslint-disable-next-line no-unused-vars
  const [showThumbs, setShowThumbs] = useState(true);
  const [sponsoredTrialsExaplained, setSponsoredTrialsExaplained] = useState(false);
  const [treatmentCategories, setTreatmentCategories] = useState([]);
  const [socTreatmentsCategories, setSocTreatmentsCategories] = useState([]);

  const userTrialList = formmatedTrials(app?.trials ?? []);
  const userHaveSponseredTrialList = app.trials?.some(trial => {
    return trial?.isSponsored;
  });
  const userSponsoredTrials = app.trials?.filter(trial => {
    return trial?.isSponsored;
  });

  const userWixId = app.user.info.user_wix_id;
  const testReturnUser = app.user.info.test_return_user;
  const indication = app.user.personal.condition;
  const userLocation = { label: app.user.info.country, location: app.user.info.location };
  const firstRegistered = app.user.personal.first_registered_at;
  const lastActive = new Date();
  const timeSinceRegisteredInHours = hoursDiff(firstRegistered, lastActive);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (app.treatmentsCategories) {
      setSocTreatmentsCategories(
        app.treatmentsCategories
          .filter(e => !e.treatment_category_key.includes('supportive_care'))
          .map(e => e.treatment_category_key)
      );
    } else {
      setSocTreatmentsCategories([]);
    }
    if (app.trials) {
      const tempTreatmentCategories = [];
      app.trials.forEach(item => {
        item.groups.forEach(group => {
          if (!tempTreatmentCategories.includes(group)) {
            tempTreatmentCategories.push(group);
          }
        });
        setTreatmentCategories(tempTreatmentCategories);
      });
    } else {
      setTreatmentCategories([]);
    }
    setSocTreatments(socTreatmentsCategoriesFormatter(app.treatments));
    const userIntention = (
      <AnswerPicker
        isFullMode={isFullMode}
        key="userIntention"
        options={[
          {
            value: 'need_treatment',
            title: 'I need a treatment',
          },
          {
            value: 'in_treatment',
            title: `I’m in treatment`,
          },
          {
            value: 'waiting_for_scan',
            title: `I'm waiting for a test result`,
          },
        ]}
        onOptionSelected={selectedOption =>
          setUserIntent(
            selectedOption,
            setUserChatMode,
            chatHistory,
            setChatHistory,
            socTreatments.length,
            userTrialList.length,
            setShowChatNextScan,
            missingCritical,
            setLoading
          )
        }
      />
    );
    if (localStorage.getItem('chatUploadSeen') === '1') {
      app.chatHistory = null;
      localStorage.removeItem('chatUploadSeen');
    }
    setChatHistory(
      app.chatHistory || [
        {
          role: CHAT_ROLES.JANE,
          content: `${
            isReturnUser && isMoreThan29DaysApart(Date.now(), lastTimeProfileUpdate)
              ? ``
              : timeSinceRegisteredInHours <= 12
              ? ` Hi, sorry to hear about your cancer. I'm here to help. Can you tell me if you are looking for treatment for now or exploring options for the future?
                `
              : ` Hi, it's important that you've returned. I'm happy to help. Can you tell me what are you looking for?`
          }`,
          daynamicContent: (
            <div>
              {(isReturnUser && isMoreThan29DaysApart(Date.now(), lastTimeProfileUpdate)) || testReturnUser
                ? showUpdateIntentPicker
                  ? returnChatUserOpenSentence(
                      showUpdateIntentPicker,
                      setShowUpdateIntentPicker,
                      setChatHistory,
                      chatHistory,
                      userIntention
                    )
                  : null
                : userIntention}
            </div>
          ),
        },
      ]
    );
    setInit(true);
  }, []);

  useEffect(() => {
    const $chatPopup = document.querySelector('.chat-popup');
    if ($chatPopup) {
      $chatPopup.scrollTop = $chatPopup.scrollHeight;
    }
    app.chatHistory = chatHistory;
  }, [chatHistory]);

  useEffect(() => {}, [showUpdateIntentPicker]);
  const handleSendMessageClick = async inputMessage => {
    await handleSendMessage(
      inputMessage,
      chatHistory,
      setChatHistory,
      userTrialList,
      userWixId,
      treatmentCategories,
      missingCritical,
      indication,
      userChatMode,
      setUserChatMode,
      setLoading,
      setShowChatNextScan,
      userHaveSponseredTrialList,
      userLocation,
      userSponsoredTrials,
      sponsoredTrialsExaplained,
      setSponsoredTrialsExaplained,
      setShowThumbs,
      socTreatmentsCategories,
      socTreatments
    );
  };

  const NextScanContainer = (
    <div>
      {showChatNextScan ? (
        <div className={`${location.pathname.includes('dashboard/chat') ? 'next-scan-full' : 'next-scan'}`}>
          <NextScanChat setShowChatNextScan={setShowChatNextScan} setChatHistory={setChatHistory} />
        </div>
      ) : null}
    </div>
  );

  const ActionPanelContainer = (
    <ActionPanel
      loading={loading}
      setUserChatMode={setUserChatMode}
      setChatHistory={setChatHistory}
      userHaveSponseredTrialList={userHaveSponseredTrialList}
      setShowChatNextScan={setShowChatNextScan}
      isFullMode={isFullMode}
    />
  );

  return init ? (
    <GenericChat
      handleSendMessageClick={handleSendMessageClick}
      updateChatScoreUsingThumb={updateChatScoreUsingThumb}
      actionPanel={<>{isFullMode ? ActionPanelContainer : null}</>}
      chatHistory={chatHistory}
      chatBodyComponent={<>{NextScanContainer}</>}
      isFullMode={isFullMode}
    />
  ) : null;
};

export default ChatPopup;
