import { store } from 'utils/exportStore';
import { FB_EVENTS, FB_TRACK_TYPE } from 'functions/analytics/constants/fbKeys';

export default (method, eventID) => {
  // Process only known signup methods, with configured fb pixel and only on production
  if (typeof window.fbq !== 'function') {
    return;
  }

  const { authentication, profile } = store.getState();
  const dataToSend = {
    userWixId: authentication?.user?.user_wix_id || profile?.personal?.user_wix_id || null,
    disease: profile?.personal?.condition || null,
    method: (method || '').toLowerCase(),
  };

  window.fbq(
    FB_TRACK_TYPE.TRACK_ALL,
    FB_EVENTS.REGISTRATION_EVENT,
    dataToSend,
    { eventID },
  );
};
