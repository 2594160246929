import * as React from "react";

const EditIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} width="15" height="14" viewBox="0 0 15 14" fill="none">
        <path d="M7.29238 0.524902H5.31738C2.8321 0.524902 0.817383 2.53962 0.817383 5.0249V8.9749C0.817383 11.4602 2.8321 13.4749 5.31738 13.4749H9.26738C11.7527 13.4749 13.7674 11.4602 13.7674 8.9749V6.9999" stroke="#213332" strokeLinecap="round"/>
        <path d="M9.59481 1.5468C10.4491 0.692524 11.8341 0.692524 12.6884 1.5468V1.5468C13.5427 2.40107 13.5427 3.78612 12.6884 4.64039L7.8133 9.51548C7.56518 9.76361 7.23791 9.91689 6.88845 9.94866L5.81895 10.0459C4.88604 10.1307 4.1045 9.34915 4.1893 8.41625L4.28653 7.34675C4.3183 6.99728 4.47158 6.67002 4.71971 6.42189L9.59481 1.5468Z" stroke="#213332"/>
        <path d="M9.04199 2.1875V2.1875C9.60075 3.58439 10.7076 4.69124 12.1045 5.25V5.25" stroke="#213332" strokeLinecap="square"/>
    </svg>
);
export default EditIcon;
