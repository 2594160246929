import {
  SINGLE_OPTION_SELECT,
  MULTIPLE_OPTION_SELECT,
  NUMBER_INPUT,
  MONTH_PICKER,
  TREATMENT_HISTORY,
  PROCEDURES,
  TRIPLE_QUESTION_DROP_DOWN,
  MULTIPLE_OPTION_SEARCH_SELECT,
  BIOMARKERS,
  OPTIONS_FROM_SERVER,
} from '../../constants/questionTypes';
import dateType from '../../../../utils/constants/dateTypes';
import { Profile as ID } from './UterineProfile.enum';
import ConditionTypes from '../../constants/conditions';
import i from '../../constants/icons';
import numberType from '../../../../utils/constants/numberTypes';
import {
  preferredLocation,
  travelLimit,
  userType,
  dateOfBirth,
  race,
  diseaseStatus,
  haveInsurance,
  insurance,
  pdL1ExpressionByQNumber,
  initialDiagnosisDate,
} from '../../constants/globalQuestions';
import {
  notSureOption, treatmentCyclesOptions,
  // takingStateOptions,
  yesAndNoOptions,
} from '../../constants/globalOptions';
import SECTIONS from '../../constants/questionSections';
import questionOptions from '../../constants/questionOptions';
import { QUESTION_VIEW_TYPES } from '../../constants/questionViewTypes';
import GLOBAL_ENUMS from '../../constants/globalEnums';

const { biomarkers: BIOMARKERS_QUESTION_ID } = GLOBAL_ENUMS;

const { OPTIONS_LIST_WITH_SHOW_MORE } = QUESTION_VIEW_TYPES;

const noneOption = questionOptions.NONE;
const {
  EQUAL_TO,
  NOT_EQUAL_TO_OR_SKIPPED,
  CONTAIN_ONE_OF,
  AGE_LESS_THAN,
  IS_SKIPPED,
  DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
  CONTAINS_ONE_OF_OR_SKIPPED,
} = ConditionTypes;

const Questions = [
  diseaseStatus,
  {
    q: 'Q6',
    id: ID.type_of_uterine_cancer,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.urine.1_type_of_uterine_cancer.title',

    info: {
      title: 'questionnaire.questions.urine.1_type_of_uterine_cancer.info.title',
      content: 'questionnaire.questions.urine.1_type_of_uterine_cancer.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.urine.1_type_of_uterine_cancer.options.sarcoma',
        value: 'sarcoma',
      },
      {
        label: 'questionnaire.questions.urine.1_type_of_uterine_cancer.options.carcinoma',
        value: 'carcinoma',
      },
      notSureOption,
    ],
    conditions: [],
    isRequired: true,
  },
  {
    q: 'Q6a',
    id: ID.type_of_carcinoma,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.urine.2_type_of_carcinoma.title',

    info: {
      title: 'questionnaire.questions.urine.2_type_of_carcinoma.info.title',
      content: 'questionnaire.questions.urine.2_type_of_carcinoma.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.urine.2_type_of_carcinoma.options.endometrioid',
        value: 'endometrioid',
      },
      {
        label: 'questionnaire.questions.urine.2_type_of_carcinoma.options.serous',
        value: 'serous',
      },
      {
        label: 'questionnaire.questions.urine.2_type_of_carcinoma.options.clear_cell',
        value: 'clear_cell',
      },
      {
        label: 'questionnaire.questions.urine.2_type_of_carcinoma.options.undifferentiated',
        value: 'undifferentiated',
      },
      {
        label: 'questionnaire.questions.urine.2_type_of_carcinoma.options.carcinosarcoma',
        value: 'carcinosarcoma',
      },
      {
        label: 'questionnaire.questions.urine.2_type_of_carcinoma.options.mixed',
        value: 'mixed',
      },
      {
        label: 'questionnaire.questions.urine.2_type_of_carcinoma.options.adenocarcinoma_nos',
        value: 'adenocarcinoma_nos',
      },
      {
        label: 'questionnaire.questions.urine.2_type_of_carcinoma.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: ID.type_of_uterine_cancer,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'sarcoma',
      },
    ],
    isRequired: true,
  },

  {
    q: 'Q6b',
    id: ID.type_of_sarcoma,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.urine.3_type_of_sarcoma.title',

    info: {
      title: 'questionnaire.questions.urine.3_type_of_sarcoma.info.title',
      content: 'questionnaire.questions.urine.3_type_of_sarcoma.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.urine.3_type_of_sarcoma.options.low_grade_ess',
        value: 'low_grade_ess',
      },
      {
        label: 'questionnaire.questions.urine.3_type_of_sarcoma.options.high_grade_ess',
        value: 'high_grade_ess',
      },
      {
        label: 'questionnaire.questions.urine.3_type_of_sarcoma.options.uus',
        value: 'uus',
      },
      {
        label: 'questionnaire.questions.urine.3_type_of_sarcoma.options.ulms',
        value: 'ulms',
      },
      {
        label: 'questionnaire.questions.urine.3_type_of_sarcoma.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: ID.type_of_uterine_cancer,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'carcinoma',
      },
    ],
  },
  {
    q: 'Q8',
    id: ID.current_stage,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.urine.5_current_stage.title',

    info: {
      title: 'questionnaire.questions.urine.5_current_stage.info.title',
      content: 'questionnaire.questions.urine.5_current_stage.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.urine.5_current_stage.options.1',
        value: '1',
      },
      {
        label: 'questionnaire.questions.urine.5_current_stage.options.2',
        value: '2',
      },
      {
        label: 'questionnaire.questions.urine.5_current_stage.options.3.1',
        value: '3.1',
      },
      {
        label: 'questionnaire.questions.urine.5_current_stage.options.3.2',
        value: '3.2',
      },
      {
        label: 'questionnaire.questions.urine.5_current_stage.options.3.3',
        value: '3.3',
      },
      {
        label: 'questionnaire.questions.urine.5_current_stage.options.4.1',
        value: '4.1',
      },
      {
        label: 'questionnaire.questions.urine.5_current_stage.options.4.2',
        value: '4.2',
      },
    ],
    conditions: [],
    isRequired: true,
  },
  {
    q: 'Q9',
    id: ID.treatment_for_uterine_cancer,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.urine.19_treatment_for_uterine_cancer.title',

    info: {
      title: '',
      content: '',
    },
    options: yesAndNoOptions,
    conditions: [],
  },
  {
    q: 'Q7',
    id: ID.menopausal_status,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.urine.4_menopausal_status.title',

    info: {
      title: 'questionnaire.questions.urine.4_menopausal_status.info.title',
      content: 'questionnaire.questions.urine.4_menopausal_status.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.urine.4_menopausal_status.options.post_menopausal',
        value: 'post_menopausal',
      },
      {
        label: 'questionnaire.questions.urine.4_menopausal_status.options.pre_menopausal',
        value: 'pre_menopausal',
      },
      {
        label: 'questionnaire.questions.urine.4_menopausal_status.options.pre_menopausal_with_ovarian_suppression',
        value: 'pre_menopausal_with_ovarian_suppression',
      },
    ],
    conditions: [
      {
        type: AGE_LESS_THAN,
        ifValue: 55,
      },
    ],
  },
  {
    q: 'Q11',
    id: ID.triple_question_drop_down,
    type: TRIPLE_QUESTION_DROP_DOWN,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.urine.6_triple_question_drop_down.title',
    subtitle: 'questionnaire.questions.urine.6_triple_question_drop_down.subtitle',
    info: {
      title: 'questionnaire.questions.urine.6_triple_question_drop_down.info.title',
      content: 'questionnaire.questions.urine.6_triple_question_drop_down.info.content',
    },
    options: [
      {
        title: 'T (1-4)',
        name: ID.tnm_t,
        options: [
          {
            label: '',
            value: '',
          },
          {
            label: '1',
            value: '1',
          },
          {
            label: '2',
            value: '2',
          },
          {
            label: '3',
            value: '3',
          },
          {
            label: '4a',
            value: '4.1',
          },
          {
            label: '4b',
            value: '4.2',
          },
        ],
      },
      {
        title: 'N (0-3)',
        name: ID.tnm_n,
        options: [
          {
            label: '',
            value: '',
          },
          {
            label: '0',
            value: '0',
          },
          {
            label: '1',
            value: '1',
          },
          {
            label: '2',
            value: '2',
          },
          {
            label: '3',
            value: '3',
          },
        ],
      },
      {
        title: 'M (0-1)',
        name: ID.tnm_m,
        options: [
          {
            label: '',
            value: '',
          },
          {
            label: '0',
            value: '0',
          },
          {
            label: '1',
            value: '1',
          },
        ],
      },
    ],
    conditions: [
      {
        ifId: ID.current_stage,
        type: IS_SKIPPED,
      },
    ],
  },
  {
    q: 'Q17',
    id: ID.disease_location,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.urine.7_disease_location.title',

    info: {
      title: 'questionnaire.questions.urine.7_disease_location.info.title',
      content: 'questionnaire.questions.urine.7_disease_location.info.content',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.urine.7_disease_location.options.uterus',
        value: 'uterus',
      },
      {
        label: 'questionnaire.questions.urine.7_disease_location.options.cervix',
        value: 'cervix',
      },
      {
        label: 'questionnaire.questions.urine.7_disease_location.options.abdomen',
        value: 'abdomen',
      },
      {
        label: 'questionnaire.questions.urine.7_disease_location.options.sentinel_ln',
        value: 'sentinel_ln',
      },
      {
        label: 'questionnaire.questions.urine.7_disease_location.options.vagina',
        value: 'vagina',
      },
      {
        label: 'questionnaire.questions.urine.7_disease_location.options.fallopian_tubes',
        value: 'fallopian_tubes',
      },
      {
        label: 'questionnaire.questions.urine.7_disease_location.options.ovaries',
        value: 'ovaries',
      },
      {
        label: 'questionnaire.questions.urine.7_disease_location.options.bladder',
        value: 'bladder',
      },
      {
        label: 'questionnaire.questions.urine.7_disease_location.options.rectum',
        value: 'rectum',
      },
      {
        label: 'questionnaire.questions.urine.7_disease_location.options.lymph_nodes',
        value: 'lymph_nodes',
      },
      {
        label: 'questionnaire.questions.urine.7_disease_location.options.lung',
        value: 'lung',
      },
      {
        label: 'questionnaire.questions.urine.7_disease_location.options.bone',
        value: 'bone',
      },
      {
        label: 'questionnaire.questions.urine.7_disease_location.options.liver',
        value: 'liver',
      },
      {
        label: 'questionnaire.questions.urine.7_disease_location.options.brain',
        value: 'brain',
      },
      {
        label: 'questionnaire.questions.urine.7_disease_location.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.disease_status,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'remission',
      },
    ],
  },
  {
    q: 'Q15',
    id: ID.disease_measurable,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.urine.8_local_regional_disease_measurable.title',
    info: {
      title: 'questionnaire.questions.urine.8_local_regional_disease_measurable.info.title',
      content: 'questionnaire.questions.urine.8_local_regional_disease_measurable.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.urine.8_local_regional_disease_measurable.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.urine.8_local_regional_disease_measurable.options.no_but_it_can_be',
        value: 'no_but_it_can_be',
      },
      {
        label: 'questionnaire.questions.urine.8_local_regional_disease_measurable.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.urine.8_local_regional_disease_measurable.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: ID.disease_status,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'remission',
      },
    ],
    isRequired: true,
  },
  {
    q: 'Q16',
    id: ID.tumor_size_mm,
    type: NUMBER_INPUT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.urine.9_local_regional_tumor_size_mm.title',
    subtitle: 'questionnaire.questions.urine.9_local_regional_tumor_size_mm.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [],
    conditions: [
      {
        ifId: ID.disease_measurable,
        type: EQUAL_TO,
        ifValue: 'yes',
      },
    ],
    numberType: numberType.int,
  },
  {
    q: 'Q18',
    id: ID.metastasis_location_brain_leptomeningeal,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.urine.11_metastasis_location_brain_leptomeningeal.title',
    subtitle: 'questionnaire.questions.urine.11_metastasis_location_brain_leptomeningeal.subtitle',
    info: {
      title: 'questionnaire.questions.urine.11_metastasis_location_brain_leptomeningeal.info.title',
      content: 'questionnaire.questions.urine.11_metastasis_location_brain_leptomeningeal.info.content',
    },
    options: yesAndNoOptions,
    conditions: [
      {
        ifId: ID.disease_location,
        type: CONTAIN_ONE_OF,
        ifValue: ['brain'],
      },
    ],
  },
  {
    q: 'Q19',
    id: ID.brain_metastasis_active,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.urine.12_brain_metastasis_active.title',
    subtitle: 'questionnaire.questions.urine.12_brain_metastasis_active.subtitle',
    info: {
      title: 'questionnaire.questions.urine.12_brain_metastasis_active.info.title',
      content: 'questionnaire.questions.urine.12_brain_metastasis_active.info.content',
    },
    options: yesAndNoOptions,
    conditions: [
      {
        ifId: ID.disease_location,
        type: CONTAIN_ONE_OF,
        ifValue: ['brain'],
      },
    ],
  },
  {
    q: 'Q21',
    id: ID.has_biopsy,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.urine.13_has_biopsy.title',

    info: {
      title: 'questionnaire.questions.urine.13_has_biopsy.info.title',
      content: 'questionnaire.questions.urine.13_has_biopsy.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.urine.13_has_biopsy.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.urine.13_has_biopsy.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.urine.13_has_biopsy.options.biopsy_possible',
        value: 'biopsy_possible',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q21b',
    id: ID.msi,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.molecular,
    order: 0,
    title: 'questionnaire.questions.urine.msi.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.urine.msi.options.stable',
        value: 'stable',
      },
      {
        label: 'questionnaire.questions.urine.msi.options.unstable',
        value: 'unstable',
      },
      {
        label: 'questionnaire.questions.urine.msi.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: ID.has_biopsy,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['no', 'biopsy_possible'],
      },
    ],
  },
  // {
  //   q: 'Q90',
  //   id: ID.pd_l1_carrier_status,
  //   type: SINGLE_OPTION_SELECT,
  //   section: SECTIONS.MY_CANCER,
  //   bgIcon: i.molecular,
  //   order: 0,
  //   title: 'questionnaire.questions.urine.pd_l1_carrier_status.title',
  //   info: {
  //     title: 'questionnaire.questions.urine.pd_l1_carrier_status.info.title',
  //     content: 'questionnaire.questions.urine.pd_l1_carrier_status.info.content',
  //   },
  //   options: [
  //     {
  //       label: 'questionnaire.questions.urine.pd_l1_carrier_status.options.positive',
  //       value: 'yes',
  //     },
  //     {
  //       label: 'questionnaire.questions.urine.pd_l1_carrier_status.options.negative',
  //       value: 'no',
  //     },
  //     {
  //       label: 'questionnaire.questions.urine.pd_l1_carrier_status.options.not_sure',
  //       value: 'not_sure',
  //     },
  //   ],
  //   conditions: [
  //     {
  //       ifId: ID.has_biopsy,
  //       type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
  //       ifValue: ['no', 'biopsy_possible'],
  //     },
  //   ],
  // },
  pdL1ExpressionByQNumber('Q90a'),
  {
    q: 'Q80',
    id: ID.ngs,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.molecular,
    order: 0,
    title: 'questionnaire.questions.urine.39_ngs.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.urine.39_ngs.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.urine.39_ngs.options.yes_waiting_for_the_results',
        value: 'yes_waiting_for_the_results',
      },
      {
        label: 'questionnaire.questions.urine.39_ngs.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.urine.39_ngs.options.no_but_planning',
        value: 'no_but_planning',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q22',
    id: BIOMARKERS_QUESTION_ID,
    type: BIOMARKERS,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.molecular,
    order: 0,
    title: 'questionnaire.questions.urine.14_biomarkers.title',
    subtitle: 'questionnaire.questions.urine.14_biomarkers.subtitle',
    placeholder: 'questionnaire.questions.biomarkers.placeholder',
    info: {
      title: '',
      content: '',
    },
    conditions: [
      {
        ifId: ID.has_biopsy,
        type: CONTAINS_ONE_OF_OR_SKIPPED,
        ifValue: ['yes'],
      },
    ],
  },
  {
    q: 'Q10',
    id: ID.hr_status,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.urine.15_hr_status.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.urine.15_hr_status.options.hr_positive',
        value: 'hr_positive',
      },
      {
        label: 'questionnaire.questions.urine.15_hr_status.options.hr_negative',
        value: 'hr_negative',
      },
      {
        label: 'questionnaire.questions.urine.15_hr_status.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: ID.has_biopsy,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['no', 'biopsy_possible'],
      },
    ],
  },
  {
    q: 'Q23',
    id: ID.disease_resectable,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.urine.16_disease_resectable.title',

    info: {
      title: 'questionnaire.questions.urine.16_disease_resectable.info.title',
      content: 'questionnaire.questions.urine.16_disease_resectable.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.urine.16_disease_resectable.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.urine.16_disease_resectable.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.urine.16_disease_resectable.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: ID.disease_status,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'remission',
      },
      {
        ifId: ID.current_stage,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['4.1', '4.2'],
      },
    ],
  },
  {
    q: 'Q12',
    id: ID.had_procedure_for_uterine_cancer,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.urine.17_had_procedure_for_uterine_cancer.title',

    info: {
      title: '',
      content: '',
    },
    options: yesAndNoOptions,
    conditions: [],
  },
  {
    q: 'Q70',
    id: ID.procedures,
    type: PROCEDURES,
    section: SECTIONS.TREATMENT,
    bgIcon: '',
    order: 0,
    title: 'questionnaire.questions.urine.18_procedures.title',
    subtitle: 'questionnaire.questions.urine.18_procedures.subtitle',
    footer: 'questionnaire.questions.urine.18_procedures.footer',
    requiredSubQuestionsId: [ID.procedures_received],
    info: {
      title: '',
      content: '',
    },
    submitButtonLabel: 'questionnaire.questions.urine.18_procedures.button.submit',
    options: [
      {
        iconText: 'A',
        label: 'questionnaire.questions.urine.18_procedures.options.procedure_name',
        value: null,
      },
      {
        iconText: 'B',
        label: 'questionnaire.questions.urine.18_procedures.options.procedure_took_place',
        value: null,
      },
      {
        iconText: 'C',
        label: 'questionnaire.questions.urine.18_procedures.options.procedure_outcome',
        value: null,
      },
    ],
    shouldAutoOpenSubQuestions: [{
      ifId: ID.had_procedure_for_uterine_cancer,
      type: EQUAL_TO,
      ifValue: 'yes',
    }],
    conditions: [
      {
        ifId: ID.had_procedure_for_uterine_cancer,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'no',
      },
    ],
    isRequired: true,
    subQuestions: [
      {
        q: 'Q70a',
        id: ID.procedures_received,
        type: MULTIPLE_OPTION_SEARCH_SELECT,
        viewType: OPTIONS_LIST_WITH_SHOW_MORE,
        section: SECTIONS.TREATMENT,
        bgIcon: i.treatment,
        order: 0,
        titleIconText: 'A',
        title: 'questionnaire.questions.urine.18_procedures.sub_questions.procedures_received.title',
        subtitle: '',
        placeholder: 'questionnaire.questions.procedures_received.placeholder',
        info: {
          title: '',
          content: '',
        },
        options: {
          dropDownOptions: [
            {
              label: 'Surgery-Removal of the uterus (Hysterectomy)',
              value: 'hysterectomy',
            },
            {
              label: 'Surgery-Removal of the fallopian tubes and ovaries (Salpingo-Oophorectomy)',
              value: 'salpingo_oophorectomy',
            },
            {
              label: 'Surgery-Tumor debulking (removal of the tumor or parts of it)',
              value: 'debulking',
            },
            {
              label: 'EBRT (external beam radiation therapy)',
              value: 'ebrt',
            },
            {
              label: 'Brachytherapy/ Internal radiation',
              value: 'brachytherapy',
            },
            {
              label: 'Removal of isolated metastases by surgery (resection)',
              value: 'metastasis_resection',
            },
            {
              label: 'Removal of isolated metastases by ablation',
              value: 'ablation',
            },
          ],
          radioOptions: [
            {
              label: 'Other procedure not on the list',
              value: 'other',
            },
          ],
        },
        conditions: [],
      },
      {
        q: 'Q70b',
        id: ID.procedure_date,
        type: MONTH_PICKER,
        section: SECTIONS.TREATMENT,
        bgIcon: '',
        order: 0,
        titleIconText: 'B',
        title: 'questionnaire.questions.urine.18_procedures.sub_questions.procedure_date.title',
        subtitle: '',
        info: {
          title: '',
          content: '',
        },
        options: [],
        conditions: [],
        dateType: {
          type: dateType.stopTreatment,
        },
      },
      {
        q: 'Q70c',
        id: ID.procedure_outcome,
        type: SINGLE_OPTION_SELECT,
        section: SECTIONS.TREATMENT,
        bgIcon: '',
        order: 0,
        titleIconText: 'C',
        title: 'questionnaire.questions.urine.18_procedures.sub_questions.procedure_outcome.title',
        subtitle: '',
        info: {
          title: '',
          content: '',
        },
        disableNextOnOptionSelect: false,
        options: [
          {
            label: 'questionnaire.questions.urine.18_procedures.sub_questions.procedure_outcome.options.disease_free',
            value: 'disease_free',
          },
          {
            label:
              'questionnaire.questions.urine.18_procedures.sub_questions.procedure_outcome.options.local_residual_disease',
            value: 'local_residual_disease',
          },
          {
            label:
              'questionnaire.questions.urine.18_procedures.sub_questions.procedure_outcome.options.residual_systemic_disease',
            value: 'residual_systemic_disease',
          },
        ],
        answerResetable: true,
        conditions: [],
      },
    ],
  },

  {
    q: 'Q60',
    id: ID.treatments,
    type: TREATMENT_HISTORY,
    section: SECTIONS.TREATMENT,
    bgIcon: '',
    order: 0,
    title: 'questionnaire.questions.urine.20_treatments.title',

    requiredSubQuestionsId: [ID.drugs_received],
    footer: 'questionnaire.questions.urine.20_treatments.footer',
    info: {
      title: '',
      content: '',
    },
    submitButtonLabel: 'questionnaire.questions.urine.20_treatments.button.submit',
    options: [
      {
        iconText: 'A',
        label: 'questionnaire.questions.urine.20_treatments.options.treatment_name',
        value: null,
      },
      {
        iconText: 'B',
        label: 'questionnaire.questions.urine.20_treatments.options.course_received',
        value: null,
      },
      {
        iconText: 'C',
        label: 'questionnaire.questions.urine.20_treatments.options.when_treatment_stopped',
        value: null,
      },
      {
        iconText: 'D',
        label: 'questionnaire.questions.urine.20_treatments.options.reason_treatment_stopped',
        value: null,
      },
    ],
    // shouldAutoOpenSubQuestions: [{
    //   ifId: ID.treatment_for_uterine_cancer,
    //   type: EQUAL_TO,
    //   ifValue: 'yes',
    // }],
    conditions: [
      {
        ifId: ID.treatment_for_uterine_cancer,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'no',
      },
    ],
    isRequired: true,
    subQuestions: [
      {
        q: 'Q60a',
        id: ID.drugs_received,
        type: OPTIONS_FROM_SERVER,
        viewType: OPTIONS_LIST_WITH_SHOW_MORE,
        section: SECTIONS.TREATMENT,
        bgIcon: i.treatment,
        order: 0,
        titleIconText: 'A',
        title: 'questionnaire.questions.urine.20_treatments.sub_questions.drugs_received.title',
        subtitle: 'questionnaire.questions.urine.20_treatments.sub_questions.drugs_received.subtitle',
        placeholder: 'questionnaire.questions.drugs_received.placeholder',
        info: {
          title: '',
          content: '',
        },
        questionProps: {
          isSearchableByOptionGroupName: true,
        },
      },
      {
        q: 'Q60f',
        id: ID.cycles,
        type: SINGLE_OPTION_SELECT,
        section: SECTIONS.TREATMENT,
        title: 'questionnaire.questions.urine.20_treatments.sub_questions.cycles.title',
        disableNextOnOptionSelect: false,
        options: [...treatmentCyclesOptions],
      },
      {
        q: 'Q60b',
        id: ID.treatment_admin,
        type: SINGLE_OPTION_SELECT,
        section: SECTIONS.TREATMENT,
        bgIcon: i.treatment,
        order: 0,
        titleIconText: 'B',
        title: 'questionnaire.questions.urine.20_treatments.sub_questions.treatment_admin.title',
        subtitle: '',
        info: {
          title: '',
          content: '',
        },
        disableNextOnOptionSelect: true,
        options: [
          {
            label: 'questionnaire.questions.urine.20_treatments.sub_questions.treatment_admin.options.neoadjuvant',
            value: 'neoadjuvant',
          },
          {
            label: 'questionnaire.questions.urine.20_treatments.sub_questions.treatment_admin.options.adjuvant',
            value: 'adjuvant',
          },
          {
            label: 'questionnaire.questions.urine.20_treatments.sub_questions.treatment_admin.options.systemic',
            value: 'systemic',
          },
        ],
        conditions: [],
      },
      {
        q: 'Q60c',
        id: ID.treatment_end_date,
        type: MONTH_PICKER,
        section: SECTIONS.TREATMENT,
        bgIcon: '',
        order: 0,
        titleIconText: 'C',
        title: 'questionnaire.questions.urine.20_treatments.sub_questions.treatment_end_date.title',
        subtitle: '',
        info: {
          title: '',
          content: '',
        },
        options: [],
        conditions: [],
        dateType: {
          type: dateType.stopTreatment,
        },
      },
      {
        q: 'Q60d',
        id: ID.treatment_stop_reason,
        type: SINGLE_OPTION_SELECT,
        section: SECTIONS.TREATMENT,
        bgIcon: '',
        order: 0,
        titleIconText: 'D',
        title: 'questionnaire.questions.urine.20_treatments.sub_questions.treatment_stop_reason.title',
        subtitle: '',
        info: {
          title: '',
          content: '',
        },
        disableNextOnOptionSelect: false,
        options: [
          {
            label:
              'questionnaire.questions.urine.20_treatments.sub_questions.treatment_stop_reason.options.treatment_ongoing',
            value: 'treatment_ongoing',
          },
          {
            label:
              'questionnaire.questions.urine.20_treatments.sub_questions.treatment_stop_reason.options.end_of_protocol',
            value: 'end_of_protocol',
          },
          {
            label:
              'questionnaire.questions.urine.20_treatments.sub_questions.treatment_stop_reason.options.progression',
            value: 'progression',
          },
          {
            label:
              'questionnaire.questions.urine.20_treatments.sub_questions.treatment_stop_reason.options.no_response',
            value: 'no_response',
          },
          {
            label:
              'questionnaire.questions.urine.20_treatments.sub_questions.treatment_stop_reason.options.loss_of_response',
            value: 'loss_of_response',
          },
          {
            label: 'questionnaire.questions.urine.20_treatments.sub_questions.treatment_stop_reason.options.remission',
            value: 'remission',
          },
          {
            label: 'questionnaire.questions.urine.20_treatments.sub_questions.treatment_stop_reason.options.toxicity',
            value: 'toxicity',
          },
          {
            label: 'questionnaire.questions.urine.20_treatments.sub_questions.treatment_stop_reason.options.other_medical_conditions',
            value: 'other_medical_conditions',
          },
          {
            label: 'questionnaire.questions.urine.20_treatments.sub_questions.treatment_stop_reason.options.dosing_issues',
            value: 'dosing_issues',
          },
          {
            label: 'questionnaire.questions.urine.20_treatments.sub_questions.treatment_stop_reason.options.scheduling',
            value: 'scheduling',
          },
          {
            label: 'questionnaire.questions.urine.20_treatments.sub_questions.treatment_stop_reason.options.travel_limitation',
            value: 'travel_limitation',
          },
          {
            label: 'questionnaire.questions.urine.20_treatments.sub_questions.treatment_stop_reason.options.financial_reasons_or_insurance',
            value: 'financial_reasons_or_insurance',
          },
          {
            label: 'questionnaire.questions.urine.20_treatments.sub_questions.treatment_stop_reason.options.other',
            value: 'other',
          },
        ],
        conditions: [],
      },
    ],
    // title: '',
    // info: {
    //   title: '',
    //   content: '',
    // },
    // footer: '',
    // type: TREATMENT_HISTORY,
    // section: SECTIONS.TREATMENT,
    // bgIcon: '',
    // order: 0,
    // conditions: [
    //   {
    //     ifId: ID.treatment_for_uterine_cancer,
    //     type: NOT_EQUAL_TO_OR_SKIPPED,
    //     ifValue: 'no',
    //   },
    // ],
    // subQuestionsIds: [
    //   ID.drugs_received,
    //   ID.treatment_stop_reason,
    //   ID.treatment_admin,
    // ],
    // stageIds: [ID.current_stage],
    // surgeryId: ID.had_procedure_for_uterine_cancer,
    // options: [],
    // subQuestionsOptions: {
    //   treatment_stop_reason: [
    //     {
    //       label:
    //         'questionnaire.questions.treatment_stop_reason.options.no_response',
    //       value: 'no_response',
    //     },
    //     {
    //       label:
    //         'questionnaire.questions.treatment_stop_reason.options.end_of_protocol',
    //       value: 'end_of_protocol',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.toxicity',
    //       value: 'toxicity',
    //     },
    //     {
    //       label:
    //         'questionnaire.questions.treatment_stop_reason.options.progression',
    //       value: 'progression',
    //     },
    //     {
    //       label:
    //         'questionnaire.questions.treatment_stop_reason.options.loss_of_response',
    //       value: 'loss_of_response',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.remission',
    //       value: 'remission',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.other',
    //       value: 'other',
    //     },
    //   ],
    //   taking_state: takingStateOptions,
    //   treatment_admin: [
    //     {
    //       label: 'questionnaire.questions.crc_2.treatments.sub_questions.treatment_admin.options.neoadjuvant',
    //       value: 'neoadjuvant',
    //     },
    //     {
    //       label: 'questionnaire.questions.crc_2.treatments.sub_questions.treatment_admin.options.adjuvant',
    //       value: 'adjuvant',
    //     },
    //     {
    //       label: 'questionnaire.questions.crc_2.treatments.sub_questions.treatment_admin.options.systemic',
    //       value: 'systemic',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatments.options.i_dont_know',
    //       value: 'i_dont_know',
    //     },
    //   ],
    // },
  },
  {
    q: 'Q44',
    id: ID.current_treatment_systemic_steroids,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.urine.21_current_treatment_systemic_steroids.title',
    subtitle: 'questionnaire.questions.urine.21_current_treatment_systemic_steroids.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.urine.21_current_treatment_systemic_steroids.options.none',
        value: 'none',
      },
      {
        label: 'questionnaire.questions.urine.21_current_treatment_systemic_steroids.options.under_10_mg_daily',
        value: 'under_10_mg_daily',
      },
      {
        label: 'questionnaire.questions.urine.21_current_treatment_systemic_steroids.options.over_10_mg_daily',
        value: 'over_10_mg_daily',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q44a',
    id: ID.systemic_steroids_treatment_end_date,
    type: MONTH_PICKER,
    section: SECTIONS.TREATMENT,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.urine.22_systemic_steroids_treatment_end_date.title',

    info: {
      title: '',
      content: '',
    },
    options: [],
    conditions: [
      {
        ifId: ID.current_treatment_systemic_steroids,
        type: CONTAIN_ONE_OF,
        ifValue: ['under_10_mg_daily', 'over_10_mg_daily'],
      },
    ],
    dateType: {
      type: dateType.stopTreatment,
    },
  },
  {
    q: 'Q45',
    id: ID.ecog,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.urine.23_ecog.title',

    info: {
      title: 'questionnaire.questions.urine.23_ecog.info.title',
      content: 'questionnaire.questions.urine.23_ecog.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.urine.23_ecog.options.0',
        value: '0',
      },
      {
        label: 'questionnaire.questions.urine.23_ecog.options.1',
        value: '1',
      },
      {
        label: 'questionnaire.questions.urine.23_ecog.options.2',
        value: '2',
      },
      {
        label: 'questionnaire.questions.urine.23_ecog.options.3',
        value: '3',
      },
      {
        label: 'questionnaire.questions.urine.23_ecog.options.4',
        value: '4',
      },
    ],
    conditions: [],
    isRequired: true,
  },
  {
    q: 'Q46',
    id: ID.other_medical_condition,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.urine.24_other_medical_condition.title',
    subtitle: 'questionnaire.questions.urine.24_other_medical_condition.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.urine.24_other_medical_condition.options.no_other_health_issues',
        value: 'no_other_health_issues',
        exclusive: true,
      },
      {
        label: 'questionnaire.questions.urine.24_other_medical_condition.options.liver',
        value: 'liver',
      },
      {
        label: 'questionnaire.questions.urine.24_other_medical_condition.options.heart',
        value: 'heart',
      },
      {
        label: 'questionnaire.questions.urine.24_other_medical_condition.options.kidney',
        value: 'kidney',
      },
      {
        label: 'questionnaire.questions.urine.24_other_medical_condition.options.autoimmune',
        value: 'autoimmune',
      },
      {
        label: 'questionnaire.questions.urine.24_other_medical_condition.options.mental',
        value: 'mental',
      },
      {
        label: 'questionnaire.questions.urine.24_other_medical_condition.options.allergies',
        value: 'allergies',
      },
      {
        label: 'questionnaire.questions.urine.24_other_medical_condition.options.chronic_infection_or_inflammation',
        value: 'chronic_infection_or_inflammation',
      },
      {
        label: 'questionnaire.questions.urine.24_other_medical_condition.options.gastrointestinal',
        value: 'gastrointestinal',
      },
      {
        label: 'questionnaire.questions.urine.24_other_medical_condition.options.other_cancer_type',
        value: 'other_cancer_type',
      },
      {
        label: 'questionnaire.questions.urine.24_other_medical_condition.options.anemia',
        value: 'anemia',
      },
      {
        label: 'questionnaire.questions.urine.24_other_medical_condition.options.thyroid',
        value: 'thyroid',
      },
      {
        label: 'questionnaire.questions.urine.24_other_medical_condition.options.hypertension',
        value: 'hypertension',
      },
      {
        label: 'questionnaire.questions.urine.24_other_medical_condition.options.epilepsy',
        value: 'epilepsy',
      },
      {
        label: 'questionnaire.questions.urine.24_other_medical_condition.options.diabetes',
        value: 'diabetes',
      },
      {
        label: 'questionnaire.questions.urine.24_other_medical_condition.options.organ_transplant',
        value: 'organ_transplant',
      },
      {
        label: 'questionnaire.questions.urine.24_other_medical_condition.options.hsct',
        value: 'hsct',
      },
      {
        label: 'questionnaire.questions.urine.24_other_medical_condition.options.extreme_obesity',
        value: 'extreme_obesity',
      },
      {
        label: 'questionnaire.questions.urine.24_other_medical_condition.options.stroke',
        value: 'stroke',
      },
      {
        label: 'questionnaire.questions.urine.24_other_medical_condition.options.lung',
        value: 'lung',
      },
      {
        label: 'questionnaire.questions.urine.24_other_medical_condition.options.neuropathy',
        value: 'neuropathy',
      },
      {
        label: 'questionnaire.questions.urine.24_other_medical_condition.options.bleeding_disorder',
        value: 'bleeding_disorder',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q48',
    id: ID.omc_liver,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.urine.25_omc_liver.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.urine.25_omc_liver.options.elevated_alt_ast',
        value: 'elevated_alt_ast',
      },
      {
        label: 'questionnaire.questions.urine.25_omc_liver.options.elevated_bilirubin',
        value: 'elevated_bilirubin',
      },
      {
        label: 'questionnaire.questions.urine.25_omc_liver.options.cirrhosis',
        value: 'cirrhosis',
      },
      {
        label: 'questionnaire.questions.urine.25_omc_liver.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['liver'],
      },
    ],
  },
  {
    q: 'Q49',
    id: ID.omc_heart,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.urine.26_omc_heart.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.urine.26_omc_heart.options.class_at_least_nyha_ii',
        value: 'class_at_least_nyha_ii',
      },
      {
        label: 'questionnaire.questions.urine.26_omc_heart.options.condition_at_least_grade_2',
        value: 'condition_at_least_grade_2',
      },
      {
        label: 'questionnaire.questions.urine.26_omc_heart.options.cardiac_ventricular_arrhythmia',
        value: 'cardiac_ventricular_arrhythmia',
      },
      {
        label: 'questionnaire.questions.urine.26_omc_heart.options.heart_failure',
        value: 'heart_failure',
      },
      {
        label: 'questionnaire.questions.urine.26_omc_heart.options.lvef_under_50_percent',
        value: 'lvef_under_50_percent',
      },
      {
        label: 'questionnaire.questions.urine.26_omc_heart.options.qtcf_abnormal',
        value: 'qtcf_abnormal',
      },
      {
        label: 'questionnaire.questions.urine.26_omc_heart.options.angina',
        value: 'angina',
      },
      {
        label: 'questionnaire.questions.urine.26_omc_heart.options.myocardial_infarction',
        value: 'myocardial_infarction',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['heart'],
      },
    ],
  },
  {
    q: 'Q50',
    id: ID.omc_stroke_date,
    type: MONTH_PICKER,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.urine.27_omc_stroke_date.title',

    info: {
      title: '',
      content: '',
    },
    options: [],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['stroke'],
      },
    ],
    dateType: {
      type: dateType.stopTreatment,
    },
  },
  {
    q: 'Q51',
    id: ID.omc_kidney,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.urine.28_omc_kidney.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.urine.28_omc_kidney.options.low_glomerular_filtration_rate',
        value: 'low_glomerular_filtration_rate',
      },
      {
        label: 'questionnaire.questions.urine.28_omc_kidney.options.dialysis',
        value: 'dialysis',
      },
      {
        label: 'questionnaire.questions.urine.28_omc_kidney.options.kidney_stones',
        value: 'kidney_stones',
      },
      {
        label: 'questionnaire.questions.urine.28_omc_kidney.options.creatinine_high',
        value: 'creatinine_high',
      },
      {
        label: 'questionnaire.questions.urine.28_omc_kidney.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['kidney'],
      },
    ],
  },
  {
    q: 'Q52',
    id: ID.omc_autoimmune,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.urine.29_omc_autoimmune.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.urine.29_omc_autoimmune.options.congenital_or_acquired_immunosuppression',
        value: 'congenital_or_acquired_immunosuppression',
      },
      {
        label: 'questionnaire.questions.urine.29_omc_autoimmune.options.hepatitis',
        value: 'hepatitis',
      },
      {
        label: 'questionnaire.questions.urine.29_omc_autoimmune.options.granulomatous_disease',
        value: 'granulomatous_disease',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['autoimmune'],
      },
    ],
  },
  {
    q: 'Q53',
    id: ID.omc_mental,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.urine.30_omc_mental.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.urine.30_omc_mental.options.dementia',
        value: 'dementia',
      },
      {
        label: 'questionnaire.questions.urine.30_omc_mental.options.neuropsychyatric',
        value: 'neuropsychyatric',
      },
      {
        label: 'questionnaire.questions.urine.30_omc_mental.options.depression',
        value: 'depression',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['mental'],
      },
    ],
  },
  {
    q: 'Q54',
    id: ID.omc_allergy,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.urine.31_omc_allergy.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.urine.31_omc_allergy.options.interferon',
        value: 'interferon',
      },
      {
        label: 'questionnaire.questions.urine.31_omc_allergy.options.metformin',
        value: 'metformin',
      },
      {
        label: 'questionnaire.questions.urine.31_omc_allergy.options.vitD',
        value: 'vitD',
      },
      {
        label: 'questionnaire.questions.urine.31_omc_allergy.options.penicillin',
        value: 'penicillin',
      },
      {
        label: 'questionnaire.questions.urine.31_omc_allergy.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['allergies'],
      },
    ],
  },
  {
    q: 'Q55',
    id: ID.omc_chronic_infection_inflammation,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.urine.32_omc_chronic_infection_inflammation.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.urine.32_omc_chronic_infection_inflammation.options.hepatitis',
        value: 'hepatitis',
      },
      {
        label: 'questionnaire.questions.urine.32_omc_chronic_infection_inflammation.options.hiv',
        value: 'hiv',
      },
      {
        label: 'questionnaire.questions.urine.32_omc_chronic_infection_inflammation.options.other',
        value: 'other',
      },
      {
        label: 'questionnaire.questions.urine.32_omc_chronic_infection_inflammation.options.uncontrolled_infection',
        value: 'uncontrolled_infection',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['chronic_infection_or_inflammation'],
      },
    ],
  },
  {
    q: 'Q58',
    id: ID.omc_gastro_problem,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.urine.33_omc_gastro_problem.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.urine.33_omc_gastro_problem.options.gastrointestinal_bleeding',
        value: 'gastrointestinal_bleeding',
      },
      {
        label: 'questionnaire.questions.urine.33_omc_gastro_problem.options.crohns',
        value: 'crohns',
      },
      {
        label: 'questionnaire.questions.urine.33_omc_gastro_problem.options.colitis',
        value: 'colitis',
      },
      {
        label: 'questionnaire.questions.urine.33_omc_gastro_problem.options.ibs',
        value: 'ibs',
      },
      {
        label: 'questionnaire.questions.urine.33_omc_gastro_problem.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['gastrointestinal'],
      },
    ],
  },
  {
    q: 'Q59',
    id: ID.omc_lung_disease,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.urine.34_omc_lung_disease.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.urine.34_omc_lung_disease.options.interstitial_lung_disease',
        value: 'interstitial_lung_disease',
      },
      {
        label: 'questionnaire.questions.urine.34_omc_lung_disease.options.pneumonitis',
        value: 'pneumonitis',
      },
      {
        label: 'questionnaire.questions.urine.34_omc_lung_disease.options.dyspnea',
        value: 'dyspnea',
      },
      {
        label: 'questionnaire.questions.urine.34_omc_lung_disease.options.tuberculosis',
        value: 'tuberculosis',
      },
      {
        label: 'questionnaire.questions.urine.34_omc_lung_disease.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['lung'],
      },
    ],
  },
  {
    q: 'Q56',
    id: ID.omc_abnormal_blood_results,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.urine.35_omc_abnormal_blood_results.title',
    subtitle: 'questionnaire.questions.urine.35_omc_abnormal_blood_results.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.urine.35_omc_abnormal_blood_results.options.none',
        value: 'none',
        exclusive: true,
      },
      {
        label: 'questionnaire.questions.urine.35_omc_abnormal_blood_results.options.low_wbc',
        value: 'low_wbc',
      },
      {
        label: 'questionnaire.questions.urine.35_omc_abnormal_blood_results.options.low_hemoglobin',
        value: 'low_hemoglobin',
      },
      {
        label: 'questionnaire.questions.urine.35_omc_abnormal_blood_results.options.low_neutrophil_count',
        value: 'low_neutrophil_count',
      },
      {
        label: 'questionnaire.questions.urine.35_omc_abnormal_blood_results.options.low_platelet_count',
        value: 'low_platelet_count',
      },
      {
        label: 'questionnaire.questions.urine.35_omc_abnormal_blood_results.options.low_potassium',
        value: 'low_potassium',
      },
      {
        label: 'questionnaire.questions.urine.35_omc_abnormal_blood_results.options.low_magnesium',
        value: 'low_magnesium',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q47',
    id: ID.other_medications,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.urine.36_other_medications.title',
    subtitle: 'questionnaire.questions.urine.36_other_medications.subtitle',
    info: {
      title: 'questionnaire.questions.urine.36_other_medications.info.title',
      content: 'questionnaire.questions.urine.36_other_medications.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.urine.36_other_medications.options.cyp3a4_inhibitors',
        value: 'cyp3a4_inhibitors',
      },
      {
        label: 'questionnaire.questions.urine.36_other_medications.options.cyp3a4_inducers',
        value: 'cyp3a4_inducers',
      },
      {
        label: 'questionnaire.questions.urine.36_other_medications.options.immunosuppressive_medications',
        value: 'immunosuppressive_medications',
      },
      {
        label: 'questionnaire.questions.urine.36_other_medications.options.warfarin',
        value: 'warfarin',
      },
      {
        label: 'questionnaire.questions.urine.36_other_medications.options.anticonvulsants',
        value: 'anticonvulsants',
      },
      {
        label: 'questionnaire.questions.urine.36_other_medications.options.anti_retroviral_therapy',
        value: 'anti_retroviral_therapy',
      },
      {
        label: 'questionnaire.questions.urine.36_other_medications.options.none',
        value: 'none',
        exclusive: true,
      },
    ],
    conditions: [],
  },
  initialDiagnosisDate('Q5'),
  userType,
  dateOfBirth,
  race,
  {
    q: 'Q3',
    id: ID.is_pregnant,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.ABOUT_ME,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.urine.37_is_pregnant.title',

    info: {
      title: 'questionnaire.questions.urine.37_is_pregnant.info.title',
      content: 'questionnaire.questions.urine.37_is_pregnant.info.content',
    },
    options: yesAndNoOptions,
    conditions: [
      // {
      //   ifId: ID.gender,
      //   type: CONTAIN_ONE_OF,
      //   ifValue: ['female']
      // },
      {
        type: AGE_LESS_THAN,
        ifValue: 55,
      },
      {
        ifId: ID.menopausal_status,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['post_menopausal', 'pre_menopausal_with_ovarian_suppression'],
      },
    ],
  },
  {
    q: 'Q14',
    id: ID.is_breastfeeding,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.ABOUT_ME,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.urine.38_is_breastfeeding.title',

    info: {
      title: 'questionnaire.questions.urine.38_is_breastfeeding.info.title',
      content: 'questionnaire.questions.urine.38_is_breastfeeding.info.content',
    },
    options: yesAndNoOptions,
    conditions: [
      // {
      //   ifId: ID.gender,
      //   type: CONTAIN_ONE_OF,
      //   ifValue: ['female']
      // },
      {
        type: AGE_LESS_THAN,
        ifValue: 55,
      },
      {
        ifId: ID.menopausal_status,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['post_menopausal', 'pre_menopausal_with_ovarian_suppression'],
      },
    ],
  },
  haveInsurance,
  insurance,
  preferredLocation,
  travelLimit,
];

export default Questions;
