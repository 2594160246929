import {
  copyToClipboard, DOM, isMobile, sendEmail,
} from 'new-ui/functions';
import './Iframe.css';

import xIcon from 'new-ui/assets/icons/x.svg';
import linkIcon from 'new-ui/assets/icons/link.svg';
import emailIcon from 'new-ui/assets/icons/email_share.svg';
import { INTERCOM_EVENTS } from 'new-ui/constants';

class Iframe {
  constructor(settings = {}) {
    const DEFAULTS = {
      url: 'http://wikipedia.com',
    };

    this.settings = Object.assign(DEFAULTS, settings);
  }

  show(src = this.settings.url) {
    this.$iframe = DOM('.iframe-overlay', {
      '/.child.top': {
        '/.close': {
          '/img': {
            src: xIcon,
          },
          onclick: this.hide.bind(this),
        },
      },
      '/.child.middle': {
        name: 'middle',
        '/iframe': {
          src,
        },
      },
      '/.child.bottom': {
        '/.share': {
          '/.share-title': {
            innerHTML: 'Share',
          },
          '/.share-icon.link': {
            onclick: this.shareLink.bind(this, src),
            '/img': {
              src: linkIcon,
            },
          },
          '/.share-icon.email': {
            onclick: this.shareEmail.bind(this, src),
            '/img': {
              src: emailIcon,
            },
          },
        },
      },
    });
    document.body.appendChild(this.$iframe);
  }

  hide() {
    if (this.$iframe) document.body.removeChild(this.$iframe);
  }

  async shareLink(src) {
    const url = src.split('?')[0];
    window.app.intercom.sendEvent(INTERCOM_EVENTS.SHARE.BLOGPOST, {
      blog: url,
      device: isMobile() ? 'mobile' : 'desktop',
      content: 'link',
    });
    if (navigator.share) {
      await navigator.share({
        title: 'Interesting article I found!',
        text: 'I just found an interesting article that might interest you',
        url,
      });
    } else {
      const copied = await copyToClipboard(url);
      if (copied) {
        window.app.alert('Link copied succesfully');
      }
    }
  }

  shareEmail(src) {
    const url = src.split('?')[0];
    window.app.intercom.sendEvent(INTERCOM_EVENTS.SHARE.BLOGPOST, {
      blog: url,
      device: isMobile() ? 'mobile' : 'desktop',
      content: 'email',
    });
    sendEmail('', 'Interesting article I found!', `Hi,\n\rI just found an interesting article that might interest you:\n${url}&source=sharing_email\n\rBest Regards!`);
  }
}

window._iframe = Iframe;

export default Iframe;
