import React, { useState } from 'react';
import DeleteIcon from 'new-ui/assets/icons/delete_icon.svg';
import './style.css';
import { EVENT_TYPES } from '../../options';
import { useTranslation } from 'utils/modifiedTranslation';

const UpcomingEvent = ({ item, onDelete }) => {
  const { t } = useTranslation();
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);

  const handleToggleDelete = () => {
    setIsDeleteVisible(prevState => !prevState);
  };

  const { id, type, date } = item;

  return (
    <div className='upcoming-event' style={{ backgroundColor: EVENT_TYPES[type].color }} onClick={handleToggleDelete}>
      <span className='label'>{t(EVENT_TYPES[type].label)}</span>
      <span className='date'>{date}</span>
      {isDeleteVisible && (
        <span className='delete_btn' onClick={(e) => { 
          e.stopPropagation();
          onDelete(id); 
        }}>
          <img src={DeleteIcon} alt='icon' />
        </span>
      )}
    </div>
  );
};

export default UpcomingEvent;