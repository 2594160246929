import configs from 'configs';
import request from 'functions/api/request';
import { authHeaders } from 'functions/authHeader';
import getUrlWithQueryString from 'functions/getUrlWithQueryString';

const apiUrl = configs.beApiUrl;

export async function getSurvey(surveyName, userWixId) {
  const url = getUrlWithQueryString(`${apiUrl}/survey/${userWixId}/${surveyName}`, {});

  const requestOptions = {
    url,
    headers: await authHeaders(),
    json: true,
  };

  try {
    return await request.get(requestOptions);
  } catch (ex) {
    console.error('Error occurred, while getting Treatment Experience survey:', ex);
    throw ex;
  }
}

/**
 *
 * @param {*} surveyName
 * @param {*} answers // following format
 * [
 *  {
 *    surveyKey: 'string',
 *    key: 'string',
 *    value: 'string'
 *  }
 * ]
 * @param {*} userWixId
 * @returns
 */

export async function setSurvey(surveyName, answers, userWixId) {
  const url = getUrlWithQueryString(`${apiUrl}/survey/${userWixId}/${surveyName}`, {});

  const requestOptions = {
    url,
    headers: await authHeaders(),
    data: answers,
    json: true,
  };

  try {
    return await request.post(requestOptions);
  } catch (ex) {
    console.error('Error occurred, while setting Treatment Experience survey:', ex);
    throw ex;
  }
}
