/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-trailing-spaces */
/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable no-console */
/* eslint-disable prefer-template */
/* eslint-disable arrow-parens */
/* eslint-disable array-callback-return */
// import React from 'react';
// import AnswerPicker from './AnswerPicker/AnswerPicker';

export const hoursDiff = (dt1, dt2) => {
  const date1 = new Date(dt1);
  const date2 = new Date(dt2);
  const millisecondsDiff = date2.getTime() - date1.getTime();
  const diffInHours = millisecondsDiff / (1000 * 60 * 60);

  return diffInHours;
};

export const formatTextToUseNewLineBeforeNumbers = text => {
  try {
    return text.replace('**', '').replace(/\n/g, '<br />');
  } catch (error) {
    return text;
  }
};
