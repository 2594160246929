import userConstants from '../constants';

const initialState = {
  info: {
    user_wix_id: null,
    country: null,
    email: null,
    family_name: null,
    phone: null,
    partner: null,
    user_type: null,
  },
  profileDetails: {},
  personal: {
    user_wix_id: null,
    profile_id: null,
    patient_id: null,
    condition: null,
    gender: null,
    dob: null,
    is_done: 'N',
    travel_limit: null,
    looking_for: null,
  },
  condition_profile: {},
  trialsCount: [],
};

export function profile(state = initialState, action) {
  switch (action.type) {
    case userConstants.SET_CONDITION:
      return {
        ...state,
        personal: {
          ...state.personal,
          condition: action.payload,
        },
      };
    case userConstants.SET_USER_DATA:
      return {
        ...state,
        info: {
          ...state.info,
          ...action.payload.info,
        },
        personal: {
          ...state.personal,
          ...action.payload.personal,
        },
        condition_profile: {
          ...state.condition_profile,
          ...action.payload.condition_profile,
        },
      };
    case userConstants.SET_CONDITION_PROFILE:
      return {
        ...state,
        condition_profile: {
          ...state.condition_profile,
          ...action.payload,
        },
      };
    case userConstants.SET_USER_WIX_ID:
      return {
        ...state,
        info: {
          ...state.info,
          user_wix_id: action.payload,
        },
        personal: {
          ...state.personal,
          user_wix_id: action.payload,
        },
      };
    case userConstants.SET_PROFILE_ID:
      return {
        ...state,
        personal: {
          ...state.personal,
          profile_id: action.payload,
        },
      };
    case userConstants.SET_TRIALS_COUNT:
      return {
        ...state,
        trialsCount: action.payload,
      };
    case userConstants.SET_PATIENT_ID:
      return {
        ...state,
        personal: {
          ...state.personal,
          patient_id: action.payload,
        },
      };
    case userConstants.SET_NEW_PROFILE:
      return {
        info: {
          user_wix_id: action.payload.user_wix_id,
          country: null,
          email: action.payload.email,
          family_name: action.payload.family_name,
          phone: action.payload.phone,
          partner: null,
          user_type: null,
        },
        personal: {
          user_wix_id: action.payload.user_wix_id,
          profile_id: null,
          patient_id: null,
          condition: null,
          gender: null,
          dob: null,
          is_done: 'N',
          travel_limit: null,
          looking_for: null,
          ...action.payload.personal,
        },
        condition_profile: {},
        profileDetails: state.profileDetails,
      };
    case userConstants.SET_PROFILE_DETAILS:
      return {
        ...state,
        profileDetails: { ...action.payload },
      };
    case userConstants.CLEAR_DATA:
      return {
        info: {
          user_wix_id: null,
          country: null,
          email: null,
          family_name: null,
          phone: null,
          partner: null,
          user_type: null,
        },
        personal: {
          user_wix_id: null,
          profile_id: null,
          patient_id: null,
          condition: null,
          gender: null,
          dob: null,
          is_done: 'N',
          travel_limit: null,
          looking_for: null,
        },
        condition_profile: {},
        profileDetails: state.profileDetails,
      };
    case userConstants.SOFT_CLEAR_DATA:
      return {
        info: {
          ...state.info,
          email: null,
          family_name: null,
          phone: null,
        },
        personal: {
          ...state.personal,
          is_done: 'N',
        },
        condition_profile: state.condition_profile,
        profileDetails: state.profileDetails,
      };

    default:
      return state;
  }
}
