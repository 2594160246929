import React, { useState } from 'react';
import './MultipleOptionSelect.css';

import { useTranslation } from 'utils/modifiedTranslation';

import OnIcon from 'new-ui/assets/icons/tick-on.svg';
import OffIcon from 'new-ui/assets/icons/tick-off.svg';

import PropTypes from 'prop-types';
import Button from 'new-ui/Components/Button';
import DevMode from 'new-ui/Components/DevMode';
import { conditionChecker } from 'new-ui/Components/Intake/functions';
import { QuestionTitle } from '../../utils';

const MultipleOptionSelect = (props) => {
  const {
    q,
    user,
    onselect,
    singleValues = [],
    autoChange = false,
    showTitle = false,
    controller = {},
  } = props;

  const {
    initValue = user?.condition_profile[q.id],
  } = props;

  const [value, setValue] = useState(initValue || []);
  const { t } = useTranslation();
  const isOptionSelected = (v) => value.includes(v);

  controller.setValue = setValue;

  q.options.forEach((o)=>{
    if (o.isSingle || o.value === 'not_sure') singleValues.push(o.value);
  });

  const isOptionDisabled = (option) => {
    const { disableConditions } = option;
    if (!Array.isArray(disableConditions)) return false;
    return conditionChecker(user.condition_profile, disableConditions);
  };

  const isOptionHidden = (option) => {
    const { hideConditions } = option;
    if (!Array.isArray(hideConditions)) return false;
    return conditionChecker(user.condition_profile, hideConditions);
  };

  return (
    <div className="multiple-option-select">
      { showTitle ? <QuestionTitle q={q} /> : null}
      {
        q.options.map((option, key) => (
          isOptionHidden(option) ? null : (
            <div
              key={key}
              className={`select-option ${isOptionSelected(option.value) ? 'option-selected' : ''} ${isOptionDisabled(option) ? 'option-disabled' : ''}`}
              onClick={
                () => {
                  let n = [...value];
                  singleValues.forEach((v)=>{
                    if (n.includes(v)) n.splice(n.indexOf(v), 1);
                  });
                  if (!isOptionSelected(option.value)) n.push(option.value);
                  else n.splice(n.indexOf(option.value), 1);
                  if (singleValues.includes(option.value) && n.includes(option.value)) n = [option.value];
                  setValue(n);
                  if (autoChange) onselect(n);
                }
              }
            >
              <div className="option-selector">
                <img alt="tick" src={isOptionSelected(option.value) ? OnIcon : OffIcon} />
              </div>
              <div className="option-label">
                {t(option.label)}
              </div>
            </div>
          )
        ))
      }
      {
        !autoChange ? (
          <Button
            title={t('general.save')}
            width="200px"
            action={() => {
              onselect(value);
            }}
          />
        ) : null
      }
      <DevMode>
        <div onClick={() => {
          setValue([]);
          onselect([]);
        }}
        >
          set null
        </div>
      </DevMode>
    </div>
  );
};

MultipleOptionSelect.propTypes = {
  q: PropTypes.any,
  user: PropTypes.any,
  onselect: PropTypes.func,
  initValue: PropTypes.any,
  controller: PropTypes.any,
  showTitle: PropTypes.bool,
  autoChange: PropTypes.bool,
  singleValues: PropTypes.array,
};

export default MultipleOptionSelect;
