import React, { useEffect, useState } from 'react';
import {
  PopupTitle, PopupContent, PopupSubtitle, PopupButton,
} from 'new-ui/Components/Popup';

import app from 'new-ui/app';

import { useTranslation } from 'utils/modifiedTranslation';
import { UNSUBSCRIBE_PROPERTIES } from '../../constants';
import { FORM_THEMES } from '../../Components/Form';
import { CheckboxNew } from '../../../components/Checkbox';

const UnsubscribeForm = ({ closePopup }) => {
  const { t } = useTranslation();
  const [emailSelected, setEmailSelected] = useState(false);
  const [smsSelected, setSmsSelected] = useState(false);

  const save = () => {
    const data = {
      [UNSUBSCRIBE_PROPERTIES.EMAIL]: emailSelected,
      [UNSUBSCRIBE_PROPERTIES.SMS]: smsSelected,
    };

    app.setConfiguration(data);
    app.intercom.updateUserAttributes(data);

    closePopup();
  };

  useEffect(() => {
    app.getConfiguration().then(() => {
      const data = app?.coniguration?.data || {};
      setSmsSelected(data[UNSUBSCRIBE_PROPERTIES.SMS] || false);
      setEmailSelected(data[UNSUBSCRIBE_PROPERTIES.EMAIL] || false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PopupContent>
      <div className="unsubscribe_form">
        <PopupTitle title={t('unsubscribe_form_popup.title')} />
        <PopupSubtitle subtitle={t('unsubscribe_form_popup.question.email')} />
        <CheckboxNew
          key="email"
          theme="default"
          label="Unsubscribe from emails"
          checked={emailSelected}
          onChange={(event) => setEmailSelected(event.target.checked)}
        />
        <CheckboxNew
          key="sms"
          theme={FORM_THEMES.ORANGE}
          label="Unsubscribe from sms"
          checked={smsSelected}
          onChange={(event) => setSmsSelected(event.target.checked)}
        />
      </div>
      <PopupButton title={t('unsubscribe_form_popup.btn')} action={save} />
    </PopupContent>
  );
};

export default UnsubscribeForm;
