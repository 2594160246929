import React, { useEffect } from 'react';
import './ScrollTabs.css';
import PropTypes from 'prop-types';

let startScrollX;
let startX;
let moved = false;
let touch = false;
let isScrollIntoView = false;

const ScrollTabs = (props) => {
  const { tabs, header } = props;

  const selectTabById = (id) => {
    const tabs = Array.from(document.querySelectorAll('.scroll-tabs-tab'));
    const tab = tabs[id];
    tabs.forEach((a) => {
      a.classList.remove('selected-tab');
      if (a === tab) a.classList.add('selected-tab');
    });
    return tab;
  };

  useEffect(() => {
    function findClosestIndex(arr, target) {
      let closestIndex = 0;
      let closestDiff = Math.abs(target - arr[0]);

      for (let i = 1; i < arr.length; i++) {
        const diff = Math.abs(target - arr[i]);
        if (diff < closestDiff) {
          closestIndex = i;
          closestDiff = diff;
        }
      }

      return closestIndex;
    }

    function handleScroll(contents, $body) {
      if (!isScrollIntoView) {
        const y = contents.map((a) => a.getBoundingClientRect().y);
        const id = findClosestIndex(y, 20);
        const tab = selectTabById(id);
        tab.scrollIntoView();
      }
      isScrollIntoView = false;
    }

    const $popup = document.querySelector('.popup');
    const $popupHeader = document.querySelector('.popup-header');
    const $head = document.querySelector('.scroll-tabs-head');
    const $body = document.querySelector('.scroll-tabs-body');
    const contents = Array.from(document.querySelectorAll('.scroll-tabs-tab-content'));
    const topHeadHeight = $popup.clientHeight - $head.clientHeight - $popupHeader.clientHeight;
    $body.style.height = `${topHeadHeight}px`;

    $body.onscroll = () => handleScroll(contents, $body);

    $head.onmousewheel = (ev) => {
      $head.scrollLeft += 30 * (ev.deltaY > 1 ? 1 : -1);
    };

    const remover = (ev) => {
      if (touch && moved) ev.stopPropagation();
      touch = false;
      moved = false;
      document.removeEventListener('mouseup', remover);
    };

    $head.onmousedown = (ev) => {
      startScrollX = $head.scrollLeft;
      startX = ev.clientX;
      touch = true;
      moved = false;
      document.addEventListener('mouseup', remover);
    };

    $head.onmouseup = remover;

    $head.onmousemove = (ev) => {
      if (touch) {
        moved = true;
        $head.scrollLeft = startScrollX - (ev.clientX - startX);
      }
    };
  }, []);

  const tabClick = (ev) => {
    ev.target.scrollIntoView();
    const $body = document.querySelector('.scroll-tabs-body');
    const tabs = Array.from(document.querySelectorAll('.scroll-tabs-tab'));
    const contents = Array.from(document.querySelectorAll('.scroll-tabs-tab-content'));
    const id = tabs.indexOf(ev.target);
    const content = contents[id];
    selectTabById(id);
    isScrollIntoView = true;
    content.scrollIntoView();
    $body.scrollTop -= 80;
  };

  return (
    <div className="scroll-tabs">
      <div className="scroll-tabs-head">
        {tabs.map((tab, i) => (
          <div key={i} className={`scroll-tabs-tab ${!i ? 'selected-tab' : ''}`} onMouseUp={tabClick}>
            {tab.alert > 0 && <span>{tab.alert}</span>}
            {tab.title}
          </div>
        ))}
      </div>
      <div className="scroll-tabs-body">
        { header || null }
        {tabs.map((tab, i) => (
          <div key={i} className="scroll-tabs-tab-content">{tab.body}</div>
        ))}
      </div>
    </div>
  );
};

ScrollTabs.propTypes = {
  tabs: PropTypes.array,
  header: PropTypes.any,
  height: PropTypes.any,
};

export default ScrollTabs;
