import React from 'react';
import './style.css';
import { useTranslation } from "utils/modifiedTranslation";

const SponsoredLabel = () => {
  const { t } = useTranslation();
  return (
    <div className='sponsored-label'>
      {t("list_item.label.sponsored")}
    </div>
  );
};

export default SponsoredLabel;