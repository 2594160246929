import React from "react";
import "./style.css";
import { Button } from '@material-ui/core';

const ArchiveCard = ({patient_id, onClose, onArchivePatient}) => {
  return (
    <div className="archive-card">
      <h2>Archive</h2>
      <h3 className="description">Are you sure you want to archive this profile?</h3>
      <div className="actions-cont">
         <Button onClick={onClose} className='cancel-button'> 
            <span className='label'>Cancel</span>
        </Button>
        <Button onClick={onArchivePatient} className='archive-button'> 
            <span className='label'>Yes, Arcive</span>
        </Button>
      </div>
    </div>
  );
};

export default ArchiveCard;