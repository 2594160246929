import React from 'react';
import { useHistory } from 'react-router-dom';
import arrowDown from 'new-ui/assets/icons/arrow-down.svg';
import './NGSHeader.css';
import { NGS_HEADER_LINKS, PAGES, URI } from 'new-ui/constants';
import app from 'new-ui/app';

const BLOG_NAV_ITEMS = [{
  title: 'Patient',
  link: NGS_HEADER_LINKS.PATIENTS,
}, {
  title: 'Pharma',
  link: NGS_HEADER_LINKS.PHARMA
}, {
  title: 'Phisician',
  link: NGS_HEADER_LINKS.PHARMAPHISICIAN
}];
const RESOURCES_NAV_ITEMS = [{
  title: 'FAQ',
  link: NGS_HEADER_LINKS.FAQ,
}, {
  title: 'Questions To Ask',
  link: NGS_HEADER_LINKS.QUESTIONS_TO_ASK,
}, {
  title: 'Patient Support Resources',
  link: NGS_HEADER_LINKS.PATIENT_SUPPORT_RESOURCES,
}];
const WHO_WE_ARE_NAV_ITEMS = [{
  title: 'About',
  link: NGS_HEADER_LINKS.ABOUT,
}, {
  title: 'Publications and Articles',
  link: NGS_HEADER_LINKS.PUBLICATIONS,
}];

const NAV_ITEMS = [{
  title: 'Who we are',
  dropdownItems: WHO_WE_ARE_NAV_ITEMS,
}, {
  title: 'Blog',
  dropdownItems: BLOG_NAV_ITEMS,
}, {
  title: 'Resources',
  dropdownItems: RESOURCES_NAV_ITEMS,
}];

const NGSHeader = () => {
  const history = useHistory();
  const openLink = (item, key) => {
    const { link, dropdownItems = [] } = item;
    if (dropdownItems.length) {
      animate(key);
    } else {
      window.open(link, '_blank');
    }
  };

  const animate = (key) => {
    const items = document.querySelectorAll('.header-item');
    items.forEach(item => {
      const itemKey = item.getAttribute('itemkey');
      const img = item.querySelector('img');
      const dropDown = item.querySelector('.header-dropdown-items');
      const rotate180 = 'rotate(180deg)'
      if (img) {
        if (itemKey === key.toString() && img.style.transform !== rotate180) {
          img.style.transform = rotate180;
          dropDown.style.opacity = '1';
          dropDown.style['max-height'] = '1000px';
        } else {
          img.style.transform = 'rotate(0deg)';
          dropDown.style.opacity = '0';
          dropDown.style['max-height'] = '0';
        }
      }
    });
  }

  const NavItems = ({ items = [] }) => (
    items.map((item, key) => (
      <div
        key={key}
        itemkey={key}
        className='header-item'
        onClick={() => openLink(item, key)}>
          {item.title}
          {item?.dropdownItems && <img
            alt="arrow down"
            src={arrowDown} />}
          <div className="header-dropdown-items">
            {item?.dropdownItems && <NavItems items={item.dropdownItems} />}
          </div>
      </div>
    ))
  );

  const goToLogin = () => {
    app.setCustomHeader(null);
    history.push(URI[PAGES.LOGIN]);
  };

  return (
    <div className="ngs-custom-header">
      <div className='nav-items'>
        <NavItems items={NAV_ITEMS} />
      </div>
      <div className='login-item' onClick={goToLogin}>
        Login
      </div>
    </div>
  );
};

export default NGSHeader;
