import geoConstants from '../constants/geo';

const initialState = {};

export function geoLocation(state = initialState, action) {
  switch (action.type) {
    case geoConstants.SET_USER_GEO_LOCATION:
      return {
        ...state,
        ...action.payload,
      };
    case geoConstants.CLEAR_GEO_LOCATION:
      return {};
    default:
      return state;
  }
}
