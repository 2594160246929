import Button from 'new-ui/Components/Button';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'utils/modifiedTranslation';
import EmailScane from '../assets/EmailScane.svg';
import Check from '../assets/check.svg';
import './ReceivingFile.css';

const ReceivingFile = ({ setUploadType })=>{
  const { t } = useTranslation();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setUploadType('UPLOADING');
    }, 10000);

    return () => clearTimeout(timeoutId);
  }, [setUploadType]);
  return (
    <div className="receiving-wrapper">
      <div>
        <div className="title">{t('upload.receiving_title_text')}</div>
        <div className="image-section"><img src={EmailScane} alt="receiving-img" /></div>
        <div className="receiving-btn">
          <Button
            title={t('upload.send_files_btn_text')}
            backgroundColor="#fff"
            color="#213332"
            icon={Check}
            width="160px"
          />
        </div>
      </div>
    </div>
  );
};

ReceivingFile.propTypes = {
  setUploadType: PropTypes.func,
};

export default ReceivingFile;
