import { store } from 'utils/exportStore';
import configs from 'configs';

export default (eventName, params = {}) => {
  if (!configs.isProduction || !eventName) {
    return;
  }

  if (typeof window.gtag !== 'function') {
    console.error('gtag is not configured');
    return;
  }

  const { authentication, profile } = store.getState();
  const dataToSend = {
    userWixId: authentication?.user?.user_wix_id || profile?.personal?.user_wix_id || null,
    disease: profile?.personal?.condition || null,
    ...params,
  };

  window.gtag('event', eventName, dataToSend);
};
