import React from 'react';
import './BackButton.css';

import { useTranslation } from 'utils/modifiedTranslation';
import { useHistory } from 'react-router-dom';

import BackIcon from 'new-ui/assets/icons/back.svg';

const BackButton = (props) => {
  const { action } = props;
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <div className="back-button middle" onClick={action || history.goBack}>
      <img alt="" src={BackIcon} />
      {' '}
      {t('trial.back')}
    </div>
  );
};

export default BackButton;
