import _ from 'lodash';

const combineObjectValues = array => {
  const result = {};

  array.forEach(object => {
    for (const key in object) {
      const value = object[key];
      if (_.has(result, key)) {
        // eslint-disable-next-line
        _.isArray(value) ? result[key].push(...value) : result[key].push(value);
      } else {
        result[key] = _.isArray(value) ? value : [value];
      }
    }
  });
  return result;
};

export function flattenAnswers(answers) {
  let clonedAnswers = _.cloneDeep(answers);

  for (const questionId in clonedAnswers) {
    const currentQuestionAnswers = clonedAnswers[questionId];
    const hasSubQuestions =
      _.isArray(currentQuestionAnswers) && currentQuestionAnswers.some(answer => _.isObject(answer));

    if (hasSubQuestions) {
      for (const answer of currentQuestionAnswers) {
        if (currentQuestionAnswers.length > 1) {
          clonedAnswers = { ...clonedAnswers, ...combineObjectValues(clonedAnswers[questionId]) };
        } else {
          clonedAnswers = { ...clonedAnswers, ...answer };
        }
      }
    }
  }

  return clonedAnswers;
}
