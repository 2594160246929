export const Profile = {
  dob: 'dob',
  gender: 'gender',
  race: 'race',
  is_pregnant: 'is_pregnant',
  is_breastfeeding: 'is_breastfeeding',
  type_of_mds: 'type_of_mds',
  type_of_mds_rs: 'type_of_mds_rs',
  type_of_mds_eb: 'type_of_mds_eb',
  risk_group: 'risk_group',
  intermediate_risk_subgroup: 'intermediate_risk_subgroup',
  percentage_of_bm_blasts: 'percentage_of_bm_blasts',
  clinical_classification: 'clinical_classification',
  treatment_for_mds: 'treatment_for_mds',
  number_of_lines_of_treatment: 'number_of_lines_of_treatment',
  disease_status: 'disease_status',
  received_allogenic_transplant: 'received_allogenic_transplant',
  allogenic_transplant_date: 'allogenic_transplant_date',
  allogenic_transplant_outcome: 'allogenic_transplant_outcome',
  treatment_after_transplant_relapse: 'treatment_after_transplant_relapse',
  second_allogenic_transplant_outcome: 'second_allogenic_transplant_outcome',
  number_of_azacitidine_treatment_cycles: 'number_of_azacitidine_treatment_cycles',
  number_of_decitabine_treatment_cycles: 'number_of_decitabine_treatment_cycles',
  treatment_after_transplant_relapse_outcome: 'treatment_after_transplant_relapse_outcome',
  treatment_nontransplant_candidate: 'treatment_nontransplant_candidate',
  chemotherapy_treatment_last_date: 'chemotherapy_treatment_last_date',
  nontransplant_candidate_treatment_outcome: 'nontransplant_candidate_treatment_outcome',
  chemotherapy_other: 'chemotherapy_other',
  treatment_for_low_risk_mds: 'treatment_for_low_risk_mds',
  treatment_received_last_date: 'treatment_received_last_date',
  treatment_for_low_risk_mds_treatment_outcome: 'treatment_for_low_risk_mds_treatment_outcome',
  has_biopsy: 'has_biopsy',
  received_investigational_therapy: 'received_investigational_therapy',
  supportive_care_type: 'supportive_care_type',
  number_of_blood_transfusions: 'number_of_blood_transfusions',
  last_blood_transfusion_received_date: 'last_blood_transfusion_received_date',
  current_treatment_systemic_steroids: 'current_treatment_systemic_steroids',
  systemic_steroids_treatment_end_date: 'systemic_steroids_treatment_end_date',
  gvhd: 'gvhd',

  other_medical_condition: 'other_medical_condition',
  ecog: 'ecog',
  ngs: 'ngs',
  karnofsky_score: 'karnofsky_score',
  omc_abnormal_blood_results: 'omc_abnormal_blood_results',
  omc_liver: 'omc_liver',
  omc_heart: 'omc_heart',
  omc_stroke_date: 'omc_stroke_date',
  omc_kidney: 'omc_kidney',
  omc_autoimmune: 'omc_autoimmune',
  omc_mental: 'omc_mental',
  omc_gastro_problem: 'omc_gastro_problem',
  omc_gastrointestinal: 'omc_gastrointestinal',
  omc_allergy: 'omc_allergy',
  omc_chronic_infection_inflammation: 'omc_chronic_infection_inflammation',
  omc_lung_disease: 'omc_lung_disease',

  other_medications: 'other_medications',

  have_insurance: 'have_insurance',
  insurances: 'insurances',
};
