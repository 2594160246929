import React, { useState } from 'react';
import './DropdownButton.css';

import PropTypes from 'prop-types';

import IconUp from 'new-ui/assets/icons/arrow_drop_up.svg';
import IconDown from 'new-ui/assets/icons/arrow_drop_down.svg';
import IconSort from 'new-ui/assets/icons/sort.svg';

const DropdownButton = (props)=>{
  const {
    options = [],
    className,
  } = props;
  const [selected, setSelected] = useState(options.find((o)=>o.isSelected) || options[0]);
  const [showOptions, setShowOptions] = useState(false);
  return (
    <div className={`dropdown-button ${className || ''}`} onClick={()=>{ setShowOptions(!showOptions); }}>
      <div className="dropdown-button-header">
        <div className="dropdown-button-header-icon">
          <img alt="icon" src={showOptions ? IconUp : IconDown} />
        </div>
        <div className="dropdown-button-header-title">
          {selected.title}
        </div>
      </div>
      {showOptions ? (
        <div className="dropdown-button-options">
          {
            options.map((option, k)=>(
              <div
                key={k}
                className={`dropdown-button-option ${selected === option ? 'dropdown-button-option-selected' : ''}`}
                onClick={()=>{
                  setSelected(option);
                  setShowOptions(false);
                  if (option.action) option.action();
                }}
              >
                <div className="dropdown-button-option-icon">
                  <img alt="icon" src={option.icon || IconSort} />
                </div>
                <div className="dropdown-button-option-title">
                  {option.title}
                </div>
              </div>
            ))
          }
        </div>
      ) : null}
    </div>
  );
};

DropdownButton.propTypes = {
  options: PropTypes.array,
  className: PropTypes.string,
};

export default DropdownButton;
