import React from 'react';
import closeIcon from 'new-ui/assets/icons/close.svg';

import './ChatBot.css';

import LoadingIcon from 'new-ui/assets/icons/loading.gif';

import { DOM } from 'new-ui/functions';
import { render } from 'react-dom';
import { THEMES } from 'new-ui/constants';
import Button from '../Button';

export const CHATBOT_MESSAGE_TYPES = {
  TEXT_MESSAGE: 'TEXT_MESSAGE',
  BUTTONS: 'BUTTONS',
};

export const removeChatBot = () => {
  [].forEach.call(document.querySelectorAll('.chatbot-overlay'), (a) => {
    a.parentElement.removeChild(a);
  });
};

export const createChatBot = (data = {}) => {
  removeChatBot();
  const $el = DOM('.chatbot-overlay', {
    '/.chatbot': {
      name: 'chatbot',
      '/.chatbot-header': {
        '/img.chatbot-close': {
          src: closeIcon,
          onclick: () => {
            $el.parentElement.removeChild($el);
          },
        },
      },
      '/.chatbot-body': {
        name: 'chatbotBody',
      },
    },
  });

  document.body.appendChild($el);

  setTimeout(() => {
    $el.classList.add('chatbot-overlay-visible');
    setTimeout(() => {
      $el.$chatbot.classList.add('chatbot-visible');
    }, 100);
  }, 1);

  const { chat = [] } = data;

  chat.forEach((chatItem) => {
    const $chatItem = DOM('.chatbot-chat-item', {
      '/.chatbot-icon': {
        '/img.chatbot-icon-img': {
          src: chatItem.icon,
        },
      },
      '/.chatbot-thread': {
        '/.chatbot-messages': {
          name: 'messages',
        },
        '/.chatbot-loading': {
          name: 'loading',
          '/img.chatbot-loader': {
            src: LoadingIcon,
          },
        },
      },
    });

    const { $messages, $loading } = $chatItem;

    const { messages = [] } = chatItem;

    const done = [];

    const showMessage = (idx = 0) => {
      const chatMessage = messages[idx];
      $loading.classList.add('display-block');
      setTimeout(() => {
        let $chatMessage;
        switch (chatMessage.type) {
          default:
          case CHATBOT_MESSAGE_TYPES.TEXT_MESSAGE:
            $chatMessage = DOM('.chatbot-chat-message', {
              innerHTML: chatMessage.text,
            });
            break;
          case CHATBOT_MESSAGE_TYPES.BUTTONS:
            $chatMessage = DOM('.chatbot-chat-buttons');
            chatMessage.buttons.forEach((button) => {
              const $button = DOM('.chatbot-button');
              $chatMessage.appendChild($button);
              render(<Button title={button.text} action={button.action} theme={button.theme || THEMES.ORANGE} />, $button);
            });
            break;
        }
        $messages.appendChild($chatMessage);
        done.push(chatMessage);
        $loading.classList.remove('display-block');
        if (done.length !== messages.length) showMessage(idx + 1);
      }, idx === 0 ? 1 : ((Math.random() * 1500) + 2500));
    };

    showMessage();

    $el.$chatbotBody.appendChild($chatItem);
  });

  console.log($el);
};

window.createChatBot = createChatBot;
