/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import app from 'new-ui/app';
import { POPUP_TYPES, SURVEYS } from 'new-ui/constants';
import './TreatmentSurvey.css';
import { useTranslation } from 'utils/modifiedTranslation';
import { PopupContent, PopupFooter, PopupTitle } from 'new-ui/Components/Popup';
import Form, { FORM_INPUT_TYPES, FORM_THEMES } from 'new-ui/Components/Form';
import Button from 'new-ui/Components/Button';
import Input from 'new-ui/Components/Form/Input';
import ChatIcon from 'new-ui/assets/icons/chat.png';
import ArrowLeft from 'new-ui/assets/icons/arrow_left_2.svg';
import SentimentSatisfied from 'new-ui/assets/icons/sentiment_satisfied.svg';
import SentimentNeutral from 'new-ui/assets/icons/sentiment_neutral.svg';
import SentimentDissatisfied from 'new-ui/assets/icons/sentiment_very_dissatisfied.svg';
import thanksIcon from 'new-ui/Popups/TreatmentSurvey/assets/thanks.svg';
import CommunityTag from 'new-ui/Components/CommunityTag';
import useForceUpdate from 'use-force-update';
import Chat, { CHAT_TYPES } from 'new-ui/Components/Chat';
import Preloader from 'new-ui/Components/Preloader';
import { getDrugsTranslatedLabel } from 'new-ui/functions/getDrugsTranslatedLabel';
import { GA_CATEGORY_NAME } from 'new-ui/Components/GoogleAnalytics/inContextEvents';
import { profileTreatmentSectionEvents } from 'new-ui/Components/GoogleAnalytics/profileSectionEvents';
import { getSurvey, setSurvey } from './api';

const {
  surveyAnswered,
} = profileTreatmentSectionEvents;

const SURVEY_KEYS = {
  AGGRESSION: 'aggression',
  SIDE_EFFECTS: 'side_effects',
  GENERAL: 'general',
  CHAT: 'chat',
  SHARE: 'share',
  NO_TREATMENTS: 'no_treatments',
  COMPLETED: 'completed',
};

const AGGRESSION_VALUES = {
  NOT_AGGRESSIVE: 'not_aggressive',
  SOMEWHAT_AGGRESSIVE: 'somewhat_aggresive',
  VERY_AGGRESIVE: 'very_aggressive',
};

const BUTTONS = {
  NO_SIDE_EFFECTS: 'no-side-effects',
  SUBMIT: 'submit',
  BACK: 'back',
};

let steps = [];
let answers = [];
let sideEffects = [];
let drugs = [];
let completed = [];

const TreatmentSurvey = ({ lastTreatment }) => {
  const [data, setData] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const { t } = useTranslation();

  const forceUpdate = useForceUpdate();

  const createSurvey = (drug) => {
    steps = [
      { page: SURVEY_KEYS.AGGRESSION, drug },
      { page: SURVEY_KEYS.SIDE_EFFECTS, drug },
      { page: SURVEY_KEYS.CHAT },
    ];
    setCurrentStep(0);
  };

  useEffect(() => {
    const fetch = async () => {
      const r = await getSurvey(SURVEYS.TREATMENT_EXPERIENCE, app.user.info.user_wix_id);

      answers = r.data.answers || [];
      sideEffects = r.data?.data?.sideEffects;
      const loadedDrugs = r.data?.data?.drugs || [];
      const loadedDrugsNames = loadedDrugs.map((a) => a.name);
      const storage_completed = [];
      loadedDrugsNames.forEach((loadedName) => {
        answers.forEach((answer) => {
          if (answer.surveyKey === loadedName && answer.key === 'side_effects' && !storage_completed.includes(loadedName)) {
            storage_completed.push(loadedName);
          }
        });
      });
      completed = storage_completed;
      drugs = loadedDrugsNames.filter((d) => !storage_completed.includes(d));

      const arraysEqual = (a = [], b = []) => {
        if (a.length !== b.length) return false;
        return JSON.stringify(a.sort()) === JSON.stringify(b.sort());
      };

      const completedLoadedDrugs = storage_completed.filter((a) => loadedDrugsNames.includes(a));

      if (loadedDrugs.length && storage_completed.length && arraysEqual(
        loadedDrugsNames,
        completedLoadedDrugs,
      )) {
        steps = [{ page: SURVEY_KEYS.COMPLETED }];
      } else if (!drugs.length) {
        steps = [{ page: SURVEY_KEYS.NO_TREATMENTS }];
      } else {
        createSurvey(lastTreatment || drugs[0]);
      }

      setData(r.data);
    };

    fetch();
  }, [lastTreatment]);

  useEffect(() => {
    const $popup = document.querySelector('.popup-content');
    const $body = document.querySelector('.popup-content .side-effects');
    const $popupTitle = document.querySelector('.popup-content .popup-title');
    const $popupContentTitle = document.querySelector('.popup-content .popup-content-wrapper .title');
    const $popupContentDescroption = document.querySelector('.popup-content .popup-content-wrapper .description');
    const $popupFooter = document.querySelector('.popup-content .popup-footer');
    if ($popup && $body) {
      const topHeadHeight = $popup.clientHeight - $popupTitle.clientHeight
      - $popupContentTitle.clientHeight - $popupContentDescroption.clientHeight - $popupFooter.clientHeight - 110;
      $body.style.height = `${topHeadHeight}px`;
    }
  }, [currentStep]);

  const getAnswers = (surveyKey, key) => {
    const values = answers.filter((answer) => (answer.surveyKey === surveyKey && answer.key === key)).map((answer) => ({ value: answer.value }));
    return values;
  };

  const setAnswers = (surveyKey, key, values = []) => {
    let i = answers.length;

    while (i > 0) {
      i -= 1;
      const answer = answers[i];
      if (answer.surveyKey === surveyKey && answer.key === key) answers.splice(i, 1);
    }

    if (typeof values === 'string') {
      answers.push({ surveyKey, key, value: values });
    } else {
      values.forEach((value) => {
        answers.push({ surveyKey, key, value });
      });
    }

    setSurvey(SURVEYS.TREATMENT_EXPERIENCE, answers, app.user.info.user_wix_id);
  };

  const next = () => {
    const nextStep = currentStep + 1;
    if (nextStep >= steps.length) return;
    setCurrentStep(nextStep);
  };

  const back = () => {
    const nextStep = currentStep - 1;
    if (nextStep < 0) return;
    setCurrentStep(nextStep);
  };

  const getName = () => (app.user.info.family_name ? `${app.user.info.family_name}, ` : '');

  const getButton = (b) => document.querySelector(`.${b}-button`);

  const hideButtons = () => {
    getButton(BUTTONS.SUBMIT).classList.add('hidden');
    getButton(BUTTONS.BACK).classList.add('hidden');
    getButton(BUTTONS.NO_SIDE_EFFECTS).classList.add('hidden');
    getButton(BUTTONS.SUBMIT).classList.remove('disabled');
  };

  const showButton = (b) => {
    getButton(b).classList.remove('hidden');
  };

  const render = () => {
    if (!data || !steps.length) {
      return (
        <PopupContent>
          <Preloader isVisible />
        </PopupContent>
      );
    }

    hideButtons();

    const step = steps[currentStep];
    const { drug } = step;
    let form;

    switch (step.page) {
      default:
      case SURVEY_KEYS.AGGRESSION: // aggression

        const aggression = getAnswers(drug, SURVEY_KEYS.AGGRESSION)[0] ? getAnswers(drug, SURVEY_KEYS.AGGRESSION)[0].value : null;

        const setAggression = (value) => {
          setAnswers(drug, SURVEY_KEYS.AGGRESSION, value);
          next();
          forceUpdate();
        };

        return (
          <>
            <PopupTitle>
              <div className="share-experiences">
                {t('treatment_experience.share_your_experiences')}
                {' '}
                <CommunityTag />
              </div>
              {getDrugsTranslatedLabel(t, drug)}
            </PopupTitle>
            <PopupContent>
              <div className="title">
                {t('treatment_experience.aggression_title')}
              </div>
              <div className="description">
                {t('treatment_experience.aggression_description')}
              </div>
              <div className="aggression">
                <div className="option" onClick={() => { setAggression(AGGRESSION_VALUES.NOT_AGGRESSIVE); }}>
                  <div className={`sentiment-image ${aggression === AGGRESSION_VALUES.NOT_AGGRESSIVE ? 'selected' : ''}`}><img alt="sentiment" src={SentimentSatisfied} /></div>
                  <div className="option-title">{t('treatment_experience.not_intense')}</div>
                </div>
                <div className="option" onClick={() => { setAggression(AGGRESSION_VALUES.SOMEWHAT_AGGRESSIVE); }}>
                  <div className={`sentiment-image ${aggression === AGGRESSION_VALUES.SOMEWHAT_AGGRESSIVE ? 'selected' : ''}`}><img alt="sentiment" src={SentimentNeutral} /></div>
                  <div className="option-title">{t('treatment_experience.somewhat_intense')}</div>
                </div>
                <div className="option" onClick={() => { setAggression(AGGRESSION_VALUES.VERY_AGGRESIVE); }}>
                  <div className={`sentiment-image ${aggression === AGGRESSION_VALUES.VERY_AGGRESIVE ? 'selected' : ''}`}><img alt="sentiment" src={SentimentDissatisfied} /></div>
                  <div className="option-title">{t('treatment_experience.very_intense')}</div>
                </div>
              </div>
            </PopupContent>
          </>
        );

      case SURVEY_KEYS.SIDE_EFFECTS:
        // sideeffects

        showButton(BUTTONS.SUBMIT);
        getButton(BUTTONS.SUBMIT).classList.add('disabled');
        showButton(BUTTONS.BACK);
        showButton(BUTTONS.NO_SIDE_EFFECTS);

        form = {
          inputs: {
            side_effects: {
              type: FORM_INPUT_TYPES.MULTI_CHECKBOX,
              options: [{
                title: t('treatment_experience.didnt_have_sideeffects'),
                name: 'no_sideeffects',
              }].concat(sideEffects.map((sideEffect) => ({
                title: t(`side_effects.${sideEffect.key}`),
                name: sideEffect.key,
              }))),
            },
          },
          data: {
            side_effects: getAnswers(drug, SURVEY_KEYS.SIDE_EFFECTS).map((a) => a.value),
          },
        };

        getButton(BUTTONS.NO_SIDE_EFFECTS).onclick = () => {
          form.data.side_effects = [
            'no_sideeffects',
          ];
          document.querySelector('.search input').value = '';
          getButton(BUTTONS.SUBMIT).classList.remove('disabled');
          form.render();
        };

        getButton(BUTTONS.SUBMIT).onclick = () => {
          setAnswers(drug, SURVEY_KEYS.SIDE_EFFECTS, form.data.side_effects);
          completed.push(drug);
          if (lastTreatment) {
            app.sendGoogleAnalyticsEvent(
              GA_CATEGORY_NAME,
              surveyAnswered.eventName,
              surveyAnswered.getMetaData(),
            );
          }
          next();
        };

        return (
          <>
            <PopupTitle>
              <div className="share-experiences">
                {t('treatment_experience.share_your_experiences')}
                {' '}
                <CommunityTag />
              </div>
              {getDrugsTranslatedLabel(t, drug)}
            </PopupTitle>
            <PopupContent>
              <div className="title">
                {t('treatment_experience.side_effects_title')}
              </div>
              <div className="description">
                {t('treatment_experience.side_effects_description')}
              </div>
              <div>
                <Input
                  input={{ type: 'text' }}
                  theme={FORM_THEMES.ORANGE}
                  className="search"
                  placeholder="Start typing your side effects"
                  icon="search_big"
                  value=""
                  onChange={(v) => {
                    Array.from(document.querySelectorAll('.side_effects .checkbox')).forEach(($el) => {
                      if (!v) $el.classList.remove('hidden');
                      else {
                        $el.classList.add('hidden');
                        const text = $el.querySelector('.checkbox-title').innerHTML;
                        if (String(text).indexOf(v) !== -1) $el.classList.remove('hidden');
                      }
                    });
                  }}
                />
              </div>
              {/* <div className="small-title">{t('treatment_experience.common_side_effect')}</div> */}
              <div className="side-effects">
                <Form
                  form={form}
                  theme={FORM_THEMES.ORANGE}
                  onChange={(f, n, newValue) => {
                    if (newValue === 'no_sideeffects' && f.side_effects.length) {
                      form.data.side_effects = ['no_sideeffects'];
                      form.render();
                    } else if (newValue !== 'no_sideeffects' && f.side_effects.includes('no_sideeffects')) {
                      f.side_effects.splice(f.side_effects.indexOf('no_sideeffects'), 1);
                      form.data.side_effects = f.side_effects;
                      form.render();
                    }
                    getButton(BUTTONS.SUBMIT).classList.remove('disabled');
                  }}
                />
              </div>
            </PopupContent>
          </>
        );
      case SURVEY_KEYS.CHAT:
        // thank you

        const lastQuestion = {
          type: CHAT_TYPES.QUESTION,
          text: t('treatment_experience.last_question', {
            name: getName(),
          }),
          answers: [
            {
              text: t('treatment_experience.yes_i_would'),
              onanswer: () => {
                setAnswers(SURVEY_KEYS.GENERAL, SURVEY_KEYS.SHARE, 'yes');
                if (lastTreatment) {
                  app.setPopup(POPUP_TYPES.MEDICAL_PROFILE);
                } else {
                  app.setPopup(false);
                }
                app.intercom.update();
              },
            },
            {
              text: t('treatment_experience.no_thanks'),
              onanswer: () => {
                setAnswers(SURVEY_KEYS.GENERAL, SURVEY_KEYS.SHARE, 'no');
                if (lastTreatment) {
                  app.setPopup(POPUP_TYPES.MEDICAL_PROFILE);
                } else {
                  app.setPopup(false);
                }
                app.intercom.update();
              },
            },
          ],
        };

        const updateProfile = {
          type: CHAT_TYPES.QUESTION,
          text: t('treatment_experience.do_you_want_to_update_profile'),
          answers: [
            {
              text: t('treatment_experience.go_to_my_results'),
              next: lastQuestion,
            },
            {
              text: t('treatment_experience.update_my_profile'),
              onanswer: () => {
                app.setPopup(POPUP_TYPES.MEDICAL_PROFILE);
              },
            },
          ],
        };

        const updateAddTreatment = {
          type: CHAT_TYPES.QUESTION,
          text: t('treatment_experience.would_you_like_to_add_treatments'),
          answers: [
            {
              text: t('treatment_experience.yes'),
              onanswer: () => {
                app.editQuestion('treatments', () => {
                  app.intercom.update();
                  app.setPopup('treatment_survey', { lastTreatment });
                });
              },
            },
            {
              text: t('treatment_experience.not_now'),
              next: lastQuestion,
            },
          ],
        };

        const drugAnswers = drugs.filter((drug) => !completed.includes(drug)).map((drug) => ({
          text: getDrugsTranslatedLabel(t, drug),
          onanswer: () => {
            createSurvey(drug);
          },
        }));

        const chatItems = [
          {
            type: CHAT_TYPES.MESSAGE,
            text: t('treatment_experience.thanks_for_helping_others', { num: data.data.patients_with_same_indication }), // todo
            fontWeight: 'bold',
          },
          {
            type: CHAT_TYPES.QUESTION,
            text: t('treatment_experience.please_give_feedback_other_treatments'),
            answers: drugAnswers.concat([
              {
                text: t('treatment_experience.treatment_is_not_listed'),
                next: updateAddTreatment,
              },
              {
                text: t('treatment_experience.not_now'),
                next: updateProfile,
              },
            ]),
          },
        ];

        return (
          <>
            <PopupTitle>
              <div className="experiences">
                <div className="experiences-icon"><img alt="icon" src={ChatIcon} /></div>
                <div>{t('treatment_experience.your_treatment_experiences')}</div>
              </div>
            </PopupTitle>
            <PopupContent>
              <Chat items={chatItems} />
            </PopupContent>
          </>
        );
      case SURVEY_KEYS.NO_TREATMENTS:
        // you dont have any treatments
        document.querySelector('.survey-footer').classList.add('hidden');

        return (
          <>
            <div className="thank-you-image">
              <img alt="thanks" src={thanksIcon} />
            </div>
            <div className="thank-you-text">
              <div className="thank-you-title">{t('treatment_experience.message_thank_you_title')}</div>
              <div>
                {t('treatment_experience.no_treatments', {
                  name: getName(),
                })}
              </div>
              <div className="thank-you-question">
                {t('treatment_experience.have_you_received_treatments')}
              </div>
            </div>
            <PopupFooter>
              <Button
                action={() => {
                  app.editQuestion('treatments', () => {
                    app.intercom.update();
                    app.setPopup('treatment_survey', { lastTreatment });
                  });
                }}
                title={t('treatment_experience.add_treatment')}
              />
            </PopupFooter>
          </>
        );
      case SURVEY_KEYS.COMPLETED:
        // you dont have any treatments
        document.querySelector('.survey-footer').classList.add('hidden');
        return (
          <>
            <div className="thank-you-image">
              <img alt="thanks" src={thanksIcon} />
            </div>
            <div className="thank-you-text">
              <div className="thank-you-title">{t('treatment_experience.message_thank_you_title')}</div>
              <div>
                {t('treatment_experience.message_thank_you', {
                  name: getName(),
                })}
              </div>
              <div className="thank-you-bold">
                {t('treatment_experience.we_appreciate_it')}
              </div>
            </div>
            <PopupFooter>
              <Button
                action={() => {
                  if (lastTreatment) {
                    app.setPopup(POPUP_TYPES.MEDICAL_PROFILE);
                  } else {
                    app.setPopup(false);
                  }
                }}
                title={t('treatment_experience.next')}
              />
            </PopupFooter>
          </>
        );
    }
  };

  const renderFooter = () => (
    <PopupFooter className="survey-footer">
      <div className="space-between footer-buttons">
        <img className="back-button hidden" alt="back" src={ArrowLeft} onClick={back} />
        <Button className="submit-button hidden" action={next} title={t('treatment_experience.submit')} />
      </div>
      <div className="no-side-effects-button hidden">{t('treatment_experience.no_side_effects')}</div>
    </PopupFooter>
  );

  return (
    <div className="treatment-survey">
      {render()}
      {renderFooter()}
    </div>
  );
};

export default TreatmentSurvey;
