import React from 'react';
import { useTranslation } from 'utils/modifiedTranslation';
import SadIcon from 'new-ui/assets/icons/emojis/sad.svg';
import BadIcon from 'new-ui/assets/icons/emojis/bad.svg';
import HappyIcon from 'new-ui/assets/icons/emojis/happy.svg';
import './style.css';

const TreatmentFeedback = ({ treatment, onSelect }) => {
  const { t } = useTranslation();
  const { id } = treatment;
  
  const emojis = [
    { value: 'medium', icon: SadIcon },
    { value: 'low', icon: BadIcon },
    { value: 'good', icon: HappyIcon }
  ];

  return (
    <div className='treatment-feedback'>
      <h3 className='feedback-title'>{t('events_widget.feedback.title')}</h3>
      <ul className='emoji-list'>
        {emojis.map((emojy, index) => (
          <li key={index} onClick={()=> onSelect(id, emojy.value)}>
            <img src={emojy.icon} alt='icon' />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TreatmentFeedback;