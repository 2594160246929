import {
  diseaseStatus,
  preferredLocation,
  travelLimit,
  race,
  haveInsurance,
  insurance,
  userType,
} from 'components/Questionnaire/constants/globalQuestions';
import { Profile as ID } from 'components/Questionnaire/cancers/cll/CllProfile.enum';
import {
  treatmentCyclesOptions,
  // takingStateOptions,
  yesAndNoOptions,
} from 'components/Questionnaire/constants/globalOptions';
import { QUESTION_VIEW_TYPES } from 'components/Questionnaire/constants/questionViewTypes';
import { dateOfBirth, initialDiagnosisDate } from '../../constants/globalQuestions';
import i from '../../constants/icons';
import {
  MONTH_PICKER,
  SINGLE_OPTION_SELECT,
  MULTIPLE_OPTION_SELECT,
  TREATMENT_HISTORY,
  NUMBER_INPUT,
  BIOMARKERS,
  OPTIONS_FROM_SERVER,
} from '../../constants/questionTypes';
import SECTIONS from '../../constants/questionSections';
import dateType from '../../../../utils/constants/dateTypes';
import ConditionTypes from '../../constants/conditions';
import NumberType from '../../../../utils/constants/numberTypes';
import GLOBAL_ENUMS from '../../constants/globalEnums';

const { biomarkers: BIOMARKERS_QUESTION_ID } = GLOBAL_ENUMS;
const { OPTIONS_LIST_WITH_SHOW_MORE } = QUESTION_VIEW_TYPES;

const {
  NOT_EQUAL_TO_OR_SKIPPED, CONTAIN_ONE_OF, EQUAL_TO, AGE_LESS_THAN, CONTAINS_ONE_OF_OR_SKIPPED,
} = ConditionTypes;

const Questions = [
  // My cancer
  diseaseStatus,
  {
    q: 'Q5',
    id: ID.type_of_disease,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.cll.type_of_disease.5.title',
    subtitle: '',
    info: {
      title: 'questionnaire.questions.cll.type_of_disease.5.info.title',
      content: 'questionnaire.questions.cll.type_of_disease.5.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.cll.type_of_disease.5.options.cll',
        value: 'cll',
      },
      {
        label: 'questionnaire.questions.cll.type_of_disease.5.options.sll',
        value: 'sll',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q6',
    id: ID.disease_stage,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.cll.disease_stage.6.title',
    subtitle: '',
    info: {
      title: 'questionnaire.questions.cll.disease_stage.6.info.title',
      content: 'questionnaire.questions.cll.disease_stage.6.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.cll.disease_stage.6.options.low',
        value: 'low',
      },
      {
        label: 'questionnaire.questions.cll.disease_stage.6.options.intermediate',
        value: 'intermediate',
      },
      {
        label: 'questionnaire.questions.cll.disease_stage.6.options.high',
        value: 'high',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q7',
    id: ID.risk_group_ipi,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: '',
    order: 0,
    title: 'questionnaire.questions.cll.risk_group_ipi.7.title',
    subtitle: '',
    info: {
      title: 'questionnaire.questions.cll.risk_group_ipi.7.info.title',
      content: 'questionnaire.questions.cll.risk_group_ipi.7.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.cll.risk_group_ipi.7.options.low',
        value: 'low',
      },
      {
        label: 'questionnaire.questions.cll.risk_group_ipi.7.options.intermediate',
        value: 'intermediate',
      },
      {
        label: 'questionnaire.questions.cll.risk_group_ipi.7.options.high',
        value: 'high',
      },
      {
        label: 'questionnaire.questions.cll.risk_group_ipi.7.options.very_high',
        value: 'very_high',
      },
      {
        label: 'questionnaire.questions.cll.risk_group_ipi.7.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q8',
    id: ID.lymphocyte_count,
    type: NUMBER_INPUT,
    section: SECTIONS.MY_CANCER,
    numberType: NumberType.cellUl,
    bgIcon: '',
    order: 0,
    title: 'questionnaire.questions.cll.lymphocyte_count.8.title',
    subtitle: '',
    info: {
      title: '',
      content: '',
    },
    options: [],
    conditions: [],
  },
  {
    q: 'Q9',
    id: ID.platelet_count,
    type: NUMBER_INPUT,
    section: SECTIONS.MY_CANCER,
    numberType: NumberType.uLCube,
    bgIcon: '',
    order: 0,
    title: 'questionnaire.questions.cll.platelet_count.9.title',
    subtitle: '',
    info: {
      title: 'questionnaire.questions.cll.platelet_count.9.info.title',
      content: 'questionnaire.questions.cll.platelet_count.9.info.content',
    },
    options: [],
    conditions: [],
  },
  {
    q: 'Q10',
    id: ID.neutrophil_count,
    type: NUMBER_INPUT,
    section: SECTIONS.MY_CANCER,
    numberType: NumberType.cellUl,
    bgIcon: '',
    order: 0,
    title: 'questionnaire.questions.cll.neutrophil_count.10.title',
    subtitle: '',
    info: {
      title: '',
      content: '',
    },
    options: [],
    conditions: [],
  },
  {
    q: 'Q11',
    id: ID.cll_characteristics,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: '',
    order: 0,
    title: 'questionnaire.questions.cll.cll_characteristics.11.title',
    subtitle: '',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.cll.cll_characteristics.11.options.none',
        value: 'none',
      },
      {
        label: 'questionnaire.questions.cll.cll_characteristics.11.options.autoimmune_anemia',
        value: 'autoimmune_anemia',
      },
      {
        label: 'questionnaire.questions.cll.cll_characteristics.11.options.itp',
        value: 'itp',
      },
      {
        label: 'questionnaire.questions.cll.cll_characteristics.11.options.splenomegaly',
        value: 'splenomegaly',
      },
      {
        label: 'questionnaire.questions.cll.cll_characteristics.11.options.hepatomegaly',
        value: 'hepatomegaly',
      },
      {
        label: 'questionnaire.questions.cll.cll_characteristics.11.options.lymphadenopathy',
        value: 'lymphadenopathy',
      },
      {
        label: 'questionnaire.questions.cll.cll_characteristics.11.options.extranodal',
        value: 'extranodal',
      },
      {
        label: 'questionnaire.questions.cll.cll_characteristics.11.options.fever_fatigue_weight_loss_sweats',
        value: 'fever_fatigue_weight_loss_sweats',
      },
      {
        label: 'questionnaire.questions.cll.cll_characteristics.11.options.cytopenias',
        value: 'cytopenias',
      },
      {
        label: 'questionnaire.questions.cll.cll_characteristics.11.options.progressive_lymphocytosis',
        value: 'progressive_lymphocytosis',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q12',
    id: ID.cll_lesion_measurement,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: '',
    order: 0,
    title: 'questionnaire.questions.cll.cll_lesion_measurement.12.title',
    subtitle: '',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.cll.cll_lesion_measurement.12.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.cll.cll_lesion_measurement.12.options.measurable_nodal_lesion',
        value: 'measurable_nodal_lesion',
      },
      {
        label: 'questionnaire.questions.cll.cll_lesion_measurement.12.options.extranodal',
        value: 'extranodal',
      },
    ],
    conditions: [
      {
        ifId: ID.cll_characteristics,
        type: CONTAIN_ONE_OF,
        ifValue: ['lymphadenopathy', 'extranodal'],
      },
    ],
  },
  {
    q: 'Q13',
    id: ID.cns_richter,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: '',
    order: 0,
    title: 'questionnaire.questions.cll.cns_richter.13.title',
    subtitle: '',
    info: {
      title: 'questionnaire.questions.cll.cns_richter.13.info.title',
      content: 'questionnaire.questions.cll.cns_richter.13.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.cll.cns_richter.13.options.cns',
        value: 'cns',
      },
      {
        label: 'questionnaire.questions.cll.cns_richter.13.options.richter',
        value: 'richter',
      },
      {
        label: 'questionnaire.questions.cll.cns_richter.13.options.none',
        value: 'none',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q14',
    id: ID.have_biopsy,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.cll.have_biopsy.14.title',
    subtitle: '',
    info: {
      title: 'questionnaire.questions.cll.have_biopsy.14.info.title',
      content: 'questionnaire.questions.cll.have_biopsy.14.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.cll.have_biopsy.14.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.cll.have_biopsy.14.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.cll.have_biopsy.14.options.biopsy_possible',
        value: 'biopsy_possible',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q80',
    id: ID.ngs,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.molecular,
    order: 0,
    title: 'questionnaire.questions.cll.ngs.80.title',
    subtitle: '',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.cll.ngs.80.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.cll.ngs.80.options.yes_waiting_for_the_results',
        value: 'yes_waiting_for_the_results',
      },
      {
        label: 'questionnaire.questions.cll.ngs.80.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.cll.ngs.80.options.no_but_planning',
        value: 'no_but_planning',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q15',
    id: BIOMARKERS_QUESTION_ID,
    type: BIOMARKERS,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.molecular,
    order: 0,
    title: 'questionnaire.questions.cll.biomarkers.15.title',
    subtitle: '',
    placeholder: 'questionnaire.questions.biomarkers.placeholder',
    info: {
      title: '',
      content: '',
    },
    conditions: [
      {
        ifId: ID.have_biopsy,
        type: CONTAINS_ONE_OF_OR_SKIPPED,
        ifValue: ['yes'],
      },
    ],
  },
  {
    q: 'Q16',
    id: ID.received_transplant,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: '',
    order: 0,
    title: 'questionnaire.questions.cll.received_transplant.16.title',
    subtitle: '',
    info: {
      title: 'questionnaire.questions.cll.received_transplant.16.info.title',
      content: 'questionnaire.questions.cll.received_transplant.16.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.cll.received_transplant.16.options.allogeneic',
        value: 'allogeneic',
      },
      {
        label: 'questionnaire.questions.cll.received_transplant.16.options.autologous',
        value: 'autologous',
      },
      {
        label: 'questionnaire.questions.cll.received_transplant.16.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.cll.received_transplant.16.options.candidate_no_match',
        value: 'candidate_no_match',
      },
      {
        label: 'questionnaire.questions.cll.received_transplant.16.options.scheduled_related',
        value: 'scheduled_related',
      },
      {
        label: 'questionnaire.questions.cll.received_transplant.16.options.scheduled_unrelated',
        value: 'scheduled_unrelated',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q17',
    id: ID.transplantation_treatment_date,
    type: MONTH_PICKER,
    section: SECTIONS.TREATMENT,
    bgIcon: '',
    order: 0,
    title: 'questionnaire.questions.cll.transplantation_treatment_date.17.title',
    subtitle: '',
    info: {
      title: 'questionnaire.questions.cll.transplantation_treatment_date.17.info.title',
      content: 'questionnaire.questions.cll.transplantation_treatment_date.17.info.content',
    },
    options: [],
    conditions: [
      {
        ifId: ID.received_transplant,
        type: CONTAIN_ONE_OF,
        ifValue: ['allogeneic', 'autologous'],
      },
    ],
    dateType: {
      type: dateType.stopTreatment,
    },
  },
  {
    q: 'Q18',
    id: ID.transplant_outcome,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: '',
    order: 0,
    title: 'questionnaire.questions.cll.transplant_outcome.18.title',
    subtitle: '',
    info: {
      title: 'questionnaire.questions.cll.transplant_outcome.18.info.title',
      content: 'questionnaire.questions.cll.transplant_outcome.18.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.cll.transplant_outcome.18.options.remission',
        value: 'remission',
      },
      {
        label: 'questionnaire.questions.cll.transplant_outcome.18.options.no_response',
        value: 'no_response',
      },
      {
        label: 'questionnaire.questions.cll.transplant_outcome.18.options.relapse',
        value: 'relapse',
      },
    ],
    conditions: [
      {
        ifId: ID.received_transplant,
        type: CONTAIN_ONE_OF,
        ifValue: ['allogeneic', 'autologous'],
      },
    ],
  },
  {
    q: 'Q19',
    id: ID.treatment_for_cll,
    section: SECTIONS.TREATMENT,
    type: SINGLE_OPTION_SELECT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.cll.treatment_for_cll.19.title',
    subtitle: '',
    info: {
      title: 'questionnaire.questions.cll.treatment_for_cll.19.info.title',
      content: 'questionnaire.questions.cll.treatment_for_cll.19.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.cll.treatment_for_cll.19.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.cll.treatment_for_cll.19.options.no',
        value: 'no',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q60',
    id: ID.treatments,
    type: TREATMENT_HISTORY,
    section: SECTIONS.TREATMENT,
    bgIcon: '',
    order: 0,
    title: 'questionnaire.questions.cll.treatments.60.title',
    requiredSubQuestionsId: [ID.drugs_received],
    footer: 'questionnaire.questions.cll.treatments.60.footer',
    info: {
      title: '',
      content: '',
    },
    submitButtonLabel: 'questionnaire.questions.global.treatments.button.submit',
    options: [
      {
        iconText: 'A',
        label: 'questionnaire.questions.cll.treatments.60.options.a',
        value: null,
      },
      {
        iconText: 'B',
        label: 'questionnaire.questions.cll.treatments.60.options.b',
        value: null,
      },
      {
        iconText: 'C',
        label: 'questionnaire.questions.cll.treatments.60.options.c',
        value: null,
      },
    ],
    // shouldAutoOpenSubQuestions: [{
    //   ifId: ID.treatment_for_cll,
    //   type: EQUAL_TO,
    //   ifValue: 'yes',
    // }],
    conditions: [
      {
        ifId: ID.treatment_for_cll,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'no',
      },
    ],
    isRequired: true,
    subQuestions: [
      {
        q: 'Q60a',
        id: ID.drugs_received,
        type: OPTIONS_FROM_SERVER,
        viewType: OPTIONS_LIST_WITH_SHOW_MORE,
        section: SECTIONS.TREATMENT,
        bgIcon: i.treatment,
        order: 0,
        titleIconText: 'A',
        title: 'questionnaire.questions.cll.drugs_received.60a.title',
        subtitle: 'questionnaire.questions.cll.drugs_received.60a.subtitle',
        placeholder: 'questionnaire.questions.drugs_received.placeholder',
        info: {
          title: '',
          content: '',
        },
        questionProps: {
          isSearchableByOptionGroupName: true,
        },
      },
      {
        q: 'Q60f',
        id: ID.cycles,
        type: SINGLE_OPTION_SELECT,
        section: SECTIONS.TREATMENT,
        title: 'questionnaire.questions.cll.cycles.Q60f.title',
        disableNextOnOptionSelect: false,
        options: [...treatmentCyclesOptions],
      },
      {
        q: 'Q60b',
        id: ID.treatment_end_date,
        type: MONTH_PICKER,
        section: SECTIONS.TREATMENT,
        bgIcon: '',
        order: 0,
        titleIconText: 'B',
        title: 'questionnaire.questions.cll.treatment_end_date.60b.title',
        subtitle: '',
        info: {
          title: '',
          content: '',
        },
        options: [],
        conditions: [],
        dateType: {
          type: dateType.stopTreatment,
        },
      },
      {
        q: 'Q60c',
        id: ID.treatment_stop_reason,
        type: SINGLE_OPTION_SELECT,
        section: SECTIONS.TREATMENT,
        bgIcon: '',
        order: 0,
        titleIconText: 'C',
        title: 'questionnaire.questions.cll.treatment_stop_reason.60c.title',
        subtitle: '',
        info: {
          title: '',
          content: '',
        },
        disableNextOnOptionSelect: false,
        options: [
          {
            label: 'questionnaire.questions.cll.treatment_stop_reason.60c.options.treatment_ongoing',
            value: 'treatment_ongoing',
          },
          {
            label: 'questionnaire.questions.cll.treatment_stop_reason.60c.options.end_of_protocol',
            value: 'end_of_protocol',
          },
          {
            label: 'questionnaire.questions.cll.treatment_stop_reason.60c.options.progression',
            value: 'progression',
          },
          {
            label: 'questionnaire.questions.cll.treatment_stop_reason.60c.options.no_response',
            value: 'no_response',
          },
          {
            label: 'questionnaire.questions.cll.treatment_stop_reason.60c.options.relapse',
            value: 'relapse',
          },
          {
            label: 'questionnaire.questions.cll.treatment_stop_reason.60c.options.remission',
            value: 'remission',
          },
          {
            label: 'questionnaire.questions.cll.treatment_stop_reason.60c.options.toxicity',
            value: 'toxicity',
          },
          {
            label: 'questionnaire.questions.cll.treatment_stop_reason.60c.options.partial_response',
            value: 'partial_response',
          },
          {
            label: 'questionnaire.questions.cll.treatment_stop_reason.60c.options.other_medical_conditions',
            value: 'other_medical_conditions',
          },
          {
            label: 'questionnaire.questions.cll.treatment_stop_reason.60c.options.dosing_issues',
            value: 'dosing_issues',
          },
          {
            label: 'questionnaire.questions.cll.treatment_stop_reason.60c.options.scheduling',
            value: 'scheduling',
          },
          {
            label: 'questionnaire.questions.cll.treatment_stop_reason.60c.options.travel_limitation',
            value: 'travel_limitation',
          },
          {
            label: 'questionnaire.questions.cll.treatment_stop_reason.60c.options.financial_reasons_or_insurance',
            value: 'financial_reasons_or_insurance',
          },
          {
            label: 'questionnaire.questions.cll.treatment_stop_reason.60c.options.other',
            value: 'other',
          },
        ],
        conditions: [],
      },
    ],
    // title: '',
    // info: {
    //   title: '',
    //   content: '',
    // },
    // footer: '',
    // type: TREATMENT_HISTORY,
    // section: SECTIONS.TREATMENT,
    // bgIcon: '',
    // order: 0,
    // conditions: [
    //   {
    //     ifId: ID.treatment_for_cll,
    //     type: NOT_EQUAL_TO_OR_SKIPPED,
    //     ifValue: 'no',
    //   },
    // ],
    // subQuestionsIds: [
    //   ID.drugs_received,
    //   ID.treatment_stop_reason,
    // ],
    // options: [],
    // subQuestionsOptions: {
    //   treatment_stop_reason: [
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.no_response',
    //       value: 'no_response',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.end_of_protocol',
    //       value: 'end_of_protocol',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.toxicity',
    //       value: 'toxicity',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.progression',
    //       value: 'progression',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.no_response',
    //       value: 'no_response',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.relapse',
    //       value: 'relapse',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.remission',
    //       value: 'remission',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.partial_response',
    //       value: 'partial_response',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.other',
    //       value: 'other',
    //     },
    //   ],
    //   taking_state: takingStateOptions,
    // },
  },
  {
    q: 'Q20',
    id: ID.supportive_care_type,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: '',
    order: 0,
    title: 'questionnaire.questions.cll.supportive_care_type.20.title',
    subtitle: '',
    info: {
      title: 'questionnaire.questions.cll.supportive_care_type.20.info.title',
      content: 'questionnaire.questions.cll.supportive_care_type.20.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.cll.supportive_care_type.20.options.none',
        value: 'none',
        exclusive: true,
      },
      {
        label: 'questionnaire.questions.cll.supportive_care_type.20.options.esa',
        value: 'esa',
      },
      {
        label: 'questionnaire.questions.cll.supportive_care_type.20.options.transfusions',
        value: 'transfusions',
      },
      {
        label: 'questionnaire.questions.cll.supportive_care_type.20.options.platelet_transfusion',
        value: 'platelet_transfusion',
      },
      {
        label: 'questionnaire.questions.cll.supportive_care_type.20.options.antifibrolytic_agent',
        value: 'antifibrolytic_agent',
      },
      {
        label: 'questionnaire.questions.cll.supportive_care_type.20.options.growth_factors',
        value: 'growth_factors',
      },
      {
        label: 'questionnaire.questions.cll.supportive_care_type.20.options.leukapheresis',
        value: 'leukapheresis',
      },
      {
        label: 'questionnaire.questions.cll.supportive_care_type.20.options.allopurinol_rasburicase',
        value: 'allopurinol_rasburicase',
      },
      {
        label: 'questionnaire.questions.cll.supportive_care_type.20.options.ivig',
        value: 'ivig',
      },
      {
        label: 'questionnaire.questions.cll.supportive_care_type.20.options.other',
        value: 'other',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q21',
    id: ID.current_treatment_systemic_steroids,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.cll.current_treatment_systemic_steroids.21.title',
    subtitle: 'questionnaire.questions.cll.current_treatment_systemic_steroids.21.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.cll.current_treatment_systemic_steroids.21.options.under_10_mg_daily',
        value: 'under_10_mg_daily',
      },
      {
        label: 'questionnaire.questions.cll.current_treatment_systemic_steroids.21.options.over_10_mg_daily',
        value: 'over_10_mg_daily',
      },
      {
        label: 'questionnaire.questions.cll.current_treatment_systemic_steroids.21.options.none',
        value: 'none',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q22',
    id: ID.systemic_steroids_treatment_end_date,
    type: MONTH_PICKER,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.cll.systemic_steroids_treatment_end_date.22.title',
    subtitle: '',
    info: {
      title: '',
      content: '',
    },
    options: [],
    conditions: [
      {
        ifId: ID.current_treatment_systemic_steroids,
        type: CONTAIN_ONE_OF,
        ifValue: ['under_10_mg_daily', 'over_10_mg_daily'],
      },
    ],
    dateType: {
      type: dateType.stopTreatment,
    },
  },
  {
    q: 'Q23',
    id: ID.ecog,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.cll.ecog.23.title',
    subtitle: '',
    info: {
      title: 'questionnaire.questions.cll.ecog.23.info.title',
      content: 'questionnaire.questions.cll.ecog.23.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.cll.ecog.23.options.0',
        value: '0',
      },
      {
        label: 'questionnaire.questions.cll.ecog.23.options.1',
        value: '1',
      },
      {
        label: 'questionnaire.questions.cll.ecog.23.options.2',
        value: '2',
      },
      {
        label: 'questionnaire.questions.cll.ecog.23.options.3',
        value: '3',
      },
      {
        label: 'questionnaire.questions.cll.ecog.23.options.4',
        value: '4',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q24',
    id: ID.gvhd,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: '',
    order: 0,
    title: 'questionnaire.questions.cll.gvhd.24.title',
    subtitle: '',
    info: {
      title: 'questionnaire.questions.cll.gvhd.24.info.title',
      content: 'questionnaire.questions.cll.gvhd.24.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.cll.gvhd.24.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.cll.gvhd.24.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.cll.gvhd.24.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: ID.received_transplant,
        type: CONTAIN_ONE_OF,
        ifValue: ['allogeneic'],
      },
    ],
  },
  {
    q: 'Q25',
    id: ID.other_medical_condition,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.cll.other_medical_condition.25.title',
    subtitle: 'questionnaire.questions.cll.other_medical_condition.25.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.cll.other_medical_condition.25.options.no_other_health_issues',
        value: 'no_other_health_issues',
        exclusive: true,
      },
      {
        label: 'questionnaire.questions.cll.other_medical_condition.25.options.liver',
        value: 'liver',
      },
      {
        label: 'questionnaire.questions.cll.other_medical_condition.25.options.heart',
        value: 'heart',
      },
      {
        label: 'questionnaire.questions.cll.other_medical_condition.25.options.kidney',
        value: 'kidney',
      },
      {
        label: 'questionnaire.questions.cll.other_medical_condition.25.options.autoimmune',
        value: 'autoimmune',
      },
      {
        label: 'questionnaire.questions.cll.other_medical_condition.25.options.mental',
        value: 'mental',
      },
      {
        label: 'questionnaire.questions.cll.other_medical_condition.25.options.allergies',
        value: 'allergies',
      },
      {
        label: 'questionnaire.questions.cll.other_medical_condition.25.options.chronic_infection_or_inflammation',
        value: 'chronic_infection_or_inflammation',
      },
      {
        label: 'questionnaire.questions.cll.other_medical_condition.25.options.gastrointestinal',
        value: 'gastrointestinal',
      },
      {
        label: 'questionnaire.questions.cll.other_medical_condition.25.options.other_cancer_type',
        value: 'other_cancer_type',
      },
      {
        label: 'questionnaire.questions.cll.other_medical_condition.25.options.anemia',
        value: 'anemia',
      },
      {
        label: 'questionnaire.questions.cll.other_medical_condition.25.options.thyroid',
        value: 'thyroid',
      },
      {
        label: 'questionnaire.questions.cll.other_medical_condition.25.options.hypertension',
        value: 'hypertension',
      },
      {
        label: 'questionnaire.questions.cll.other_medical_condition.25.options.epilepsy',
        value: 'epilepsy',
      },
      {
        label: 'questionnaire.questions.cll.other_medical_condition.25.options.diabetes',
        value: 'diabetes',
      },
      {
        label: 'questionnaire.questions.cll.other_medical_condition.25.options.organ_transplant',
        value: 'organ_transplant',
      },
      {
        label: 'questionnaire.questions.cll.other_medical_condition.25.options.extreme_obesity',
        value: 'extreme_obesity',
      },
      {
        label: 'questionnaire.questions.cll.other_medical_condition.25.options.stroke',
        value: 'stroke',
      },
      {
        label: 'questionnaire.questions.cll.other_medical_condition.25.options.lung',
        value: 'lung',
      },
      {
        label: 'questionnaire.questions.cll.other_medical_condition.25.options.neuropathy',
        value: 'neuropathy',
      },
      {
        label: 'questionnaire.questions.cll.other_medical_condition.25.options.bleeding',
        value: 'bleeding',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q26',
    id: ID.omc_liver,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.cll.omc_liver.26.title',
    subtitle: '',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.cll.omc_liver.26.options.elevated_alt_ast',
        value: 'elevated_alt_ast',
      },
      {
        label: 'questionnaire.questions.cll.omc_liver.26.options.elevated_bilirubin',
        value: 'elevated_bilirubin',
      },
      {
        label: 'questionnaire.questions.cll.omc_liver.26.options.cirrhosis',
        value: 'cirrhosis',
      },
      {
        label: 'questionnaire.questions.cll.omc_liver.26.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['liver'],
      },
    ],
  },
  {
    q: 'Q27',
    id: ID.omc_heart,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.cll.omc_heart.27.title',
    subtitle: '',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.cll.omc_heart.27.options.class_at_least_nyha_ii',
        value: 'class_at_least_nyha_ii',
      },
      {
        label: 'questionnaire.questions.cll.omc_heart.27.options.condition_at_least_grade_2',
        value: 'condition_at_least_grade_2',
      },
      {
        label: 'questionnaire.questions.cll.omc_heart.27.options.cardiac_ventricular_arrhythmia',
        value: 'cardiac_ventricular_arrhythmia',
      },
      {
        label: 'questionnaire.questions.cll.omc_heart.27.options.heart_failure',
        value: 'heart_failure',
      },
      {
        label: 'questionnaire.questions.cll.omc_heart.27.options.lvef_under_40_percent',
        value: 'lvef_under_40_percent',
      },
      {
        label: 'questionnaire.questions.cll.omc_heart.27.options.qtcf_abnormal',
        value: 'qtcf_abnormal',
      },
      {
        label: 'questionnaire.questions.cll.omc_heart.27.options.angina',
        value: 'angina',
      },
      {
        label: 'questionnaire.questions.cll.omc_heart.27.options.myocardial_infarction_less_than_6_months',
        value: 'myocardial_infarction_less_than_6_months',
      },
      {
        label: 'questionnaire.questions.cll.omc_heart.27.options.myocardial_infarction_more_than_6_months',
        value: 'myocardial_infarction_more_than_6_months',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['heart'],
      },
    ],
  },
  {
    q: 'Q28',
    id: ID.omc_stroke_date,
    type: MONTH_PICKER,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.cll.omc_stroke_date.28.title',
    subtitle: '',
    info: {
      title: '',
      content: '',
    },
    options: [],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['stroke'],
      },
    ],
    dateType: {
      type: dateType.stopTreatment,
    },
  },
  {
    q: 'Q29',
    id: ID.omc_kidney,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.cll.omc_kidney.29.title',
    subtitle: '',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.cll.omc_kidney.29.options.low_glomerular_filtration_rate',
        value: 'low_glomerular_filtration_rate',
      },
      {
        label: 'questionnaire.questions.cll.omc_kidney.29.options.dialysis',
        value: 'dialysis',
      },
      {
        label: 'questionnaire.questions.cll.omc_kidney.29.options.kidney_stones',
        value: 'kidney_stones',
      },
      {
        label: 'questionnaire.questions.cll.omc_kidney.29.options.creatinine_high',
        value: 'creatinine_high',
      },
      {
        label: 'questionnaire.questions.cll.omc_kidney.29.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['kidney'],
      },
    ],
  },
  {
    q: 'Q30',
    id: ID.omc_autoimmune,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.cll.omc_autoimmune.30.title',
    subtitle: '',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.cll.omc_autoimmune.30.options.congenital_or_acquired_immunosuppression',
        value: 'congenital_or_acquired_immunosuppression',
      },
      {
        label: 'questionnaire.questions.cll.omc_autoimmune.30.options.hepatitis',
        value: 'hepatitis',
      },
      {
        label: 'questionnaire.questions.cll.omc_autoimmune.30.options.granulomatous_disease',
        value: 'granulomatous_disease',
      },
      {
        label: 'questionnaire.questions.cll.omc_autoimmune.30.options.rheumatoid_arthritis',
        value: 'rheumatoid_arthritis',
      },
      {
        label: 'questionnaire.questions.cll.omc_autoimmune.30.options.scleroderma',
        value: 'scleroderma',
      },
      {
        label: 'questionnaire.questions.cll.omc_autoimmune.30.options.lupus',
        value: 'lupus',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['autoimmune'],
      },
    ],
  },
  {
    q: 'Q31',
    id: ID.omc_mental,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.cll.omc_mental.31.title',
    subtitle: '',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.cll.omc_mental.31.options.dementia',
        value: 'dementia',
      },
      {
        label: 'questionnaire.questions.cll.omc_mental.31.options.neuropsychyatric',
        value: 'neuropsychyatric',
      },
      {
        label: 'questionnaire.questions.cll.omc_mental.31.options.depression',
        value: 'depression',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['mental'],
      },
    ],
  },
  {
    q: 'Q32',
    id: ID.omc_allergy,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.cll.omc_allergy.32.title',
    subtitle: '',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.cll.omc_allergy.32.options.interferon',
        value: 'interferon',
      },
      {
        label: 'questionnaire.questions.cll.omc_allergy.32.options.metformin',
        value: 'metformin',
      },
      {
        label: 'questionnaire.questions.cll.omc_allergy.32.options.vitd',
        value: 'vitd',
      },
      {
        label: 'questionnaire.questions.cll.omc_allergy.32.options.penicillin',
        value: 'penicillin',
      },
      {
        label: 'questionnaire.questions.cll.omc_allergy.32.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['allergies'],
      },
    ],
  },
  {
    q: 'Q33',
    id: ID.omc_chronic_infection_inflammation,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.cll.omc_chronic_infection_inflammation.33.title',
    subtitle: '',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.cll.omc_chronic_infection_inflammation.33.options.hbv_hbc',
        value: 'hbv_hcv',
      },
      {
        label: 'questionnaire.questions.cll.omc_chronic_infection_inflammation.33.options.hiv',
        value: 'hiv',
      },
      {
        label: 'questionnaire.questions.cll.omc_chronic_infection_inflammation.33.options.cmv',
        value: 'cmv',
      },
      {
        label: 'questionnaire.questions.cll.omc_chronic_infection_inflammation.33.options.uncontrolled_infection',
        value: 'uncontrolled_infection',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['chronic_infection_or_inflammation'],
      },
    ],
  },
  {
    q: 'Q34',
    id: ID.omc_gastro_problem,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.cll.omc_gastro_problem.34.title',
    subtitle: '',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.cll.omc_gastro_problem.34.options.gastrointestinal_bleeding',
        value: 'gastrointestinal_bleeding',
      },
      {
        label: 'questionnaire.questions.cll.omc_gastro_problem.34.options.crohns',
        value: 'crohns',
      },
      {
        label: 'questionnaire.questions.cll.omc_gastro_problem.34.options.colitis',
        value: 'colitis',
      },
      {
        label: 'questionnaire.questions.cll.omc_gastro_problem.34.options.ibs',
        value: 'ibs',
      },
      {
        label: 'questionnaire.questions.cll.omc_gastro_problem.34.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['gastrointestinal'],
      },
    ],
  },
  {
    q: 'Q35',
    id: ID.omc_lung_disease,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.cll.omc_lung_disease.35.title',
    subtitle: '',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.cll.omc_lung_disease.35.options.dyspnea',
        value: 'dyspnea',
      },
      {
        label: 'questionnaire.questions.cll.omc_lung_disease.35.options.tuberculosis',
        value: 'tuberculosis',
      },
      {
        label: 'questionnaire.questions.cll.omc_lung_disease.35.options.copd',
        value: 'copd',
      },
      {
        label: 'questionnaire.questions.cll.omc_lung_disease.35.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['lung'],
      },
    ],
  },
  {
    q: 'Q36',
    id: ID.omc_abnormal_blood_results,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.cll.omc_abnormal_blood_results.36.title',
    subtitle: '',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.cll.omc_abnormal_blood_results.36.options.none',
        value: 'none',
      },
      {
        label: 'questionnaire.questions.cll.omc_abnormal_blood_results.36.options.low_wbc',
        value: 'low_wbc',
      },
      {
        label: 'questionnaire.questions.cll.omc_abnormal_blood_results.36.options.low_hemoglobin',
        value: 'low_hemoglobin',
      },
      {
        label: 'questionnaire.questions.cll.omc_abnormal_blood_results.36.options.low_neutrophil_count',
        value: 'low_neutrophil_count',
      },
      {
        label: 'questionnaire.questions.cll.omc_abnormal_blood_results.36.options.low_platelet_count',
        value: 'low_platelet_count',
      },
      {
        label: 'questionnaire.questions.cll.omc_abnormal_blood_results.36.options.low_potassium',
        value: 'low_potassium',
      },
      {
        label: 'questionnaire.questions.cll.omc_abnormal_blood_results.36.options.low_magnesium',
        value: 'low_magnesium',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q37',
    id: ID.other_medications,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.cll.other_medications.37.title',
    subtitle: '',
    info: {
      title: 'questionnaire.questions.cll.other_medications.37.info.title',
      content: 'questionnaire.questions.cll.other_medications.37.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.cll.other_medications.37.options.cyp3a4_inhibitors',
        value: 'cyp3a4_inhibitors',
      },
      {
        label: 'questionnaire.questions.cll.other_medications.37.options.cyp3a4_inducers',
        value: 'cyp3a4_inducers',
      },
      {
        label: 'questionnaire.questions.cll.other_medications.37.options.immunosuppressive_medications',
        value: 'immunosuppressive_medications',
      },
      {
        label: 'questionnaire.questions.cll.other_medications.37.options.anticonvulsants',
        value: 'anticonvulsants',
      },
      {
        label: 'questionnaire.questions.cll.other_medications.37.options.warfarin',
        value: 'warfarin',
      },
      {
        label: 'questionnaire.questions.cll.other_medications.37.options.proton_pump_inhibitors',
        value: 'proton_pump_inhibitors',
      },
      {
        label: 'questionnaire.questions.cll.other_medications.37.options.none',
        value: 'none',
      },
    ],
    conditions: [],
  },
  initialDiagnosisDate(),
  userType,
  dateOfBirth,
  {
    q: 'Q2',
    id: ID.gender,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.ABOUT_ME,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.cll.gender.2.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.cll.gender.2.options.female',
        value: 'female',
      },
      {
        label: 'questionnaire.questions.cll.gender.2.options.male',
        value: 'male',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q3',
    id: ID.is_pregnant,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.ABOUT_ME,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.cll.is_pregnant.3.title',
    info: {
      title: 'questionnaire.questions.cll.is_pregnant.3.info.title',
      content: 'questionnaire.questions.cll.is_pregnant.3.info.content',
    },
    options: yesAndNoOptions,
    conditions: [
      {
        ifId: ID.gender,
        type: CONTAIN_ONE_OF,
        ifValue: ['female'],
      },
      {
        type: AGE_LESS_THAN,
        ifValue: 50,
      },
    ],
  },
  {
    q: 'Q4',
    id: ID.is_breastfeeding,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.ABOUT_ME,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.cll.is_breastfeeding.4.title',
    info: {
      title: 'questionnaire.questions.cll.is_breastfeeding.4.info.title',
      content: 'questionnaire.questions.cll.is_breastfeeding.4.info.content',
    },
    options: yesAndNoOptions,
    conditions: [
      {
        ifId: ID.gender,
        type: EQUAL_TO,
        ifValue: 'female',
      },
      {
        type: AGE_LESS_THAN,
        ifValue: 50,
      },
    ],
  },
  // About me
  race,
  haveInsurance,
  insurance,
  preferredLocation,
  travelLimit,
];

export default Questions;
