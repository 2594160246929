import React from 'react';
import './Personalize.css';

import { useTranslation } from 'utils/modifiedTranslation';
import Form from 'new-ui/Components/Form';
import PropTypes from 'prop-types';

import app from 'new-ui/app';
import { getSearchObject, getTrialsGroups } from 'new-ui/functions';

import {
  PopupTitle, PopupContent, PopupButton,
} from 'new-ui/Components/Popup';

const Personalize = (props) => {
  const {
    onSave, openLocationPopup, trials = app.trials, noLocation, noNew, filters = getSearchObject(),
  } = props;
  const { t } = useTranslation();

  const distance = app.user?.personal?.travel_limit;

  let data = {
    type: [],
    phase: [],
    ...filters,
  };

  if (data.new) data.new = true;

  const groups = getTrialsGroups(trials);
  const types = Object.keys(groups.type);

  const inputs = {
    type: {
      type: 'multi-checkbox',
      title: t('personalize.preferred_treatment_type'),
      options: types,
    },
    phase: {
      type: 'multi-checkbox',
      title: t('personalize.phase'),
      options: [
        {
          name: '1',
          title: `${t('personalize.phase')} 1`,
        },
        {
          name: '2',
          title: `${t('personalize.phase')} 2`,
        },
        {
          name: '3',
          title: `${t('personalize.phase')} 3`,
        },
        {
          name: '4',
          title: `${t('personalize.phase')} 4`,
        },
      ],
    },
    new: {
      type: 'checkbox',
      label: t('personalize.status'),
      title: t('personalize.new_matches_only'),
    },
  };

  if (noNew) delete inputs.new;

  const form = {
    inputs,
    data,
  };

  const formChange = (newData) => {
    data = newData;
  };

  return (
    <div className="personalize">
      <PopupTitle title={t('personalize.personalize_your_experience')} />
      <PopupContent>
        <Form inputs={form.inputs} data={form.data} onChange={formChange} />
        {
          noLocation ? null : (
            <div className="change-location-button" onClick={openLocationPopup}>{t('personalize.change_location')}</div>
          )
        }
      </PopupContent>
      <PopupButton
        title="Save"
        action={() => {
          const filters = { ...data };
          if (filters.distance === distance) delete filters.distance;
          onSave(filters);
        }}
      />
    </div>
  );
};

Personalize.propTypes = {
  onSave: PropTypes.func,
  openLocationPopup: PropTypes.func,
  trials: PropTypes.array,
  noLocation: PropTypes.bool,
  noNew: PropTypes.bool,
  filters: PropTypes.object,
};

export default Personalize;
