import { updateAppSettings } from '../../utils/actions/appSettings';
import APP_SETTINGS from '../../utils/constants/appSettings';
import MAIN_SETTINGS from '../../utils/constants/main';
import LOCAL_STORAGE_KEYS from '../../utils/constants/localStorageKeys';
import LANGUAGE from './constants/language';

export const changeLanguage = (payload) => (dispatch) => {
  const settings = { ...APP_SETTINGS };

  if (payload === LANGUAGE.he.SHORT) {
    settings.showTreatmentFinder = false;
    settings.showRaceQuestion = false;
    settings.showDistanceQuestion = false;
    settings.showLocationQuestion = false;
    settings.showChatBot = false;
    settings.showChangeLocation = false;
    settings.openTrialInDetailsTab = false;
    settings.showMatchesTalkToOncologistButton = false;
    settings.showMatchesDetailTab = false;
    settings.showDownloadTrialList = true;
  }

  dispatch(updateAppSettings(settings));
  localStorage.setItem(LOCAL_STORAGE_KEYS.LANGUAGE, payload);

  dispatch({
    type: MAIN_SETTINGS.SET_LANG,
    payload,
  });
};
