import React from 'react';
import { useTranslation } from 'utils/modifiedTranslation';
import AIIcon from './assets/AIIcon.svg';
import './SendEmailAnalyzing.css';

const SendEmailAnalyzing = ()=>{
  const { t } = useTranslation();
  return (
    <div className="send-email-analyzing">
      <div>
        <img src={AIIcon} alt="receiving-img" />
        <div className="text">{t('upload.send_email_analyzing_text')}</div>
      </div>
    </div>
  );
};

export default SendEmailAnalyzing;
