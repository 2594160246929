import React from 'react';
import './style.css';
import HospitalIcon from 'new-ui/assets/icons/hospital.svg';

const SettingsLabel = ({ options }) => {
  return (
    <div className='settings-list'>
      <img src={HospitalIcon} alt='icon' />
      <span>
        {options.join(', ')}
      </span>
    </div>
  );
};

export default SettingsLabel;