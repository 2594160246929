/* eslint-disable no-prototype-builtins */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Drawer } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// ICONS
import DownloadIcon from 'new-ui/assets/icons/_download.svg';
// import EditIconOrange from 'new-ui/assets/icons/edit_orange.svg';
import PlayIcon from 'new-ui/assets/icons/play.svg';
import EditIcon from 'new-ui/assets/icons/edit.svg';
import PrintIcon from 'new-ui/assets/icons/_print.svg';
import arrowCircleRight from 'new-ui/assets/icons/arrow-circle-right.svg';
import VIcon from 'new-ui/assets/icons/v.svg';
import XIcon from 'new-ui/assets/icons/x.svg';
import Icon from 'new-ui/assets/icons/profile.svg';
import Tooltip from 'new-ui/assets/icons/tooltip.png';
import TooltipLamp from 'new-ui/assets/icons/tooltip_lamp.svg';

import app from 'new-ui/app';
import { profileTreatmentSectionEvents, GA_CATEGORY_NAME } from 'new-ui/Components/GoogleAnalytics/profileSectionEvents';
import {
  DAY_IN_MS,
  LOCAL_STORAGE_KEYS as LOCAL_STORAGE,
  POPUP_TYPES,
  SURVEYS,
} from 'new-ui/constants';
import ScrollTabs from 'new-ui/Components/ScrollTabs';
import LanguageMenu from 'new-ui/Components/LanguageMenu';
import Preloader from 'new-ui/Components/Preloader';
import {
  PopupContent, PopupProperty, PopupButton,
} from 'new-ui/Components/Popup';
import { ProfileTreatments } from 'new-ui/Components/ProfileTreatments';
import { sortTreatmentByLatest } from 'new-ui/functions/sortTreatmentByLatest';
import {
  filterQuestionsByCondition, getLottie, getOverlay, hasGenderQuestion,
} from 'new-ui/functions';

import globalEnums from 'components/Questionnaire/constants/globalEnums';
import { treatmentForIndicationQuestionIds } from 'components/Questionnaire/constants/treatmentForIndicationQuestionIds';
import { SIDEBAR_POSITIONS, TRANSITION_DURATION } from 'components/Sidebar/sidebarSettings';
import LOCAL_STORAGE_KEYS from 'utils/constants/localStorageKeys';
import { getQuestionnaireRoute } from 'functions/getQuestionnaireRoute';
import clearAuthDataInLocalStorage from 'functions/clearAuthDataInLocalStorage';
import MatchesApi from 'components/Matches/api';
import AiAssistant from 'new-ui/Components/AiAssistent';
import { getAge } from 'functions/getAge';
import { getSurvey } from 'components/Surveys/api';
import LottieAnimationLoading from 'new-ui/assets/lottie/medical_profile.json';
import { getIntakeUnansweredNum } from 'new-ui/Components/Intake';
import { flashIcon } from './functions';
import { getUnreadActionsNum, getProfileActions } from './profileActions';
import PhoneNumber from '../PhoneNumber';

import './MedicalProfilePopup.css';

const {
  profileCriticalInformationPresented,
  profileCriticalInformationClicked,
  profileCriticalInformationAnswered,
} = profileTreatmentSectionEvents;

const PARTS = [
  'diseaseCharacteristics',
  'proceduresAndSurgeries',
  'treatmentHistory',
  'comorbiditiesAndGeneralHealthInformation',
];

const cansersToExcludeSurvey = ['rcc_kidney', 'mesothelioma', 'general'];

const MedicalProfilePopup = (props) => {
  const { highlight, scrollTop = 0 } = props;
  const [drawerContent, setDrawerContent] = useState(null);
  const [profile, setProfile] = useState(null);
  const { t } = useTranslation();
  const history = useHistory();
  const [showTreatmentsAlert, setShowTreatmentsAlert] = useState(false);
  const [myDetailsAlert, setMyDetailsAlert] = useState(false);
  const [showSurveyBtn, setShowSurveyBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tooltipInfoVisible, setTooltipInfoVisible] = useState(false);
  const [filteredProfile, setFilteredProfile] = useState({});
  const [showTreatmentsQuestion, setShowTreatmentsQuestion] = useState(false);
  const {
    userUpdate,
    userGet,
  } = useSelector((state) => ({
    userUpdate: state?.userUpdate || {},
    userGet: state?.userGet || {},
  }));

  let $loading;
  const { condition } = app?.user?.personal || {};
  const isFetching = userUpdate.isFetching
    || userGet.isFetching;
  const lang = localStorage.getItem(LOCAL_STORAGE_KEYS.LANG);
  const { treatments, treatment_for_advanced_metastatic_disease } = app?.user?.condition_profile || {};
  const [lastTreatment, ...previousTreatments] = sortTreatmentByLatest(treatments || []);
  const lastTreatmentDrug = lastTreatment?.drugs_received?.sort()?.join('+');
  let missedQuestions = [
    ...new Set(app.trials.reduce((a, b) => a.concat(b.missed_questions), [])),
    ...new Set(app.treatments ? app.treatments.reduce((a, b) => a.concat(b.missed_questions), []) : null),
  ];
  missedQuestions = [...new Set(missedQuestions)];
  let missedPartsAlert = 0;
  useEffect(() => {
    if (profile && Object.keys(filteredProfile).length) {
      app.sendGoogleAnalyticsEvent(
        GA_CATEGORY_NAME,
        profileCriticalInformationPresented.eventName,
        profileCriticalInformationPresented.getMetaData(missedPartsAlert > 0),
      );
    }
    // eslint-disable-next-line
  }, [profile, filteredProfile]);

  useEffect(() => {
    const fetch = async () => {
      if (!lastTreatmentDrug) return;
      setIsLoading(true);
      const r = await getSurvey(SURVEYS.TREATMENT_EXPERIENCE, app.user.info.user_wix_id);
      const answers = r?.data?.answers || [];
      let hasAnswer = false;
      answers.forEach((ans) => {
        if (ans?.surveyKey === lastTreatmentDrug && ans.key === 'side_effects') {
          hasAnswer = true;
        }
      });
      if (hasAnswer && !cansersToExcludeSurvey.includes(condition)) {
        setShowSurveyBtn(false);
      } else if (!hasAnswer && !cansersToExcludeSurvey.includes(condition)) {
        setShowSurveyBtn(true);
      }
      setIsLoading(false);
    };
    fetch();
  }, [condition, lastTreatmentDrug]);

  useEffect(() => {
    const getProfile = async () => {
      const api = new MatchesApi();
      const r = await api.getFormattedProfile(null, lang, true);
      setProfile(r.result);
      const $prop = document.querySelector('.popup-property.highlighted');
      const $popupEl = document.querySelector('.medical_profile .popup-content');
      if ($popupEl) {
        $popupEl.scrollTop = scrollTop;
      }
      if ($prop) flashIcon(VIcon, $prop);
    };
    getProfile();
  }, [highlight, lang, scrollTop]);

  useEffect(() => {
    const filterParts = async () => {
      const filteredP = {};
      const user = app?.user || {};
      const { condition_profile } = user;
      const filterTreatments = await filterQuestionsByCondition({ questionsIds: ['treatments'], user });
      await Promise.all(PARTS.map(async (name) => {
        if (!profile?.[name]) return null;

        await Promise.all(profile[name].map(async (part) => {
          if (typeof part !== 'string' && part.formatType) {
            if (!filteredP[name]) {
              filteredP[name] = [part];
            } else {
              filteredP[name] = [...filteredP[name], part];
            }
            return;
          }
          let value = part.slice(part.indexOf(':') + 2);
          let question = null;
          const valueParts = value.split('&&');

          if (valueParts.length === 2) {
            [value, question] = valueParts;
            const filteredQuestion = await filterQuestionsByCondition({ questionsIds: [question], user: app?.user });
            if (filteredQuestion.length) {
              if (!filteredP[name]) {
                filteredP[name] = [part];
              } else {
                filteredP[name] = [...filteredP[name], part];
              }
            }
            return;
          }

          if (!filteredP[name]) {
            filteredP[name] = [part];
          } else {
            filteredP[name] = [...filteredP[name], part];
          }
        }));
        return null;
      }));
      let showForNewTreatments = false;
      const treatmentForIndicationQuestionId = treatmentForIndicationQuestionIds[user?.personal?.condition];
      const treatmentForIndicationQuestion = user?.condition_profile[treatmentForIndicationQuestionId];
      if (treatmentForIndicationQuestion === 'no' && (
        !user?.personal.answered_no_on_has_treatment_date
        || Date.now() - user?.personal.answered_no_on_has_treatment_date > DAY_IN_MS
      ) && condition_profile?.hasOwnProperty('treatments')) {
        showForNewTreatments = true;
      }
      setShowTreatmentsQuestion((!!filterTreatments.length && condition_profile?.hasOwnProperty('treatments')) || showForNewTreatments);
      setFilteredProfile(filteredP);
    };
    if (profile) {
      filterParts();
    }
  }, [profile]);

  const goToDiseaseStatus = () => {
    history.push(getQuestionnaireRoute({ questionId: globalEnums.disease_status }));
  };
  const edit = () => {
    delete app.user;
    goToDiseaseStatus();
  };
  const logout = () => {
    clearAuthDataInLocalStorage();
    let loginLink = '/login';
    if (app.provider?.code) loginLink += (`?provider=${app.provider.code}`);
    window.location = loginLink;
  };
  const startNew = () => {
    goToDiseaseStatus();
  };
  const printMedicalProfile = () => {
    const matchesApi = new MatchesApi();
    app.setShowPreloader(true);
    matchesApi.printProfilePDF({ lang: 'en' }, () => {
      app.setShowPreloader(false);
    });
  };
  const downloadMedicalProfile = () => {
    const matchesApi = new MatchesApi();
    app.setShowPreloader(true);
    matchesApi.downloadProfilePDF({ lang: 'en' }, () => {
      app.setShowPreloader(false);
    });
  };
  const gotoQuestionnaire = (question, onEdit) => {
    const $popup = document.querySelector('.popup.medical_profile .popup-content');
    const { scrollTop } = $popup;
    setTimeout(() => {
      $popup.scrollTop = 0;
      app.editQuestion(question,
        async () => {
          $loading = getOverlay();
          const $lottie = getLottie(LottieAnimationLoading);
          $loading.append($lottie);
          document.body.appendChild($loading);
          $loading.show();
          app.userSignupInteracted = true;
          if (onEdit) { onEdit(); }
          await app.update({ rematch: true });
          app.setPopup('medical_profile', {
            highlight: question,
            scrollTop,
          });
          $loading.remove();
          app.renderPage();
        });
    }, 10);
  };

  const getTreatments = () => (
    <ProfileTreatments
      showSurveyBtn={showSurveyBtn}
      showTreatmentsAlert={showTreatmentsAlert}
      setShowTreatmentsAlert={setShowTreatmentsAlert}
      gotoQuestionnaire={gotoQuestionnaire}
      setDrawerContent={setDrawerContent}
      profile={app?.user?.condition_profile}
      treatment_for_advanced_metastatic_disease={treatment_for_advanced_metastatic_disease}
      lastTreatment={lastTreatment}
      previousTreatments={previousTreatments}
      treatments={treatments || []}
      lastTreatmentDrug={lastTreatmentDrug}
    />
  );

  const handleMedicalInfo = () => {
    setTooltipInfoVisible((v) => !v);
  };

  const getMedicalInformation = () => {
    const bodyContentArr = [];
    const missedBodyParts = [];

    let alerts = 0;

    PARTS.forEach(async (name, key) => {
      if (!filteredProfile?.[name]) return null;
      const bodyMainContent = [];
      const bodyMissedContent = [];

      filteredProfile[name].map(async (part, k) => {
        if (typeof part !== 'string' && part.formatType) {
          if (part.values.length && part.values.length === 1 && part.values[0].includes('Unknown')) {
            return;
          }
          bodyMainContent.push((
            <div key={k} className="part-box" onClick={() => { gotoQuestionnaire('procedures'); }}>
              {part.values.map((v, key2) => <div key={key2} className="part-box-value">{v}</div>)}
            </div>
          ));
          return;
        }
        const title = part.slice(0, part.indexOf(':'));
        let value = part.slice(part.indexOf(':') + 2);
        let question = null;
        let editMenu = null;
        let onClick = null;
        const valueParts = value.split('&&');
        let propertyClass = 'profile-property';

        if (valueParts.length === 2) {
          [value, question] = valueParts;
          const isUnknown = (value === 'Unknown' || value.includes('null'));
          if (highlight && highlight === question) propertyClass += ' highlighted';
          editMenu = {
            action: () => {
              gotoQuestionnaire(question);
            },
            title: isUnknown ? t('medical_profile.add') : null,
            icon: !isUnknown ? EditIcon : null,
          };
          if (isUnknown) {
            alerts += 1;
            propertyClass += ' empty';
            value = '';
          }

          onClick = () => {
            gotoQuestionnaire(question);
          };
          if (missedQuestions.includes(question) && isUnknown) {
            const onEdit = () => {
              app.sendGoogleAnalyticsEvent(
                GA_CATEGORY_NAME,
                profileCriticalInformationAnswered.eventName,
                profileCriticalInformationAnswered.getMetaData(),
              );
            };
            missedPartsAlert += 1;
            bodyMissedContent.push((
              <PopupProperty
                onClick={() => gotoQuestionnaire(question, onEdit)}
                key={part + k + key}
                title={title}
                value={value}
                sideButton={{
                  action: () => {
                    app.sendGoogleAnalyticsEvent(
                      GA_CATEGORY_NAME,
                      profileCriticalInformationClicked.eventName,
                      profileCriticalInformationClicked.getMetaData(),
                    );
                    gotoQuestionnaire(question, onEdit);
                  },
                  title: isUnknown ? t('medical_profile.add') : null,
                  icon: !isUnknown ? EditIcon : null,
                }}
                className={propertyClass}
              />
            ));
            return;
          }
        }

        bodyMainContent.push((
          <PopupProperty
            onClick={onClick}
            key={part + k + key}
            title={title}
            value={value}
            sideButton={editMenu}
            className={propertyClass}
          />
        ));
      });

      bodyContentArr.push(
        ...bodyMainContent,
      );
      missedBodyParts.push(
        ...bodyMissedContent,
      );
      return null;
    });

    return {
      title: `${t('popup.navbar.my_info')}`,
      body: (
        <div className="part medical-information-wrapper">
          <div className="section-title-wrapper">
            {alerts > 0 && <span>{alerts}</span>}
            <h3>{t('popup.medical.my_info')}</h3>
          </div>
          {missedBodyParts.length > 0 && (
            <div className="section-missed-parts">
              <div className="section-title-wrapper">
                {missedPartsAlert > 0 && <span>{missedPartsAlert}</span>}
                <div className="title-wrapper">
                  <h3>Critical Information</h3>
                  <img
                    alt="tooltip"
                    src={Tooltip}
                    className="close"
                    onClick={handleMedicalInfo}
                  />
                </div>
              </div>
              <div className={`tooltip-wrapper ${tooltipInfoVisible ? 'visible' : ''}`}>
                <div className="title-close"><img onClick={handleMedicalInfo} src={XIcon} alt="tooltip close" /></div>
                <div className="title-content">
                  <img src={TooltipLamp} alt="tooltip img" />
                  <div>
                    <h3>{t('medical_profile.critical_tooltip.title')}</h3>
                    <p>
                      {t('medical_profile.critical_tooltip.body')}
                    </p>
                  </div>
                </div>
              </div>
              {missedBodyParts}
            </div>
          )}
          {bodyContentArr}
        </div>
      ),
      alert: alerts,
    };
  };

  const getMyDetails = () => {
    let alerts = 0;
    const questions = [
      {
        question: 'family_name',
        title: 'Name',
        val: app?.user?.info?.family_name,
        condition: true,
      },
      {
        title: 'Email',
        val: app?.user?.info?.email,
        condition: !!app?.user?.info?.email,
      },
      {
        question: 'phone_number',
        title: 'Phone',
        val: app?.user?.info?.phone,
        clickHandle: () => {
          const drawerContent = (
            <PhoneNumber
              closePopup={() => {
                setDrawerContent(null);
                app.setPopup(POPUP_TYPES.MEDICAL_PROFILE);
              }}
            />
          );
          setDrawerContent(drawerContent);
        },
        condition: true,
      },
      {
        question: 'preferred_location',
        title: 'Location',
        val: (!app?.user?.info?.country?.label || app?.user?.info?.country?.label === 'user location') ? null : app?.user?.info?.country?.label,
        condition: true,
      },
      {
        question: 'travel_limit',
        title: 'Preferred distance',
        val: app?.user?.personal?.travel_limit,
        condition: true,
      },
      {
        question: 'dob',
        title: 'Age',
        val: app?.user?.personal?.dob && getAge(app?.user?.personal?.dob),
        condition: true,
      },
      {
        question: 'gender',
        title: 'Gender',
        val: app?.user?.personal?.gender,
        condition: hasGenderQuestion(),
      },
      {
        question: 'user_type',
        title: 'User type',
        val: app?.user?.info?.user_type,
        condition: true,
      },
      {
        question: 'insurances',
        title: 'Insurance',
        val: (() => {
          let insurances = app?.user?.condition_profile?.insurances;
          insurances = Array.isArray(insurances) ? insurances : insurances?.split(',');

          return insurances
            ? insurances.map(
              (ins) => t(`questionnaire.questions.insurances.${ins}`),
            ).join(' , ')
            : null;
        })(),
        condition: Array.isArray(app?.user?.condition_profile?.have_insurance)
          ? app?.user?.condition_profile?.have_insurance?.[0] === 'have_insurance_yes'
          : app?.user?.condition_profile?.have_insurance === 'have_insurance_yes',
      },
      {
        question: 'race',
        title: 'Race/ethnicity',
        val: app?.user?.condition_profile?.race?.length ? app?.user?.condition_profile?.race?.map(
          (val) => val.split('_').map((part) => part.charAt(0).toUpperCase() + part.slice(1)).join(' '),
        ).join(' , ') : null,
        condition: true,
      },
    ]
      .filter(({ condition }) => !!condition)
      .map(({
        question, title, val, clickHandle,
      }, k) => {
        let propertyClass = 'profile-property';
        let value = val;
        if (highlight && highlight === question) propertyClass += ' highlighted';
        const isUnknown = ((value === 'Unknown') || (value === null));
        let editMenuTitle = isUnknown ? t('medical_profile.add') : null;

        if (question === 'phone_number' && !isUnknown) {
          editMenuTitle = t('medical_profile.update');
        }

        const editMenu = {
          action: () => {
            if (clickHandle) {
              clickHandle();
            } else {
              gotoQuestionnaire(question);
            }
          },
          title: editMenuTitle,
          icon: !isUnknown && question !== 'phone_number' ? EditIcon : null,
        };
        if (isUnknown) {
          alerts += 1;
          propertyClass += ' empty';
          value = '';
        }
        const onClick = () => {
          if (clickHandle) {
            clickHandle();
          } else {
            gotoQuestionnaire(question);
          }
        };
        return (
          <PopupProperty
            onClick={question && onClick}
            key={k}
            title={title}
            value={value}
            sideButton={question && editMenu}
            className={propertyClass}
          />
        );
      });
    if (alerts && !myDetailsAlert) {
      setMyDetailsAlert(alerts);
    }
    return (
      <div className="my-details-wrapper">
        <div className="section-title-wrapper">
          {myDetailsAlert && <span>{myDetailsAlert}</span>}
          <h3>My details</h3>
        </div>
        {questions}
      </div>
    );
  };

  const getYourAttention = () => {
    const jsx = [];

    jsx.push(
      <div key="actions" className="your-attention-wrapper part actions">
        <div className="actions-title">
          {getUnreadActionsNum() > 0 && <span>{getUnreadActionsNum()}</span>}
          <p>
            {' '}
            {t('medical_profile.actions.title')}
            {' '}
          </p>
        </div>
        {getProfileActions()}
      </div>,
    );

    setTimeout(() => {
      if (app.setUnreadActions) app.setUnreadActions(0);
      sessionStorage.setItem(LOCAL_STORAGE.ACTIONS_SEEN, true);
    }, 100);

    return jsx;
  };

  const getActions = () => (
    <div className="medical-profile-actions">
      <div className="medical-profile-system-language">
        <LanguageMenu fullWidth />
      </div>
      <div className="popup-link download" onClick={printMedicalProfile}>
        <img src={PrintIcon} alt="PrintIcon" />
        {t('Print your medical profile')}
      </div>
      <div className="popup-link download" onClick={downloadMedicalProfile}>
        <img src={DownloadIcon} alt="DownloadIcon" />
        {t('navbar.menu.item.download_medical_profile')}
      </div>
      <div className="popup-link" onClick={startNew}>
        <img src={PlayIcon} alt="PlayIcon" />
        {t('navbar.menu.item.start_new_medical_profile')}
      </div>
      <div className="popup-link logout" onClick={logout}>
        {t('navbar.menu.item.log_out')}
      </div>
      <PopupButton forwardIcon={arrowCircleRight} title={t('medical_profile.edit_medical_profile')} action={edit} />
    </div>
  );

  const tabs = [
    {
      ...getMedicalInformation(),
    },
    {
      title: 'My details',
      body: getMyDetails(),
      alert: myDetailsAlert,
    },
    {
      title: `${t('popup.navbar.my_journey')}`,
      body: getYourAttention(),
      alert: getUnreadActionsNum(),
    },
    {
      body: getActions(),
    },
  ];

  if (showTreatmentsQuestion) {
    tabs.unshift({
      title: `${t('popup.navbar.my_treatments')}`,
      body: getTreatments(),
      alert: showTreatmentsAlert ? 1 : null,
    });
  }

  const showPreloader = isFetching || isLoading || !profile;

  return showPreloader ? <Preloader isVisible size={0.5} /> : (
    <div className="medical-profile-popup">
      <div className="mobile-only medical-profile-popup-header">
        <div className="icon-wrapper">
          <img alt="icon" src={Icon} />
        </div>
      </div>
      <PopupContent>
        <ScrollTabs
          header={app.isIntakeFlow(app.user?.personal.condition) && getIntakeUnansweredNum() ? (
            <AiAssistant />
            // <div className="new-improve-accuracy">
            //   <div className="new-improve-accuracy-title">
            //     {t('intake.improve_accurracy')}
            //   </div>

          // </div>
          ) : null}
          tabs={tabs}
        />
      </PopupContent>

      {drawerContent && (
        <Drawer
          transitionDuration={TRANSITION_DURATION}
          anchor={SIDEBAR_POSITIONS.TOP}
          PaperProps={{ style: { position: 'absolute' } }}
          BackdropProps={{ style: { position: 'absolute' } }}
          ModalProps={{
            container: document.querySelector('.medical_profile'),
            style: { position: 'absolute' },
          }}
          variant="temporary"
          open={!!drawerContent}
          onClose={() => { setDrawerContent(null); }}
        >
          <div className="medical-drawer-header">
            <img alt="close" src={XIcon} className="close" onClick={() => { setDrawerContent(null); }} />
          </div>
          <div className="medical-drawer-body">
            {drawerContent}
          </div>
        </Drawer>
      )}
    </div>
  );
};
export default MedicalProfilePopup;
