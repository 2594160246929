const GA_CATEGORY_NAME = 'PDF Download';

const pdfDownloadEvents = {
  downloadPDFWithoutSOC: {
    eventName: 'download_pdf_without_SOC',
    getMetaData: () => ({}),
  },
  downloadPDFWithSOC: {
    eventName: 'download_pdf_with_SOC',
    getMetaData: () => ({}),
  },
  downloadPDFProfile: {
    eventName: 'download_pdf_profile',
    getMetaData: () => ({}),
  },
};

export {
  GA_CATEGORY_NAME,
};

export default pdfDownloadEvents;
