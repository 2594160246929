import React from 'react';
import './ClosestTrial.css';

import app from 'new-ui/app';

import { useTranslation } from 'utils/modifiedTranslation';

import Map from 'new-ui/Components/Map';
import TrialLocationIcon from 'new-ui/assets/icons/locationPin.svg';
import consultIcon from 'new-ui/assets/icons/consult_icon.svg';
import IconLine from 'new-ui/Components/IconLine';
import ChatIcon from 'new-ui/assets/icons/chat_icon.svg';
import PropTypes from 'prop-types';
import Button from 'new-ui/Components/Button';
import { POPUP_TYPES } from 'new-ui/constants';
import { isCallUsMode } from 'new-ui/functions';

const ClosestTrial = (props) => {
  const { trial } = props;
  const { t } = useTranslation();
  const center = {
    lat: trial?.closest_facility?.lat,
    lng: trial?.closest_facility?.lng,
  };
  const markers = [];
  const consultWithUs = () => {
    app.setPopup('consult_with_us');
  };

  trial.facilities.forEach((element) => {
    markers.push({
      lat: element.lat,
      lng: element.lng,
      icon: TrialLocationIcon,
    });
  });

  const closest = trial.closest_facility;
  const places = [closest.facility_city, closest.facility_state, closest.facility_country].filter((p) => p);
  return (
    <div className="closest-trial">
      <h2>{t('trial.closest_trial_site')}</h2>
      <div className="box">
        <div className="padding-5">
          <div className="title">
            {closest.facility_name}
          </div>
          <p className="title">
            {`${places.join(', ')}`}
          </p>
          {trial.nearest_facility_distance ? <div className="from-you"><IconLine icon="location" title={`${Math.round(trial.nearest_facility_distance)} ${t('trial.miles_from_you')}`} /></div> : null}
          {trial.isSponsored ? (
            app.user && isCallUsMode() ? (
              <Button
                icon={ChatIcon}
                className="consult-button"
                title={t('call_us.btn.title')}
                action={() => {
                  app.setPopup(POPUP_TYPES.CALLUS, {
                    v2: true,
                    CSS: {
                      width: `${document.querySelector('.call-us-buttons')?.getBoundingClientRect().width || 600}px`,
                    },
                  });
                }}
              />
            )
              : app.user ? (
                <Button
                  icon={consultIcon}
                  className="consult-button"
                  title={t('consult_with_us.title')}
                  action={consultWithUs}
                />
              ) : null
          ) : (
            <Button
              title={t('trial.closest_trial_site_contact')}
              action={() => {
                app.setPopup(POPUP_TYPES.CONNECT_WITH_ONCOLOGIST, { trial });
              }}
              forwardIconArrow
            />
          )}

        </div>
        <div className="map">
          <Map
            center={center}
            markers={markers}
            zoom={markers?.length > 1 ? 3 : 4}
          />
        </div>
        <div className="subtitle">
          {t('trial.this_trial_has_x_locations', { x: trial.facilities.length })}
        </div>
      </div>
    </div>
  );
};

ClosestTrial.propTypes = {
  trial: PropTypes.object,
};

export default ClosestTrial;
