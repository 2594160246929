import { shouldShowTrialByCancerType } from 'components/Sponsor/constants/cancerTypeConditions';
import { checkIfSponsoredTrialTemplateExists } from 'modules/sponsoredTrialTemplate/api';
import { TEMPLATE_CONTENT_BLOCK_NAMES } from 'components/Sponsor/constants';
import { nctNumberToNctId } from 'functions/formValidations/nctNumberToNctId';
import analytics from 'functions/analytics';
import validateEmail from '../../functions/formValidations/validateEmail';
import { getFirstSponsoredTrial } from '../Matches/components/List/functions';
import MatchesApi from '../Matches/api';

export const clearTrialsForPDF = (trials) => trials.map((trial) => ({
  title: trial.title,
  brief_summary: trial.brief_summary,
  url: trial.url,
  _id: trial._id,
  nct_number: trial.nct_number,
  phases: trial.phases,
  study_type: trial.study_type,
  first_submitted: trial.first_submitted,
  enrollment: trial.enrollment,
  sponsor_name: trial.sponsorName,
  groups: trial.groups,
  closest_facility: trial.closest_facility,
  isSponsored: !!trial.isSponsored,
}));

export const validateForm = (values) => {
  const errors = {};
  const requiredFields = ['doctorEmail'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  if (errors.doctorEmail) {
    return errors;
  }

  const emailError = validateEmail(values.doctorEmail);

  if (emailError) {
    errors.doctorEmail = emailError;
  }

  return errors;
};

export const downloadTrialsPdf = async (trials, userData, dispatch, cb, pdfFor) => {
  const isSingleSelectedTrial = trials?.length === 1;

  const singleSelectedSponsoredTrial = isSingleSelectedTrial ? getFirstSponsoredTrial(trials) : null;

  let singleSelectedTrialNctNumber;
  if (singleSelectedSponsoredTrial) {
    singleSelectedTrialNctNumber = singleSelectedSponsoredTrial._id;
  }

  if (
    isSingleSelectedTrial
    && singleSelectedTrialNctNumber
    && shouldShowTrialByCancerType(userData.personalCondition, singleSelectedTrialNctNumber)
    && getFirstSponsoredTrial(trials)
  ) {
    const {
      userWixId, profileId, country, countryState, personalCondition, lang,
    } = userData;

    const conditions = {
      lang,
      isMobile: false,
      isForPDF: true,
      country,
      cancerType: personalCondition,
      nctNumbers: [singleSelectedTrialNctNumber],
    };
    if (countryState) {
      conditions.countryStates = [countryState];
    }

    let shouldDownloadSponsorPDF = false;
    try {
      let success;
      ({ result: shouldDownloadSponsorPDF, success = true } = await checkIfSponsoredTrialTemplateExists(
        nctNumberToNctId(singleSelectedTrialNctNumber),
        conditions,
        TEMPLATE_CONTENT_BLOCK_NAMES.DESCRIPTION,
      ));
      if (!success) {
        throw new Error('checkIfSponsoredTrialTemplateExists response.success is negative');
      }
    } catch (ex) {
      console.error(ex);
      return;
    }

    if (shouldDownloadSponsorPDF) {
      analytics.clickDownloadAndShareOnMinisite();

      await MatchesApi.downloadSponsorPDF(singleSelectedTrialNctNumber, {
        profileId,
        userWixId,
        countryState,
        nctNumber: singleSelectedTrialNctNumber,
        conditions,
        indication: personalCondition,
      });
      return;
    }
  }

  // the case of not sponsored trial(s)
  new MatchesApi(dispatch).downloadTrialsPDF({
    trials: clearTrialsForPDF(trials),
    cancerType: userData?.personalCondition,
    providerCode: userData?.providerCode,
    pdfFor,
  }, cb);
};
