import React from 'react';
import { makeStyles } from '@material-ui/core';

import { logoIcon } from 'utils/static/logo';
import { useSelector } from 'react-redux';

import Logo from 'new-ui/assets/logo.svg';
import { useTranslation } from 'utils/modifiedTranslation';

const useStyles = makeStyles(() => ({
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  rootDiv: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#fff',
    zIndex: 2000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column-reverse',
    marginBottom: '10px',
  },
  logoImage: {
    height: '34px',
    width: '34px',
    animation: '$spin 3s linear infinite',
  },
  logoText: {
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '24px',
    lineHeight: '28px',
    textAlign: 'center',
    color: '#ff8c69',
    marginLeft: '5px',
    marginBottom: 10,
  },
  splashText: {
    fontSize: '24px',
    lineHeight: '28px',
    textAlign: 'center',
    color: '#ff8c69',
  },
}));

const Splash = (props) => {
  const { login } = props;
  const { t } = useTranslation();
  const { appConfiguration: { providerSpecific: provider } = {} } = useSelector((state) => state) ?? {};
  const classes = useStyles({ provider });
  const [text, setText] = React.useState(login ? `${t('splash.please_wait')}...` : t('splash.text.first'));

  React.useEffect(() => {
    const body = document.querySelector('body');
    body.style.overflowY = 'hidden';
    if (login) return () => { };
    setTimeout(() => {
      setText(t('splash.text.second'));

      setTimeout(() => {
        setText(t('splash.text.third'));
      }, 2000);
    }, 2000);
    return () => {
      body.style.overflowY = 'visible';
    };
  }, [t, login]);

  return (
    <div className={classes.rootDiv}>
      <div className={classes.wrapper}>
        <div className={classes.logoContainer}>
          <img src={logoIcon} className={classes.logoImage} alt={t('navbar.logo.alt')} />
          <img src={Logo} className={classes.logoText} alt="Leal Health" />
        </div>
        <p className={classes.splashText}>{text}</p>
      </div>
    </div>
  );
};

export default Splash;
