import React, { useState } from 'react';
import './Form.css';

import phone from 'phone';
import Checkbox from 'new-ui/Components/Form/Checkbox';
import Radio from 'new-ui/Components/Form/Radio';
import Range from 'new-ui/Components/Form/Range';
import Text from 'new-ui/Components/Form/Text';
import Input from 'new-ui/Components/Form/Input';
import PhoneInput from 'new-ui/Components/Form/PhoneInput';
import Select from 'new-ui/Components/Form/Select';
import LocationInput from 'new-ui/Components/Form/LocationInput';
import PropTypes from 'prop-types';
import { COLORS } from 'new-ui/constants';
import SelectV2 from './Select.v2';
import DateInput from './Date';
import { PopupFooter } from '../Popup';
import Button from '../Button';
import IndicationChooser from './IndicationChooser';

export const FORM_INPUT_TYPES = {
  CHECKBOX: 'checkbox',
  MULTI_CHECKBOX: 'multi-checkbox',
  PASSWORD: 'password',
  PHONE: 'phone',
  RADIO: 'radio',
  RANGE: 'range',
  INPUT: 'input',
  TEXT: 'text',
  DATE: 'date',
  SELECT: 'select',
  SELECT_V2: 'select_v2',
  LOCATION: 'location',
  LIST: '',
  INDICATION_CHOOSER: 'indication_chooser',
};

export const FORM_THEMES = {
  DEFAULT: 'default',
  ORANGE: 'orange',
};

const Form = (props) => {
  const [renderKey, setRenderKey] = useState(1);
  const {
    onChange = () => {}, submit, popupMode = false, form, className = '', keyup, onenter, append, theme = FORM_THEMES.DEFAULT,
  } = props;
  let { data, inputs } = props;

  if (form) {
    data = form.data;
    inputs = form.inputs;
    form.render = () => { setRenderKey(Math.random()); }; // for re-rendering the form after data change;
    window._form = form;
  } else if (!data && !inputs && !form) {
    return null;
  }

  const inputChange = (name, newValue) => {
    // warning: state mutation
    data[name] = newValue;
    onChange(data, name, newValue);
  };

  const getInput = (input, renderKey, tabindex) => {
    switch (input.type) {
      default:
      case FORM_INPUT_TYPES.PASSWORD:
      case FORM_INPUT_TYPES.INPUT:
        return (
          <Input
            key={renderKey}
            theme={theme}
            input={input}
            title={input.title || input.name}
            value={data[input.name]}
            required={input.required}
            isPassword={input.type === FORM_INPUT_TYPES.PASSWORD}
            icon={input.icon}
            email={input.email}
            placeholder={input.placeholder}
            keyup={keyup}
            onenter={onenter}
            className={input.className}
            tabindex={tabindex}
            onChange={
              (newValue) => {
                inputChange(input.name, newValue);
              }
            }
          />
        );
      case FORM_INPUT_TYPES.INDICATION_CHOOSER:
        return (
          <IndicationChooser
            key={renderKey}
            {...input}
            value={data[input.name]}
            onChange={
              (newValue) => {
                inputChange(input.name, newValue);
              }
            }
          />
        );
      case FORM_INPUT_TYPES.DATE:
        return (
          <DateInput
            key={renderKey}
            theme={theme}
            tabindex={tabindex}
            input={input}
            value={data[input.name]}
            {...input}
            onChange={
              (newValue) => {
                inputChange(input.name, newValue);
              }
            }
          />
        );
      case FORM_INPUT_TYPES.PHONE:
        return (
          <PhoneInput
            key={renderKey}
            theme={theme}
            tabindex={tabindex}
            placeholder={input.placeholder}
            country={input.country}
            keyup={keyup}
            onenter={onenter}
            onChange={
              (newValue) => {
                inputChange(input.name, newValue);
              }
            }
          />
        );
      case FORM_INPUT_TYPES.TEXT:
        return (
          <Text
            key={renderKey}
            theme={theme}
            tabindex={tabindex}
            title={input.title || input.name}
            value={data[input.name]}
            height={input.height}
            required={input.required}
            onChange={
              (newValue) => {
                inputChange(input.name, newValue);
              }
            }
          />
        );
      case FORM_INPUT_TYPES.LIST:
        return (
          <Checkbox
            isList
            key={renderKey}
            theme={theme}
            tabindex={tabindex}
            title={input.title || input.name}
            checked={data[input.name] || false}
            onChange={
              (newValue) => {
                inputChange(input.name, newValue);
              }
            }
          />
        );
      case FORM_INPUT_TYPES.CHECKBOX:
        return (
          <Checkbox
            key={renderKey}
            theme={theme}
            tabindex={tabindex}
            title={input.title || input.name}
            checked={data[input.name] || false}
            onChange={
              (newValue) => {
                inputChange(input.name, newValue);
              }
            }
          />
        );
      case FORM_INPUT_TYPES.MULTI_CHECKBOX:
        // eslint-disable-next-line no-case-declarations
        const jsx = [];
        input.options.forEach((checkbox, key) => {
          const name = typeof checkbox === 'string' ? checkbox : checkbox.name;
          const values = data[input.name] || [];
          const _checkbox = (
            <Checkbox
              key={key + renderKey}
              tabindex={tabindex}
              theme={theme}
              title={typeof checkbox === 'string' ? checkbox : (checkbox.title || checkbox.name)}
              checked={values.indexOf(name) !== -1}
              onChange={
                (newValue) => {
                  if (values.indexOf(name) !== -1) { values.splice(values.indexOf(name), 1); }
                  if (newValue === true) { values.push(name); }
                  onChange(data, undefined, name);
                }
              }
            />
          );
          window._checkbox = _checkbox;
          jsx.push(
            _checkbox,
          );
        });
        return jsx;
      case FORM_INPUT_TYPES.RADIO:
        return (
          <Radio
            key={renderKey}
            theme={theme}
            options={input.options}
            value={data[input.name]}
            onChange={
              (newValue) => {
                inputChange(input.name, newValue);
              }
            }
          />
        );
      case FORM_INPUT_TYPES.RANGE:
        return (
          <Range
            key={renderKey}
            theme={theme}
            tabindex={tabindex}
            title={input.title}
            single={input.single}
            options={input.options}
            value={data[input.name]}
            onChange={
              (newValue) => {
                inputChange(input.name, newValue);
              }
            }
          />
        );
      case FORM_INPUT_TYPES.SELECT:
        return (
          <Select
            key={renderKey}
            theme={theme}
            {...input}
            tabindex={tabindex}
            value={data[input.name]}
            onChange={
              (newValue) => {
                inputChange(input.name, newValue);
                if (input.onchange) input.onchange(newValue);
              }
            }
          />
        );
      case FORM_INPUT_TYPES.SELECT_V2:
        return (
          <SelectV2
            key={renderKey}
            theme={theme}
            {...input}
            value={data[input.name]}
            tabindex={tabindex}
            onChange={
              (newValue) => {
                inputChange(input.name, newValue);
                if (input.onchange) input.onchange(newValue);
              }
            }
          />
        );
      case FORM_INPUT_TYPES.LOCATION:
        return (
          <LocationInput
            key={renderKey}
            theme={theme}
            tabindex={tabindex}
            {...input}
            value={data[input.name]}
            onChange={
              (newValue) => {
                inputChange(input.name, newValue);
              }
            }
          />
        );
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    submit && submit.action();
  };

  const render = () => Object.keys(inputs).map((inputName, key) => {
    const input = inputs[inputName];
    input.name = inputName;
    return (
      <div className={`form-part ${inputName} ${input.hidden ? 'form-input-hidden' : ''} ${input.disabled ? 'form-part-disabled' : ''}`} key={key}>
        {((input.label || input.title) && !input.noHeader) ? (
          <div className="form-input-title">
            {input.label || input.title}
            {' '}
            {input.required ? <span className="required">*</span> : null}
          </div>
        ) : null}
        <div className={`form-input ${input.type} ${input.className || ''}`}>{getInput(input, renderKey, 0)}</div>
      </div>
    );
  });
  return (
    <form renderkey={renderKey} className={`form ${className || ''}`} onSubmit={onSubmit}>
      {render()}
      {append || null}
      { submit
        ? (popupMode ? (
          <PopupFooter>
            <input type="submit" style={{ backgroundColor: COLORS.primaryButton }} className="button form-submit" value={submit.title} />
          </PopupFooter>
        ) : <Button {...submit} />) : null}
    </form>
  );
};

Form.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func,
  submit: PropTypes.object,
  popupMode: PropTypes.bool,
  data: PropTypes.object,
  form: PropTypes.object,
  className: PropTypes.string,
  theme: PropTypes.string,
  keyup: PropTypes.func,
  onenter: PropTypes.func,
  append: PropTypes.any,
  api: PropTypes.any,
};

export default Form;

const validateEmail = (email) => String(email)
  .toLowerCase()
  .match(
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  );

export const FORM_VALIDATORS = {
  EMAIL: (email) => validateEmail(email),
  PHONE: (phoneNumber) => phone(phoneNumber?.startsWith('+') ? phoneNumber : `+${phoneNumber}`).isValid,
};
