export default {
  en: {
    SHORT: 'en',
    LONG: 'en-US',
    LABEL: 'navbar.menu.item.lang.en',
  },
  he: {
    SHORT: 'he',
    LONG: 'he-IL',
    LABEL: 'navbar.menu.item.lang.he',
  },
  fr: {
    SHORT: 'fr',
    LONG: 'fr-FR',
    LABEL: 'navbar.menu.item.lang.fr',
  },
  es: {
    SHORT: 'es',
    LONG: 'es-ES',
    LABEL: 'navbar.menu.item.lang.es',
  },
  tr: {
    SHORT: 'tr',
    LONG: 'tr-TR',
    LABEL: 'navbar.menu.item.lang.tr',
  },
};

export const pfizerLanguages = {
  en: {
    SHORT: 'en',
    LONG: 'en-US',
    LABEL: 'navbar.menu.item.lang.en',
  },
  es: {
    SHORT: 'es',
    LONG: 'es-ES',
    LABEL: 'navbar.menu.item.lang.es',
  },
};
