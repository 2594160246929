import React from 'react';
import './AiAssistant.css';

import StarImg from 'new-ui/assets/icons/ai.svg';
import UploadIcon from 'new-ui/Pages/Upload/assets/upload-files.svg';
import { useTranslation } from 'utils/modifiedTranslation';
import Button from 'new-ui/Components/Button';
import {
  PAGES, POPUP_TYPES, ROUTES, THEMES,
} from 'new-ui/constants';
import app from 'new-ui/app';
import { getQstack } from 'new-ui/Components/Intake/functions';
import { getFieldsUpdateDates } from 'new-ui/api';
import { CancerFieldsByIndication } from 'new-ui/api/data/cancerTypes';

const AiAssistant = ({
  onUploadClick,
  onEditClick,
})=>{
  const { t } = useTranslation();
  return (
    <div className="profile-header-actions ai-assistant">
      <div className="title">{t('ai_profile.bar_title')}</div>
      <div className="ai-area">
        <div className="upload-nav">
          <div className="info">
            <img src={StarImg} alt="star" />
            <div>
              {t('ai_profile.ai_title')}
            </div>
          </div>
          <div className="buttons">
            <Button
              theme={THEMES.PURPLE}
              action={()=>{
                if (onUploadClick) onUploadClick();
                app.setPopup(POPUP_TYPES.UPLOAD, {
                  theme: 'middle',
                  condition: app.user.personal.condition,
                  onBeforeIntake: async (questions, results, user)=>{
                    window.__userFromExtraction = JSON.parse(JSON.stringify(user));

                    user.condition_profile = Object.assign(app.user.condition_profile, user.condition_profile);
                    user.info = Object.assign(app.user.info, user.info);
                    user.personal = Object.assign(app.user.personal, user.personal);
                    user.qstack = await getQstack(user, app.questions);

                    window.__userBeforeIntake = JSON.parse(JSON.stringify(user));

                    const fields = await getFieldsUpdateDates();
                    console.log(questions, results);
                    const hasDate = results?.files?.find((a)=>a.documentDate);
                    if (!hasDate) return;
                    if (!results?.files) return;
                    const fi = [];
                    results.files.forEach((file)=>{
                      let fileDate = file.documentDate;
                      let num = 0;
                      if (!fileDate) return;
                      fileDate = new Date(file.documentDate);
                      Object.keys(fields).forEach((fieldName)=>{
                        if (fieldName === 'patient_stage') {
                          const patientStageField = CancerFieldsByIndication[app.user.personal.condition].patientStage;
                          fieldName = patientStageField;
                        }
                        const field = fields[fieldName];
                        const fieldDate = new Date(field.date);
                        if (fileDate < fieldDate) {
                          num += 1;
                          console.log('field hide', fieldName);
                          if (fieldName === 'patient_stage') questions.splice(questions.indexOf('current_stage'), 1);
                          else {
                            questions.splice(questions.indexOf(fieldName), 1);
                          }
                        }
                      });
                      console.log('questions after', questions);
                      if (num === Object.keys(fields).length) {
                        fi.push(file);
                      }
                    });
                    if (!questions.length) {
                      app.setPopup(false);
                      app.setPopup(POPUP_TYPES.GENERAL, {
                        theme: 'middle',
                        title: <div>{t('ai_profile.profile_up_to_date')}</div>,
                        content: <div>
                          <Button
                            title={t('ai_profile.close')}
                            action={()=>{
                              app.setPopup(false);
                            }}
                          />
                        </div>,
                      });
                    }
                  },
                  onSuccess: async (extractedProfile, questions)=>{
                    console.log('upload onsucess extractedProfile', extractedProfile, questions);
                    Object.keys(app.user.condition_profile).forEach((field)=>{
                      if (extractedProfile.condition_profile[field]) {
                        app.user.condition_profile[field] = extractedProfile.condition_profile[field];
                        console.log('changed field after ai profile', field);
                      }
                    });
                    const qstack = await getQstack(app.user, app.questions);
                    app.user.condition_profile.qstack = qstack;
                    app.setShowPreloader(true);
                    await app.updateUser(app.user);
                    app.setShowPreloader(false);
                    // app.setPopup(false);
                  },
                });
              }}
              backgroundColor="#C77DFF"
              title={t('ai_profile.upload_button')}
              icon={UploadIcon}
              className="upload-files"
              width="210"
            />
            {/* {!matches ? <div className="or">Or</div> : null}
          <Button
            backgroundColor="#C77DFF"
            title={matches ? 'Analyze NGS' : 'Analyze NGS Report'}
            icon={!matches ? AnalyzeIcon : null}
            className="upload-files"
            width="210"
            action={()=>{
              app.setPopup(POPUP_TYPES.NGS, {
                theme: 'middle',
                onSuccess: (biomarkers) => {
                  console.log('biomarkers found', biomarkers);
                  app.user.condition_profile.biomarkers = biomarkers;
                  app.updateUser();
                  app.setPopup(false);
                },
              });
            }}
          /> */}
          </div>
        </div>
      </div>
      <div className="intake-area">
        <Button
          className="intake-button"
          title={t('general.update_manually')}
          action={()=>{
            app.setPopup(false);
            if (onEditClick) onEditClick();
            app.history.push(`${ROUTES[PAGES.INTAKE]}?unanswered=1`);
            // app.history.push(`${ROUTES[PAGES.INTAKE]}`);
          }}
        />
      </div>
    </div>
  );
};

export default AiAssistant;
