import React, { useState } from 'react';
import './Login.css';

import { PAGES, URI } from 'new-ui/constants';
import Form, { FORM_INPUT_TYPES, FORM_VALIDATORS } from 'new-ui/Components/Form';

import Button from 'new-ui/Components/Button';
import BackButton from 'new-ui/Components/BackButton';
import { useHistory } from 'react-router-dom';
import { authenticationService } from 'components/Authentication/api';
import useRecaptcha from 'utils/hooks/useRecaptcha';
import { useTranslation } from 'utils/modifiedTranslation';

const forgotPasswordForm = {
  inputs: {
    email: {
      type: FORM_INPUT_TYPES.INPUT,
      placeholder: 'Your email',
      icon: 'email',
    },
  },
  data: {
    email: '',
  },
};

const ForgotPassword = () => {
  const { t } = useTranslation();
  const [showError, setError] = useState(null);
  const [showSuccess, setSuccess] = useState(null);
  const history = useHistory();
  const { recaptcha, getRecaptchaTokenAsync } = useRecaptcha();

  forgotPasswordForm.inputs.email.placeholder = t('login.your_email');

  const forgotPassword = async () => {
    const email = forgotPasswordForm?.data?.email;
    if (!FORM_VALIDATORS.EMAIL(forgotPasswordForm?.data?.email)) {
      setError(t('login.invalid_email_address'));
      return false;
    }

    setError(null);

    const r = await authenticationService.forgotPassword({
      email,
      recaptchaToken: await getRecaptchaTokenAsync(),
    });

    if (!r || !r.success) {
      return setError(r ? r.message : t('login.forgot_password_failed'));
    }

    setSuccess(true);
  };

  return (
    <div className="login center flex">
      {recaptcha}
      <div className="forgot-password">
        <div className="back-holder">
          <BackButton action={() => { history.push(URI[PAGES.LOGIN]); }} />
        </div>
        <div className="login-form">
          <div className="title">{t('login.forgot_your_password')}</div>
          {showSuccess ? (
            <div>
              {t('login.password_link_sent')}
            </div>
          ) : (
            <div>
              <div className="text">
                {t('login.enter_the_email_you_used')}
              </div>
              <div className="input-title">
                <div>{t('login.your_email')}</div>
                <div className="required">{t('login.required')}</div>
              </div>
              <Form
                className="forgot-password-form"
                form={forgotPasswordForm}
                onenter={forgotPassword}
              />
              {showError ? <div className="login-error">{showError}</div> : null}
              <div className="login-button-holder">
                <Button action={forgotPassword} title={t('login.submit')} />
              </div>
            </div>
          )}

        </div>
        <div className="padding-20 text-align-center">
          <span className="inline-button" onClick={() => { history.push(`${URI[PAGES.LOGIN]}?type=email`); }}>{t('login.nevermind_i_remember_my_password')}</span>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
