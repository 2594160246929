export const mockSideEffects = [
    {
        "surveyKey": "anastrozole",
        "answerKey": "side_effects",
        "value": "back_or_bone_pain",
        "sideEffectDescription": "Back or bone pain",
        "percent": 7
    },
    {
        "surveyKey": "anastrozole",
        "answerKey": "side_effects",
        "value": "body_aches_or_pain",
        "sideEffectDescription": "Body aches or pain",
        "percent": 6
    },
    {
        "surveyKey": "anastrozole",
        "answerKey": "side_effects",
        "value": "decreased_sex_drive",
        "sideEffectDescription": "decreased sex drive",
        "percent": 3
    },
    {
        "surveyKey": "anastrozole",
        "answerKey": "side_effects",
        "value": "hair_loss_thinning_of_hair",
        "sideEffectDescription": "hair loss, thinning of hair",
        "percent": 3
    }
];


export const mockIntensities = [
    {
        "surveyKey": "anastrozole",
        "answerKey": "aggression",
        "value": "somewhat_aggresive",
        "sideEffectDescription": null,
        "percent": 40
    },
    {
        "surveyKey": "anastrozole",
        "answerKey": "aggression",
        "value": "not_aggressive",
        "sideEffectDescription": null,
        "percent": 35
    },
    {
        "surveyKey": "anastrozole",
        "answerKey": "aggression",
        "value": "very_aggressive",
        "sideEffectDescription": null,
        "percent": 25
    }
];