import configs from 'configs';
import { authHeaders } from 'functions/authHeader';
import request from 'functions/api/request';
import { questionnaireService } from 'components/Questionnaire/api';
import { formatOptionsFromServerData } from 'components/Questionnaire/functions/formatOptionsFromServerData';

const apiUrl = configs.beApiUrl;

export const getRandomQuotes = async () => {
  let response = null;

  const requestOptions = {
    url: `${apiUrl}/webappContent/random?type=quote&limit=1`,
    headers: await authHeaders(),
    json: true,
  };

  try {
    response = await request.get(requestOptions);
  } catch (ex) {
    console.error('Error occurred, while getting quotes', ex);
    throw ex;
  }

  return response?.data;
};

window.__getRandomQuotes = getRandomQuotes;


export const getDrugs = async () => {
  const result = await questionnaireService.getQuestionOptions('drugs_received');
  if (!result.data) {
    throw new Error('no data in response');
  }
  return await formatOptionsFromServerData(result.data, 'drugs_received');
}

window.__getDrugs = getDrugs;