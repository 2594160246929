import React from 'react';
import YouTube from 'react-youtube';
import { isMobile } from 'new-ui/functions';
import './style.css';
import app from 'new-ui/app';

const FinanceReview = ({ review, GA }) => {
  const getVideoOptions = (isMobile) => {
    const options = {
      height: '228',
      width: '339',
    };
    if (isMobile) {
      options.height = '234';
      options.width = '100%';
    }
    return options;
  };

  const options = getVideoOptions(isMobile());

  return (
    <div className="finance-review">
      <div className="video">
        <YouTube
          videoId={review.YOUTUBE_VIDEO_ID}
          opts={options}
          onPlay={()=>{
            app.sendGoogleAnalyticsEvent(GA.category, GA.events.videoStarted, { name: 'Financial support video started' });
          }}
        />
      </div>
      <div className="review">
        <div className="comment">
          "
          {review.comment}
          "
        </div>
        <div className="author">{review.author}</div>
      </div>
    </div>
  );
};

export default FinanceReview;
