import configs from 'configs';
import { authHeaders } from 'functions/authHeader';
import request from 'functions/api/request';

const apiUrl = configs.financeApiUrl;

/*
GET /look-up/categories
GET /programs
POST /webapp/user
GET /webapp/user/me
PUT /webapp/user/me
POST /webapp/programs/match
*/

export const getFinancePrograms = async () => {
  let response = null;

  const requestOptions = {
    url: `${apiUrl}/webapp/programs`,
    headers: await authHeaders(),
    json: true,
  };

  try {
    response = await request.get(requestOptions);
  } catch (ex) {
    console.error('Error occurred, while getting programs', ex);
    throw ex;
  }

  return response?.programs;
};

window.__getFinancePrograms = getFinancePrograms;

export const getFinanceCategorties = async () => {
  let response = null;

  const requestOptions = {
    url: `${apiUrl}/webapp/categories`,
    headers: await authHeaders(),
    json: true,
  };

  try {
    response = await request.get(requestOptions);
    // response = await getMockData(0, CATEGORIES);
  } catch (ex) {
    console.error('Error occurred, while getting profile data:', ex);
    throw ex;
  }

  return response?.categories;
};

window.__getFinanceCategorties = getFinanceCategorties;

export const getFinanceMatch = async (financeParams = {})=>{
  let response = null;

  delete financeParams.userWixId;
  delete financeParams.email;

  const requestOptions = {
    url: `${apiUrl}/webapp/programs/match`,
    headers: await authHeaders(),
    data: financeParams,
    json: true,
  };

  try {
    response = await request.post(requestOptions);
    // response = await getMockData(0, PROGRAMS);
  } catch (ex) {
    console.error('Error occurred, while getting finance match:', ex);
    throw ex;
  }

  return response.matchedPrograms;
};

window.__getFinanceMatch = getFinanceMatch;

export const getFinanceProfile = async ()=>{
  let response = null;

  const requestOptions = {
    url: `${apiUrl}/webapp/user/me`,
    headers: await authHeaders(),
    json: true,
  };

  try {
    response = await request.get(requestOptions);
  } catch (ex) {
    console.error('getFinanceProfile:: Error occurred', ex);
    throw ex;
  }

  return response?.patient;
};

window.__getFinanceProfile = getFinanceProfile;

export const setFinanceUser = async (financeParams)=>{
  let response = null;

  delete financeParams.userWixId;
  delete financeParams.email;
  delete financeParams.location;

  const requestOptions = {
    url: `${apiUrl}/webapp/user/me`,
    headers: await authHeaders(),
    data: financeParams,
    json: true,
  };

  try {
    response = await request.put(requestOptions);
    // response = await getMockData(0, PROGRAMS);
  } catch (ex) {
    console.error('Error occurred, while getting profile data:', ex);
    throw ex;
  }

  return response;
};

window.__setFinanceUser = setFinanceUser;

export const createFinanceUser = async (financeParams)=>{
  let response = null;

  delete financeParams.userWixId;
  delete financeParams.email;
  delete financeParams.location;

  const requestOptions = {
    url: `${apiUrl}/webapp/user`,
    headers: await authHeaders(),
    data: financeParams,
    json: true,
  };

  try {
    response = await request.post(requestOptions);
    // response = await getMockData(0, PROGRAMS);
  } catch (ex) {
    console.error('Error occurred, creating finance profile:', ex);
    throw ex;
  }

  return response;
};

window.__createFinanceUser = createFinanceUser;

// const getMockData = async (delay = 0, options) => {
//   return await new Promise((resolve, reject) => {
//     setTimeout(() => {
//       resolve(options);
//     }, delay);
//   });
// };

// const PROGRAMS = [
//   {
//     title: "Genevieve's Helping Hands",
//     imgUrl: 'https://img001.prntscr.com/file/img001/DV7XWRzIRrGTFHlncCHsGQ.png',
//     categoryName: 'children_care',
//     isEligible: true,
//   },
//   {
//     title: "Genevieve's Helping Hands",
//     imgUrl: 'https://img001.prntscr.com/file/img001/DV7XWRzIRrGTFHlncCHsGQ.png',
//     categoryName: 'financial',
//     isEligible: false,
//   },
//   {
//     title: "Genevieve's Helping Hands",
//     imgUrl: 'https://img001.prntscr.com/file/img001/DV7XWRzIRrGTFHlncCHsGQ.png',
//     categoryName: 'children_care',
//     isEligible: true,
//   },
//   {
//     title: "Genevieve's Helping Hands",
//     imgUrl: 'https://img001.prntscr.com/file/img001/DV7XWRzIRrGTFHlncCHsGQ.png',
//     categoryName: 'children_care',
//     isEligible: true,
//   },
//   {
//     title: "Genevieve's Helping Hands",
//     imgUrl: 'https://img001.prntscr.com/file/img001/DV7XWRzIRrGTFHlncCHsGQ.png',
//     categoryName: 'financial',
//     isEligible: true,
//   },
// ];

// const CATEGORIES = [
//   {
//     name: 'children_care',
//     label: 'Children Care',
//     iconName: 'children_care',
//   },
//   {
//     name: 'co_pay',
//     label: 'Co-pay',
//     iconName: 'co_pay',
//   },
//   {
//     name: 'financial',
//     label: 'Financial',
//     iconName: 'financial',
//   },
//   {
//     name: 'financial_kids_support',
//     label: 'Financial Kids Support',
//     iconName: 'financial_kids_support',
//   },
//   {
//     name: 'living_expenses',
//     label: 'Living expenses',
//     iconName: 'living_expenses',
//   },
//   {
//     name: 'loding',
//     label: 'Loding',
//     iconName: 'loding',
//   },
//   {
//     name: 'medical_expenses',
//     label: 'Medical expenses',
//     iconName: 'medical_expenses',
//   },
//   {
//     name: 'support',
//     label: 'Support',
//     iconName: 'support',
//   },
//   {
//     name: 'travel_expenses',
//     label: 'Travel expenses',
//     iconName: 'travel_expenses',
//   },
//   {
//     name: 'transportation',
//     label: 'Transportation',
//     iconName: 'transportation',
//   },
// ];
