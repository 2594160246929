import { NCT_IDS_WITH_MATCHED_TRIAL_MESSAGES } from '../Matches/constants';
// Created above for reusability
const LEARN_MORE = 'learnMore';
const HERE_TO_HELP = 'hereToHelp';
const UPDATE_EMAIL = 'updateEmail';
const CONTACT_ONCOLOGIST = 'contactOncologist';
const MORE_ABOUT_TRIAL = 'moreAboutTrial';
const SPONSORED_TRIAL_MATCH = 'trialMatched';
const QUESTION_INFO = 'questionInfo';
const MISSING_INFO = 'missingInfo';
const SHARE_TRIAL = 'shareTrial';
const CHOOSE_CONTACT = 'chooseContact';
const CONTACT_EMAIL = 'contactEmail';
const CALENCLY = 'calendly';
const YOU_QUALIFY = 'youQualify';
const SITE_COORDINATOR_DETAILS = 'siteCoordinatorDetails';
const SENT_SUCCESSFULLY = 'sentSuccessfully';
const TREATMENT_TYPE_FILTER = 'treatmentTypeFilter';
const MATCHES_MOBILE_FILTER = 'matchesMobileFilter';
const TREATMENT_FINDER_MOBILE_FILTER = 'treatmentFinderMobileFilter';
const CHANGE_LOCATION = 'changeLocation';
const SHARE_TRIALS_EMAIL = 'shareTrialsEmail';
const YOU_QUALIFY_PROMPT = 'youQualifyPrompt';
const UPDATE_MEDICAL_CONDITION = 'updateMedicalCondition';
const YOUTUBE_DIALOG = 'youtubeDialog';
const SIGNUP_LEARN_MORE = 'signupLearnMore';
const SPONSORED_MATCH_DIALOG = 'sponsoredMatchDialog';
const EMAIL_US_SUCCESS_DIALOG = 'emailUsSuccessDialog';
const GENETIC_TESTING_DIALOG = 'geneticTestingDialog';

export const refIdByNctIdsForMatchedTrialMessage = {
  fdsew: NCT_IDS_WITH_MATCHED_TRIAL_MESSAGES.NCT04069936,
  tewyu: NCT_IDS_WITH_MATCHED_TRIAL_MESSAGES.NCT04069936,
};

export const REDUX_ACTIONS = {
  DIALOG_DATA_SET: 'DIALOG_DATA_SET',
  DIALOG_DATA_CLEAR: 'DIALOG_DATA_CLEAR',
  DIALOG_TOGGLE: 'DIALOG_TOGGLE',
};

export const OPENED_VIA = {
  REDUX_ACTION: 'REDUX_ACTION',
  URL: 'URL',
};

export const THEME = {
  STANDARD: 'STANDARD',
  INFO: 'INFO',
  INFO_SMALL: 'INFO_SMALL',
  SPONSORED: 'SPONSORED',
};

export const SIDEBAR_DIALOGS = [
  UPDATE_MEDICAL_CONDITION,
];

export default {
  YOU_QUALIFY_PROMPT,
  CONTACT_ONCOLOGIST,
  HERE_TO_HELP,
  LEARN_MORE,
  MORE_ABOUT_TRIAL,
  QUESTION_INFO,
  MISSING_INFO,
  SPONSORED_TRIAL_MATCH,
  UPDATE_EMAIL,
  SHARE_TRIAL,
  CHOOSE_CONTACT,
  CONTACT_EMAIL,
  CALENCLY,
  YOU_QUALIFY,
  SITE_COORDINATOR_DETAILS,
  SENT_SUCCESSFULLY,
  TREATMENT_TYPE_FILTER,
  MATCHES_MOBILE_FILTER,
  TREATMENT_FINDER_MOBILE_FILTER,
  CHANGE_LOCATION,
  UPDATE_MEDICAL_CONDITION,
  SHARE_TRIALS_EMAIL,
  YOUTUBE_DIALOG,
  SIGNUP_LEARN_MORE,
  SPONSORED_MATCH_DIALOG,
  EMAIL_US_SUCCESS_DIALOG,
  GENETIC_TESTING_DIALOG,
};
