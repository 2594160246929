import {
  MONTH_PICKER,
  MULTIPLE_OPTION_SELECT,
  NUMBER_INPUT,
  PROCEDURES,
  SINGLE_OPTION_SELECT,
  TREATMENT_HISTORY,
  TRIPLE_QUESTION_DROP_DOWN,
  MULTIPLE_OPTION_SEARCH_SELECT,
  BIOMARKERS,
  OPTIONS_FROM_SERVER,
} from 'components/Questionnaire/constants/questionTypes';
import ConditionTypes from 'components/Questionnaire/constants/conditions';
import i from 'components/Questionnaire/constants/icons';
import {
  dateOfBirth,
  diseaseStatus,
  preferredLocation,
  travelLimit,
  userType,
  race,
  haveInsurance,
  insurance,
  pdL1ExpressionByQNumber,
  initialDiagnosisDate,
} from 'components/Questionnaire/constants/globalQuestions';
import SECTIONS from 'components/Questionnaire/constants/questionSections';
import { Profile as ID } from './OvarianProfile.enum';
import dateType from '../../../../utils/constants/dateTypes';
import {
  treatmentCyclesOptions,
  // takingStateOptions,
  yesAndNoOptions, yesNoIAmNotSureOptions,
} from '../../constants/globalOptions';
import numberType from '../../../../utils/constants/numberTypes';
import { QUESTION_VIEW_TYPES } from '../../constants/questionViewTypes';
import GLOBAL_ENUMS from '../../constants/globalEnums';

const { biomarkers: BIOMARKERS_QUESTION_ID } = GLOBAL_ENUMS;

const { OPTIONS_LIST_WITH_SHOW_MORE } = QUESTION_VIEW_TYPES;

const {
  CONTAIN_ONE_OF,
  EQUAL_TO,
  NOT_EQUAL_TO_OR_SKIPPED,
  AGE_LESS_THAN,
  DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
  CONTAINS_ONE_OF_OR_SKIPPED,
} = ConditionTypes;

const Questions = [
  // My cancer
  diseaseStatus,
  {
    q: 'Q6',
    id: ID.type_of_ovarian_cancer,
    type: SINGLE_OPTION_SELECT,
    bgIcon: i.cancer_research,
    section: SECTIONS.MY_CANCER,
    order: 0,
    title: 'questionnaire.questions.ovarian.type_of_ovarian_cancer.6.title',
    subtitle: '',
    info: {
      title: 'questionnaire.questions.ovarian.type_of_ovarian_cancer.6.info.title',
      content: 'questionnaire.questions.ovarian.type_of_ovarian_cancer.6.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.ovarian.type_of_ovarian_cancer.6.options.high_grade_serous',
        value: 'high_grade_serous',
      },
      {
        label: 'questionnaire.questions.ovarian.type_of_ovarian_cancer.6.options.low_grade_serous',
        value: 'low_grade_serous',
      },
      {
        label: 'questionnaire.questions.ovarian.type_of_ovarian_cancer.6.options.high_grade_endometrioid',
        value: 'high_grade_endometrioid',
      },
      {
        label: 'questionnaire.questions.ovarian.type_of_ovarian_cancer.6.options.grade_one_endometrioid',
        value: 'grade_one_endometrioid',
      },
      {
        label: 'questionnaire.questions.ovarian.type_of_ovarian_cancer.6.options.carcinosarcoma',
        value: 'carcinosarcoma',
      },
      {
        label: 'questionnaire.questions.ovarian.type_of_ovarian_cancer.6.options.clear_cell',
        value: 'clear_cell',
      },
      {
        label: 'questionnaire.questions.ovarian.type_of_ovarian_cancer.6.options.mucinous',
        value: 'mucinous',
      },
      {
        label: 'questionnaire.questions.ovarian.type_of_ovarian_cancer.6.options.lmp',
        value: 'lmp',
      },
      {
        label: 'questionnaire.questions.ovarian.type_of_ovarian_cancer.6.options.other_non_epithelial',
        value: 'other_non_epithelial',
      },
      {
        label: 'questionnaire.questions.ovarian.type_of_ovarian_cancer.6.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q8',
    id: ID.current_stage,
    type: SINGLE_OPTION_SELECT,
    bgIcon: i.medical_report,
    section: SECTIONS.MY_CANCER,
    order: 0,
    title: 'questionnaire.questions.ovarian.current_stage.8.title',
    subtitle: 'questionnaire.questions.ovarian.current_stage.8.subtitle',
    info: {
      title: 'questionnaire.questions.ovarian.current_stage.8.info.title',
      content: 'questionnaire.questions.ovarian.current_stage.8.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.ovarian.current_stage.8.options.1.1',
        value: '1.1',
      },
      {
        label: 'questionnaire.questions.ovarian.current_stage.8.options.1.2',
        value: '1.2',
      },
      {
        label: 'questionnaire.questions.ovarian.current_stage.8.options.1.3',
        value: '1.3',
      },
      {
        label: 'questionnaire.questions.ovarian.current_stage.8.options.2.1',
        value: '2.1',
      },
      {
        label: 'questionnaire.questions.ovarian.current_stage.8.options.2.2',
        value: '2.2',
      },
      {
        label: 'questionnaire.questions.ovarian.current_stage.8.options.3.1',
        value: '3.1',
      },
      {
        label: 'questionnaire.questions.ovarian.current_stage.8.options.3.2',
        value: '3.2',
      },
      {
        label: 'questionnaire.questions.ovarian.current_stage.8.options.3.3',
        value: '3.3',
      },
      {
        label: 'questionnaire.questions.ovarian.current_stage.8.options.4.1',
        value: '4.1',
      },
      {
        label: 'questionnaire.questions.ovarian.current_stage.8.options.4.2',
        value: '4.2',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q11b',
    id: ID.treatment_for_ovarian_cancer,
    type: SINGLE_OPTION_SELECT,
    bgIcon: i.treatment,
    section: SECTIONS.TREATMENT,
    order: 0,
    title: 'questionnaire.questions.ovarian.treatment_for_ovarian.title',
    subtitle: '',
    info: {
      title: '',
      content: '',
    },
    options: yesAndNoOptions,
    conditions: [],
  },
  {
    q: 'Q11a',
    id: ID.platinum_response,
    type: SINGLE_OPTION_SELECT,
    bgIcon: i.treatment,
    section: SECTIONS.TREATMENT,
    order: 0,
    title: 'questionnaire.questions.ovarian.platinum_response.title',
    subtitle: '',
    info: {
      title: 'questionnaire.questions.ovarian.platinum_response.info.title',
      content: 'questionnaire.questions.ovarian.platinum_response.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.ovarian.platinum_response.options.platinum_refractory',
        value: 'platinum_refractory',
      },
      {
        label: 'questionnaire.questions.ovarian.platinum_response.options.platinum_sensitive',
        value: 'platinum_sensitive',
      },
      {
        label: 'questionnaire.questions.ovarian.platinum_response.options.platinum_resistant',
        value: 'platinum_resistant',
      },
      {
        label: 'questionnaire.questions.ovarian.platinum_response.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: ID.disease_status,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'new_disease',
      },
      {
        ifId: ID.treatment_for_ovarian_cancer,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['no'],
      },
    ],
  },
  {
    q: 'Q6a',
    id: ID.type_of_non_epithelial_disease,
    type: SINGLE_OPTION_SELECT,
    bgIcon: i.cancer_research,
    section: SECTIONS.MY_CANCER,
    order: 0,
    title: 'questionnaire.questions.ovarian.type_of_non_epithelial_disease.6a.title',
    subtitle: '',
    info: {
      title: 'questionnaire.questions.ovarian.type_of_non_epithelial_disease.6a.info.title',
      content: 'questionnaire.questions.ovarian.type_of_non_epithelial_disease.6a.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.ovarian.type_of_non_epithelial_disease.6a.options.dysgerminoma',
        value: 'dysgerminoma',
      },
      {
        label: 'questionnaire.questions.ovarian.type_of_non_epithelial_disease.6a.options.yolk_sac',
        value: 'yolk_sac',
      },
      {
        label: 'questionnaire.questions.ovarian.type_of_non_epithelial_disease.6a.options.granulosa',
        value: 'granulosa',
      },
      {
        label: 'questionnaire.questions.ovarian.type_of_non_epithelial_disease.6a.options.teratoma',
        value: 'teratoma',
      },
      {
        label: 'questionnaire.questions.ovarian.type_of_non_epithelial_disease.6a.options.embryonal',
        value: 'embryonal',
      },
    ],
    conditions: [
      {
        ifId: ID.type_of_ovarian_cancer,
        type: CONTAIN_ONE_OF,
        ifValue: ['other_non_epithelial'],
      },
    ],
  },
  {
    q: 'Q5a',
    id: ID.cancer_origin,
    type: SINGLE_OPTION_SELECT,
    bgIcon: i.cancer_research,
    section: SECTIONS.MY_CANCER,
    order: 0,
    title: 'questionnaire.questions.ovarian.cancer_origin.5.title',
    subtitle: '',
    info: {
      title: 'questionnaire.questions.ovarian.cancer_origin.5.info.title',
      content: 'questionnaire.questions.ovarian.cancer_origin.5.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.ovarian.cancer_origin.5.options.ovaries',
        value: 'ovaries',
      },
      {
        label: 'questionnaire.questions.ovarian.cancer_origin.5.options.fallopian_tubes',
        value: 'fallopian_tubes',
      },
      {
        label: 'questionnaire.questions.ovarian.cancer_origin.5.options.peritoneum',
        value: 'peritoneum',
      },
      {
        label: 'questionnaire.questions.ovarian.cancer_origin.5.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q9',
    id: ID.triple_question_drop_down,
    type: TRIPLE_QUESTION_DROP_DOWN,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.hnscc.tnm.title',
    subtitle: 'questionnaire.questions.hnscc.tnm.subtitle',
    info: {
      title: 'questionnaire.questions.hnscc.tnm.info.title',
      content: 'questionnaire.questions.hnscc.tnm.info.content',
    },
    options: [
      {
        title: 'questionnaire.questions.bladder_2.tnm.options.tnm_t',
        name: ID.tnm_t,
        options: [
          {
            label: '',
            value: '',
          },
          {
            label: '1',
            value: '1',
          },
          {
            label: '2',
            value: '2',
          },
          {
            label: '3',
            value: '3',
          },
          {
            label: '4a',
            value: '4.1',
          },
          {
            label: '4b',
            value: '4.2',
          },
        ],
      },
      {
        title: 'questionnaire.questions.bladder_2.tnm.options.tnm_n',
        name: ID.tnm_n,
        options: [
          {
            label: '',
            value: '',
          },
          {
            label: '0',
            value: '0',
          },
          {
            label: '1',
            value: '1',
          },
          {
            label: '2',
            value: '2',
          },
          {
            label: '3',
            value: '3',
          },
        ],
      },
      {
        title: 'questionnaire.questions.bladder_2.tnm.options.tnm_m',
        name: ID.tnm_m,
        options: [
          {
            label: '',
            value: '',
          },
          {
            label: '0',
            value: '0',
          },
          {
            label: '1',
            value: '1',
          },
        ],
      },
    ],
    conditions: [
      {
        ifId: ID.current_stage,
        type: ConditionTypes.IS_SKIPPED,
      },
    ],
  },
  {
    q: 'Q10',
    id: ID.disease_location,
    type: MULTIPLE_OPTION_SELECT,
    bgIcon: i.medical_report,
    section: SECTIONS.MY_CANCER,
    order: 0,
    title: 'questionnaire.questions.ovarian.disease_location.10.title',
    subtitle: 'questionnaire.questions.ovarian.disease_location.10.subtitle',
    info: {
      title: 'questionnaire.questions.ovarian.disease_location.10.info.title',
      content: 'questionnaire.questions.ovarian.disease_location.10.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.ovarian.disease_location.10.options.ovaries',
        value: 'ovaries',
      },
      {
        label: 'questionnaire.questions.ovarian.disease_location.10.options.uterus',
        value: 'uterus',
      },
      {
        label: 'questionnaire.questions.ovarian.disease_location.10.options.omentum_peritoneum',
        value: 'omentum_peritoneum',
      },
      {
        label: 'questionnaire.questions.ovarian.disease_location.10.options.fallopian_tubes',
        value: 'fallopian_tubes',
      },
      {
        label: 'questionnaire.questions.ovarian.disease_location.10.options.close_lymph',
        value: 'close_lymph',
      },
      {
        label: 'questionnaire.questions.ovarian.disease_location.10.options.distant_lymph_nodes',
        value: 'distant_lymph_nodes',
      },
      {
        label: 'questionnaire.questions.ovarian.disease_location.10.options.breast',
        value: 'breast',
      },
      {
        label: 'questionnaire.questions.ovarian.disease_location.10.options.bowel',
        value: 'bowel',
      },
      {
        label: 'questionnaire.questions.ovarian.disease_location.10.options.lung',
        value: 'lung',
      },
      {
        label: 'questionnaire.questions.ovarian.disease_location.10.options.bone',
        value: 'bone',
      },
      {
        label: 'questionnaire.questions.ovarian.disease_location.10.options.liver',
        value: 'liver',
      },
      {
        label: 'questionnaire.questions.ovarian.disease_location.10.options.brain',
        value: 'brain',
      },
      {
        label: 'questionnaire.questions.ovarian.disease_location.10.options.spleen',
        value: 'spleen',
      },
      {
        label: 'questionnaire.questions.ovarian.disease_location.10.options.ascites',
        value: 'ascites',
      },
      {
        label: 'questionnaire.questions.ovarian.disease_location.10.options.pleural_effusion',
        value: 'pleural_effusion',
      },
      {
        label: 'questionnaire.questions.ovarian.disease_location.10.options.none',
        value: 'none',
      },
      {
        label: 'questionnaire.questions.ovarian.disease_location.10.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.disease_status,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'remission',
      },
    ],
  },
  {
    q: 'Q10a',
    id: ID.metastasis_location_brain_leptomeningeal,
    type: SINGLE_OPTION_SELECT,
    bgIcon: i.medical_report,
    section: SECTIONS.MY_CANCER,
    order: 0,
    title: 'questionnaire.questions.ovarian.metastasis_location_brain_leptomeningeal.18.title',
    subtitle: '',
    info: {
      title: 'questionnaire.questions.ovarian.metastasis_location_brain_leptomeningeal.18.info.title',
      content: 'questionnaire.questions.ovarian.metastasis_location_brain_leptomeningeal.18.info.content',
    },
    options: yesAndNoOptions,
    conditions: [
      {
        ifId: ID.disease_location,
        type: CONTAIN_ONE_OF,
        ifValue: ['brain'],
      },
    ],
  },
  {
    q: 'Q10b',
    id: ID.brain_metastasis_active,
    type: SINGLE_OPTION_SELECT,
    bgIcon: i.medical_report,
    section: SECTIONS.MY_CANCER,
    order: 0,
    title: 'questionnaire.questions.ovarian.brain_metastasis_active.19.title',
    subtitle: '',
    info: {
      title: 'questionnaire.questions.ovarian.brain_metastasis_active.19.info.title',
      content: 'questionnaire.questions.ovarian.brain_metastasis_active.19.info.content',
    },
    options: yesAndNoOptions,
    conditions: [
      {
        ifId: ID.disease_location,
        type: CONTAIN_ONE_OF,
        ifValue: ['brain'],
      },
    ],
  },
  {
    q: 'Q15',
    id: ID.disease_measurable,
    type: SINGLE_OPTION_SELECT,
    bgIcon: i.cancer_research,
    section: SECTIONS.MY_CANCER,
    order: 0,
    title: 'questionnaire.questions.ovarian.disease_measurable.15.title',
    subtitle: '',
    info: {
      title: 'questionnaire.questions.ovarian.disease_measurable.15.info.title',
      content: 'questionnaire.questions.ovarian.disease_measurable.15.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.ovarian.disease_measurable.15.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.ovarian.disease_measurable.15.options.no_but_it_can_be',
        value: 'no_but_it_can_be',
      },
      {
        label: 'questionnaire.questions.ovarian.disease_measurable.15.options.no',
        value: 'no',
      },
    ],
    conditions: [
      {
        ifId: ID.disease_status,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'remission',
      },
    ],
  },
  {
    q: 'Q15a',
    id: ID.tumor_size_mm,
    type: NUMBER_INPUT,
    numberType: numberType.int,
    bgIcon: i.cancer_research,
    section: SECTIONS.MY_CANCER,
    order: 0,
    title: 'questionnaire.questions.ovarian.primary_tumor_size_mm.16.title',
    subtitle: 'questionnaire.questions.ovarian.primary_tumor_size_mm.16.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: '',
        value: '',
      },
    ],
    conditions: [
      {
        ifId: ID.disease_measurable,
        type: CONTAIN_ONE_OF,
        ifValue: ['yes'],
      },
    ],
  },
  {
    q: 'Q15b',
    id: ID.ca_125_level,
    type: NUMBER_INPUT,
    numberType: numberType.uML,
    bgIcon: i.cancer_research,
    section: SECTIONS.MY_CANCER,
    order: 0,
    title: 'questionnaire.questions.ovarian.ca_125_level.15b.title',
    subtitle: '',
    info: {
      title: 'questionnaire.questions.ovarian.ca_125_level.15b.info.title',
      content: 'questionnaire.questions.ovarian.ca_125_level.15b.info.content',
    },
    options: [
      {
        label: '',
        value: '',
      },
    ],
    conditions: [
      {
        ifId: ID.disease_measurable,
        type: CONTAIN_ONE_OF,
        ifValue: ['yes'],
      },
    ],
  },
  {
    q: 'Q21',
    id: ID.have_biopsy,
    type: SINGLE_OPTION_SELECT,
    bgIcon: i.medical_report,
    section: SECTIONS.MY_CANCER,
    order: 0,
    title: 'questionnaire.questions.ovarian.primary_tumor_size_mm.21.title',
    subtitle: '',
    info: {
      title: 'questionnaire.questions.ovarian.primary_tumor_size_mm.21.info.title',
      content: 'questionnaire.questions.ovarian.primary_tumor_size_mm.21.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.ovarian.primary_tumor_size_mm.21.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.ovarian.primary_tumor_size_mm.21.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.ovarian.primary_tumor_size_mm.21.options.biopsy_possible',
        value: 'biopsy_possible',
      },
    ],
    conditions: [],
  },
  // {
  //   q: 'Q21a',
  //   id: ID.pd_l1_carrier_status,
  //   type: SINGLE_OPTION_SELECT,
  //   bgIcon: i.molecular,
  //   section: SECTIONS.MY_CANCER,
  //   order: 0,
  //   title: 'questionnaire.questions.ovarian.pd_l1_carrier_status.21a.title',
  //   subtitle: '',
  //   info: {
  //     title: 'questionnaire.questions.ovarian.pd_l1_carrier_status.21a.info.title',
  //     content: 'questionnaire.questions.ovarian.pd_l1_carrier_status.21a.info.content',
  //   },
  //   options: [
  //     {
  //       label: 'questionnaire.questions.ovarian.pd_l1_carrier_status.21a.options.pd_l1_positive',
  //       value: 'yes',
  //     },
  //     {
  //       label: 'questionnaire.questions.ovarian.pd_l1_carrier_status.21a.options.pd_l1_negative',
  //       value: 'no',
  //     },
  //     {
  //       label: 'questionnaire.questions.ovarian.pd_l1_carrier_status.21a.options.not_sure',
  //       value: 'not_sure',
  //     },
  //   ],
  //   conditions: [
  //     {
  //       ifId: ID.have_biopsy,
  //       type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
  //       ifValue: ['no', 'biopsy_possible'],
  //     },
  //   ],
  // },
  pdL1ExpressionByQNumber('Q21c'),
  {
    q: 'Q21b',
    id: ID.brca_mutation,
    type: MULTIPLE_OPTION_SELECT,
    bgIcon: i.molecular,
    section: SECTIONS.MY_CANCER,
    order: 0,
    title: 'questionnaire.questions.ovarian.brca_mutation.21b.title',
    subtitle: 'questionnaire.questions.ovarian.brca_mutation.21b.subtitle',
    info: {
      title: 'questionnaire.questions.ovarian.brca_mutation.21b.info.title',
      content: 'questionnaire.questions.ovarian.brca_mutation.21b.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.ovarian.brca_mutation.21b.options.yes_brca1',
        value: 'yes_brca1',
      },
      {
        label: 'questionnaire.questions.ovarian.brca_mutation.21b.options.yes_brca2',
        value: 'yes_brca2',
      },
      {
        label: 'questionnaire.questions.ovarian.brca_mutation.21b.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.ovarian.brca_mutation.21b.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: ID.have_biopsy,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['no', 'biopsy_possible'],
      },
    ],
  },
  {
    q: 'Q21d',
    id: ID.msi,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.molecular,
    order: 0,
    title: 'questionnaire.questions.ovarian.msi.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.ovarian.msi.options.stable',
        value: 'stable',
      },
      {
        label: 'questionnaire.questions.ovarian.msi.options.unstable',
        value: 'unstable',
      },
      {
        label: 'questionnaire.questions.ovarian.msi.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: ID.have_biopsy,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['no', 'biopsy_possible'],
      },
    ],
  },
  {
    q: 'Q80',
    id: ID.ngs,
    type: SINGLE_OPTION_SELECT,
    bgIcon: i.molecular,
    section: SECTIONS.MY_CANCER,
    order: 0,
    title: 'questionnaire.questions.ovarian.ngs.title',
    subtitle: '',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.ovarian.ngs.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.ovarian.ngs.options.yes_waiting_for_the_results',
        value: 'yes_waiting_for_the_results',
      },
      {
        label: 'questionnaire.questions.ovarian.ngs.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.ovarian.ngs.options.no_but_planning',
        value: 'no_but_planning',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q22',
    id: BIOMARKERS_QUESTION_ID,
    type: BIOMARKERS,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.molecular,
    order: 0,
    title: 'questionnaire.questions.biomarkers.title',
    subtitle: 'questionnaire.questions.ovarian.biomarkers.subtitle',
    placeholder: 'questionnaire.questions.biomarkers.placeholder',
    info: {
      title: '',
      content: '',
    },
    conditions: [
      {
        ifId: ID.have_biopsy,
        type: CONTAINS_ONE_OF_OR_SKIPPED,
        ifValue: ['yes'],
      },
    ],
  },
  {
    q: 'Q7',
    id: ID.disease_resectable,
    type: SINGLE_OPTION_SELECT,
    bgIcon: i.medical_profile,
    section: SECTIONS.MY_CANCER,
    order: 0,
    title: 'questionnaire.questions.ovarian.disease_resectable.title',
    subtitle: '',
    info: {
      title: 'questionnaire.questions.ovarian.disease_resectable.info.title',
      content: 'questionnaire.questions.ovarian.disease_resectable.info.content',
    },
    options: yesNoIAmNotSureOptions,
    conditions: [
      {
        ifId: ID.current_stage,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['4', '4.1', '4.2'],
      },
    ],
  },
  // My treatment
  {
    q: 'Q12',
    id: ID.had_procedure_for_ovarian_cancer,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.ovarian.had_procedure_for_gi_cancer.12.title',
    subtitle: 'questionnaire.questions.ovarian.had_procedure_for_gi_cancer.12.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.ovarian.had_procedure_for_gi_cancer.12.options.yes',
        value: 'yes',
        disableConditions: [
          {
            ifId: ID.had_procedure_for_ovarian_cancer,
            type: EQUAL_TO,
            ifValue: 'no',
          },
        ],
      },
      {
        label: 'questionnaire.questions.ovarian.had_procedure_for_gi_cancer.12.options.no',
        value: 'no',
        disableConditions: [
          {
            ifId: ID.had_procedure_for_ovarian_cancer,
            type: CONTAIN_ONE_OF,
            ifValue: ['yes', 'scheduled_surgery', 'scheduled_radiation'],
          },
        ],
      },
      {
        label: 'questionnaire.questions.ovarian.had_procedure_for_gi_cancer.12.options.scheduled_surgery',
        value: 'scheduled_surgery',
        disableConditions: [
          {
            ifId: ID.had_procedure_for_ovarian_cancer,
            type: EQUAL_TO,
            ifValue: 'no',
          },
        ],
      },
      {
        label: 'questionnaire.questions.ovarian.had_procedure_for_gi_cancer.12.options.scheduled_radiation',
        value: 'scheduled_radiation',
        disableConditions: [
          {
            ifId: ID.had_procedure_for_ovarian_cancer,
            type: EQUAL_TO,
            ifValue: 'no',
          },
        ],
      },
    ],
    conditions: [],
  },
  //   Treatment
  {
    q: 'Q70',
    id: ID.procedures,
    type: PROCEDURES,
    section: SECTIONS.TREATMENT,
    bgIcon: '',
    order: 0,
    title: 'questionnaire.questions.ovarian.procedures.title',
    subtitle: 'questionnaire.questions.ovarian.procedures.subtitle',
    footer: 'questionnaire.questions.ovarian.procedures.footer',
    requiredSubQuestionsId: [ID.procedures_received],
    info: {
      title: '',
      content: '',
    },
    submitButtonLabel: 'questionnaire.questions.ovarian.procedures.submit_button',
    options: [
      {
        iconText: 'A',
        label: 'questionnaire.questions.ovarian.procedures.options.a',
        value: null,
      },
      {
        iconText: 'B',
        label: 'questionnaire.questions.ovarian.procedures.options.b',
        value: null,
      },
      {
        iconText: 'C',
        label: 'questionnaire.questions.ovarian.procedures.options.c',
        value: null,
      },
    ],
    shouldAutoOpenSubQuestions: [{
      ifId: ID.had_procedure_for_ovarian_cancer,
      type: EQUAL_TO,
      ifValue: 'yes',
    }],
    conditions: [
      {
        ifId: ID.had_procedure_for_ovarian_cancer,
        type: CONTAIN_ONE_OF,
        ifValue: ['yes'],
      },
    ],
    subQuestions: [
      {
        q: 'Q70a',
        id: ID.procedures_received,
        type: MULTIPLE_OPTION_SEARCH_SELECT,
        viewType: OPTIONS_LIST_WITH_SHOW_MORE,
        section: SECTIONS.TREATMENT,
        bgIcon: i.treatment,
        order: 0,
        titleIconText: 'A',
        title: 'questionnaire.questions.ovarian.procedures.procedures_received.title',
        subtitle: '',
        placeholder: 'questionnaire.questions.procedures_received.placeholder',
        info: {
          title: '',
          content: '',
        },
        options: {
          dropDownOptions: [
            {
              label: 'questionnaire.questions.ovarian.procedures.procedures_received.options.total_hysterectomy',
              value: 'total_hysterectomy',
            },
            {
              label: 'questionnaire.questions.ovarian.procedures.procedures_received.options.salpingo_oophorectomy',
              value: 'salpingo_oophorectomy',
            },
            {
              label: 'questionnaire.questions.ovarian.procedures.procedures_received.options.debulking',
              value: 'debulking',
            },
            {
              label: 'questionnaire.questions.ovarian.procedures.procedures_received.options.hipec',
              value: 'hipec',
            },
            {
              label: 'questionnaire.questions.ovarian.procedures.procedures_received.options.metastasis_resection',
              value: 'metastasis_resection',
            },
            {
              label: 'questionnaire.questions.ovarian.procedures.procedures_received.options.lymph_node_dissection',
              value: 'lymph_node_dissection',
            },
            {
              label: 'questionnaire.questions.ovarian.procedures.procedures_received.options.external_radiation',
              value: 'external_radiation',
            },
          ],
          radioOptions: [
            {
              label: 'questionnaire.questions.ovarian.procedures.procedures_received.options.other',
              value: 'other',
            },
          ],
        },
        conditions: [],
      },
      {
        q: 'Q70b',
        id: ID.procedure_date,
        type: MONTH_PICKER,
        section: SECTIONS.TREATMENT,
        bgIcon: '',
        order: 0,
        titleIconText: 'B',
        title: 'questionnaire.questions.ovarian.procedures.procedures_date.title',
        subtitle: '',
        info: {
          title: '',
          content: '',
        },
        options: [],
        conditions: [],
        dateType: {
          type: dateType.stopTreatment,
        },
      },
      {
        q: 'Q70c',
        id: ID.procedure_outcome,
        type: SINGLE_OPTION_SELECT,
        section: SECTIONS.TREATMENT,
        bgIcon: '',
        order: 0,
        titleIconText: 'C',
        title: 'questionnaire.questions.ovarian.procedures.procedure_outcome.title',
        subtitle: '',
        info: {
          title: '',
          content: '',
        },
        disableNextOnOptionSelect: false,
        options: [
          {
            label: 'questionnaire.questions.ovarian.procedures.procedure_outcome.options.disease_free',
            value: 'disease_free',
          },
          {
            label: 'questionnaire.questions.ovarian.procedures.procedure_outcome.options.local_residual_disease',
            value: 'local_residual_disease',
          },
          {
            label: 'questionnaire.questions.ovarian.procedures.procedure_outcome.options.residual_systemic_disease',
            value: 'residual_systemic_disease',
          },
        ],
        answerResetable: true,
        conditions: [],
      },
    ],
  },
  {
    q: 'Q11b',
    id: ID.treatment_for_advanced_metastatic_disease,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.ovarian.treatment_for_advanced_metastatic_disease.title',
    subtitle: 'questionnaire.questions.ovarian.treatment_for_advanced_metastatic_disease.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: yesAndNoOptions,
    conditions: [
      {
        ifId: ID.treatment_for_ovarian_cancer,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['no'],
      },
      {
        ifId: ID.current_stage,
        type: CONTAIN_ONE_OF,
        ifValue: ['3.1', '3.2', '3.3', '4.1', '4.2'],
      },
    ],
  },
  {
    q: 'Q60',
    id: ID.treatments,
    type: TREATMENT_HISTORY,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.ovarian.treatments.title',
    footer: 'questionnaire.questions.ovarian.treatments.footer',
    requiredSubQuestionsId: [ID.drugs_received],
    subtitle: '',
    info: {
      title: '',
      content: '',
    },
    submitButtonLabel: 'questionnaire.questions.treatments.button.submit',
    options: [
      {
        iconText: 'A',
        label: 'questionnaire.questions.ovarian.treatments.options.treatment_name',
        value: null,
      },
      {
        iconText: 'B',
        label: 'questionnaire.questions.ovarian.treatments.options.course_received',
        value: null,
      },
      // {
      //   iconText: '',
      //   label: 'questionnaire.questions.ovarian.treatments.options.cycles',
      //   value: null,
      // },
      {
        iconText: 'C',
        label: 'questionnaire.questions.ovarian.treatments.options.treatment_stopped',
        value: null,
      },
      {
        iconText: 'D',
        label: 'questionnaire.questions.ovarian.treatments.options.stop_reason',
        value: null,
      },
    ],
    // shouldAutoOpenSubQuestions: [{
    //   ifId: ID.treatment_for_ovarian_cancer,
    //   type: EQUAL_TO,
    //   ifValue: 'yes',
    // }],
    conditions: [
      {
        ifId: ID.treatment_for_ovarian_cancer,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'no',
      },
    ],
    subQuestions: [
      {
        q: 'Q60a',
        id: ID.drugs_received,
        type: OPTIONS_FROM_SERVER,
        viewType: OPTIONS_LIST_WITH_SHOW_MORE,
        section: SECTIONS.TREATMENT,
        bgIcon: i.treatment,
        order: 0,
        titleIconText: 'A',
        title: 'questionnaire.questions.ovarian.treatments.drugs_received.title',
        subtitle: 'questionnaire.questions.ovarian.treatments.drugs_received.subtitle',
        placeholder: 'questionnaire.questions.drugs_received.placeholder',
        info: {
          title: '',
          content: '',
        },
        questionProps: {
          isSearchableByOptionGroupName: true,
        },
      },
      {
        q: 'Q60f',
        id: ID.cycles,
        type: SINGLE_OPTION_SELECT,
        section: SECTIONS.TREATMENT,
        title: 'questionnaire.questions.ovarian.treatments.cycles.title',
        disableNextOnOptionSelect: false,
        options: [...treatmentCyclesOptions],
      },
      {
        q: 'Q60b',
        id: ID.treatment_admin,
        type: SINGLE_OPTION_SELECT,
        section: SECTIONS.TREATMENT,
        bgIcon: i.treatment,
        order: 0,
        titleIconText: 'B',
        title: 'questionnaire.questions.ovarian.treatments.treatment_admin.title',
        subtitle: '',
        info: {
          title: '',
          content: '',
        },
        disableNextOnOptionSelect: true,
        options: [
          {
            label: 'questionnaire.questions.ovarian.treatments.treatment_admin.options.neoadjuvant',
            value: 'neoadjuvant',
          },
          {
            label: 'questionnaire.questions.ovarian.treatments.treatment_admin.options.adjuvant',
            value: 'adjuvant',
          },
          {
            label: 'questionnaire.questions.ovarian.treatments.treatment_admin.options.maintenance',
            value: 'maintenance',
          },
          {
            label: 'questionnaire.questions.ovarian.treatments.treatment_admin.options.systemic',
            value: 'systemic',
          },
        ],
        conditions: [],
      },
      {
        q: 'Q60c',
        id: ID.treatment_end_date,
        type: MONTH_PICKER,
        section: SECTIONS.TREATMENT,
        bgIcon: i.treatment,
        order: 0,
        titleIconText: 'C',
        title: 'questionnaire.questions.ovarian.treatments.treatment_end_date.title',
        subtitle: '',
        info: {
          title: '',
          content: '',
        },
        options: [],
        conditions: [],
        dateType: {
          type: dateType.stopTreatment,
        },
      },
      {
        q: 'Q60d',
        id: ID.treatment_stop_reason,
        type: SINGLE_OPTION_SELECT,
        section: SECTIONS.TREATMENT,
        bgIcon: i.treatment,
        order: 0,
        titleIconText: 'D',
        title: 'questionnaire.questions.ovarian.treatments.treatment_stop_reason.title',
        subtitle: '',
        info: {
          title: '',
          content: '',
        },
        disableNextOnOptionSelect: false,
        options: [
          {
            label: 'questionnaire.questions.ovarian.treatments.treatment_admin.options.treatment_ongoing',
            value: 'treatment_ongoing',
          },
          {
            label: 'questionnaire.questions.ovarian.treatments.treatment_admin.options.end_of_protocol',
            value: 'end_of_protocol',
          },
          {
            label: 'questionnaire.questions.ovarian.treatments.treatment_admin.options.progression',
            value: 'progression',
          },
          {
            label: 'questionnaire.questions.ovarian.treatments.treatment_admin.options.no_response',
            value: 'no_response',
          },
          {
            label: 'questionnaire.questions.ovarian.treatments.treatment_admin.options.loss_of_response',
            value: 'loss_of_response',
          },
          {
            label: 'questionnaire.questions.ovarian.treatments.treatment_admin.options.remission',
            value: 'remission',
          },
          {
            label: 'questionnaire.questions.ovarian.treatments.treatment_admin.options.toxicity',
            value: 'toxicity',
          },
          {
            label: 'questionnaire.questions.ovarian.treatments.treatment_admin.options.other_medical_conditions',
            value: 'other_medical_conditions',
          },
          {
            label: 'questionnaire.questions.ovarian.treatments.treatment_admin.options.dosing_issues',
            value: 'dosing_issues',
          },
          {
            label: 'questionnaire.questions.ovarian.treatments.treatment_admin.options.scheduling',
            value: 'scheduling',
          },
          {
            label: 'questionnaire.questions.ovarian.treatments.treatment_admin.options.travel_limitation',
            value: 'travel_limitation',
          },
          {
            label: 'questionnaire.questions.ovarian.treatments.treatment_admin.options.financial_reasons_or_insurance',
            value: 'financial_reasons_or_insurance',
          },
          {
            label: 'questionnaire.questions.ovarian.treatments.treatment_admin.options.other',
            value: 'other',
          },
        ],
        conditions: [],
      },
    ],
    // title: '',
    // info: {
    //   title: '',
    //   content: '',
    // },
    // footer: '',
    // type: TREATMENT_HISTORY,
    // section: SECTIONS.TREATMENT,
    // bgIcon: i.treatment,
    // order: 0,
    // subQuestionsIds: [
    //   ID.treatment_for_advanced_metastatic_disease,
    //   ID.drugs_received,
    //   ID.treatment_stop_reason,
    //   ID.treatment_admin,
    // ],
    stageIds: [ID.current_stage],
    // surgeryId: ID.had_procedure_for_ovarian_cancer,
    // options: [],
    // subQuestionsOptions: {
    //   treatment_stop_reason: [
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.no_response',
    //       value: 'no_response',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.end_of_protocol',
    //       value: 'end_of_protocol',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.toxicity',
    //       value: 'toxicity',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.progression',
    //       value: 'progression',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.loss_of_response',
    //       value: 'loss_of_response',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.remission',
    //       value: 'remission',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.other',
    //       value: 'other',
    //     },
    //   ],
    //   taking_state: takingStateOptions,
    //   treatment_admin: [
    //     {
    //       label: 'questionnaire.questions.ovarian.treatments.treatment_admin.options.neoadjuvant',
    //       value: 'neoadjuvant',
    //     },
    //     {
    //       label: 'questionnaire.questions.ovarian.treatments.treatment_admin.options.adjuvant',
    //       value: 'adjuvant',
    //     },
    //     {
    //       label: 'questionnaire.questions.ovarian.treatments.treatment_admin.options.maintenance',
    //       value: 'maintenance',
    //     },
    //     {
    //       label: 'questionnaire.questions.ovarian.treatments.treatment_admin.options.systemic',
    //       value: 'systemic',
    //     },
    //   ],
    // },
    // conditions: [
    //   {
    //     ifId: ID.treatment_for_ovarian_cancer,
    //     type: NOT_EQUAL_TO_OR_SKIPPED,
    //     ifValue: 'no',
    //   },
    // ],
  },
  // My health
  {
    q: 'Q44',
    id: ID.current_treatment_systemic_steroids,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.ovarian.current_treatment_systemic_steroids.title',
    subtitle: 'questionnaire.questions.ovarian.current_treatment_systemic_steroids.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.ovarian.current_treatment_systemic_steroids.options.under_10_mg_daily',
        value: 'under_10_mg_daily',
      },
      {
        label: 'questionnaire.questions.ovarian.current_treatment_systemic_steroids.options.over_10_mg_daily',
        value: 'over_10_mg_daily',
      },
      {
        label: 'questionnaire.questions.ovarian.current_treatment_systemic_steroids.options.none',
        value: 'none',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q44a',
    id: ID.systemic_steroids_treatment_end_date,
    type: MONTH_PICKER,
    bgIcon: i.doctor_book_kit,
    section: SECTIONS.MY_HEALTH,
    order: 0,
    title: 'questionnaire.questions.ovarian.systemic_steroids_treatment_end_date.title',
    subtitle: '',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: '',
        value: '',
      },
    ],
    conditions: [
      {
        ifId: ID.current_treatment_systemic_steroids,
        type: CONTAIN_ONE_OF,
        ifValue: ['under_10_mg_daily', 'over_10_mg_daily'],
      },
    ],
    dateType: {
      type: dateType.stopTreatment,
    },
  },
  {
    q: 'Q45',
    id: ID.ecog,
    type: SINGLE_OPTION_SELECT,
    bgIcon: i.doctor_book_kit,
    section: SECTIONS.MY_HEALTH,
    order: 0,
    title: 'questionnaire.questions.ovarian.ecog.title',
    subtitle: '',
    info: {
      title: 'questionnaire.questions.ovarian.ecog.info.title',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.ovarian.ecog.options.0',
        value: '0',
      },
      {
        label: 'questionnaire.questions.ovarian.ecog.options.1',
        value: '1',
      },
      {
        label: 'questionnaire.questions.ovarian.ecog.options.2',
        value: '2',
      },
      {
        label: 'questionnaire.questions.ovarian.ecog.options.3',
        value: '3',
      },
      {
        label: 'questionnaire.questions.ovarian.ecog.options.4',
        value: '4',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q46',
    id: ID.other_medical_condition,
    type: MULTIPLE_OPTION_SELECT,
    bgIcon: i.doctor_book_kit,
    section: SECTIONS.MY_HEALTH,
    order: 0,
    title: 'questionnaire.questions.ovarian.other_medical_condition.title',
    subtitle: 'questionnaire.questions.ovarian.other_medical_condition.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.ovarian.other_medical_condition.options.no_other_health_issues',
        value: 'no_other_health_issues',
        exclusive: true,
      },
      {
        label: 'questionnaire.questions.ovarian.other_medical_condition.options.liver',
        value: 'liver',
      },
      {
        label: 'questionnaire.questions.ovarian.other_medical_condition.options.heart',
        value: 'heart',
      },
      {
        label: 'questionnaire.questions.ovarian.other_medical_condition.options.kidney',
        value: 'kidney',
      },
      {
        label: 'questionnaire.questions.ovarian.other_medical_condition.options.autoimmune',
        value: 'autoimmune',
      },
      {
        label: 'questionnaire.questions.ovarian.other_medical_condition.options.mental',
        value: 'mental',
      },
      {
        label: 'questionnaire.questions.ovarian.other_medical_condition.options.allergies',
        value: 'allergies',
      },
      {
        label: 'questionnaire.questions.ovarian.other_medical_condition.options.chronic_infection_or_inflammation',
        value: 'chronic_infection_or_inflammation',
      },
      {
        label: 'questionnaire.questions.ovarian.other_medical_condition.options.gastrointestinal',
        value: 'gastrointestinal',
      },
      {
        label: 'questionnaire.questions.ovarian.other_medical_condition.options.other_cancer_type',
        value: 'other_cancer_type',
      },
      {
        label: 'questionnaire.questions.ovarian.other_medical_condition.options.anemia',
        value: 'anemia',
      },
      {
        label: 'questionnaire.questions.ovarian.other_medical_condition.options.thyroid',
        value: 'thyroid',
      },
      {
        label: 'questionnaire.questions.ovarian.other_medical_condition.options.hypertension',
        value: 'hypertension',
      },
      {
        label: 'questionnaire.questions.ovarian.other_medical_condition.options.epilepsy',
        value: 'epilepsy',
      },
      {
        label: 'questionnaire.questions.ovarian.other_medical_condition.options.endometriosis',
        value: 'endometriosis',
      },
      {
        label: 'questionnaire.questions.ovarian.other_medical_condition.options.ascites',
        value: 'ascites',
      },
      {
        label: 'questionnaire.questions.ovarian.other_medical_condition.options.diabetes',
        value: 'diabetes',
      },
      {
        label: 'questionnaire.questions.ovarian.other_medical_condition.options.organ_transplant',
        value: 'organ_transplant',
      },
      {
        label: 'questionnaire.questions.ovarian.other_medical_condition.options.extreme_obesity',
        value: 'extreme_obesity',
      },
      {
        label: 'questionnaire.questions.ovarian.other_medical_condition.options.stroke',
        value: 'stroke',
      },
      {
        label: 'questionnaire.questions.ovarian.other_medical_condition.options.lung',
        value: 'lung',
      },
      {
        label: 'questionnaire.questions.ovarian.other_medical_condition.options.neuropathy',
        value: 'neuropathy',
      },
      {
        label: 'questionnaire.questions.ovarian.other_medical_condition.options.bleeding_disorder',
        value: 'bleeding_disorder',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q47',
    id: ID.omc_liver,
    type: SINGLE_OPTION_SELECT,
    bgIcon: i.doctor_book_kit,
    section: SECTIONS.MY_HEALTH,
    order: 0,
    title: 'questionnaire.questions.ovarian.omc_liver.title',
    subtitle: '',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.ovarian.omc_liver.options.elevated_alt_ast',
        value: 'elevated_alt_ast',
      },
      {
        label: 'questionnaire.questions.ovarian.omc_liver.options.elevated_bilirubin',
        value: 'elevated_bilirubin',
      },
      {
        label: 'questionnaire.questions.ovarian.omc_liver.options.cirrhosis',
        value: 'cirrhosis',
      },
      {
        label: 'questionnaire.questions.ovarian.omc_liver.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['liver'],
      },
    ],
  },
  {
    q: 'Q48',
    id: ID.omc_heart,
    type: MULTIPLE_OPTION_SELECT,
    bgIcon: i.doctor_book_kit,
    section: SECTIONS.MY_HEALTH,
    order: 0,
    title: 'questionnaire.questions.ovarian.omc_heart.title',
    subtitle: '',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.ovarian.omc_heart.options.class_at_least_nyha_ii',
        value: 'class_at_least_nyha_ii',
      },
      {
        label: 'questionnaire.questions.ovarian.omc_heart.options.condition_at_least_grade_2',
        value: 'condition_at_least_grade_2',
      },
      {
        label: 'questionnaire.questions.ovarian.omc_heart.options.cardiac_ventricular_arrhythmia',
        value: 'cardiac_ventricular_arrhythmia',
      },
      {
        label: 'questionnaire.questions.ovarian.omc_heart.options.heart_failure',
        value: 'heart_failure',
      },
      {
        label: 'questionnaire.questions.ovarian.omc_heart.options.lvef_under_50_percent',
        value: 'lvef_under_50_percent',
      },
      {
        label: 'questionnaire.questions.ovarian.omc_heart.options.qtcf_abnormal',
        value: 'qtcf',
      },
      {
        label: 'questionnaire.questions.ovarian.omc_heart.options.angina',
        value: 'angina',
      },
      {
        label: 'questionnaire.questions.ovarian.omc_heart.options.myocardial_infarction',
        value: 'myocardial_infarction',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['heart'],
      },
    ],
  },
  {
    q: 'Q49',
    id: ID.omc_stroke_date,
    type: MONTH_PICKER,
    bgIcon: i.doctor_book_kit,
    section: SECTIONS.MY_HEALTH,
    order: 0,
    title: 'questionnaire.questions.ovarian.omc_stroke_date.title',
    subtitle: '',
    info: {
      title: '',
      content: '',
    },
    options: [],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['stroke'],
      },
    ],
    dateType: {
      type: dateType.dob,
    },
  },
  {
    q: 'Q50',
    id: ID.omc_myocardial_infarction_date,
    type: MONTH_PICKER,
    bgIcon: i.doctor_book_kit,
    section: SECTIONS.MY_HEALTH,
    order: 0,
    title: 'questionnaire.questions.ovarian.omc_myocardial_infarction_date.title',
    subtitle: '',
    info: {
      title: '',
      content: '',
    },
    options: [],
    conditions: [
      {
        ifId: ID.omc_heart,
        type: CONTAIN_ONE_OF,
        ifValue: ['myocardial_infarction'],
      },
    ],
    dateType: {
      type: dateType.dob,
    },
  },
  {
    q: 'Q51',
    id: ID.omc_kidney,
    type: MULTIPLE_OPTION_SELECT,
    bgIcon: i.doctor_book_kit,
    section: SECTIONS.MY_HEALTH,
    order: 0,
    title: 'questionnaire.questions.ovarian.omc_kidney.title',
    subtitle: '',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.ovarian.omc_kidney.options.low_glomerular_filtration_rate',
        value: 'low_glomerular_filtration_rate',
      },
      {
        label: 'questionnaire.questions.ovarian.omc_kidney.options.dialysis',
        value: 'dialysis',
      },
      {
        label: 'questionnaire.questions.ovarian.omc_kidney.options.kidney_stones',
        value: 'kidney_stones',
      },
      {
        label: 'questionnaire.questions.ovarian.omc_kidney.options.creatinine_high',
        value: 'creatinine_high',
      },
      {
        label: 'questionnaire.questions.ovarian.omc_kidney.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['kidney'],
      },
    ],
  },
  {
    q: 'Q52',
    id: ID.omc_autoimmune,
    type: SINGLE_OPTION_SELECT,
    bgIcon: i.doctor_book_kit,
    section: SECTIONS.MY_HEALTH,
    order: 0,
    title: 'questionnaire.questions.ovarian.omc_autoimmune.title',
    subtitle: '',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.ovarian.omc_autoimmune.options.congenital_or_acquired_immunosuppression',
        value: 'congenital_or_acquired_immunosuppression',
      },
      {
        label: 'questionnaire.questions.ovarian.omc_autoimmune.options.hepatitis',
        value: 'hepatitis',
      },
      {
        label: 'questionnaire.questions.ovarian.omc_autoimmune.options.granulomatous_disease',
        value: 'granulomatous_disease',
      },
      {
        label: 'questionnaire.questions.ovarian.omc_autoimmune.options.ms',
        value: 'ms',
      },
      {
        label: 'questionnaire.questions.ovarian.omc_autoimmune.options.lupus',
        value: 'lupus',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['autoimmune'],
      },
    ],
  },
  {
    q: 'Q53',
    id: ID.omc_mental,
    type: MULTIPLE_OPTION_SELECT,
    bgIcon: i.doctor_book_kit,
    section: SECTIONS.MY_HEALTH,
    order: 0,
    title: 'questionnaire.questions.ovarian.omc_mental.title',
    subtitle: '',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.ovarian.omc_mental.options.dementia_alzheimer',
        value: 'dementia_alzheimer',
      },
      {
        label: 'questionnaire.questions.ovarian.omc_mental.options.neuropsychyatric',
        value: 'neuropsychyatric',
      },
      {
        label: 'questionnaire.questions.ovarian.omc_mental.options.depression',
        value: 'depression',
      },
      {
        label: 'questionnaire.questions.ovarian.omc_mental.options.addiction',
        value: 'addiction',
      },
      {
        label: 'questionnaire.questions.ovarian.omc_mental.options.psychotic',
        value: 'psychotic',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['mental'],
      },
    ],
  },
  {
    q: 'Q54',
    id: ID.omc_allergy,
    type: MULTIPLE_OPTION_SELECT,
    bgIcon: i.doctor_book_kit,
    section: SECTIONS.MY_HEALTH,
    order: 0,
    title: 'questionnaire.questions.ovarian.omc_allergy.title',
    subtitle: '',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.ovarian.omc_allergy.options.interferon',
        value: 'interferon',
      },
      {
        label: 'questionnaire.questions.ovarian.omc_allergy.options.metformin',
        value: 'metformin',
      },
      {
        label: 'questionnaire.questions.ovarian.omc_allergy.options.vitd',
        value: 'vitd',
      },
      {
        label: 'questionnaire.questions.ovarian.omc_allergy.options.penicillin',
        value: 'penicillin',
      },
      {
        label: 'questionnaire.questions.ovarian.omc_allergy.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['allergies'],
      },
    ],
  },
  {
    q: 'Q55',
    id: ID.omc_chronic_infection_inflammation,
    type: MULTIPLE_OPTION_SELECT,
    bgIcon: i.doctor_book_kit,
    section: SECTIONS.MY_HEALTH,
    order: 0,
    title: 'questionnaire.questions.ovarian.omc_chronic_infection_inflammation.title',
    subtitle: '',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.ovarian.omc_chronic_infection_inflammation.options.active_hbv_hcv',
        value: 'active_hbv_hcv',
      },
      {
        label: 'questionnaire.questions.ovarian.omc_chronic_infection_inflammation.options.active_hiv',
        value: 'active_hiv',
      },
      {
        label: 'questionnaire.questions.ovarian.omc_chronic_infection_inflammation.options.cmv',
        value: 'cmv',
      },
      {
        label: 'questionnaire.questions.ovarian.omc_chronic_infection_inflammation.options.uncontrolled_infection',
        value: 'uncontrolled_infection',
      },
      {
        label: 'questionnaire.questions.ovarian.omc_chronic_infection_inflammation.options.covid19',
        value: 'covid19',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['chronic_infection_or_inflammation'],
      },
    ],
  },
  {
    q: 'Q56',
    id: ID.omc_gastro_problem,
    type: MULTIPLE_OPTION_SELECT,
    bgIcon: i.doctor_book_kit,
    section: SECTIONS.MY_HEALTH,
    order: 0,
    title: 'questionnaire.questions.ovarian.omc_gastro_problem.title',
    subtitle: '',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.ovarian.omc_gastro_problem.options.gastrointestinal_bleeding',
        value: 'gastrointestinal_bleeding',
      },
      {
        label: 'questionnaire.questions.ovarian.omc_gastro_problem.options.crohns',
        value: 'crohns',
      },
      {
        label: 'questionnaire.questions.ovarian.omc_gastro_problem.options.colitis',
        value: 'colitis',
      },
      {
        label: 'questionnaire.questions.ovarian.omc_gastro_problem.options.vomiting',
        value: 'vomiting',
      },
      {
        label: 'questionnaire.questions.ovarian.omc_gastro_problem.options.ibd',
        value: 'ibd',
      },
      {
        label: 'questionnaire.questions.ovarian.omc_gastro_problem.options.dysphagia',
        value: 'dysphagia',
      },
      {
        label: 'questionnaire.questions.ovarian.omc_gastro_problem.options.bowel_obstruction',
        value: 'bowel_obstruction',
      },
      {
        label: 'questionnaire.questions.ovarian.omc_gastro_problem.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['gastrointestinal'],
      },
    ],
  },
  {
    q: 'Q57',
    id: ID.omc_lung_disease,
    type: MULTIPLE_OPTION_SELECT,
    bgIcon: i.doctor_book_kit,
    section: SECTIONS.MY_HEALTH,
    order: 0,
    title: 'questionnaire.questions.ovarian.omc_lung_disease.title',
    subtitle: '',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.ovarian.omc_lung_disease.options.interstitial_lung_disease',
        value: 'interstitial_lung_disease',
      },
      {
        label: 'questionnaire.questions.ovarian.omc_lung_disease.options.pneumonitis',
        value: 'pneumonitis',
      },
      {
        label: 'questionnaire.questions.ovarian.omc_lung_disease.options.dyspnea',
        value: 'dyspnea',
      },
      {
        label: 'questionnaire.questions.ovarian.omc_lung_disease.options.tuberculosis',
        value: 'tuberculosis',
      },
      {
        label: 'questionnaire.questions.ovarian.omc_lung_disease.options.copd',
        value: 'copd',
      },
      {
        label: 'questionnaire.questions.ovarian.omc_lung_disease.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['lung'],
      },
    ],
  },
  {
    q: 'Q58',
    id: ID.omc_abnormal_blood_results,
    type: MULTIPLE_OPTION_SELECT,
    bgIcon: i.doctor_book_kit,
    section: SECTIONS.MY_HEALTH,
    order: 0,
    title: 'questionnaire.questions.ovarian.omc_abnormal_blood_results.title',
    subtitle: '',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.ovarian.omc_abnormal_blood_results.options.none',
        value: 'none',
      },
      {
        label: 'questionnaire.questions.ovarian.omc_abnormal_blood_results.options.low_wbc',
        value: 'low_wbc',
      },
      {
        label: 'questionnaire.questions.ovarian.omc_abnormal_blood_results.options.low_hemoglobin',
        value: 'low_hemoglobin',
      },
      {
        label: 'questionnaire.questions.ovarian.omc_abnormal_blood_results.options.low_neutrophil_count',
        value: 'low_neutrophil_count',
      },
      {
        label: 'questionnaire.questions.ovarian.omc_abnormal_blood_results.options.low_platelet_count',
        value: 'low_platelet_count',
      },
      {
        label: 'questionnaire.questions.ovarian.omc_abnormal_blood_results.options.low_potassium',
        value: 'low_potassium',
      },
      {
        label: 'questionnaire.questions.ovarian.omc_abnormal_blood_results.options.low_magnesium',
        value: 'low_magnesium',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q59',
    id: ID.other_medications,
    type: MULTIPLE_OPTION_SELECT,
    bgIcon: i.doctor_book_kit,
    section: SECTIONS.MY_HEALTH,
    order: 0,
    title: 'questionnaire.questions.hnscc.other_medications.title',
    subtitle: '',
    info: {
      title: 'questionnaire.questions.hnscc.other_medications.info.title',
      content: 'questionnaire.questions.hnscc.other_medications.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.hnscc.other_medications.options.cyp3a4_inhibitors',
        value: 'cyp3a4_inhibitors',
      },
      {
        label: 'questionnaire.questions.hnscc.other_medications.options.cyp3a4_inducers',
        value: 'cyp3a4_inducers',
      },
      {
        label: 'questionnaire.questions.hnscc.other_medications.options.immunosuppressive_medications',
        value: 'immunosuppressive_medications',
      },
      {
        label: 'questionnaire.questions.hnscc.other_medications.options.anticonvulsants',
        value: 'anticonvulsants',
      },
      {
        label: 'questionnaire.questions.hnscc.other_medications.options.anti_retroviral_therapy',
        value: 'anti_retroviral_therapy',
      },
      {
        label: 'questionnaire.questions.hnscc.other_medications.options.warfarin',
        value: 'warfarin',
      },
      {
        label: 'questionnaire.questions.ovarian.other_medications.options.proton_pump_inhibitors',
        value: 'proton_pump_inhibitors',
      },
      {
        label: 'questionnaire.questions.hnscc.other_medications.options.none',
        value: 'none',
      },
    ],
    conditions: [],
  },
  // About me
  initialDiagnosisDate('Q5b'),
  userType,
  dateOfBirth,
  {
    q: 'Q3',
    id: ID.is_pregnant,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.ABOUT_ME,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.aml.is_pregnant.title',
    info: {
      title: 'questionnaire.questions.aml.is_pregnant.info.title',
      content: 'questionnaire.questions.aml.is_pregnant.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.aml.is_pregnant.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.aml.is_pregnant.options.no',
        value: 'no',
      },
    ],
    conditions: [
      {
        type: AGE_LESS_THAN,
        ifValue: 50,
      },
      {
        ifId: ID.user_type,
        type: EQUAL_TO,
        ifValue: 'patient',
      },
    ],
  },
  {
    q: 'Q3',
    id: ID.is_menopausal,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.ABOUT_ME,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.ovarian.is_menopausal.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.aml.is_pregnant.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.aml.is_pregnant.options.no',
        value: 'no',
      },
    ],
    conditions: [
      {
        ifId: ID.user_type,
        type: EQUAL_TO,
        ifValue: 'patient',
      },
      {
        type: AGE_LESS_THAN,
        ifValue: 50,
      },
    ],
  },
  {
    q: 'Q3',
    id: ID.is_breastfeeding,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.ABOUT_ME,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.aml.is_breastfeeding.title',
    info: {
      title: 'questionnaire.questions.aml.is_breastfeeding.info.title',
      content: 'questionnaire.questions.aml.is_breastfeeding.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.aml.is_breastfeeding.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.aml.is_breastfeeding.options.no',
        value: 'no',
      },
    ],
    conditions: [
      {
        type: AGE_LESS_THAN,
        ifValue: 50,
      },
      {
        ifId: ID.user_type,
        type: EQUAL_TO,
        ifValue: 'patient',
      },
    ],
  },

  // About me
  race,
  haveInsurance,
  insurance,
  preferredLocation,
  travelLimit,
];

export default Questions;
