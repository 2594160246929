import React from 'react';
import './style.css';

import LeftArrowIcon from 'new-ui/assets/icons/left_arrow.svg';
import RightArrowIcon from 'new-ui/assets/icons/right_arrow.svg';

const ArrowButton = ({ direction, onClick }) => {
  const isLeft = direction === 'left';

  return (
    <img 
      className={`arrow arrow-${isLeft ? 'left' : 'right'}`} 
      src={isLeft ? LeftArrowIcon : RightArrowIcon} 
      alt={`${isLeft ? 'Left' : 'Right'} Arrow`} 
      onClick={onClick} 
    />
  );
};

export default ArrowButton;
