import { createEmailsToken } from 'new-ui/api';
import app from 'new-ui/app';
import { LOCAL_STORAGE_KEYS } from 'new-ui/constants';

export const updateIntercomToken = async () => {
  const token = await createEmailsToken();
  if (token) {
    app.intercom.update({
      data: {
        token,
      },
    });
    localStorage.removeItem(LOCAL_STORAGE_KEYS.SHOULD_CREATE_EMAILS_TOKEN);
  }
};
