import {
  SINGLE_OPTION_SELECT,
  MULTIPLE_OPTION_SELECT,
  MONTH_PICKER,
  TREATMENT_HISTORY,
  NUMBER_INPUT,
  PROCEDURES,
  MULTIPLE_OPTION_SEARCH_SELECT,
  BIOMARKERS,
  OPTIONS_FROM_SERVER,
} from 'components/Questionnaire/constants/questionTypes';
import dateType from 'utils/constants/dateTypes';
import { Profile as ID } from 'components/Questionnaire/cancers/nhl//NhlProfile.enum';
import numberType from 'utils/constants/numberTypes';
import ConditionTypes from 'components/Questionnaire/constants/conditions';
import i from 'components/Questionnaire/constants/icons';
import {
  preferredLocation,
  travelLimit,
  userType,
  dateOfBirth,
  race,
  insurance,
  haveInsurance,
  diseaseStatus,
  initialDiagnosisDate,
} from 'components/Questionnaire/constants/globalQuestions';
import {
  treatmentCyclesOptions,
  // takingStateOptions,
  yesAndNoOptions,
} from 'components/Questionnaire/constants/globalOptions';
import SECTIONS from 'components/Questionnaire/constants/questionSections';
import questionOptions from 'components/Questionnaire/constants/questionOptions';
import { QUESTION_VIEW_TYPES } from '../../constants/questionViewTypes';
import GLOBAL_ENUMS from '../../constants/globalEnums';

const { biomarkers: BIOMARKERS_QUESTION_ID } = GLOBAL_ENUMS;

const { OPTIONS_LIST_WITH_SHOW_MORE } = QUESTION_VIEW_TYPES;

const noneOption = questionOptions.NONE;
const {
  NOT_EQUAL_TO_OR_SKIPPED,
  DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
  CONTAIN_ONE_OF,
  AGE_LESS_THAN,
  EQUAL_TO,
  CONTAINS_ONE_OF_OR_SKIPPED,
} = ConditionTypes;

const Questions = [
  {
    ...diseaseStatus,
    options: [
      ...diseaseStatus.options,
      {
        label: 'questionnaire.questions.nhl.disease_status.options.complete_response',
        value: 'complete_response',
      },
    ],
  },
  {
    q: 'Q6',
    id: ID.type_of_lymphoma,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.nhl.3_1.type_of_lymphoma.title',
    subtitle: '',
    info: {
      title: 'questionnaire.questions.nhl.3_1.type_of_lymphoma.info.title',
      content: 'questionnaire.questions.nhl.3_1.type_of_lymphoma.content',
    },
    options: [
      {
        label: 'questionnaire.questions.nhl.3_1.type_of_lymphoma.options.b_cell_lymphoma',
        value: 'b_cell_lymphoma',
      },
      {
        label: 'questionnaire.questions.nhl.3_1.type_of_lymphoma.options.t_cell_lymphoma',
        value: 't_cell_lymphoma',
      },
      {
        label: 'questionnaire.questions.nhl.3_1.type_of_lymphoma.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.nhl.3_1.type_of_lymphoma.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [],
    isRequired: true,
  },
  {
    q: 'Q6a',
    id: ID.type_of_bcell_lymphoma,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.nhl.2_type_of_bcell_lymphoma.title',
    info: {
      title: 'questionnaire.questions.nhl.2_type_of_bcell_lymphoma.info.title',
      content: 'questionnaire.questions.nhl.2_type_of_bcell_lymphoma.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.nhl.2_type_of_bcell_lymphoma.options.dlbcl',
        value: 'dlbcl',
      },
      {
        label: 'questionnaire.questions.nhl.2_type_of_bcell_lymphoma.options.follicular_lymphoma',
        value: 'follicular_lymphoma',
      },
      {
        label: 'questionnaire.questions.nhl.2_type_of_bcell_lymphoma.options.mantle_cell_lymphoma',
        value: 'mantle_cell_lymphoma',
      },
      {
        label: 'questionnaire.questions.nhl.2_type_of_bcell_lymphoma.options.marginal_cell_lymphoma',
        value: 'marginal_cell_lymphoma',
      },
      {
        label: 'questionnaire.questions.nhl.2_type_of_bcell_lymphoma.options.hgbl_double_triple_hit',
        value: 'hgbl_double_triple_hit',
      },
      {
        label: 'questionnaire.questions.nhl.2_type_of_bcell_lymphoma.options.hgbl_nos',
        value: 'hgbl_nos',
      },
      {
        label: 'questionnaire.questions.nhl.2_type_of_bcell_lymphoma.options.mediastinal_lymphoma',
        value: 'mediastinal_lymphoma',
      },
      {
        label: 'questionnaire.questions.nhl.2_type_of_bcell_lymphoma.options.burkitt_lymphoma',
        value: 'burkitt_lymphoma',
      },
      {
        label: 'questionnaire.questions.nhl.2_type_of_bcell_lymphoma.options.waldenstrom',
        value: 'waldenstrom',
      },
      {
        label: 'questionnaire.questions.nhl.2_type_of_bcell_lymphoma.options.lymphoblastic_lymphoma',
        value: 'lymphoblastic_lymphoma',
      },
      {
        label: 'questionnaire.questions.nhl.2_type_of_bcell_lymphoma.options.aids_related_lymphoma',
        value: 'aids_related_lymphoma',
      },
      {
        label: 'questionnaire.questions.nhl.2_type_of_bcell_lymphoma.options.post_transplant_lymphoma',
        value: 'post_transplant_lymphoma',
      },
      {
        label: 'questionnaire.questions.nhl.2_type_of_bcell_lymphoma.options.castleman_disease',
        value: 'castleman_disease',
      },
      {
        label: 'questionnaire.questions.nhl.2_type_of_bcell_lymphoma.options.cns_lymphoma',
        value: 'cns_lymphoma',
      },
      {
        label: 'questionnaire.questions.nhl.2_type_of_bcell_lymphoma.options.intraocular_lymphoma',
        value: 'intraocular_lymphoma',
      },
      {
        label: 'questionnaire.questions.nhl.2_type_of_bcell_lymphoma.options.plasmablastic_lymphoma',
        value: 'plasmablastic_lymphoma',
      },
      {
        label: 'questionnaire.questions.nhl.2_type_of_bcell_lymphoma.options.primary_effusion_lymphoma',
        value: 'primary_effusion_lymphoma',
      },
      {
        label: 'questionnaire.questions.nhl.2_type_of_bcell_lymphoma.options.ptfl',
        value: 'ptfl',
      },
      {
        label: 'questionnaire.questions.nhl.2_type_of_bcell_lymphoma.options.other_type',
        value: 'other_type',
      },
      {
        label: 'questionnaire.questions.nhl.2_type_of_bcell_lymphoma.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: ID.type_of_lymphoma,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 't_cell_lymphoma',
      },
    ],
    isRequired: true,
  },
  {
    q: 'Q6b',
    id: ID.type_of_tcell_lymphoma,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.nhl.type_of_tcell_lymphoma.title',
    info: {
      title: 'questionnaire.questions.nhl.type_of_tcell_lymphoma.info.title',
      content: 'questionnaire.questions.nhl.type_of_tcell_lymphoma.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.nhl.type_of_tcell_lymphoma.options.ptcl_nos',
        value: 'ptcl_nos',
      },
      {
        label: 'questionnaire.questions.nhl.type_of_tcell_lymphoma.options.aitl',
        value: 'aitl',
      },
      {
        label: 'questionnaire.questions.nhl.type_of_tcell_lymphoma.options.alcl_alk_pos',
        value: 'alcl_alk_pos',
      },
      {
        label: 'questionnaire.questions.nhl.type_of_tcell_lymphoma.options.alcl_alk_neg',
        value: 'alcl_alk_neg',
      },
      {
        label: 'questionnaire.questions.nhl.type_of_tcell_lymphoma.options.primary_cutaneus',
        value: 'primary_cutaneus',
      },
      {
        label: 'questionnaire.questions.nhl.type_of_tcell_lymphoma.options.primary_cutaneus_g_d',
        value: 'primary_cutaneus_g_d',
      },
      {
        label: 'questionnaire.questions.nhl.type_of_tcell_lymphoma.options.breast_implant_alcl',
        value: 'breast_implant_alcl',
      },
      {
        label: 'questionnaire.questions.nhl.type_of_tcell_lymphoma.options.eatl',
        value: 'eatl',
      },
      {
        label: 'questionnaire.questions.nhl.type_of_tcell_lymphoma.options.meitl',
        value: 'meitl',
      },
      {
        label: 'questionnaire.questions.nhl.type_of_tcell_lymphoma.options.nodal_ptcl_tfh',
        value: 'nodal_ptcl_tfh',
      },
      {
        label: 'questionnaire.questions.nhl.type_of_tcell_lymphoma.options.ftcl',
        value: 'ftcl',
      },
      {
        label: 'questionnaire.questions.nhl.type_of_tcell_lymphoma.options.adult_t_cell_lymphoma',
        value: 'adult_t_cell_lymphoma',
      },
      {
        label: 'questionnaire.questions.nhl.type_of_tcell_lymphoma.options.extranodal_nk_t_cell_lymphoma',
        value: 'extranodal_nk_t_cell_lymphoma',
      },
      {
        label: 'questionnaire.questions.nhl.type_of_tcell_lymphoma.options.hepatosplenic_t_cell_lymphoma',
        value: 'hepatosplenic_t_cell_lymphoma',
      },
      {
        label: 'questionnaire.questions.nhl.type_of_tcell_lymphoma.options.subcutaneous_panniculitis',
        value: 'subcutaneous_panniculitis',
      },
      {
        label: 'questionnaire.questions.nhl.type_of_tcell_lymphoma.options.primary_cns_t_cell_lymphoma',
        value: 'primary_cns_t_cell_lymphoma',
      },
      {
        label: 'questionnaire.questions.nhl.type_of_tcell_lymphoma.options.sezary_syndrome',
        value: 'sezary_syndrome',
      },
      {
        label: 'questionnaire.questions.nhl.type_of_tcell_lymphoma.options.mycosis_fungoides',
        value: 'mycosis_fungoides',
      },
      {
        label: 'questionnaire.questions.nhl.type_of_tcell_lymphoma.options.t_lymphoblastic_lymphoma',
        value: 't_lymphoblastic_lymphoma',
      },
    ],
    conditions: [
      {
        ifId: ID.type_of_lymphoma,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'b_cell_lymphoma',
      },
    ],
    isRequired: true,
  },
  {
    q: 'Q8',
    id: ID.current_stage,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.nhl.4_current_stage.title',
    subtitle: 'questionnaire.questions.nhl.4_current_stage.subtitle',
    info: {
      title: 'questionnaire.questions.nhl.4_current_stage.info.title',
      content: 'questionnaire.questions.nhl.4_current_stage.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.nhl.4_current_stage.options.0',
        value: '0',
      },
      {
        label: 'questionnaire.questions.nhl.4_current_stage.options.1',
        value: '1',
      },
      {
        label: 'questionnaire.questions.nhl.4_current_stage.options.2',
        value: '2',
      },
      {
        label: 'questionnaire.questions.nhl.4_current_stage.options.3',
        value: '3',
      },
      {
        label: 'questionnaire.questions.nhl.4_current_stage.options.4',
        value: '4',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q11',
    id: ID.treatment_for_lymphoma,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.nhl.23_treatment_for_lymphoma.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.nhl.23_treatment_for_lymphoma.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.nhl.23_treatment_for_lymphoma.options.no',
        value: 'no',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q6c',
    id: ID.type_of_aids_related_lymphoma,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.nhl.type_of_aids_related_lymphoma.title',
    info: {
      title: 'questionnaire.questions.nhl.type_of_aids_related_lymphoma.info.title',
      content: 'questionnaire.questions.nhl.type_of_aids_related_lymphoma.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.nhl.type_of_aids_related_lymphoma.options.dlbcl',
        value: 'dlbcl',
      },
      {
        label: 'questionnaire.questions.nhl.type_of_aids_related_lymphoma.options.burkitt_lymphoma',
        value: 'burkitt_lymphoma',
      },
      {
        label: 'questionnaire.questions.nhl.type_of_aids_related_lymphoma.options.cns_lymphoma',
        value: 'cns_lymphoma',
      },
      {
        label: 'questionnaire.questions.nhl.type_of_aids_related_lymphoma.options.plasmablastic_lymphoma',
        value: 'plasmablastic_lymphoma',
      },
      {
        label: 'questionnaire.questions.nhl.type_of_aids_related_lymphoma.options.primary_effusion_lymphoma',
        value: 'primary_effusion_lymphoma',
      },
    ],
    conditions: [
      {
        ifId: ID.type_of_bcell_lymphoma,
        type: EQUAL_TO,
        ifValue: 'aids_related_lymphoma',
      },
    ],
  },
  {
    q: 'Q7',
    id: ID.indolent_transformed_dlbcl,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.nhl.3_indolent_transformed_dlbcl.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.nhl.3_indolent_transformed_dlbcl.options.transformed',
        value: 'transformed',
      },
      {
        label: 'questionnaire.questions.nhl.3_indolent_transformed_dlbcl.options.not_transformed',
        value: 'not_transformed',
      },
    ],
    conditions: [
      {
        ifId: ID.type_of_bcell_lymphoma,
        type: EQUAL_TO,
        ifValue: 'dlbcl',
      },
    ],
  },
  {
    q: 'Q8a',
    id: ID.current_stage_follicular_lymphoma,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.nhl.5_current_stage_follicular_lymphoma.title',

    info: {
      title: 'questionnaire.questions.nhl.5_current_stage_follicular_lymphoma.info.title',
      content: 'questionnaire.questions.nhl.5_current_stage_follicular_lymphoma.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.nhl.5_current_stage_follicular_lymphoma.options.1',
        value: '1',
      },
      {
        label: 'questionnaire.questions.nhl.5_current_stage_follicular_lymphoma.options.2',
        value: '2',
      },
      {
        label: 'questionnaire.questions.nhl.5_current_stage_follicular_lymphoma.options.3.1',
        value: '3.1',
      },
      {
        label: 'questionnaire.questions.nhl.5_current_stage_follicular_lymphoma.options.3.2',
        value: '3.2',
      },
    ],
    conditions: [
      {
        ifId: ID.type_of_bcell_lymphoma,
        type: EQUAL_TO,
        ifValue: 'follicular_lymphoma',
      },
    ],
  },
  {
    q: 'Q84',
    id: ID.type_of_dlbcl,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.nhl.6_type_of_dlbcl.title',

    info: {
      title: 'questionnaire.questions.nhl.6_type_of_dlbcl.info.title',
      content: 'questionnaire.questions.nhl.6_type_of_dlbcl.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.nhl.6_type_of_dlbcl.options.dlbcl_nos',
        value: 'dlbcl_nos',
      },
      {
        label: 'questionnaire.questions.nhl.6_type_of_dlbcl.options.gcb_dlbcl',
        value: 'gcb_dlbcl',
      },
      {
        label: 'questionnaire.questions.nhl.6_type_of_dlbcl.options.abc_dlbcl',
        value: 'abc_dlbcl',
      },
      {
        label: 'questionnaire.questions.nhl.6_type_of_dlbcl.options.hit_dlbcl',
        value: 'hit_dlbcl',
      },
      {
        label: 'questionnaire.questions.nhl.6_type_of_dlbcl.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: ID.type_of_bcell_lymphoma,
        type: EQUAL_TO,
        ifValue: 'dlbcl',
      },
    ],
  },
  {
    q: 'Q85',
    id: ID.ebv_dlbcl,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.nhl.7_ebv_dlbcl.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.nhl.7_ebv_dlbcl.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.nhl.7_ebv_dlbcl.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.nhl.7_ebv_dlbcl.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: ID.type_of_bcell_lymphoma,
        type: EQUAL_TO,
        ifValue: 'dlbcl',
      },
    ],
  },
  {
    q: 'Q82',
    id: ID.type_of_marginal_cell_lymphoma,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.nhl.8_type_of_marginal_cell_lymphoma.title',

    info: {
      title: 'questionnaire.questions.nhl.8_type_of_marginal_cell_lymphoma.info.title',
      content: 'questionnaire.questions.nhl.8_type_of_marginal_cell_lymphoma.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.nhl.8_type_of_marginal_cell_lymphoma.options.gastric_malt_lymphoma',
        value: 'gastric_malt_lymphoma',
      },
      {
        label: 'questionnaire.questions.nhl.8_type_of_marginal_cell_lymphoma.options.nongastric_malt_lymphoma',
        value: 'nongastric_malt_lymphoma',
      },
      {
        label: 'questionnaire.questions.nhl.8_type_of_marginal_cell_lymphoma.options.nodal_marginal_lymphoma',
        value: 'nodal_marginal_lymphoma',
      },
      {
        label: 'questionnaire.questions.nhl.8_type_of_marginal_cell_lymphoma.options.splenic_marginal_lymphoma',
        value: 'splenic_marginal_lymphoma,',
      },
      {
        label: 'questionnaire.questions.nhl.8_type_of_marginal_cell_lymphoma.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: ID.type_of_bcell_lymphoma,
        type: EQUAL_TO,
        ifValue: 'marginal_cell_lymphoma',
      },
    ],
  },
  {
    q: 'Q81',
    id: ID.hpylori,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.nhl.9_hpylori.title',

    info: {
      title: 'questionnaire.questions.nhl.9_hpylori.info.title',
      content: 'questionnaire.questions.nhl.9_hpylori.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.nhl.9_hpylori.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.nhl.9_hpylori.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.nhl.9_hpylori.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: ID.type_of_marginal_cell_lymphoma,
        type: EQUAL_TO,
        ifValue: 'gastric_malt_lymphoma',
      },
    ],
  },
  {
    q: 'Q81a',
    id: ID.hpylori_antbiotic_treatment,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.nhl.10_hpylori_antbiotic_treatment.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.nhl.10_hpylori_antbiotic_treatment.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.nhl.10_hpylori_antbiotic_treatment.options.yes_on_treatment',
        value: 'yes_on_treatment',
      },
      {
        label: 'questionnaire.questions.nhl.10_hpylori_antbiotic_treatment.options.scheduled',
        value: 'scheduled',
      },
      {
        label: 'questionnaire.questions.nhl.10_hpylori_antbiotic_treatment.options.no',
        value: 'no',
      },
    ],
    conditions: [
      {
        ifId: ID.type_of_marginal_cell_lymphoma,
        type: EQUAL_TO,
        ifValue: 'gastric_malt_lymphoma',
      },
    ],
  },
  {
    q: 'Q10',
    id: ID.disease_location,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.nhl.11_disease_location.title',
    subtitle: 'questionnaire.questions.nhl.11_disease_location.subtitle',
    info: {
      title: 'questionnaire.questions.nhl.11_disease_location.info.title',
      content: 'questionnaire.questions.nhl.11_disease_location.info.content',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.nhl.11_disease_location.options.ln',
        value: 'ln',
      },
      {
        label: 'questionnaire.questions.nhl.11_disease_location.options.spleen',
        value: 'spleen',
      },
      {
        label: 'questionnaire.questions.nhl.11_disease_location.options.thymus',
        value: 'thymus',
      },
      {
        label: 'questionnaire.questions.nhl.11_disease_location.options.bm',
        value: 'bm',
      },
      {
        label: 'questionnaire.questions.nhl.11_disease_location.options.blood',
        value: 'blood',
      },
      {
        label: 'questionnaire.questions.nhl.11_disease_location.options.liver',
        value: 'liver',
      },
      {
        label: 'questionnaire.questions.nhl.11_disease_location.options.lung',
        value: 'lung',
      },
      {
        label: 'questionnaire.questions.nhl.11_disease_location.options.kidney',
        value: 'kidney',
      },
      {
        label: 'questionnaire.questions.nhl.11_disease_location.options.gi_tract',
        value: 'gi_tract',
      },
      {
        label: 'questionnaire.questions.nhl.11_disease_location.options.skin',
        value: 'skin',
      },
      {
        label: 'questionnaire.questions.nhl.11_disease_location.options.thyroid',
        value: 'thyroid',
      },
      {
        label: 'questionnaire.questions.nhl.11_disease_location.options.testis”',
        value: 'testis”',
      },
      {
        label: 'questionnaire.questions.nhl.11_disease_location.options.saliva_glands',
        value: 'saliva_glands',
      },
      {
        label: 'questionnaire.questions.nhl.11_disease_location.options.eye',
        value: 'eye',
      },
      {
        label: 'questionnaire.questions.nhl.11_disease_location.options.brain',
        value: 'brain',
      },
      {
        label: 'questionnaire.questions.nhl.11_disease_location.options.spinal_cord',
        value: 'spinal_cord',
      },
      {
        label: 'questionnaire.questions.nhl.11_disease_location.options.other',
        value: 'other',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q18',
    id: ID.metastasis_location_brain_leptomeningeal,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.nhl.12_metastasis_location_brain_leptomeningeal.title',

    info: {
      title: 'questionnaire.questions.nhl.12_metastasis_location_brain_leptomeningeal.info.title',
      content: 'questionnaire.questions.nhl.12_metastasis_location_brain_leptomeningeal.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.nhl.12_metastasis_location_brain_leptomeningeal.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.nhl.12_metastasis_location_brain_leptomeningeal.options.no',
        value: 'no',
      },
    ],
    conditions: [
      {
        ifId: ID.disease_location,
        type: CONTAIN_ONE_OF,
        ifValue: ['brain'],
      },
    ],
  },
  {
    q: 'Q19',
    id: ID.brain_metastasis_active,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.nhl.13_brain_metastasis_active.title',
    subtitle: 'questionnaire.questions.nhl.13_brain_metastasis_active.subtitle',
    info: {
      title: 'questionnaire.questions.nhl.13_brain_metastasis_active.info.title',
      content: 'questionnaire.questions.nhl.13_brain_metastasis_active.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.nhl.13_brain_metastasis_active.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.nhl.13_brain_metastasis_active.options.no',
        value: 'no',
      },
    ],
    conditions: [
      {
        ifId: ID.disease_location,
        type: CONTAIN_ONE_OF,
        ifValue: ['brain', 'spinal_cord'],
      },
    ],
  },
  {
    q: 'Q15',
    id: ID.disease_measurable,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.nhl.14_disease_measurable.title',

    info: {
      title: 'questionnaire.questions.nhl.14_disease_measurable.info.title',
      content: 'questionnaire.questions.nhl.14_disease_measurable.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.nhl.14_disease_measurable.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.nhl.14_disease_measurable.options.no_but_it_can_be',
        value: 'no_but_it_can_be',
      },
      {
        label: 'questionnaire.questions.nhl.14_disease_measurable.options.no',
        value: 'no',
      },
    ],
    conditions: [
      {
        ifId: ID.disease_status,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'remission',
      },
    ],
    isRequired: true,
  },
  {
    q: 'Q86',
    id: ID.lymphoma_size_characteristics,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.nhl.15_lymphoma_size_characteristics.title',
    subtitle: 'questionnaire.questions.nhl.15_lymphoma_size_characteristics.subtitle',
    info: {
      title: 'questionnaire.questions.nhl.15_lymphoma_size_characteristics.info.title',
      content: 'questionnaire.questions.nhl.15_lymphoma_size_characteristics.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.nhl.15_lymphoma_size_characteristics.options.measurable_nodal_lesion',
        value: 'measurable_nodal_lesion',
      },
      {
        label: 'questionnaire.questions.nhl.15_lymphoma_size_characteristics.options.measurable_extranodal_lesion',
        value: 'measurable_extranodal_lesion',
      },
      {
        label: 'questionnaire.questions.nhl.15_lymphoma_size_characteristics.options.none',
        value: 'none',
        exclusive: true,
      },
    ],
    conditions: [
      {
        ifId: ID.disease_measurable,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['no'],
      },
    ],
  },
  {
    q: 'Q16',
    id: ID.largest_lesion_size_mm,
    type: NUMBER_INPUT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.nhl.16_largest_lesion_size_mm.title',
    subtitle: 'questionnaire.questions.nhl.16_largest_lesion_size_mm.subtitle',
    instruction: 'questionnaire.questions.nhl.16_largest_lesion_size_mm.instruction',
    info: {
      title: 'questionnaire.questions.nhl.16_largest_lesion_size_mm.info.title',
      content: 'questionnaire.questions.nhl.16_largest_lesion_size_mm.info.content',
    },
    options: [],
    conditions: [
      {
        ifId: ID.disease_measurable,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['no', 'no_but_it_can_be'],
      },
    ],
    numberType: numberType.int,
  },
  {
    q: 'Q21',
    id: ID.has_biopsy,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.nhl.17_has_biopsy.title',

    info: {
      title: 'questionnaire.questions.nhl.17_has_biopsy.info.title',
      content: 'questionnaire.questions.nhl.17_has_biopsy.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.nhl.17_has_biopsy.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.nhl.17_has_biopsy.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.nhl.17_has_biopsy.options.biopsy_possible',
        value: 'biopsy_possible',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q80',
    id: ID.ngs,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.molecular,
    order: 0,
    title: 'questionnaire.questions.nhl.44_ngs.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.nhl.44_ngs.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.nhl.44_ngs.options.yes_waiting_for_the_results',
        value: 'yes_waiting_for_the_results',
      },
      {
        label: 'questionnaire.questions.nhl.44_ngs.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.nhl.44_ngs.options.no_but_planning',
        value: 'no_but_planning',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q22',
    id: BIOMARKERS_QUESTION_ID,
    type: BIOMARKERS,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.molecular,
    order: 0,
    title: 'questionnaire.questions.nhl.18_biomarkers.title',
    subtitle: 'questionnaire.questions.nhl.18_biomarkers.subtitle',
    placeholder: 'questionnaire.questions.biomarkers.placeholder',
    info: {
      title: '',
      content: '',
    },
    conditions: [
      {
        ifId: ID.has_biopsy,
        type: CONTAINS_ONE_OF_OR_SKIPPED,
        ifValue: ['yes'],
      },
    ],
  },
  {
    q: 'Q12',
    id: ID.indolent_lymphoma_burden,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.nhl.19_indolent_lymphoma_burden.title',

    info: {
      title: 'questionnaire.questions.nhl.19_indolent_lymphoma_burden.info.title',
      content: 'questionnaire.questions.nhl.19_indolent_lymphoma_burden.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.nhl.19_indolent_lymphoma_burden.options.high_burden',
        value: 'high_burden',
      },
      {
        label: 'questionnaire.questions.nhl.19_indolent_lymphoma_burden.options.low_burden',
        value: 'low_burden',
      },
      {
        label: 'questionnaire.questions.nhl.19_indolent_lymphoma_burden.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      // {  // TODO: commented out intentionally; WEBAPP-2350
      //   ifId: ID.type_of_bcell_lymphoma,
      //   type: CONTAIN_ONE_OF,
      //   ifValue: [
      //     'follicular_lymphoma',
      //     'marginal_cell_lymphoma'
      //   ]
      // }
    ],
    isRequired: true,
  },
  {
    q: 'Q13',
    id: ID.procedure_candidate,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.nhl.20_procedure_candidate.title',

    info: {
      title: 'questionnaire.questions.nhl.20_procedure_candidate.info.title',
      content: 'questionnaire.questions.nhl.20_procedure_candidate.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.nhl.20_procedure_candidate.options.autologous_tranplant_candidate',
        value: 'autologous_tranplant_candidate',
      },
      {
        label: 'questionnaire.questions.nhl.20_procedure_candidate.options.allogenenic_transplant_candidate',
        value: 'allogenenic_transplant_candidate',
      },
      {
        label: 'questionnaire.questions.nhl.20_procedure_candidate.options.cart_therapy_candidate',
        value: 'cart_therapy_candidate',
      },
      {
        label: 'questionnaire.questions.nhl.20_procedure_candidate.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.nhl.20_procedure_candidate.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: ID.indolent_lymphoma_burden,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'low_burden',
      },
    ],
  },
  {
    q: 'Q61',
    id: ID.matching_donor,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.nhl.matching_donor.title',
    info: {
      title: 'questionnaire.questions.nhl.matching_donor.info.title',
      content: 'questionnaire.questions.nhl.matching_donor.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.nhl.matching_donor.options.fully_matched',
        value: 'fully_matched',
      },
      {
        label: 'questionnaire.questions.nhl.matching_donor.options.haploidentical',
        value: 'haploidentical',
      },
      {
        label: 'questionnaire.questions.nhl.matching_donor.options.look_for_donor',
        value: 'look_for_donor',
      },
      {
        label: 'questionnaire.questions.nhl.matching_donor.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: ID.procedure_candidate,
        type: EQUAL_TO,
        ifValue: 'allogenenic_transplant_candidate',
      },
    ],
  },
  {
    q: 'Q13a',
    id: ID.had_procedure_for_lymphoma,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.nhl.21_had_procedure_for_lymphoma.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.nhl.21_had_procedure_for_lymphoma.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.nhl.21_had_procedure_for_lymphoma.options.no',
        value: 'no',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q70',
    id: ID.procedures,
    type: PROCEDURES,
    section: SECTIONS.TREATMENT,
    bgIcon: '',
    order: 0,
    title: 'questionnaire.questions.nhl.22_procedures.title',
    subtitle: 'questionnaire.questions.nhl.22_procedures.subtitle',
    footer: 'questionnaire.questions.nhl.22_procedures.footer',
    requiredSubQuestionsId: [ID.procedures_received],
    info: {
      title: '',
      content: '',
    },
    submitButtonLabel: 'questionnaire.questions.nhl.22_procedures.button.submit',
    options: [
      {
        iconText: 'A',
        label: 'questionnaire.questions.nhl.22_procedures.options.procedure_name',
        value: null,
      },
      {
        iconText: 'B',
        label: 'questionnaire.questions.nhl.22_procedures.options.procedure_purpose',
        value: null,
      },
      {
        iconText: 'C',
        label: 'questionnaire.questions.nhl.22_procedures.options.procedure_took_place',
        value: null,
      },
      {
        iconText: 'D',
        label: 'questionnaire.questions.nhl.22_procedures.options.procedure_outcome',
        value: null,
      },
    ],
    shouldAutoOpenSubQuestions: [{
      ifId: ID.had_procedure_for_lymphoma,
      type: EQUAL_TO,
      ifValue: 'yes',
    }],
    conditions: [
      {
        ifId: ID.had_procedure_for_lymphoma,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'no',
      },
    ],
    subQuestions: [
      {
        q: 'Q70a',
        id: ID.procedures_received,
        type: MULTIPLE_OPTION_SEARCH_SELECT,
        viewType: OPTIONS_LIST_WITH_SHOW_MORE,
        section: SECTIONS.TREATMENT,
        bgIcon: i.treatment,
        order: 0,
        titleIconText: 'A',
        title: 'questionnaire.questions.nhl.22_procedures.sub_questions.procedures_received.title',
        subtitle: '',
        placeholder: 'questionnaire.questions.procedures_received.placeholder',
        info: {
          title: '',
          content: '',
        },
        options: {
          dropDownOptions: [
            {
              label: 'questionnaire.questions.nhl.22_procedures.sub_questions.procedures_received.options.radiation',
              value: 'radiation',
            },
            {
              label:
                'questionnaire.questions.nhl.22_procedures.sub_questions.procedures_received.options.autologous_transplant',
              value: 'autologous_transplant',
            },
            {
              label:
                'questionnaire.questions.nhl.22_procedures.sub_questions.procedures_received.options.allogeneic_transplant',
              value: 'allogeneic_transplant',
            },
            {
              label: 'questionnaire.questions.nhl.22_procedures.sub_questions.procedures_received.options.car_t',
              value: 'car_t',
            },
            {
              label: 'questionnaire.questions.nhl.22_procedures.sub_questions.procedures_received.options.surgery',
              value: 'surgery',
            },
          ],
          radioOptions: [
            {
              label: 'questionnaire.questions.nhl.22_procedures.sub_questions.procedures_received.options.other',
              value: 'other',
            },
          ],
        },
        conditions: [],
      },
      {
        q: 'Q70b',
        id: ID.procedure_date,
        type: MONTH_PICKER,
        section: SECTIONS.TREATMENT,
        bgIcon: '',
        order: 0,
        titleIconText: 'B',
        title: 'questionnaire.questions.nhl.22_procedures.sub_questions.procedure_date.title',
        subtitle: '',
        info: {
          title: '',
          content: '',
        },
        options: [],
        conditions: [],
        dateType: {
          type: dateType.stopTreatment,
        },
      },
      {
        q: 'Q70c',
        id: ID.procedure_outcome,
        type: SINGLE_OPTION_SELECT,
        section: SECTIONS.TREATMENT,
        bgIcon: '',
        order: 0,
        titleIconText: 'C',
        title: 'questionnaire.questions.nhl.22_procedures.sub_questions.procedure_outcome.title',
        subtitle: '',
        info: {
          title: '',
          content: '',
        },
        disableNextOnOptionSelect: false,
        options: [
          {
            label: 'questionnaire.questions.nhl.22_procedures.sub_questions.procedure_outcome.options.cr',
            value: 'cr',
          },
          {
            label: 'questionnaire.questions.nhl.22_procedures.sub_questions.procedure_outcome.options.pr',
            value: 'pr',
          },
        ],
        answerResetable: true,
        conditions: [],
      },
    ],
  },
  {
    q: 'Q60',
    id: ID.treatments,
    type: TREATMENT_HISTORY,
    section: SECTIONS.TREATMENT,
    bgIcon: '',
    order: 0,
    title: 'questionnaire.questions.nhl.24_treatments.title',

    requiredSubQuestionsId: [ID.drugs_received],
    footer: 'questionnaire.questions.nhl.24_treatments.footer',
    submitButtonLabel: 'questionnaire.questions.nhl.24_treatments.button.submit',
    options: [
      {
        iconText: 'A',
        label: 'questionnaire.questions.nhl.24_treatments.options.treatment_name',
        value: null,
      },
      {
        iconText: 'B',
        label: 'questionnaire.questions.nhl.24_treatments.options.when_treatment_stopped',
        value: null,
      },
      {
        iconText: 'C',
        label: 'questionnaire.questions.nhl.24_treatments.options.reason_treatment_stopped',
        value: null,
      },
    ],
    // shouldAutoOpenSubQuestions: [{
    //   ifId: ID.treatment_for_lymphoma,
    //   type: EQUAL_TO,
    //   ifValue: 'yes',
    // }],
    conditions: [
      {
        ifId: ID.treatment_for_lymphoma,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'no',
      },
    ],
    isRequired: true,
    subQuestions: [
      {
        q: 'Q60a',
        id: ID.drugs_received,
        type: OPTIONS_FROM_SERVER,
        viewType: OPTIONS_LIST_WITH_SHOW_MORE,
        section: SECTIONS.TREATMENT,
        bgIcon: i.treatment,
        order: 0,
        titleIconText: 'A',
        title: 'questionnaire.questions.nhl.24_treatments.sub_questions.drugs_received.title',
        subtitle: 'questionnaire.questions.nhl.24_treatments.sub_questions.drugs_received.subtitle',
        placeholder: 'questionnaire.questions.drugs_received.placeholder',
        info: {
          title: '',
          content: '',
        },
        questionProps: {
          isSearchableByOptionGroupName: true,
        },
      },
      {
        q: 'Q60f',
        id: ID.cycles,
        type: SINGLE_OPTION_SELECT,
        section: SECTIONS.TREATMENT,
        title: 'questionnaire.questions.nhl.24_treatments.sub_questions.cycles.title',
        disableNextOnOptionSelect: false,
        options: [...treatmentCyclesOptions],
      },
      {
        q: 'Q60b',
        id: ID.treatment_end_date,
        type: MONTH_PICKER,
        section: SECTIONS.TREATMENT,
        bgIcon: '',
        order: 0,
        titleIconText: 'B',
        title: 'questionnaire.questions.nhl.24_treatments.sub_questions.treatment_end_date.title',
        subtitle: '',
        info: {
          title: '',
          content: '',
        },
        options: [],
        conditions: [],
        dateType: {
          type: dateType.stopTreatment,
        },
      },
      {
        q: 'Q60c',
        id: ID.treatment_stop_reason,
        type: SINGLE_OPTION_SELECT,
        section: SECTIONS.TREATMENT,
        bgIcon: i.treatment,
        order: 0,
        titleIconText: 'C',
        title: 'questionnaire.questions.nhl.24_treatments.sub_questions.treatment_stop_reason.title',
        subtitle: '',
        info: {
          title: '',
          content: '',
        },
        disableNextOnOptionSelect: false,
        options: [
          {
            label:
              'questionnaire.questions.nhl.24_treatments.sub_questions.treatment_stop_reason.options.treatment_ongoing',
            value: 'treatment_ongoing',
          },
          {
            label:
              'questionnaire.questions.nhl.24_treatments.sub_questions.treatment_stop_reason.options.end_of_protocol',
            value: 'end_of_protocol',
          },
          {
            label: 'questionnaire.questions.nhl.24_treatments.sub_questions.treatment_stop_reason.options.progression',
            value: 'progression',
          },
          {
            label: 'questionnaire.questions.nhl.24_treatments.sub_questions.treatment_stop_reason.options.no_response',
            value: 'no_response',
          },
          {
            label:
              'questionnaire.questions.nhl.24_treatments.sub_questions.treatment_stop_reason.options.loss_of_response',
            value: 'loss_of_response',
          },
          {
            label:
              'questionnaire.questions.nhl.24_treatments.sub_questions.treatment_stop_reason.options.complete_remission',
            value: 'complete_remission',
          },
          {
            label:
              'questionnaire.questions.nhl.24_treatments.sub_questions.treatment_stop_reason.options.partial_remission',
            value: 'partial_remission',
          },
          {
            label: 'questionnaire.questions.nhl.24_treatments.sub_questions.treatment_stop_reason.options.toxicity',
            value: 'toxicity',
          },
          {
            label: 'questionnaire.questions.nhl.24_treatments.sub_questions.treatment_stop_reason.options.other_medical_conditions',
            value: 'other_medical_conditions',
          },
          {
            label: 'questionnaire.questions.nhl.24_treatments.sub_questions.treatment_stop_reason.options.dosing_issues',
            value: 'dosing_issues',
          },
          {
            label: 'questionnaire.questions.nhl.24_treatments.sub_questions.treatment_stop_reason.options.scheduling',
            value: 'scheduling',
          },
          {
            label: 'questionnaire.questions.nhl.24_treatments.sub_questions.treatment_stop_reason.options.travel_limitation',
            value: 'travel_limitation',
          },
          {
            label: 'questionnaire.questions.nhl.24_treatments.sub_questions.treatment_stop_reason.options.financial_reasons_or_insurance',
            value: 'financial_reasons_or_insurance',
          },
          {
            label: 'questionnaire.questions.nhl.24_treatments.sub_questions.treatment_stop_reason.options.other',
            value: 'other',
          },
        ],
        conditions: [],
      },
    ],
    // title: '',
    // info: {
    //   title: '',
    //   content: '',
    // },
    // footer: '',
    // type: TREATMENT_HISTORY,
    // section: SECTIONS.TREATMENT,
    // bgIcon: '',
    // order: 0,
    // subQuestionsIds: [
    //   ID.drugs_received,
    //   ID.treatment_stop_reason,
    // ],
    // stageIds: [ID.current_stage],
    // surgeryId: ID.had_procedure_for_lymphoma,
    // options: [],
    // subQuestionsOptions: {
    //   treatment_stop_reason: [
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.no_response',
    //       value: 'no_response',
    //     },
    //     {
    //       label:
    //         'questionnaire.questions.treatment_stop_reason.options.end_of_protocol',
    //       value: 'end_of_protocol',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.toxicity',
    //       value: 'toxicity',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.progression',
    //       value: 'progression',
    //     },
    //     {
    //       label:
    //         'questionnaire.questions.treatment_stop_reason.options.loss_of_response',
    //       value: 'loss_of_response',
    //     },
    //     {
    //       label:
    //         'questionnaire.questions.treatment_stop_reason.options.complete_remission',
    //       value: 'complete_remission',
    //     },
    //     {
    //       label:
    //         'questionnaire.questions.treatment_stop_reason.options.partial_remission',
    //       value: 'partial_remission',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.other',
    //       value: 'other',
    //     },
    //   ],
    //   taking_state: takingStateOptions,
    // },
    // conditions: [
    //   {
    //     ifId: ID.treatment_for_lymphoma,
    //     type: NOT_EQUAL_TO_OR_SKIPPED,
    //     ifValue: 'no',
    //   },
    // ],
  },
  {
    q: 'Q83',
    id: ID.gvhd,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.nhl.25_gvhd.title',

    info: {
      title: 'questionnaire.questions.nhl.25_gvhd.info.title',
      content: 'questionnaire.questions.nhl.25_gvhd.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.nhl.25_gvhd.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.nhl.25_gvhd.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.nhl.25_gvhd.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: ID.procedures_received,
        type: CONTAIN_ONE_OF,
        ifValue: ['allogeneic_transplant'],
      },
    ],
  },
  {
    q: 'Q44',
    id: ID.current_treatment_systemic_steroids,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.nhl.26_current_treatment_systemic_steroids.title',
    subtitle: 'questionnaire.questions.nhl.26_current_treatment_systemic_steroids.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.nhl.26_current_treatment_systemic_steroids.options.none',
        value: 'none',
      },
      {
        label: 'questionnaire.questions.nhl.26_current_treatment_systemic_steroids.options.under_10_mg_daily',
        value: 'under_10_mg_daily',
      },
      {
        label: 'questionnaire.questions.nhl.26_current_treatment_systemic_steroids.options.over_10_mg_daily',
        value: 'over_10_mg_daily',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q44a',
    id: ID.systemic_steroids_treatment_end_date,
    type: MONTH_PICKER,
    section: SECTIONS.TREATMENT,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.nhl.27_systemic_steroids_treatment_end_date.title',

    info: {
      title: '',
      content: '',
    },
    options: [],
    conditions: [
      {
        ifId: ID.current_treatment_systemic_steroids,
        type: CONTAIN_ONE_OF,
        ifValue: ['under_10_mg_daily', 'over_10_mg_daily'],
      },
    ],
    dateType: {
      type: dateType.stopTreatment,
    },
  },
  {
    q: 'Q45',
    id: ID.ecog,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.nhl.28_ecog.title',

    info: {
      title: 'questionnaire.questions.nhl.28_ecog.info.title',
      content: 'questionnaire.questions.nhl.28_ecog.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.nhl.28_ecog.options.0',
        value: '0',
      },
      {
        label: 'questionnaire.questions.nhl.28_ecog.options.1',
        value: '1',
      },
      {
        label: 'questionnaire.questions.nhl.28_ecog.options.2',
        value: '2',
      },
      {
        label: 'questionnaire.questions.nhl.28_ecog.options.3',
        value: '3',
      },
      {
        label: 'questionnaire.questions.nhl.28_ecog.options.4',
        value: '4',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q46',
    id: ID.other_medical_condition,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.nhl.29_other_medical_condition.title',
    subtitle: 'questionnaire.questions.nhl.29_other_medical_condition.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.nhl.29_other_medical_condition.options.no_other_health_issues',
        value: 'no_other_health_issues',
        exclusive: true,
      },
      {
        label: 'questionnaire.questions.nhl.29_other_medical_condition.options.liver',
        value: 'liver',
      },
      {
        label: 'questionnaire.questions.nhl.29_other_medical_condition.options.heart',
        value: 'heart',
      },
      {
        label: 'questionnaire.questions.nhl.29_other_medical_condition.options.kidney',
        value: 'kidney',
      },
      {
        label: 'questionnaire.questions.nhl.29_other_medical_condition.options.autoimmune',
        value: 'autoimmune',
      },
      {
        label: 'questionnaire.questions.nhl.29_other_medical_condition.options.mental',
        value: 'mental',
      },
      {
        label: 'questionnaire.questions.nhl.29_other_medical_condition.options.allergies',
        value: 'allergies',
      },
      {
        label: 'questionnaire.questions.nhl.29_other_medical_condition.options.chronic_infection_or_inflammation',
        value: 'chronic_infection_or_inflammation',
      },
      {
        label: 'questionnaire.questions.nhl.29_other_medical_condition.options.gastrointestinal',
        value: 'gastrointestinal',
      },
      {
        label: 'questionnaire.questions.nhl.29_other_medical_condition.options.other_cancer_type',
        value: 'other_cancer_type',
      },
      {
        label: 'questionnaire.questions.nhl.29_other_medical_condition.options.anemia',
        value: 'anemia',
      },
      {
        label: 'questionnaire.questions.nhl.29_other_medical_condition.options.thyroid',
        value: 'thyroid',
      },
      {
        label: 'questionnaire.questions.nhl.29_other_medical_condition.options.hypertension',
        value: 'hypertension',
      },
      {
        label: 'questionnaire.questions.nhl.29_other_medical_condition.options.epilepsy',
        value: 'epilepsy',
      },
      {
        label: 'questionnaire.questions.nhl.29_other_medical_condition.options.diabetes',
        value: 'diabetes',
      },
      {
        label: 'questionnaire.questions.nhl.29_other_medical_condition.options.organ_transplant',
        value: 'organ_transplant',
      },
      {
        label: 'questionnaire.questions.nhl.29_other_medical_condition.options.extreme_obesity',
        value: 'extreme_obesity',
      },
      {
        label: 'questionnaire.questions.nhl.29_other_medical_condition.options.stroke',
        value: 'stroke',
      },
      {
        label: 'questionnaire.questions.nhl.29_other_medical_condition.options.lung',
        value: 'lung',
      },
      {
        label: 'questionnaire.questions.nhl.29_other_medical_condition.options.neuropathy',
        value: 'neuropathy',
      },
      {
        label: 'questionnaire.questions.nhl.29_other_medical_condition.options.bleeding_disorder',
        value: 'bleeding_disorder',
      },
      {
        label: 'questionnaire.questions.nhl.29_other_medical_condition.options.other',
        value: 'other',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q48',
    id: ID.omc_liver,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.nhl.30_omc_liver.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.nhl.30_omc_liver.options.elevated_alt_ast',
        value: 'elevated_alt_ast',
      },
      {
        label: 'questionnaire.questions.nhl.30_omc_liver.options.elevated_bilirubin',
        value: 'elevated_bilirubin',
      },
      {
        label: 'questionnaire.questions.nhl.30_omc_liver.options.cirrhosis',
        value: 'cirrhosis',
      },
      {
        label: 'questionnaire.questions.nhl.30_omc_liver.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['liver'],
      },
    ],
  },
  {
    q: 'Q49',
    id: ID.omc_heart,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.nhl.31_omc_heart.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.nhl.31_omc_heart.options.class_at_least_nyha_ii',
        value: 'class_at_least_nyha_ii',
      },
      {
        label: 'questionnaire.questions.nhl.31_omc_heart.options.condition_at_least_grade_2',
        value: 'condition_at_least_grade_2',
      },
      {
        label: 'questionnaire.questions.nhl.31_omc_heart.options.cardiac_ventricular_arrhythmia',
        value: 'cardiac_ventricular_arrhythmia',
      },
      {
        label: 'questionnaire.questions.nhl.31_omc_heart.options.heart_failure',
        value: 'heart_failure',
      },
      {
        label: 'questionnaire.questions.nhl.31_omc_heart.options.lvef_under_50_percent',
        value: 'lvef_under_50_percent',
      },
      {
        label: 'questionnaire.questions.nhl.31_omc_heart.options.qtcf_abnormal',
        value: 'qtcf_abnormal',
      },
      {
        label: 'questionnaire.questions.nhl.31_omc_heart.options.angina',
        value: 'angina',
      },
      {
        label: 'questionnaire.questions.nhl.31_omc_heart.options.myocardial_infarction',
        value: 'myocardial_infarction',
      },
      {
        label: 'questionnaire.questions.nhl.31_omc_heart.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['heart'],
      },
    ],
  },
  {
    q: 'NEWQ61',
    id: ID.omc_heart_failure_date,
    type: MONTH_PICKER,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.nhl.32_omc_heart_failure_date.title',

    info: {
      title: '',
      content: '',
    },
    options: [],
    conditions: [
      {
        ifId: ID.omc_heart,
        type: CONTAIN_ONE_OF,
        ifValue: ['heart_failure'],
      },
    ],
    dateType: {
      type: dateType.stopTreatment,
    },
  },
  {
    q: 'NEWQ62',
    id: ID.omc_myocardial_infarction_date,
    type: MONTH_PICKER,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.nhl.32_omc_myocardial_infarction_date.title',

    info: {
      title: '',
      content: '',
    },
    options: [],
    conditions: [
      {
        ifId: ID.omc_heart,
        type: CONTAIN_ONE_OF,
        ifValue: ['myocardial_infarction'],
      },
    ],
    dateType: {
      type: dateType.stopTreatment,
    },
  },
  {
    q: 'Q50',
    id: ID.omc_stroke_date,
    type: MONTH_PICKER,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.nhl.32_omc_stroke_date.title',

    info: {
      title: '',
      content: '',
    },
    options: [],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['stroke'],
      },
    ],
    dateType: {
      type: dateType.stopTreatment,
    },
  },
  {
    q: 'Q51',
    id: ID.omc_kidney,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.nhl.33_omc_kidney.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.nhl.33_omc_kidney.options.low_glomerular_filtration_rate',
        value: 'low_glomerular_filtration_rate',
      },
      {
        label: 'questionnaire.questions.nhl.33_omc_kidney.options.dialysis',
        value: 'dialysis',
      },
      {
        label: 'questionnaire.questions.nhl.33_omc_kidney.options.kidney_stones',
        value: 'kidney_stones',
      },
      {
        label: 'questionnaire.questions.nhl.33_omc_kidney.options.creatinine_high',
        value: 'creatinine_high',
      },
      {
        label: 'questionnaire.questions.nhl.33_omc_kidney.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['kidney'],
      },
    ],
  },
  {
    q: 'Q52',
    id: ID.omc_autoimmune,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.nhl.34_omc_autoimmune.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.nhl.34_omc_autoimmune.options.congenital_or_acquired_immunosuppression',
        value: 'congenital_or_acquired_immunosuppression',
      },
      {
        label: 'questionnaire.questions.nhl.34_omc_autoimmune.options.hepatitis',
        value: 'hepatitis',
      },
      {
        label: 'questionnaire.questions.nhl.34_omc_autoimmune.options.granulomatous_disease',
        value: 'granulomatous_disease',
      },
      {
        label: 'questionnaire.questions.nhl.34_omc_autoimmune.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['autoimmune'],
      },
    ],
  },
  {
    q: 'Q53',
    id: ID.omc_mental,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.nhl.35_omc_mental.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.nhl.35_omc_mental.options.dementia',
        value: 'dementia',
      },
      {
        label: 'questionnaire.questions.nhl.35_omc_mental.options.neuropsychyatric',
        value: 'neuropsychyatric',
      },
      {
        label: 'questionnaire.questions.nhl.35_omc_mental.options.depression',
        value: 'depression',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['mental'],
      },
    ],
  },
  {
    q: 'Q54',
    id: ID.omc_allergy,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.nhl.36_omc_allergy.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.nhl.36_omc_allergy.options.interferon',
        value: 'interferon',
      },
      {
        label: 'questionnaire.questions.nhl.36_omc_allergy.options.metformin',
        value: 'metformin',
      },
      {
        label: 'questionnaire.questions.nhl.36_omc_allergy.options.vitd',
        value: 'vitd',
      },
      {
        label: 'questionnaire.questions.nhl.36_omc_allergy.options.penicillin',
        value: 'penicillin',
      },
      {
        label: 'questionnaire.questions.nhl.36_omc_allergy.options.aminoglycosides',
        value: 'aminoglycosides',
      },
      {
        label: 'questionnaire.questions.nhl.36_omc_allergy.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['allergies'],
      },
    ],
  },
  {
    q: 'Q55',
    id: ID.omc_chronic_infection_inflammation,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.nhl.37_omc_chronic_infection_inflammation.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.nhl.37_omc_chronic_infection_inflammation.options.pml',
        value: 'pml',
      },
      {
        label: 'questionnaire.questions.nhl.37_omc_chronic_infection_inflammation.options.hepatitis',
        value: 'hepatitis',
      },
      {
        label: 'questionnaire.questions.nhl.37_omc_chronic_infection_inflammation.options.hiv',
        value: 'hiv',
      },
      {
        label: 'questionnaire.questions.nhl.37_omc_chronic_infection_inflammation.options.other',
        value: 'other',
      },
      {
        label: 'questionnaire.questions.nhl.37_omc_chronic_infection_inflammation.options.uncontrolled_infection',
        value: 'uncontrolled_infection',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['chronic_infection_or_inflammation'],
      },
    ],
  },
  {
    q: 'Q58',
    id: ID.omc_gastro_problem,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.nhl.38_omc_gastro_problem.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.nhl.38_omc_gastro_problem.options.gastrointestinal_bleeding',
        value: 'gastrointestinal_bleeding',
      },
      {
        label: 'questionnaire.questions.nhl.38_omc_gastro_problem.options.ulcer',
        value: 'ulcer',
      },
      {
        label: 'questionnaire.questions.nhl.38_omc_gastro_problem.options.crohns',
        value: 'crohns',
      },
      {
        label: 'questionnaire.questions.nhl.38_omc_gastro_problem.options.colitis',
        value: 'colitis',
      },
      {
        label: 'questionnaire.questions.nhl.38_omc_gastro_problem.options.ibs',
        value: 'ibs',
      },
      {
        label: 'questionnaire.questions.nhl.38_omc_gastro_problem.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['gastrointestinal'],
      },
    ],
  },
  {
    q: 'Q59',
    id: ID.omc_lung_disease,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.nhl.39_omc_lung_disease.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.nhl.39_omc_lung_disease.options.interstitial_lung_disease',
        value: 'interstitial_lung_disease',
      },
      {
        label: 'questionnaire.questions.nhl.39_omc_lung_disease.options.pneumonitis”',
        value: 'pneumonitis”',
      },
      {
        label: 'questionnaire.questions.nhl.39_omc_lung_disease.options.dyspnea',
        value: 'dyspnea',
      },
      {
        label: 'questionnaire.questions.nhl.39_omc_lung_disease.options.tuberculosis',
        value: 'tuberculosis',
      },
      {
        label: 'questionnaire.questions.nhl.39_omc_lung_disease.options.dclo_under_50_percent',
        value: 'dclo_under_50_percent',
      },
      {
        label: 'questionnaire.questions.nhl.39_omc_lung_disease.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['lung'],
      },
    ],
  },
  {
    q: 'Q56',
    id: ID.omc_abnormal_blood_results,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.nhl.40_omc_abnormal_blood_results.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.nhl.40_omc_abnormal_blood_results.options.none',
        value: 'none',
        exclusive: true,
      },
      {
        label: 'questionnaire.questions.nhl.40_omc_abnormal_blood_results.options.low_wbc',
        value: 'low_wbc',
      },
      {
        label: 'questionnaire.questions.nhl.40_omc_abnormal_blood_results.options.low_neutrophil_count',
        value: 'low_neutrophil_count',
      },
      {
        label: 'questionnaire.questions.nhl.40_omc_abnormal_blood_results.options.low_hemoglobin',
        value: 'low_hemoglobin',
      },
      {
        label: 'questionnaire.questions.nhl.40_omc_abnormal_blood_results.options.low_platelet_count',
        value: 'low_platelet_count',
      },
      {
        label: 'questionnaire.questions.nhl.40_omc_abnormal_blood_results.options.low_potassium',
        value: 'low_potassium',
      },
      {
        label: 'questionnaire.questions.nhl.40_omc_abnormal_blood_results.options.low_magnesium',
        value: 'low_magnesium',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q47',
    id: ID.other_medications,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.nhl.41_other_medications.title',

    info: {
      title: 'questionnaire.questions.nhl.41_other_medications.info.title',
      content: 'questionnaire.questions.nhl.41_other_medications.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.nhl.41_other_medications.options.cyp3a4_inhibitors',
        value: 'cyp3a4_inhibitors',
      },
      {
        label: 'questionnaire.questions.nhl.41_other_medications.options.cyp3a4_inducers',
        value: 'cyp3a4_inducers',
      },
      {
        label: 'questionnaire.questions.nhl.41_other_medications.options.immunosuppressive_medications',
        value: 'immunosuppressive_medications',
      },
      {
        label: 'questionnaire.questions.nhl.41_other_medications.options.warfarin',
        value: 'warfarin',
      },
      {
        label: 'questionnaire.questions.nhl.41_other_medications.options.anticonvulsants',
        value: 'anticonvulsants',
      },
      {
        label: 'questionnaire.questions.nhl.41_other_medications.options.anti_retroviral_therapy',
        value: 'anti_retroviral_therapy',
      },
      {
        label: 'questionnaire.questions.nhl.41_other_medications.options.proton_pump_inhibitors',
        value: 'proton_pump_inhibitors',
      },
      {
        label: 'questionnaire.questions.nhl.41_other_medications.options.none',
        value: 'none',
        exclusive: true,
      },
    ],
    conditions: [],
  },
  initialDiagnosisDate('Q5'),
  userType,
  dateOfBirth,
  {
    q: 'Q2',
    id: ID.gender,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.ABOUT_ME,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.nhl.0_gender.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.nhl.0_gender.options.female',
        value: 'female',
      },
      {
        label: 'questionnaire.questions.nhl.0_gender.options.male',
        value: 'male',
      },
      {
        label: 'questionnaire.questions.global.gender.options.other',
        value: 'other',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q3',
    id: ID.is_pregnant,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.ABOUT_ME,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.nhl.42_is_pregnant.title',

    info: {
      title: 'questionnaire.questions.nhl.42_is_pregnant.info.title',
      content: 'questionnaire.questions.nhl.42_is_pregnant.info.content',
    },
    options: yesAndNoOptions,
    conditions: [
      {
        ifId: ID.gender,
        type: CONTAIN_ONE_OF,
        ifValue: ['female'],
      },
      {
        type: AGE_LESS_THAN,
        ifValue: 50,
      },
    ],
  },
  {
    q: 'Q4',
    id: ID.is_breastfeeding,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.ABOUT_ME,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.nhl.43_is_breastfeeding.title',

    info: {
      title: 'questionnaire.questions.nhl.43_is_breastfeeding.info.title',
      content: 'questionnaire.questions.nhl.43_is_breastfeeding.info.content',
    },
    options: yesAndNoOptions,
    conditions: [
      {
        ifId: ID.gender,
        type: EQUAL_TO,
        ifValue: 'female',
      },
      {
        type: AGE_LESS_THAN,
        ifValue: 50,
      },
    ],
  },
  race,
  haveInsurance,
  insurance,
  preferredLocation,
  travelLimit,
];

export default Questions;
