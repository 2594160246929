import { PRISTINE, PENDING, FAILURE, SUCCESS } from 'utils/constants/queryStatuses';
import { sponsorReduxActions } from './constants';

const {
  GET_TEMPLATE_DATA_REQUEST,
  GET_TEMPLATE_DATA_SUCCESS,
  GET_TEMPLATE_DATA_FAILURE,
  RESET_TEMPLATE_DATA
} = sponsorReduxActions;

const initialState = {
  status: PRISTINE,
  error: null,
  nctId: null,
  conditions: null,
  data: null
};

export function sponsoredTrialTemplate(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_TEMPLATE_DATA_REQUEST:
      return { ...payload, status: PENDING, error: null };
    case GET_TEMPLATE_DATA_SUCCESS: {
      const { nctId: currentNctId, conditions: currentConditions } = state;
      const { nctId, conditions, result } = payload;

      if (nctId === currentNctId && JSON.stringify(conditions) === JSON.stringify(currentConditions)) {
        return { ...state, status: SUCCESS, data: result };
      }
      return state;
    }
    case GET_TEMPLATE_DATA_FAILURE:
      return { ...state, status: FAILURE, error: payload };
    case RESET_TEMPLATE_DATA:
      return initialState;
    default:
      return state;
  }
}
