import React from 'react';
import './style.css';
import { statesCodes } from 'utils/constants/statesCodes';
import MapIcon from 'new-ui/assets/icons/maps.svg';
import { useTranslation } from "utils/modifiedTranslation";

const getFacilityLabel = (facility, prefix = '') => {
  if (!facility || !facility.facility_name) {
    return null;
  }
  const {
    facility_name, facility_city, facility_state, facility_country,
  } = facility;
  return (
    prefix + [facility_name, facility_city, statesCodes[facility_state], facility_country].filter((i) => i).join(', ')
  );
};

const FacilityLabel = ({item}) => {
  const { t } = useTranslation();
  const closest = getFacilityLabel(item.closest_facility);
  return (
    <div className='facility-label'>
      <img src={MapIcon} alt='icon' />
      <div><span className='title'>{t("list_item.title.nearest_site")}:</span> {closest}</div>
    </div>
  );
};

export default FacilityLabel;