import React, { useEffect, useState } from 'react';
import './style.css';
import { useTranslation } from 'utils/modifiedTranslation';
import Preloader from 'new-ui/Components/Preloader';
import EventAddForm from './AddEventForm';
import EventActions from './EventActions';
import UpcomingEvent from './UpcomingEvent';
import ExpiredEvent from './ExpiredEvent';
import { addEvent, getEvents, deleteEvent } from '../api';
import { COMPONENT } from '..';

const EventList = ({ onSelectType, selectedType }) => {
  const { t } = useTranslation();

  const SUB_COMPONENT = {
    FORM: 'form',
    ACTIONS: 'actions'
  };

  const [eventList, setEventList] = useState([]);
  const [selectedEventType, setSelectedEventType] = useState(selectedType);
  const [visibleComponent, setVisibleComponent] = useState(SUB_COMPONENT.FORM);
  const [showPreloader, setShowPreloader] = useState(false);

  const fetchEvents = async () => {
    setShowPreloader(true);
    const events = await getEvents(500);
    setEventList(events);
    setShowPreloader(false);
  };

  useEffect(()=> {
    fetchEvents();
  },[])

  const handleEventDelete = async (id) => {
    setShowPreloader(true);
    await deleteEvent(500, id);
    await fetchEvents();
  };

  const handleStatusBtnClick = () => {
    onSelectType(COMPONENT.STATUS_CARDS);
  };

  const handleTypeSelect = (type) => {
    setSelectedEventType(type);
    setVisibleComponent(SUB_COMPONENT.FORM);
  };

  const handleEventAdd = async (formData) => {
    setShowPreloader(true);
    await addEvent(500, formData);
    await fetchEvents(500);
    setShowPreloader(false);
    setVisibleComponent(SUB_COMPONENT.ACTIONS);
  };

  const getUpcommingEvents = () => {
    return eventList.filter(event => event.status === 'upcoming');
  };
  const getExpiredEvents = () => {
    return eventList.filter(event => event.status === 'expired');
  };

  const upCommingEvents = getUpcommingEvents();
  const expiredEvents = getExpiredEvents();

  return (
      <div className="event-list">
        {(upCommingEvents.length > 0) && <div className='next-appointments'>
          <h3>{t('events_widget.list.upcoming.title')}</h3>
          <ul>
            {getUpcommingEvents().map((item, index) => (
              <li key={index}>
                <UpcomingEvent item={item} onDelete={handleEventDelete} />
              </li>
            ))}
          </ul>
        </div>}
        
        {(expiredEvents.length > 0) && <div className='last-visits'>
          <h3>{t('events_widget.list.expired.title')}</h3>
          <ul>
            {getExpiredEvents().map((item, index) => (
              <li key={index}>
                <ExpiredEvent item={item} onDelete={()=>{}} onClickStatusBtn={handleStatusBtnClick} />
              </li>
            ))}
          </ul>
        </div>}

        {visibleComponent === SUB_COMPONENT.ACTIONS && <EventActions onTypeSelect={handleTypeSelect} />}
        {visibleComponent === SUB_COMPONENT.FORM && <EventAddForm initialEventType={selectedEventType} onAddEvent={handleEventAdd} />}

        <Preloader isVisible={showPreloader} />
      </div>
  );
};
export default EventList;
