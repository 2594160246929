import configs from 'configs';
import { useEffect, useRef } from 'react';

export const useGoogleOneTapAuth = (handleAuthCallback) => {
  const isInitialized = useRef(null);

  useEffect(() => () => window.google?.accounts?.id.cancel(), []);

  // If user is unauthenticated, google one tap ui is initialized and rendered
  const { google } = window;
  if (google?.accounts?.id && !isInitialized.current) {
    google.accounts.id.initialize({
      client_id: configs.externalAuth.google.appId,
      callback: (response) => {
        handleAuthCallback(response.credential);
      },
      cancel_on_tap_outside: false,
      prompt_parent_id: 'g_id_onload',
    });

    // Showing UI on the screen
    google.accounts.id.prompt();
    isInitialized.current = true;
  }

  return true;
};
