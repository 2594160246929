import { MATCH_FLOW } from 'components/Matches/constants';
import { MatchFlow } from 'domains/Matches/MatchFlow';
import { toggleDialog } from 'components/Dialogs/actions';
import DIALOG_NAMES_CONSTANTS, { THEME } from 'components/Dialogs/constants';

const BaseStorageShowOperations = {
  initParamsInLocalStorage(initialValue = false) {
    if (!Object.hasOwnProperty.call(localStorage, this.storageKey)) {
      localStorage.setItem(this.storageKey, String(initialValue));
    }
  },
  isCouldBeShown() {
    return MatchFlow.is(MATCH_FLOW.LATEST) && localStorage.getItem(this.storageKey) === 'true';
  },
  setShow(value) {
    localStorage.setItem(this.storageKey, value);
  },
};

export const UpdateConditionalProfileDialog = {
  storageKey: 'show_update_medical_condition_dialog',
  ...BaseStorageShowOperations,
};

export const LatestMatchesFlow = {
  updateConditionalProfileDialog: UpdateConditionalProfileDialog,

  onLogin() {
    MatchFlow.setMatchFlow(MATCH_FLOW.LATEST);
    UpdateConditionalProfileDialog.setShow(true);
  },

  onMatchesList(dispatch) {
    UpdateConditionalProfileDialog.initParamsInLocalStorage();

    if (UpdateConditionalProfileDialog.isCouldBeShown()) {
      UpdateConditionalProfileDialog.setShow(false);
      dispatch(toggleDialog(DIALOG_NAMES_CONSTANTS.UPDATE_MEDICAL_CONDITION, THEME.INFO));
    }
  },
  onUpdateMedicalCondition() {
    MatchFlow.setMatchFlow(MATCH_FLOW.BASIC);
    UpdateConditionalProfileDialog.setShow(false);
  },
  onQuestionnairePass() {
    MatchFlow.setMatchFlow(MATCH_FLOW.BASIC);
    UpdateConditionalProfileDialog.setShow(false);
  },
};
