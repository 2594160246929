export const Profile = {
  user_type: 'user_type',
  disease_status: 'disease_status',
  dob: 'dob',
  gender: 'gender',
  race: 'race',
  type_of_bc: 'type_of_bc',
  other_type_bc: 'other_type_bc',
  menopausal_status: 'menopausal_status',
  molecular_subtype: 'molecular_subtype',
  her2_levels: 'her2_levels',
  brca_carrier_status: 'brca_carrier_status',
  patient_stage: 'patient_stage',
  ptnm: 'ptnm',
  ptnm_pt: 'ptnm_pt',
  ptnm_pn: 'ptnm_pn',
  ptnm_pm: 'ptnm_pm',
  disease_measurable: 'disease_measurable',
  tumor_size_mm: 'tumor_size_mm',
  disease_location: 'disease_location',
  type_of_recurrence: 'type_of_recurrence',
  metastasis_location_brain_leptomeningeal: 'metastasis_location_brain_leptomeningeal',
  is_brain_metastasis_active: 'is_brain_metastasis_active',
  has_biopsy: 'has_biopsy',
  disease_resectable: 'disease_resectable',
  had_procedure_for_breast_cancer: 'had_procedure_for_breast_cancer',
  procedures: 'procedures',
  procedures_received: 'procedures_received',
  procedure_date: 'procedure_date',
  procedure_outcome: 'procedure_outcome',
  treatment_for_breast_cancer: 'treatment_for_breast_cancer',
  treatment_for_advanced_metastatic_disease: 'treatment_for_advanced_metastatic_disease',
  received_fam_trastuzumab: 'received_fam_trastuzumab',
  drugs_received: 'drugs_received',
  cycles: 'cycles',
  treatments: 'treatments',
  treatment_admin: 'treatment_admin',
  treatment_end_date: 'treatment_end_date',
  treatment_stop_reason: 'treatment_stop_reason',
  bone_metastasis_complications: 'bone_metastasis_complications',
  current_treatment_systemic_steroids: 'current_treatment_systemic_steroids',
  systemic_steroids_treatment_end_date: 'systemic_steroids_treatment_end_date',
  ecog: 'ecog',
  pd_l1_carrier_status: 'pd_l1_carrier_status',
  pd_l1_expression: 'pd_l1_expression',
  ngs: 'ngs',
  karnofsky: 'karnofsky',
  other_medical_condition: 'other_medical_condition',
  omc_liver: 'omc_liver',
  omc_heart: 'omc_heart',
  omc_stroke_date: 'omc_stroke_date',
  omc_kidney: 'omc_kidney',
  omc_autoimmune: 'omc_autoimmune',
  omc_mental: 'omc_mental',
  omc_allergy: 'omc_allergy',
  omc_chronic_infection_inflammation: 'omc_chronic_infection_inflammation',
  omc_gastro_problem: 'omc_gastro_problem',
  omc_lung_disease: 'omc_lung_disease',
  omc_eye_condition: 'omc_eye_condition',
  omc_abnormal_blood_results: 'omc_abnormal_blood_results',
  other_medications: 'other_medications',
  is_pregnant: 'is_pregnant',
  is_breastfeeding: 'is_breastfeeding',
  have_insurance: 'have_insurance',
  insurances: 'insurances',
};
