import { customPaths } from 'utils/constants/customPaths';
import {
  treatmentCyclesOptions,
  // takingStateOptions,
  yesAndNoOptions,
} from 'components/Questionnaire/constants/globalOptions';
import { QUESTION_VIEW_TYPES } from 'components/Questionnaire/constants/questionViewTypes';
import {
  SINGLE_OPTION_SELECT,
  MULTIPLE_OPTION_SELECT,
  TRIPLE_QUESTION_DROP_DOWN,
  NUMBER_INPUT,
  MONTH_PICKER,
  TREATMENT_HISTORY,
  BIOMARKERS,
  OPTIONS_FROM_SERVER,
} from '../../constants/questionTypes';
import dateType from '../../../../utils/constants/dateTypes';
import { Profile as ID } from './MelanomaProfile_2.enum';
import ConditionTypes from '../../constants/conditions';
import i from '../../constants/icons';
import numberType from '../../../../utils/constants/numberTypes';
import {
  diseaseStatus,
  preferredLocation,
  travelLimit,
  userType,
  race,
  haveInsurance,
  insurance,
  pdL1ExpressionByQNumber,
  initialDiagnosisDate,
} from '../../constants/globalQuestions';
import SECTIONS from '../../constants/questionSections';
import questionOptions from '../../constants/questionOptions';
import GLOBAL_ENUMS from '../../constants/globalEnums';

const { biomarkers: BIOMARKERS_QUESTION_ID } = GLOBAL_ENUMS;
const { OPTIONS_LIST_WITH_SHOW_MORE } = QUESTION_VIEW_TYPES;

const noneOption = questionOptions.NONE;
const {
  EQUAL_TO,
  CONTAIN_ONE_OF,
  AGE_LESS_THAN,
  DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
  IS_SKIPPED,
  NOT_EQUAL_TO_OR_SKIPPED,
  CONTAINS_ONE_OF_OR_SKIPPED,
} = ConditionTypes;

const Questions = [
  diseaseStatus,
  {
    q: 'Q7',
    id: ID.melanoma_subtype,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.melanoma_2.4_melanoma_subtype.title',
    info: {
      title: 'questionnaire.questions.melanoma_2.4_melanoma_subtype.info.title',
      content: 'questionnaire.questions.melanoma_2.4_melanoma_subtype.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma_2.4_melanoma_subtype.options.cutaneous',
        value: 'cutaneous',
      },
      {
        label: 'questionnaire.questions.melanoma_2.4_melanoma_subtype.options.uveal',
        value: 'uveal',
      },
      {
        label: 'questionnaire.questions.melanoma_2.4_melanoma_subtype.options.mucosal',
        value: 'mucosal',
      },
      {
        label: 'questionnaire.questions.melanoma_2.4_melanoma_subtype.options.unknown',
        value: 'unknown',
      },
      {
        label: 'questionnaire.questions.melanoma_2.4_melanoma_subtype.options.other',
        value: 'other',
      },
    ],
    conditions: [],
    isRequired: true,
  },
  {
    q: 'Q6',
    id: ID.current_stage,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.melanoma_2.7_current_stage.title',
    info: {
      title: 'questionnaire.questions.melanoma_2.7_current_stage.info.title',
      content: 'questionnaire.questions.melanoma_2.7_current_stage.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma_2.7_current_stage.options.1',
        value: '1',
      },
      {
        label: 'questionnaire.questions.melanoma_2.7_current_stage.options.2',
        value: '2',
      },
      {
        label: 'questionnaire.questions.melanoma_2.7_current_stage.options.3.1',
        value: '3.1',
      },
      {
        label: 'questionnaire.questions.melanoma_2.7_current_stage.options.3.2',
        value: '3.2',
      },
      {
        label: 'questionnaire.questions.melanoma_2.7_current_stage.options.3.3',
        value: '3.3',
      },
      {
        label: 'questionnaire.questions.melanoma_2.7_current_stage.options.3.4',
        value: '3.4',
      },
      {
        label: 'questionnaire.questions.melanoma_2.7_current_stage.options.4',
        value: '4',
      },
      {
        label: 'questionnaire.questions.melanoma_2.7_current_stage.options.null',
        value: 'null',
      },
    ],
    conditions: [],
    isRequired: true,
  },
  {
    q: 'Q66',
    id: ID.treatment_for_melanoma,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.melanoma_2.19_treatment_for_melanoma.title',

    info: {
      title: 'questionnaire.questions.melanoma_2.19_treatment_for_melanoma.info.title',
      content: 'questionnaire.questions.melanoma_2.19_treatment_for_melanoma.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma_2.19_treatment_for_melanoma.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.melanoma_2.19_treatment_for_melanoma.options.no',
        value: 'no',
      },
    ],
    conditions: [],
    isRequired: true,
  },
  {
    q: 'Q7a',
    id: ID.melanoma_subtype_mucosal,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.melanoma_2.melanoma_subtype_mucosal.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma_2.melanoma_subtype_mucosal.options.vulva',
        value: 'vulva',
      },
      {
        label: 'questionnaire.questions.melanoma_2.melanoma_subtype_mucosal.options.sinus_nasal',
        value: 'sinus_nasal',
      },
      {
        label: 'questionnaire.questions.melanoma_2.melanoma_subtype_mucosal.options.mouth_throat',
        value: 'mouth_throat',
      },
      {
        label: 'questionnaire.questions.melanoma_2.melanoma_subtype_mucosal.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.melanoma_subtype,
        type: EQUAL_TO,
        ifValue: 'mucosal',
      },
    ],
  },
  {
    q: 'Q69',
    id: ID.disease_resectable,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.melanoma_2.disease_resectable.title',
    info: {
      title: 'questionnaire.questions.melanoma_2.disease_resectable.info.title',
      content: 'questionnaire.questions.melanoma_2.disease_resectable.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma_2.disease_resectable.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.melanoma_2.disease_resectable.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.melanoma_2.disease_resectable.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: ID.disease_status,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'remission',
      },
      {
        ifId: ID.current_stage,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['4'],
      },
    ],
  },

  {
    q: 'Q46',
    id: ID.types_of_recurrence,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.melanoma_2.6_types_of_recurrence.title',
    subtitle: 'questionnaire.questions.melanoma_2.6_types_of_recurrence.subtitle',
    info: {
      title: 'questionnaire.questions.melanoma_2.6_types_of_recurrence.info.title',
      content: 'questionnaire.questions.melanoma_2.6_types_of_recurrence.info.content',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.melanoma_2.6_types_of_recurrence.options.local_recurrence',
        value: 'local_recurrence',
      },
      {
        label: 'questionnaire.questions.melanoma_2.6_types_of_recurrence.options.in_transit_recurrence',
        value: 'in_transit_recurrence',
      },
      {
        label: 'questionnaire.questions.melanoma_2.6_types_of_recurrence.options.sentinel_ln_recurrence',
        value: 'sentinel_ln_recurrence',
      },
      {
        label: 'questionnaire.questions.melanoma_2.6_types_of_recurrence.options.distant_recurrence',
        value: 'distant_recurrence',
      },
      {
        label: 'questionnaire.questions.melanoma_2.6_types_of_recurrence.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.disease_status,
        type: EQUAL_TO,
        ifValue: 'recurrence',
      },
    ],
  },

  {
    q: 'Q6a',
    id: ID.triple_question_drop_down,
    type: TRIPLE_QUESTION_DROP_DOWN,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.melanoma_2.8_triple_question_drop_down.title',
    subtitle: 'questionnaire.questions.melanoma_2.8_triple_question_drop_down.subtitle',
    info: {
      title: 'questionnaire.questions.melanoma_2.8_triple_question_drop_down.info.title',
      content: 'questionnaire.questions.melanoma_2.8_triple_question_drop_down.info.content',
    },
    options: [
      {
        title: 'questionnaire.questions.melanoma_2.8_triple_question_drop_down.options.tnm_t.title',
        name: 'tnm_t',
        options: [
          {
            label: '',
            value: '',
          },
          {
            label: 'questionnaire.questions.melanoma_2.8_triple_question_drop_down.options.tnm_t.1',
            value: '1',
          },
          {
            label: 'questionnaire.questions.melanoma_2.8_triple_question_drop_down.options.tnm_t.2',
            value: '2',
          },
          {
            label: 'questionnaire.questions.melanoma_2.8_triple_question_drop_down.options.tnm_t.3',
            value: '3',
          },
          {
            label: 'questionnaire.questions.melanoma_2.8_triple_question_drop_down.options.tnm_t.4a',
            value: '4.1',
          },
          {
            label: 'questionnaire.questions.melanoma_2.8_triple_question_drop_down.options.tnm_t.4b',
            value: '4.2',
          },
        ],
      },
      {
        title: 'questionnaire.questions.melanoma_2.8_triple_question_drop_down.options.tnm_n.title',
        name: 'tnm_n',
        options: [
          {
            label: '',
            value: '',
          },
          {
            label: 'questionnaire.questions.melanoma_2.8_triple_question_drop_down.options.0',
            value: '0',
          },
          {
            label: 'questionnaire.questions.melanoma_2.8_triple_question_drop_down.options.1',
            value: '1',
          },
          {
            label: 'questionnaire.questions.melanoma_2.8_triple_question_drop_down.options.2',
            value: '2',
          },
          {
            label: 'questionnaire.questions.melanoma_2.8_triple_question_drop_down.options.3',
            value: '3',
          },
        ],
      },
      {
        title: 'questionnaire.questions.melanoma_2.8_triple_question_drop_down.options.tnm_m.title',
        name: 'tnm_m',
        options: [
          {
            label: '',
            value: '',
          },
          {
            label: 'questionnaire.questions.melanoma_2.8_triple_question_drop_down.options.0',
            value: '0',
          },
          {
            label: 'questionnaire.questions.melanoma_2.8_triple_question_drop_down.options.1',
            value: '1',
          },
        ],
      },
    ],
    conditions: [
      {
        ifId: ID.current_stage,
        type: IS_SKIPPED,
        ifValue: null,
      },
    ],
  },
  {
    q: 'Q48',
    id: ID.sentinel_lymph_node_status,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.melanoma_2.11_sentinel_lymph_node_status.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma_2.11_sentinel_lymph_node_status.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.melanoma_2.11_sentinel_lymph_node_status.options.removal_planned',
        value: 'removal_planned',
      },
      {
        label: 'questionnaire.questions.melanoma_2.11_sentinel_lymph_node_status.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.melanoma_2.11_sentinel_lymph_node_status.options.already_removed',
        value: 'already_removed',
      },
    ],
    conditions: [
      {
        ifId: ID.current_stage,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: '1',
      },
      {
        ifId: ID.disease_status,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'remission',
      },
    ],
  },
  {
    q: 'Q8',
    id: ID.metastasis_locations,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.melanoma_2.12_metastasis_locations.title',
    subtitle: 'questionnaire.questions.melanoma_2.12_metastasis_locations.subtitle',
    info: {
      title: 'questionnaire.questions.melanoma_2.12_metastasis_locations.info.title',
      content: 'questionnaire.questions.melanoma_2.12_metastasis_locations.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma_2.12_metastasis_locations.options.none',
        value: 'none',
        exclusive: true,
      },
      {
        label: 'questionnaire.questions.melanoma_2.12_metastasis_locations.options.sentinel_lymph_nodes',
        value: 'sentinel_lymph_nodes',
      },
      {
        label: 'questionnaire.questions.melanoma_2.12_metastasis_locations.options.distant_lymph_nodes',
        value: 'distant_lymph_nodes',
      },
      {
        label: 'questionnaire.questions.melanoma_2.12_metastasis_locations.options.abdomen',
        value: 'abdomen',
      },
      {
        label: 'questionnaire.questions.melanoma_2.12_metastasis_locations.options.bone',
        value: 'bone',
      },
      {
        label: 'questionnaire.questions.melanoma_2.12_metastasis_locations.options.brain',
        value: 'brain',
      },
      {
        label: 'questionnaire.questions.melanoma_2.12_metastasis_locations.options.distant_skin',
        value: 'distant_skin',
      },
      {
        label: 'questionnaire.questions.melanoma_2.12_metastasis_locations.options.lung',
        value: 'lung',
      },
      {
        label: 'questionnaire.questions.melanoma_2.12_metastasis_locations.options.liver',
        value: 'liver',
      },
      {
        label: 'questionnaire.questions.melanoma_2.12_metastasis_locations.options.satellite_in_transit',
        value: 'satellite_in_transit',
      },
      {
        label: 'questionnaire.questions.melanoma_2.12_metastasis_locations.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.disease_status,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'remission',
      },
    ],
    isRequired: true,
  },
  {
    q: 'Q9',
    id: ID.metastasis_location_brain_leptomeningeal,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.melanoma_2.13_metastasis_location_brain_leptomeningeal.title',
    subtitle: 'questionnaire.questions.melanoma_2.13_metastasis_location_brain_leptomeningeal.subtitle',
    info: {
      title: 'questionnaire.questions.melanoma_2.13_metastasis_location_brain_leptomeningeal.info.title',
      content: 'questionnaire.questions.melanoma_2.13_metastasis_location_brain_leptomeningeal.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma_2.13_metastasis_location_brain_leptomeningeal.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.melanoma_2.13_metastasis_location_brain_leptomeningeal.options.no',
        value: 'no',
      },
    ],
    conditions: [
      {
        ifId: ID.metastasis_locations,
        type: CONTAIN_ONE_OF,
        ifValue: ['brain'],
      },
    ],
  },
  {
    q: 'Q10',
    id: ID.brain_metastasis_active,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.melanoma_2.14_brain_metastasis_active.title',
    subtitle: 'questionnaire.questions.melanoma_2.14_brain_metastasis_active.subtitle',
    info: {
      title: 'questionnaire.questions.melanoma_2.14_brain_metastasis_active.info.title',
      content: 'questionnaire.questions.melanoma_2.14_brain_metastasis_active.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma_2.14_brain_metastasis_active.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.melanoma_2.14_brain_metastasis_active.options.no',
        value: 'no',
      },
    ],
    conditions: [
      {
        ifId: ID.metastasis_locations,
        type: CONTAIN_ONE_OF,
        ifValue: ['brain'],
      },
    ],
  },
  {
    q: 'Q11',
    id: ID.have_biopsy,
    path: customPaths.biopsy,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.molecular,
    order: 0,
    title: 'questionnaire.questions.melanoma_2.15_have_biopsy.title',

    info: {
      title: 'questionnaire.questions.melanoma_2.15_have_biopsy.info.title',
      content: 'questionnaire.questions.melanoma_2.15_have_biopsy.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma_2.15_have_biopsy.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.melanoma_2.15_have_biopsy.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.melanoma_2.15_have_biopsy.options.biopsy_possible',
        value: 'biopsy_possible',
      },
      {
        label: 'questionnaire.questions.melanoma_2.15_have_biopsy.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q90',
    id: ID.pd_l1_carrier_status,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.molecular,
    order: 0,
    title: 'questionnaire.questions.melanoma_2.pd_l1_carrier_status.title',
    info: {
      title: 'questionnaire.questions.melanoma_2.pd_l1_carrier_status.info.title',
      content: 'questionnaire.questions.melanoma_2.pd_l1_carrier_status.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma_2.pd_l1_carrier_status.options.positive',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.melanoma_2.pd_l1_carrier_status.options.negative',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.melanoma_2.pd_l1_carrier_status.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: ID.have_biopsy,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['no', 'biopsy_possible'],
      },
    ],
  },
  pdL1ExpressionByQNumber('Q90a'),
  {
    q: 'Q80',
    id: ID.ngs,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.molecular,
    order: 0,
    title: 'questionnaire.questions.melanoma_2.42_ngs.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma_2.42_ngs.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.melanoma_2.42_ngs.options.yes_waiting_for_the_results',
        value: 'yes_waiting_for_the_results',
      },
      {
        label: 'questionnaire.questions.melanoma_2.42_ngs.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.melanoma_2.42_ngs.options.no_but_planning',
        value: 'no_but_planning',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q12',
    id: BIOMARKERS_QUESTION_ID,
    type: BIOMARKERS,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.molecular,
    order: 0,
    title: 'questionnaire.questions.melanoma_2.16_biomarkers.title',
    subtitle: 'questionnaire.questions.melanoma_2.16_biomarkers.subtitle',
    placeholder: 'questionnaire.questions.biomarkers.placeholder',
    info: {
      title: '',
      content: '',
    },
    conditions: [
      {
        ifId: ID.have_biopsy,
        type: CONTAINS_ONE_OF_OR_SKIPPED,
        ifValue: ['yes'],
      },
    ],
  },
  {
    q: 'Q67',
    id: ID.braf_mutation,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: null,
    order: 0,
    title: 'questionnaire.questions.melanoma_2.17_braf_mutation.title',

    info: {
      title: 'questionnaire.questions.melanoma_2.17_braf_mutation.info.title',
      content: 'questionnaire.questions.melanoma_2.17_braf_mutation.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma_2.17_braf_mutation.options.wildtype_braf',
        value: 'wildtype_braf',
      },
      {
        label: 'questionnaire.questions.melanoma_2.17_braf_mutation.options.braf_v600e',
        value: 'braf_v600e',
      },
      {
        label: 'questionnaire.questions.melanoma_2.17_braf_mutation.options.braf_v600d',
        value: 'braf_v600d',
      },
      {
        label: 'questionnaire.questions.melanoma_2.17_braf_mutation.options.braf_v600k',
        value: 'braf_v600k',
      },
      {
        label: 'questionnaire.questions.melanoma_2.17_braf_mutation.options.braf_v600r',
        value: 'braf_v600r',
      },
      {
        label: 'questionnaire.questions.melanoma_2.17_braf_mutation.options.braf_v600g',
        value: 'braf_v600g',
      },
      {
        label: 'questionnaire.questions.melanoma_2.17_braf_mutation.options.braf_v600m',
        value: 'braf_v600m',
      },
      {
        label: 'questionnaire.questions.melanoma_2.17_braf_mutation.options.braf_non_v600',
        value: 'braf_non_v600',
      },
    ],
    conditions: [
      {
        ifId: BIOMARKERS_QUESTION_ID,
        type: CONTAIN_ONE_OF,
        ifValue: ['braf'],
      },
    ],
  },
  {
    q: 'Q61',
    id: ID.disease_measurable,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.melanoma_2.disease_measurable.title',
    info: {
      title: 'questionnaire.questions.melanoma_2.disease_measurable.info.title',
      content: 'questionnaire.questions.melanoma_2.disease_measurable.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma_2.disease_measurable.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.melanoma_2.disease_measurable.options.no_but_it_can_be',
        value: 'no_but_it_can_be',
      },
      {
        label: 'questionnaire.questions.melanoma_2.disease_measurable.options.no',
        value: 'no',
      },
    ],
    conditions: [
      {
        ifId: ID.disease_status,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'remission',
      },
    ],
  },
  {
    q: 'Q62',
    id: ID.primary_tumor_size_mm,
    type: NUMBER_INPUT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.melanoma_2.primary_tumor_size_mm.title',
    subtitle: 'questionnaire.questions.melanoma_2.primary_tumor_size_mm.subtitle',
    info: {
      title: 'questionnaire.questions.melanoma_2.primary_tumor_size_mm.info.title',
      content: 'questionnaire.questions.melanoma_2.primary_tumor_size_mm.info.content',
    },
    options: [],
    conditions: [
      {
        ifId: ID.disease_measurable,
        type: EQUAL_TO,
        ifValue: 'yes',
      },
    ],
    numberType: numberType.int,
  },
  {
    q: 'Q63',
    id: ID.metastasis_size_mm,
    type: NUMBER_INPUT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.melanoma_2.metastasis_size_mm.title',
    subtitle: 'questionnaire.questions.melanoma_2.metastasis_size_mm.subtitle',
    info: {
      title: 'questionnaire.questions.melanoma_2.metastasis_size_mm.info.title',
      content: 'questionnaire.questions.melanoma_2.metastasis_size_mm.info.content',
    },
    options: [],
    conditions: [
      {
        ifId: ID.disease_measurable,
        type: EQUAL_TO,
        ifValue: 'yes',
      },
      {
        ifId: ID.current_stage,
        type: EQUAL_TO,
        ifValue: '4',
      },
    ],
    numberType: numberType.int,
  },
  {
    q: 'Q66a',
    id: ID.treatment_for_advanced_metastatic_disease,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.melanoma_2.treatment_for_advanced_metastatic_disease.title',
    info: {
      title: 'questionnaire.questions.melanoma_2.treatment_for_advanced_metastatic_disease.info.title',
      content: 'questionnaire.questions.melanoma_2.treatment_for_advanced_metastatic_disease.info.content',
    },
    options: yesAndNoOptions,
    conditions: [
      {
        ifId: ID.treatment_for_melanoma,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'no',
      },
    ],
  },
  {
    q: 'Q60',
    id: ID.treatments,
    type: TREATMENT_HISTORY,
    section: SECTIONS.TREATMENT,
    bgIcon: null,
    order: 0,
    title: 'questionnaire.questions.melanoma_2.20_treatments.title',
    conditions: [
      {
        ifId: ID.treatment_for_melanoma,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['no'],
      },
    ],
    requiredSubQuestionsId: [ID.drugs_received],
    footer: 'questionnaire.questions.melanoma_2.20_treatments.footer',
    info: {
      title: '',
      content: '',
    },
    submitButtonLabel: 'questionnaire.questions.melanoma_2.20_treatments.button.submit',
    options: [
      {
        iconText: 'A',
        label: 'questionnaire.questions.melanoma_2.20_treatments.options.treatment_name',
        value: null,
      },
      {
        iconText: 'B',
        label: 'questionnaire.questions.melanoma_2.20_treatments.options.course_received',
        value: null,
      },
      {
        iconText: 'C',
        label: 'questionnaire.questions.melanoma_2.20_treatments.options.when_treatment_stopped',
        value: null,
      },
      {
        iconText: 'D',
        label: 'questionnaire.questions.melanoma_2.20_treatments.options.reason_treatment_stopped',
        value: null,
      },
    ],
    subQuestions: [
      {
        q: 'Q60a',
        id: ID.drugs_received,
        type: OPTIONS_FROM_SERVER,
        viewType: OPTIONS_LIST_WITH_SHOW_MORE,
        section: SECTIONS.TREATMENT,
        bgIcon: null,
        order: 0,
        titleIconText: 'A',
        title: 'questionnaire.questions.melanoma_2.20_treatments.sub_questions.drugs_received.title',
        subtitle: 'questionnaire.questions.melanoma_2.20_treatments.sub_questions.drugs_received.subtitle',
        placeholder: 'questionnaire.questions.drugs_received.placeholder',
        info: {
          title: '',
          content: '',
        },
        questionProps: {
          isSearchableByOptionGroupName: true,
        },
      },
      {
        q: 'Q60f',
        id: ID.cycles,
        type: SINGLE_OPTION_SELECT,
        section: SECTIONS.TREATMENT,
        title: 'questionnaire.questions.melanoma_2.20_treatments.sub_questions.cycles.title',
        disableNextOnOptionSelect: false,
        options: [...treatmentCyclesOptions],
      },
      {
        q: 'Q60b',
        id: ID.treatment_admin,
        type: SINGLE_OPTION_SELECT,
        section: SECTIONS.TREATMENT,
        bgIcon: null,
        order: 0,
        titleIconText: 'B',
        title: 'questionnaire.questions.melanoma_2.20_treatments.sub_questions.treatment_admin.title',
        subtitle: '',
        info: {
          title: '',
          content: '',
        },
        disableNextOnOptionSelect: true,
        options: [
          {
            label: 'questionnaire.questions.melanoma_2.20_treatments.sub_questions.treatment_admin.options.neoadjuvant',
            value: 'neoadjuvant',
          },
          {
            label: 'questionnaire.questions.melanoma_2.20_treatments.sub_questions.treatment_admin.options.adjuvant',
            value: 'adjuvant',
          },
          {
            label: 'questionnaire.questions.melanoma_2.20_treatments.sub_questions.treatment_admin.options.systemic',
            value: 'systemic',
          },
          {
            label:
              'questionnaire.questions.melanoma_2.20_treatments.sub_questions.treatment_admin.options.tumor_injection',
            value: 'tumor_injection',
          },
          {
            label:
              'questionnaire.questions.melanoma_2.20_treatments.sub_questions.treatment_admin.options.limb_perfusion',
            value: 'limb_perfusion',
          },
        ],
        conditions: [],
      },
      {
        q: 'Q60c',
        id: ID.treatment_end_date,
        type: MONTH_PICKER,
        section: SECTIONS.TREATMENT,
        bgIcon: null,
        order: 0,
        titleIconText: 'C',
        title: 'questionnaire.questions.melanoma_2.20_treatments.sub_questions.treatment_end_date.title',
        subtitle: '',
        info: {
          title: '',
          content: '',
        },
        options: [],
        conditions: [],
        dateType: {
          type: dateType.stopTreatment,
        },
      },
      {
        q: 'Q60d',
        id: ID.treatment_stop_reason,
        type: SINGLE_OPTION_SELECT,
        section: SECTIONS.TREATMENT,
        bgIcon: null,
        order: 0,
        titleIconText: 'D',
        title: 'questionnaire.questions.melanoma_2.20_treatments.sub_questions.treatment_stop_reason.title',
        subtitle: '',
        info: {
          title: '',
          content: '',
        },
        disableNextOnOptionSelect: false,
        options: [
          {
            label:
              'questionnaire.questions.melanoma_2.20_treatments.sub_questions.treatment_stop_reason.options.treatment_ongoing',
            value: 'treatment_ongoing',
          },
          {
            label:
              'questionnaire.questions.melanoma_2.20_treatments.sub_questions.treatment_stop_reason.options.end_of_protocol',
            value: 'end_of_protocol',
          },
          {
            label:
              'questionnaire.questions.melanoma_2.20_treatments.sub_questions.treatment_stop_reason.options.progression',
            value: 'progression',
          },
          {
            label:
              'questionnaire.questions.melanoma_2.20_treatments.sub_questions.treatment_stop_reason.options.toxicity',
            value: 'toxicity',
          },
          {
            label:
              'questionnaire.questions.melanoma_2.20_treatments.sub_questions.treatment_stop_reason.options.remission',
            value: 'remission',
          },
          {
            label:
              'questionnaire.questions.melanoma_2.20_treatments.sub_questions.treatment_stop_reason.options.no_response',
            value: 'no_response',
          },
          {
            label:
              'questionnaire.questions.melanoma_2.20_treatments.sub_questions.treatment_stop_reason.options.relapse_loss_of_response',
            value: 'relapse_loss_of_response',
          },
          {
            label: 'questionnaire.questions.melanoma_2.20_treatments.sub_questions.treatment_stop_reason.options.other_medical_conditions',
            value: 'other_medical_conditions',
          },
          {
            label: 'questionnaire.questions.melanoma_2.20_treatments.sub_questions.treatment_stop_reason.options.dosing_issues',
            value: 'dosing_issues',
          },
          {
            label: 'questionnaire.questions.melanoma_2.20_treatments.sub_questions.treatment_stop_reason.options.scheduling',
            value: 'scheduling',
          },
          {
            label: 'questionnaire.questions.melanoma_2.20_treatments.sub_questions.treatment_stop_reason.options.travel_limitation',
            value: 'travel_limitation',
          },
          {
            label: 'questionnaire.questions.melanoma_2.20_treatments.sub_questions.treatment_stop_reason.options.financial_reasons_or_insurance',
            value: 'financial_reasons_or_insurance',
          },
          {
            label: 'questionnaire.questions.melanoma_2.20_treatments.sub_questions.treatment_stop_reason.options.other',
            value: 'other',
          },
        ],
        conditions: [],
      },
    ],
    // shouldAutoOpenSubQuestions: [{
    //   ifId: ID.treatment_for_melanoma,
    //   type: EQUAL_TO,
    //   ifValue: 'yes',
    // }],
    // title: '',
    // info: {
    //   title: '',
    //   content: '',
    // },
    // footer: '',
    // type: TREATMENT_HISTORY,
    // section: SECTIONS.TREATMENT,
    // bgIcon: null,
    // order: 0,
    // subQuestionsIds: [
    //   ID.treatment_for_advanced_metastatic_disease,
    //   ID.drugs_received,
    //   ID.treatment_stop_reason,
    //   ID.treatment_admin,
    // ],
    stageIds: [ID.current_stage],
    // options: [],
    // subQuestionsOptions: {
    //   treatment_stop_reason: [
    //     {
    //       label:
    //         'questionnaire.questions.treatment_stop_reason.options.no_response',
    //       value: 'no_response',
    //     },
    //     {
    //       label:
    //         'questionnaire.questions.treatment_stop_reason.options.end_of_protocol',
    //       value: 'end_of_protocol',
    //     },
    //     {
    //       label:
    //         'questionnaire.questions.treatment_stop_reason.options.toxicity',
    //       value: 'toxicity',
    //     },
    //     {
    //       label:
    //         'questionnaire.questions.treatment_stop_reason.options.progression',
    //       value: 'progression',
    //     },
    //     {
    //       label:
    //         'questionnaire.questions.treatment_stop_reason.options.remission',
    //       value: 'remission',
    //     },
    //     {
    //       label:
    //         'questionnaire.questions.treatment_stop_reason.options.loss_of_response',
    //       value: 'relapse_loss_of_response',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.other',
    //       value: 'other',
    //     },
    //   ],
    //   taking_state: takingStateOptions,
    //   treatment_admin: [
    //     {
    //       label: 'questionnaire.questions.melanoma_2.20_treatments.sub_questions.treatment_admin.options.neoadjuvant',
    //       value: 'neoadjuvant',
    //     },
    //     {
    //       label: 'questionnaire.questions.melanoma_2.20_treatments.sub_questions.treatment_admin.options.adjuvant',
    //       value: 'adjuvant',
    //     },
    //     {
    //       label: 'questionnaire.questions.melanoma_2.20_treatments.sub_questions.treatment_admin.options.systemic',
    //       value: 'systemic',
    //     },
    //     {
    //       label:
    //         'questionnaire.questions.melanoma_2.20_treatments.sub_questions.treatment_admin.options.tumor_injection',
    //       value: 'tumor_injection',
    //     },
    //     {
    //       label:
    //         'questionnaire.questions.melanoma_2.20_treatments.sub_questions.treatment_admin.options.limb_perfusion',
    //       value: 'limb_perfusion',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatments.options.i_dont_know',
    //       value: 'i_dont_know',
    //     },
    //   ],
    // },
  },
  {
    q: 'Q26',
    id: ID.radiation_treatment,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.melanoma_2.21_radiation_treatment.title',

    info: {
      title: 'questionnaire.questions.melanoma_2.21_radiation_treatment.info.title',
      content: 'questionnaire.questions.melanoma_2.21_radiation_treatment.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma_2.21_radiation_treatment.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.melanoma_2.21_radiation_treatment.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.melanoma_2.21_radiation_treatment.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: ID.disease_status,
        type: EQUAL_TO,
        ifValue: 'remission',
      },
    ],
  },
  {
    q: 'Q27',
    id: ID.radiation_end_date,
    type: MONTH_PICKER,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.melanoma_2.22_radiation_end_date.title',
    subtitle: 'questionnaire.questions.melanoma_2.22_radiation_end_date.subtitle',
    info: {
      title: '',
      content: '',
    },
    conditions: [
      {
        ifId: ID.radiation_treatment,
        type: CONTAIN_ONE_OF,
        ifValue: ['yes'],
      },
    ],
    dateType: {
      type: dateType.stopTreatment,
    },
  },
  {
    q: 'Q65',
    id: ID.current_treatment_systemic_steroids,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.melanoma_2.23_current_treatment_systemic_steroids.title',
    subtitle: 'questionnaire.questions.melanoma_2.23_current_treatment_systemic_steroids.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma_2.23_current_treatment_systemic_steroids.options.none',
        value: 'none',
      },
      {
        label: 'questionnaire.questions.melanoma_2.23_current_treatment_systemic_steroids.options.under_10_mg_daily',
        value: 'under_10_mg_daily',
      },
      {
        label: 'questionnaire.questions.melanoma_2.23_current_treatment_systemic_steroids.options.over_10_mg_daily',
        value: 'over_10_mg_daily',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q65a',
    id: ID.systemic_steroids_treatment_end_date,
    type: MONTH_PICKER,
    section: SECTIONS.TREATMENT,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.melanoma_2.24_systemic_steroids_treatment_end_date.title',

    info: {
      title: '',
      content: '',
    },
    conditions: [
      {
        ifId: ID.current_treatment_systemic_steroids,
        type: CONTAIN_ONE_OF,
        ifValue: ['under_10_mg_daily', 'over_10_mg_daily'],
      },
    ],
    dateType: {
      type: dateType.stopTreatment,
    },
  },
  {
    q: 'Q32',
    id: ID.ecog,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.melanoma_2.25_ecog.title',

    info: {
      title: 'questionnaire.questions.melanoma_2.25_ecog.info.title',
      content: 'questionnaire.questions.melanoma_2.25_ecog.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma_2.25_ecog.options.0',
        value: '0',
      },
      {
        label: 'questionnaire.questions.melanoma_2.25_ecog.options.1',
        value: '1',
      },
      {
        label: 'questionnaire.questions.melanoma_2.25_ecog.options.2',
        value: '2',
      },
      {
        label: 'questionnaire.questions.melanoma_2.25_ecog.options.3',
        value: '3',
      },
      {
        label: 'questionnaire.questions.melanoma_2.25_ecog.options.4',
        value: '4',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q33',
    id: ID.other_medical_condition,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.melanoma_2.26_other_medical_condition.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma_2.26_other_medical_condition.options.none',
        value: 'none',
        exclusive: true,
      },
      {
        label: 'questionnaire.questions.melanoma_2.26_other_medical_condition.options.liver',
        value: 'liver',
      },
      {
        label: 'questionnaire.questions.melanoma_2.26_other_medical_condition.options.heart',
        value: 'heart',
      },
      {
        label: 'questionnaire.questions.melanoma_2.26_other_medical_condition.options.kidney',
        value: 'kidney',
      },
      {
        label: 'questionnaire.questions.melanoma_2.26_other_medical_condition.options.autoimmune',
        value: 'autoimmune',
      },
      {
        label: 'questionnaire.questions.melanoma_2.26_other_medical_condition.options.immunodeficiency',
        value: 'immunodeficiency',
      },
      {
        label: 'questionnaire.questions.melanoma_2.26_other_medical_condition.options.mental',
        value: 'mental',
      },
      {
        label: 'questionnaire.questions.melanoma_2.26_other_medical_condition.options.allergies',
        value: 'allergies',
      },
      {
        label:
          'questionnaire.questions.melanoma_2.26_other_medical_condition.options.chronic_infection_or_inflammation',
        value: 'chronic_infection_or_inflammation',
      },
      {
        label: 'questionnaire.questions.melanoma_2.26_other_medical_condition.options.gastrointestinal',
        value: 'gastrointestinal',
      },
      {
        label: 'questionnaire.questions.melanoma_2.26_other_medical_condition.options.other_cancer_type',
        value: 'other_cancer_type',
      },
      {
        label: 'questionnaire.questions.melanoma_2.26_other_medical_condition.options.anemia',
        value: 'anemia',
      },
      {
        label: 'questionnaire.questions.melanoma_2.26_other_medical_condition.options.thyroid',
        value: 'thyroid',
      },
      {
        label: 'questionnaire.questions.melanoma_2.26_other_medical_condition.options.hypertension',
        value: 'hypertension',
      },
      {
        label: 'questionnaire.questions.melanoma_2.26_other_medical_condition.options.epilepsy',
        value: 'epilepsy',
      },
      {
        label: 'questionnaire.questions.melanoma_2.26_other_medical_condition.options.diabetes',
        value: 'diabetes',
      },
      {
        label: 'questionnaire.questions.melanoma_2.26_other_medical_condition.options.organ_transplant',
        value: 'organ_transplant',
      },
      {
        label: 'questionnaire.questions.melanoma_2.26_other_medical_condition.options.extreme_obesity',
        value: 'extreme_obesity',
      },
      {
        label: 'questionnaire.questions.melanoma_2.26_other_medical_condition.options.stroke',
        value: 'stroke',
      },
      {
        label: 'questionnaire.questions.melanoma_2.26_other_medical_condition.options.lung',
        value: 'lung',
      },
      {
        label: 'questionnaire.questions.melanoma_2.26_other_medical_condition.options.neuropathy',
        value: 'neuropathy',
      },
      {
        label: 'questionnaire.questions.melanoma_2.26_other_medical_condition.options.ocular_pathology',
        value: 'ocular_pathology',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q35',
    id: ID.omc_liver,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.melanoma_2.27_omc_liver.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma_2.27_omc_liver.options.elevated_alt_ast',
        value: 'elevated_alt_ast',
      },
      {
        label: 'questionnaire.questions.melanoma_2.27_omc_liver.options.elevated_bilirubin',
        value: 'elevated_bilirubin',
      },
      {
        label: 'questionnaire.questions.melanoma_2.27_omc_liver.options.cirrhosis',
        value: 'cirrhosis',
      },
      {
        label: 'questionnaire.questions.melanoma_2.27_omc_liver.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['liver'],
      },
    ],
  },
  {
    q: 'Q36',
    id: ID.omc_heart,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.melanoma_2.28_omc_heart.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma_2.28_omc_heart.options.class_at_least_nyha_ii',
        value: 'class_at_least_nyha_ii',
      },
      {
        label: 'questionnaire.questions.melanoma_2.28_omc_heart.options.condition_at_least_grade_2',
        value: 'condition_at_least_grade_2',
      },
      {
        label: 'questionnaire.questions.melanoma_2.28_omc_heart.options.cardiac_arrhythmia',
        value: 'cardiac_arrhythmia',
      },
      {
        label: 'questionnaire.questions.melanoma_2.28_omc_heart.options.heart_failure',
        value: 'heart_failure',
      },
      {
        label: 'questionnaire.questions.melanoma_2.28_omc_heart.options.lvef_under_50_percent',
        value: 'lvef_under_50_percent',
      },
      {
        label: 'questionnaire.questions.melanoma_2.28_omc_heart.options.qtcf_abnormal',
        value: 'qtcf_abnormal',
      },
      {
        label: 'questionnaire.questions.melanoma_2.28_omc_heart.options.angina',
        value: 'angina',
      },
      {
        label: 'questionnaire.questions.melanoma_2.28_omc_heart.options.myocardial_infarction',
        value: 'myocardial_infarction',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['heart'],
      },
    ],
  },
  {
    q: 'Q37',
    id: ID.omc_stroke_date,
    type: MONTH_PICKER,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.melanoma_2.29_omc_stroke_date.title',

    info: {
      title: '',
      content: '',
    },
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['stroke'],
      },
    ],
    dateType: {
      type: dateType.stopTreatment,
    },
  },
  {
    q: 'Q38',
    id: ID.omc_kidney,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.melanoma_2.30_omc_kidney.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma_2.30_omc_kidney.options.low_glomerular_filtration_rate',
        value: 'low_glomerular_filtration_rate',
      },
      {
        label: 'questionnaire.questions.melanoma_2.30_omc_kidney.options.dialysis',
        value: 'dialysis',
      },
      {
        label: 'questionnaire.questions.melanoma_2.30_omc_kidney.options.kidney_stones',
        value: 'kidney_stones',
      },
      {
        label: 'questionnaire.questions.melanoma_2.30_omc_kidney.options.creatinine_high',
        value: 'creatinine_high',
      },
      {
        label: 'questionnaire.questions.melanoma_2.30_omc_kidney.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['kidney'],
      },
    ],
  },
  {
    q: 'Q39',
    id: ID.omc_autoimmune,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.melanoma_2.31_omc_autoimmune.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma_2.31_omc_autoimmune.options.congenital_or_acquired_immunosuppression',
        value: 'congenital_or_acquired_immunosuppression',
      },
      {
        label: 'questionnaire.questions.melanoma_2.31_omc_autoimmune.options.hepatitis',
        value: 'hepatitis',
      },
      {
        label: 'questionnaire.questions.melanoma_2.31_omc_autoimmune.options.granulomatous_disease',
        value: 'granulomatous_disease',
      },
      {
        label: 'questionnaire.questions.melanoma_2.31_omc_autoimmune.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['autoimmune'],
      },
    ],
  },
  {
    q: 'Q40',
    id: ID.omc_mental,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.melanoma_2.32_omc_mental.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma_2.32_omc_mental.options.dementia',
        value: 'dementia',
      },
      {
        label: 'questionnaire.questions.melanoma_2.32_omc_mental.options.neuropsychyatric',
        value: 'neuropsychyatric',
      },
      {
        label: 'questionnaire.questions.melanoma_2.32_omc_mental.options.depression',
        value: 'depression',
      },
      {
        label: 'questionnaire.questions.melanoma_2.32_omc_mental.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['mental'],
      },
    ],
  },
  {
    q: 'Q41',
    id: ID.omc_allergy,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.melanoma_2.33_omc_allergy.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.melanoma_2.33_omc_allergy.options.interferon',
        value: 'interferon',
      },
      {
        label: 'questionnaire.questions.melanoma_2.33_omc_allergy.options.metformin',
        value: 'metformin',
      },
      {
        label: 'questionnaire.questions.melanoma_2.33_omc_allergy.options.vitd',
        value: 'vitd',
      },
      {
        label: 'questionnaire.questions.melanoma_2.33_omc_allergy.options.penicillin',
        value: 'penicillin',
      },
      {
        label: 'questionnaire.questions.melanoma_2.33_omc_allergy.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['allergies'],
      },
    ],
  },
  {
    q: 'Q42',
    id: ID.omc_chronic_infection_inflammation,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.melanoma_2.34_omc_chronic_infection_inflammation.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma_2.34_omc_chronic_infection_inflammation.options.hepatitis',
        value: 'hepatitis',
      },
      {
        label: 'questionnaire.questions.melanoma_2.34_omc_chronic_infection_inflammation.options.hiv',
        value: 'hiv',
      },
      {
        label: 'questionnaire.questions.melanoma_2.34_omc_chronic_infection_inflammation.options.other',
        value: 'other',
      },
      {
        label:
          'questionnaire.questions.melanoma_2.34_omc_chronic_infection_inflammation.options.uncontrolled_infection',
        value: 'uncontrolled_infection',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['chronic_infection_or_inflammation'],
      },
    ],
  },
  {
    q: 'Q56',
    id: ID.omc_gastro_problem,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.melanoma_2.35_omc_gastro_problem.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma_2.35_omc_gastro_problem.options.gastrointestinal_bleeding',
        value: 'gastrointestinal_bleeding',
      },
      {
        label: 'questionnaire.questions.melanoma_2.35_omc_gastro_problem.options.crohns',
        value: 'crohns',
      },
      {
        label: 'questionnaire.questions.melanoma_2.35_omc_gastro_problem.options.colitis',
        value: 'colitis',
      },
      {
        label: 'questionnaire.questions.melanoma_2.35_omc_gastro_problem.options.ibs',
        value: 'ibs',
      },
      {
        label: 'questionnaire.questions.melanoma_2.35_omc_gastro_problem.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['gastrointestinal'],
      },
    ],
  },
  {
    q: 'Q57',
    id: ID.omc_lung_disease,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.melanoma_2.36_omc_lung_disease.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma_2.36_omc_lung_disease.options.interstitial_lung_disease',
        value: 'interstitial_lung_disease',
      },
      {
        label: 'questionnaire.questions.melanoma_2.36_omc_lung_disease.options.pneumonitis',
        value: 'pneumonitis',
      },
      {
        label: 'questionnaire.questions.melanoma_2.36_omc_lung_disease.options.dyspnea',
        value: 'dyspnea',
      },
      {
        label: 'questionnaire.questions.melanoma_2.36_omc_lung_disease.options.tuberculosis',
        value: 'tuberculosis',
      },
      {
        label: 'questionnaire.questions.melanoma_2.36_omc_lung_disease.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['lung'],
      },
    ],
  },
  {
    q: 'Q68',
    id: ID.omc_retinal_disease,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.melanoma_2.37_omc_retinal_disease.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma_2.37_omc_retinal_disease.options.rvo',
        value: 'rvo',
      },
      {
        label: 'questionnaire.questions.melanoma_2.37_omc_retinal_disease.options.rped',
        value: 'rped',
      },
      {
        label: 'questionnaire.questions.melanoma_2.37_omc_retinal_disease.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['ocular_pathology'],
      },
    ],
  },
  {
    q: 'Q58',
    id: ID.omc_abnormal_blood_results,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.melanoma_2.38_omc_abnormal_blood_results.title',
    subtitle: 'questionnaire.questions.melanoma_2.38_omc_abnormal_blood_results.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma_2.38_omc_abnormal_blood_results.options.none',
        value: 'none',
        exclusive: true,
      },
      {
        label: 'questionnaire.questions.melanoma_2.38_omc_abnormal_blood_results.options.low_wbc',
        value: 'low_wbc',
      },
      {
        label: 'questionnaire.questions.melanoma_2.38_omc_abnormal_blood_results.options.low_hemoglobin',
        value: 'low_hemoglobin',
      },
      {
        label: 'questionnaire.questions.melanoma_2.38_omc_abnormal_blood_results.options.low_neutrophil_count',
        value: 'low_neutrophil_count',
      },
      {
        label: 'questionnaire.questions.melanoma_2.38_omc_abnormal_blood_results.options.low_platelet_count',
        value: 'low_platelet_count',
      },
      {
        label: 'questionnaire.questions.melanoma_2.38_omc_abnormal_blood_results.options.low_potassium',
        value: 'low_potassium',
      },
      {
        label: 'questionnaire.questions.melanoma_2.38_omc_abnormal_blood_results.options.low_magnesium',
        value: 'low_magnesium',
      },
      {
        label: 'questionnaire.questions.melanoma_2.38_omc_abnormal_blood_results.options.other',
        value: 'other',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q59',
    id: ID.other_medications,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.melanoma_2.39_other_medications.title',
    subtitle: 'questionnaire.questions.melanoma_2.39_other_medications.subtitle',
    info: {
      title: 'questionnaire.questions.melanoma_2.39_other_medications.info.title',
      content: 'questionnaire.questions.melanoma_2.39_other_medications.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma_2.39_other_medications.options.cyp3a4_inhibitors',
        value: 'cyp3a4_inhibitors',
      },
      {
        label: 'questionnaire.questions.melanoma_2.39_other_medications.options.cyp3a4_inducers',
        value: 'cyp3a4_inducers',
      },
      {
        label: 'questionnaire.questions.melanoma_2.39_other_medications.options.immunosuppressive_medications',
        value: 'immunosuppressive_medications',
      },
      {
        label: 'questionnaire.questions.melanoma_2.39_other_medications.options.warfarin',
        value: 'warfarin',
      },
      {
        label: 'questionnaire.questions.melanoma_2.39_other_medications.options.anticonvulsants',
        value: 'anticonvulsants',
      },
      {
        label: 'questionnaire.questions.melanoma_2.39_other_medications.options.anti_retroviral_therapy',
        value: 'anti_retroviral_therapy',
      },
      {
        label: 'questionnaire.questions.melanoma_2.39_other_medications.options.none',
        value: 'none',
        exclusive: true,
      },
    ],
    conditions: [],
  },
  initialDiagnosisDate('Q5'),
  userType,
  {
    q: 'Q1',
    id: ID.dob,
    type: MONTH_PICKER,
    section: SECTIONS.ABOUT_ME,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.melanoma_2.1_dob.title',
    info: {
      title: '',
      content: '',
    },
    conditions: [],
    dateType: {
      type: dateType.dob,
    },
  },
  {
    q: 'Q2',
    id: ID.gender,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.ABOUT_ME,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.melanoma_2.2_gender.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma_2.2_gender.options.female',
        value: 'female',
      },
      {
        label: 'questionnaire.questions.melanoma_2.2_gender.options.male',
        value: 'male',
      },
      {
        label: 'questionnaire.questions.global.gender.options.other',
        value: 'other',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q3',
    id: ID.is_pregnant,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.ABOUT_ME,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.melanoma_2.40_is_pregnant.title',

    info: {
      title: 'questionnaire.questions.melanoma_2.40_is_pregnant.info.title',
      content: 'questionnaire.questions.melanoma_2.40_is_pregnant.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma_2.40_is_pregnant.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.melanoma_2.40_is_pregnant.options.no',
        value: 'no',
      },
    ],
    conditions: [
      {
        ifId: ID.gender,
        type: CONTAIN_ONE_OF,
        ifValue: ['female'],
      },
      {
        type: AGE_LESS_THAN,
        ifValue: 50,
      },
    ],
  },
  {
    q: 'Q4',
    id: ID.is_breastfeeding,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.ABOUT_ME,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.melanoma_2.41_is_breastfeeding.title',

    info: {
      title: 'questionnaire.questions.melanoma_2.41_is_breastfeeding.info.title',
      content: 'questionnaire.questions.melanoma_2.41_is_breastfeeding.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma_2.41_is_breastfeeding.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.melanoma_2.41_is_breastfeeding.options.no',
        value: 'no',
      },
    ],
    conditions: [
      {
        ifId: ID.gender,
        type: CONTAIN_ONE_OF,
        ifValue: ['female'],
      },
      {
        type: AGE_LESS_THAN,
        ifValue: 50,
      },
    ],
  },
  race,
  haveInsurance,
  insurance,
  preferredLocation,
  travelLimit,
];

export default Questions;
