import React from 'react';
import { useTranslation } from 'utils/modifiedTranslation';

import { downloadTrialsPdf } from 'components/ShareDoctor/functions';
import PropTypes from 'prop-types';

import app from 'new-ui/app';
import { POPUP_TYPES } from 'new-ui/constants';
import IconLine from '../../Components/IconLine';

import './ResultActions.css';

export const download = (
  user = app.user,
  trials = app.trials,
  lang = 'en',
  cb,
  pdfFor,
) => {
  const profileId = user.personal?.profile_id;
  const userWixId = user.personal?.user_wix_id;
  const personalCondition = user.personal?.condition;
  const providerCode = user.personal?.providerCode;
  const country = user.info?.country?.name;
  const countryState = user.info?.country?.state;

  downloadTrialsPdf(
    trials,
    {
      profileId,
      personalCondition,
      userWixId,
      country,
      countryState,
      lang,
      providerCode,
    },
    () => {
      console.log('dispatch');
    },
    cb,
    pdfFor,
  );
};

const ResultActions = (props) => {
  const { t } = useTranslation();

  const { trials } = props;
  const { user, lang } = app;

  // const print = () => {
  //   const { print } = window;
  //   print();
  // };

  const shareWithDoctor = () => {
    app.setPopup(POPUP_TYPES.SHARE_WITH_DOCTOR);
  };

  return (
    <div className="result-actions box">
      {/* <IconLine icon="print" title="Print your trial list" onClick={print} />/ */}
      <IconLine
        icon="download"
        title={t('action.download_your_trial_list')}
        last
        onClick={() => {
          download(user, trials, lang);
        }}
      />
      <IconLine icon="email" title={t('action.share_with_doctor_button')} last onClick={shareWithDoctor} className="share_with_doctor" />
    </div>
  );
};

ResultActions.propTypes = {
  trials: PropTypes.array,
};

export default ResultActions;
