import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'utils/modifiedTranslation';
import app from 'new-ui/app';
import Form from 'new-ui/Components/Form';
import {
  PopupTitle,
  PopupContent,
  PopupButton,
} from 'new-ui/Components/Popup';
import { authenticationActions } from 'components/Authentication/actions';
import globalEnums from 'components/Questionnaire/constants/globalEnums';
import './HasAnythingChanged.css';
import { getQuestionnaireRoute } from 'functions/getQuestionnaireRoute';

const HasAnythingChanged = (props) => {
  const dispatch = useDispatch();
  const { user } = app;
  const { closePopup } = props;
  const history = useHistory();
  const { t } = useTranslation();

  const form = {
    inputs: {
      HasAnythingChanged: {
        type: 'radio',
        options: [
          {
            value: 'NO',
            title: t('med_info_uptodate.med_info_changed_popup_opt_one'),
          },
          {
            value: 'YES',
            title: t('med_info_uptodate.med_info_changed_popup_opt_two'),
          },
        ],
      },
    },
    data: {
      HasAnythingChanged: '',
    },
  };

  const save = async () => {
    if (form.data.HasAnythingChanged === 'YES') {
      history.push(getQuestionnaireRoute({ questionId: globalEnums.disease_status }));
      return;
    }
    if (form.data.HasAnythingChanged === 'NO') {
      // isAnythingChangeUpdate is required variable
      // to update profile_updated_at column
      user.personal.isAnythingChangeUpdate = true;
      dispatch(authenticationActions.updateUser(user, true, app.user));
      app.setShowPreloader(true);
      await app.update({ rematch: true });
      app.setShowPreloader(false);
      app.renderPage();
    }
    closePopup();
  };

  return (
    <div>
      <PopupTitle title={t('med_info_uptodate.med_info_changed_popup_lbl')} />
      {/* <PopupContent>
        Tell us a bit about your current situation, so  we can guide you to the next best steps.
      </PopupContent> */}
      <PopupContent>
        <Form inputs={form.inputs} data={form.data} />
      </PopupContent>
      <PopupButton title={t('med_info_uptodate.med_info_changed_popup_btn')} action={save} />
    </div>
  );
};

HasAnythingChanged.propTypes = {
  closePopup: PropTypes.func,
};

export default HasAnythingChanged;
