import React, { useRef, useEffect } from 'react';
import moment from 'moment';

import './Datepicker.css';

import leftArrow from 'new-ui/assets/icons/left.svg';
import rightArrow from 'new-ui/assets/icons/right.svg';
import datepickerIcon from 'new-ui/assets/icons/datepicker.svg';

import PropTypes from 'prop-types';

export const DATEPICKER_MODES = {
  DATE: 'DATE',
  MONTH: 'MONTH',
  YEAR: 'YEAR',
};

export const DATEPICKER_DIRECTION = {
  LTR: 'LTR',
  RTL: 'RTL',
};

export const DATEPICKER_WEEKSTART = {
  SUNDAY: 0,
  MONDAY: 1,
};

const DAY_NAMES_SHORT = [
  'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun',
];

const MONTH_NAMES_SHORT = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const $el = (className, type = 'div') => {
  const $e = document.createElement(type);
  $e.className = className;
  $e.datepickerElement = true;
  return $e;
};

function getFirstDayOfMonth(year, month) {
  return new Date(year, month, 1);
}

// eslint-disable-next-line no-extend-native
Date.prototype.isSameDateAs = function (pDate) {
  return (
    this.getFullYear() === pDate.getFullYear()
    && this.getMonth() === pDate.getMonth()
    && this.getDate() === pDate.getDate()
  );
};

function isValidDate(d) {
  // eslint-disable-next-line no-restricted-globals
  return d instanceof Date && !isNaN(d);
}

class DatePicker {
  constructor(settings = {}) {
    const DEFAULT = {
      direction: DATEPICKER_DIRECTION.LTR,
      weekStart: DATEPICKER_WEEKSTART.SUNDAY,
      dayNames: DAY_NAMES_SHORT,
      monthNames: MONTH_NAMES_SHORT,
      minDate: null, // new Date(),
      maxDate: null,
      formatter: (date = new Date()) => moment(date).format('MM/DD/YYYY'),
      valueFormatter: (date = new Date()) => date,
      hideOnSelect: false,
      floatMode: false,
      placeholder: 'MM/DD/YYYY', // default display when no value
      monthPickerMode: false,
    };

    this.settings = Object.assign(DEFAULT, settings);

    this.$datepicker = settings.$datepicker;
    this.$value = settings.$value;
    this.$chooser = settings.$chooser;
    this.$icon = settings.$icon;

    this.mode = this.settings.monthPickerMode ? DATEPICKER_MODES.YEAR : DATEPICKER_MODES.DATE;

    this.settings.weekEnd = this.settings.weekStart - 1;
    if (this.settings.weekEnd === -1) this.settings.weekEnd = 6;

    this.init();
  }

  init() {
    try {
      this.date = isValidDate(new Date(this.settings.value)) ? new Date(this.settings.value) : new Date();
    } catch (e) {
      this.date = new Date();
    }
    this.resetPages();
    this.$value.onclick = this.inputClick.bind(this);
    this.$icon.onclick = this.inputClick.bind(this);
    // this.show();
    this.render();

    // eslint-disable-next-line
    document.addEventListener('click', function(ev){
      if (!this.$datepicker.classList.contains('datepicker-visible')) return;
      if (ev.target.datepickerElement) return;
      this.hide();
    }.bind(this));
  }

  inputClick(e) {
    e.stopPropagation();
    if (this.$datepicker.classList.contains('datepicker-visible')) {
      this.hide();
    } else this.show();
  }

  show() {
    this.$datepicker.classList.add('datepicker-visible');
    if (this.settings.floatMode) {
      this.floatInterval = setInterval(() => {
        const bb = this.$value.getBoundingClientRect();
        const screenHeight = document.body.clientHeight;
        const chooserHeight = this.$chooser.getBoundingClientRect().height;
        let top = bb.y + bb.height;
        if (top + chooserHeight > screenHeight) {
          top = bb.y - chooserHeight;
        }
        this.$chooser.classList.add('datepicker-float');
        this.$chooser.style.top = `${top}px`;
        this.$chooser.style.left = `${bb.x}px`;
        this.$chooser.style.width = `${bb.width}px`;
      }, 3);
    }
  }

  hide() {
    this.$datepicker.classList.remove('datepicker-visible');
    window.clearInterval(this.floatInterval);
    this.$chooser.classList.remove('datepicker-float');
  }

  select(date) {
    this.date = date;
    this.settings.value = this.settings.formatter(this.date);
    if (this.settings.onChange) this.settings.onChange(this.settings.valueFormatter(date), this);
    this.resetPages();
    this.render();
    if (this.settings.hideOnSelect) this.hide();
  }

  resetPages() {
    this.currentMonth = new Date(new Date(this.date).setDate(15));
    this.monthsPage = 0;
    this.yearsPage = 0;
  }

  renderDates() {
    this.$body.innerHTML = '';

    this.$body.onmousewheel = (e) => {
      this.currentMonth.setMonth(this.currentMonth.getMonth() - 1 * (e.deltaY < 0 ? 1 : -1));
      this.render();
    };

    const firstOfMonth = getFirstDayOfMonth(this.currentMonth.getFullYear(), this.currentMonth.getMonth());
    const lastOfMonth = new Date(this.currentMonth.getFullYear(), this.currentMonth.getMonth() + 1, 0);

    const firstOfPicker = new Date(firstOfMonth);
    const lastOfPicker = new Date(lastOfMonth);

    while (firstOfPicker.getDay() !== this.settings.weekStart) {
      firstOfPicker.setDate(firstOfPicker.getDate() - 1);
    }

    while (lastOfPicker.getDay() !== this.settings.weekEnd) {
      lastOfPicker.setDate(lastOfPicker.getDate() + 1);
    }

    const $table = $el('datepicker-table', 'table');
    const $head = $el('', 'tr');

    $table.appendChild($head);
    for (let x = 0; x <= 6; x += 1) {
      const $td = $el('datepicker-head-cell', 'td');
      $td.innerHTML = this.settings.dayNames[x];
      $head.appendChild($td);
    }

    const cursorDate = new Date(firstOfPicker);

    let $tr;

    while (
      cursorDate < lastOfPicker
    ) {
      if (cursorDate.getDay() === this.settings.weekStart) {
        $tr = $el('', 'tr');
        $table.appendChild($tr);
      }

      const $td = $el('datepicker-date-cell', 'td');

      $td.date = new Date(cursorDate);

      $td.innerHTML = $td.date.getDate();

      const minDate = new Date(this.settings.minDate);
      minDate.setDate(minDate.getDate() - 1);

      const maxDate = new Date(this.settings.maxDate);
      maxDate.setDate(maxDate.getDate() + 1);

      if ($td.date < firstOfMonth || $td.date > lastOfMonth) $td.classList.add('datepicker-date-inactive');
      if ($td.date.isSameDateAs(new Date())) $td.classList.add('datepicker-date-today');
      if ($td.date.isSameDateAs(this.date)) $td.classList.add('datepicker-date-selected');
      if (this.settings.minDate && $td.date < minDate) $td.classList.add('datepicker-date-no-selection');
      if (this.settings.maxDate && $td.date > maxDate) $td.classList.add('datepicker-date-no-selection');

      $td.onclick = () => {
        this.select($td.date);
      };

      $tr.appendChild($td);

      cursorDate.setDate(cursorDate.getDate() + 1);
    }

    this.$body.appendChild($table);
  }

  renderMonths() {
    this.$body.innerHTML = '';

    this.$body.onmousewheel = (e) => {
      this.monthsPage += 1 * (e.deltaY < 0 ? -1 : 1);
      this.render();
    };

    const $table = $el('datepicker-table', 'table');
    let month = 0;

    for (let x = 0; x <= 3; x += 1) {
      const $tr = $el('datepicker-table-row', 'tr');
      for (let y = 0; y <= 2; y += 1) {
        const $td = $el('datepicker-table-cell', 'td');
        $td.month = month;
        if (this.date.getMonth() === month) $td.classList.add('datepicker-date-selected');
        $td.onclick = () => {
          this.currentMonth.setMonth($td.month);
          this.mode = DATEPICKER_MODES.DATE;
          if (this.settings.monthPickerMode) {
            this.currentMonth.setDate(1);
            this.select(this.currentMonth);
            this.mode = DATEPICKER_MODES.YEAR;
          }
          this.render();
        };
        $td.innerHTML = this.settings.monthNames[month];
        month += 1;
        $tr.appendChild($td);
      }
      $table.appendChild($tr);
    }

    this.$body.appendChild($table);
  }

  renderYears() {
    this.$body.innerHTML = '';

    this.$body.onmousewheel = (e) => {
      this.yearsPage += 1 * (e.deltaY < 0 ? -1 : 1);
      this.render();
    };

    const $table = $el('datepicker-table', 'table');
    let year = this.currentMonth.getFullYear() + (this.yearsPage * 6);

    for (let x = 0; x <= 3; x += 1) {
      const $tr = $el('datepicker-table-row', 'tr');
      for (let y = 0; y <= 2; y += 1) {
        const $td = $el('datepicker-table-cell', 'td');
        $td.year = year;
        if (this.date.getFullYear() === year) $td.classList.add('datepicker-date-selected');
        $td.onclick = () => {
          this.currentMonth.setFullYear($td.year);
          this.mode = DATEPICKER_MODES.DATE;
          if (this.settings.monthPickerMode) this.mode = DATEPICKER_MODES.MONTH;
          this.render();
        };
        $td.innerHTML = year;
        year += 1;
        $tr.appendChild($td);
      }
      $table.appendChild($tr);
    }

    this.$body.appendChild($table);
  }

  render() {
    this.$chooser.innerHTML = '';

    this.$picker = $el('datepicker-picker');

    this.$header = $el('datepicker-picker-header');
    this.$prev = $el('datepicker-page', 'img');
    this.$next = $el('datepicker-page', 'img');
    this.$middle = $el('datepicker-picker-middle'); // month year

    this.$prev.src = leftArrow;
    this.$next.src = rightArrow;

    this.$header.appendChild(this.$prev);
    this.$header.appendChild(this.$middle);
    this.$header.appendChild(this.$next);

    if (this.mode === DATEPICKER_MODES.MONTH) {
      this.$prev.style.visibility = 'hidden';
      this.$next.style.visibility = 'hidden';
    }

    this.$prev.onclick = () => {
      switch (this.mode) {
        default:
        case DATEPICKER_MODES.DATE:
          this.currentMonth.setMonth(this.currentMonth.getMonth() - 1);
          break;
        case DATEPICKER_MODES.MONTH:
          this.monthsPage -= 1;
          break;
        case DATEPICKER_MODES.YEAR:
          this.yearsPage -= 1;
          break;
      }
      this.render();
    };

    this.$next.onclick = () => {
      switch (this.mode) {
        default:
        case DATEPICKER_MODES.DATE:
          this.currentMonth.setMonth(this.currentMonth.getMonth() + 1);
          break;
        case DATEPICKER_MODES.MONTH:
          this.monthsPage += 1;
          break;
        case DATEPICKER_MODES.YEAR:
          this.yearsPage += 1;
          break;
      }
      this.render();
    };

    this.$month = $el('datepicker-month', 'span');
    this.$year = $el('datepicker-year', 'span');

    this.$month.innerHTML = this.settings.monthNames[this.currentMonth.getMonth()];
    this.$year.innerHTML = this.currentMonth.getFullYear();

    if (this.mode === DATEPICKER_MODES.MONTH) this.$month.classList.add('datepicker-current-view');
    if (this.mode === DATEPICKER_MODES.YEAR) this.$year.classList.add('datepicker-current-view');

    this.$month.onclick = () => {
      this.mode = this.mode !== DATEPICKER_MODES.MONTH ? DATEPICKER_MODES.MONTH : DATEPICKER_MODES.DATE;
      this.render();
    };

    this.$year.onclick = () => {
      this.mode = this.mode !== DATEPICKER_MODES.YEAR ? DATEPICKER_MODES.YEAR : DATEPICKER_MODES.DATE;
      this.render();
    };

    this.$middle.appendChild(this.$month);
    this.$middle.appendChild(this.$year);

    this.$body = $el('datepicker-picker-body');

    switch (this.mode) {
      default:
      case DATEPICKER_MODES.DATE:
        this.renderDates();
        break;
      case DATEPICKER_MODES.MONTH:
        this.renderMonths();
        break;
      case DATEPICKER_MODES.YEAR:
        this.renderYears();
        break;
    }

    this.$picker.appendChild(this.$header);
    this.$picker.appendChild(this.$body);

    this.$chooser.appendChild(this.$picker);

    this.renderValue();
  }

  renderValue() {
    if (this.settings.placeholder && !this.settings.value) {
      this.$value.innerHTML = this.settings.placeholder;
      return;
    }
    this.$value.innerHTML = this.settings.formatter(this.date);
  }
}

const Datepicker = (props) => {
  const $datepicker = useRef(null);
  const $value = useRef(null);
  const $chooser = useRef(null);
  const $icon = useRef(null);
  useEffect(() => {
    // eslint-disable-next-line no-new
    new DatePicker({
      $datepicker: $datepicker.current,
      $value: $value.current,
      $chooser: $chooser.current,
      $icon: $icon.current,
      ...props,
    });
    const $chatPopup = document.querySelector('.chat-popup');
    if ($chatPopup) {
      $chatPopup.scrollTop = $chatPopup.scrollHeight;
    }
    // eslint-disable-next-line
  }, []);
  return (
    <div className={`datepicker ${props.inlineMode ? 'datepicker-inline' : ''}`} ref={$datepicker}>
      <div className="datepicker-value-wrapper">
        <div className="datepicker-value" ref={$value} />
        <img alt="datepicker" src={datepickerIcon} ref={$icon} />
      </div>
      <div className="datepicker-chooser" ref={$chooser} />
    </div>
  );
};

Datepicker.propTypes = {
  inlineMode: PropTypes.bool,
};

export default Datepicker;
