import React from 'react';
import PropTypes from 'prop-types';
import * as Translation from 'react-i18next';
import * as ReactRedux from 'react-redux';
import { useTranslation } from 'utils/modifiedTranslation';
import Spinner from '../Spinner';
import { getQueryDataFromRootRoute } from '../AppRoutes/functions';
import LANGUAGE from './constants/language';
import { changeLanguage } from './action';
import LOCAL_STORAGE_KEYS from '../../utils/constants/localStorageKeys';
import { isSuperUser } from '../../domains/User/isSuperUser';

const bodyEl = document.querySelector('body');

const AppTranslationWrapper = (props) => {
  const { children, tReady, i18n } = props;
  const { t } = useTranslation();
  window.t = t;
  const dispatch = ReactRedux.useDispatch();
  const currLang = ReactRedux.useSelector((state) => state.main.lang);

  React.useEffect(() => {
    const { lang } = getQueryDataFromRootRoute();
    const alreadySetLang = localStorage.getItem(LOCAL_STORAGE_KEYS.LANGUAGE);
    let i18Lang;

    if (isSuperUser()) {
      i18n.changeLanguage(LANGUAGE.en.SHORT);
      dispatch(changeLanguage(LANGUAGE.en.SHORT));

      return;
    }

    if (lang) {
      i18Lang = LANGUAGE[lang];
    } else if (alreadySetLang) {
      i18Lang = LANGUAGE[alreadySetLang];
    }

    if (i18Lang?.SHORT) {
      i18n.changeLanguage(i18Lang?.SHORT);
      dispatch(changeLanguage(i18Lang?.SHORT));

      if (i18Lang?.SHORT === LANGUAGE.he.SHORT) {
        bodyEl.setAttribute('dir', 'rtl');
        bodyEl.classList.add('rtl');
      }
    } else {
      i18n.changeLanguage(LANGUAGE.en.SHORT);
      dispatch(changeLanguage(LANGUAGE.en.SHORT));
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    i18n.changeLanguage(currLang);

    if (currLang === LANGUAGE.he.SHORT) {
      bodyEl.setAttribute('dir', 'rtl');
      bodyEl.classList.add('rtl');
    } else {
      bodyEl.removeAttribute('dir');
      bodyEl.classList.remove('rtl');
    }
  }, [i18n, currLang]);

  if (!tReady) {
    return <Spinner />;
  }

  return children;
};

AppTranslationWrapper.propTypes = {
  children: PropTypes.object,
  tReady: PropTypes.bool,
  i18n: PropTypes.object,
};

export default Translation.withTranslation()(AppTranslationWrapper);
