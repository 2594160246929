/**
 * @enum {string}
 */
const IN_CONTEXT_UPDATE = {
  MAIN: 'MAIN',
  SPECIFIC_QUESTION: 'SPECIFIC_QUESTION',
};

const IN_CONTEXT_UPDATE_LOCATION = {
  [IN_CONTEXT_UPDATE.MAIN]: 'main button',
  [IN_CONTEXT_UPDATE.SPECIFIC_QUESTION]: 'specific question',
};

const IN_CONTEXT_UPDATE_EXPLANATION = {
  [IN_CONTEXT_UPDATE.MAIN]: 'main button: user clicked on “Update critical information”',
  [IN_CONTEXT_UPDATE.SPECIFIC_QUESTION]: 'specific question: main button user clicked on a question',
};

/**
 * @enum {string}
 */
const GA_STATUS_TYPE = {
  PENDING: 'Pending',
  START_REVIEWING: 'Start reviewing',
};

/**
 * @enum {string}
*/
const GA_TRIAL_TYPE = {
  SPONSORED: 'Sponsored',
  NOT_SPONSORED: 'Not sponsored',
};

const GA_CATEGORY_NAME = 'In-context';

const inContextEvents = {
  clickedInContext: {
    eventName: 'Clicked - In-context',
    /**
     *
     * @param {GA_STATUS_TYPE} status
     * @param {GA_TRIAL_TYPE} userType
     * @param {GA_TRIAL_TYPE} trialType
     * @returns {object}
     */
    getMetaData: (status, userType, trialType) => ({
      Status: status,
      'User type': userType,
      'Trial type': trialType,
    }),
  },
  popupInContextDisplayed: {
    eventName: 'Popup - In-context displayed',
    /**
     *
     * @param {number} numberOfQuestions
     * @returns {object}
     */
    getMetaData: (numberOfQuestions) => ({
      'Number of Questions': numberOfQuestions,
    }),
  },
  clickedInContextUpdating: {
    eventName: 'Clicked - In-context updating',
    /**
     *
     * @param {IN_CONTEXT_UPDATE} updateLocation
     * @returns {object}
     */
    getMetaData: (updateLocation) => ({
      Location: IN_CONTEXT_UPDATE_LOCATION[updateLocation],
      Explanation: IN_CONTEXT_UPDATE_EXPLANATION[updateLocation],
    }),
  },
  clickedInContextHelp: {
    eventName: 'In-context help',
    /**
     *
     * @returns {object}
     */
    getMetaData: () => ({
      Explanation: 'The user clicked on “Cannot update this information”',
    }),
  },
  profileUpdate: {
    eventName: 'Profile update - In-context help',
    /**
     *
     * @param {number} numberOfAnsweredQuestions
     * @param {string} toastMessage
     * @returns {object}
     */
    getMetaData: (numberOfAnsweredQuestions, toastMessage) => ({
      Explanation: 'When a user answered on question or update few questions',
      'Number of answered question': numberOfAnsweredQuestions,
      'Toast message': toastMessage,
    }),
  },
};

export {
  IN_CONTEXT_UPDATE,
  GA_STATUS_TYPE,
  GA_TRIAL_TYPE,
  GA_CATEGORY_NAME,
};

export default inContextEvents;
