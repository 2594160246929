import moment from 'moment';
import monthsConstants from '../constants/months';

/**
  DATA_FORMAT_FOR_MONTH_PICKER = {
    months: [
      { label: 'March', value: 2 }
    ],
    year: [
      { label: '1998', value: 1998 }
    ]
  }
 */

export default (formValues, isFromServer) => {
  // from server type value to component type value
  if (isFromServer && typeof formValues === 'string') {
    const date = new Date(formValues);
    return {
      month: [monthsConstants[date.getMonth()]],
      year: [
        {
          label: `${date.getFullYear()}`,
          value: date.getFullYear(),
        },
      ],
    };
  }

  // from component type value to server type value
  if (!formValues || Object.keys(formValues).length < 2 || !Object.keys(formValues).includes('year')) {
    return null;
  }
  const { month, year } = formValues;
  return moment().set({
    year: year[0]?.value || 1930,
    month: month[0]?.value || 0,
    date: 2,
  });
};
