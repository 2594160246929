import TzviaIcon from 'new-ui/assets/images/tzvia.png';
import app from 'new-ui/app';
import { THEMES } from 'new-ui/constants';
import { GA_INTAKE_CATEGORY, GA_INTAKE_EVENTS } from 'new-ui/Components/GoogleAnalytics/generalEvents';
import { CHATBOT_MESSAGE_TYPES, createChatBot, removeChatBot } from '..';

const ChatbotIntakeFlow = (props) => {
  const {
    onYes = ()=>{},
    onNo = ()=>{},
  } = props;

  app.sendGoogleAnalyticsEvent(GA_INTAKE_CATEGORY, GA_INTAKE_EVENTS.INTAKE_BOT_VIEW);

  createChatBot({
    chat: [
      {
        icon: TzviaIcon,
        messages: [
          {
            type: CHATBOT_MESSAGE_TYPES.TEXT_MESSAGE,
            text: "Hi, I'm Tzvia, a cancer survivor and CEO of Leal. Welcome!",
          },
          {
            type: CHATBOT_MESSAGE_TYPES.TEXT_MESSAGE,
            text: `We found <b>${app.numResults(true)} treatment options</b> for ${window.t(`quest_selector.cancer_types.${app.user?.personal?.condition}`)}.`,
          },
          {
            type: CHATBOT_MESSAGE_TYPES.TEXT_MESSAGE,
            text: 'To find the best treatment for you, we need to learn more about your specific condition.',
          },
          {
            type: CHATBOT_MESSAGE_TYPES.BUTTONS,
            buttons: [
              {
                theme: THEMES.ORANGE_FULL,
                text: 'Yes, I want to add information',
                action: () => {
                  removeChatBot();
                  onYes();
                },
              },
              {
                text: 'No, I want to see basic results',
                action: () => {
                  removeChatBot();
                  onNo();
                },
              },
            ],
          },
        ],
      },
    ],
  });
};

export default ChatbotIntakeFlow;
