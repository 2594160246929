const SINGLE_OPTION_SELECT = 'SINGLE_OPTION_SELECT';

export const FINANCE_QUESTIONS = {
  HAS_TREATMENT: {
    id: 'hasTreatment',
    intakeTitle: 'finance_intake.has_treatment_intake_title',
    title: 'finance_intake.has_treatment_title',
    subtitle: 'finance_intake.has_treatment_subtitle',
    // showSubtitle: true,
    type: SINGLE_OPTION_SELECT,
    options: [
      {
        label: 'Currently in active treatment',
        value: 'yes',
      },
      {
        label: 'Not in active treatment',
        value: 'no',
      },
    ],
  },
  FINANCIAL_HELP: {
    id: 'helpReceivedBefore',
    intakeTitle: 'finance_intake.financial_help_intake_title',
    title: 'finance_intake.financial_help_title',
    subtitle: 'finance_intake.financial_help_subtitle',
    showSubtitle: true,
    type: SINGLE_OPTION_SELECT,
    options: [
      {
        label: 'general.yes',
        value: 'yes',
      },
      {
        label: 'general.no',
        value: 'no',
      },
    ],
  },
  HAS_SSN: {
    id: 'hasSsn',
    intakeTitle: 'finance_intake.has_ssn_intake_title',
    title: 'finance_intake.has_ssn_title',
    subtitle: 'finance_intake.has_ssn_subtitle',
    showSubtitle: true,
    type: SINGLE_OPTION_SELECT,
    options: [
      {
        label: 'general.yes',
        value: 'yes',
      },
      {
        label: 'general.no',
        value: 'no',
      },
    ],
  },
  HOUSEHOLD_MEMBERS_NUM: {
    id: 'numOfHouseholdMembers',
    intakeTitle: 'finance_intake.household_intake_title',
    title: 'finance_intake.household_title',
    subtitle: 'finance_intake.household_subtitle',
    showSubtitle: true,
    type: SINGLE_OPTION_SELECT,
    options: [
      {
        label: '1-3',
        value: '1-3',
      },
      {
        label: '4-5',
        value: '4-5',
      },
      {
        label: '>6',
        value: '>6',
      },
    ],
  },
  INCOME: {
    id: 'income',
    intakeTitle: 'finance_intake.income_intake_title',
    title: 'finance_intake.income_title',
    // subtitle: 'finance_intake.income_subtitle',
    // showSubtitle: true,
    type: SINGLE_OPTION_SELECT,
    options: [
      {
        label: 'Less Than $41,000',
        value: 'less_than_40k',
      },
      {
        label: '$41,000 - $55,000',
        value: '41k-55k',
      },
      {
        label: '$56,000 - $100,000',
        value: '56k-100k',
      },
      {
        label: 'More Than $100,000',
        value: 'more_than_100k',
      },
    ],
  },
  INSURANCE: {
    id: 'insurance',
    intakeTitle: 'finance_intake.insurance_intake_title',
    title: 'finance_intake.insurance_title',
    subtitle: 'finance_intake.insurance_subtitle',
    showSubtitle: true,
    type: SINGLE_OPTION_SELECT,
    options: [
      {
        label: 'Medicare',
        value: 'Medicare',
      },
      {
        label: 'Medicaid',
        value: 'Medicaid',
      },
      {
        label: 'Military',
        value: 'Military',
      },
      {
        label: 'CHIP (Children Help Insurance Program)',
        value: 'CHIP (Children Help Insurance Program)',
      },
      {
        label: 'Private Healthcare Insurance',
        value: 'Private Healthcare Insurance',
      },
      {
        label: 'None',
        value: 'None',
      },
    ],
  },
};
