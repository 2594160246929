import React, { useState } from 'react';
import './ProfileButton.css';
import app from 'new-ui/app';

import UserIcon from 'new-ui/assets/icons/user_button.svg';
import { useTranslation } from 'utils/modifiedTranslation';
import { PAGES, URI } from 'new-ui/constants';

const DEFAULT_ACTIONS = [
  {
    title: 'general.logout',
    onClick: ()=>{
      app.logout(URI[PAGES.SEARCH]);
    },
  },
];

const ProfileButton = (props)=>{
  const {
    actions = DEFAULT_ACTIONS,
  } = props;
  const [showActions, setShowActions] = useState(false);
  const { t } = useTranslation();
  return (
    <div className="profile-button">
      <img
        onClick={()=>{
          setShowActions(!showActions);
        }}
        src={UserIcon}
        alt=""
      />
      {
        showActions ? actions.map((action, key)=>(
          <>
            <div key={key} onClick={action.onClick} className="profile-button-actions">
              <div className="profile-button-action">{t(action.title)}</div>
            </div>
          </>
        )) : null
      }
    </div>
  );
};

export default ProfileButton;
