import _ from 'lodash';
import { flattenAnswers } from './flattenAnswers';

export function checkIfDoesntContainsOneOf(profile, condition) {
  const clonedProfile = flattenAnswers(profile);
  // check if an array | string contains one of the values in the arguments
  // ifValue could be array or string
  // ifId stays the same
  const { ifId, ifValue } = condition;
  const profileValue = clonedProfile[ifId];

  if (!_.hasIn(clonedProfile, ifId)) {
    // console.log(ifId, 'not found');
    return true;
  }

  if (_.isArray(profileValue)) {
    // In case profile value is an array

    if (_.isArray(ifValue)) {
      const checks = ifValue.map((value) => {
        return _.includes(profileValue, value);
      });
      // console.log("check if", profileValue, "contains:",ifValue, "Result::", checks);
      return _.every(checks, (check) => check === false);
    }
    // console.log(profileValue,"is an array. check if",ifValue, !_.includes(profileValue, ifValue));
    return !_.includes(profileValue, ifValue);
  }
  // In case profile value is an array and ifValue is not array
  // console.log("If profile value", profileValue, ifValue, " is an array", !_.some(ifValue, value => value === profileValue));
  return !_.some(ifValue, (value) => value === profileValue);
}
