import mapEventConstants from '../constants/mapEvent';

const initialState = {
  type: null,
  trialIds: []
};

export function mapEvent(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case mapEventConstants.HOVER_ENTER:
      return {
        type: mapEventConstants.HOVER_ENTER,
        trialIds: payload
      };
    case mapEventConstants.HOVER_LEAVE:
      return {
        type: mapEventConstants.HOVER_LEAVE,
        trialIds: payload
      };
    case mapEventConstants.ON_CLICK:
      return {
        type: mapEventConstants.ON_CLICK,
        trialIds: payload[0]
      };
    default:
      return state;
  }
}
