import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ROUTE_PATH, ROUTE_NAME } from '../constants';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: () => ({
    minHeight: '100%',
    overflow: 'unset',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
  }),
  providerRootAppRouterContainer: {
    minHeight: 'unset',
    position: 'relative',
    height: 'calc(100% - 15px)',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 20px 5px !important',
    backgroundColor: '#F9EEF1',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100% - 20px)',
      padding: '15px 5px 5px !important',
    },
  },
  fixedHeight: {
    [theme.breakpoints.down('sm')]: ({ provider }) => ({
      height: provider ? '100%' : '93vh',
      minHeight: 0,
      overflowY: 'hidden',
    }),
  },
}));

const AppRouteContainer = props => {
  const { appConfiguration: { providerSpecific } = {} } = useSelector((state) => state) ?? {};
  const { children } = props;
  const classes = useStyles();
  const match = useRouteMatch(ROUTE_PATH[ROUTE_NAME.QUESTIONNAIRE]);
  const className = `${providerSpecific?.code ? classes.providerRootAppRouterContainer : classes.root} ${match?.isExact ? classes.fixedHeight : ''}`;

  return <div className={className} id="rootContainer">{children}</div>;
};

export default AppRouteContainer;
