import {
  SINGLE_OPTION_SELECT,
  MULTIPLE_OPTION_SELECT,
  MONTH_PICKER,
  TREATMENT_HISTORY,
  NUMBER_INPUT,
  PROCEDURES,
  TRIPLE_QUESTION_DROP_DOWN,
  MULTIPLE_OPTION_SEARCH_SELECT,
  BIOMARKERS,
  OPTIONS_FROM_SERVER,
} from '../../constants/questionTypes';
import dateType from '../../../../utils/constants/dateTypes';
import { Profile as ID } from './Breast2Profile.enum';
import numberType from '../../../../utils/constants/numberTypes';
import ConditionTypes from '../../constants/conditions';
import i from '../../constants/icons';
import {
  preferredLocation,
  travelLimit,
  userType,
  dateOfBirth,
  race,
  haveInsurance,
  insurance,
  diseaseStatus,
  pdL1ExpressionByQNumber,
  initialDiagnosisDate,
} from '../../constants/globalQuestions';
import {
  treatmentCyclesOptions,
  // takingStateOptions,
  yesAndNoOptions,
  yesNoIAmNotSureOptions,
} from '../../constants/globalOptions';
import SECTIONS from '../../constants/questionSections';
import questionOptions from '../../constants/questionOptions';
import { QUESTION_VIEW_TYPES } from '../../constants/questionViewTypes';
import GLOBAL_ENUMS from '../../constants/globalEnums';

const { biomarkers: BIOMARKERS_QUESTION_ID } = GLOBAL_ENUMS;

const { OPTIONS_LIST_WITH_SHOW_MORE } = QUESTION_VIEW_TYPES;

const noneOption = questionOptions.NONE;
const {
  NOT_EQUAL_TO_OR_SKIPPED,
  IS_SKIPPED,
  DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
  CONTAIN_ONE_OF,
  AGE_LESS_THAN,
  EQUAL_TO,
  CONTAINS_ONE_OF_OR_SKIPPED,
} = ConditionTypes;

const Questions = [
  diseaseStatus,
  {
    q: 'Q9',
    id: ID.molecular_subtype,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.molecular,
    order: 0,
    title: 'questionnaire.questions.breast2.5_molecular_subtype.title',
    subtitle: 'questionnaire.questions.breast2.5_molecular_subtype.subtitle',
    info: {
      title: 'questionnaire.questions.breast2.5_molecular_subtype.info.title',
      content: 'questionnaire.questions.breast2.5_molecular_subtype.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.breast2.5_molecular_subtype.options.er',
        value: 'er',
      },
      {
        label: 'questionnaire.questions.breast2.5_molecular_subtype.options.pr',
        value: 'pr',
      },
      {
        label: 'questionnaire.questions.breast2.5_molecular_subtype.options.her2',
        value: 'her2',
      },
      {
        label: 'questionnaire.questions.breast2.5_molecular_subtype.options.tnbc.label',
        formattedLabel: 'questionnaire.questions.breast2.5_molecular_subtype.options.tnbc.formatted_label',
        value: 'tnbc',
        exclusive: true,
      },
    ],
    conditions: [],
    isRequired: true,
  },
  {
    q: 'Q9a',
    id: ID.her2_levels,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.molecular,
    order: 0,
    title: 'questionnaire.questions.breast2.her2_levels.title',
    subtitle: 'questionnaire.questions.breast2.her2_levels.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.breast2.her2_levels.options.0',
        value: '0',
      },
      {
        label: 'questionnaire.questions.breast2.her2_levels.options.1_or_2',
        value: '1_or_2',
      },
      {
        label: 'questionnaire.questions.breast2.her2_levels.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: ID.molecular_subtype,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['her2'],
      },
    ],
  },
  {
    q: 'Q10',
    id: ID.patient_stage,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.breast2.7_patient_stage.title',

    info: {
      title: 'questionnaire.questions.breast2.7_patient_stage.info.title',
      content: 'questionnaire.questions.breast2.7_patient_stage.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.breast2.7_patient_stage.options.0.0',
        value: '0.0',
      },
      {
        label: 'questionnaire.questions.breast2.7_patient_stage.options.1.0',
        value: '1.0',
      },
      {
        label: 'questionnaire.questions.breast2.7_patient_stage.options.2.1',
        value: '2.1',
      },
      {
        label: 'questionnaire.questions.breast2.7_patient_stage.options.2.2',
        value: '2.2',
      },
      {
        label: 'questionnaire.questions.breast2.7_patient_stage.options.3.1',
        value: '3.1',
      },
      {
        label: 'questionnaire.questions.breast2.7_patient_stage.options.3.2',
        value: '3.2',
      },
      {
        label: 'questionnaire.questions.breast2.7_patient_stage.options.3.3',
        value: '3.3',
      },
      {
        label: 'questionnaire.questions.breast2.7_patient_stage.options.4',
        value: '4',
      },
    ],
    conditions: [],
    isRequired: true,
  },
  {
    q: 'Q13',
    id: ID.treatment_for_breast_cancer,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.breast2.20_treatment_for_breast_cancer.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.breast2.20_treatment_for_breast_cancer.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.breast2.20_treatment_for_breast_cancer.options.no',
        value: 'no',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q6',
    id: ID.type_of_bc,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.breast2.2_type_of_bc.title',
    info: {
      title: 'questionnaire.questions.breast2.2_type_of_bc.info.title',
      content: 'questionnaire.questions.breast2.2_type_of_bc.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.breast2.2_type_of_bc.options.dcis',
        value: 'dcis',
      },
      {
        label: 'questionnaire.questions.breast2.2_type_of_bc.options.lcis',
        value: 'lcis',
      },
      {
        label: 'questionnaire.questions.breast2.2_type_of_bc.options.idc',
        value: 'idc',
      },
      {
        label: 'questionnaire.questions.breast2.2_type_of_bc.options.ilc',
        value: 'ilc',
      },
      {
        label: 'questionnaire.questions.breast2.2_type_of_bc.options.ibc',
        value: 'ibc',
      },
      {
        label: 'questionnaire.questions.breast2.2_type_of_bc.options.other',
        value: 'other',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q17',
    id: ID.disease_location,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.breast2.11_disease_location.title',
    subtitle: 'questionnaire.questions.breast2.11_disease_location.subtitle',
    info: {
      title: 'questionnaire.questions.breast2.11_disease_location.info.title',
      content: 'questionnaire.questions.breast2.11_disease_location.info.content',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.breast2.11_disease_location.options.breast',
        value: 'breast',
      },
      {
        label: 'questionnaire.questions.breast2.11_disease_location.options.lymph_nodes',
        value: 'lymph_nodes',
      },
      {
        label: 'questionnaire.questions.breast2.11_disease_location.options.lung',
        value: 'lung',
      },
      {
        label: 'questionnaire.questions.breast2.11_disease_location.options.bone',
        value: 'bone',
      },
      {
        label: 'questionnaire.questions.breast2.11_disease_location.options.liver',
        value: 'liver',
      },
      {
        label: 'questionnaire.questions.breast2.11_disease_location.options.ovaries',
        value: 'ovaries',
      },
      {
        label: 'questionnaire.questions.breast2.11_disease_location.options.brain',
        value: 'brain',
      },
      {
        label: 'questionnaire.questions.breast2.11_disease_location.options.other',
        value: 'other',
      },
    ],
    conditions: [],
    isRequired: true,
  },
  {
    q: 'Q61',
    id: ID.brca_carrier_status,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.molecular,
    order: 0,
    title: 'questionnaire.questions.breast2.6_brca_carrier_status.title',

    info: {
      title: 'questionnaire.questions.breast2.6_brca_carrier_status.info.title',
      content: 'questionnaire.questions.breast2.6_brca_carrier_status.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.breast2.6_brca_carrier_status.options.brca1',
        value: 'brca1',
      },
      {
        label: 'questionnaire.questions.breast2.6_brca_carrier_status.options.brca2',
        value: 'brca2',
      },
      {
        label: 'questionnaire.questions.breast2.6_brca_carrier_status.options.non_brca_carrier',
        value: 'non_brca_carrier',
      },
      {
        label: 'questionnaire.questions.breast2.6_brca_carrier_status.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q11',
    id: ID.ptnm,
    type: TRIPLE_QUESTION_DROP_DOWN,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.breast2.8_ptnm.title',
    subtitle: 'questionnaire.questions.breast2.8_ptnm.subtitle',
    info: {
      title: 'questionnaire.questions.breast2.8_ptnm.info.title',
      content: 'questionnaire.questions.breast2.8_ptnm.info.content',
    },
    options: [
      {
        title: 'questionnaire.questions.breast2.8_ptnm.options.ptnm_pt.title',
        name: ID.ptnm_pt,
        options: [
          {
            label: '',
            value: '',
          },
          {
            label: 'questionnaire.questions.breast2.8_ptnm.options.ptnm_pt.options.1',
            value: '1',
          },
          {
            label: 'questionnaire.questions.breast2.8_ptnm.options.ptnm_pt.options.2',
            value: '2',
          },
          {
            label: 'questionnaire.questions.breast2.8_ptnm.options.ptnm_pt.options.3',
            value: '3',
          },
          {
            label: 'questionnaire.questions.breast2.8_ptnm.options.ptnm_pt.options.4',
            value: '4',
          },
        ],
      },
      {
        title: 'questionnaire.questions.breast2.8_ptnm.options.ptnm_pn.title',
        name: ID.ptnm_pn,
        options: [
          {
            label: '',
            value: '',
          },
          {
            label: 'questionnaire.questions.breast2.8_ptnm.options.ptnm_pn.options.0',
            value: '0',
          },
          {
            label: 'questionnaire.questions.breast2.8_ptnm.options.ptnm_pn.options.1',
            value: '1',
          },
          {
            label: 'questionnaire.questions.breast2.8_ptnm.options.ptnm_pn.options.2',
            value: '2',
          },
          {
            label: 'questionnaire.questions.breast2.8_ptnm.options.ptnm_pn.options.3',
            value: '3',
          },
        ],
      },
      {
        title: 'questionnaire.questions.breast2.8_ptnm.options.ptnm_pm.title',
        name: ID.ptnm_pm,
        options: [
          {
            label: '',
            value: '',
          },
          {
            label: 'questionnaire.questions.breast2.8_ptnm.options.ptnm_pm..options.0',
            value: '0',
          },
          {
            label: 'questionnaire.questions.breast2.8_ptnm.options.ptnm_pm..options.1',
            value: '1',
          },
        ],
      },
    ],
    conditions: [
      {
        ifId: ID.patient_stage,
        type: IS_SKIPPED,
      },
    ],
  },
  {
    q: 'Q15',
    id: ID.disease_measurable,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.breast2.9_disease_measurable.title',

    info: {
      title: 'questionnaire.questions.breast2.9_disease_measurable.info.title',
      content: 'questionnaire.questions.breast2.9_disease_measurable.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.breast2.9_disease_measurable.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.breast2.9_disease_measurable.options.no_but_it_can_be',
        value: 'no_but_it_can_be',
      },
      {
        label: 'questionnaire.questions.breast2.9_disease_measurable.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.breast2.9_disease_measurable.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: ID.disease_status,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'remission',
      },
    ],
  },
  {
    q: 'Q16',
    id: ID.tumor_size_mm,
    type: NUMBER_INPUT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.breast2.10_tumor_size_mm.title',
    subtitle: 'questionnaire.questions.breast2.10_tumor_size_mm.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [],
    conditions: [
      {
        ifId: ID.disease_measurable,
        type: EQUAL_TO,
        ifValue: 'yes',
      },
    ],
    numberType: numberType.int,
  },
  {
    q: 'Q602',
    id: ID.type_of_recurrence,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.breast2.12_type_of_recurrence.title',
    subtitle: 'questionnaire.questions.breast2.12_type_of_recurrence.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.breast2.12_type_of_recurrence.options.ipsilateral_recurrence',
        value: 'ipsilateral_recurrence',
      },
      {
        label: 'questionnaire.questions.breast2.12_type_of_recurrence.options.contralateral_recurrence',
        value: 'contralateral_recurrence',
      },
      {
        label: 'questionnaire.questions.breast2.12_type_of_recurrence.options.regional_recurrence',
        value: 'regional_recurrence',
      },
    ],
    conditions: [
      {
        ifId: ID.disease_location,
        type: CONTAIN_ONE_OF,
        ifValue: ['breast', 'lymph_nodes'],
      },
      {
        ifId: ID.disease_status,
        type: EQUAL_TO,
        ifValue: 'recurrence',
      },
    ],
  },
  {
    q: 'Q18',
    id: ID.metastasis_location_brain_leptomeningeal,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.breast2.13_metastasis_location_brain_leptomeningeal.title',

    info: {
      title: 'questionnaire.questions.breast2.13_metastasis_location_brain_leptomeningeal.info.title',
      content: 'questionnaire.questions.breast2.13_metastasis_location_brain_leptomeningeal.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.breast2.13_metastasis_location_brain_leptomeningeal.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.breast2.13_metastasis_location_brain_leptomeningeal.options.no',
        value: 'no',
      },
    ],
    conditions: [
      {
        ifId: ID.disease_location,
        type: CONTAIN_ONE_OF,
        ifValue: ['brain'],
      },
    ],
  },
  {
    q: 'Q19',
    id: ID.is_brain_metastasis_active,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.breast2.14_is_brain_metastasis_active.title',

    info: {
      title: 'questionnaire.questions.breast2.14_is_brain_metastasis_active.info.title',
      content: 'questionnaire.questions.breast2.14_is_brain_metastasis_active.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.breast2.14_is_brain_metastasis_active.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.breast2.14_is_brain_metastasis_active.options.no',
        value: 'no',
      },
    ],
    conditions: [
      {
        ifId: ID.disease_location,
        type: CONTAIN_ONE_OF,
        ifValue: ['brain'],
      },
    ],
  },
  {
    q: 'Q21',
    id: ID.has_biopsy,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.breast2.15_has_biopsy.title',

    info: {
      title: 'questionnaire.questions.breast2.15_has_biopsy.info.title',
      content: 'questionnaire.questions.breast2.15_has_biopsy.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.breast2.15_has_biopsy.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.breast2.15_has_biopsy.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.breast2.15_has_biopsy.options.biopsy_possible',
        value: 'biopsy_possible',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q90',
    id: ID.pd_l1_carrier_status,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.molecular,
    order: 0,
    title: 'questionnaire.questions.breast2.44_pd_l1_carrier_status.title',
    info: {
      title: 'questionnaire.questions.breast2.44_pd_l1_carrier_status.info.title',
      content: 'questionnaire.questions.breast2.44_pd_l1_carrier_status.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.breast2.44_pd_l1_carrier_status.options.positive',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.breast2.44_pd_l1_carrier_status.options.negative',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.breast2.44_pd_l1_carrier_status.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: ID.has_biopsy,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['no', 'biopsy_possible'],
      },
    ],
  },
  pdL1ExpressionByQNumber('Q90a'),
  {
    q: 'Q80',
    id: ID.ngs,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.molecular,
    order: 0,
    title: 'questionnaire.questions.breast2.43_ngs.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.breast2.43_ngs.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.breast2.43_ngs.options.yes_waiting_for_the_results',
        value: 'yes_waiting_for_the_results',
      },
      {
        label: 'questionnaire.questions.breast2.43_ngs.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.breast2.43_ngs.options.no_but_planning',
        value: 'no_but_planning',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q22',
    id: BIOMARKERS_QUESTION_ID,
    type: BIOMARKERS,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.molecular,
    order: 0,
    title: 'questionnaire.questions.breast2.16_biomarkers.title',
    subtitle: 'questionnaire.questions.breast2.16_biomarkers.subtitle',
    placeholder: 'questionnaire.questions.biomarkers.placeholder',
    info: {
      title: '',
      content: '',
    },
    conditions: [
      {
        ifId: ID.has_biopsy,
        type: CONTAINS_ONE_OF_OR_SKIPPED,
        ifValue: ['yes'],
      },
    ],
  },
  {
    q: 'Q23',
    id: ID.disease_resectable,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.breast2.17_disease_resectable.title',

    info: {
      title: 'questionnaire.questions.breast2.17_disease_resectable.info.title',
      content: 'questionnaire.questions.breast2.17_disease_resectable.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.breast2.17_disease_resectable.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.breast2.17_disease_resectable.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.breast2.17_disease_resectable.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: ID.disease_status,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'remission',
      },
      {
        ifId: ID.patient_stage,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['4'],
      },
    ],
  },
  {
    q: 'Q12',
    id: ID.had_procedure_for_breast_cancer,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.breast2.18_had_procedure_for_breast_cancer.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.breast2.18_had_procedure_for_breast_cancer.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.breast2.18_had_procedure_for_breast_cancer.options.not_yet',
        value: 'not_yet',
      },
      {
        label: 'questionnaire.questions.breast2.18_had_procedure_for_breast_cancer.options.no',
        value: 'no',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q70',
    id: ID.procedures,
    type: PROCEDURES,
    section: SECTIONS.TREATMENT,
    bgIcon: '',
    order: 0,
    title: 'questionnaire.questions.breast2.19_procedures.title',
    subtitle: 'questionnaire.questions.breast2.19_procedures.subtitle',
    footer: 'questionnaire.questions.breast2.19_procedures.footer',
    requiredSubQuestionsId: [ID.procedures_received],
    info: {
      title: '',
      content: '',
    },
    submitButtonLabel: 'questionnaire.questions.breast2.19_procedures.button.submit',
    options: [
      {
        iconText: 'A',
        label: 'questionnaire.questions.breast2.19_procedures.options.type.label',
        value: null,
      },
      {
        iconText: 'B',
        label: 'questionnaire.questions.breast2.19_procedures.options.when_procedure.label',
        value: null,
      },
      {
        iconText: 'C',
        label: 'questionnaire.questions.breast2.19_procedures.options.procedure_outcome.label',
        value: null,
      },
    ],
    shouldAutoOpenSubQuestions: [{
      ifId: ID.had_procedure_for_breast_cancer,
      type: EQUAL_TO,
      ifValue: 'yes',
    }],
    conditions: [
      {
        ifId: ID.had_procedure_for_breast_cancer,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['no', 'not_yet'],
      },
    ],
    isRequired: true,
    subQuestions: [
      {
        q: 'Q70a',
        id: ID.procedures_received,
        type: MULTIPLE_OPTION_SEARCH_SELECT,
        viewType: OPTIONS_LIST_WITH_SHOW_MORE,
        section: SECTIONS.TREATMENT,
        bgIcon: i.treatment,
        order: 0,
        titleIconText: 'A',
        title: 'questionnaire.questions.breast2.19_procedures.sub_questions.procedures_received.title',
        subtitle: '',
        placeholder: 'questionnaire.questions.procedures_received.placeholder',
        info: {
          title: '',
          content: '',
        },
        options: {
          dropDownOptions: [
            {
              label:
                'questionnaire.questions.breast2.19_procedures.sub_questions.procedures_received.options.lumpectomy',
              value: 'lumpectomy',
            },
            {
              label:
                'questionnaire.questions.breast2.19_procedures.sub_questions.procedures_received.options.mastectomy',
              value: 'mastectomy',
            },
            {
              label: 'questionnaire.questions.breast2.19_procedures.sub_questions.procedures_received.options.alnd',
              value: 'alnd',
            },
            {
              label:
                'questionnaire.questions.breast2.19_procedures.sub_questions.procedures_received.options.reconstruction',
              value: 'reconstruction',
            },
            {
              label: 'questionnaire.questions.breast2.19_procedures.sub_questions.procedures_received.options.wbrt',
              value: 'wbrt',
            },
            {
              label:
                'questionnaire.questions.breast2.19_procedures.sub_questions.procedures_received.options.in_radiation',
              value: 'in_radiation',
            },
            {
              label:
                'questionnaire.questions.breast2.19_procedures.sub_questions.procedures_received.options.metastasis_resection',
              value: 'metastasis_resection',
            },
            {
              label: 'questionnaire.questions.breast2.19_procedures.sub_questions.procedures_received.options.ablation',
              value: 'ablation',
            },
            {
              label: 'questionnaire.questions.breast2.19_procedures.sub_questions.procedures_received.options.rfa',
              value: 'rfa',
            },
            {
              label: 'questionnaire.questions.breast2.19_procedures.sub_questions.procedures_received.options.ebrt',
              value: 'ebrt',
            },
          ],
          radioOptions: [
            {
              label: 'questionnaire.questions.breast2.19_procedures.sub_questions.procedures_received.options.other',
              value: 'other',
            },
          ],
        },
        conditions: [],
      },
      {
        q: 'Q70b',
        id: ID.procedure_date,
        type: MONTH_PICKER,
        section: SECTIONS.TREATMENT,
        bgIcon: '',
        order: 0,
        titleIconText: 'B',
        title: 'questionnaire.questions.breast2.19_procedures.sub_questions.procedure_date.title',
        subtitle: '',
        info: {
          title: '',
          content: '',
        },
        options: [],
        conditions: [],
        dateType: {
          type: dateType.stopTreatment,
        },
      },
      {
        q: 'Q70c',
        id: ID.procedure_outcome,
        type: SINGLE_OPTION_SELECT,
        section: SECTIONS.TREATMENT,
        bgIcon: '',
        order: 0,
        titleIconText: 'C',
        title: 'questionnaire.questions.breast2.19_procedures.sub_questions.procedure_outcome.title',
        subtitle: '',
        info: {
          title: '',
          content: '',
        },
        disableNextOnOptionSelect: false,
        options: [
          {
            label: 'questionnaire.questions.breast2.19_procedures.sub_questions.procedure_outcome.options.disease_free',
            value: 'disease_free',
          },
          {
            label:
              'questionnaire.questions.breast2.19_procedures.sub_questions.procedure_outcome.options.local_residual_disease',
            value: 'local_residual_disease',
          },
          {
            label:
              'questionnaire.questions.breast2.19_procedures.sub_questions.procedure_outcome.options.residual_systemic_disease',
            value: 'residual_systemic_disease',
          },
        ],
        answerResetable: true,
        conditions: [],
      },
    ],
  },

  {
    q: 'Q13a',
    id: ID.treatment_for_advanced_metastatic_disease,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.breast2.21_treatment_for_advanced_metastatic_disease.title',
    subtitle: 'questionnaire.questions.breast2.21_treatment_for_advanced_metastatic_disease.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: yesAndNoOptions,
    conditions: [
      {
        ifId: ID.treatment_for_breast_cancer,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'no',
      },
      {
        ifId: ID.patient_stage,
        type: CONTAIN_ONE_OF,
        ifValue: ['3.1', '3.2', '3.3', '4'],
      },
    ],
  },
  {
    q: 'Q13b',
    id: ID.received_fam_trastuzumab,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.breast2.received_fam_trastuzumab.title',
    subtitle: 'questionnaire.questions.breast2.received_fam_trastuzumab.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: yesNoIAmNotSureOptions,
    conditions: [
      {
        ifId: ID.treatment_for_breast_cancer,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'no',
      },
      {
        ifId: ID.patient_stage,
        type: CONTAIN_ONE_OF,
        ifValue: ['3.1', '3.2', '3.3', '4'],
      },
      {
        ifId: ID.her2_levels,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: '0',
      },
    ],
  },
  {
    q: 'Q60',
    id: ID.treatments,
    type: TREATMENT_HISTORY,
    section: SECTIONS.TREATMENT,
    bgIcon: '',
    order: 0,
    title: 'questionnaire.questions.breast2.22_treatments.title',

    requiredSubQuestionsId: [ID.drugs_received],
    footer: 'questionnaire.questions.breast2.22_treatments.footer',
    info: {
      title: '',
      content: '',
    },
    submitButtonLabel: 'questionnaire.questions.breast2.22_treatments.button.submit',
    options: [
      {
        iconText: 'A',
        label: 'questionnaire.questions.breast2.22_treatments.options.treatment_name',
        value: null,
      },
      {
        iconText: 'B',
        label: 'questionnaire.questions.breast2.22_treatments.options.course_received',
        value: null,
      },
      {
        iconText: 'C',
        label: 'questionnaire.questions.breast2.22_treatments.options.treatment_stopped',
        value: null,
      },
      {
        iconText: 'D',
        label: 'questionnaire.questions.breast2.22_treatments.options.stop_reason',
        value: null,
      },
    ],
    conditions: [
      {
        ifId: ID.treatment_for_breast_cancer,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'no',
      },
    ],
    // shouldAutoOpenSubQuestions: [{
    //   ifId: ID.treatment_for_breast_cancer,
    //   type: EQUAL_TO,
    //   ifValue: 'yes',
    // }],
    isRequired: true,
    subQuestions: [
      {
        q: 'Q60a',
        id: ID.drugs_received,
        type: OPTIONS_FROM_SERVER,
        viewType: OPTIONS_LIST_WITH_SHOW_MORE,
        section: SECTIONS.TREATMENT,
        bgIcon: i.treatment,
        order: 0,
        titleIconText: 'A',
        title: 'questionnaire.questions.breast2.22_treatments.sub_questions.drugs_received.title',
        subtitle: 'questionnaire.questions.breast2.22_treatments.sub_questions.drugs_received.subtitle',
        placeholder: 'questionnaire.questions.drugs_received.placeholder',
        info: {
          title: '',
          content: '',
        },
        questionProps: {
          isSearchableByOptionGroupName: true,
        },
      },
      {
        q: 'Q60f',
        id: ID.cycles,
        type: SINGLE_OPTION_SELECT,
        section: SECTIONS.TREATMENT,
        title: 'questionnaire.questions.breast2.22_treatments.sub_questions.cycles.title',
        disableNextOnOptionSelect: false,
        options: [...treatmentCyclesOptions],
      },
      {
        q: 'Q60b',
        id: ID.treatment_admin,
        type: SINGLE_OPTION_SELECT,
        section: SECTIONS.TREATMENT,
        bgIcon: i.treatment,
        order: 0,
        titleIconText: 'B',
        title: 'questionnaire.questions.breast2.22_treatments.sub_questions.treatment_admin.title',
        subtitle: '',
        info: {
          title: '',
          content: '',
        },
        disableNextOnOptionSelect: true,
        options: [
          {
            label: 'questionnaire.questions.breast2.22_treatments.sub_questions.treatment_admin.options.neoadjuvant',
            value: 'neoadjuvant',
          },
          {
            label: 'questionnaire.questions.breast2.22_treatments.sub_questions.treatment_admin.options.adjuvant',
            value: 'adjuvant',
          },
          {
            label: 'questionnaire.questions.breast2.22_treatments.sub_questions.treatment_admin.options.systemic',
            value: 'systemic',
          },
        ],
        conditions: [],
      },
      {
        q: 'Q60c',
        id: ID.treatment_end_date,
        type: MONTH_PICKER,
        section: SECTIONS.TREATMENT,
        bgIcon: '',
        order: 0,
        titleIconText: 'C',
        title: 'questionnaire.questions.breast2.22_treatments.sub_questions.treatment_end_date.title',
        subtitle: '',
        info: {
          title: '',
          content: '',
        },
        options: [],
        conditions: [],
        dateType: {
          type: dateType.stopTreatment,
        },
      },
      {
        q: 'Q60d',
        id: ID.treatment_stop_reason,
        type: SINGLE_OPTION_SELECT,
        section: SECTIONS.TREATMENT,
        bgIcon: '',
        order: 0,
        titleIconText: 'D',
        title: 'questionnaire.questions.breast2.22_treatments.sub_questions.treatment_stop_reason.title',
        subtitle: '',
        info: {
          title: '',
          content: '',
        },
        disableNextOnOptionSelect: false,
        options: [
          {
            label:
              'questionnaire.questions.breast2.22_treatments.sub_questions.treatment_stop_reason.options.treatment_ongoing',
            value: 'treatment_ongoing',
          },
          {
            label:
              'questionnaire.questions.breast2.22_treatments.sub_questions.treatment_stop_reason.options.end_of_protocol',
            value: 'end_of_protocol',
          },
          {
            label:
              'questionnaire.questions.breast2.22_treatments.sub_questions.treatment_stop_reason.options.progression',
            value: 'progression',
          },
          {
            label:
              'questionnaire.questions.breast2.22_treatments.sub_questions.treatment_stop_reason.options.no_response',
            value: 'no_response',
          },
          {
            label:
              'questionnaire.questions.breast2.22_treatments.sub_questions.treatment_stop_reason.options.loss_of_response',
            value: 'loss_of_response',
          },
          {
            label:
              'questionnaire.questions.breast2.22_treatments.sub_questions.treatment_stop_reason.options.remission',
            value: 'remission',
          },
          {
            label: 'questionnaire.questions.breast2.22_treatments.sub_questions.treatment_stop_reason.options.toxicity',
            value: 'toxicity',
          },
          {
            label: 'questionnaire.questions.breast2.22_treatments.sub_questions.treatment_stop_reason.options.other_medical_conditions',
            value: 'other_medical_conditions',
          },
          {
            label: 'questionnaire.questions.breast2.22_treatments.sub_questions.treatment_stop_reason.options.dosing_issues',
            value: 'dosing_issues',
          },
          {
            label: 'questionnaire.questions.breast2.22_treatments.sub_questions.treatment_stop_reason.options.scheduling',
            value: 'scheduling',
          },
          {
            label: 'questionnaire.questions.breast2.22_treatments.sub_questions.treatment_stop_reason.options.travel_limitation',
            value: 'travel_limitation',
          },
          {
            label: 'questionnaire.questions.breast2.22_treatments.sub_questions.treatment_stop_reason.options.financial_reasons_or_insurance',
            value: 'financial_reasons_or_insurance',
          },
          {
            label: 'questionnaire.questions.breast2.22_treatments.sub_questions.treatment_stop_reason.options.other',
            value: 'other',
          },
        ],
        conditions: [],
      },
    ],
    // title: '',
    // info: {
    //   title: '',
    //   content: '',
    // },
    // footer: '',
    // type: TREATMENT_HISTORY,
    // section: SECTIONS.TREATMENT,
    // bgIcon: i.treatment,
    // order: 0,
    // conditions: [
    //   {
    //     ifId: ID.treatment_for_breast_cancer,
    //     type: NOT_EQUAL_TO_OR_SKIPPED,
    //     ifValue: 'no',
    //   },
    // ],
    // subQuestionsIds: [
    //   ID.treatment_for_advanced_metastatic_disease,
    //   ID.drugs_received,
    //   ID.treatment_stop_reason,
    //   ID.treatment_admin,
    // ],
    stageIds: [ID.patient_stage],
    // surgeryId: ID.had_procedure_for_breast_cancer,
    // options: [],
    // subQuestionsOptions: {
    //   treatment_stop_reason: [
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.no_response',
    //       value: 'no_response',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.end_of_protocol',
    //       value: 'end_of_protocol',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.toxicity',
    //       value: 'toxicity',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.progression',
    //       value: 'progression',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.loss_of_response',
    //       value: 'loss_of_response',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.remission',
    //       value: 'remission',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.other',
    //       value: 'other',
    //     },
    //   ],
    //   taking_state: takingStateOptions,
    //   treatment_admin: [
    //     {
    //       label: 'questionnaire.questions.crc_2.treatments.sub_questions.treatment_admin.options.neoadjuvant',
    //       value: 'neoadjuvant',
    //     },
    //     {
    //       label: 'questionnaire.questions.crc_2.treatments.sub_questions.treatment_admin.options.adjuvant',
    //       value: 'adjuvant',
    //     },
    //     {
    //       label: 'questionnaire.questions.crc_2.treatments.sub_questions.treatment_admin.options.systemic',
    //       value: 'systemic',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatments.options.i_dont_know',
    //       value: 'i_dont_know',
    //     },
    //   ],
    // },
  },
  {
    q: 'Q45',
    id: ID.bone_metastasis_complications,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.breast2.23_bone_metastasis_complications.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.breast2.23_bone_metastasis_complications.options.none',
        value: 'none',
        exclusive: true,
      },
      {
        label: 'questionnaire.questions.breast2.23_bone_metastasis_complications.options.pathological_bone_fractures',
        value: 'pathological_bone_fractures',
      },
      {
        label: 'questionnaire.questions.breast2.23_bone_metastasis_complications.options.hypercalcemia',
        value: 'hypercalcemia',
      },
      {
        label: 'questionnaire.questions.breast2.23_bone_metastasis_complications.options.spinal_cord_compression',
        value: 'spinal_cord_compression',
      },
    ],
    conditions: [
      {
        ifId: ID.disease_location,
        type: CONTAIN_ONE_OF,
        ifValue: ['bone'],
      },
    ],
  },
  {
    q: 'Q44',
    id: ID.current_treatment_systemic_steroids,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.breast2.24_current_treatment_systemic_steroids.title',
    subtitle: 'questionnaire.questions.breast2.24_current_treatment_systemic_steroids.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.breast2.24_current_treatment_systemic_steroids.options.none',
        value: 'none',
      },
      {
        label: 'questionnaire.questions.breast2.24_current_treatment_systemic_steroids.options.under_10_mg_daily',
        value: 'under_10_mg_daily',
      },
      {
        label: 'questionnaire.questions.breast2.24_current_treatment_systemic_steroids.options.over_10_mg_daily',
        value: 'over_10_mg_daily',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q44a',
    id: ID.systemic_steroids_treatment_end_date,
    type: MONTH_PICKER,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.breast2.25_systemic_steroids_treatment_end_date.title',

    info: {
      title: '',
      content: '',
    },
    options: [],
    conditions: [
      {
        ifId: ID.current_treatment_systemic_steroids,
        type: CONTAIN_ONE_OF,
        ifValue: ['under_10_mg_daily', 'over_10_mg_daily'],
      },
    ],
    dateType: {
      type: dateType.stopTreatment,
    },
  },
  {
    q: 'Q57',
    id: ID.ecog,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.breast2.26_ecog.title',
    subtitle: '',
    info: {
      title: 'questionnaire.questions.breast2.26_ecog.info.title',
      content: 'questionnaire.questions.breast2.26_ecog.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.breast2.26_ecog.options.0',
        value: '0',
      },
      {
        label: 'questionnaire.questions.breast2.26_ecog.options.1',
        value: '1',
      },
      {
        label: 'questionnaire.questions.breast2.26_ecog.options.2',
        value: '2',
      },
      {
        label: 'questionnaire.questions.breast2.26_ecog.options.3',
        value: '3',
      },
      {
        label: 'questionnaire.questions.breast2.26_ecog.options.4',
        value: '4',
      },
    ],
    conditions: [],
    isRequired: true,
  },
  {
    q: 'Q46',
    id: ID.other_medical_condition,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.breast2.27_other_medical_condition.title',
    subtitle: 'questionnaire.questions.breast2.27_other_medical_condition.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.breast2.27_other_medical_condition.options.liver',
        value: 'liver',
      },
      {
        label: 'questionnaire.questions.breast2.27_other_medical_condition.options.heart',
        value: 'heart',
      },
      {
        label: 'questionnaire.questions.breast2.27_other_medical_condition.options.kidney',
        value: 'kidney',
      },
      {
        label: 'questionnaire.questions.breast2.27_other_medical_condition.options.autoimmune',
        value: 'autoimmune',
      },
      {
        label: 'questionnaire.questions.breast2.27_other_medical_condition.options.mental',
        value: 'mental',
      },
      {
        label: 'questionnaire.questions.breast2.27_other_medical_condition.options.allergies',
        value: 'allergies',
      },
      {
        label: 'questionnaire.questions.breast2.27_other_medical_condition.options.chronic_infection_or_inflammation',
        value: 'chronic_infection_or_inflammation',
      },
      {
        label: 'questionnaire.questions.breast2.27_other_medical_condition.options.gastrointestinal',
        value: 'gastrointestinal',
      },
      {
        label: 'questionnaire.questions.breast2.27_other_medical_condition.options.other_cancer_type',
        value: 'other_cancer_type',
      },
      {
        label: 'questionnaire.questions.breast2.27_other_medical_condition.options.anemia',
        value: 'anemia',
      },
      {
        label: 'questionnaire.questions.breast2.27_other_medical_condition.options.thyroid',
        value: 'thyroid',
      },
      {
        label: 'questionnaire.questions.breast2.27_other_medical_condition.options.hypertension',
        value: 'hypertension',
      },
      {
        label: 'questionnaire.questions.breast2.27_other_medical_condition.options.epilepsy',
        value: 'epilepsy',
      },
      {
        label: 'questionnaire.questions.breast2.27_other_medical_condition.options.diabetes',
        value: 'diabetes',
      },
      {
        label: 'questionnaire.questions.breast2.27_other_medical_condition.options.organ_transplant',
        value: 'organ_transplant',
      },
      {
        label: 'questionnaire.questions.breast2.27_other_medical_condition.options.eye_condition',
        value: 'eye_condition',
      },
      {
        label: 'questionnaire.questions.breast2.27_other_medical_condition.options.extreme_obesity',
        value: 'extreme_obesity',
      },
      {
        label: 'questionnaire.questions.breast2.27_other_medical_condition.options.stroke',
        value: 'stroke',
      },
      {
        label: 'questionnaire.questions.breast2.27_other_medical_condition.options.lung',
        value: 'lung',
      },
      {
        label: 'questionnaire.questions.breast2.27_other_medical_condition.options.neuropathy',
        value: 'neuropathy',
      },
      {
        label: 'questionnaire.questions.breast2.27_other_medical_condition.options.no_other_health_issues',
        value: 'no_other_health_issues',
        exclusive: true,
      },
    ],
    conditions: [],
  },
  {
    q: 'Q48',
    id: ID.omc_liver,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.breast2.28_omc_liver.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.breast2.28_omc_liver.options.elevated_alt_ast',
        value: 'elevated_alt_ast',
      },
      {
        label: 'questionnaire.questions.breast2.28_omc_liver.options.elevated_bilirubin',
        value: 'elevated_bilirubin',
      },
      {
        label: 'questionnaire.questions.breast2.28_omc_liver.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['liver'],
      },
    ],
  },
  {
    q: 'Q49',
    id: ID.omc_heart,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.breast2.29_omc_heart.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.breast2.29_omc_heart.options.class_at_least_nyha_ii',
        value: 'class_at_least_nyha_ii',
      },
      {
        label: 'questionnaire.questions.breast2.29_omc_heart.options.condition_at_least_grade_2',
        value: 'condition_at_least_grade_2',
      },
      {
        label: 'questionnaire.questions.breast2.29_omc_heart.options.cardiac_ventricular_arrhythmia',
        value: 'cardiac_ventricular_arrhythmia',
      },
      {
        label: 'questionnaire.questions.breast2.29_omc_heart.options.heart_failure',
        value: 'heart_failure',
      },
      {
        label: 'questionnaire.questions.breast2.29_omc_heart.options.lvef_under_50_percent',
        value: 'lvef_under_50_percent',
      },
      {
        label: 'questionnaire.questions.breast2.29_omc_heart.options.qtcf_abnormal',
        value: 'qtcf_abnormal',
      },
      {
        label: 'questionnaire.questions.breast2.29_omc_heart.options.angina',
        value: 'angina',
      },
      {
        label: 'questionnaire.questions.breast2.29_omc_heart.options.myocardial_infarction',
        value: 'myocardial_infarction',
      },
      {
        label: 'questionnaire.questions.breast2.29_omc_heart.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['heart'],
      },
    ],
  },
  {
    q: 'Q50',
    id: ID.omc_stroke_date,
    type: MONTH_PICKER,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.breast2.30_omc_stroke_date.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: '',
        value: '',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['stroke'],
      },
    ],
    dateType: {
      type: dateType.stopTreatment,
    },
  },
  {
    q: 'Q51',
    id: ID.omc_kidney,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.breast2.31_omc_kidney.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.breast2.31_omc_kidney.options.low_glomerular_filtration_rate',
        value: 'low_glomerular_filtration_rate',
      },
      {
        label: 'questionnaire.questions.breast2.31_omc_kidney.options.dialysis',
        value: 'dialysis',
      },
      {
        label: 'questionnaire.questions.breast2.31_omc_kidney.options.kidney_stones',
        value: 'kidney_stones',
      },
      {
        label: 'questionnaire.questions.breast2.31_omc_kidney.options.creatinine_high',
        value: 'creatinine_high',
      },
      {
        label: 'questionnaire.questions.breast2.31_omc_kidney.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['kidney'],
      },
    ],
  },
  {
    q: 'Q52',
    id: ID.omc_autoimmune,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.breast2.32_omc_autoimmune.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.breast2.32_omc_autoimmune.options.congenital_or_acquired_immunosuppression',
        value: 'congenital_or_acquired_immunosuppression',
      },
      {
        label: 'questionnaire.questions.breast2.32_omc_autoimmune.options.hepatitis',
        value: 'hepatitis',
      },
      {
        label: 'questionnaire.questions.breast2.32_omc_autoimmune.options.granulomatous_disease',
        value: 'granulomatous_disease',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['autoimmune'],
      },
    ],
  },
  {
    q: 'Q53',
    id: ID.omc_mental,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.breast2.33_omc_mental.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.breast2.33_omc_mental.options.dementia',
        value: 'dementia',
      },
      {
        label: 'questionnaire.questions.breast2.33_omc_mental.options.neuropsychyatric',
        value: 'neuropsychyatric',
      },
      {
        label: 'questionnaire.questions.breast2.33_omc_mental.options.depression',
        value: 'depression',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['mental'],
      },
    ],
  },
  {
    q: 'Q54',
    id: ID.omc_allergy,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.breast2.34_omc_allergy.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.breast2.34_omc_allergy.options.interferon',
        value: 'interferon',
      },
      {
        label: 'questionnaire.questions.breast2.34_omc_allergy.options.metformin',
        value: 'metformin',
      },
      {
        label: 'questionnaire.questions.breast2.34_omc_allergy.options.vitd',
        value: 'vitd',
      },
      {
        label: 'questionnaire.questions.breast2.34_omc_allergy.options.penicillin',
        value: 'penicillin',
      },
      {
        label: 'questionnaire.questions.breast2.34_omc_allergy.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['allergies'],
      },
    ],
  },
  {
    q: 'Q55',
    id: ID.omc_chronic_infection_inflammation,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.breast2.35_omc_chronic_infection_inflammation.title',
    subtitle: 'questionnaire.questions.breast2.35_omc_chronic_infection_inflammation.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.breast2.35_omc_chronic_infection_inflammation.options.hepatitis',
        value: 'hepatitis',
      },
      {
        label: 'questionnaire.questions.breast2.35_omc_chronic_infection_inflammation.options.hiv',
        value: 'hiv',
      },
      {
        label: 'questionnaire.questions.breast2.35_omc_chronic_infection_inflammation.options.other',
        value: 'other',
      },
      {
        label: 'questionnaire.questions.breast2.35_omc_chronic_infection_inflammation.options.elevated_wbc',
        value: 'elevated_wbc',
      },
      {
        label: 'questionnaire.questions.breast2.35_omc_chronic_infection_inflammation.options.none',
        value: 'none',
        exclusive: true,
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['chronic_infection_or_inflammation'],
      },
    ],
  },
  {
    q: 'Q58',
    id: ID.omc_gastro_problem,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.breast2.36_omc_gastro_problem.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.breast2.36_omc_gastro_problem.options.gastrointestinal_bleeding',
        value: 'gastrointestinal_bleeding',
      },
      {
        label: 'questionnaire.questions.breast2.36_omc_gastro_problem.options.crohns',
        value: 'crohns',
      },
      {
        label: 'questionnaire.questions.breast2.36_omc_gastro_problem.options.colitis',
        value: 'colitis',
      },
      {
        label: 'questionnaire.questions.breast2.36_omc_gastro_problem.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['gastrointestinal'],
      },
    ],
  },
  {
    q: 'Q59',
    id: ID.omc_lung_disease,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.breast2.37_omc_lung_disease.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.breast2.37_omc_lung_disease.options.interstitial_lung_disease',
        value: 'interstitial_lung_disease',
      },
      {
        label: 'questionnaire.questions.breast2.37_omc_lung_disease.options.pneumonitis”',
        value: 'pneumonitis”',
      },
      {
        label: 'questionnaire.questions.breast2.37_omc_lung_disease.options.dyspnea',
        value: 'dyspnea',
      },
      {
        label: 'questionnaire.questions.breast2.37_omc_lung_disease.options.tuberculosis',
        value: 'tuberculosis',
      },
      {
        label: 'questionnaire.questions.breast2.37_omc_lung_disease.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['lung'],
      },
    ],
  },
  {
    q: 'Q62',
    id: ID.omc_eye_condition,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.breast2.38_omc_eye_condition.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.breast2.38_omc_eye_condition.options.cataract',
        value: 'cataract',
      },
      {
        label: 'questionnaire.questions.breast2.38_omc_eye_condition.options.veitis_or_vitritis',
        value: 'veitis_or_vitritis',
      },
      {
        label: 'questionnaire.questions.breast2.38_omc_eye_condition.options.uveitis_or_vitritis',
        value: 'uveitis_or_vitritis',
      },
      {
        label: 'questionnaire.questions.breast2.38_omc_eye_condition.options.conjunctivitis',
        value: 'conjunctivitis',
      },
      {
        label: 'questionnaire.questions.breast2.38_omc_eye_condition.options.glaucoma',
        value: 'glaucoma',
      },
      {
        label: 'questionnaire.questions.breast2.38_omc_eye_condition.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['eye_condition'],
      },
    ],
  },
  {
    q: 'Q56',
    id: ID.omc_abnormal_blood_results,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.breast2.39_omc_abnormal_blood_results.title',
    subtitle: 'questionnaire.questions.breast2.39_omc_abnormal_blood_results.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.breast2.39_omc_abnormal_blood_results.options.none',
        value: 'none',
        exclusive: true,
      },
      {
        label: 'questionnaire.questions.breast2.39_omc_abnormal_blood_results.options.low_wbc',
        value: 'low_wbc',
      },
      {
        label: 'questionnaire.questions.breast2.39_omc_abnormal_blood_results.options.low_hemoglobin',
        value: 'low_hemoglobin',
      },
      {
        label: 'questionnaire.questions.breast2.39_omc_abnormal_blood_results.options.low_potassium',
        value: 'low_potassium',
      },
      {
        label: 'questionnaire.questions.breast2.39_omc_abnormal_blood_results.options.low_magnesium',
        value: 'low_magnesium',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q47',
    id: ID.other_medications,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.breast2.40_other_medications.title',
    subtitle: 'questionnaire.questions.breast2.40_other_medications.subtitle',
    info: {
      title: 'questionnaire.questions.breast2.40_other_medications.info.title',
      content: 'questionnaire.questions.breast2.40_other_medications.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.breast2.40_other_medications.options.cyp3a4_inhibitors',
        value: 'cyp3a4_inhibitors',
      },
      {
        label: 'questionnaire.questions.breast2.40_other_medications.options.immunosuppressive_medications',
        value: 'immunosuppressive_medications',
      },
      {
        label: 'questionnaire.questions.breast2.40_other_medications.options.cyp3a4_inducers',
        value: 'cyp3a4_inducers',
      },
      {
        label: 'questionnaire.questions.breast2.40_other_medications.options.warfarin',
        value: 'warfarin',
      },
      {
        label: 'questionnaire.questions.breast2.40_other_medications.options.anticonvulsants',
        value: 'anticonvulsants',
      },
      {
        label: 'questionnaire.questions.breast2.40_other_medications.options.anti_retroviral_therapy',
        value: 'anti_retroviral_therapy',
      },
      {
        label: 'questionnaire.questions.breast2.40_other_medications.options.none',
        value: 'none',
        exclusive: true,
      },
    ],
    conditions: [],
  },
  initialDiagnosisDate('Q5'),
  userType,
  dateOfBirth,
  {
    q: 'Q2',
    id: ID.gender,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.ABOUT_ME,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.breast2.0_gender.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.breast2.0_gender.options.female',
        value: 'female',
      },
      {
        label: 'questionnaire.questions.breast2.0_gender.options.male',
        value: 'male',
      },
      {
        label: 'questionnaire.questions.global.gender.options.other',
        value: 'other',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q8',
    id: ID.menopausal_status,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.ABOUT_ME,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.breast2.4_menopausal_status.title',

    info: {
      title: 'questionnaire.questions.breast2.4_menopausal_status.info.title',
      content: 'questionnaire.questions.breast2.4_menopausal_status.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.breast2.4_menopausal_status.options.post_menopausal',
        value: 'post_menopausal',
      },
      {
        label: 'questionnaire.questions.breast2.4_menopausal_status.options.pre_menopausal',
        value: 'pre_menopausal',
      },
      {
        label: 'questionnaire.questions.breast2.4_menopausal_status.options.suppression',
        value: 'suppression',
      },
    ],
    conditions: [
      {
        ifId: ID.gender,
        type: EQUAL_TO,
        ifValue: 'female',
      },
      {
        type: AGE_LESS_THAN,
        ifValue: 60,
      },
    ],
  },
  {
    q: 'Q3',
    id: ID.is_pregnant,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.ABOUT_ME,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.breast2.41_is_pregnant.title',

    info: {
      title: 'questionnaire.questions.breast2.41_is_pregnant.info.title',
      content: 'questionnaire.questions.breast2.41_is_pregnant.info.content',
    },
    options: yesAndNoOptions,
    conditions: [
      {
        ifId: ID.gender,
        type: CONTAIN_ONE_OF,
        ifValue: ['female'],
      },
      {
        type: AGE_LESS_THAN,
        ifValue: 50,
      },
      {
        ifId: ID.menopausal_status,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'post_menopausal',
      },
    ],
  },
  {
    q: 'Q4',
    id: ID.is_breastfeeding,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.ABOUT_ME,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.breast2.42_is_breastfeeding.title',

    info: {
      title: 'questionnaire.questions.breast2.42_is_breastfeeding.info.title',
      content: 'questionnaire.questions.breast2.42_is_breastfeeding.info.content',
    },
    options: yesAndNoOptions,
    conditions: [
      {
        ifId: ID.gender,
        type: EQUAL_TO,
        ifValue: 'female',
      },
      {
        type: AGE_LESS_THAN,
        ifValue: 50,
      },
      {
        ifId: ID.menopausal_status,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'post_menopausal',
      },
    ],
  },
  race,
  {
    q: 'Q7',
    id: ID.other_type_bc,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.ABOUT_ME,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.breast2.3_other_type_bc.title',

    info: {
      title: 'questionnaire.questions.breast2.3_other_type_bc.info.title',
      content: 'questionnaire.questions.breast2.3_other_type_bc.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.breast2.3_other_type_bc.options.pagets',
        value: 'pagets',
      },
      {
        label: 'questionnaire.questions.breast2.3_other_type_bc.options.phyllodes',
        value: 'phyllodes',
      },
      {
        label: 'questionnaire.questions.breast2.3_other_type_bc.options.axillary',
        value: 'axillary',
      },
    ],
    conditions: [
      {
        ifId: ID.type_of_bc,
        type: CONTAIN_ONE_OF,
        ifValue: ['other'],
      },
    ],
  },
  haveInsurance,
  insurance,
  preferredLocation,
  travelLimit,
];

export default Questions;
