import orderBy from 'lodash/orderBy';
import { getTranslationFunction } from 'utils/actions/intl';
import { cancerTypes } from 'utils/constants/cancerTypes';
import enumsThatSupportOptionsFromServer from '../constants/enumsThatSupportOptionsFromServer';
import { fixedOptionsValues } from '../constants/fixedOptionsValues';

const deletedOption = ['questionnaire.questions.biomarkers.other',
  'questionnaire.questions.insurances.dont_have',
  'questionnaire.questions.insurances.not_relevant',
  'questionnaire.questions.insurances.international_patient',
  'questionnaire.questions.insurances.other',
  'questionnaire.questions.drugs_received.not_sure',
];
const orderOfDrugCategoriesByIndication = {
  [cancerTypes.BLADDER_2]: {
    'questionnaire.questions.drugs_received_category.intravesical_treatment_chemo': 1,
    'questionnaire.questions.drugs_received_category.intravesical_treatment': 2,
    'questionnaire.questions.drugs_received_category.chemo_platinum': 3,
    'questionnaire.questions.drugs_received_category.chemo': 4,
    'questionnaire.questions.drugs_received_category.immuno': 5,
    'questionnaire.questions.drugs_received_category.bone_targeted': 6,
    'questionnaire.questions.drugs_received_category.targeted': 7,
    'questionnaire.questions.drugs_received_category.other': 8,
  },
};

const orderByLabelsAsc = (options) => orderBy(options, [(option) => option?.label?.toLowerCase()], ['asc']);

const groupBiomarkersOptions = (options, { t }) => {
  const optionsByGroupName = {};
  options.forEach((option) => {
    const groupName = t(option.label).slice(0, 1).toLowerCase();
    if (!optionsByGroupName[groupName]) {
      optionsByGroupName[groupName] = [];
    }
    optionsByGroupName[groupName].push(option);
  });
  const optionGroups = Object.entries(optionsByGroupName).map(([groupName, groupOptions]) => (
    { label: groupName, options: groupOptions }
  ));

  optionGroups.sort((a, b) => (
    (a.label === b.label && 0) || a.label > b.label ? 1 : -1
  ));

  optionGroups.forEach((optionGroup) => {
    // eslint-disable-next-line no-param-reassign
    optionGroup.label = optionGroup.label.toUpperCase();
  });

  return optionGroups;
};

const groupCombinationOptions = (options, { t }) => {
  const optionsByIndication = {};

  options.forEach((option) => {
    // eslint-disable-next-line no-param-reassign
    option = {
      ...option,
      label: t(option.label),
    };
    if (!optionsByIndication[option.indication]) {
      optionsByIndication[option.indication] = [];
    }
    optionsByIndication[option.indication].push(option);
  });

  return optionsByIndication;
};

const groupInsurancesOptions = (options, { t }) => {
  const optionsByGroupName = {};
  options.forEach((option) => {
    const groupName = t(option.label).slice(0, 1).toLowerCase();
    if (!optionsByGroupName[groupName]) {
      optionsByGroupName[groupName] = [];
    }
    optionsByGroupName[groupName].push(option);
  });
  const optionGroups = Object.entries(optionsByGroupName).map(([groupName, groupOptions]) => (
    { label: groupName, options: groupOptions }
  ));

  optionGroups.sort((a, b) => (
    (a.label === b.label && 0) || a.label > b.label ? 1 : -1
  ));

  optionGroups.forEach((optionGroup) => {
    // eslint-disable-next-line no-param-reassign
    optionGroup.label = optionGroup.label.toUpperCase();
  });

  return optionGroups;
};

const groupDrugsReceivedOptionsByDiseases = (options, { t, optionDataByOptionCodesMap }) => {
  const formGroupNameTranslationKey = (drugCategory) => (
    `questionnaire.questions.drugs_received_category.${drugCategory}`
  );

  // works if any category is not specified or category has such code
  const DEFAULT_CATEGORY = 'other';
  const groupedOptionsMapByDiseases = {};
  const defaultOptionsGroupByDiseases = {};

  Object.values(cancerTypes).forEach((cancerType) => {
    groupedOptionsMapByDiseases[cancerType] = new Map();
    defaultOptionsGroupByDiseases[cancerType] = {
      label: formGroupNameTranslationKey(DEFAULT_CATEGORY),
      options: [],
      isDefault: true,
    };
  });

  options.forEach((option) => {
    const optionData = optionDataByOptionCodesMap.get(option.value);
    if (!optionData) {
      // invariant
      return;
    }
    const { categoryByConditions } = optionData;

    Object.values(cancerTypes).forEach((cancerType) => {
      const optionCategory = categoryByConditions?.[cancerType] ?? DEFAULT_CATEGORY;
      if (optionCategory === DEFAULT_CATEGORY) {
        defaultOptionsGroupByDiseases[cancerType].options.push(option);
      } else {
        const groupedOptions = groupedOptionsMapByDiseases[cancerType].get(optionCategory) ?? [];
        groupedOptions.push(option);
        groupedOptionsMapByDiseases[cancerType].set(optionCategory, groupedOptions);
      }
    });
  });

  const groupedOptionsByDiseases = {};
  Object.values(cancerTypes).forEach((cancerType) => {
    const initiallyGroupedOptions = [];
    [...groupedOptionsMapByDiseases[cancerType].entries()].forEach(([optionCategory, groupedOptions]) => {
      initiallyGroupedOptions.push({
        label: formGroupNameTranslationKey(optionCategory),
        options: groupedOptions,
      });
    });

    const labelTranslationByTranslationKeys = {};
    initiallyGroupedOptions.forEach(({ label: labelTranslationKey }) => {
      labelTranslationByTranslationKeys[labelTranslationKey] = t(labelTranslationKey);
    });

    // regroup according to the following rules:
    // 1. if the group label has no translation, then move its options to the default category
    // 2. if the group label have the same translation, then combine its options into one category

    const groupOptionsByGroupLabel = new Map();
    initiallyGroupedOptions.forEach(({ label: labelTranslationKey, options: groupOptions }) => {
      const labelTranslation = labelTranslationByTranslationKeys[labelTranslationKey];
      const hasGroupLabelTranslation = labelTranslationKey !== labelTranslation;
      if (!hasGroupLabelTranslation) {
        defaultOptionsGroupByDiseases[cancerType].options.push(...groupOptions);
        return;
      }

      const currentGroupOptions = groupOptionsByGroupLabel.get(labelTranslation) ?? [];
      currentGroupOptions.push(...groupOptions);

      groupOptionsByGroupLabel.set(
        labelTranslation,
        currentGroupOptions,
      );
    });

    groupedOptionsByDiseases[cancerType] = [];

    [...groupOptionsByGroupLabel.entries()].forEach(([translatedGroupLabel, groupOptions]) => {
      // take any first translation key
      const labelTranslationKey = Object.entries(labelTranslationByTranslationKeys).find(([, labelTranslation]) => (
        labelTranslation === translatedGroupLabel
      ))[0];

      groupedOptionsByDiseases[cancerType].push({
        label: labelTranslationKey,
        options: orderByLabelsAsc(groupOptions),
      });
    });

    groupedOptionsByDiseases[cancerType].sort((a, b) => {
      const orderOfDrugCategory = orderOfDrugCategoriesByIndication[cancerType];
      const aTranslatedLabel = labelTranslationByTranslationKeys[a.label];
      const bTranslatedLabel = labelTranslationByTranslationKeys[b.label];
      return (orderOfDrugCategory
        ? orderOfDrugCategory[a.label] - orderOfDrugCategory[b.label] : (aTranslatedLabel === bTranslatedLabel && 0)
        || aTranslatedLabel > bTranslatedLabel ? 1 : -1

      );
    });

    if (defaultOptionsGroupByDiseases[cancerType].options.length) {
      defaultOptionsGroupByDiseases[cancerType].options = orderByLabelsAsc(
        defaultOptionsGroupByDiseases[cancerType].options,
      );

      groupedOptionsByDiseases[cancerType].push(defaultOptionsGroupByDiseases[cancerType]);
    }
  });

  return groupedOptionsByDiseases;
};

const groupOptionsFunctionByQuestionIds = {
  [enumsThatSupportOptionsFromServer.biomarkers]: groupBiomarkersOptions,
};

const groupOptionsByDiseasesFunctionByQuestionIds = {
  [enumsThatSupportOptionsFromServer.drugs_received]: groupDrugsReceivedOptionsByDiseases,
};

const groupOptionsByCombination = {
  [enumsThatSupportOptionsFromServer.drugs_combinations]: groupCombinationOptions,
};

const groupOptionsInsurancesByQuestionIds = {
  [enumsThatSupportOptionsFromServer.insurances]: groupInsurancesOptions,
};

export const formatOptionsFromServerData = async (data, questionId) => {
  const dropDownOptions = [];
  const radioOptions = [];
  const topSelectedOptionsByDiseases = {};

  const optionDataByOptionCodesMap = new Map();

  for (const item of data) {
    optionDataByOptionCodesMap.set(item.code, item);

    const {
      code: value, name: label, topSelectedForConditions = [], combinations, conditions, indication,
    } = item;

    if (fixedOptionsValues.includes(value)) {
      if (!deletedOption.includes(label)) {
        radioOptions.push({
          label,
          value,
        });
      }
    } else {
      dropDownOptions.push({
        label,
        value,
        combinations,
        conditions,
        indication,
      });
    }

    topSelectedForConditions.forEach((disease) => {
      if (!topSelectedOptionsByDiseases[disease]) {
        topSelectedOptionsByDiseases[disease] = [];
      }
      topSelectedOptionsByDiseases[disease].push({
        label,
        value,
      });
    });
  }

  Object.entries(topSelectedOptionsByDiseases).forEach(([disease, topSelectedOptions]) => {
    topSelectedOptionsByDiseases[disease] = orderByLabelsAsc(topSelectedOptions);
  });

  const sortedDropDownOptions = orderByLabelsAsc(dropDownOptions);

  let groupedOptions;
  const groupOptions = groupOptionsFunctionByQuestionIds[questionId]
    || groupOptionsInsurancesByQuestionIds[questionId]
    || groupOptionsByCombination[questionId];
  if (groupOptions) {
    const t = await getTranslationFunction();
    groupedOptions = groupOptions(sortedDropDownOptions, { t, optionDataByOptionCodesMap });
  }

  let groupedOptionsByDiseases;
  const groupOptionsByDiseases = groupOptionsByDiseasesFunctionByQuestionIds[questionId];
  if (groupOptionsByDiseases) {
    const t = await getTranslationFunction();
    groupedOptionsByDiseases = groupOptionsByDiseases(sortedDropDownOptions, { t, optionDataByOptionCodesMap });
  }

  return {
    options: {
      dropDownOptions: sortedDropDownOptions,
      radioOptions: orderByLabelsAsc(radioOptions),
    },
    topSelectedOptionsByDiseases,
    groupedOptions,
    groupedOptionsByDiseases,
  };
};
