import configs from 'configs';
import request from 'functions/api/request';
import { authHeaders } from 'functions/authHeader';
import getUrlWithQueryString from 'functions/getUrlWithQueryString';

const apiUrl = configs.beApiUrl;

const getConfiguration = async (options) => {
  let user_wix_id = null;
  try {
    user_wix_id = JSON.parse(localStorage.getItem('user')).user_wix_id;
  } catch (e) {}

  const url = getUrlWithQueryString(`${apiUrl}/configuration${user_wix_id ? `/${user_wix_id}` : ''}`, options);

  const requestOptions = {
    url,
    headers: await authHeaders(),
    json: true,
  };

  try {
    return await request.get(requestOptions);
  } catch (ex) {
    console.error('Error occurred, while getting app configuration:', ex);
    throw ex;
  }
};

const setConfiguration = async (configuration) => {
  let user_wix_id = null;
  try {
    user_wix_id = JSON.parse(localStorage.getItem('user')).user_wix_id;
  } catch (e) {}

  const url = getUrlWithQueryString(`${apiUrl}/configuration/${user_wix_id}`);

  const requestOptions = {
    url,
    headers: await authHeaders(),
    data: configuration,
    json: true,
  };

  try {
    return await request.post(requestOptions);
  } catch (ex) {
    console.error('Error occurred, while getting app configuration:', ex);
    throw ex;
  }
};

export const configurationService = {
  getConfiguration,
  setConfiguration,
};
