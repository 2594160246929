import React, { useState } from 'react';
import { useTranslation } from 'utils/modifiedTranslation';
// import useForceUpdate from 'use-force-update';
import PropTypes from 'prop-types';
import checkboxOn from 'new-ui/assets/icons/tick-on.svg';
import checkboxOff from 'new-ui/assets/icons/tick-off.svg';
import arrow from 'new-ui/assets/icons/arrow-circle-black.svg';
import search from 'new-ui/assets/icons/search_big.svg';
import './Select.v2.css';

const SelectV2 = (props) => {
  const {
    options = [],
    onChange,
    multi = false,
    // disabled = false,
    // placeholder = '',
    // autocomplete = false,
    // autocompletePlaceholder = '',
    value = [],
    initOpened = false,
    tabindex,
    staticOptions,
  } = props;

  // const update = useForceUpdate();

  const [opened, setOpened] = useState(initOpened);
  const [values, setValues] = useState(value);

  const { t } = useTranslation();

  const autocompleteChange = (e) => {
    const v = e.target.value;
    Array.from(document.querySelectorAll('.select-v2-option')).forEach(($o) => {
      $o.classList[$o.children[1].innerText.toLowerCase().indexOf(v.toLowerCase()) === -1 ? 'add' : 'remove']('hidden');
    });
  };

  const isOptionSelected = (option) => values.indexOf(option.value) !== -1;

  const selectOption = (option) => {
    let updatedValues;
    if (multi) {
      if (isOptionSelected(option)) {
        updatedValues = values.filter((value) => value !== option.value);
      } else {
        updatedValues = [...values, option.value];
      }
    } else if (isOptionSelected(option)) {
      updatedValues = [];
    } else {
      updatedValues = [option.value];
    }
    setValues(updatedValues);
    onChange(updatedValues);
    if (!multi) setOpened(false);
  };

  const optionKeyDown = (event, o) => {
    if (document.activeElement === event.currentTarget && (event.keyCode === 13)) {
      selectOption(o);
    }
  };

  const renderOptions = () => options.map((o, k) => (
    <div
      tabIndex={tabindex}
      key={k}
      onKeyDown={(event) => { optionKeyDown(event, o); }}
      className={`select-v2-option ${isOptionSelected(o) ? 'select-v2-option-selected' : ''}`}
      onClick={() => { selectOption(o); }}
    >
      <div className="select-v2-option-checkbox">
        <img alt="option" src={isOptionSelected(o) ? checkboxOn : checkboxOff} />
      </div>
      <div className="select-v2-option-title">
        {o?.title || t(o?.label)}
      </div>
    </div>
  ));

  const renderValue = () => values.map((v) => {
    const option = options.filter(
      (a) => a.value === v,
    )[0];
    return (option?.title || t(option?.label));
  }).join(', ');

  const selectKeyDown = (event) => {
    if (document.activeElement === event.currentTarget && (event.keyCode === 13 || event.keyCode === 40)) {
      setOpened(!event.currentTarget.classList.contains('select-v2-opened'));
    }
  };

  return (
    <div onKeyDown={selectKeyDown} className={`select-v2 ${opened ? 'select-v2-opened' : ''} ${values && values.length ? 'select-v2-has-values' : ''}`} tabIndex={tabindex}>
      <div className="select-v2-value-holder" onClick={() => { setOpened(!opened); }}>
        <div className="select-v2-value">
          {renderValue()}
        </div>
        <div className="select-v2-icon">
          <img alt="arrow" src={arrow} />
        </div>
      </div>
      <div className={`select-v2-options-holder ${staticOptions ? 'select-v2-static-options' : ''}`}>
        <div className="select-v2-autocomplete">
          <input tabIndex={tabindex} placeholder={t('questionnaire.questions.global.default.placeholder.typing_here')} onChange={autocompleteChange} />
          <div className="select-v2-autocomplete-icon">
            <img alt="search" src={search} />
          </div>
        </div>
        <div className="select-v2-options">
          {renderOptions()}
        </div>
      </div>
    </div>
  );
};

SelectV2.propTypes = {
  value: PropTypes.any,
  tabindex: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.any,
  multi: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  autocomplete: PropTypes.bool,
  autocompletePlaceholder: PropTypes.string,
  initOpened: PropTypes.bool,
  staticOptions: PropTypes.bool,
};

export default SelectV2;
