import React from 'react';
import './style.css';
import OptionCheckbox from '../../Checkbox';
import { useTranslation } from "utils/modifiedTranslation";
import IntInterventionalType from '../../OptionTypes/IntInterventionalTypes';

const MultipleTypes = ({
    onOptionChange, 
    category_1,
    category_2,
    category_3,
    options_1,
    options_2,
    options_3,
    selectedOptions_1,
    selectedOptions_2,
    selectedOptions_3,
    onCancel
}) => {
    const { t } = useTranslation();
    
    const handleCheckboxChange_2 = (value, _category, _selectedOptions) => {
        const isChecked = _selectedOptions.includes(value);
        onOptionChange(_category, value, !isChecked);
    };

    return (
        <div className='multiple-types-wrapper'>
            {category_1 && <IntInterventionalType 
                category={category_1}
                options={options_1}
                selectedOptions={selectedOptions_1}
                onOptionChange={onOptionChange}
                withSelectAll={true}
            />}

            <div className='options phase'>
                <h3 className='title'>{t("treatment_options.option.title.clinical_trial_phase")}</h3>
                <ul>
                    {options_3.map((option) => (
                        <li key={option.value}>
                            <OptionCheckbox
                                label={option.label}
                                checked={selectedOptions_3.includes(option.value)}
                                onChange={() => {
                                    handleCheckboxChange_2(option.value, category_3, selectedOptions_3);
                                }}
                            />
                        </li>
                    ))}
                </ul>
            </div>

            <div className='options administration_method'>
                <h3 className='title'>{t("treatment_options.option.title.administration_method")}</h3>
                <ul>
                    {options_2.map((option) => (
                        <li key={option.value}>
                            <OptionCheckbox
                                label={option.label}
                                checked={selectedOptions_2.includes(option.value)}
                                onChange={() => {
                                    handleCheckboxChange_2(option.value, category_2, selectedOptions_2);
                                }}
                            />
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default MultipleTypes;