// All the names are synced with this document:
// https://docs.google.com/document/d/1qat7pGpBG9cExT8_hLuxHVbwVGX-M-m_a33nx7XipVc/edit?usp=sharing

import clickDownloadAndShareOnMinisite from 'functions/analytics/functions/clickDownloadAndShareOnMinisite';
import clickNextOnAQuestion from 'functions/analytics/functions/clickNextOnAQuestion';
import downloadPdfFromPopup from 'functions/analytics/functions/downloadPdfFromPopup';
import geneticTestingEvents from 'functions/analytics/functions/geneticTesting';
import matchesPageChangeLocation from 'functions/analytics/functions/matchesPageChangeLocation';
import matchesPageEmailUs from 'functions/analytics/functions/matchesPageEmailUs';
import matchesPagePopupScheduleTime from 'functions/analytics/functions/matchesPagePopupScheduleTime';
import matchesPageReturningUserUpdateProfileFromBanner from 'functions/analytics/functions/matchesPageReturningUserUpdateProfileFromBanner';
import matchesPageReturningUserUpdateProfileFromPopup from 'functions/analytics/functions/matchesPageReturningUserUpdateProfileFromPopup';
import matchesPageScheduleTime from 'functions/analytics/functions/matchesPageScheduleTime';
import matchesPageShareWithDoctor from 'functions/analytics/functions/matchesPageShareWithDoctor';
import matchesPageSortRank from 'functions/analytics/functions/matchesPageSortRank';
import matchesPageSortTreatmentType from 'functions/analytics/functions/matchesPageSortTreatmentType';
import matchesPageSpeakWithTrialOncologist from 'functions/analytics/functions/matchesPageSpeakWithTrialOncologist';
import matchesPageTrialStudyDetails from 'functions/analytics/functions/matchesPageTrialStudyDetails';
import matchesPageUserUpdateProfile from 'functions/analytics/functions/matchesPageUserUpdateProfile';
import openCalendlyToScheduleCallOnMinisite from 'functions/analytics/functions/openCalendlyToScheduleCallOnMinisite';
import seeMoreModalOpenTrial from 'functions/analytics/functions/seeMoreModalOpenTrial';
import seeMoreModalViewFaq from 'functions/analytics/functions/seeMoreModalViewFaq';
import sendToDoctorFromPopup from 'functions/analytics/functions/sendToDoctorFromPopup';
import sideMenuLearnMore from 'functions/analytics/functions/sideMenuLearnMore';
import sideMenuSentToDoctor from 'functions/analytics/functions/sideMenuSentToDoctor';
import sideMenuTreatmentFinder from 'functions/analytics/functions/sideMenuTreatmentFinder';
import sideMenuVideo from 'functions/analytics/functions/sideMenuVideo';
import signUpViewYourTrials from 'functions/analytics/functions/signUpViewYourTrials';
import signUpEmail from 'functions/analytics/functions/signUpEmail';
import signUpFacebook from 'functions/analytics/functions/signUpFacebook';
import signUpGoogle from 'functions/analytics/functions/signUpGoogle';
import signUpSMS from 'functions/analytics/functions/signUpSMS';
import trialPopupSeeMore from 'functions/analytics/functions/trialPopupSeeMore';
import trialPopupSpeakWithOncologist from 'functions/analytics/functions/trialPopupSpeakWithOncologist';
import trialsListTrialItem from 'functions/analytics/functions/trialsListTrialItem';
import userIsMovedToTheNextSection from 'functions/analytics/functions/userIsMovedToTheNextSection';
import viewQuestionsPopupSpeakWithOncologist from 'functions/analytics/functions/viewQuestionsPopupSpeakWithOncologist';
import whatNextPopupDoctor from 'functions/analytics/functions/whatNextPopupDoctor';
import whatNextPopupLearnMore from 'functions/analytics/functions/whatNextPopupLearnMore';
import whatNextPopupTreatmentFinder from 'functions/analytics/functions/whatNextPopupTreatmentFinder';
import quickSignUpBtnViewed from 'functions/analytics/functions/quickSignUpBtnViewed';
import quickSignUpLoginPageViewed from 'functions/analytics/functions/quickSignUpLoginPageViewed';
import quickSignUpLoginSucceed from 'functions/analytics/functions/quickSignUpLoginSucceed';
import quickSignUpStarted from 'functions/analytics/functions/quickSignUpStarted';
import quickSignUpSucceed from 'functions/analytics/functions/quickSignUpSucceed';
import quickSingupModalViewed from 'functions/analytics/functions/quickSingupModalViewed';
import { sendFbEventToConversionApi } from 'functions/analytics/functions/sendFbEventToConversionApi';

export default {
  quickSignUpSucceed,
  quickSignUpStarted,
  quickSignUpLoginSucceed,
  quickSignUpLoginPageViewed,
  quickSignUpBtnViewed,
  clickDownloadAndShareOnMinisite,
  clickNextOnAQuestion,
  downloadPdfFromPopup,
  geneticTestingEvents,
  matchesPageChangeLocation,
  matchesPageEmailUs,
  matchesPagePopupScheduleTime,
  matchesPageReturningUserUpdateProfileFromBanner,
  matchesPageReturningUserUpdateProfileFromPopup,
  matchesPageScheduleTime,
  matchesPageShareWithDoctor,
  matchesPageSpeakWithTrialOncologist,
  matchesPageSortRank,
  matchesPageSortTreatmentType,
  matchesPageTrialStudyDetails,
  matchesPageUserUpdateProfile,
  openCalendlyToScheduleCallOnMinisite,
  seeMoreModalOpenTrial,
  seeMoreModalViewFaq,
  sendToDoctorFromPopup,
  sideMenuLearnMore,
  sideMenuSentToDoctor,
  sideMenuTreatmentFinder,
  sideMenuVideo,
  signUpViewYourTrials,
  trialPopupSeeMore,
  trialPopupSpeakWithOncologist,
  trialsListTrialItem,
  userIsMovedToTheNextSection,
  viewQuestionsPopupSpeakWithOncologist,
  whatNextPopupDoctor,
  whatNextPopupLearnMore,
  whatNextPopupTreatmentFinder,
  signUpEmail,
  signUpSMS,
  signUpGoogle,
  signUpFacebook,
  sendFbEventToConversionApi,
  quickSingupModalViewed,
};
