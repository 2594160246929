import React from 'react';
import './style.css';

const ProgressBar = ({ percentage }) => {
  const greenWidth = `${percentage}%`;
  const redWidth = `${100 - percentage}%`;

  return (
    <div className="progress-bar">
      <div className="progress-green" style={{ width: greenWidth }}></div>
      <div className="progress-red" style={{ width: redWidth }}></div>
      <div className="progress-text">{percentage}%</div>
    </div>
  );
};

export default ProgressBar;