/* eslint-disable import/no-cycle */
/* eslint-disable class-methods-use-this */
import app from 'new-ui/app';
import api from 'new-ui/api';
import { AUDIT_COMMENTS, PAGES, URI } from 'new-ui/constants';

import './Provider.css';

const REDIRECT_URL = `${URI[PAGES.RESULTS]}?rematch=1`;

class ProviderLogic {
  async detach(auditComment = AUDIT_COMMENTS.DEFAULT, simpleMode = false) {
    if (simpleMode) {
      await api.detachUserProvider({ auditComment });
      return;
    }
    app.setShowPreloader(true);
    await api.detachUserProvider({ auditComment });
    app.setShowPreloader(false);
    window.location = REDIRECT_URL;
  }

  setPopup(providerData) {
    app.user.providersPopup = providerData;
  }

  setTheme(providerData) {
    console.log('set provider theme', providerData);
    document.body.classList.add('provider-mode');
  }
}

const Provider = new ProviderLogic();

window.__Provider = Provider; // for debug

export default Provider;
