import { BIOMARKERS } from '../constants/questionTypes';

export const mergeFormQuestionValues = (questionType, ...values) => {
  switch (questionType) {
    case BIOMARKERS: {
      const selectedOptionsMap = new Map(
        values.filter((i) => !!i).flat().map((item) => [item.value, item]),
      );

      return [...selectedOptionsMap.values()];
    }
    default:
      console.error(`mergeFormQuestionValues doesn't support ${questionType} question type`);
      return values[0];
  }
};
