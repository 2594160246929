export default [
  // common questions
  'user_type',
  'race',
  'has_biopsy',
  'biopsy_bladder_tumor',
  'biopsy_metastatic_disease',
  'have_biopsy',
  'ecog',
  'preferred_location',

  // specified questions which defines if user receives any treatments
  'treatment_for_brain_cancer',
  'treatment_for_breast_cancer',
  'received_any_treatments',
  'treatment_for_lung_cancer',
  'treatment_for_melanoma',
  'treatment_for_mm',
  'treatment_for_mpn',
  'treatment_for_lymphoma',
  'treatment_for_uterine_cancer',
  'treatment_for_crc_cancer',
  'treatment_for_prostate_cancer',
  'treatment_for_aml',
  'treatment_for_hnscc',
  'treatment_for_bladder_cancer',
  'treatment_for_cll_cancer',
  'treatment_for_gi_tract_cancer',
  'treatment_for_ovarian_cancer',
  'treatment_for_liver',
];
