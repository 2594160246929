import React from 'react';
import './style.css';
import { useTranslation } from 'utils/modifiedTranslation';

const StableConditionFeedback = () => {
    const { t } = useTranslation();
    return (
        <div className='condition-feedback'>
            <h3 className='titles'>{t('experience_widget.stable_condition_title')}</h3>
            <div className='description'>{t('experience_widget.stable_condition_description')}</div>
        </div>
    );
};

export default StableConditionFeedback;