import LANGUAGE from './constants/language';
import LOCAL_STORAGE_KEYS from '../../utils/constants/localStorageKeys';
import { isSuperUser } from '../../domains/User/isSuperUser';

export const getFallbackLng = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const langFromQueryParam = urlParams.get('lang');

  const alreadySetLang = langFromQueryParam || localStorage.getItem(LOCAL_STORAGE_KEYS.LANGUAGE);
  const supportedLanguages = Object.keys(LANGUAGE);

  if (isSuperUser() || !alreadySetLang || !supportedLanguages.includes(alreadySetLang)) {
    return LANGUAGE.en.SHORT;
  }

  return alreadySetLang;
};
