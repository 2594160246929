import React, { useEffect, useState } from 'react';
import './Signup.css';
import Form, { FORM_INPUT_TYPES } from 'new-ui/Components/Form';
import app from 'new-ui/app';
import { useTranslation } from 'utils/modifiedTranslation';
import { getIndications } from 'new-ui/api';
import PropTypes from 'prop-types';
import PopupButton from 'new-ui/Components/Popup/PopupButton';
import { getInitialConditionProfileByIndication, signup } from 'new-ui/functions';
import useRecaptcha from 'utils/hooks/useRecaptcha';
import Preloader from 'new-ui/Components/Preloader';

const GA = {
  category: 'SIGNUP_POPUP',
  events: {
    success: 'signup_success',
    error: 'signup_error',
  },
};

let indications;

const Signup = (props) => {
  const {
    data = {},
    buttonText = 'Save',
    onSignup = () => {},
    title,
  } = props;
  const { t } = useTranslation();
  const [form, setForm] = useState(null);
  const [error, setError] = useState(null);
  const { recaptcha, getRecaptchaTokenAsync } = useRecaptcha();

  useEffect(() => {
    const init = async () => {
      // all_biomarkers = await getBiomarkers();
      indications = await getIndications();
      const _form = {
        inputs: {
          email: {
            type: FORM_INPUT_TYPES.INPUT,
            required: true,
            icon: 'email',
            title: 'Email',
            placeholder: '',
          },
          indication: {
            title: 'Select type',
            required: true,
            type: FORM_INPUT_TYPES.SELECT_V2,
            options: indications.map((i) => ({
              title: i.title.indexOf('disease_name') !== -1 ? t(i.title) : i.title,
              value: i.value,
            })),
          },
        },
        data: {
          email: '',
          indication: data?.info?.condition ? [data.info.condition] : [],
        },
      };
      if (data.info.condition) _form.inputs.indication.hidden = true;
      setForm(_form);
    };

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formChange = (f) => {
    // console.log(f);
  };

  const doSignup = async () => {
    if (!form.data.email || !form.data.indication.length) {
      setError('Email and Condition fields are required');
      return;
    }
    const indication = form.data.indication[0];
    setError(null);
    // error check
    app.setShowPreloader(true);
    const condition_profile = await getInitialConditionProfileByIndication(indication, data?.condition_profile || {});
    console.log('signup popup', condition_profile);
    signup({
      email: form.data.email,
      indication,
      recaptchaToken: await getRecaptchaTokenAsync(),
      condition_profile,
      info: data?.info || {},
      onsignup: (user) => {
        console.log('user signup success', user);
        app.intercom.updateUserAttributes({
          email: form.data.email,
          condition: form.data.indication[0],
        }, user.info.user_wix_id);
        app.sendGoogleAnalyticsEvent(GA.category, GA.events.success, { name: 'user_signup_success' });
        app.setShowPreloader(false);
        onSignup(user);
      },
      onerror: (e) => {
        app.setShowPreloader(false);
        setError('Signup Error');
        app.sendGoogleAnalyticsEvent(GA.category, GA.events.error, { name: 'user_signup_error' });
      },
    });
  };

  return (
    <div className="signup-popup">
      {recaptcha}
      { form ? (
        <>
          { error ? (
            <div className="signup-error">{error}</div>
          ) : null}
          <div className="signup-title">{title || t('signup.title')}</div>
          <Form form={form} onChange={formChange} />
          <PopupButton title={buttonText} action={doSignup} />
        </>
      ) : (
        <div className="signup-preloader">
          <Preloader isVisible />
        </div>
      )}
    </div>
  );
};

Signup.propTypes = {
  data: PropTypes.any,
  buttonText: PropTypes.string,
  title: PropTypes.string,
  onSignup: PropTypes.func,
};

export default Signup;
