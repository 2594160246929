import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { CRM_CHAT_KEY, DEFAULT_CRM_CHAT_SETTINGS } from './constants';
import api from './api';

/**
 * Zendesk Widget Classic
 * @param props
 * @returns {null}
 * @constructor
 */
const CrmChat = (props) => {
  const {
    defer, visible, onLoaded, getToken, ...settings
  } = props;
  const updateVisibility = () => {
    if (visible) {
      api.showLauncher();
    } else {
      api.hideLauncher();
    }
  };

  useEffect(() => {
    // Did mount
    if (!window.zE) {
      api.insertScript(defer, CRM_CHAT_KEY, () => {
        updateVisibility();
        if (typeof onLoaded === 'function') {
          onLoaded();
        }
      });
      window.zESettings = {
        ...DEFAULT_CRM_CHAT_SETTINGS,
        authenticate: {
          chat: {
            jwtFn: (callback) => {
              getToken().then((token) => callback(token));
            },
          },
        },
        ...settings,
      };
      if (visible) {
        window.zESettings.webWidget.chat.suppress = false;
      }
    }

    // Will unmount
    return () => {
      api.hideLauncher();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateVisibility();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return null;
};

CrmChat.propTypes = {
  defer: PropTypes.bool,
  visible: PropTypes.bool,
  getToken: PropTypes.func,
};

export default CrmChat;

export const CrmChatAPI = api;
