import React from 'react';
import './Intake.css';

import app from 'new-ui/app';
import PropTypes from 'prop-types';

import Intake from 'new-ui/Components/Intake';
import { PopupContent, PopupTitle } from 'new-ui/Components/Popup';

const IntakePopup = (props)=>{
  const {
    user = app.user,
    part,
  } = props;
  return (
    <div className="intake-popup">
      <PopupTitle title="About You" />
      <PopupContent>
        <Intake user={user} part={part} />
      </PopupContent>
    </div>
  );
};

IntakePopup.propTypes = {
  user: PropTypes.any,
  part: PropTypes.string,
};

export default IntakePopup;
