import serverStatusConstants from '../constants/serverStatus';
import { checkSeverUptime } from '../../functions/api/serverStatus';

const checkServerStatus = () => async dispatch => {
  dispatch({ type: serverStatusConstants.SERVER_STATUS_REQUEST });

  try {
    await checkSeverUptime();

    dispatch({ type: serverStatusConstants.SERVER_STATUS_SUCCESS });
  } catch (error) {
    console.error("Error checking the server's status", error);
    dispatch({
      type: serverStatusConstants.SERVER_STATUS_FAILURE,
      payload: {
        error: error.message
      }
    });
  }
};

export const serverStatusActions = {
  checkServerStatus
};
