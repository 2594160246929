import { BACKDROP_CONSTANTS } from './constants';

export const showBackdrop = ({ type: nextType }) => (dispatch, getState) => {
  const { backdrop: { shownAt, type, isShown } } = getState();

  const nextShownAt = isShown && type === nextType ? shownAt : Date.now();

  dispatch({ type: BACKDROP_CONSTANTS.SHOW_BACKDROP, payload: { shownAt: nextShownAt, type: nextType } });
};

export const hideBackdrop = ({ minDisplayTime = 0, callback = () => {} } = {}) => (dispatch, getState) => {
  const { backdrop: { shownAt } } = getState();

  const displayTime = Date.now() - (shownAt ?? 0);

  if (displayTime >= minDisplayTime) {
    dispatch({ type: BACKDROP_CONSTANTS.HIDE_BACKDROP });
    callback();
  } else {
    setTimeout(() => {
      dispatch({ type: BACKDROP_CONSTANTS.HIDE_BACKDROP });
      callback();
    }, minDisplayTime - displayTime);
  }
};
