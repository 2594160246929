import { PRISTINE, PENDING, FAILURE, SUCCESS } from 'utils/constants/queryStatuses';
import userConstants from 'components/Authentication/constants';
import {
  APP_CONFIGURATION_REQUEST,
  APP_CONFIGURATION_SUCCESS,
  APP_CONFIGURATION_FAILURE,
} from './constants';

const initialState = {
  status: PRISTINE,
  providerSpecific: {},
};

export function appConfiguration(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case APP_CONFIGURATION_REQUEST:
      return {
        ...state,
        status: PENDING,
      };
    case APP_CONFIGURATION_SUCCESS:
      return {
        ...state,
        ...payload,
        status: SUCCESS,
      };
    case APP_CONFIGURATION_FAILURE:
      return {
        ...state,
        status: FAILURE,
      };
    case userConstants.LOGOUT:
      return initialState;
    default:
      return state;
  }
}
