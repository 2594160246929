import React, { useState } from 'react';
import './Debug.css';

import app from 'new-ui/app';

import DebugPopup from 'new-ui/Popups/Debug';
import Popup1 from '../Popup';

const Debug = () => {
  const { user } = app;
  const [debugPopup, setDebugPopup] = useState(false);

  const isSuperUser = localStorage.getItem('superuser') === 'true';
  return (
    <>
      { isSuperUser ? (
        <div className="debug-box" onClick={() => { setDebugPopup(true); }}>
          <table>
            <tr>
              <td>email</td>
              <td>{user.info.email}</td>
            </tr>
            <tr>
              <td>user_wix_id</td>
              <td>{user.info.user_wix_id}</td>
            </tr>
          </table>
        </div>
      ) : null }
      <Popup1 className="debug" isVisible={debugPopup} closePopup={() => { setDebugPopup(false); }}>
        <DebugPopup />
      </Popup1>
    </>
  );
};

export default Debug;
