import React, { useState, useEffect } from 'react';
import './Pager.css';

import arrowCircle from 'new-ui/assets/icons/arrow-circle.svg';

import PropTypes from 'prop-types';

/**
 * Pager
 * @param {*} props
 * @param arrowsData {
 *    exclude: [0,1]
 *    lastPageAction: ()=>{}
 * }
 * @returns
 */

const Pager = (props) => {
  const {
    pages,
    controller = {},
    mandatory = [],
    showArrows,
    lastPageAction,
    page = 1,
  } = props;

  const [currentPage, setCurrentPage] = useState(page);

  const gotoPage = (pageNum) => {
    setCurrentPage(pageNum);
  };

  controller.gotoPage = gotoPage;

  window.__gotoPage = gotoPage;

  useEffect(() => {
    if (controller.onSetPage) controller.onSetPage(currentPage);
    // eslint-disable-next-line
  }, [currentPage]);

  const renderArrows = (isNext = false)=>{
    if (!showArrows) return;
    let hidden = false;
    const isLast = currentPage === pages.length;
    if (!isNext && currentPage === 1) hidden = true;
    if (isNext && isLast && !lastPageAction) hidden = true;
    if (mandatory.includes(currentPage)) hidden = true;
    return (
      <img
        className={`pager-arrows pager-arrows-${isNext ? 'next' : 'prev'} ${hidden ? 'pager-arrows-hidden' : ''}`}
        alt="arrow"
        src={arrowCircle}
        onClick={()=>{
          if (isNext) {
            if (isLast && lastPageAction) return lastPageAction();
            gotoPage(currentPage + 1);
          } else {
            gotoPage(currentPage - 1);
          }
        }}
      />
    );
  };

  return (
    <div className="pager">
      <div className="pager-index">
        { renderArrows() }
        {pages.map((p, i) => (
          <div
            className={`pager-index-button ${((currentPage - 1) === i || currentPage - 1 >= i) ? 'pager-selected' : ''} ${mandatory.includes(currentPage) ? 'pager-disabled' : ''}`}
            key={i}
            onClick={() => { gotoPage(i + 1); }}
          />
        ))}
        { renderArrows(true) }
      </div>
      <div className="pager-pages">
        <div className="pager-pages-strip" style={{ left: `${-((currentPage - 1) * 100)}%` }}>
          {pages.map((page, i) => (
            <div className={`pager-page ${currentPage - 1 !== i ? 'pager-page-hidden' : ''}`} key={i}>
              {page}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

Pager.propTypes = {
  controller: PropTypes.object,
  pages: PropTypes.array,
  page: PropTypes.number,
  showArrows: PropTypes.bool,
  mandatory: PropTypes.array,
  lastPageAction: PropTypes.func,
};

export default Pager;
