export const mock_lung_extraction = {
  condition_profile: {
    biomarkers: [
      'hgf',
      'pik3ca',
      'egfr',
      'nbn',
    ],
    current_stage: null,
    disease_location: [
      'lungs',
    ],
    disease_status: null,
    drugs_received: null,
    qstack: [
      'dob',
      'gender',
      'biomarkers',
      'family_name',
      'current_stage',
      'type_of_nsclc',
      'disease_status',
      'drugs_received',
      'disease_location',
      'type_of_lung_cancer',
      'treatment_for_lung_cancer',
      'treatment_for_advanced_metastatic_disease',
    ],
    treatment_for_advanced_metastatic_disease: null,
    treatment_for_lung_cancer: null,
    type_of_lung_cancer: null,
    type_of_nsclc: null,
  },
  info: {
    family_name: 'Ho, Suet',
  },
  personal: {
    condition: 'lung',
    dob: '1964-03-08',
    gender: 'female',
  },
};
