export default {
  landingPageUrl: 'https://leal.health',
  colors: {
    orange: '#0C9BED',
    green: '#4CB6AC',
  },
  ones: {
    trial: 'trial',
    trials: 'trials',
  },
  footer: {
    copyright: {
      year: new Date().getFullYear(),
      text: 'footer.copyright',
    },
    contactUs: {
      text: 'footer.contact_us',
    },
    email: 'clinicalteam_us@trialjectory.com',
    widget: {
      openMessage: 'footer.widget.open_message',
    },
  },
  preMatches: {
    title: "You're about to view your matched trials",
    weFound: 'We found',
    forYou: 'for you',
    whatsNext: "What's next?",
    whatsNextBody: 'Our clinical team will contact you within 72 hours by email. \n Please verify your address below.',
    yourName: 'Your name',
    yourContactEmail: 'Your contact email',
    yourPhone: 'Can we contact you by phone?',
    viewYourMatches: 'View your matched trials',
  },
  matches: {
    applyToAll: 'matches.apply_to_all',
  },
  serverDown: {
    errorMessage: 'Whoops... something is not working well',
    solutionMessage: {
      textWithoutLink: 'We are working to solve the problem. In the meantime, you can learn about us on our',
      textWithLink: 'site',
    },
  },
  errors: {
    inactiveProfile: {
      errorMessage: 'errors.inactive_profile.error_message',
      solutionMessage: 'errors.inactive_profile.solution_message',
    },
  },
};

