import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';
import 'core-js/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import Spinner from './components/Spinner';
import * as serviceWorker from './serviceWorker';
import { store, persistor } from './utils/store';
import configs from './configs';

import './index.css';
import './functions/nodeMutationObserver';

Sentry.init({
  dsn: configs.sentry.dsn,
  environment: configs.reactAppStage,
});

const gaInit = (code) => {
  ReactGA.initialize(code, {
    gaOptions: { cookieFlags: 'SameSite=None; Secure' },
  });
};

if (!configs.isLocal && !configs.preventGATracking) {
  gaInit(configs.gaTrackingCode);
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<Spinner />} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
