import configs from 'configs';
import { authHeaders } from 'functions/authHeader';
import handleResponse from 'functions/handleResponse';
// import { encodeURIComponent } from 'new-ui/functions';
// import { treatmentExperienceMock, treatmentRelatedTrialsMock } from 'new-ui/mock';
import * as queryString from 'query-string';

const apiUrl = configs.beApiUrl;

export const fetchSponsoredTreatment = async (nctId, nctNumber) => {
  const requestOptions = {
    method: 'GET',
    headers: await authHeaders(),
  };

  const url = `${apiUrl}/public/sponsoredTreatment/templateData/${encodeURIComponent(nctId)}?${queryString.stringify({
    nctNumber,
  })}`;

  return fetch(url, requestOptions)
    .then(handleResponse)
    .catch((ex) => {
      console.error('Error occurred, while getting profile data:', ex);
    });
};

export const fetchTreatmentExperience = async (nctNumber) => {
  const requestOptions = {
    method: 'GET',
    // headers: await authHeaders(),
  };

  const url = `${apiUrl}/profile/matches/${encodeURIComponent(nctNumber)}/experience`;

  // return treatmentExperienceMock;

  return fetch(url, requestOptions)
    .then(handleResponse)
    .catch((ex) => {
      console.error('Error occurred, while getting profile data:', ex);
    });
};

export const fetchTreatmentRelated = async (nctNumber) => {
  const requestOptions = {
    method: 'GET',
    headers: await authHeaders(),
  };

  const url = `${apiUrl}/profile/trials/related/${encodeURIComponent(nctNumber)}`;

  // return treatmentRelatedTrialsMock;

  return fetch(url, requestOptions)
    .then(handleResponse)
    .catch((ex) => {
      console.error('Error occurred, while getting profile data:', ex);
    });
};
