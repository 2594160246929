import { customPaths } from 'utils/constants/customPaths';
import {
  MONTH_PICKER,
  SINGLE_OPTION_SELECT,
  NUMBER_INPUT,
  MULTIPLE_OPTION_SELECT,
  BIOMARKERS,
} from '../../constants/questionTypes';
import { Profile as ID } from './CrcProfile.enum';
import ConditionTypes from '../../constants/conditions';
import i from '../../constants/icons';
import dateType from '../../../../utils/constants/dateTypes';
import numberType from '../../../../utils/constants/numberTypes';
import {
  preferredLocation, travelLimit, userType, lookingFor, race,
  initialDiagnosisDate,
} from '../../constants/globalQuestions';
import { notSureOption } from '../../constants/globalOptions';
import SECTIONS from '../../constants/questionSections';
import questionOptions from '../../constants/questionOptions';
import GLOBAL_ENUMS from '../../constants/globalEnums';

const { biomarkers: BIOMARKERS_QUESTION_ID } = GLOBAL_ENUMS;

const noneOption = questionOptions.NONE;

const {
  NOT_EQUAL_TO_OR_SKIPPED,
  EQUAL_TO,
  CONTAIN_ONE_OF,
  AGE_LESS_THAN,
  DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
} = ConditionTypes;

const stopReasonConditions = [
  {
    label: 'questionnaire.questions.crc.stop_reason_conditions.not stop',
    value: 'not stop',
  },
  {
    label: 'questionnaire.questions.crc.stop_reason_conditions.remission',
    value: 'remission',
  },
  {
    label: 'questionnaire.questions.crc.stop_reason_conditions.disease progression',
    value: 'disease progression',
  },
  {
    label: 'questionnaire.questions.crc.stop_reason_conditions.toxicity',
    value: 'toxicity',
  },
  {
    label: 'questionnaire.questions.crc.stop_reason_conditions.other',
    value: 'other',
  },
];

const Questions = [
  {
    q: 'Q65',
    id: ID.current_disease_status,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.crc.3_current_disease_status.title',

    info: {
      title: 'questionnaire.questions.crc.3_current_disease_status.info.title',
      content: 'questionnaire.questions.crc.3_current_disease_status.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.crc.3_current_disease_status.options.disease_free',
        value: 'disease_free',
      },
      {
        label: 'questionnaire.questions.crc.3_current_disease_status.options.residual_disease',
        value: 'residual_disease',
      },
      {
        label: 'questionnaire.questions.crc.3_current_disease_status.options.metastatic_disease',
        value: 'metastatic_disease',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q6',
    id: ID.diagnosis_stage,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.crc.4_diagnosis_stage.title',

    info: {
      title: 'questionnaire.questions.crc.4_diagnosis_stage.info.title',
      content: 'questionnaire.questions.crc.4_diagnosis_stage.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.crc.4_diagnosis_stage.options.1',
        value: '1',
      },
      {
        label: 'questionnaire.questions.crc.4_diagnosis_stage.options.2',
        value: '2',
      },
      {
        label: 'questionnaire.questions.crc.4_diagnosis_stage.options.3.1',
        value: '3.1',
      },
      {
        label: 'questionnaire.questions.crc.4_diagnosis_stage.options.3.2',
        value: '3.2',
      },
      {
        label: 'questionnaire.questions.crc.4_diagnosis_stage.options.3.3',
        value: '3.3',
      },
      {
        label: 'questionnaire.questions.crc.4_diagnosis_stage.options.4',
        value: '4',
      },
      {
        label: 'questionnaire.questions.crc.4_diagnosis_stage.options.999',
        value: '999',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q7',
    id: ID.type_of_crc,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.crc.5_type_of_crc.title',

    info: {
      title: 'questionnaire.questions.crc.5_type_of_crc.info.title',
      content: 'questionnaire.questions.crc.5_type_of_crc.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.crc.5_type_of_crc.options.colon',
        value: 'colon',
      },
      {
        label: 'questionnaire.questions.crc.5_type_of_crc.options.rectal',
        value: 'rectal',
      },
      {
        label: 'questionnaire.questions.crc.5_type_of_crc.options.familial',
        value: 'familial',
      },
      {
        label: 'questionnaire.questions.crc.5_type_of_crc.options.neuroendocrine',
        value: 'neuroendocrine',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q9',
    id: ID.type_of_familial_cancer,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.crc.6_type_of_familial_cancer.title',

    info: {
      title: 'questionnaire.questions.crc.6_type_of_familial_cancer.info.title',
      content: 'questionnaire.questions.crc.6_type_of_familial_cancer.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.crc.6_type_of_familial_cancer.options.afap',
        value: 'afap',
      },
      {
        label: 'questionnaire.questions.crc.6_type_of_familial_cancer.options.lynch syndrome',
        value: 'lynch syndrome',
      },
    ],
    conditions: [
      {
        ifId: ID.type_of_crc,
        type: EQUAL_TO,
        ifValue: 'familial',
      },
    ],
  },
  {
    q: 'Q16',
    id: ID.primary_tumor_unresectable,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.crc.7_primary_tumor_unresectable.title',

    info: {
      title: 'questionnaire.questions.crc.7_primary_tumor_unresectable.info.title',
      content: 'questionnaire.questions.crc.7_primary_tumor_unresectable.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.crc.7_primary_tumor_unresectable.options.N',
        value: 'N',
      },
      {
        label: 'questionnaire.questions.crc.7_primary_tumor_unresectable.options.Y',
        value: 'Y',
      },
      {
        label: 'questionnaire.questions.crc.7_primary_tumor_unresectable.options.NS',
        value: 'NS',
      },
      {
        label: 'questionnaire.questions.crc.7_primary_tumor_unresectable.options.NA',
        value: 'NA',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q17',
    id: ID.tumor_meausrable,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.crc.8_tumor_meausrable.title',

    info: {
      title: 'questionnaire.questions.crc.8_tumor_meausrable.info.title',
      content: 'questionnaire.questions.crc.8_tumor_meausrable.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.crc.8_tumor_meausrable.options.Y',
        value: 'Y',
      },
      {
        label: 'questionnaire.questions.crc.8_tumor_meausrable.options.N',
        value: 'N',
      },
      {
        label: 'questionnaire.questions.crc.8_tumor_meausrable.options.NS',
        value: 'NS',
      },
    ],
    conditions: [
      {
        ifId: ID.primary_tumor_unresectable,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['NA'],
      },
    ],
  },
  {
    q: 'Q18',
    id: ID.tumor_size,
    type: NUMBER_INPUT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.crc.9_tumor_size.title',
    subtitle: 'questionnaire.questions.crc.9_tumor_size.subtitle',
    info: {
      title: 'questionnaire.questions.crc.9_tumor_size.info.title',
      content: 'questionnaire.questions.crc.9_tumor_size.info.content',
    },
    options: [],
    conditions: [
      {
        ifId: ID.tumor_meausrable,
        type: CONTAIN_ONE_OF,
        ifValue: ['Y'],
      },
    ],
    instruction: 'questionnaire.questions.crc.9_tumor_size.instruction',
    numberType: numberType.fraction,
  },
  {
    q: 'Q8',
    id: ID.distance_from_anal_verge,
    type: NUMBER_INPUT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.crc.10_distance_from_anal_verge.title',

    info: {
      title: 'questionnaire.questions.crc.10_distance_from_anal_verge.info.title',
      content: 'questionnaire.questions.crc.10_distance_from_anal_verge.info.content',
    },
    options: [],
    conditions: [
      {
        ifId: ID.type_of_crc,
        type: EQUAL_TO,
        ifValue: 'rectal',
      },
      {
        ifId: ID.primary_tumor_unresectable,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'NA',
      },
    ],
    instruction: 'questionnaire.questions.crc.10_distance_from_anal_verge.instruction',
    numberType: numberType.fraction,
    // label: "Tumor measurement"
  },
  {
    q: 'Q20',
    id: ID.undergo_surgery,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.crc.11_undergo_surgery.title',

    info: {
      title: 'questionnaire.questions.crc.11_undergo_surgery.info.title',
      content: 'questionnaire.questions.crc.11_undergo_surgery.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.crc.11_undergo_surgery.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.crc.11_undergo_surgery.options.Scheduled',
        value: 'Scheduled',
      },
      {
        label: 'questionnaire.questions.crc.11_undergo_surgery.options.no',
        value: 'no',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q20a',
    id: ID.surgery_date,
    type: MONTH_PICKER,
    section: SECTIONS.TREATMENT,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.crc.12_surgery_date.title',

    info: {
      title: '',
      content: '',
    },
    options: [],
    conditions: [
      {
        ifId: ID.undergo_surgery,
        type: EQUAL_TO,
        ifValue: 'yes',
      },
    ],
    dateType: {
      type: dateType.currentDate,
    },
  },
  {
    q: 'Q21',
    id: ID.status_after_surgery,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.crc.13_status_after_surgery.title',

    info: {
      title: 'questionnaire.questions.crc.13_status_after_surgery.info.title',
      content: 'questionnaire.questions.crc.13_status_after_surgery.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.crc.13_status_after_surgery.options.r0',
        value: 'r0',
      },
      {
        label: 'questionnaire.questions.crc.13_status_after_surgery.options.r1 or r2',
        value: 'r1 or r2',
      },
      {
        label: 'questionnaire.questions.crc.13_status_after_surgery.options.metastatic disease',
        value: 'metastatic disease',
      },
      {
        label: 'questionnaire.questions.crc.13_status_after_surgery.options.NS',
        value: 'NS',
      },
    ],
    conditions: [
      {
        ifId: ID.undergo_surgery,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['no', 'Scheduled'],
      },
    ],
  },
  {
    q: 'Q10',
    id: ID.have_biopsy,
    path: customPaths.biopsy,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.crc.14_have_biopsy.title',

    info: {
      title: 'questionnaire.questions.crc.14_have_biopsy.info.title',
      content: 'questionnaire.questions.crc.14_have_biopsy.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.crc.14_have_biopsy.options.Y',
        value: 'Y',
      },
      {
        label: 'questionnaire.questions.crc.14_have_biopsy.options.N',
        value: 'N',
      },
      {
        label: 'questionnaire.questions.crc.14_have_biopsy.options.NS',
        value: 'NS',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q80',
    id: ID.ngs,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.molecular,
    order: 0,
    title: 'questionnaire.questions.crc.62_ngs.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.crc.62_ngs.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.crc.62_ngs.options.no',
        value: 'no',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q11',
    id: BIOMARKERS_QUESTION_ID,
    type: BIOMARKERS,
    section: SECTIONS.TREATMENT,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.crc.15_biomarkers.title',
    subtitle: 'questionnaire.questions.crc.15_biomarkers.subtitle',
    placeholder: 'questionnaire.questions.biomarkers.placeholder',
    info: {
      title: '',
      content: '',
    },
    conditions: [
      {
        ifId: ID.have_biopsy,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'N',
      },
    ],
  },
  {
    q: 'Q11b',
    id: ID.kras_mut,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.crc.16_kras_mut.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.crc.16_kras_mut.options.g12c',
        value: 'g12c',
      },
      {
        label: 'questionnaire.questions.crc.16_kras_mut.options.g12v',
        value: 'g12v',
      },
      {
        label: 'questionnaire.questions.crc.16_kras_mut.options.g12d',
        value: 'g12d',
      },
      {
        label: 'questionnaire.questions.crc.16_kras_mut.options.g12a',
        value: 'g12a',
      },
      {
        label: 'questionnaire.questions.crc.16_kras_mut.options.g12s',
        value: 'g12s',
      },
      {
        label: 'questionnaire.questions.crc.16_kras_mut.options.g12f',
        value: 'g12f',
      },
      {
        label: 'questionnaire.questions.crc.16_kras_mut.options.g12r',
        value: 'g12r',
      },
      {
        label: 'questionnaire.questions.crc.16_kras_mut.options.g13c',
        value: 'g13c',
      },
      {
        label: 'questionnaire.questions.crc.16_kras_mut.options.g13d',
        value: 'g13d',
      },
      {
        label: 'questionnaire.questions.crc.16_kras_mut.options.other',
        value: 'other',
      },
      {
        label: 'questionnaire.questions.crc.16_kras_mut.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: BIOMARKERS_QUESTION_ID,
        type: CONTAIN_ONE_OF,
        ifValue: ['kras'],
      },
    ],
  },
  {
    q: 'Q19',
    id: ID.received_any_treatments,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.crc.17_received_any_treatments.title',

    info: {
      title: 'questionnaire.questions.crc.17_received_any_treatments.info.title',
      content: 'questionnaire.questions.crc.17_received_any_treatments.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.crc.17_received_any_treatments.options.Y',
        value: 'Y',
      },
      {
        label: 'questionnaire.questions.crc.17_received_any_treatments.options.N',
        value: 'N',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q22',
    id: ID.received_chemotherapy_treatment_administration_neoadjuvant,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.crc.18_received_chemotherapy_treatment_administration_neoadjuvant.title',

    info: {
      title: 'questionnaire.questions.crc.18_received_chemotherapy_treatment_administration_neoadjuvant.info.title',
      content: 'questionnaire.questions.crc.18_received_chemotherapy_treatment_administration_neoadjuvant.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.crc.18_received_chemotherapy_treatment_administration_neoadjuvant.options.Y',
        value: 'Y',
      },
      {
        label: 'questionnaire.questions.crc.18_received_chemotherapy_treatment_administration_neoadjuvant.options.N',
        value: 'N',
      },
    ],
    conditions: [
      {
        ifId: ID.undergo_surgery,
        type: CONTAIN_ONE_OF,
        ifValue: ['yes', 'Scheduled'],
      },
      {
        ifId: ID.received_any_treatments,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['N'],
      },
    ],
  },
  {
    q: 'Q24',
    id: ID.received_chemotherapy_treatment_administration_adjuvant,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.crc.19_received_chemotherapy_treatment_administration_adjuvant.title',

    info: {
      title: 'questionnaire.questions.crc.19_received_chemotherapy_treatment_administration_adjuvant.info.title',
      content: 'questionnaire.questions.crc.19_received_chemotherapy_treatment_administration_adjuvant.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.crc.19_received_chemotherapy_treatment_administration_adjuvant.options.Y',
        value: 'Y',
      },
      {
        label: 'questionnaire.questions.crc.19_received_chemotherapy_treatment_administration_adjuvant.options.N',
        value: 'N',
      },
    ],
    conditions: [
      {
        ifId: ID.undergo_surgery,
        type: EQUAL_TO,
        ifValue: 'yes',
      },
      {
        ifId: ID.received_any_treatments,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['N'],
      },
      {
        ifId: ID.status_after_surgery,
        type: CONTAIN_ONE_OF,
        ifValue: ['r0', 'NS', null, undefined],
      },
    ],
  },
  {
    q: 'Q24a',
    id: ID.adjuvant_type,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.crc.20_adjuvant_type.title',
    subtitle: 'questionnaire.questions.crc.20_adjuvant_type.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.crc.20_adjuvant_type.options.none',
        value: 'none',
        exclusive: true,
      },
      {
        label: 'questionnaire.questions.crc.20_adjuvant_type.options.xeloda',
        value: 'xeloda',
      },
      {
        label: 'questionnaire.questions.crc.20_adjuvant_type.options.camptosar',
        value: 'camptosar',
      },
      {
        label: 'questionnaire.questions.crc.20_adjuvant_type.options.leucovorin+5-fu',
        value: 'leucovorin+5-fu',
      },
      {
        label: 'questionnaire.questions.crc.20_adjuvant_type.options.eloxatin',
        value: 'eloxatin',
      },
      {
        label: 'questionnaire.questions.crc.20_adjuvant_type.options.lonsurf',
        value: 'lonsurf',
      },
      {
        label: 'questionnaire.questions.crc.20_adjuvant_type.options.leucovorin+5-fu+oxaliplatin',
        value: 'leucovorin+5-fu+oxaliplatin',
      },
      {
        label: 'questionnaire.questions.crc.20_adjuvant_type.options.leucovorin+5-fu+irinotecan',
        value: 'leucovorin+5-fu+irinotecan',
      },
      {
        label: 'questionnaire.questions.crc.20_adjuvant_type.options.capecitabine+oxaliplatin',
        value: 'capecitabine+oxaliplatin',
      },
      {
        label: 'questionnaire.questions.crc.20_adjuvant_type.options.leucovorin+5-fu+oxaliplatin+irinotecan',
        value: 'leucovorin+5-fu+oxaliplatin+irinotecan',
      },
    ],
    conditions: [
      {
        ifId: ID.received_chemotherapy_treatment_administration_adjuvant,
        type: EQUAL_TO,
        ifValue: 'Y',
      },
    ],
  },
  {
    q: 'Q12',
    id: ID.metastasis_locations,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.crc.21_metastasis_locations.title',
    subtitle: 'questionnaire.questions.crc.21_metastasis_locations.subtitle',
    info: {
      title: 'questionnaire.questions.crc.21_metastasis_locations.info.title',
      content: 'questionnaire.questions.crc.21_metastasis_locations.info.content',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.crc.21_metastasis_locations.options.lymph',
        value: 'lymph',
      },
      {
        label: 'questionnaire.questions.crc.21_metastasis_locations.options.liver',
        value: 'liver',
      },
      {
        label: 'questionnaire.questions.crc.21_metastasis_locations.options.lung',
        value: 'lung',
      },
      {
        label: 'questionnaire.questions.crc.21_metastasis_locations.options.brain',
        value: 'brain',
      },
      {
        label: 'questionnaire.questions.crc.21_metastasis_locations.options.bone',
        value: 'bone',
      },
      {
        label: 'questionnaire.questions.crc.21_metastasis_locations.options.abdomen',
        value: 'abdomen',
      },
      {
        label: 'questionnaire.questions.crc.21_metastasis_locations.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.diagnosis_stage,
        type: EQUAL_TO,
        ifValue: '4',
      },
    ],
  },
  {
    q: 'Q14',
    id: ID.is_brain_metastasis_leptomeningeal,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.crc.22_is_brain_metastasis_leptomeningeal.title',

    info: {
      title: 'questionnaire.questions.crc.22_is_brain_metastasis_leptomeningeal.info.title',
      content: 'questionnaire.questions.crc.22_is_brain_metastasis_leptomeningeal.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.crc.22_is_brain_metastasis_leptomeningeal.options.Y',
        value: 'Y',
      },
      {
        label: 'questionnaire.questions.crc.22_is_brain_metastasis_leptomeningeal.options.N',
        value: 'N',
      },
      {
        label: 'questionnaire.questions.crc.22_is_brain_metastasis_leptomeningeal.options.NS',
        value: 'NS',
      },
    ],
    conditions: [
      {
        ifId: ID.metastasis_locations,
        type: CONTAIN_ONE_OF,
        ifValue: ['brain'],
      },
    ],
  },
  {
    q: 'Q15',
    id: ID.is_brain_metastasis_active,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.crc.23_is_brain_metastasis_active.title',

    info: {
      title: 'questionnaire.questions.crc.23_is_brain_metastasis_active.info.title',
      content: 'questionnaire.questions.crc.23_is_brain_metastasis_active.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.crc.23_is_brain_metastasis_active.options.Y',
        value: 'Y',
      },
      {
        label: 'questionnaire.questions.crc.23_is_brain_metastasis_active.options.N',
        value: 'N',
      },
      {
        label: 'questionnaire.questions.crc.23_is_brain_metastasis_active.options.NS',
        value: 'NS',
      },
    ],
    conditions: [
      {
        ifId: ID.metastasis_locations,
        type: CONTAIN_ONE_OF,
        ifValue: ['brain'],
      },
    ],
  },
  {
    q: 'Q13',
    id: ID.metastases_unresectable,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.crc.24_metastases_unresectable.title',

    info: {
      title: 'questionnaire.questions.crc.24_metastases_unresectable.info.title',
      content: 'questionnaire.questions.crc.24_metastases_unresectable.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.crc.24_metastases_unresectable.options.N',
        value: 'N',
      },
      {
        label: 'questionnaire.questions.crc.24_metastases_unresectable.options.Y',
        value: 'Y',
      },
      {
        label: 'questionnaire.questions.crc.24_metastases_unresectable.options.NS',
        value: 'NS',
      },
    ],
    conditions: [
      {
        ifId: ID.diagnosis_stage,
        type: EQUAL_TO,
        ifValue: '4',
      },
    ],
  },
  {
    q: 'Q23',
    id: ID.metastatic_disease_measurable,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.crc.25_metastatic_disease_measurable.title',

    info: {
      title: 'questionnaire.questions.crc.25_metastatic_disease_measurable.info.title',
      content: 'questionnaire.questions.crc.25_metastatic_disease_measurable.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.crc.25_metastatic_disease_measurable.options.Y',
        value: 'Y',
      },
      {
        label: 'questionnaire.questions.crc.25_metastatic_disease_measurable.options.measurable',
        value: 'measurable',
      },
      {
        label: 'questionnaire.questions.crc.25_metastatic_disease_measurable.options.N',
        value: 'N',
      },
      notSureOption,
    ],
    conditions: [
      {
        ifId: ID.diagnosis_stage,
        type: EQUAL_TO,
        ifValue: '4',
      },
    ],
  },
  {
    q: 'Q28',
    id: ID.received_radiation_treatment,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.crc.26_received_radiation_treatment.title',

    info: {
      title: 'questionnaire.questions.crc.26_received_radiation_treatment.info.title',
      content: 'questionnaire.questions.crc.26_received_radiation_treatment.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.crc.26_received_radiation_treatment.options.Y',
        value: 'Y',
      },
      {
        label: 'questionnaire.questions.crc.26_received_radiation_treatment.options.N',
        value: 'N',
      },
    ],
    conditions: [
      {
        ifId: ID.received_any_treatments,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['N'],
      },
    ],
  },
  {
    q: 'Q29',
    id: ID.radiation_treatment_end_date,
    type: MONTH_PICKER,
    section: SECTIONS.TREATMENT,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.crc.27_radiation_treatment_end_date.title',

    info: {
      title: '',
      content: '',
    },
    options: [],
    conditions: [
      {
        ifId: ID.received_radiation_treatment,
        type: EQUAL_TO,
        ifValue: 'Y',
      },
    ],
    dateType: {
      type: dateType.stopTreatment,
    },
  },
  {
    q: 'Q32',
    id: ID.received_ablation_or_embolization_treatment,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.crc.28_received_ablation_or_embolization_treatment.title',
    subtitle: 'questionnaire.questions.crc.28_received_ablation_or_embolization_treatment.subtitle',
    info: {
      title: 'questionnaire.questions.crc.28_received_ablation_or_embolization_treatment.info.title',
      content: 'questionnaire.questions.crc.28_received_ablation_or_embolization_treatment.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.crc.28_received_ablation_or_embolization_treatment.options.Y',
        value: 'Y',
      },
      {
        label: 'questionnaire.questions.crc.28_received_ablation_or_embolization_treatment.options.N',
        value: 'N',
      },
      {
        label: 'questionnaire.questions.crc.28_received_ablation_or_embolization_treatment.options.NS',
        value: 'NS',
      },
    ],
    conditions: [
      {
        ifId: ID.received_any_treatments,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'N',
      },
      {
        ifId: ID.metastasis_locations,
        type: CONTAIN_ONE_OF,
        ifValue: ['lung', 'liver'],
      },
    ],
  },
  {
    q: 'Q33',
    id: ID.status_after_ablation_or_embolization,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.crc.29_status_after_ablation_or_embolization.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.crc.29_status_after_ablation_or_embolization.options.remission',
        value: 'remission',
      },
      {
        label: 'questionnaire.questions.crc.29_status_after_ablation_or_embolization.options.residual disease',
        value: 'residual disease',
      },
      {
        label: 'questionnaire.questions.crc.29_status_after_ablation_or_embolization.options.NS',
        value: 'NS',
      },
    ],
    conditions: [
      {
        ifId: ID.received_ablation_or_embolization_treatment,
        type: EQUAL_TO,
        ifValue: 'Y',
      },
    ],
  },
  {
    q: 'Q30',
    id: ID.prior_treatment_for_metastatic_disease,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.crc.30_prior_treatment_for_metastatic_disease.title',

    info: {
      title: 'questionnaire.questions.crc.30_prior_treatment_for_metastatic_disease.info.title',
      content: 'questionnaire.questions.crc.30_prior_treatment_for_metastatic_disease.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.crc.30_prior_treatment_for_metastatic_disease.options.Y',
        value: 'Y',
      },
      {
        label: 'questionnaire.questions.crc.30_prior_treatment_for_metastatic_disease.options.N',
        value: 'N',
      },
      {
        label: 'questionnaire.questions.crc.30_prior_treatment_for_metastatic_disease.options.NS',
        value: 'NS',
      },
    ],
    conditions: [
      {
        ifId: ID.received_any_treatments,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'N',
      },
      {
        ifId: ID.diagnosis_stage,
        type: CONTAIN_ONE_OF,
        ifValue: ['3.1', '3.2', '3.3', '4'],
      },
    ],
  },
  {
    q: 'Q31',
    id: ID.treatment_lines_received_for_advanced_disease,
    type: NUMBER_INPUT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.crc.31_treatment_lines_received_for_advanced_disease.title',

    info: {
      title: 'questionnaire.questions.crc.31_treatment_lines_received_for_advanced_disease.info.title',
      content: 'questionnaire.questions.crc.31_treatment_lines_received_for_advanced_disease.info.content',
    },
    options: [],
    conditions: [
      {
        ifId: ID.prior_treatment_for_metastatic_disease,
        type: EQUAL_TO,
        ifValue: 'Y',
      },
    ],
    instruction: 'questionnaire.questions.crc.31_treatment_lines_received_for_advanced_disease.instruction',
    label: '',
    numberType: numberType.int,
  },
  {
    q: 'Q34',
    id: ID.received_chemotherapy_treatment_administration_advanced_disease,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.crc.32_received_chemotherapy_treatment_administration_advanced_disease.title',

    info: {
      title:
        'questionnaire.questions.crc.32_received_chemotherapy_treatment_administration_advanced_disease.info.title',
      content:
        'questionnaire.questions.crc.32_received_chemotherapy_treatment_administration_advanced_disease.info.content',
    },
    options: [
      {
        label:
          'questionnaire.questions.crc.32_received_chemotherapy_treatment_administration_advanced_disease.options.Y',
        value: 'Y',
      },
      {
        label:
          'questionnaire.questions.crc.32_received_chemotherapy_treatment_administration_advanced_disease.options.N',
        value: 'N',
      },
    ],
    conditions: [
      {
        ifId: ID.prior_treatment_for_metastatic_disease,
        type: EQUAL_TO,
        ifValue: 'Y',
      },
    ],
  },
  {
    q: 'Q35',
    id: ID.chemotherapy_lines_for_advanced_disease,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.crc.33_chemotherapy_lines_for_advanced_disease.title',

    info: {
      title: 'questionnaire.questions.crc.33_chemotherapy_lines_for_advanced_disease.info.title',
      content: 'questionnaire.questions.crc.33_chemotherapy_lines_for_advanced_disease.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.crc.33_chemotherapy_lines_for_advanced_disease.options.1',
        value: '1',
      },
      {
        label: 'questionnaire.questions.crc.33_chemotherapy_lines_for_advanced_disease.options.2',
        value: '2',
      },
      {
        label: 'questionnaire.questions.crc.33_chemotherapy_lines_for_advanced_disease.options.3',
        value: '3',
      },
      {
        label: 'questionnaire.questions.crc.33_chemotherapy_lines_for_advanced_disease.options.4',
        value: '4',
      },
    ],
    conditions: [
      {
        ifId: ID.received_chemotherapy_treatment_administration_advanced_disease,
        type: EQUAL_TO,
        ifValue: 'Y',
      },
    ],
  },
  {
    q: 'Q36',
    id: ID.chemotherapy_treatment_administration_advanced_disease_types,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.crc.34_chemotherapy_treatment_administration_advanced_disease_types.title',
    subtitle: 'questionnaire.questions.crc.34_chemotherapy_treatment_administration_advanced_disease_types.subtitle',
    info: {
      title: 'questionnaire.questions.crc.34_chemotherapy_treatment_administration_advanced_disease_types.info.title',
      content:
        'questionnaire.questions.crc.34_chemotherapy_treatment_administration_advanced_disease_types.info.content',
    },
    options: [
      noneOption,
      {
        label:
          'questionnaire.questions.crc.34_chemotherapy_treatment_administration_advanced_disease_types.options.xeloda',
        value: 'xeloda',
      },
      {
        label:
          'questionnaire.questions.crc.34_chemotherapy_treatment_administration_advanced_disease_types.options.camptosar',
        value: 'camptosar',
      },
      {
        label:
          'questionnaire.questions.crc.34_chemotherapy_treatment_administration_advanced_disease_types.options.leucovorin+5-fu',
        value: 'leucovorin+5-fu',
      },
      {
        label:
          'questionnaire.questions.crc.34_chemotherapy_treatment_administration_advanced_disease_types.options.eloxatin',
        value: 'eloxatin',
      },
      {
        label:
          'questionnaire.questions.crc.34_chemotherapy_treatment_administration_advanced_disease_types.options.lonsurf',
        value: 'lonsurf',
      },
      {
        label:
          'questionnaire.questions.crc.34_chemotherapy_treatment_administration_advanced_disease_types.options.leucovorin+5-fu+oxaliplatin',
        value: 'leucovorin+5-fu+oxaliplatin',
      },
      {
        label:
          'questionnaire.questions.crc.34_chemotherapy_treatment_administration_advanced_disease_types.options.leucovorin+5-fu+irinotecan',
        value: 'leucovorin+5-fu+irinotecan',
      },
      {
        label:
          'questionnaire.questions.crc.34_chemotherapy_treatment_administration_advanced_disease_types.options.capecitabine+oxaliplatin',
        value: 'capecitabine+oxaliplatin',
      },
      {
        label:
          'questionnaire.questions.crc.34_chemotherapy_treatment_administration_advanced_disease_types.options.leucovorin+5-fu+oxaliplatin+irinotecan',
        value: 'leucovorin+5-fu+oxaliplatin+irinotecan',
      },
    ],
    conditions: [
      {
        ifId: ID.received_chemotherapy_treatment_administration_advanced_disease,
        type: EQUAL_TO,
        ifValue: 'Y',
      },
    ],
  },
  {
    q: 'Q27',
    id: ID.chemotherapy_treatment_administration_advanced_disease_end_date,
    type: MONTH_PICKER,
    section: SECTIONS.TREATMENT,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.crc.35_chemotherapy_treatment_administration_advanced_disease_end_date.title',

    info: {
      title:
        'questionnaire.questions.crc.35_chemotherapy_treatment_administration_advanced_disease_end_date.info.title',
      content:
        'questionnaire.questions.crc.35_chemotherapy_treatment_administration_advanced_disease_end_date.info.content',
    },
    options: [],
    conditions: [
      {
        ifId: ID.received_chemotherapy_treatment_administration_advanced_disease,
        type: EQUAL_TO,
        ifValue: 'Y',
      },
    ],
    dateType: {
      type: dateType.stopTreatment,
    },
  },
  {
    q: 'Q26',
    id: ID.chemotherapy_treatment_administration_advanced_disease_stop_reas,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.crc.36_chemotherapy_treatment_administration_advanced_disease_stop_reas.title',

    info: {
      title:
        'questionnaire.questions.crc.36_chemotherapy_treatment_administration_advanced_disease_stop_reas.info.title',
      content:
        'questionnaire.questions.crc.36_chemotherapy_treatment_administration_advanced_disease_stop_reas.info.content',
    },
    options: stopReasonConditions,
    conditions: [
      {
        ifId: ID.received_chemotherapy_treatment_administration_advanced_disease,
        type: EQUAL_TO,
        ifValue: 'Y',
      },
    ],
  },
  {
    q: 'Q25',
    id: ID.received_target_drug_treatment,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.crc.37_received_target_drug_treatment.title',

    info: {
      title: 'questionnaire.questions.crc.37_received_target_drug_treatment.info.title',
      content: 'questionnaire.questions.crc.37_received_target_drug_treatment.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.crc.37_received_target_drug_treatment.options.Y',
        value: 'Y',
      },
      {
        label: 'questionnaire.questions.crc.37_received_target_drug_treatment.options.N',
        value: 'N',
      },
      {
        label: 'questionnaire.questions.crc.37_received_target_drug_treatment.options.NS',
        value: 'NS',
      },
    ],
    conditions: [
      {
        ifId: ID.prior_treatment_for_metastatic_disease,
        type: EQUAL_TO,
        ifValue: 'Y',
      },
    ],
  },
  {
    q: 'Q37',
    id: ID.target_drug_categories,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.crc.38_target_drug_categories.title',
    subtitle: 'questionnaire.questions.crc.38_target_drug_categories.subtitle',
    info: {
      title: 'questionnaire.questions.crc.38_target_drug_categories.info.title',
      content: 'questionnaire.questions.crc.38_target_drug_categories.info.content',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.crc.38_target_drug_categories.options.avastin',
        value: 'avastin',
      },
      {
        label: 'questionnaire.questions.crc.38_target_drug_categories.options.cyrazma',
        value: 'cyrazma',
      },
      {
        label: 'questionnaire.questions.crc.38_target_drug_categories.options.zaltrap',
        value: 'zaltrap',
      },
      {
        label: 'questionnaire.questions.crc.38_target_drug_categories.options.erbitux',
        value: 'erbitux',
      },
      {
        label: 'questionnaire.questions.crc.38_target_drug_categories.options.vectibix',
        value: 'vectibix',
      },
      {
        label: 'questionnaire.questions.crc.38_target_drug_categories.options.stivarga',
        value: 'stivarga',
      },
      {
        label: 'questionnaire.questions.crc.38_target_drug_categories.options.braftovi',
        value: 'braftovi',
      },
      {
        label: 'questionnaire.questions.crc.38_target_drug_categories.options.rozlytrek',
        value: 'rozlytrek',
      },
      {
        label: 'questionnaire.questions.crc.38_target_drug_categories.options.vitrakvi',
        value: 'vitrakvi',
      },
      {
        label: 'questionnaire.questions.crc.38_target_drug_categories.options.braftovi_and_erbitux_combo',
        value: 'braftovi and erbitux combo',
      },
      {
        label: 'questionnaire.questions.crc.38_target_drug_categories.options.other',
        value: 'other_drugs_or_drug_combinations',
      },
    ],
    conditions: [
      {
        ifId: ID.received_target_drug_treatment,
        type: EQUAL_TO,
        ifValue: 'Y',
      },
    ],
  },
  {
    q: 'Q38',
    id: ID.target_drug_treatment_end_date,
    type: MONTH_PICKER,
    section: SECTIONS.TREATMENT,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.crc.39_target_drug_treatment_end_date.title',

    info: {
      title: 'questionnaire.questions.crc.39_target_drug_treatment_end_date.info.title',
      content: 'questionnaire.questions.crc.39_target_drug_treatment_end_date.info.content',
    },
    options: [],
    conditions: [
      {
        ifId: ID.received_target_drug_treatment,
        type: EQUAL_TO,
        ifValue: 'Y',
      },
    ],
    dateType: {
      type: dateType.stopTreatment,
    },
  },
  {
    q: 'Q39',
    id: ID.target_drug_treatment_stop_reason,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.crc.40_target_drug_treatment_stop_reason.title',

    info: {
      title: 'questionnaire.questions.crc.40_target_drug_treatment_stop_reason.info.title',
      content: 'questionnaire.questions.crc.40_target_drug_treatment_stop_reason.info.content',
    },
    options: stopReasonConditions,
    conditions: [
      {
        ifId: ID.received_target_drug_treatment,
        type: EQUAL_TO,
        ifValue: 'Y',
      },
    ],
  },
  {
    q: 'Q44',
    id: ID.received_immunotherapy_treatment,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.crc.41_received_immunotherapy_treatment.title',

    info: {
      title: 'questionnaire.questions.crc.41_received_immunotherapy_treatment.info.title',
      content: 'questionnaire.questions.crc.41_received_immunotherapy_treatment.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.crc.41_received_immunotherapy_treatment.options.Y',
        value: 'Y',
      },
      {
        label: 'questionnaire.questions.crc.41_received_immunotherapy_treatment.options.N',
        value: 'N',
      },
      {
        label: 'questionnaire.questions.crc.41_received_immunotherapy_treatment.options.NS',
        value: 'NS',
      },
    ],
    conditions: [
      {
        ifId: ID.prior_treatment_for_metastatic_disease,
        type: EQUAL_TO,
        ifValue: 'Y',
      },
    ],
  },
  {
    q: 'Q45',
    id: ID.immunotheraphy_types,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.crc.42_immunotheraphy_types.title',

    info: {
      title: 'questionnaire.questions.crc.42_immunotheraphy_types.info.title',
      content: 'questionnaire.questions.crc.42_immunotheraphy_types.info.content',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.crc.42_immunotheraphy_types.options.opdivo',
        value: 'opdivo',
      },
      {
        label: 'questionnaire.questions.crc.42_immunotheraphy_types.options.keytruda',
        value: 'keytruda',
      },
      {
        label: 'questionnaire.questions.crc.42_immunotheraphy_types.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.received_immunotherapy_treatment,
        type: EQUAL_TO,
        ifValue: 'Y',
      },
    ],
  },
  {
    q: 'Q46',
    id: ID.immunotheraphy_treatment_end_date,
    type: MONTH_PICKER,
    section: SECTIONS.TREATMENT,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.crc.43_immunotheraphy_treatment_end_date.title',

    info: {
      title: 'questionnaire.questions.crc.43_immunotheraphy_treatment_end_date.info.title',
      content: 'questionnaire.questions.crc.43_immunotheraphy_treatment_end_date.info.content',
    },
    options: [],
    conditions: [
      {
        ifId: ID.received_immunotherapy_treatment,
        type: EQUAL_TO,
        ifValue: 'Y',
      },
    ],
    dateType: {
      type: dateType.stopTreatment,
    },
  },
  {
    q: 'Q47',
    id: ID.immunotherapy_treatment_stop_reason,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.crc.44_immunotherapy_treatment_stop_reason.title',

    info: {
      title: 'questionnaire.questions.crc.44_immunotherapy_treatment_stop_reason.info.title',
      content: 'questionnaire.questions.crc.44_immunotherapy_treatment_stop_reason.info.content',
    },
    options: stopReasonConditions,
    conditions: [
      {
        ifId: ID.received_immunotherapy_treatment,
        type: EQUAL_TO,
        ifValue: 'Y',
      },
    ],
  },
  {
    q: 'Q48',
    id: ID.received_investigational_therapy_treatment,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.crc.45_received_investigational_therapy_treatment.title',

    info: {
      title: 'questionnaire.questions.crc.45_received_investigational_therapy_treatment.info.title',
      content: 'questionnaire.questions.crc.45_received_investigational_therapy_treatment.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.crc.45_received_investigational_therapy_treatment.options.Y',
        value: 'Y',
      },
      {
        label: 'questionnaire.questions.crc.45_received_investigational_therapy_treatment.options.N',
        value: 'N',
      },
      {
        label: 'questionnaire.questions.crc.45_received_investigational_therapy_treatment.options.NS',
        value: 'NS',
      },
    ],
    conditions: [
      {
        ifId: ID.prior_treatment_for_metastatic_disease,
        type: EQUAL_TO,
        ifValue: 'Y',
      },
    ],
  },
  {
    q: 'Q49',
    id: ID.investigational_therapy_treatment_end_date,
    type: MONTH_PICKER,
    section: SECTIONS.TREATMENT,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.crc.46_investigational_therapy_treatment_end_date.title',

    info: {
      title: 'questionnaire.questions.crc.46_investigational_therapy_treatment_end_date.info.title',
      content: 'questionnaire.questions.crc.46_investigational_therapy_treatment_end_date.info.content',
    },
    options: [],
    conditions: [
      {
        ifId: ID.received_investigational_therapy_treatment,
        type: EQUAL_TO,
        ifValue: 'Y',
      },
    ],
    dateType: {
      type: dateType.stopTreatment,
    },
  },
  {
    q: 'Q62',
    id: ID.ecog,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.crc.47_ecog.title',

    info: {
      title: 'questionnaire.questions.crc.47_ecog.info.title',
      content: 'questionnaire.questions.crc.47_ecog.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.crc.47_ecog.options.0',
        value: '0',
      },
      {
        label: 'questionnaire.questions.crc.47_ecog.options.1',
        value: '1',
      },
      {
        label: 'questionnaire.questions.crc.47_ecog.options.2',
        value: '2',
      },
      {
        label: 'questionnaire.questions.crc.47_ecog.options.3',
        value: '3',
      },
      {
        label: 'questionnaire.questions.crc.47_ecog.options.4',
        value: '4',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q50',
    id: ID.other_medical_conditions,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.crc.48_other_medical_conditions.title',
    subtitle: 'questionnaire.questions.crc.48_other_medical_conditions.subtitle',
    info: {
      title: 'questionnaire.questions.crc.48_other_medical_conditions.info.title',
      content: 'questionnaire.questions.crc.48_other_medical_conditions.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.crc.48_other_medical_conditions.options.none',
        value: 'none',
        exclusive: true,
      },
      {
        label: 'questionnaire.questions.crc.48_other_medical_conditions.options.liver problem',
        value: 'liver problem',
      },
      {
        label: 'questionnaire.questions.crc.48_other_medical_conditions.options.heart problem',
        value: 'heart problem',
      },
      {
        label: 'questionnaire.questions.crc.48_other_medical_conditions.options.kidney',
        value: 'kidney',
      },
      {
        label: 'questionnaire.questions.crc.48_other_medical_conditions.options.autoimmune',
        value: 'autoimmune',
      },
      {
        label: 'questionnaire.questions.crc.48_other_medical_conditions.options.mental',
        value: 'mental',
      },
      {
        label: 'questionnaire.questions.crc.48_other_medical_conditions.options.allergies',
        value: 'allergies',
      },
      {
        label: 'questionnaire.questions.crc.48_other_medical_conditions.options.active infection',
        value: 'active infection',
      },
      {
        label: 'questionnaire.questions.crc.48_other_medical_conditions.options.gastrointestinal problem',
        value: 'gastrointestinal problem',
      },
      {
        label: 'questionnaire.questions.crc.48_other_medical_conditions.options.other cancer type',
        value: 'other cancer type',
      },
      {
        label: 'questionnaire.questions.crc.48_other_medical_conditions.options.anemia',
        value: 'anemia',
      },
      {
        label: 'questionnaire.questions.crc.48_other_medical_conditions.options.thyroid',
        value: 'thyroid',
      },
      {
        label: 'questionnaire.questions.crc.48_other_medical_conditions.options.hypertension',
        value: 'hypertension',
      },
      {
        label: 'questionnaire.questions.crc.48_other_medical_conditions.options.epilepsy',
        value: 'epilepsy',
      },
      {
        label: 'questionnaire.questions.crc.48_other_medical_conditions.options.diabetes',
        value: 'diabetes',
      },
      {
        label: 'questionnaire.questions.crc.48_other_medical_conditions.options.organ_transplant',
        value: 'organ_transplant',
      },
      {
        label: 'questionnaire.questions.crc.48_other_medical_conditions.options.extreme_obesity',
        value: 'extreme_obesity',
      },
      {
        label: 'questionnaire.questions.crc.48_other_medical_conditions.options.stroke',
        value: 'stroke',
      },
      {
        label: 'questionnaire.questions.crc.48_other_medical_conditions.options.lung',
        value: 'lung',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q52',
    id: ID.liver_problem,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.crc.49_liver_problem.title',

    info: {
      title: 'questionnaire.questions.crc.49_liver_problem.info.title',
      content: 'questionnaire.questions.crc.49_liver_problem.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.crc.49_liver_problem.options.elevated_alt_ast',
        value: 'elevated_alt_ast',
      },
      {
        label: 'questionnaire.questions.crc.49_liver_problem.options.elevated_bilirubin',
        value: 'elevated_bilirubin',
      },
      {
        label: 'questionnaire.questions.crc.49_liver_problem.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_conditions,
        type: CONTAIN_ONE_OF,
        ifValue: ['liver problem'],
      },
    ],
  },
  {
    q: 'Q53',
    id: ID.heart_problem,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.crc.50_heart_problem.title',

    info: {
      title: 'questionnaire.questions.crc.50_heart_problem.info.title',
      content: 'questionnaire.questions.crc.50_heart_problem.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.crc.50_heart_problem.options.class_at_least_nyha_ii',
        value: 'class_at_least_nyha_ii',
      },
      {
        label: 'questionnaire.questions.crc.50_heart_problem.options.condition_at_least_grade_2',
        value: 'condition_at_least_grade_2',
      },
      {
        label: 'questionnaire.questions.crc.50_heart_problem.options.cardiac_ventricular_arrhythmia',
        value: 'cardiac_ventricular_arrhythmia',
      },
      {
        label: 'questionnaire.questions.crc.50_heart_problem.options.heart_failure',
        value: 'heart_failure',
      },
      {
        label: 'questionnaire.questions.crc.50_heart_problem.options.lvef_under_50_percent',
        value: 'lvef_under_50_percent',
      },
      {
        label: 'questionnaire.questions.crc.50_heart_problem.options.qtcf_abnormal',
        value: 'qtcf_abnormal',
      },
      {
        label: 'questionnaire.questions.crc.50_heart_problem.options.angina',
        value: 'angina',
      },
      {
        label: 'questionnaire.questions.crc.50_heart_problem.options.myocardial_infarction',
        value: 'myocardial_infarction',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_conditions,
        type: CONTAIN_ONE_OF,
        ifValue: ['heart problem'],
      },
    ],
  },
  {
    q: 'Q54',
    id: ID.stroke_date,
    type: MONTH_PICKER,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.crc.51_stroke_date.title',

    info: {
      title: 'questionnaire.questions.crc.51_stroke_date.info.title',
      content: 'questionnaire.questions.crc.51_stroke_date.info.content',
    },
    options: [],
    conditions: [
      {
        ifId: ID.other_medical_conditions,
        type: CONTAIN_ONE_OF,
        ifValue: ['stroke'],
      },
    ],
    dateType: {
      type: dateType.currentDate,
    },
  },
  {
    q: 'Q55',
    id: ID.kidney_problem,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.crc.52_kidney_problem.title',

    info: {
      title: 'questionnaire.questions.crc.52_kidney_problem.info.title',
      content: 'questionnaire.questions.crc.52_kidney_problem.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.crc.52_kidney_problem.options.low_glomerular_filtration_rate',
        value: 'low_glomerular_filtration_rate',
      },
      {
        label: 'questionnaire.questions.crc.52_kidney_problem.options.dialysis',
        value: 'dialysis',
      },
      {
        label: 'questionnaire.questions.crc.52_kidney_problem.options.kidney_stones',
        value: 'kidney_stones',
      },
      {
        label: 'questionnaire.questions.crc.52_kidney_problem.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_conditions,
        type: CONTAIN_ONE_OF,
        ifValue: ['kidney'],
      },
    ],
  },
  {
    q: 'Q56',
    id: ID.autoimmune_condition,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.crc.53_autoimmune_condition.title',

    info: {
      title: 'questionnaire.questions.crc.53_autoimmune_condition.info.title',
      content: 'questionnaire.questions.crc.53_autoimmune_condition.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.crc.53_autoimmune_condition.options.congenital_or_acquired_immunosuppression',
        value: 'congenital_or_acquired_immunosuppression',
      },
      {
        label: 'questionnaire.questions.crc.53_autoimmune_condition.options.hepatitis',
        value: 'hepatitis',
      },
      {
        label: 'questionnaire.questions.crc.53_autoimmune_condition.options.granulomatous_disease',
        value: 'granulomatous_disease',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_conditions,
        type: CONTAIN_ONE_OF,
        ifValue: ['autoimmune'],
      },
    ],
  },
  {
    q: 'Q57',
    id: ID.mental_disorder,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.crc.54_mental_disorder.title',

    info: {
      title: 'questionnaire.questions.crc.54_mental_disorder.info.title',
      content: 'questionnaire.questions.crc.54_mental_disorder.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.crc.54_mental_disorder.options.Dementia',
        value: 'Dementia',
      },
      {
        label: 'questionnaire.questions.crc.54_mental_disorder.options.Dysphrenia',
        value: 'Dysphrenia',
      },
      {
        label: 'questionnaire.questions.crc.54_mental_disorder.options.Neuropsychiatric Disorder',
        value: 'Neuropsychiatric Disorder',
      },
      {
        label: 'questionnaire.questions.crc.54_mental_disorder.options.Depression',
        value: 'Depression',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_conditions,
        type: CONTAIN_ONE_OF,
        ifValue: ['mental'],
      },
    ],
  },
  {
    q: 'Q58',
    id: ID.allergies,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.crc.55_allergies.title',

    info: {
      title: 'questionnaire.questions.crc.55_allergies.info.title',
      content: 'questionnaire.questions.crc.55_allergies.info.content',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.crc.55_allergies.options.interferon',
        value: 'interferon',
      },
      {
        label: 'questionnaire.questions.crc.55_allergies.options.metformin',
        value: 'metformin',
      },
      {
        label: 'questionnaire.questions.crc.55_allergies.options.vitD',
        value: 'vitD',
      },
      {
        label: 'questionnaire.questions.crc.55_allergies.options.penicillin',
        value: 'penicillin',
      },
      {
        label: 'questionnaire.questions.crc.55_allergies.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_conditions,
        type: CONTAIN_ONE_OF,
        ifValue: ['allergies'],
      },
    ],
  },
  {
    q: 'Q59',
    id: ID.infection_or_inflammation,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.crc.56_infection_or_inflammation.title',

    info: {
      title: 'questionnaire.questions.crc.56_infection_or_inflammation.info.title',
      content: 'questionnaire.questions.crc.56_infection_or_inflammation.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.crc.56_infection_or_inflammation.options.hepatitis',
        value: 'hepatitis',
      },
      {
        label: 'questionnaire.questions.crc.56_infection_or_inflammation.options.hiv',
        value: 'hiv',
      },
      {
        label: 'questionnaire.questions.crc.56_infection_or_inflammation.options.other',
        value: 'other',
      },
      {
        label: 'questionnaire.questions.crc.56_infection_or_inflammation.options.elevated_wbc',
        value: 'elevated_wbc',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_conditions,
        type: CONTAIN_ONE_OF,
        ifValue: ['active infection'],
      },
    ],
  },
  {
    q: 'Q61',
    id: ID.gastro_issue,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.crc.57_gastro_issue.title',

    info: {
      title: 'questionnaire.questions.crc.57_gastro_issue.info.title',
      content: 'questionnaire.questions.crc.57_gastro_issue.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.crc.57_gastro_issue.options.gastrointestinal_bleeding',
        value: 'gastrointestinal_bleeding',
      },
      {
        label: 'questionnaire.questions.crc.57_gastro_issue.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_conditions,
        type: CONTAIN_ONE_OF,
        ifValue: ['gastrointestinal problem'],
      },
    ],
  },
  {
    q: 'Q60',
    id: ID.blood_test,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.crc.58_blood_test.title',
    subtitle: 'questionnaire.questions.crc.58_blood_test.subtitle',
    info: {
      title: 'questionnaire.questions.crc.58_blood_test.info.title',
      content: 'questionnaire.questions.crc.58_blood_test.info.content',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.crc.58_blood_test.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.crc.58_blood_test.options.low_wbc',
        value: 'low_wbc',
      },
      {
        label: 'questionnaire.questions.crc.58_blood_test.options.low_hemoglobin',
        value: 'low_hemoglobin',
      },
      {
        label: 'questionnaire.questions.crc.58_blood_test.options.low_potassium',
        value: 'low_potassium',
      },
      {
        label: 'questionnaire.questions.crc.58_blood_test.options.low_magnesium',
        value: 'low_magnesium',
      },
      {
        label: 'questionnaire.questions.crc.58_blood_test.options.clotting_coagulation_platelets',
        value: 'clotting_coagulation_platelets',
      },
    ],
    conditions: [],
  },
  // Supposed to be lung question Q59
  {
    q: 'Q51',
    id: ID.other_medications,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.crc.59_other_medications.title',

    info: {
      title: 'questionnaire.questions.crc.59_other_medications.info.title',
      content: 'questionnaire.questions.crc.59_other_medications.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.crc.59_other_medications.options.CYP3A inhibitors',
        value: 'CYP3A inhibitors',
      },
      {
        label: 'questionnaire.questions.crc.59_other_medications.options.immunosuppressive medications',
        value: 'immunosuppressive medications',
      },
      {
        label: 'questionnaire.questions.crc.59_other_medications.options.none',
        value: 'none',
      },
    ],
    conditions: [],
  },
  preferredLocation,
  travelLimit,
  initialDiagnosisDate('Q5'),
  userType,
  lookingFor,
  {
    q: 'Q1',
    id: ID.dob,
    type: MONTH_PICKER,
    section: SECTIONS.ABOUT_ME,
    bgIcon: i.user,
    order: 0,
    title: 'questionnaire.questions.crc.0_dob.title',
    info: {
      title: '',
      content: '',
    },
    options: [],
    conditions: [],
    dateType: {
      type: dateType.dob,
    },
  },
  {
    q: 'Q2',
    id: ID.gender,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.ABOUT_ME,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.crc.1_gender.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.crc.1_gender.options.Female',
        value: 'female',
      },
      {
        label: 'questionnaire.questions.crc.1_gender.options.Male',
        value: 'male',
      },
      {
        label: 'questionnaire.questions.global.gender.options.other',
        value: 'other',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q3',
    id: ID.pregnant,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.ABOUT_ME,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.crc.60_pregnant.title',
    info: {
      title: 'questionnaire.questions.crc.60_pregnant.info.title',
      content: 'questionnaire.questions.crc.60_pregnant.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.crc.60_pregnant.options.Y',
        value: 'Y',
      },
      {
        label: 'questionnaire.questions.crc.60_pregnant.options.N',
        value: 'N',
      },
    ],
    conditions: [
      {
        ifId: ID.gender,
        type: EQUAL_TO,
        ifValue: 'female',
      },
      {
        type: AGE_LESS_THAN,
        ifValue: 50,
      },
    ],
  },
  {
    q: 'Q4',
    id: ID.breastfeeding,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.ABOUT_ME,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.crc.61_breastfeeding.title',
    info: {
      title: 'questionnaire.questions.crc.61_breastfeeding.info.title',
      content: 'questionnaire.questions.crc.61_breastfeeding.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.crc.61_breastfeeding.options.Y',
        value: 'Y',
      },
      {
        label: 'questionnaire.questions.crc.61_breastfeeding.options.N',
        value: 'N',
      },
    ],
    conditions: [
      {
        ifId: ID.gender,
        type: EQUAL_TO,
        ifValue: 'female',
      },
      {
        type: AGE_LESS_THAN,
        ifValue: 50,
      },
    ],
  },
  race,
];

export default Questions;
