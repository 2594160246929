export default {
  cancer_research: 'cancer-research.svg',
  clock_brain_pills: 'clock-brain-pills.svg',
  doctor_book_kit: 'doctor-book-kit.svg',
  globe: 'globe.svg',
  medical_profile: 'medical-profile.svg',
  medical_report: 'medical-report.svg',
  molecular: 'molecular.svg',
  plane_hospital_pills: 'plane-hospital-pills.svg',
  treatment: 'treatment.svg',
  user: 'user.svg',
  other: {
    new_profile_icon: 'newprofile-icon.svg',
    docotr_sent: 'email-doctor.svg',
    pdf_sent: 'sent-pdf.svg',
    delete_icon: 'delete-icon.svg'
  }
};
