import LOCAL_STORAGE_KEYS from '../utils/constants/localStorageKeys';

/**
 * Checks if the users has registered (logged in or signed up) on this browser,
 * if not then mark user as registered in the local storage.
 *
 * This function should be called in the /login or /signup routes. This are the
 * possible routes where registration happens first.
 *
 * @return {String} The date in string of when user first registered
 */
export default () => {
  let joinedAt = localStorage.getItem(LOCAL_STORAGE_KEYS.JOINED_AT);

  if (!joinedAt) {
    joinedAt = localStorage.setItem(LOCAL_STORAGE_KEYS.JOINED_AT, new Date().toString());
  }

  return joinedAt;
};
