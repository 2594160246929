import React, { useState } from 'react';
import './style.css';

const Toggle = ({ keyProp, initialState, onChange }) => {
    const [isToggled, setIsToggled] = useState(initialState);
  
    const handleToggle = () => {
      const newState = !isToggled;
      setIsToggled(newState);
      onChange(keyProp, newState);
    };
  
    return (
      <div className="toggle-container" onClick={handleToggle}>
        <div className={`toggle-switch ${isToggled ? 'toggled' : ''}`}>
          <div className="toggle-thumb"></div>
        </div>
      </div>
    );
};
  
export default Toggle;