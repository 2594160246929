/* eslint-disable react/jsx-no-target-blank */
import React from 'react';

// import CloseIcon from 'new-ui/assets/icons/x.svg';

import './ActionBox.css';
import PropTypes from 'prop-types';
import Arrow from 'new-ui/assets/icons/arrow_left.svg';
import { getColorByBackground } from './functions';

const COLORS = {
  WHITE: '#fff',
  BLACK: '#000',
};

const WHITE_FILTER = 'invert(1) brightness(10)';

const ActionBox = (props) => {
  const {
    icon, title, subtitle, buttonText, action, color, href, hrefOnClick,
  } = props;

  const textColor = getColorByBackground(color, COLORS.WHITE, COLORS.BLACK);

  const renderButtonIcon = () => (
    <div className="action-button-icon">
      <img
        alt="arrow button"
        src={Arrow}
        style={{
          filter: textColor === COLORS.WHITE ? WHITE_FILTER : 'none',
          rotate: document?.body?.classList?.contains('rtl') ? '180deg' : '0deg',
        }}
      />
    </div>
  );

  const renderButtonText = () => (
    <div className="action-button-text">
      {buttonText}
    </div>
  );

  const renderButton = () => (href ? (
    <a
      className="action-button"
      href={href}
      onClick={hrefOnClick || (() => {})}
      target="_blank"
      style={{ color: textColor }}
    >
      {renderButtonText()}
      {renderButtonIcon()}
    </a>
  ) : (
    <div className="action-button" onClick={action} style={{ color: textColor }}>
      {renderButtonText()}
      {renderButtonIcon()}
    </div>
  ));

  return (
    <div className="actionbox" style={{ backgroundColor: color || 'transparent' }}>
      <div className="top">
        <div className="icon">
          <div className="icon-wrapper">
            <img
              src={require(`../../assets/icons/actions/${icon}.svg`)}
              alt=""
              style={{
                filter: textColor === COLORS.WHITE ? WHITE_FILTER : 'none',
              }}
            />
          </div>
        </div>
        <div className="titles" style={{ color: textColor }}>
          <div className="title">{title}</div>
          <div className="subtitle">{subtitle}</div>
        </div>
      </div>
      <div className="action">
        {
          renderButton()
        }
      </div>
    </div>
  );
};

ActionBox.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonText: PropTypes.string,
  href: PropTypes.any,
  action: PropTypes.func,
  color: PropTypes.string,
  hrefOnClick: PropTypes.func,
};

export default ActionBox;
