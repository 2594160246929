import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './NextScan.css';

import { useTranslation } from 'utils/modifiedTranslation';
import { getSurvey, setSurvey } from 'components/Surveys/api';

import Form from 'new-ui/Components/Form';
import { PopupTitle, PopupContent } from 'new-ui/Components/Popup';

import app from 'new-ui/app';

const SURVEY_NAME = 'nextScan';

const NextScan = (props) => {
  const { closePopup } = props;
  const [form, setForm] = useState(false);
  const { t } = useTranslation();
  const { user_wix_id } = app.user.info;

  useEffect(() => {
    const fetch = async () => {
      const r = await getSurvey(SURVEY_NAME, user_wix_id);

      const formData = {
        inputs: {
          nextScan: {
            type: 'radio',
            options: [
              {
                value: 'NEXT_COUPLE_OF_WEEKS',
                title: 'In the next couple of weeks',
              },
              {
                value: 'TWO_MONTHS',
                title: 'About 2 months',
              },
              {
                value: 'THREE_MONTHS',
                title: '3 Months from now',
              },
              {
                value: 'MORE_THAN_THREE_MONTHS',
                title: 'More than 3 months',
              },
            ],
          },
        },
        data: {
          nextScan: r?.data?.answers[0]?.value || '',
        },
      };

      setForm(formData);
    };

    fetch();
  }, [user_wix_id]);

  const send = async () => {
    const answers = [
      {
        surveyKey: 'general',
        key: 'nextScan',
        value: form.data.nextScan,
      },
    ];
    await setSurvey(SURVEY_NAME, answers, user_wix_id);
    closePopup();
  };

  return (
    <div className="next-scan">
      <PopupTitle title="When is your next scan?" />
      <PopupContent>
        {form ? <Form inputs={form.inputs} data={form.data} popupMode submit={{ title: t('share_with_doctor.send'), action: send }} /> : null}
      </PopupContent>
    </div>
  );
};

NextScan.propTypes = {
  closePopup: PropTypes.func,
};

export default NextScan;
