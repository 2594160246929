const WHAT_NEXT_BLOCK_NAME_PREFIX = 'WhatNext';

export const TEMPLATE_CONTENT_BLOCK_NAMES = {
  LOGO: 'Logo',
  HEADER_TITLE: 'HeaderTitle',
  DESCRIPTION: 'Description',
  WHAT_NEXT_ENGLISH: `${WHAT_NEXT_BLOCK_NAME_PREFIX}English`,
  WHAT_NEXT_HEBREW: `${WHAT_NEXT_BLOCK_NAME_PREFIX}Hebrew`,
  WHAT_NEXT_CANADA: `${WHAT_NEXT_BLOCK_NAME_PREFIX}Canada`,
  WHAT_NEXT_FRENCH: `${WHAT_NEXT_BLOCK_NAME_PREFIX}French`,
  WHAT_NEXT_TURKEY: `${WHAT_NEXT_BLOCK_NAME_PREFIX}Turkey`,
};
