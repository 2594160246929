import React from 'react';
import PropTypes from 'prop-types';
import './Popup.css';

const PopupContent = (props) => {
  const { children } = props;
  return (
    <div className="popup-content-wrapper popup-padding">
      {children}
    </div>
  );
};

PopupContent.propTypes = {
  children: PropTypes.any,
};

export default PopupContent;
