export const TRIAL_FINDER_ACTIONS = {
  TRIAL_FINDER_FETCH_TRIALS_START: 'TRIAL_FINDER_FETCH_TRIALS_START',
  TRIAL_FINDER_FETCH_TRIALS_SUCCESS: 'TRIAL_FINDER_FETCH_TRIALS_SUCCESS',
  TRIAL_FINDER_FETCH_TRIALS_ERROR: 'TRIAL_FINDER_FETCH_TRIALS_ERROR',
  FETCH_CANCER_TYPES_START: 'FETCH_CANCER_TYPES_START',
  FETCH_CANCER_TYPES_ERROR: 'FETCH_CANCER_TYPES_ERROR',
  FETCH_CANCER_TYPES_SUCCESS: 'FETCH_CANCER_TYPES_SUCCESS',
  RESET_TRIAL_FINDER_STORE: 'RESET_TRIAL_FINDER_STORE',
};

export const FORM_FIELDS = {
  CANCER_TYPE: 'cancerType',
  PREFERRED_LOCATION: 'preferredLocation'
};
