import configs from 'configs';
import request from 'functions/api/request';

const apiUrl = configs.beApiUrl;

export const getCombinations = async () => {
  let response = null;

  const requestOptions = {
    url: `${apiUrl}/questionnaire/question/drugs_combinations`,
    json: true,
  };

  try {
    response = await request.get(requestOptions);
  } catch (ex) {
    console.error('Error occurred, while getting profile data:', ex);
    throw ex;
  }

  return response;
};

export const getDrugs = async () => {
  let response = null;

  const requestOptions = {
    url: `${apiUrl}/questionnaire/question/drugs_received`,
    json: true,
  };

  try {
    response = await request.get(requestOptions);
  } catch (ex) {
    console.error('Error occurred, while getting profile data:', ex);
    throw ex;
  }

  return response;
};
