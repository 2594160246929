import React from 'react';

import PopupContent from 'new-ui/Components/Popup/PopupContent';
import { useTranslation } from 'utils/modifiedTranslation';

import './index.css';
import { POPUP_PREFERENCES } from 'new-ui/constants';

const EVIDENCES = [
  '1', '2A', '2B', '3',
];

const PreferenceCategory = ({ preference, evidence }) => {
  const { t } = useTranslation();

  return (
    <PopupContent>
      <div className='preference-popup'>
        <h3>{t('match.category_of_preference.popup.title')}</h3>
        {POPUP_PREFERENCES.map((value) => (
          <div
            key={value}
            className={preference === value ? 'active' : ''}>
            <p className='title'>
              {t('match.list.item.treatment.category_of_preference.title.' + value)}
            </p>
            <p className='description'>
              {t('match.list.item.treatment.category_of_preference.description.' + value)}
            </p>
          </div>
        ))}
        <h3>{t('match.category_of_evidence.popup.title')}</h3>
        {EVIDENCES.map((value) => (
          <div
            key={value}
            className={evidence === value ? 'active' : ''}>
            <p className='title'>
              {t('match.list.item.treatment.category_of_evidence.title.' + value)}
            </p>
            <p className='description'>
              {t('match.list.item.treatment.category_of_evidence.description.' + value)}
            </p>
          </div>
        ))}
      </div>
    </PopupContent>
  );
};

export default PreferenceCategory;
