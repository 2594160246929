import * as queryString from 'query-string';
import { matchPath } from 'react-router-dom';

import { ROUTE_PATH, ROUTE_NAME } from './constants';

const ROUTES_TO_HIDE_FOOTER_RULE = [
  {
    path: ROUTE_PATH[ROUTE_NAME.MATCHES],
    shouldHide: () => true,
  },
  {
    path: ROUTE_PATH[ROUTE_NAME.MATCHES_DETAILS],
    shouldHide: () => true,
  },
  {
    path: ROUTE_PATH[ROUTE_NAME.SIGN_UP],
    shouldHide: ({ provider }) => !provider,
  },
  {
    path: ROUTE_PATH[ROUTE_NAME.SEND_TO_CLINICAL_TEAM],
    shouldHide: () => true,
  },
  {
    path: ROUTE_PATH[ROUTE_NAME.TREATMENT_FINDER],
    shouldHide: () => true,
  },
  {
    path: ROUTE_PATH[ROUTE_NAME.QUESTIONNAIRE],
    shouldHide: ({ isMobile, provider, showIntro }) => (provider ? false : isMobile) || showIntro,
  },
  {
    path: ROUTE_PATH[ROUTE_NAME.MATCHES_MAP],
    shouldHide: ({ isMobile }) => isMobile,
  },
];

export const shouldHideFooter = (pathName, isMobile, provider, showIntro = false) => ROUTES_TO_HIDE_FOOTER_RULE.some((routeRule) => {
  const wasPathnameMatched = matchPath(pathName, {
    path: routeRule.path,
    exact: true,
    strict: true,
  });
  return wasPathnameMatched && routeRule.shouldHide({ isMobile, provider, showIntro });
});

export const getQueryDataFromRootRoute = () => {
  const {
    redirectTo = null,
    after = null,
    partner = null,
    int = null,
    superuser = null,
    lang = null,
    provider = null,
    survey = null,
  } = queryString.parse(window.location.search);

  let redirectToActionProps = null;
  if (redirectTo && after) {
    const routePath = ROUTE_PATH[redirectTo.toUpperCase()];

    if (routePath) {
      redirectToActionProps = {
        eventName: after,
        routePath,
      };
    }
  }

  return {
    partner,
    int,
    redirectToActionProps,
    superuser,
    lang,
    provider,
    survey,
  };
};

export const isInlineEditing = () => !!(new URLSearchParams(window.location.search).get('inline'));
