import { 
    filterByTreatmentType, 
    filterByAdministrationMethod, 
    filterByClinicalTrialPhase,
    filterByInterventionalType
} from './filters';

const getImagePath = (item) => {
    let src = '';
    if (item.sponsorIcon) {
        const path = `${item.sponsorIcon.split('.')[0]}.png`;
        src = `/assets/sponsors/logos/${path}`;
    }
    return src;
};

export {
    getImagePath,
    filterByTreatmentType,
    filterByAdministrationMethod,
    filterByClinicalTrialPhase,
    filterByInterventionalType
}