import React from 'react';
import './style.css';
import { useTranslation } from 'utils/modifiedTranslation';
import Button from 'new-ui/Components/Button';
import AssistanceButton from '../../AssistanceButton';
import { EVENT_TYPES } from '../options';

const EventTypes = ({ onSelectType }) => {
  const { t } = useTranslation();

  const options = Object.values(EVENT_TYPES); 
  
  const handleButtonClick = (name) => {
    onSelectType(name);
  };

  return (
      <div className="main-events">
        <ul>
          {options.map((option, index) => (
            <li key={index}>
              <div className='icon_box' style={{ backgroundColor: option.color }}>
                {option.icon}
              </div>
              <Button
                backgroundColor='#FFF' 
                color='#213332'
                width='100%'
                className='btn' 
                title={t(option.label)}
                action={()=>handleButtonClick(option.key)}
              />
            </li>
          ))}
        </ul>
        <AssistanceButton title={t('events_widget.actions.assistance.button')} onClick={() => {}} />
      </div>
  );
};
export default EventTypes;
