import React, { useState } from 'react';
import './Chat.css';

import PropTypes from 'prop-types';

import RefreshIcon from 'new-ui/assets/icons/refresh.svg';
import useForceUpdate from 'use-force-update';

export const CHAT_TYPES = {
  MESSAGE: 'MESSAGE',
  QUESTION: 'QUESTION',
  ANSWERED_QUESTION: 'ANSWERED_QUESTION',
};

export const CHAT_ANSWER_TYPES = {
  CALLBACK: 'CALLBACK',
};

const Chat = (props) => {
  const { items } = props;
  const [history, setHistory] = useState(items);
  const update = useForceUpdate();

  const scrollToView = () => {
    setTimeout(() => {
      const $el = document.querySelector('.chat > *:last-of-type');
      if ($el) $el.scrollIntoView();
    }, 10);
  };

  const setAnswer = (answer, question) => {
    const answered_question = {
      type: CHAT_TYPES.ANSWERED_QUESTION,
      question,
      answer,
    };
    history[history.length - 1] = answered_question;
    if (answer.onanswer) answer.onanswer(answer, history);
    if (answer.next) history.push(answer.next);
    setHistory(history);
    update();
    scrollToView();
  };

  const goBack = (idx) => {
    const item = history[idx];
    const question = { ...item.question };
    history[idx] = question;
    history.splice(idx + 1, history.length - (idx + 1));
    setHistory(history);
    update();
    scrollToView();
  };

  return (
    <div className="chat">
      {history.map((item, idx) => {
        switch (item.type) {
          default:
          case CHAT_TYPES.MESSAGE:
            return <div key={idx} className="bot">{item.text}</div>;
          case CHAT_TYPES.QUESTION:
            return (
              <React.Fragment key={idx}>
                <div className="bot question">{item.text}</div>
                <div className="user answers">
                  {item.answers.map((answer, answerIdx) => (
                    <div
                      key={answerIdx}
                      className="answer"
                      onClick={() => {
                        setAnswer(answer, item);
                      }}
                    >
                      <div className="bullet" />
                      <div className="answer-text">
                        {answer.text}
                      </div>
                    </div>
                  ))}
                </div>
              </React.Fragment>
            );
          case CHAT_TYPES.ANSWERED_QUESTION:
            return (
              <React.Fragment key={idx}>
                <div className="bot question">{item.question.text}</div>
                <div className="user selected-answer">
                  <img alt="back" src={RefreshIcon} onClick={() => { goBack(idx); }} />
                  <div className="answer answered">
                    <div className="bullet" />
                    <div className="answer-text">
                      {item.answer.text}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            );
        }
      })}
    </div>
  );
};

Chat.propTypes = {
  items: PropTypes.any,
};

export default Chat;
