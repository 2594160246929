/* eslint-disable comma-dangle */
import { authHeaders } from 'functions/authHeader';
import { GA_CHAT_EVENTS } from 'new-ui/Components/GoogleAnalytics/generalEvents';
import app from 'new-ui/app';
import { getMissingCriticalFromTrials } from 'new-ui/functions';
import configs from '../../../configs';

const apiUrl = configs.beApiUrl;
/**
 * Starts a chat session with the given parameters.
 *
 * @param {string} prompt - The prompt for the chat session
 * @param {string} wixId - The Wix ID of the user
 * @param {Array} userTrialList - The list of trials for the user
 * @param {Array} treatmentCategories - The categories of treatment
 * @return {Promise} The response from the chat session
 */
export const startChatSession = async (
  prompt,
  wixId,
  userTrialList,
  treatmentCategories,
  socTreatmentsCategories,
  socTreatments,
  userLocation,
  setShowChatNextScan
) => {
  const payload = {
    prompt,
    userWixId: wixId,
    userTrialList,
    treatmentCategories,
    socTreatmentsCategories,
    socTreatments,
    userLocation,
    missingCritical: getMissingCriticalFromTrials(app?.trials),
  };
  setShowChatNextScan(false);
  app.sendGoogleAnalyticsEvent(GA_CHAT_EVENTS.PROFILE_CHAT_STARTED, 'user start profile chat');
  const response = await fetch(`${apiUrl}/chat/startChatSession`, {
    method: 'POST',
    headers: await authHeaders(),
    body: JSON.stringify(payload),
  });

  return response.ok ? response.json() : Promise.reject(new Error(response.statusText));
};

/**
 * Sends a message to the chat API and returns the response.
 *
 * @param {string} prompt - The prompt for the message
 * @param {string[]} userTrialList - The list of user's Nct
 * @param {string} userWixId - The user's Wix ID
 * @param {string[]} treatmentCategories - The categories of treatment
 * @param {boolean} missingCritical - Indicates if there are missing critical details
 * @param {string} indication - The indication for the message
 * @param {string} userChatMode - The user's chat mode
 * @return {Promise<Object>} The response from the chat API
 */
export const sendMessage = async (
  prompt,
  userTrialList,
  userWixId,
  treatmentCategories,
  missingCritical,
  indication,
  userChatMode,
  userHaveSponseredTrialList,
  userSponsoredTrials,
  sponsoredTrialsExaplained,
  socTreatmentsCategories,
  socTreatments,
  userLocation,
  setShowChatNextScan
) => {
  const apiPath = `chatSessionUserMode${userChatMode}`;
  const payload = JSON.stringify({
    prompt,
    userTrialList,
    userWixId,
    treatmentCategories,
    missingCritical,
    indication,
    userChatMode,
    userHaveSponseredTrialList,
    userSponsoredTrials,
    sponsoredTrialsExaplained,
    socTreatmentsCategories,
    socTreatments,
    userLocation,
  });

  try {
    setShowChatNextScan(false);
    app.sendGoogleAnalyticsEvent(GA_CHAT_EVENTS.PROFILE_CHAT_USER_SEND_MESSAGE_TO_CHAT, 'user send msg to chat');
    const response = await fetch(`${apiUrl}/chat/${apiPath}`, {
      method: 'POST',
      headers: await authHeaders(),
      body: payload,
    });

    if (!response.ok) {
      throw new Error(`Error sending message: ${response.statusText}`);
    }

    const ans = await response.json();
    return ans;
  } catch (error) {
    console.error('Error sending message:', error);
  }
};
/**
 * Updates the chat score for a user.
 *
 * @param {string} userWixId - The Wix ID of the user.
 * @param {number} score - The new score for the user.
 * @return {Promise<void>} A promise that resolves when the score is updated.
 */

export const updateChatScore = async (userWixId, score) => {
  const data = {
    userWixId,
    score,
  };
  await fetch(`${apiUrl}/chat/updateChatScore`, {
    method: 'POST',
    headers: await authHeaders(),
    body: JSON.stringify(data),
  });
  app.sendGoogleAnalyticsEvent(GA_CHAT_EVENTS.PROFILE_CHAT_USER_UPDATE_SCORING, 'user update score in profile chat');
};
