export const iDontKnowOption = {
  label: 'questionnaire.options.global.unknown',
  value: 'unknown',
};

export const notSureOption = {
  label: 'questionnaire.options.global.not_sure',
  value: 'not_sure',
};

export const yesAndNoOptions = [
  {
    label: 'questionnaire.options.global.yes',
    value: 'yes',
  },
  {
    label: 'questionnaire.options.global.no',
    value: 'no',
  },
];

export const yesNoIAmNotSureOptions = [
  {
    label: 'questionnaire.options.global.yes',
    value: 'yes',
  },
  {
    label: 'questionnaire.options.global.no',
    value: 'no',
  },
  {
    label: 'questionnaire.options.global.not_sure',
    value: 'not_sure',
  },
];

export const takingStateOptions = [
  { label: 'questionnaire.options.global.still_taking', value: 'taking' },
  { label: 'questionnaire.options.global.finished_taking', value: 'finished' },
];

export const treatmentCyclesOptions = Array.from({ length: 8 }).map((i, index) => ({
  label: `questionnaire.options.global.cycles.${index + 1}`,
  value: String(index + 1),
}));
