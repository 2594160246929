import React, { useState, useEffect, useRef } from 'react';
import './style.css';
import { Button } from '@material-ui/core';
import EmailIcon from 'new-ui/assets/icons/_email.svg';
import PDFIcon from 'new-ui/assets/icons/download-pdf.svg';
import EditIcon from 'new-ui/assets/icons/edit_treatment.svg';
import TrashIcon from 'new-ui/assets/icons/trash_orange.svg';
import app from 'new-ui/app';
import { PAGES, URI } from 'new-ui/constants';
import LimitedItemList from './LimitedItemList';
// import MoreOptionsButton from './MoreOptionsButton';
// import NewLabel from './NewLabel';
import Tooltip from './Tooltip';
import ShareButton from '../ShareButton';
import TooltipButton from './TooltipButton';
import ArchiveCard from '../ArchiveCard';
import ShareWithColleagueCard from '../ShareWithColleagueCard';
import ShareWithPatientCard from '../ShareWithPatientCard';
import Modal from '../Modal';
import { formatDate } from '../utils';

const PatientsList = ({ patients, indications }) => {
  const [visibleTooltipId, setVisibleTooltipId] = useState(null);
  const [visibleTooltipType, setVisibleTooltipType] = useState(null);
  const tooltipRefs = useRef({});

  // const toggleTooltip = (patientId) => {
  //   setVisibleTooltipId((prevId) => (prevId === patientId ? null : patientId));
  // };

  const closeModal = () => {
    setVisibleTooltipId(null);
  };

  const onClickOpen = (patient) => {
    delete patient._details;
    app.hcpUser = patient;
    // app.history.push(`${URI[PAGES.SEARCH]}?patient_id=${patient.id}`);
    localStorage.setItem('set_patient', patient.info.user_wix_id);
    window.location = URI[PAGES.SEARCH];
  };

  // const onClickMore = (patient_id) => {
  //   setVisibleTooltipType('options')
  //   if(visibleTooltipId === patient_id) {
  //     toggleTooltip(null);
  //     return
  //   }
  //   toggleTooltip(patient_id);
  // };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        visibleTooltipId
        && tooltipRefs.current[visibleTooltipId]
        && !tooltipRefs.current[visibleTooltipId].contains(event.target)
        && !event.target.closest('.more-options-button')
      ) {
        setVisibleTooltipId(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [visibleTooltipId]);

  return (
    <ul className="patient-list">
      <li>
        <div className="patient-name">Name</div>
        <div className="patient-profile">Medical profile</div>
        <div className="patient-trial">
          {/* Clinical trial */}
        </div>
        <div className="patient-treatment">
          {/* Standard treatment */}
        </div>
        <div className="patient-support">Financial Support</div>
        <div className="patient-actions" />
      </li>
      {patients.map((patient, index) => (
        <li key={index}>
          <div className="patient-name">{patient.info.family_name}</div>
          <div className="patient-profile">
            <div className="updated_at">
              Updated:
              {formatDate(patient.personal.profile_updated_at)}
            </div>
            <div className="info">{indications[patient.personal.condition].title}</div>
          </div>

          <div className="patient-trial">
            <div className="details">
              {/* <div className="count">{patient.trials.length}</div> */}
              {/* <div className="count">{[]}</div> */}
              {/* <NewLabel /> */}
            </div>
            <LimitedItemList items={[]} />
          </div>
          <div className="patient-treatment">
            <div className="count">{[]}</div>
            <LimitedItemList items={[]} />
          </div>

          <div className="patient-support">
            <div className="count">{patient._details?.programsCount || null}</div>
            <LimitedItemList items={patient._details?.aidTypes || []} />
          </div>
          <div className="patient-actions">
            <Button onClick={() => onClickOpen(patient)} className="open-button">
              <span className="label">Open</span>
            </Button>
            {/* <MoreOptionsButton onClick={() => onClickMore(patient.id)} /> */}
            <Tooltip
              ref={(el) => (tooltipRefs.current[patient.id] = el)}
              visible={visibleTooltipId === patient.id}
            >
              {visibleTooltipType === 'options' && (
                <>
                  <ShareButton
                    title="Share with Colleague"
                    onClick={()=> {
                      setVisibleTooltipType('shareWithColleague');
                    }}
                  />
                  <TooltipButton
                    icon={EmailIcon}
                    title="Share with patients"
                    onClick={()=> {
                      setVisibleTooltipType('shareWithPatient');
                    }}
                  />
                  <TooltipButton icon={PDFIcon} title="Download" onClick={()=>{}} />
                  <TooltipButton icon={EditIcon} title="Edit" onClick={()=>{}} />
                  <TooltipButton
                    icon={TrashIcon}
                    title="Archive"
                    onClick={()=> {
                      setVisibleTooltipType('archive');
                    }}
                  />
                </>
              )}
              {visibleTooltipType === 'archive' && (
                <Modal onClose={closeModal}>
                  <ArchiveCard
                    onClose={closeModal}
                    onArchivePatient={()=>{}}
                  />
                </Modal>
              )}
              {visibleTooltipType === 'shareWithColleague' && (
                <Modal onClose={closeModal}>
                  <ShareWithColleagueCard
                    onClose={closeModal}
                    onArchivePatient={()=>{}}
                  />
                </Modal>
              )}
              {visibleTooltipType === 'shareWithPatient' && (
                <Modal onClose={closeModal}>
                  <ShareWithPatientCard
                    onClose={closeModal}
                    onArchivePatient={()=>{}}
                  />
                </Modal>
              )}
            </Tooltip>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default PatientsList;
