const GA_CATEGORY_CALL_US_POPUP_NAME = 'Call us popup';

const callUsPopupEvents = {
  talkToUsClicked: {
    eventName: 'Talk to us clicked',
    getMetaData: () => ({
      source: 'call us popup',
    }),
  },
  contactUsPoppedAuto: {
    eventName: 'Contact us popped automatically',
    getMetaData: () => ({
      source: 'call us popup',
    }),
  },
  contactUsPopupViewed: {
    eventName: 'Contact us popup viewed',
    getMetaData: () => ({
      source: 'call us popup',
    }),
  },
  callUsClicked: {
    eventName: 'Call us Clicked',
    getMetaData: () => ({
      source: 'call us popup',
    }),
  },
  callMissed: {
    eventName: 'Call missed',
    getMetaData: () => ({
      source: 'call us popup',
    }),
  },

};

export {
  GA_CATEGORY_CALL_US_POPUP_NAME,
  callUsPopupEvents,
};
