import React, { useState } from 'react';
import './style.css';
import { PAGES, ROUTES } from 'new-ui/constants';
import app from 'new-ui/app';
import NewButton from '../NewButton';
import IndicationChoose from '../IndicationChoose';

const PatientsFooter = ({ indications }) => {
  const [indication, setIndication] = useState(null);
  const onSearchIndication = (value) => {
    setIndication(value);
  };
  const onButtonClick = () => {
    app.history.push(`${ROUTES[PAGES.SEARCH]}?${indication ? `indication=${indication}` : 'new=1'}`);
  };

  return (
    <div className="patients-footer">
      <span className="description">Start a new search:</span>
      <IndicationChoose onChange={onSearchIndication} options={indications} />
      <NewButton onClick={onButtonClick} />
    </div>
  );
};

export default PatientsFooter;
