export const TREATMENT_ADMIN = 'treatment_admin';

export default {
  family_name: 'family_name',
  preferred_location: 'preferred_location',
  travel_limit: 'travel_limit',
  user_type: 'user_type',
  looking_for: 'looking_for',
  disease_status: 'disease_status',
  initial_diagnosis_date: 'initial_diagnosis_date',
  dob: 'dob',
  race: 'race',
  gender: 'gender',
  biomarkers: 'biomarkers',
  insurances: 'insurances',
  have_insurance: 'have_insurance',
  pd_l1_expression: 'pd_l1_expression',
  pd_l1_carrier_status: 'pd_l1_carrier_status',
  triple_question_drop_down: 'triple_question_drop_down',
  procedures: {
    questionId: 'procedures',
    subQuestionIds: {
      procedures_received: 'procedures_received',
      procedure_date: 'procedure_date',
      procedure_outcome: 'procedure_outcome',
    },
  },
  treatments: {
    questionId: 'treatments',
    subQuestionIds: {
      drugs_received: 'drugs_received',
      treatment_admin: TREATMENT_ADMIN,
      treatment_end_date: 'treatment_end_date',
      treatment_stop_reason: 'treatment_stop_reason',
    },
  },
  tnm: 'tnm',
  tnm_t: 'tnm_t',
  tnm_n: 'tnm_n',
  tnm_m: 'tnm_m',
  current_stage: 'current_stage',
};
