import { customPaths } from 'utils/constants/customPaths';
import {
  MONTH_PICKER,
  SINGLE_OPTION_SELECT,
  NUMBER_INPUT,
  BIOMARKERS,
  MULTIPLE_OPTION_SELECT,
} from '../../constants/questionTypes';
import { MelanomaProfile as ID } from './MelanomaProfile.enum';
import ConditionTypes from '../../constants/conditions';
import i from '../../constants/icons';
import dateType from '../../../../utils/constants/dateTypes';
import numberType from '../../../../utils/constants/numberTypes';
import {
  preferredLocation, travelLimit, userType, lookingFor,
  initialDiagnosisDate,
} from '../../constants/globalQuestions';
import SECTIONS from '../../constants/questionSections';
import questionOptions from '../../constants/questionOptions';
import GLOBAL_ENUMS from '../../constants/globalEnums';

const { biomarkers: BIOMARKERS_QUESTION_ID } = GLOBAL_ENUMS;

const noneOption = questionOptions.NONE;
const {
  // NOT_EQUAL_TO_OR_SKIPPED,
  EQUAL_TO,
  CONTAIN_ONE_OF,
  AGE_LESS_THAN,
  // IS_SKIPPED,
  // DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
} = ConditionTypes;

const stopReasonConditions = [
  {
    label: 'questionnaire.questions.melanoma.stop_reason_conditions.not stop',
    value: 'not stop',
  },
  {
    label: 'questionnaire.questions.melanoma.stop_reason_conditions.remission',
    value: 'remission',
  },
  {
    label: 'questionnaire.questions.melanoma.stop_reason_conditions.disease progression',
    value: 'disease progression',
  },
  {
    label: 'questionnaire.questions.melanoma.stop_reason_conditions.toxicity',
    value: 'toxicity',
  },
  {
    label: 'questionnaire.questions.melanoma.stop_reason_conditions.other',
    value: 'other',
  },
];

// const infoGeneral = {
//   title: 'questionnaire.questions.melanoma.info_general.title',
//   content: 'questionnaire.questions.melanoma.info_general.content',
// };

const infoWhere = {
  title: 'questionnaire.questions.melanoma.info_where.title',
  content: 'questionnaire.questions.melanoma.info_where.content',
};

const infoRemission = {
  title: 'questionnaire.questions.melanoma.info_remission.title',
  content: 'questionnaire.questions.melanoma.info_remission.content',
};

const Questions = [
  {
    q: 'Q6',
    id: ID.diagnosis_stage,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.melanoma.3_diagnosis_stage.title',

    info: {
      title: 'questionnaire.questions.melanoma.3_diagnosis_stage.info.title',
      content: 'questionnaire.questions.melanoma.3_diagnosis_stage.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma.3_diagnosis_stage.options.1',
        value: '1',
      },
      {
        label: 'questionnaire.questions.melanoma.3_diagnosis_stage.options.2',
        value: '2',
      },
      {
        label: 'questionnaire.questions.melanoma.3_diagnosis_stage.options.4',
        value: '4',
      },
      {
        label: 'questionnaire.questions.melanoma.3_diagnosis_stage.options.999',
        value: '999',
      },
      {
        label: 'questionnaire.questions.melanoma.3_diagnosis_stage.options.3.1',
        value: '3.1',
      },
      {
        label: 'questionnaire.questions.melanoma.3_diagnosis_stage.options.3.2',
        value: '3.2',
      },
      {
        label: 'questionnaire.questions.melanoma.3_diagnosis_stage.options.3.3',
        value: '3.3',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q7',
    id: ID.initial_location,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.melanoma.4_initial_location.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma.4_initial_location.options.skin',
        value: 'skin',
      },
      {
        label: 'questionnaire.questions.melanoma.4_initial_location.options.eye',
        value: 'eye',
      },
      {
        label: 'questionnaire.questions.melanoma.4_initial_location.options.mucosal',
        value: 'mucosal',
      },
      {
        label: 'questionnaire.questions.melanoma.4_initial_location.options.unknown',
        value: 'unknown',
      },
      {
        label: 'questionnaire.questions.melanoma.4_initial_location.options.other',
        value: 'other',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q14',
    id: ID.tumor_meausrable,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.melanoma.5_tumor_meausrable.title',

    info: {
      title: 'questionnaire.questions.melanoma.5_tumor_meausrable.info.title',
      content: 'questionnaire.questions.melanoma.5_tumor_meausrable.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma.5_tumor_meausrable.options.Y',
        value: 'Y',
      },
      {
        label: 'questionnaire.questions.melanoma.5_tumor_meausrable.options.N',
        value: 'N',
      },
      {
        label: 'questionnaire.questions.melanoma.5_tumor_meausrable.options.NS',
        value: 'NS',
      },
    ],
    conditions: [],
  },
  {
    // TODO: create a conversion for MM from CM
    q: 'Q15',
    id: ID.tumor_size,
    type: NUMBER_INPUT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.melanoma.6_tumor_size.title',
    subtitle: 'questionnaire.questions.melanoma.6_tumor_size.subtitle',
    info: infoWhere,
    options: [],
    conditions: [
      {
        ifId: ID.tumor_meausrable,
        type: EQUAL_TO,
        ifValue: 'Y',
      },
    ],
    instruction: 'questionnaire.questions.melanoma.6_tumor_size.instruction',
    numberType: numberType.fraction,
  },
  {
    q: 'Q8',
    id: ID.tumor_locations,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.melanoma.7_tumor_locations.title',
    subtitle: 'questionnaire.questions.melanoma.7_tumor_locations.subtitle',
    info: {
      title: 'questionnaire.questions.melanoma.7_tumor_locations.info.title',
      content: 'questionnaire.questions.melanoma.7_tumor_locations.info.content',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.melanoma.7_tumor_locations.options.abdomen',
        value: 'abdomen',
      },
      {
        label: 'questionnaire.questions.melanoma.7_tumor_locations.options.bone',
        value: 'bone',
      },
      {
        label: 'questionnaire.questions.melanoma.7_tumor_locations.options.brain',
        value: 'brain',
      },
      {
        label: 'questionnaire.questions.melanoma.7_tumor_locations.options.distant_lymph',
        value: 'distant_lymph',
      },
      {
        label: 'questionnaire.questions.melanoma.7_tumor_locations.options.neartumorlymph',
        value: 'neartumorlymph',
      },
      {
        label: 'questionnaire.questions.melanoma.7_tumor_locations.options.distant_skin',
        value: 'distant_skin',
      },
      {
        label: 'questionnaire.questions.melanoma.7_tumor_locations.options.lung',
        value: 'lung',
      },
      {
        label: 'questionnaire.questions.melanoma.7_tumor_locations.options.liver',
        value: 'liver',
      },
      {
        label: 'questionnaire.questions.melanoma_2.12_metastasis_locations.options.satellite_in_transit',
        value: 'satellite_in_transit',
      },
      {
        label: 'questionnaire.questions.melanoma.7_tumor_locations.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.diagnosis_stage,
        type: EQUAL_TO,
        ifValue: '4',
      },
    ],
  },
  {
    q: 'Q9',
    id: ID.leptomeningeal,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.melanoma.8_leptomeningeal.title',
    subtitle: 'questionnaire.questions.melanoma.8_leptomeningeal.subtitle',
    info: {
      title: 'questionnaire.questions.melanoma.8_leptomeningeal.info.title',
      content: 'questionnaire.questions.melanoma.8_leptomeningeal.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma.8_leptomeningeal.options.Y',
        value: 'Y',
      },
      {
        label: 'questionnaire.questions.melanoma.8_leptomeningeal.options.N',
        value: 'N',
      },
    ],
    conditions: [
      {
        ifId: ID.tumor_locations,
        type: CONTAIN_ONE_OF,
        ifValue: ['brain'],
      },
    ],
  },
  {
    // TODO : should fix issue with brain metastasis active (probably add another attribute to user table) Fix Matching Query
    q: 'Q10',
    id: ID.matastesis_in_brain,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.melanoma.9_matastesis_in_brain.title',
    subtitle: 'questionnaire.questions.melanoma.9_matastesis_in_brain.subtitle',
    info: {
      title: 'questionnaire.questions.melanoma.9_matastesis_in_brain.info.title',
      content: 'questionnaire.questions.melanoma.9_matastesis_in_brain.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma.9_matastesis_in_brain.options.Y',
        value: 'Y',
      },
      {
        label: 'questionnaire.questions.melanoma.9_matastesis_in_brain.options.N',
        value: 'N',
      },
    ],
    conditions: [
      {
        ifId: ID.tumor_locations,
        type: CONTAIN_ONE_OF,
        ifValue: ['brain'],
      },
    ],
  },
  {
    q: 'Q11',
    id: ID.have_biopsy,
    path: customPaths.biopsy,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.molecular,
    order: 0,
    title: 'questionnaire.questions.melanoma.10_have_biopsy.title',
    subtitle: 'questionnaire.questions.melanoma.10_have_biopsy.subtitle',
    info: {
      title: 'questionnaire.questions.melanoma.10_have_biopsy.info.title',
      content: 'questionnaire.questions.melanoma.10_have_biopsy.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma.10_have_biopsy.options.Y',
        value: 'Y',
      },
      {
        label: 'questionnaire.questions.melanoma.10_have_biopsy.options.N',
        value: 'N',
      },
      {
        label: 'questionnaire.questions.melanoma.10_have_biopsy.options.NS',
        value: 'NS',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q12',
    id: BIOMARKERS_QUESTION_ID,
    type: BIOMARKERS,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.molecular,
    order: 0,
    title: 'questionnaire.questions.melanoma.11_biomarkers.title',
    subtitle: 'questionnaire.questions.melanoma.11_biomarkers.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: null,
    conditions: [
      {
        ifId: ID.have_biopsy,
        type: EQUAL_TO,
        ifValue: 'Y',
      },
    ],
  },
  {
    q: 'Q13',
    id: ID.tumor_unresectable,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.melanoma.12_tumor_unresectable.title',

    info: {
      title: 'questionnaire.questions.melanoma.12_tumor_unresectable.info.title',
      content: 'questionnaire.questions.melanoma.12_tumor_unresectable.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma.12_tumor_unresectable.options.N',
        value: 'N',
      },
      {
        label: 'questionnaire.questions.melanoma.12_tumor_unresectable.options.Y',
        value: 'Y',
      },
      {
        label: 'questionnaire.questions.melanoma.12_tumor_unresectable.options.NA',
        value: 'NA',
      },
      {
        label: 'questionnaire.questions.melanoma.12_tumor_unresectable.options.NS',
        value: 'NS',
      },
    ],
    conditions: [
      {
        ifId: ID.diagnosis_stage,
        type: CONTAIN_ONE_OF,
        ifValue: ['3.1', '3.2', '3.3', '4'],
      },
    ],
  },
  {
    q: 'Q16',
    id: ID.tumor_detectable_by_radiography,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.melanoma.13_tumor_detectable_by_radiography.title',

    info: {
      title: 'questionnaire.questions.melanoma.13_tumor_detectable_by_radiography.info.title',
      content: 'questionnaire.questions.melanoma.13_tumor_detectable_by_radiography.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma.13_tumor_detectable_by_radiography.options.Y',
        value: 'Y',
      },
      {
        label: 'questionnaire.questions.melanoma.13_tumor_detectable_by_radiography.options.N',
        value: 'N',
      },
      {
        label: 'questionnaire.questions.melanoma.13_tumor_detectable_by_radiography.options.NS',
        value: 'NS',
      },
    ],
    conditions: [
      {
        ifId: ID.diagnosis_stage,
        type: EQUAL_TO,
        ifValue: '4',
      },
    ],
  },
  {
    q: 'Q17',
    id: ID.received_chemotherapy_treatment,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.melanoma.14_received_chemotherapy_treatment.title',

    info: {
      title: 'questionnaire.questions.melanoma.14_received_chemotherapy_treatment.info.title',
      content: 'questionnaire.questions.melanoma.14_received_chemotherapy_treatment.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma.14_received_chemotherapy_treatment.options.Y',
        value: 'Y',
      },
      {
        label: 'questionnaire.questions.melanoma.14_received_chemotherapy_treatment.options.N',
        value: 'N',
      },
      {
        label: 'questionnaire.questions.melanoma.14_received_chemotherapy_treatment.options.NS',
        value: 'NS',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q18',
    id: ID.chemotherapy_treatment,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.melanoma.15_chemotherapy_treatment.title',

    info: {
      title: 'questionnaire.questions.melanoma.15_chemotherapy_treatment.info.title',
      content: 'questionnaire.questions.melanoma.15_chemotherapy_treatment.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma.15_chemotherapy_treatment.options.cisplatin plus dtci and vinblastine',
        value: 'cisplatin plus dtci and vinblastine',
      },
      {
        label: 'questionnaire.questions.melanoma.15_chemotherapy_treatment.options.carmustine',
        value: 'carmustine',
      },
      {
        label: 'questionnaire.questions.melanoma.15_chemotherapy_treatment.options.dacarbazine',
        value: 'dacarbazine',
      },
      {
        label: 'questionnaire.questions.melanoma.15_chemotherapy_treatment.options.oxaliplatin',
        value: 'oxaliplatin',
      },
      {
        label: 'questionnaire.questions.melanoma.15_chemotherapy_treatment.options.carboplatin',
        value: 'carboplatin',
      },
      {
        label: 'questionnaire.questions.melanoma.15_chemotherapy_treatment.options.paclitaxel',
        value: 'paclitaxel',
      },
      {
        label: 'questionnaire.questions.melanoma.15_chemotherapy_treatment.options.paclitaxel plus carboplatin',
        value: 'paclitaxel plus carboplatin',
      },
      {
        label: 'questionnaire.questions.melanoma.15_chemotherapy_treatment.options.docetaxel',
        value: 'docetaxel',
      },
      {
        label: 'questionnaire.questions.melanoma.15_chemotherapy_treatment.options.cisplatin',
        value: 'cisplatin',
      },
      {
        label: 'questionnaire.questions.melanoma.15_chemotherapy_treatment.options.vinblastine',
        value: 'vinblastine',
      },
      {
        label: 'questionnaire.questions.melanoma.15_chemotherapy_treatment.options.temozolomide',
        value: 'temozolomide',
      },
    ],
    conditions: [
      {
        ifId: ID.received_chemotherapy_treatment,
        type: EQUAL_TO,
        ifValue: 'Y',
      },
    ],
  },
  {
    q: 'Q19',
    id: ID.chemotherapy_end_date,
    type: MONTH_PICKER,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.melanoma.16_chemotherapy_end_date.title',

    info: {
      title: '',
      content: '',
    },
    options: [],
    conditions: [
      {
        ifId: ID.received_chemotherapy_treatment,
        type: EQUAL_TO,
        ifValue: 'Y',
      },
    ],
    dateType: {
      type: dateType.currentDate,
    },
  },
  {
    q: 'Q20',
    id: ID.chemotherapy_stop_reason,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.melanoma.17_chemotherapy_stop_reason.title',

    info: infoRemission,
    options: stopReasonConditions,
    conditions: [
      {
        ifId: ID.received_chemotherapy_treatment,
        type: EQUAL_TO,
        ifValue: 'Y',
      },
    ],
  },
  {
    q: 'Q17a',
    id: ID.adjuvant_chemotherapy_treatment,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.melanoma.18_adjuvant_chemotherapy_treatment.title',

    info: {
      title: 'questionnaire.questions.melanoma.18_adjuvant_chemotherapy_treatment.info.title',
      content: 'questionnaire.questions.melanoma.18_adjuvant_chemotherapy_treatment.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma.18_adjuvant_chemotherapy_treatment.options.Y',
        value: 'Y',
      },
      {
        label: 'questionnaire.questions.melanoma.18_adjuvant_chemotherapy_treatment.options.N',
        value: 'N',
      },
      {
        label: 'questionnaire.questions.melanoma.18_adjuvant_chemotherapy_treatment.options.NS',
        value: 'NS',
      },
    ],
    conditions: [
      {
        ifId: ID.received_chemotherapy_treatment,
        type: EQUAL_TO,
        ifValue: 'Y',
      },
    ],
  },
  {
    q: 'Q21',
    id: ID.received_immunotheraphy_and_traget_drug_treatment,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.melanoma.19_received_immunotheraphy_and_traget_drug_treatment.title',

    info: {
      title: 'questionnaire.questions.melanoma.19_received_immunotheraphy_and_traget_drug_treatment.info.title',
      content: 'questionnaire.questions.melanoma.19_received_immunotheraphy_and_traget_drug_treatment.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma.19_received_immunotheraphy_and_traget_drug_treatment.options.Y',
        value: 'Y',
      },
      {
        label: 'questionnaire.questions.melanoma.19_received_immunotheraphy_and_traget_drug_treatment.options.N',
        value: 'N',
      },
      {
        label: 'questionnaire.questions.melanoma.19_received_immunotheraphy_and_traget_drug_treatment.options.NS',
        value: 'NS',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q22',
    id: ID.immunotheraphy_and_traget_drugs,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.melanoma.20_immunotheraphy_and_traget_drugs.title',
    subtitle: 'questionnaire.questions.melanoma.20_immunotheraphy_and_traget_drugs.subtitle',
    info: {
      title: 'questionnaire.questions.melanoma.20_immunotheraphy_and_traget_drugs.info.title',
      content: 'questionnaire.questions.melanoma.20_immunotheraphy_and_traget_drugs.info.content',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.melanoma.20_immunotheraphy_and_traget_drugs.options.ipilimumab',
        value: 'ipilimumab',
      },
      {
        label: 'questionnaire.questions.melanoma.20_immunotheraphy_and_traget_drugs.options.nivolumab',
        value: 'nivolumab',
      },
      {
        label: 'questionnaire.questions.melanoma.20_immunotheraphy_and_traget_drugs.options.pembrolizumab',
        value: 'pembrolizumab',
      },
      {
        label: 'questionnaire.questions.melanoma.20_immunotheraphy_and_traget_drugs.options.otherantipdantibody',
        value: 'otherantipdantibody',
      },
      {
        label: 'questionnaire.questions.melanoma.20_immunotheraphy_and_traget_drugs.options.ipilimumabnivolumabcombo',
        value: 'ipilimumabnivolumabcombo',
      },
      {
        label: 'questionnaire.questions.melanoma.20_immunotheraphy_and_traget_drugs.options.dabrafenib',
        value: 'dabrafenib',
      },
      {
        label: 'questionnaire.questions.melanoma.20_immunotheraphy_and_traget_drugs.options.vemurafenib',
        value: 'vemurafenib',
      },
      {
        label: 'questionnaire.questions.melanoma.20_immunotheraphy_and_traget_drugs.options.cobimetinib',
        value: 'cobimetinib',
      },
      {
        label: 'questionnaire.questions.melanoma.20_immunotheraphy_and_traget_drugs.options.trametinib',
        value: 'trametinib',
      },
      {
        label: 'questionnaire.questions.melanoma.20_immunotheraphy_and_traget_drugs.options.tafmekcombo',
        value: 'tafmekcombo',
      },
      {
        label: 'questionnaire.questions.melanoma.20_immunotheraphy_and_traget_drugs.options.cotelliczelboraf',
        value: 'cotelliczelboraf',
      },
    ],
    conditions: [
      {
        ifId: ID.received_immunotheraphy_and_traget_drug_treatment,
        type: EQUAL_TO,
        ifValue: 'Y',
      },
    ],
  },
  {
    q: 'Q23',
    id: ID.immunotheraphy_end_date,
    type: MONTH_PICKER,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.melanoma.21_immunotheraphy_end_date.title',

    info: {
      title: '',
      content: '',
    },
    options: [],
    conditions: [
      {
        ifId: ID.received_immunotheraphy_and_traget_drug_treatment,
        type: EQUAL_TO,
        ifValue: 'Y',
      },
    ],
    dateType: {
      type: dateType.currentDate,
    },
  },
  {
    q: 'Q24',
    id: ID.immunotherapy_stop_reason,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.melanoma.22_immunotherapy_stop_reason.title',

    info: infoRemission,
    options: stopReasonConditions,
    conditions: [
      {
        ifId: ID.received_immunotheraphy_and_traget_drug_treatment,
        type: EQUAL_TO,
        ifValue: 'Y',
      },
    ],
  },
  {
    q: 'Q25',
    id: ID.adjuvant_immunotherapy_treatment,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.melanoma.23_adjuvant_immunotherapy_treatment.title',

    info: {
      title: 'questionnaire.questions.melanoma.23_adjuvant_immunotherapy_treatment.info.title',
      content: 'questionnaire.questions.melanoma.23_adjuvant_immunotherapy_treatment.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma.23_adjuvant_immunotherapy_treatment.options.Y',
        value: 'Y',
      },
      {
        label: 'questionnaire.questions.melanoma.23_adjuvant_immunotherapy_treatment.options.N',
        value: 'N',
      },
      {
        label: 'questionnaire.questions.melanoma.23_adjuvant_immunotherapy_treatment.options.NS',
        value: 'NS',
      },
    ],
    conditions: [
      {
        ifId: ID.received_immunotheraphy_and_traget_drug_treatment,
        type: EQUAL_TO,
        ifValue: 'Y',
      },
    ],
  },
  {
    q: 'Q26',
    id: ID.radiation_treatment,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.melanoma.24_radiation_treatment.title',

    info: {
      title: 'questionnaire.questions.melanoma.24_radiation_treatment.info.title',
      content: 'questionnaire.questions.melanoma.24_radiation_treatment.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma.24_radiation_treatment.options.Y',
        value: 'Y',
      },
      {
        label: 'questionnaire.questions.melanoma.24_radiation_treatment.options.N',
        value: 'N',
      },
      {
        label: 'questionnaire.questions.melanoma.24_radiation_treatment.options.NS',
        value: 'NS',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q27',
    id: ID.radiation_end_date,
    type: MONTH_PICKER,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.melanoma.25_radiation_end_date.title',

    info: {
      title: '',
      content: '',
    },
    options: [],
    conditions: [
      {
        ifId: ID.radiation_treatment,
        type: EQUAL_TO,
        ifValue: 'Y',
      },
    ],
    dateType: {
      type: dateType.currentDate,
    },
  },
  {
    q: 'Q29',
    id: ID.received_other_investigational_drug_treatment,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.plane_hospital_pills,
    order: 0,
    title: 'questionnaire.questions.melanoma.26_received_other_investigational_drug_treatment.title',

    info: {
      title: 'questionnaire.questions.melanoma.26_received_other_investigational_drug_treatment.info.title',
      content: 'questionnaire.questions.melanoma.26_received_other_investigational_drug_treatment.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma.26_received_other_investigational_drug_treatment.options.Y',
        value: 'Y',
      },
      {
        label: 'questionnaire.questions.melanoma.26_received_other_investigational_drug_treatment.options.N',
        value: 'N',
      },
      {
        label: 'questionnaire.questions.melanoma.26_received_other_investigational_drug_treatment.options.NS',
        value: 'NS',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q30',
    id: ID.other_investigational_drugs,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.plane_hospital_pills,
    order: 0,
    title: 'questionnaire.questions.melanoma.27_other_investigational_drug_1.title',
    subtitle: 'questionnaire.questions.melanoma.27_other_investigational_drug_1.subtitle',
    info: {
      title: 'questionnaire.questions.melanoma.27_other_investigational_drug_1.info.title',
      content: 'questionnaire.questions.melanoma.27_other_investigational_drug_1.info.content',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.melanoma.27_other_investigational_drug_1.options.t-vec',
        value: 't-vec',
      },
      {
        label: 'questionnaire.questions.melanoma.27_other_investigational_drug_1.options.cdk4/6 inhibitors',
        value: 'cdk4/6 inhibitors',
      },
    ],
    conditions: [
      {
        ifId: ID.received_other_investigational_drug_treatment,
        type: EQUAL_TO,
        ifValue: 'Y',
      },
    ],
  },
  {
    q: 'Q62',
    id: ID.ecog,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.melanoma.28_ecog.title',

    info: {
      title: 'questionnaire.questions.melanoma.28_ecog.info.title',
      content: 'questionnaire.questions.melanoma.28_ecog.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma.28_ecog.options.0',
        value: '0',
      },
      {
        label: 'questionnaire.questions.melanoma.28_ecog.options.1',
        value: '1',
      },
      {
        label: 'questionnaire.questions.melanoma.28_ecog.options.2',
        value: '2',
      },
      {
        label: 'questionnaire.questions.melanoma.28_ecog.options.3',
        value: '3',
      },
      {
        label: 'questionnaire.questions.melanoma.28_ecog.options.4',
        value: '4',
      },
      {
        label: 'questionnaire.questions.melanoma.28_ecog.options.999',
        value: '999',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q50',
    id: ID.other_medical_conditions,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.melanoma.29_other_medical_conditions.title',
    subtitle: 'questionnaire.questions.melanoma.29_other_medical_conditions.subtitle',
    info: {
      title: 'questionnaire.questions.melanoma.29_other_medical_conditions.info.title',
      content: 'questionnaire.questions.melanoma.29_other_medical_conditions.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma.29_other_medical_conditions.options.none',
        value: 'none',
        exclusive: true,
      },
      {
        label: 'questionnaire.questions.melanoma.29_other_medical_conditions.options.liver',
        value: 'liver',
      },
      {
        label: 'questionnaire.questions.melanoma.29_other_medical_conditions.options.heart',
        value: 'heart',
      },
      {
        label: 'questionnaire.questions.melanoma.29_other_medical_conditions.options.kidney',
        value: 'kidney',
      },
      {
        label: 'questionnaire.questions.melanoma.29_other_medical_conditions.options.autoimmune',
        value: 'autoimmune',
      },
      {
        label: 'questionnaire.questions.melanoma.29_other_medical_conditions.options.mental',
        value: 'mental',
      },
      {
        label: 'questionnaire.questions.melanoma.29_other_medical_conditions.options.allergies',
        value: 'allergies',
      },
      {
        label: 'questionnaire.questions.melanoma.29_other_medical_conditions.options.chronic_infection_or_inflammation',
        value: 'chronic_infection_or_inflammation',
      },
      {
        label: 'questionnaire.questions.melanoma.29_other_medical_conditions.options.gastrointestinal',
        value: 'gastrointestinal',
      },
      {
        label: 'questionnaire.questions.melanoma.29_other_medical_conditions.options.other_cancer_type',
        value: 'other_cancer_type',
      },
      {
        label: 'questionnaire.questions.melanoma.29_other_medical_conditions.options.anemia',
        value: 'anemia',
      },
      {
        label: 'questionnaire.questions.melanoma.29_other_medical_conditions.options.thyroid',
        value: 'thyroid',
      },
      {
        label: 'questionnaire.questions.melanoma.29_other_medical_conditions.options.hypertension',
        value: 'hypertension',
      },
      {
        label: 'questionnaire.questions.melanoma.29_other_medical_conditions.options.epilepsy',
        value: 'epilepsy',
      },
      {
        label: 'questionnaire.questions.melanoma.29_other_medical_conditions.options.diabetes',
        value: 'diabetes',
      },
      {
        label: 'questionnaire.questions.melanoma.29_other_medical_conditions.options.organ_transplant',
        value: 'organ_transplant',
      },
      {
        label: 'questionnaire.questions.melanoma.29_other_medical_conditions.options.extreme_obesity',
        value: 'extreme_obesity',
      },
      {
        label: 'questionnaire.questions.melanoma.29_other_medical_conditions.options.stroke',
        value: 'stroke',
      },
      {
        label: 'questionnaire.questions.melanoma.29_other_medical_conditions.options.lung',
        value: 'lung',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q52',
    id: ID.liver_problem,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.melanoma.30_liver_problem.title',

    info: {
      title: 'questionnaire.questions.melanoma.30_liver_problem.info.title',
      content: 'questionnaire.questions.melanoma.30_liver_problem.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma.30_liver_problem.options.elevated_alt_ast',
        value: 'elevated_alt_ast',
      },
      {
        label: 'questionnaire.questions.melanoma.30_liver_problem.options.elevated_bilirubin',
        value: 'elevated_bilirubin',
      },
      {
        label: 'questionnaire.questions.melanoma.30_liver_problem.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_conditions,
        type: CONTAIN_ONE_OF,
        ifValue: ['liver'],
      },
    ],
  },
  {
    q: 'Q53',
    id: ID.heart_problem,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.melanoma.31_heart_problem.title',

    info: {
      title: 'questionnaire.questions.melanoma.31_heart_problem.info.title',
      content: 'questionnaire.questions.melanoma.31_heart_problem.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma.31_heart_problem.options.class_at_least_nyha_ii',
        value: 'class_at_least_nyha_ii',
      },
      {
        label: 'questionnaire.questions.melanoma.31_heart_problem.options.condition_at_least_grade_2',
        value: 'condition_at_least_grade_2',
      },
      {
        label: 'questionnaire.questions.melanoma.31_heart_problem.options.cardiac_ventricular_arrhythmia',
        value: 'cardiac_ventricular_arrhythmia',
      },
      {
        label: 'questionnaire.questions.melanoma.31_heart_problem.options.heart_failure',
        value: 'heart_failure',
      },
      {
        label: 'questionnaire.questions.melanoma.31_heart_problem.options.lvef_under_50_percent',
        value: 'lvef_under_50_percent',
      },
      {
        label: 'questionnaire.questions.melanoma.31_heart_problem.options.qtcf_abnormal',
        value: 'qtcf_abnormal',
      },
      {
        label: 'questionnaire.questions.melanoma.31_heart_problem.options.angina',
        value: 'angina',
      },
      {
        label: 'questionnaire.questions.melanoma.31_heart_problem.options.myocardial_infarction',
        value: 'myocardial_infarction',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_conditions,
        type: CONTAIN_ONE_OF,
        ifValue: ['heart'],
      },
    ],
  },
  {
    q: 'Q54',
    id: ID.stroke_date,
    type: MONTH_PICKER,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.melanoma.32_stroke_date.title',

    info: {
      title: 'questionnaire.questions.melanoma.32_stroke_date.info.title',
      content: 'questionnaire.questions.melanoma.32_stroke_date.info.content',
    },
    options: [],
    conditions: [
      {
        ifId: ID.other_medical_conditions,
        type: CONTAIN_ONE_OF,
        ifValue: ['stroke'],
      },
    ],
    dateType: {
      type: dateType.currentDate,
    },
  },
  {
    q: 'Q55',
    id: ID.kidney_problem,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.melanoma.33_kidney_problem.title',

    info: {
      title: 'questionnaire.questions.melanoma.33_kidney_problem.info.title',
      content: 'questionnaire.questions.melanoma.33_kidney_problem.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma.33_kidney_problem.options.low_glomerular_filtration_rate',
        value: 'low_glomerular_filtration_rate',
      },
      {
        label: 'questionnaire.questions.melanoma.33_kidney_problem.options.dialysis',
        value: 'dialysis',
      },
      {
        label: 'questionnaire.questions.melanoma.33_kidney_problem.options.kidney_stones',
        value: 'kidney_stones',
      },
      {
        label: 'questionnaire.questions.melanoma.33_kidney_problem.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_conditions,
        type: CONTAIN_ONE_OF,
        ifValue: ['kidney'],
      },
    ],
  },
  {
    q: 'Q56',
    id: ID.autoimmune_condition,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.melanoma.34_autoimmune_condition.title',

    info: {
      title: 'questionnaire.questions.melanoma.34_autoimmune_condition.info.title',
      content: 'questionnaire.questions.melanoma.34_autoimmune_condition.info.content',
    },
    options: [
      {
        label:
          'questionnaire.questions.melanoma.34_autoimmune_condition.options.congenital_or_acquired_immunosuppression',
        value: 'congenital_or_acquired_immunosuppression',
      },
      {
        label: 'questionnaire.questions.melanoma.34_autoimmune_condition.options.hepatitis',
        value: 'hepatitis',
      },
      {
        label: 'questionnaire.questions.melanoma.34_autoimmune_condition.options.granulomatous_disease',
        value: 'granulomatous_disease',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_conditions,
        type: CONTAIN_ONE_OF,
        ifValue: ['autoimmune'],
      },
    ],
  },
  {
    q: 'Q57',
    id: ID.mental_disorder,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.melanoma.35_mental_disorder.title',

    info: {
      title: 'questionnaire.questions.melanoma.35_mental_disorder.info.title',
      content: 'questionnaire.questions.melanoma.35_mental_disorder.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma.35_mental_disorder.options.dementia',
        value: 'dementia',
      },
      {
        label: 'questionnaire.questions.melanoma.35_mental_disorder.options.dysphrenia',
        value: 'dysphrenia',
      },
      {
        label: 'questionnaire.questions.melanoma.35_mental_disorder.options.neuropsychyatric',
        value: 'neuropsychyatric',
      },
      {
        label: 'questionnaire.questions.melanoma.35_mental_disorder.options.depression',
        value: 'depression',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_conditions,
        type: CONTAIN_ONE_OF,
        ifValue: ['mental'],
      },
    ],
  },
  {
    q: 'Q58',
    id: ID.allergies,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.melanoma.36_allergies.title',

    info: {
      title: 'questionnaire.questions.melanoma.36_allergies.info.title',
      content: 'questionnaire.questions.melanoma.36_allergies.info.content',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.melanoma.36_allergies.options.interferon',
        value: 'interferon',
      },
      {
        label: 'questionnaire.questions.melanoma.36_allergies.options.metformin',
        value: 'metformin',
      },
      {
        label: 'questionnaire.questions.melanoma.36_allergies.options.vitD',
        value: 'vitD',
      },
      {
        label: 'questionnaire.questions.melanoma.36_allergies.options.penicillin',
        value: 'penicillin',
      },
      {
        label: 'questionnaire.questions.melanoma.36_allergies.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_conditions,
        type: CONTAIN_ONE_OF,
        ifValue: ['allergies'],
      },
    ],
  },
  {
    q: 'Q59',
    id: ID.infection_or_inflammation,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.melanoma.37_infection_or_inflammation.title',

    info: {
      title: 'questionnaire.questions.melanoma.37_infection_or_inflammation.info.title',
      content: 'questionnaire.questions.melanoma.37_infection_or_inflammation.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma.37_infection_or_inflammation.options.hepatitis',
        value: 'hepatitis',
      },
      {
        label: 'questionnaire.questions.melanoma.37_infection_or_inflammation.options.hiv',
        value: 'hiv',
      },
      {
        label: 'questionnaire.questions.melanoma.37_infection_or_inflammation.options.other',
        value: 'other',
      },
      {
        label: 'questionnaire.questions.melanoma.37_infection_or_inflammation.options.elevated_wbc',
        value: 'elevated_wbc',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_conditions,
        type: CONTAIN_ONE_OF,
        ifValue: ['chronic_infection_or_inflammation'],
      },
    ],
  },
  {
    q: 'Q61',
    id: ID.gastro_issue,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.melanoma.38_gastro_issue.title',

    info: {
      title: 'questionnaire.questions.melanoma.38_gastro_issue.info.title',
      content: 'questionnaire.questions.melanoma.38_gastro_issue.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma.38_gastro_issue.options.gastrointestinal_bleeding',
        value: 'gastrointestinal_bleeding',
      },
      {
        label: 'questionnaire.questions.melanoma.38_gastro_issue.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_conditions,
        type: CONTAIN_ONE_OF,
        ifValue: ['gastrointestinal'],
      },
    ],
  },
  {
    q: 'Q60',
    id: ID.blood_test,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.melanoma.39_blood_test.title',

    info: {
      title: 'questionnaire.questions.melanoma.39_blood_test.info.title',
      content: 'questionnaire.questions.melanoma.39_blood_test.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma.39_blood_test.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.melanoma.39_blood_test.options.low_wbc',
        value: 'low_wbc',
      },
      {
        label: 'questionnaire.questions.melanoma.39_blood_test.options.low_hemoglobin',
        value: 'low_hemoglobin',
      },
      {
        label: 'questionnaire.questions.melanoma.39_blood_test.options.low_potassium',
        value: 'low_potassium',
      },
      {
        label: 'questionnaire.questions.melanoma.39_blood_test.options.low_magnesium',
        value: 'low_magnesium',
      },
      {
        label: 'questionnaire.questions.melanoma.39_blood_test.options.clotting_coagulation_platelets',
        value: 'clotting_coagulation_platelets',
      },
    ],
    conditions: [],
  },
  // Supposed to be lung question Q59
  {
    q: 'Q51',
    id: ID.other_medications,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.melanoma.40_other_medications.title',

    info: {
      title: 'questionnaire.questions.melanoma.40_other_medications.info.title',
      content: 'questionnaire.questions.melanoma.40_other_medications.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma.40_other_medications.options.CYP3A inhibitors',
        value: 'CYP3A inhibitors',
      },
      {
        label: 'questionnaire.questions.melanoma.40_other_medications.options.immunosuppressive medications',
        value: 'immunosuppressive medications',
      },
      {
        label: 'questionnaire.questions.melanoma.40_other_medications.options.none',
        value: 'none',
      },
    ],
    conditions: [],
  },
  preferredLocation,
  travelLimit,
  initialDiagnosisDate('Q5'),
  userType,
  lookingFor,
  {
    q: 'Q1',
    id: ID.dob,
    type: MONTH_PICKER,
    section: SECTIONS.ABOUT_ME,
    bgIcon: i.user,
    order: 0,
    title: 'questionnaire.questions.melanoma.0_dob.title',
    info: {
      title: '',
      content: '',
    },
    options: [],
    conditions: [],
    dateType: {
      type: dateType.dob,
    },
  },
  {
    q: 'Q2',
    id: ID.gender,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.ABOUT_ME,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.melanoma.1_gender.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma.1_gender.options.female',
        value: 'female',
      },
      {
        label: 'questionnaire.questions.melanoma.1_gender.options.male',
        value: 'male',
      },
      {
        label: 'questionnaire.questions.global.gender.options.other',
        value: 'other',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q3',
    id: ID.pregnant,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.ABOUT_ME,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.melanoma.41_pregnant.title',
    info: {
      title: 'questionnaire.questions.melanoma.41_pregnant.info.title',
      content: 'questionnaire.questions.melanoma.41_pregnant.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma.41_pregnant.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.melanoma.41_pregnant.options.no',
        value: 'no',
      },
    ],
    conditions: [
      {
        ifId: ID.gender,
        type: EQUAL_TO,
        ifValue: 'female',
      },
      {
        type: AGE_LESS_THAN,
        ifValue: 50,
      },
    ],
  },
  {
    q: 'Q4',
    id: ID.breastfeeding,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.ABOUT_ME,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.melanoma.42_breastfeeding.title',
    info: {
      title: 'questionnaire.questions.melanoma.42_breastfeeding.info.title',
      content: 'questionnaire.questions.melanoma.42_breastfeeding.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.melanoma.42_breastfeeding.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.melanoma.42_breastfeeding.options.no',
        value: 'no',
      },
    ],
    conditions: [
      {
        ifId: ID.gender,
        type: EQUAL_TO,
        ifValue: 'female',
      },
      {
        type: AGE_LESS_THAN,
        ifValue: 50,
      },
    ],
  },
];

export default Questions;
