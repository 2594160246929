import React from 'react';
import PropTypes from 'prop-types';

import CrashImage from 'new-ui/assets/images/crash.svg';
import { MARKETING_SITE } from 'new-ui/constants';
import './index.css';

const Crash = ({ isVisible }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <div className='crash'>
      <img src={CrashImage} alt='Something went wrong!' />
      <p className='error'>Whoops... something is not working well</p>
      <p>
        We are working to solve the problem. In the meantime,
        you can learn about us on our <a href={MARKETING_SITE}>site</a>.
      </p>
      <p className='footer'>© {(new Date()).getFullYear()} by LEAL Health</p>
    </div>
  );
}

Crash.propTypes = {
  isVisible: PropTypes.bool,
};

export default Crash;
