import React from 'react';
import './PartnersAndPubications.css';
import { useTranslation } from 'utils/modifiedTranslation';
import ImgACS from './assets/partners/ACS_logo.svg';
import ImgTEMPUS from './assets/partners/TEMPUS.svg';
import ImgColorecta from './assets/partners/colorecta.svg';
import ImgMPN from './assets/partners/MPN.svg';
import ImgMCGI from './assets/partners/MCGI.svg';
import ImgALLIANCE from './assets/partners/ALLIANCE.svg';
import ImgLeukemia from './assets/partners/Leukemia.svg';
import ImgBREAST from './assets/partners/BREAST.svg';
import ImgSave from './assets/partners/save.svg';
import ImgSHARSHERET from './assets/partners/SHARSHERET.svg';
import ImgNAHAC from './assets/partners/NAHAC.svg';
import ImgCANCER from './assets/partners/CANCER.svg';
import ImgPatient from './assets/partners/Patient.svg';
import ImgBULLETPROOF from './assets/partners/BULLETPROOF.svg';
import ImgAIM from './assets/partners/AIM.svg';
import ImgWellness from './assets/partners/Wellness.svg';
import ImgBREASTCANCER from './assets/partners/BREASTCANCER.svg';
import ImgBpresent from './assets/partners/Bpresent.svg';
import ImgOurBrain from './assets/partners/OurBrain.svg';
import ImgArizona from './assets/partners/Arizona.svg';
import ImgPatientNetwork from './assets/partners/PatientNetwork.svg';
import ImgWomen from './assets/partners/Women.svg';
import ImgSUMMUS from './assets/partners/SUMMUS.svg';

import ImgTime from './assets/publications/Time.svg';
import ImgMHealth from './assets/publications/MHealth.svg';
import ImgFIERCE from './assets/publications/FIERCE.svg';
import ImgMEDS from './assets/publications/MEDS.svg';
import ImgCLINICAL from './assets/publications/CLINICAL.svg';
import ImgPharmacy from './assets/publications/Pharmacy.svg';
import ImgWHITE from './assets/publications/WHITE.svg';
import ImgBUSINESS from './assets/publications/BUSINESS.svg';
import ImgLETTER from './assets/publications/LETTER.svg';
import ImgAURN from './assets/publications/AURN.svg';
import ImgMedical from './assets/publications/Medical.svg';
import ImgCancer from './assets/publications/Cancer.svg';
import ImgEmpowered from './assets/publications/Empowered.svg';
import ImgCitybiz from './assets/publications/citybiz.svg';
import ImgAI from './assets/publications/AI.svg';
import ImgWSJ from './assets/publications/WSJ.svg';
import ImgMobi from './assets/publications/mobi.svg';
import ImgFORTUNE from './assets/publications/FORTUNE.svg';
import ImgCRAINS from './assets/publications/CRAINS.svg';
import ImgHIT from './assets/publications/HIT.svg';
import ImgAnswers from './assets/publications/Answers.svg';
import ImgPharma from './assets/publications/Pharma.svg';
import ImgFINSMES from './assets/publications/FINSMES.svg';
import ImgPODCAST from './assets/publications/PODCAST.svg';
import ImgBio from './assets/publications/Bio.svg';

const PartnersAndPubications = () => {
  const { t } = useTranslation();

  const partnersImages = [
    ImgACS, ImgTEMPUS, ImgColorecta, ImgMPN, ImgMCGI, ImgALLIANCE,
    ImgLeukemia, ImgBREAST, ImgSave, ImgSHARSHERET, ImgNAHAC, ImgCANCER, ImgPatient,
    ImgBULLETPROOF, ImgAIM, ImgWellness, ImgBREASTCANCER,
    ImgBpresent, ImgOurBrain, ImgArizona, ImgPatientNetwork, ImgWomen, ImgSUMMUS,
  ];

  const publicationsImages = [
    ImgTime, ImgMHealth, ImgFIERCE, ImgMEDS, ImgCLINICAL, ImgPharmacy, ImgWHITE, ImgBUSINESS, ImgLETTER,
    ImgAURN, ImgMedical, ImgCancer, ImgEmpowered, ImgCitybiz, ImgAI, ImgWSJ, ImgMobi, ImgFORTUNE,
    ImgCRAINS, ImgHIT, ImgAnswers, ImgPharma, ImgFINSMES, ImgPODCAST, ImgBio,
  ];
  return (
    <div>
      <div className="partners">
        <div className="title">{t('upload.our_partners_text')}</div>
        <div className="partners-image">
          {partnersImages.map((image, key)=>{
            return <img key={key} src={image} alt="partners" />;
          })}
        </div>
      </div>
      {/* <div className="publications-articles">
        <div className="title">{t('upload.publication_articles_text')}</div>
        <div className="publications-articles-image">
          {publicationsImages.map((image, key)=>{
            return <img key={key} src={image} alt="publications-articles" />;
          })}
        </div>
      </div> */}
    </div>
  );
};

export default PartnersAndPubications;
