/**
 *
 * @param files {Array<File>}
 * @param presignedUrls {Array<{ key: string, url: string }>}
 * @returns {Array<{ url: string, file: File }>}
 */
export const getUploadFiles = (files, presignedUrls) => {
  const uploadFiles = [];

  for (const file of files) {
    const presignedUrl = presignedUrls.find(({ key }) => key.includes(file.name));

    if (!presignedUrl) {
      throw new Error(`No presigned url found for file ${file.name}`);
    }

    uploadFiles.push({
      key: presignedUrl.key,
      url: presignedUrl.url,
      file,
    });
  }

  return uploadFiles;
};

/**
 * @param fn () => Promise<T>
 * @param fnCondition (result: T) => boolean
 * @param intervalMs number
 * @returns {Promise<T>}
 */
export async function poll(fn, fnCondition, intervalMs) {
  const waitAndCall = async () => {
    await wait(intervalMs);
    return await fn();
  };

  let result = await waitAndCall();

  while (fnCondition(result)) {
    result = await waitAndCall();
  }

  return result;
}

/**
 * @param fnCondition () => boolean
 * @param intervalMs number
 * @param timeoutMs number
 * @returns {Promise<boolean>}
 */
export async function waitUntil(fnCondition, intervalMs, timeoutMs = 300_000) {
  const timeout = Date.now() + timeoutMs;

  while (!fnCondition()) {
    await wait(intervalMs);

    if (Date.now() > timeout) {
      return false;
    }
  }

  return true;
}

/**
 * @param ms number
 * @returns {Promise<unknown>}
 */
async function wait(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
