export default {
  SET_IS_NEW_PROFILE: 'SET_IS_NEW_PROFILE',
  SET_REDIRECT_PATH_ONCE_QUESTIONNAIRE_COMPLETED: 'SET_REDIRECT_PATH_ONCE_QUESTIONNAIRE_COMPLETED',
  SET_CAME_FROM: 'SET_CAME_FROM',
  SET_URL_PARAMETERS: 'SET_URL_PARAMETERS',
  SET_LANG: 'SET_LANG',
  SET_IS_MOBILE: 'SET_IS_MOBILE',
  CLEAR_URL_PARAMETERS_AND_CAME_FROM: 'CLEAR_URL_PARAMETERS_AND_CAME_FROM',
  SET_LAST_OPENED_URL: 'SET_LAST_OPENED_URL',
};
