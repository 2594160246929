import serverStatusConstants from '../constants/serverStatus';

const initialState = {
  isFetching: false,
  reasonMessage: null,
  solutionMessage: null,
  error: null,
};

export function serverStatus(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case serverStatusConstants.SERVER_STATUS_REQUEST:
      return {
        isFetching: true,
        error: null,
      };
    case serverStatusConstants.SERVER_STATUS_SUCCESS:
      return {
        isFetching: false,
        error: null,
      };
    case serverStatusConstants.SERVER_STATUS_FAILURE:
      return {
        isFetching: false,
        reasonMessage: payload.reasonMessage || state.reasonMessage,
        solutionMessage: payload.solutionMessage || state.solutionMessage,
        error: payload.error || state.error,
      };
    default:
      return state;
  }
}
