import React, { useState } from 'react';
import './style.css';
import { useTranslation } from 'utils/modifiedTranslation';

const StepTwo = ({ options, onChange }) => {
    const { t } = useTranslation();

    const [selectedOption, setSelectedOption] = useState(null);
    const [startIndex, setStartIndex] = useState(0);
    const itemsPerPage = 7;
    const shiftAmount = 3;

    const handleNext = () => {
        if (startIndex + itemsPerPage < options.length) {
            setStartIndex(startIndex + shiftAmount);
        }
    };

    const handlePrev = () => {
        if (startIndex > 0) {
            setStartIndex(Math.max(startIndex - shiftAmount, 0));
        }
    };

    const handleSelect = (value) => {
        setSelectedOption(value);
        onChange(value);
    };

    const visibleOptions = options.slice(startIndex, startIndex + itemsPerPage);

    return (
        <div className='step step-2'>
            {/* <h2 className='drug-title'>Cisplatin</h2> */}
            <h2 className='title'>{t('treatment_widget.steps.cycles.title')}</h2>
            <div className='scroll-container'>
                <ul className='treatment-list'>

                    {startIndex > 0 && (
                        <li className='treatment-item scroll-button left' onClick={handlePrev}>
                            <div className='label'>&lt;</div>
                        </li>
                    )}

                    {visibleOptions.map((option) => (
                        <li
                            className={`treatment-item ${selectedOption === option.value ? 'selected' : ''}`}
                            key={option.value}
                        >
                            <label className='label'>
                                <input
                                    type='radio'
                                    name='treatment'
                                    value={option.value}
                                    checked={selectedOption === option.value}
                                    onChange={() => handleSelect(option.value)}
                                />
                                {option.label}
                            </label>
                        </li>
                    ))}

                    {startIndex + itemsPerPage < options.length && (
                        <li className='treatment-item scroll-button right' onClick={handleNext}>
                            <div className='label'>&gt;</div>
                        </li>
                    )}

                </ul>
            </div>
        </div>
    );
};

export default StepTwo;
