import React, { useState } from 'react';
import './Debug.css';

import app from 'new-ui/app';

import PopupContent from 'new-ui/Components/Popup/PopupContent';
import { prettyPrintJson } from 'pretty-print-json';
import Form, { FORM_INPUT_TYPES as INPUT_TYPES } from '../../Components/Form';
import MatchesApi from '../../../components/Matches/api';

const data = {
  nctId: '',
};
const inputs = {
  nctId: {
    title: 'Nct Id',
    type: INPUT_TYPES.INPUT,
    required: true,
  },
};
const api = new MatchesApi();

const DebugPopup = () => {
  const { user } = app;
  const [fnFp, setFnFp] = useState(false);

  const explainFnFp = () => {
    app.setShowPreloader(true);
    api.explainFnFp(data).then((result) => {
      setFnFp(result.data);
    }).catch((err) => {
      setFnFp(err.toString());
    }).finally(() => {
      app.setShowPreloader(false);
    });
  };

  return (
    <>
      <h2>Fn/Fp</h2>
      <Form inputs={inputs} data={data} popupMode submit={{ title: 'Explain', action: explainFnFp }} />
      <PopupContent>
        <pre>{fnFp}</pre>
        <pre className="json-container" dangerouslySetInnerHTML={{ __html: prettyPrintJson.toHtml(user) }} />
      </PopupContent>
    </>
  );
};

export default DebugPopup;
