/* eslint-disable comma-dangle */
import { authHeaders } from 'functions/authHeader';
import { GA_UPLOAD_CHAT_EVENTS } from 'new-ui/Components/GoogleAnalytics/generalEvents';
import app from 'new-ui/app';
import configs from '../../../configs';

const apiUrl = configs.beApiUrl;

/**
 * Sends a message to the chat API and returns the response.
 *
 * @param {string} prompt - The prompt for the message
 * @param {string} userWixId - The user's Wix ID
 * @param {string} indication - The indication for the message
 * @return {Promise<Object>} The response from the chat API
 */
export const sendMessage = async (prompt, userWixId, indication, extractedInfo) => {
  const apiPath = 'chatUploadSession';
  const payload = JSON.stringify({
    prompt,
    userWixId,
    indication,
    extractedInfo,
  });
  app.sendGoogleAnalyticsEvent(GA_UPLOAD_CHAT_EVENTS.UPLOAD_CHAT_USER_SEND_MESSAGE_TO_CHAT, 'user send msg to upload chat');
  try {
    const response = await fetch(`${apiUrl}/uploadChat/${apiPath}`, {
      method: 'POST',
      headers: await authHeaders(),
      body: payload,
    });

    if (!response.ok) {
      throw new Error(`Error sending message: ${response.statusText}`);
    }

    const ans = await response.json();
    return ans;
  } catch (error) {
    console.error('Error sending message:', error);
  }
};
/**
 * Updates the chat score for a user.
 *
 * @param {string} userWixId - The Wix ID of the user.
 * @param {number} score - The new score for the user.
 * @return {Promise<void>} A promise that resolves when the score is updated.
 */
