import React, { useState } from 'react';
import './MultiTags.css';

import checkIcon from 'new-ui/assets/icons/check_green.svg';
import xOrange from 'new-ui/assets/icons/x_orange.svg';
import AddButton from 'new-ui/assets/icons/add_round.svg';

import PropTypes from 'prop-types';

const MultiTags = (props)=>{
  const {
    tags: _tags,
    onSelect,
    onClick = ()=>{},
    onAdd,
    addTitle = 'Add',
    isStatic,
  } = props;
  const [tags, setTags] = useState(_tags);
  return (
    <div className="multi-tags-selector" onClick={onClick}>
      {
        tags.map((tag, k)=>{
          return (
            <div
              key={k}
              className={`multi-tags-tag ${tag.isSelected ? 'multi-tags-tag-selected' : ''} ${isStatic ? 'multi-tags-tag-static' : ''}`}
              onClick={()=>{
                tag.isSelected = !tag.isSelected;
                setTags([...tags]);
                onSelect(tags);
              }}
            >
              {
                !isStatic && !tag.isSelected ? (
                  <div className="multi-tags-tag-icon-empty" />
                ) : (
                  <div className="multi-tags-tag-icon">
                    <img src={isStatic ? xOrange : checkIcon} alt="check" />
                  </div>
                )
              }
              <div className="multi-tags-tag-title">{tag.title}</div>
            </div>
          );
        })
      }
      {
        onAdd ? (
          <div className="multi-tags-tag multi-tags-add-tag" onClick={onAdd}>
            <img src={AddButton} alt="add" />
            <div className="multi-tags-add-tag-title">{addTitle}</div>
          </div>
        ) : null
      }
    </div>
  );
};

MultiTags.propTypes = {
  isStatic: PropTypes.bool,
  tags: PropTypes.array,
  onSelect: PropTypes.func,
  onClick: PropTypes.func,
  onAdd: PropTypes.func,
  addTitle: PropTypes.string,
};

export default MultiTags;
