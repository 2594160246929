/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import app from 'new-ui/app';
import { WEEK_IN_MS } from 'new-ui/constants';
import lastTreatmentIcon from 'new-ui/assets/icons/last_treatment.svg';
import attentionIcon from 'new-ui/assets/icons/attention_icon.svg';
import editTreatmentIcon from 'new-ui/assets/icons/edit_treatment.svg';
import { profileTreatmentSectionEvents, GA_CATEGORY_NAME } from 'new-ui/Components/GoogleAnalytics/profileSectionEvents';
import globalEnums from 'components/Questionnaire/constants/globalEnums';
import { takingStateOptions } from 'components/Questionnaire/constants/globalOptions';
import './ProfileTreatment.css';
import { SurveyPopup } from './SurveyPopup';

const {
  treatmentTakingStatusClicked,
  treatmentStopReasonClicked,
  surveyHelpOtherClicked,
  surveyNotReportedViewed,
} = profileTreatmentSectionEvents;

export const LastTreatment = ({
  previousTreatments,
  lastTreatment,
  getDrugsTranslatedLabel,
  setShowTreatmentsAlert,
  showTreatmentsAlert,
  editTreatment,
  treatmentStopReasonOptions,
  treatmentAdminOptions,
  setDrawerContent,
  conditionName,
  showSurveyBtn,
  lastTreatmentDrug,
}) => {
  const { t } = useTranslation();
  const takingState = Array.isArray(lastTreatment.taking_state)
    ? lastTreatment.taking_state[0] : lastTreatment.taking_state;
  const stillTakingOrUnknown = !lastTreatment.treatment_stop_reason || lastTreatment.treatment_stop_reason === 'treatment_ongoing';
  const showTreatmentStatus = (stillTakingOrUnknown && !takingState)
    || (stillTakingOrUnknown
      && takingState === 'taking'
      && lastTreatment.updated_taking_state_date
      && Date.now() - lastTreatment.updated_taking_state_date > WEEK_IN_MS);
  const showTreatmentOutcome = !lastTreatment.treatment_stop_reason && takingState !== 'taking';

  useEffect(() => {
    if (showSurveyBtn) {
      app.sendGoogleAnalyticsEvent(
        GA_CATEGORY_NAME,
        surveyNotReportedViewed.eventName,
        surveyNotReportedViewed.getMetaData(),
      );
    }
  }, [showSurveyBtn]);

  const hasAlert = showTreatmentStatus || showTreatmentOutcome || showSurveyBtn;

  if (hasAlert && !showTreatmentsAlert) {
    setShowTreatmentsAlert(true);
  } else if (!hasAlert && showTreatmentsAlert) {
    setShowTreatmentsAlert(false);
  }

  const openSurveyFlow = () => {
    app.sendGoogleAnalyticsEvent(
      GA_CATEGORY_NAME,
      surveyHelpOtherClicked.eventName,
      surveyHelpOtherClicked.getMetaData(),
    );
    const drawerContent = (
      <SurveyPopup setDrawerContent={setDrawerContent} conditionName={conditionName} lastTreatment={lastTreatment} lastTreatmentDrug={lastTreatmentDrug} />
    );
    setDrawerContent(drawerContent);
  };

  const getStopReasonLabel = (value) => treatmentStopReasonOptions.find(
    (reason) => reason.value === (Array.isArray(value) ? value[0] : value),
  )?.label;
  const getTreatmentAdminLabel = (value) => treatmentAdminOptions.find(
    (reason) => reason.value === (Array.isArray(value) ? value[0] : value),
  )?.label;

  const renderTreatmentType = () => lastTreatment.treatment_admin && (
    <div className="treatmentAdminWrapper">
      <p className="treatmentAdminTitle">Type:</p>
      <span className="treatmentAdminValue">{t(getTreatmentAdminLabel(lastTreatment.treatment_admin))}</span>
    </div>
  );
  const renderWarningBlock = (drug, title, options, onClick) => (
    <div className="lastTreatmentStateWrapper">
      <div className="lastTreatmentLabelWrapper">
        <p>{getDrugsTranslatedLabel(t, drug)}</p>
        <img onClick={() => editTreatment(lastTreatment)} src={editTreatmentIcon} alt="edit treatment icon" />
      </div>
      <div className="lastTreatmentTitleWrapper">
        <img src={attentionIcon} alt="attention icon" />
        <span>{t(title)}</span>
      </div>
      <ul>
        {options.map((option, i) => (
          <li onClick={() => onClick(option)} key={i}>
            <span />
            {t(option.label)}
          </li>
        ))}
      </ul>
      {renderTreatmentType()}
    </div>
  );
  const renderLastTreatment = () => {
    if (showTreatmentStatus) {
      const updateTakingStateDate = (takingState, date) => {
        (async () => {
          await app.answerQuestion({
            treatments: [
              {
                ...lastTreatment,
                taking_state: takingState,
                updated_taking_state_date: date,
              },
              ...previousTreatments,
            ],
          });
        })();
      };

      const changeState = (option) => {
        if (option.value === 'taking') {
          app.sendGoogleAnalyticsEvent(
            GA_CATEGORY_NAME,
            treatmentTakingStatusClicked.eventName,
            treatmentTakingStatusClicked.getMetaData('still in treatment'),
          );
          // app.intercom.updateUserAttributes({
          //   last_treatment: lastTreatment.drugs_received.join(' + '),
          //   last_treatment_updating_date: new Date().toLocaleDateString(),
          //   last_treatment_status: 'still in treatment',
          // });
          updateTakingStateDate('taking', Date.now());
        }
        if (option.value === 'finished') {
          app.sendGoogleAnalyticsEvent(
            GA_CATEGORY_NAME,
            treatmentTakingStatusClicked.eventName,
            treatmentTakingStatusClicked.getMetaData('treatment finished'),
          );
          updateTakingStateDate('finished', null);
        }
      };

      return renderWarningBlock(
        lastTreatment.drugs_received,
        t('questionnaire.profile_treatments.show_treatment_status_title'),
        takingStateOptions,
        changeState,
      );
    }

    if (showTreatmentOutcome) {
      const changeTreatmentStopReason = (option) => {
        let questionsToUpdate = {
          treatments: [
            {
              ...lastTreatment,
              treatment_stop_reason: option.value,
            },
            ...previousTreatments,
          ],
        };

        switch (option.value) {
          case 'relapse':
            questionsToUpdate = {
              ...questionsToUpdate,
              [globalEnums.disease_status]: 'refractory_disease',
            };
            break;
          case 'remission':
            questionsToUpdate = {
              ...questionsToUpdate,
              [globalEnums.disease_status]: 'remission',
            };
            break;
          case 'progression':
            questionsToUpdate = {
              ...questionsToUpdate,
              [globalEnums.disease_status]: 'recurrence',
            };
            break;
          case 'no_response':
            questionsToUpdate = {
              ...questionsToUpdate,
              [globalEnums.disease_status]: 'refractory_disease',
            };
            break;

          default:
            break;
        }

        app.sendGoogleAnalyticsEvent(
          GA_CATEGORY_NAME,
          treatmentStopReasonClicked.eventName,
          treatmentStopReasonClicked.getMetaData(option.value),
        );

        (async () => {
          await app.answerQuestion(questionsToUpdate);
        })();
      };

      // app.intercom.updateUserAttributes({
      //   last_treatment: lastTreatment.drugs_received.join(' + '),
      //   last_treatment_updating_date: new Date().toLocaleDateString(),
      //   last_treatment_status: 'missing outcome',
      // });

      return renderWarningBlock(
        lastTreatment.drugs_received,
        t('questionnaire.profile_treatments.show_treatment_outcome_title'),
        treatmentStopReasonOptions,
        changeTreatmentStopReason,
      );
    }

    return (
      <div className="lastTreatmentBody">
        <div className="lastTreatmentInfoWrapper">
          <div className="lastTreatmentLabelWrapper">
            <p>{getDrugsTranslatedLabel(t, lastTreatment.drugs_received)}</p>
            <img onClick={() => editTreatment(lastTreatment)} src={editTreatmentIcon} alt="edit treatment icon" />
          </div>

          {lastTreatment.treatment_stop_reason && (
            <div className="stopReasonWrapper">
              <span className="stopReasonTitle">
                {t('questionnaire.profile_treatments.stop_reason_title')}
                :
              </span>
              {' '}
              <span className="stopReasonValue">
                {t(getStopReasonLabel(lastTreatment.treatment_stop_reason))}
              </span>
            </div>
          )}
          {renderTreatmentType()}
          {showSurveyBtn && (
            <button type="button" onClick={openSurveyFlow} className="mainOrangeButton">
              {t('questionnaire.profile_treatments.survey_btn_title')}
            </button>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="lastTreatmentSection">
      <div className="lastTreatmentHeader">
        <img alt="last treatment icon" src={lastTreatmentIcon} />
        <span>{t('questionnaire.profile_treatments.last_treatment_header_title')}</span>
      </div>
      {renderLastTreatment()}
    </div>
  );
};
