import React from 'react';
import './style.css';
import Button from 'new-ui/Components/Button';

const StatusButton = ({ onClick, title }) => {
  return (
    <Button
        backgroundColor="#213332"
        color="#FFF"
        width="100%"
        className="status-btn"
        title={title}
        action={onClick}
      />
  );
};

export default StatusButton;