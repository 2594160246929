import mainConstants from '../constants/main';
import callToActionConstants from '../constants/callToAction';

const setCameFrom = referrer => {
  return dispatch => {
    if (!referrer) {
      return null;
    }

    return dispatch({
      type: mainConstants.SET_CAME_FROM,
      payload: referrer
    });
  };
};

const setUrlParameters = data => {
  return dispatch => {
    if (!data) {
      return null;
    }

    return dispatch({
      type: mainConstants.SET_URL_PARAMETERS,
      payload: data
    });
  };
};

const setIsMobile = payload => dispatch => {
  dispatch({
    type: mainConstants.SET_IS_MOBILE,
    payload
  });
};

const setMinimizeCallToAction = payload => dispatch => {
  dispatch({
    type: callToActionConstants.SET_MINIMIZE_CALL_TO_ACTION,
    payload
  });
};

export const commonActions = {
  setCameFrom,
  setUrlParameters,
  setIsMobile,
  setMinimizeCallToAction,
};
