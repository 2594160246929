import React, { useState, useEffect } from 'react';
import './style.css';
import { useTranslation } from "utils/modifiedTranslation";
import OptionCheckbox from '../../Checkbox';
import Toggle from '../../Toggle';

const IntInterventionalType = ({ category, selectedOptions, onOptionChange, options, withFilter = false, comp, withSelectAll }) => {
    const { t } = useTranslation();

    const [toggles, setToggles] = useState({
        targeted_to_mutation: false,
        less_toxic: false,
        less_aggressive: false
    });

    const [filteredOptions, setFilteredOptions] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const handleToggleChange = (key, value) => {
        setToggles(prevToggles => ({
            ...prevToggles,
            [key]: value
        }));
    };

    const handleCheckboxChange = (value) => {
        const isChecked = selectedOptions.includes(value);
        onOptionChange(category, value, !isChecked);
    };

    const filterOptionsByToggles = (toggles, options) => {
        const areAllTogglesFalse = Object.values(toggles).every(value => !value);
        if (areAllTogglesFalse) {
            return options;
        }
        return options.filter(option => {
            return option.categories.some(cat => toggles[cat]);
        });
    };

    useEffect(() => {
        const _filteredOptions = filterOptionsByToggles(toggles, options);
        setFilteredOptions(_filteredOptions);
        // eslint-disable-next-line
    }, [toggles]);

    const handleSelectAll = () => {
        if (selectAll) {
            filteredOptions.forEach(option => {
                onOptionChange(category, option.value, false);
            });
        } else {
            filteredOptions.forEach(option => {
                onOptionChange(category, option.value, true);
            });
        }
        setSelectAll(!selectAll);
    };

    return (
        <div className='intInterventional-type-options'>
            {withFilter && (
                <div className='intInterventional-type-filter'>
                    <ul>
                        <li>
                            <Toggle keyProp="targeted_to_mutation" initialState={toggles.targeted_to_mutation} onChange={handleToggleChange} />
                            <span>{t("treatment_options.option.value.for_mutation")}</span>
                        </li>
                        <li>
                            <Toggle keyProp="less_toxic" initialState={toggles.less_toxic} onChange={handleToggleChange} />
                            <span>{t("treatment_options.option.value.less_toxic")}</span>
                        </li>
                        <li>
                            <Toggle keyProp="less_aggressive" initialState={toggles.less_aggressive} onChange={handleToggleChange} />
                            <span>{t("treatment_options.option.value.less_aggressive")}</span>
                        </li>
                    </ul>
                </div>
            )}
            {withSelectAll && (
                <div className='select-all-btn'>
                    <OptionCheckbox
                        label={'All'}
                        checked={selectAll}
                        onChange={handleSelectAll}
                    />
                </div>
            )}
            <ul className='intInterventional-type-list'>
                {filteredOptions.map((option) => (
                    <li key={option.value} style={{ backgroundColor: option.color }}>
                        <OptionCheckbox
                            label={option.label}
                            checked={selectedOptions.includes(option.value)}
                            onChange={() => handleCheckboxChange(option.value)}
                        />
                    </li>
                ))}
                {comp && React.cloneElement(comp)}
            </ul>
        </div>
    );
};

export default IntInterventionalType;