export const MelanomaProfile = {
  condition: 'condition',
  dob: 'dob',
  gender: 'gender',
  pregnant: 'pregnant',
  breastfeeding: 'breastfeeding',
  diagnosis_stage: 'diagnosis_stage',
  initial_location: 'initial_location',
  tumor_location_1: 'tumor_location_1',
  tumor_location_2: 'tumor_location_2',
  tumor_location_3: 'tumor_location_3',
  tumor_location_4: 'tumor_location_4',
  have_biopsy: 'have_biopsy',
  biomarker1: 'biomarker1',
  biomarker2: 'biomarker2',
  biomarker3: 'biomarker3',
  tumor_meausrable: 'tumor_meausrable',
  tumor_size: 'tumor_size',
  tumor_unresectable: 'tumor_unresectable',
  received_immunotheraphy_and_traget_drug_treatment: 'received_immunotheraphy_and_traget_drug_treatment',
  immunotheraphy_and_traget_drug_1: 'immunotheraphy_and_traget_drug_1',
  immunotheraphy_and_traget_drug_2: 'immunotheraphy_and_traget_drug_2',
  immunotheraphy_and_traget_drug_3: 'immunotheraphy_and_traget_drug_3',
  immunotheraphy_and_traget_drug_4: 'immunotheraphy_and_traget_drug_4',
  immunotheraphy_and_traget_drug_5: 'immunotheraphy_and_traget_drug_5',
  immunotheraphy_and_traget_drug_category_1: 'immunotheraphy_and_traget_drug_category_1',
  immunotheraphy_and_traget_drug_category_2: 'immunotheraphy_and_traget_drug_category_2',
  immunotheraphy_and_traget_drug_category_3: 'immunotheraphy_and_traget_drug_category_3',
  immunotheraphy_end_date: 'immunotheraphy_end_date',
  immunotherapy_stop_reason: 'immunotherapy_stop_reason',
  received_chemotherapy_treatment: 'received_chemotherapy_treatment',
  chemotherapy_treatment: 'chemotherapy_treatment',
  chemotherapy_end_date: 'chemotherapy_end_date',
  chemotherapy_stop_reason: 'chemotherapy_stop_reason',
  radiation_treatment: 'radiation_treatment',
  radiation_end_date: 'radiation_end_date',
  radiation_stop_reason: 'radiation_stop_reason',
  adjuvant_immunotherapy_treatment: 'adjuvant_immunotherapy_treatment',
  adjuvant_chemotherapy_treatment: 'adjuvant_chemotherapy_treatment',
  questionnaire9: 'questionnaire9',
  questionnaire10: 'questionnaire10',
  questionnaire15: 'questionnaire15',
  questionnaire1617: 'questionnaire1617',
  metastasis: 'metastasis',
  other_investigational_drug_1: 'other_investigational_drug_1',
  other_investigational_drug_2: 'other_investigational_drug_2',
  leptomeningeal: 'leptomeningeal',
  matastesis_in_brain: 'matastesis_in_brain', // brain metastesis active
  received_other_investigational_drug_treatment: 'received_other_investigational_drug_treatment',
  tumor_detectable_by_radiography: 'tumor_detectable_by_radiography',
  other_medical_condition_1: 'other_medical_condition_1',
  other_medical_condition_2: 'other_medical_condition_2',
  other_medical_condition_3: 'other_medical_condition_3',
  is_pregnant_or_breastfeeding: 'is_pregnant_or_breastfeeding',
  ecog: 'ecog',
  other_medications: 'other_medications',
  liver_problem: 'liver_problem',
  heart_problem: 'heart_problem',
  stroke_date: 'stroke_date',
  kidney_problem: 'kidney_problem',
  autoimmune_condition: 'autoimmune_condition',
  mental_disorder: 'mental_disorder',
  infection_or_inflammation: 'infection_or_inflammation',
  blood_test: 'blood_test',
  gastro_issue: 'gastro_issue',
  qstack: 'qstack',

  // new multi:
  tumor_locations: 'tumor_locations',
  immunotheraphy_and_traget_drugs: 'immunotheraphy_and_traget_drugs',
  immunotheraphy_and_traget_drug_categories: 'immunotheraphy_and_traget_drug_categories',
  other_investigational_drugs: 'other_investigational_drug_1',
  other_medical_conditions: 'other_medical_conditions',
  allergies: 'allergies',
};
