import React, { useState, useEffect } from 'react';
import Select from 'new-ui/Components/Form/Select';
import Button from 'new-ui/Components/Button';
import CheckedIcon from 'new-ui/assets/icons/checked_Icon.svg';
import './style.css';
import { useTranslation } from 'utils/modifiedTranslation';

const StepOne = ({ topSelectedDrugs=[], totalDrugs=[], onChangeTopSelected, onChange, onClickAdd }) => {
    const [selectedValues, setSelectedValues] = useState([]);
    const [topSelectedValues, setTopSelectedValues] = useState([]);
    const [showDrugsSelect, setShowDrugsSelect] = useState(false);
    const { t } = useTranslation();
  
    const handleChange = (newValue) => {
      setSelectedValues(newValue);
      onChange(newValue);
    };
    
    const handleClear = () => {
      setSelectedValues([]);
    };

    const handleCheckboxChange = (value, isChecked) => {
        setTopSelectedValues((prevSelectedDrugs) => {
            if (isChecked) {
                return [...prevSelectedDrugs, value];
            } else {
                return prevSelectedDrugs.filter((drugValue) => drugValue !== value);
            }
        });
    };

    const handleClickAdd = () => {
        onClickAdd([...topSelectedValues, ...selectedValues]);
    };

    useEffect(() => {
        onChangeTopSelected(topSelectedValues);
    }, [topSelectedValues, onChangeTopSelected]);

    useEffect(() => {
        if(topSelectedDrugs.length === 0) {
            setShowDrugsSelect(true)
        }
    },[topSelectedDrugs])

    return (
        <div className='step step-1'>
            <h2 className='title'>{t('treatment_widget.steps.select_drugs.title')}</h2>
           
            {!showDrugsSelect && (
                <div className='drugs'>
                    <ul className='option-list'>
                        {topSelectedDrugs.map((option, index) => (
                            <li
                                className={`option-item ${topSelectedValues.includes(option.value) ? 'selected' : ''}`}
                                key={index}
                            >
                                <div className='label' onClick={() => handleCheckboxChange(option.value,true)}>
                                    <span className='checkbox-imitation'></span>
                                    <span className='txt'>{t(option.label)}</span>
                                    <span className='close-icon' onClick={(e) => {
                                        e.stopPropagation();
                                        handleCheckboxChange(option.value,false)
                                    }}></span>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            )}

            {showDrugsSelect && (
                <div className='drugs-select'>
                    <Select
                        options={totalDrugs}
                        value={selectedValues}
                        onChange={handleChange}
                        onClear={handleClear}
                        multi={true}
                        placeholder="Select Treatment"
                        autocomplete={true}
                        autocompletePlaceholder="Start typing..."
                        isTags={false}
                        withCheckbox={true}
                        defaultOpen={true}
                    />
                </div>
            )}

             <div className='actions'>
                {!showDrugsSelect && (
                    <Button
                        backgroundColor={'#FFF'} 
                        color={'#213332'}
                        width='initial'
                        className='btn' 
                        title={t('treatment_widget.actions.cant_find.button')}
                        action={() => setShowDrugsSelect(true)}
                    />
                )}

                {(topSelectedValues?.length > 0 || selectedValues?.length > 0) && (
                    <Button
                        backgroundColor={'#FFF'} 
                        color={'#213332'}
                        width='176px'
                        className='btn' 
                        title={t('treatment_widget.actions.add.button')}
                        icon={CheckedIcon}
                        action={handleClickAdd}
                    />
                )}
               
            </div>

        </div>
    );
};

export default StepOne;
