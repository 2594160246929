export const Profile = {
  user_type: 'user_type',
  disease_status: 'disease_status',
  dob: 'dob',
  gender: 'gender',
  race: 'race',
  treatments: 'treatments',
  procedures: 'procedures',
  type_of_lymphoma: 'type_of_lymphoma',
  type_of_bcell_lymphoma: 'type_of_bcell_lymphoma',
  type_of_tcell_lymphoma: 'type_of_tcell_lymphoma',
  type_of_aids_related_lymphoma: 'type_of_aids_related_lymphoma',
  indolent_transformed_dlbcl: 'indolent_transformed_dlbcl',
  current_stage: 'current_stage',
  current_stage_follicular_lymphoma: 'current_stage_follicular_lymphoma',
  type_of_dlbcl: 'type_of_dlbcl',
  ebv_dlbcl: 'ebv_dlbcl',
  type_of_marginal_cell_lymphoma: 'type_of_marginal_cell_lymphoma',
  hpylori: 'hpylori',
  hpylori_antbiotic_treatment: 'hpylori_antbiotic_treatment',
  disease_location: 'disease_location',
  metastasis_location_brain_leptomeningeal: 'metastasis_location_brain_leptomeningeal',
  brain_metastasis_active: 'brain_metastasis_active',
  disease_measurable: 'disease_measurable',
  lymphoma_size_characteristics: 'lymphoma_size_characteristics',
  largest_lesion_size_mm: 'largest_lesion_size_mm',
  has_biopsy: 'has_biopsy',
  indolent_lymphoma_burden: 'indolent_lymphoma_burden',
  procedure_candidate: 'procedure_candidate',
  matching_donor: 'matching_donor',
  had_procedure_for_lymphoma: 'had_procedure_for_lymphoma',
  procedures_received: 'procedures_received',
  procedure_date: 'procedure_date',
  procedure_outcome: 'procedure_outcome',
  treatment_for_lymphoma: 'treatment_for_lymphoma',
  drugs_received: 'drugs_received',
  cycles: 'cycles',
  treatment_end_date: 'treatment_end_date',
  treatment_stop_reason: 'treatment_stop_reason',
  gvhd: 'gvhd',
  current_treatment_systemic_steroids: 'current_treatment_systemic_steroids',
  systemic_steroids_treatment_end_date: 'systemic_steroids_treatment_end_date',
  ecog: 'ecog',
  ngs: 'ngs',
  karnofsky: 'karnofsky',
  other_medical_condition: 'other_medical_condition',
  omc_liver: 'omc_liver',
  omc_heart: 'omc_heart',
  omc_stroke_date: 'omc_stroke_date',
  omc_kidney: 'omc_kidney',
  omc_autoimmune: 'omc_autoimmune',
  omc_mental: 'omc_mental',
  omc_allergy: 'omc_allergy',
  omc_chronic_infection_inflammation: 'omc_chronic_infection_inflammation',
  omc_gastro_problem: 'omc_gastro_problem',
  omc_lung_disease: 'omc_lung_disease',
  omc_abnormal_blood_results: 'omc_abnormal_blood_results',
  omc_heart_failure_date: 'omc_heart_failure_date',
  omc_myocardial_infarction_date: 'omc_myocardial_infarction_date',
  other_medications: 'other_medications',
  is_pregnant: 'is_pregnant',
  is_breastfeeding: 'is_breastfeeding',
  have_insurance: 'have_insurance',
  insurances: 'insurances',
};
