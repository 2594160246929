export const Profile = {
  disease_status: 'disease_status',
  dob: 'dob',
  gender: 'gender',
  race: 'race',
  type_of_mm: 'type_of_mm',
  has_mprotein: 'has_mprotein',
  has_mprotein_pre_message: 'has_mprotein_pre_message',
  serum_mprotein_level: 'serum_mprotein_level',
  ig_subtype: 'ig_subtype',
  urine_mprotein_level: 'urine_mprotein_level',
  has_serum_flc: 'has_serum_flc',
  serum_kappa_flc_level: 'serum_kappa_flc_level',
  has_biopsy: 'has_biopsy',
  chromosomal_alterions: 'chromosomal_alterions',
  percentage_of_plasma_cells: 'percentage_of_plasma_cells',
  plasmacytoma_tumor: 'plasmacytoma_tumor',
  plasmocytoma_measurable: 'plasmocytoma_measurable',
  plasmacytoma_size_mm: 'plasmacytoma_size_mm',
  received_transplant: 'received_transplant',
  transplant_date: 'transplant_date',
  transplant_outcome: 'transplant_outcome',
  treatment_for_mm: 'treatment_for_mm',
  treatments: 'treatments',
  drugs_received: 'drugs_received',
  cycles: 'cycles',
  treatment_end_date: 'treatment_end_date',
  treatment_stop_reason: 'treatment_stop_reason',
  myeloma_health_problems: 'myeloma_health_problems',
  serum_calcium_level: 'serum_calcium_level',
  serum_hgb_level: 'serum_hgb_level',
  platelet_count: 'platelet_count',
  neutrophil_count: 'neutrophil_count',
  omc_kidney: 'omc_kidney',
  supportive_care_type: 'supportive_care_type',
  ecog: 'ecog',
  ngs: 'ngs',
  karnofsky: 'karnofsky',
  gvhd: 'gvhd',
  other_medical_condition: 'other_medical_condition',
  omc_liver: 'omc_liver',
  omc_heart: 'omc_heart',
  omc_stroke_date: 'omc_stroke_date',
  omc_autoimmune: 'omc_autoimmune',
  omc_mental: 'omc_mental',
  omc_chronic_infection_inflammation: 'omc_chronic_infection_inflammation',
  omc_gastro_problem: 'omc_gastro_problem',
  omc_lung_disease: 'omc_lung_disease',
  omc_abnormal_blood_results: 'omc_abnormal_blood_results',
  other_medications: 'other_medications',
  is_pregnant: 'is_pregnant',
  is_breastfeeding: 'is_breastfeeding',
  have_insurance: 'have_insurance',
  insurances: 'insurances',
};
