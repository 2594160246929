import React from 'react';
import './Date.css';

import Datepicker from 'new-ui/Components/Datepicker/Datepicker';

const DateInput = (props) => (
  <div className="date-input">
    <Datepicker {...props} />
  </div>
);

export default DateInput;
