import React, { useState, useEffect } from 'react';
import app from 'new-ui/app';
import './DrugsReceived.css';

import { useTranslation } from 'utils/modifiedTranslation';

import PropTypes from 'prop-types';
import MultiTags from 'new-ui/Components/MultiTags';
import { getDrugName, randomString } from 'new-ui/functions';
import Button from 'new-ui/Components/Button';

import TrashButton from 'new-ui/assets/icons/trash_orange.svg';
import SearchSelectBox from 'new-ui/Components/SearchSelectBox';

const nums = ['1st', '2nd', '3rd', '4th', '5th', '6th', '7th', '8th', '9th', '10th'];

const DrugsReceived = (props) => {
  const {
    q,
    user,
    onselect,
  } = props;

  let {
    initValue = user?.condition_profile[q.id] || [],
  } = props;

  if (!initValue) initValue = [];

  window.___user = user;
  window.___initValue = initValue;

  const EMPTY_TREATMENT = {
    drugs_received: [],
    treatment_admin: null,
    treatment_stop_reason: null,
    treatment_end_date: null,
  };

  const userClone = { ...user };

  const [treatments, setTreatments] = useState(userClone?.condition_profile?.treatments?.length ? userClone.condition_profile.treatments : [
    { ...EMPTY_TREATMENT },
  ]);
  const [treatmentIndex, setTreatmentIndex] = useState(0);
  const [showAdd, setShowAdd] = useState(false);
  const [showSave, setShowSave] = useState(false);
  const [showDontKnow, setShowDontKnow] = useState(true);

  const { t } = useTranslation();

  useEffect(()=>{
    window.__treatments = treatments;
  }, [treatments]);

  const addTreatment = ()=>{
    // user.condition_profile.treatments = treatments;
    const treatment = { ...EMPTY_TREATMENT };
    treatments.push(treatment);
    setTreatmentIndex(treatments.length - 1);
    setTreatments([...treatments]);
  };

  const deleteTreatment = (n)=>{
    const _treatments = [...treatments];
    _treatments.splice(n, 1);
    setTreatments([..._treatments]);
    setData(_treatments);
  };

  const save = ()=>{
    setData();
    onselect(initValue);
  };

  const allDrugsIncluded = ()=>{
    const drugs = initValue;
    let included = [];
    treatments.forEach((t)=>{
      included = included.concat(t.drugs_received);
    });
    included = Array.from(new Set(...[included]));
    if (included.length === drugs.length) return true;
  };

  const setData = (__treatments)=>{
    const t = __treatments || treatments;
    const _treatments = t.filter((a)=>a.drugs_received.length);
    // console.log('setData', _treatments);
    const treatmentsClone = _treatments?.length ? [..._treatments] : [];
    user.condition_profile.treatments = treatmentsClone?.length ? treatmentsClone.reverse() : [];
    setShowSave(!!allDrugsIncluded());
    setShowDontKnow(t.length === 1 && t[0].drugs_received?.length === 0);
    onselect(initValue, true);
  };

  return (
    <div className="drugs-received">
      <div className="intake-question-title">Treatments</div>
      <div className="drugs-received-treatments">
        {treatments.map((treatment, k)=>{
          return (
            <div key={randomString()} className="drugs-received-treatment">
              <div className="drugs-received-treatments-title">
                <div>
                  {nums[k]}
                  {' '}
                  Treatment
                </div>
                { k ? (
                  <div className="drugs-received-treatments-delete">
                    <img
                      alt="delete"
                      src={TrashButton}
                      onClick={()=>{
                        deleteTreatment(k);
                      }}
                    />
                  </div>
                ) : null}
              </div>
              {
                k === treatmentIndex || !treatment.drugs_received.length ? (
                  <div className="drugs-received-add-treatment">
                    {
                      showAdd ? (
                        <div>
                          <div className="drugs-received-add-treatment-title bold">Select Treatment</div>
                          <div className="drugs-received-add-treatment-body">
                            <SearchSelectBox
                              title="Select Drugs Name"
                              placeholder={t('drugs_received.search_drug')}
                              onClose={()=>{
                                setShowAdd(false);
                              }}
                              onSelect={(item)=>{
                                initValue.push(item.code);
                                treatment.drugs_received.push(item.code);
                                setShowAdd(false);
                              }}
                              items={app.drugs.filter((a)=>!initValue.includes(a.code)).map((a)=>({
                                title: t(a.name),
                                code: a.code,
                              }))}
                            />
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="drugs-received-add-treatment-title">Select all the drugs you have received for this treatment</div>
                          <MultiTags
                            tags={initValue ? initValue.map((drug)=>{
                              return {
                                title: t(getDrugName(drug)),
                                isSelected: treatment.drugs_received.indexOf(drug) !== -1,
                                drug,
                              };
                            }) : null}
                            onAdd={()=>{
                              setShowAdd(true);
                            }}
                            addTitle={t('drugs_received.add_drug_to_treatment')}
                            onSelect={(tags)=>{
                              treatments[k].drugs_received = tags.filter((a)=>a.isSelected).map((a)=>a.drug);
                              setTreatmentIndex(k);
                              setTreatments([...treatments]);
                              setData();
                            }}
                          />
                        </>
                      )
                    }
                  </div>
                ) : (
                  <MultiTags
                    isStatic
                    tags={treatment.drugs_received.map((drug)=>{
                      return {
                        title: t(getDrugName(drug)),
                        isSelected: true,
                        drug,
                      };
                    })}
                    onClick={()=>{
                      setTreatmentIndex(k);
                      setData();
                    }}
                  />
                )
              }
            </div>
          );
        })}
      </div>
      {
        showDontKnow ? (
          <div>
            <div className="drugs-received-not-sure" onClick={save}>
              <div className="drugs-received-not-sure-box" />
              <div className="drugs-received-not-sure-text">Don't Know</div>
            </div>
          </div>
        ) : null
      }
      {
        treatments[treatments.length - 1]?.drugs_received?.length ? (
          <div className="drugs-received-actions">
            <Button title="Next Treatment" action={addTreatment} />
            { showSave ? <Button title="Done" action={save} /> : null}
          </div>
        ) : null
      }
    </div>
  );
};

DrugsReceived.propTypes = {
  q: PropTypes.any,
  user: PropTypes.any,
  onselect: PropTypes.func,
  initValue: PropTypes.any,
};

export default DrugsReceived;
