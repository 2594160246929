/**
 * This code is a workaround the issue described in task #WEBAPP-1167.
 * According to this post from the react team, they will not fix the issue and the code below is a solution they suggest to use.
 *
 * source: https://github.com/facebook/react/issues/11538#issuecomment-417504600
 */
if (typeof Node === 'function' && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild;
  Node.prototype.removeChild = function(child) {
    if (child.parentNode !== this) {
      // Error will throw here
      return child;
    }

    return originalRemoveChild.apply(this, arguments);
  };

  const originalInsertBefore = Node.prototype.insertBefore;
  Node.prototype.insertBefore = function(newNode, referenceNode) {
    if (referenceNode && referenceNode.parentNode !== this) {
      // Error will throw here
      return newNode;
    }

    return originalInsertBefore.apply(this, arguments);
  };
}
