/* eslint-disable no-lonely-if */
import { FB_EVENTS } from 'functions/analytics/constants/fbKeys';
import { sendFbEventToConversionApi } from 'functions/analytics/functions/sendFbEventToConversionApi';
import app from 'new-ui/app';

import ChatbotIntakeFlow from 'new-ui/Components/ChatBot/Flows/ChatbotIntakeFlow';
import { CLIENT_IM_NOT_SURE, getIntakeQuestions, getIntakeUnansweredNum } from 'new-ui/Components/Intake';
import { INTAKE_SECTIONS } from 'new-ui/Components/Intake/questions';
import { intercomSignupEvent } from 'new-ui/Components/Intercom';
import {
  LOCAL_STORAGE_KEYS, PAGES, POPUP_TYPES, ROUTES,
} from 'new-ui/constants';
import { callOnceEvery, isCallUsMode } from 'new-ui/functions';
import { updateIntercomToken } from 'new-ui/functions/updateIntercomToken';
import { setCallUsPopup } from 'new-ui/Popups/CallUs';

// let init = false;
let about = false;

const StartupFlow = async ()=>{
  const params = new URLSearchParams(window.location.search);
  const shouldCreateEmailsToken = localStorage.getItem(LOCAL_STORAGE_KEYS.SHOULD_CREATE_EMAILS_TOKEN);
  if (shouldCreateEmailsToken) {
    updateIntercomToken();
  }
  if (!app.isIntakeFlow(app.user?.personal.condition)) return CallUsFlow();
  const eventID = `${FB_EVENTS.QUICK_SIGNUP_WITHOUT_ENGAGEMENT}.${Date.now()}`;
  if (params.get('signup')) { // new user
    localStorage.removeItem(CLIENT_IM_NOT_SURE);
    if (params.get('source') === 'upload') {
      setTimeout(()=>{
        app.history.push(ROUTES[PAGES.INTAKE]);
      }, 7 * 1000);
    } else if (getIntakeUnansweredNum()) {
      IntakeFlow();
      setTimeout(()=>{
        if (app.userSignupInteracted) {
          sendFbEventToConversionApi({
            eventName: FB_EVENTS.QUICK_SIGNUP_WITHOUT_ENGAGEMENT,
            eventID,
          }).catch((e) => console.error('Can\'t send facebook event', e));
        }
        const intakeResult = getIntakeQuestions(app.user, {
          byType: true,
          isUnanswered: true,
        });
        const isEmptyIntake = intakeResult?.[INTAKE_SECTIONS.ABOUT_YOUR_TREATMENT].length === 0
                              && intakeResult?.[INTAKE_SECTIONS.ABOUT_YOUR_HEALTH].length === 0
                              && intakeResult?.[INTAKE_SECTIONS.ABOUT_YOU].length === 0;
        if (isEmptyIntake) {
          sendFbEventToConversionApi({
            eventName: FB_EVENTS.QUICK_SIGNUP_HASNT_FINISHED,
            eventID,
          }).catch((e) => console.error('Can\'t send facebook event', e));
        }
      }, 15 * 60 * 1000);
    } else {
      CallUsFlow();
    }
  } else {
    // returning user
    if (!about) setAboutPopup();
  }
  // init = true;
};

const IntakeFlow = ()=>{
  setTimeout(()=>{
    ChatbotIntakeFlow({
      onYes: ()=>{
        app.history.push(`${ROUTES[PAGES.INTAKE]}?unanswered=1`);
      },
      onNo: ()=>{
        CallUsFlow();
      },
    });
  }, 5000);
};

const CallUsFlow = ()=>{
  if (isCallUsMode()) {
    setTimeout(setCallUsPopup, 20000);
  } else {
    app.intercom.hidden = false;
    intercomSignupEvent();
  }
};

const setAboutPopup = ()=>{
  setTimeout(()=>{
    if (app.isCurrentPage(PAGES.INTAKE)) return;
    const unansweredAboutQuestions = getIntakeQuestions(app.user, { byType: true, isUnanswered: true })?.about?.length;
    if (unansweredAboutQuestions && !app.popup) {
      callOnceEvery(()=>{
        app.setPopup(POPUP_TYPES.ABOUT_YOU);
        about = true;
      }, 'about_popup', 6.048e+8);
    }
  }, 30000);
};

export default StartupFlow;
