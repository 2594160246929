const PERIODS_LENGTHS = {
  WEEK: 7,
  MONTH: 30,
  YEAR: 365,
};

export const getTreatmentPeriodAndTranslation = (treatmentPeriod, isTotalDaysTreatment) => {
  if (!treatmentPeriod) {
    return null;
  }

  if (treatmentPeriod === PERIODS_LENGTHS.WEEK) {
    return {
      translationKey: !isTotalDaysTreatment
        ? 'treatment_cycles.details.section.once_per_week'
        : 'treatment_cycles.details.section.one_week',
      lengthOfPeriod: treatmentPeriod,
    };
  }

  if (
    treatmentPeriod > PERIODS_LENGTHS.WEEK
    && treatmentPeriod < PERIODS_LENGTHS.MONTH
    && treatmentPeriod % PERIODS_LENGTHS.WEEK === 0
  ) {
    const periodInWeeks = Math.floor(treatmentPeriod / PERIODS_LENGTHS.WEEK);
    return {
      translationKey: periodInWeeks === 1
        ? 'treatment_cycles.details.section.every_x_week'
        : 'treatment_cycles.details.section.every_x_weeks',
      lengthOfPeriod: periodInWeeks,
    };
  }

  if (treatmentPeriod > PERIODS_LENGTHS.MONTH && treatmentPeriod < PERIODS_LENGTHS.YEAR) {
    const periodInMonths = Math.floor(treatmentPeriod / PERIODS_LENGTHS.MONTH);
    return {
      translationKey: periodInMonths === 1
        ? 'treatment_cycles.details.section.every_x_month'
        : 'treatment_cycles.details.section.every_x_months',
      lengthOfPeriod: periodInMonths,
    };
  }

  if (treatmentPeriod >= PERIODS_LENGTHS.YEAR) {
    const periodInYears = Math.floor(treatmentPeriod / PERIODS_LENGTHS.YEAR);
    return {
      translationKey: periodInYears === 1
        ? 'treatment_cycles.details.section.every_x_year'
        : 'treatment_cycles.details.section.every_x_years',
      lengthOfPeriod: periodInYears,
    };
  }

  return {
    translationKey: treatmentPeriod === 1
      ? 'treatment_cycles.details.section.every_x_day'
      : 'treatment_cycles.details.section.every_x_days',
    lengthOfPeriod: treatmentPeriod,
  };
};

export const getCyclesText = (trial, t) => {
  let cycleText = '';
  const getTreatmentMinAndMaxPeriod = (cycles) => `${Math.min(...cycles)}-${Math.max(...cycles)}`;

  const treatmentCycles = trial.treatmentInfo && trial.treatmentInfo.filter((i) => i.cycles);
  const treatmentCyclesWeekly = trial.treatmentInfo && trial.treatmentInfo.filter((i) => i.treatment_cycle_length);
  const multipleCycles = [...new Set(treatmentCycles.map((i) => i.cycles))];
  const multipleCyclesWeekly = [...new Set(treatmentCyclesWeekly.map((i) => i.treatment_cycle_length))];
  const { translationKey, lengthOfPeriod } = getTreatmentPeriodAndTranslation(
    +treatmentCyclesWeekly?.[0]?.treatment_cycle_length,
  ) ?? {};

  if (multipleCycles.length) {
    cycleText += multipleCycles.length > 1
      ? `${getTreatmentMinAndMaxPeriod(multipleCycles)} ${t('treatment_cycles.details.section.treatment_inline')}`
      : !!treatmentCycles?.[0]?.cycles && `${treatmentCycles?.[0]?.cycles} ${t('treatment_cycles.details.section.treatment_inline')}`;
  }

  if (treatmentCyclesWeekly.length && treatmentCycles.length) {
    cycleText += (treatmentCyclesWeekly.length && treatmentCycles.length) ? ' · ' : '';
  }

  if (multipleCyclesWeekly.length) {
    cycleText += multipleCyclesWeekly.length > 1
      ? t(translationKey, { number: getTreatmentMinAndMaxPeriod(multipleCyclesWeekly) })
      : !!treatmentCyclesWeekly?.[0]?.treatment_cycle_length && t(translationKey, { number: lengthOfPeriod });
  }
  return cycleText;
};
