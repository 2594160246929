import { optimizeSortItemsSorter } from '../../functions';
import OPTIMIZE_SORTER_DIRECTION, { SORT_ITEMS } from '../../constants';
import { toggleDialog } from '../../../Dialogs/actions';
import DIALOG_NAMES_CONSTANTS, { THEME } from '../../../Dialogs/constants';
import analytics from '../../../../functions/analytics';

export const getFirstSponsoredTrial = (trials) => (trials ?? []).find((trial) => trial?.isSponsored);

export const getOptimizeSortItems = geoLocation => {
  const optimizeSortItems = [
    {
      label: 'matches.filters.sort_items.match_rank_and_location',
      value: SORT_ITEMS.OPTIMIZED_SCORE,
      shouldRender: !!geoLocation?.location,
      sort: optimizeSortItemsSorter(OPTIMIZE_SORTER_DIRECTION.ASCENDING, SORT_ITEMS.OPTIMIZED_SCORE)
    },
    {
      label: 'matches.filters.sort_items.location',
      value: SORT_ITEMS.NEAREST_FACILITY_DISTANCE,
      shouldRender: !!geoLocation?.location,
      sort: optimizeSortItemsSorter(OPTIMIZE_SORTER_DIRECTION.ASCENDING, SORT_ITEMS.NEAREST_FACILITY_DISTANCE)
    },
    {
      label: 'matches.filters.sort_items.match_rank',
      value: SORT_ITEMS.SCORE,
      shouldRender: true,
      sort: optimizeSortItemsSorter(OPTIMIZE_SORTER_DIRECTION.DESCENDING, SORT_ITEMS.SCORE)
    }
  ];

  return optimizeSortItems.filter(item => item.shouldRender);
};

export const changeLocationClick = dispatch => {
  dispatch(toggleDialog(DIALOG_NAMES_CONSTANTS.CHANGE_LOCATION, THEME.INFO));
  analytics.matchesPageChangeLocation();
};

export const getCenterOfMap = country => {
  return {
    lat: country?.location?.lat || null,
    lng: country?.location?.lng || null
  };
};

export const wasDistanceIncreased = (travelLimit, center, matchesRadius) => {
  const maxDistance = !travelLimit || travelLimit === 'none' ? 0 : parseInt(travelLimit, 10);

  return (matchesRadius === 0 && Number.isNaN(maxDistance)) || (!center.lat && !center.lng) || maxDistance === 0
    ? false
    : maxDistance !== matchesRadius;
};
