export const PFIZER = 'pfizer';
export const LEUKEMIARF = 'leukemiarf';

export const DISEASES_BY_PROVIDER = {
  [LEUKEMIARF]: [
    'aml',
    'cll',
    'mds',
  ],
};
