import Loadable from 'react-loadable';
import Spinner from 'components/Spinner';

export default function PageLoader(opts) {
  return Loadable({
    loading: Spinner,
    delay: 60,
    timeout: 10000,
    ...opts,
  });
}
