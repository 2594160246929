import React, { useState } from 'react';
import './style.css';
import app from 'new-ui/app';
import { useTranslation } from 'utils/modifiedTranslation';
import EventTypes from './EventTypes';
import EventList from './EventList';
import StatusCards from './StatusCards';

export const COMPONENT = {
  STATUS_CARDS: 'StatusCards',
  EVENT_LIST: 'eventList',
  EVENT_TYPES: 'EventTypes'
};

const EventsWidget = ({ }) => {
  const { t } = useTranslation();

  const [activeComponent, setActiveComponent] = useState(COMPONENT.EVENT_TYPES);
  const [selectedType, setSelectedType] = useState('');

  const renderComponent = () => {
    switch (activeComponent) {
      case COMPONENT.STATUS_CARDS:
        return <StatusCards 
          onClickBack={() => {
            setActiveComponent(COMPONENT.EVENT_LIST)
          }} 
        />;
      case COMPONENT.EVENT_LIST:
        return <EventList 
          onSelectType={onEventTypeClick} 
          selectedType={selectedType} 
        />;
      case COMPONENT.EVENT_TYPES:
        return <EventTypes 
          onSelectType={(type) => {
            setActiveComponent(COMPONENT.EVENT_LIST);
            setSelectedType(type);
        }} />;
      default:
        return <EventTypes 
          onSelectType={(type) => {
            setActiveComponent(COMPONENT.EVENT_LIST);
            setSelectedType(type);
        }} />;
    }
  };

  const headerContent = {
    EventTypes: {
      h2: 'events_widget.title',
      h3: 'events_widget.sub_title'
    },
    eventList: {
      h2: 'events_widget.title',
      h3: 'events_widget.sub_title'
    },
    StatusCards: {
      h2: 'events_widget.status.title',
      h3: 'events_widget.status.sub_title'
    },
  };

  const onEventTypeClick = (eventName) => {
    setActiveComponent(eventName);
  };

  return (
    <div className="events-widget">
        <div className="options-header">
          <h2 className='title'>{t(headerContent[activeComponent].h2)}</h2>
          <h3 className='sub-title'>{t(headerContent[activeComponent].h3)}</h3>
        </div>
        <div className="options-body">
          {renderComponent()}
        </div>
    </div>
  );
};
export default EventsWidget;
