import * as React from "react";

const CapsuleIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="95" height="120" {...props} viewBox="0 0 95 120" fill="none">
  <g clipPath="url(#clip0_23667_18962)">
    <path d="M63.9738 120H6.33443C2.83279 120 0 117.167 0 113.666V42.0591C0 32.8919 7.43606 25.4558 16.6033 25.4558H53.7049C62.8721 25.4558 70.3082 32.8919 70.3082 42.0591V105.128H66.3738V42.0591C66.3738 35.0558 60.6688 29.3902 53.7049 29.3902H16.6033C9.6 29.3902 3.93443 35.0952 3.93443 42.0591V113.666C3.93443 114.964 4.99672 116.066 6.33443 116.066H64.0131V120H63.9738Z" fill="#213332"/>
    <path d="M54.2952 29.3508H16.0132V13.3376H54.2952V29.3901V29.3508ZM19.9476 25.4163H50.3607V17.2327H19.9476V25.4163Z" fill="#213332"/>
    <path d="M58.3476 17.2721H11.9607C10.1508 17.2721 8.65576 15.777 8.65576 13.9672V3.30492C8.65576 1.49508 10.1115 0 11.9607 0H58.3082C60.1181 0 61.6131 1.49508 61.6131 3.30492V13.9672C61.6131 15.777 60.1574 17.2721 58.3082 17.2721H58.3476ZM12.6295 13.3377H57.6787V3.93443H12.6295V13.3377Z" fill="#213332"/>
    <path d="M70.3082 99.305H0V45.8755H70.3082V99.305ZM3.93443 95.3706H66.3738V49.8099H3.93443V95.3706Z" fill="#213332"/>
    <path d="M71.8425 120H55.0032C50.3605 120 46.5835 116.223 46.5835 111.58C46.5835 106.938 50.3605 103.161 55.0032 103.161H71.8425C76.4851 103.161 80.2622 106.938 80.2622 111.58C80.2622 116.223 76.4851 120 71.8425 120ZM55.0032 107.095C52.5245 107.095 50.5179 109.102 50.5179 111.58C50.5179 114.059 52.5245 116.066 55.0032 116.066H71.8425C74.3212 116.066 76.3278 114.059 76.3278 111.58C76.3278 109.102 74.3212 107.095 71.8425 107.095H55.0032Z" fill="#213332"/>
    <path d="M63.4228 120C62.3211 120 61.4556 119.134 61.4556 118.033V105.128C61.4556 104.026 62.3211 103.161 63.4228 103.161C64.5244 103.161 65.39 104.026 65.39 105.128V118.033C65.39 119.134 64.5244 120 63.4228 120Z" fill="#213332"/>
    <path d="M83.5281 117.01C81.2068 117.01 79.0035 116.302 77.1543 114.925C76.2888 114.295 76.092 113.036 76.7215 112.171C77.3511 111.305 78.6101 111.108 79.4756 111.738C80.656 112.603 82.0724 113.075 83.5281 113.075C87.3051 113.075 90.4133 109.967 90.4133 106.19C90.4133 102.413 87.3051 99.305 83.5281 99.305C80.0265 99.305 77.115 101.902 76.6822 105.364C76.5642 106.426 75.5806 107.253 74.5183 107.095C73.456 106.977 72.6691 105.994 72.7871 104.931C73.4166 99.5017 78.0592 95.4099 83.5281 95.4099C89.5084 95.4099 94.3478 100.249 94.3478 106.23C94.3478 112.21 89.5084 117.049 83.5281 117.049V117.01Z" fill="#213332"/>
    <path d="M78.2951 113.548C77.7836 113.548 77.2722 113.351 76.8787 112.957C76.1312 112.17 76.1705 110.911 76.9574 110.164L88.7607 98.8721C89.5476 98.1246 90.7672 98.1639 91.5541 98.9508C92.3017 99.7377 92.2623 100.997 91.4754 101.744L79.6722 113.036C79.2787 113.39 78.8066 113.587 78.2951 113.587V113.548Z" fill="#213332"/>
    <path d="M31.4755 69.1673H22.623V56.3018H31.4755C35.0165 56.3018 37.928 59.1739 37.928 62.7542C37.928 66.3345 35.0558 69.2067 31.4755 69.2067V69.1673ZM26.5181 65.2329H31.4362C32.8132 65.2329 33.9542 64.0919 33.9542 62.7149C33.9542 61.3378 32.8132 60.1968 31.4362 60.1968H26.5181V65.1936V65.2329Z" fill="#213332"/>
    <path d="M26.5179 65.4688H22.5835V74.3606H26.5179V65.4688Z" fill="#213332"/>
    <path d="M32.5791 66.2142L29.3765 68.4995L43.9338 88.9006L47.1365 86.6153L32.5791 66.2142Z" fill="#213332"/>
    <path d="M42.7776 69.4741L30.5435 86.6511L33.7481 88.9336L45.9823 71.7566L42.7776 69.4741Z" fill="#213332"/>
  </g>
  <defs>
    <clipPath id="clip0_23667_18962">
      <rect width="95" height="120" fill="white"/>
    </clipPath>
  </defs>
</svg>
);
export default CapsuleIcon;
