import callToActionConstants from '../constants/callToAction';

const initialState = {
  minimizeCallToAction: false
};

export const callToAction = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case callToActionConstants.SET_MINIMIZE_CALL_TO_ACTION:
      return {
        minimizeCallToAction: payload
      };
    default:
      return state;
  }
};
