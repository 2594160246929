import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Checkbox, Typography } from '@material-ui/core';
import { useTranslation } from 'utils/modifiedTranslation';
import { joinClasses } from 'functions/joinClasses';
import useStyles from './styles';

export const CheckboxNew = (props) => {
  const {
    label,
    onChange,
    checked,
    checkboxClassName,
    labelClassName,
    topographyClassName,
    isRequired = false,
    labelHTML,
    isDefault,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const Label = () => (
    <Typography className={topographyClassName}>
      {labelHTML ? (
        <span dangerouslySetInnerHTML={{ __html: labelHTML }} />
      ) : t(label)}
      {isRequired && (
        <span className={classes.labelText}>
          {'  '}
          *
        </span>
      )}
    </Typography>
  );

  return (
    <FormControlLabel
      className={joinClasses(classes.label, labelClassName)}
      control={(
        <Checkbox
          className={joinClasses(classes.checkbox, checkboxClassName)}
          onChange={onChange}
          checked={checked}
          required={isRequired}
          color={isDefault ? 'default' : 'primary'}
          size="small"
        />
      )}
      label={<Label />}
    />
  );
};

CheckboxNew.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  checkboxClassName: PropTypes.string,
  topographyClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  labelHTML: PropTypes.string,
  isRequired: PropTypes.bool,
  isDefault: PropTypes.bool,
};

CheckboxNew.defaultProps = {
  checkboxClassName: '',
  labelClassName: '',
  isRequired: false,
  isDefault: false,
  labelHTML: '',
};
