import React from 'react';
import { useTranslation } from 'utils/modifiedTranslation';

import app from 'new-ui/app';

import ActionBox from 'new-ui/Components/ActionBox';
import PropTypes from 'prop-types';

import { ACTION_TYPES, POPUP_TYPES } from 'new-ui/constants';

const ACTION_TYPES_COLORS = {
  default: '#FF8C69',
  [ACTION_TYPES.USER_INTENT]: '#2EC4B6',
  [ACTION_TYPES.SHARE_WITH_DOCTOR]: '#CBE896',
  [ACTION_TYPES.CONSULT_WITH_US]: '#FF8C69',
  [ACTION_TYPES.NEXT_SCAN]: '#C77DFF',
  [ACTION_TYPES.NEXT_APPOINTMENT]: '#C77DFF',
  [ACTION_TYPES.TREATMENT_EXPERIENCE]: '#0C9BED',
  [ACTION_TYPES.EBOOK]: '#FF8C69',
  [ACTION_TYPES.MISSING_DATA]: '#EB4B62',
  [ACTION_TYPES.MISSING_EMAIL]: '#EB4B62',
  [ACTION_TYPES.EDIT_EMAIL]: '#EB4B62',
  [ACTION_TYPES.PHONE_LOGIN]: '#6E44FF',
  [ACTION_TYPES.CONNECT_WITH_ONCOLOGIST]: '#FC92A5',
  [ACTION_TYPES.SEE_ALL_OPTIONS]: '#FF8C69',
};

const Action = (props) => {
  const {
    type = ACTION_TYPES.init,
    data,
    className,
    href,
    hrefOnClick,
    action: actionProp,
  } = props;

  const { t } = useTranslation();

  const ACTIONS = {};

  for (const actionName in ACTION_TYPES) {
    const name = actionName.toLocaleLowerCase();
    ACTIONS[actionName] = {
      type: name,
      title: t(`action.${name}`),
      subtitle: t(`action.subtitle_${name}`),
      buttonText: t(`action.${name}_button`),
      icon: name,
      popup: POPUP_TYPES[actionName],
    };
  }

  ACTIONS[ACTION_TYPES.CONSULT_WITH_US].subtitle = t('action.discuss_your_treatment_options');

  let action = ACTIONS[type];

  const getAction = (actionType) => {
    if (actionType.endsWith('_FLOW')) {
      // eslint-disable-next-line import/no-dynamic-require, global-require
      const flowHandler = require(`new-ui/ActionFlows/${actionType.split('_FLOW')[0].toLowerCase()}.js`);
      const flowResponse = flowHandler.default()(ACTIONS);
      if (!flowResponse) return null;
      if (typeof flowResponse === 'string') {
        if (flowResponse.endsWith('_FLOW')) return getAction(flowResponse);
        return ACTIONS[flowResponse];
      }
      if (typeof flowResponse === 'object') {
        return { ...flowResponse, ...ACTIONS[flowResponse.type] };
      }
    }
    return ACTIONS[actionType];
  };

  action = getAction(type);

  // console.log('ACTION', action);

  if (!action) action = ACTIONS[ACTION_TYPES.default];

  if (action.data) action = Object.assign(action, action.data);

  if (data) action = Object.assign(action, data);

  const {
    subtitle,
    title,
    buttonText = t('action.learn_more'),
    icon = 'general',
  } = action;

  const onClick = () => {
    const popupData = action?.data || data;
    if (data?.action) {
      data.action();
    } else if (action?.data?.action) {
      action.data.action();
    } else if (actionProp) {
      actionProp();
    } else {
      app.setPopup(action.popup, { ...popupData || {} });
    }
  };

  const _class = `widget action ${className || ''}`;
  const color = ACTION_TYPES_COLORS[action.type.toUpperCase()] || ACTION_TYPES_COLORS.default;

  return (
    <div className={_class}>
      <ActionBox
        icon={icon}
        title={title}
        subtitle={subtitle}
        href={href}
        hrefOnClick={hrefOnClick}
        buttonText={buttonText}
        action={onClick}
        color={color}
      />
      <div style={{ height: '20px' }} />
    </div>
  );
};

Action.propTypes = {
  type: PropTypes.string,
  data: PropTypes.object,
  className: PropTypes.string,
  action: PropTypes.func,
  href: PropTypes.string,
  hrefOnClick: PropTypes.func,
};

export default Action;
