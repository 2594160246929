import Button from 'new-ui/Components/Button';
import React from 'react';

import UploadIcon from '../Pages/Upload/assets/upload-files.svg';
import { ReactComponent as StarImg } from '../assets/icons/star.svg';
import AnalyzeIcon from '../assets/icons/analyze.svg';

export const AiAssistant = ({ uploadAction, ngsAction })=>{
  const { t } = window;
  return (
    <div className="upload-nav">
      <div className="info">
        <StarImg />
        <div className="desktop-only">{t('general.ai.assistant')}</div>
        <div className="buttons">
          <Button
            action={uploadAction}
            backgroundColor="#C77DFF"
            title={t('hcp.bar_upload_files')}
            icon={UploadIcon}
            className="upload-files desktop-only"
            width="210"
          />
          <Button
            action={uploadAction}
            backgroundColor="#C77DFF"
            title={t('hcp.bar_upload_files_mobile')}
            className="upload-files mobile-only"
            width="210"
          />
          <div className="or desktop-only">{t('general.or')}</div>
          <Button
            backgroundColor="#C77DFF"
            title={t('hcp.bar_ngs_mobile')}
            className="upload-files desktop-only"
            icon={AnalyzeIcon}
            width="210"
            action={ngsAction}
          />
          <Button
            backgroundColor="#C77DFF"
            title={t('hcp.bar_ngs_mobile')}
            className="upload-files mobile-only"
            width="210"
            action={ngsAction}
          />
        </div>
      </div>
    </div>
  );
};
