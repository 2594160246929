import React, { useState } from 'react';
import './RecordsEmail.css';
import Button from 'new-ui/Components/Button';
import PropTypes from 'prop-types';
import { GOOGLE_RECAPTCHA_LINKS, MAIN_WEBSITE_LINKS } from 'new-ui/constants';
import { useTranslation } from 'utils/modifiedTranslation';
import CheckIcon from './assets/check.svg';
import CopyIcon from './assets/copy.svg';
import { getMedicalFilesFromEmail } from '../../../../components/MedicalFiles/api';
import useRecaptcha from '../../../../utils/hooks/useRecaptcha';
import Form, { FORM_INPUT_TYPES, FORM_VALIDATORS } from '../../../Components/Form';
import { UPLOADER_STATES } from '../../../Components/FilesUploader';

const form = {
  inputs: {
    email: {
      type: FORM_INPUT_TYPES.INPUT,
      required: true,
      icon: 'email',
      title: 'Email',
      placeholder: '',
      className: 'registration-email',
    },
    phone: {
      type: FORM_INPUT_TYPES.PHONE,
      icon: 'phone',
      title: 'Phone',
      placeholder: '651465',
      className: 'registration-phone',
    },
  },
  data: {
    email: '',
    phone: '',
  },
};

const MAX_SEND_ATTEMPTS = 3;

const RecordsEmail = ({ setUploadType }) => {
  const { t } = useTranslation();
  const [icon, setIcon] = useState(CopyIcon);
  const [iconText, setIconText] = useState(t('upload.copy_text'));
  const [formData, setFormData] = useState({ email: '', phone: '' });
  const [message, setMessage] = useState('');
  const [sendAttempts, setSendAttempts] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isFormShown, setIsFormShown] = useState(true);
  const { recaptcha, getRecaptchaTokenAsync } = useRecaptcha();

  const copyEmail = () => {
    const copyText = document.getElementById('step-one-email').innerText;

    navigator.clipboard.writeText(copyText)
      .then(() => {
        setIcon(CheckIcon);
        setIconText(t('upload.copied_text'));
      })
      .catch((error) => {
        console.error('Error copying text:', error);
      });
  };

  const onFormChange = (formData) => {
    const { email, phone } = formData;

    const isValidEmail = FORM_VALIDATORS.EMAIL(email);
    const isValidPhone = !phone || FORM_VALIDATORS.PHONE(phone);

    setIsButtonDisabled(!isValidEmail || !isValidPhone);
    setFormData(formData);
  };

  const onFilesSent = async () => {
    if (sendAttempts >= MAX_SEND_ATTEMPTS) {
      setIsFormShown(false);
      setMessage(t('upload.email_flow.message.max_attempts'));
      return;
    }

    setIsFormShown(false);
    setSendAttempts((prevAttempts) => prevAttempts + 1);
    setMessage('upload.email_flow.message.inbox_check');

    try {
      await getMedicalFilesFromEmail(formData, await getRecaptchaTokenAsync());
      setUploadType(UPLOADER_STATES.ANALYZING);
    } catch (e) {
      setIsFormShown(true);
      setIsButtonDisabled(false);
      setMessage(t('upload.email_flow.message.inbox_is_empty'));
    }
  };

  return (
    <>
      {recaptcha}
      <div className="records-title">{t('upload.records_title')}</div>
      <div className="records-wrapper">
        <div>
          <div className="subtitle">{t('upload.records_subtitle')}</div>
          <div className="step-one-wrapper">
            <div className="step-one">
              <div className="step-number one">1</div>
              <div>{t('upload.copy_email_title_text')}</div>
            </div>
            <div className="copy-wrapper">
              <div id="step-one-email" className="step-one-email">files@leal.health</div>
              <div className="copy" onClick={copyEmail}>
                <div className="copy-icon"><img src={icon} alt="copy-icon" /></div>
                <div>{iconText}</div>
              </div>
            </div>
          </div>
          <div className="step-two-wrapper">
            <div className="step-number">2</div>
            <div className="step-two-content">
              <div>
                {t('upload.forward_text')}
                <br />
                <span>files@leal.health</span>
              </div>
            </div>
          </div>
          <div className="step-three-wrapper">
            <div className="step-number">3</div>
            <div>{t('upload.received_file_text')}</div>
          </div>
          <div className="step-three-wrapper">
            <div className="step-number">4</div>
            <div>{t('upload.email_flow.step.4')}</div>
          </div>
          {isFormShown && (
            <>
              <Form
                className="email-form"
                form={{ ...form }}
                onChange={onFormChange}
              />
              <div className="next-btn">
                <Button
                  title={t('upload.send_files_btn_text')}
                  backgroundColor="#fff"
                  color="#213332"
                  width="160px"
                  action={onFilesSent}
                  disabled={isButtonDisabled}
                />
              </div>
            </>
          )}
          <div className="message">{message}</div>
        </div>
        <div className="records-bottom">
          <div className="records-bottom-text">
            {t('upload.by_sending_email_text')}
            {' '}
            <a
              href={MAIN_WEBSITE_LINKS.TERMS}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('upload.terms_text')}
            </a>
            {' '}
            {t('upload.and_text')}
            {' '}
            <a
              href={MAIN_WEBSITE_LINKS.PRIVACY_POLICY}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('upload.privacy_policy_text')}
            </a>
            .
            {' '}
            {t('upload.recaptcha_google_text')}
            {' '}
            <a
              href={GOOGLE_RECAPTCHA_LINKS.GOOGLE_PRIVACY_POLICY}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('upload.privacy_policy_text')}
            </a>
            {' '}
            {t('upload.and_text')}
            {' '}
            <a
              href={GOOGLE_RECAPTCHA_LINKS.GOOGLE_TERM_OF_SERVICE}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('upload.term_of_service_text')}
            </a>
            {' '}
            {t('upload.apply_text')}
            .
          </div>
        </div>
      </div>
    </>
  );
};

RecordsEmail.propTypes = {
  setUploadType: PropTypes.func,
};

export default RecordsEmail;
