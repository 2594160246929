import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

const OptionCheckbox = ({ checked, onChange, label }) => {
  return (
    <label className="option-checkbox">
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      <span className="option-checkbox-mask"></span>
      <span className='option-checkbox-label'>{label}</span>
    </label>
  );
};

OptionCheckbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
};

export default OptionCheckbox;