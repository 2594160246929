import React from 'react';
import './style.css';
import ImgFallback from 'new-ui/assets/icons/empty_item.svg';
import { getImagePath } from '../functions';
import { useTranslation } from 'utils/modifiedTranslation';
import { getDrugCommercialName } from 'new-ui/functions/getDrugCommercialName';

const SponsorInfo = ({item}) => {
  const { t } = useTranslation();

  return (
    <div className='sponsor-info'>
      <div className='img-cont'>
        <img
            src={getImagePath(item)}
            onError={(e)=>{
              e.target.onerror = null;
              e.target.src = ImgFallback;
            }}
            alt={t('sponsor_custom.img.alt')}
          />
      </div>
      <div className='titles'>
        <div className='sponsor-name'>{item.sponsorName}</div>
        {item.generic_name && <div className='generic-name'><span className='label'>{t('list_item.title.generic_name')}:</span> {getDrugCommercialName(item.generic_name)}</div>}
      </div>
    </div>
  );
};

export default SponsorInfo;