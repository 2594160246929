import moment from 'moment';

/**
 * @param dates {Array.<string|null>}
 * @return {string|null}
 */
export default (dates) => {
  const validDates = dates.filter((date) => date !== null || moment(date, 'YYYY-MM-DD', true).isValid());

  if (!validDates.length) {
    return null;
  }

  const lastDate = validDates.reduce((latest, current) => {
    const currentDate = moment(current);
    return currentDate.isAfter(latest) ? currentDate : latest;
  }, moment(validDates[0]));

  return lastDate.format('YYYY-MM-DD');
};
