/**
 * General popup
 * example use:
 * app.setPopup('general',{
 *  title:'Popup Title',
 *  content:'Text Content', // can also be JSX
 *  button:{
 *    title:'Button Title',
 *    action:( closePopup )=>{
 *      console.log(1);
 *      closePopup();
 *    }
 *  }
 * })
 */

import React from 'react';

import { PopupButton, PopupContent, PopupTitle } from 'new-ui/Components/Popup';
import PropTypes from 'prop-types';
import './General.css';

const GeneralPopup = (props) => {
  const {
    title, content, contents, button, rawContent = null, closePopup,
  } = props;
  return (
    <div className="general-popup">
      {
        rawContent
      }
      {
        title && <PopupTitle title={title} />
      }
      {
        content && <PopupContent>{content}</PopupContent>
      }
      {
        contents && contents.map((c) => (
          <PopupContent>{c}</PopupContent>
        ))
      }
      {
        button && (
          <PopupButton
            title={button.title}
            action={async () => {
              if (button.action) await button.action(closePopup);
            }}
          />
        )
      }
    </div>
  );
};

GeneralPopup.propTypes = {
  title: PropTypes.string,
  content: PropTypes.any,
  rawContent: PropTypes.any,
  contents: PropTypes.array,
  button: PropTypes.object,
  closePopup: PropTypes.func,
};

export default GeneralPopup;
