import isEmailValid from './isEmailValid';

export default email => {
  let error = '';

  if (!email || !isEmailValid(email)) {
    error = 'Add an email address';
  }

  return error;
};
