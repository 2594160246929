const filterByTreatmentType = (items, treatment_types) => {
  if (!treatment_types || treatment_types.length === 0) {
    return items;
  }

  return items.filter(item => {
    const isDrug = item.trialType === 'drug';
    if (treatment_types.includes('standard_of_care') && isDrug) {
      return true;
    }
    if (treatment_types.includes('clinical_trials') && !isDrug) {
      return true;
    }
    return false;
  });
};

const administrationMethodMap = {
  oral_treatment: "Oral Treatment (by mouth)",
  injection_muscle: "Injection To Muscle",
  iv_infusion: "IV infusion"
};
  
const filterByAdministrationMethod = (items, administration_methods) => {
  if (!administration_methods || administration_methods.length === 0) {
    return items;
  }

  return items.filter(item => {
    return administration_methods.some(method => {
      const correspondingString = administrationMethodMap[method];
      return item.treatment_administration_type.some(adminType => 
        adminType === correspondingString
      );
    });
  });
};

const interventionalTypeMap = {
  chemotherapy: 'Chemotherapy',
  targeted_therapy: 'Targeted therapy',
  immunotherapy: 'Immunotherapy',
  hormonal_therapy: 'Hormonal therapy',
  gene_therapy: 'Gene therapy',
  imaging: 'Imaging',
  medical_device: 'Medical device',
  vaccine: 'Vaccine',
  oncolytic_virus_therapy: 'Oncolytic virus therapy',
  radiation: 'Radiation',
  cell_therapy: 'Cell therapy',
  diagnostics: 'Diagnostics',
  surgery: 'Surgery',
  transplantation: 'Transplantation',
  general: 'General'
};
  
const filterByInterventionalType = (items, interventional_types) => {
  if (!interventional_types || interventional_types.length === 0) {
    return items;
  }

  return items.filter(item => {
    return interventional_types.some(type => {//every
      const correspondingString = interventionalTypeMap[type]?.toLowerCase();
      return item.groups.some(group => group.toLowerCase() === correspondingString);
    });
  });
};

const filterByClinicalTrialPhase = (items, clinical_trial_phases) => {
  if (!clinical_trial_phases || clinical_trial_phases.length === 0) {
    return items;
  }

  const phaseNumbers = clinical_trial_phases.map(Number);
  return items.filter(item => {
    return item._phases.some(phase => phaseNumbers.includes(phase));
  });
};

export {
  filterByTreatmentType,
  filterByAdministrationMethod,
  filterByClinicalTrialPhase,
  filterByInterventionalType
}