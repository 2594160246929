/* eslint-disable react/prop-types */
import React from 'react';
import './Overlay.css';

import xIcon from 'new-ui/assets/icons/overlay-x.svg';
import PropTypes from 'prop-types';
import lealHealthIcon from 'new-ui/assets/logo_new.svg';

const Overlay = (props) => {
  const {
    children, onclose, className, hideCloseButton,
  } = props;
  return (
    <div className={`overlay ${className || ''}`}>
      <div className="overlay-top">
        <div className="logo-pos">
          <img
            alt="arrow button"
            src={lealHealthIcon}
            style={{
            }}
          />
        </div>
        {
          hideCloseButton ? <></> : (
            <div className="overlay-close" onClick={onclose}>
              <img alt="close" src={xIcon} />
            </div>
          )
        }

      </div>
      <div className="overlay-main">
        {children}
      </div>
    </div>
  );
};

Overlay.propTypes = {
  children: PropTypes.any,
  onclose: PropTypes.func,
  className: PropTypes.string,
};

export default Overlay;
