import * as ReactRouterDom from 'react-router-dom';
import CONSTANTS from './constants';
import { ROUTE_NAME, ROUTE_PATH } from '../AppRoutes/constants';
import isEmpty from '../../functions/isEmpty';
/**
 *  Gets a list of filters we have switched on
 *
 * @param {Array} filters Array of filters in state `state.matches.filters`.
 * @requires {number}
 */
export const getTotalMatchesActiveFilters = filters => {
  return Object.values(filters).filter(filterValue => !isEmpty(filterValue))?.length;
};

export const testEmailRegex = new RegExp('@trialmatch.me|@trialjectory.com');

export const optimizeSortItemsSorter = (direction, key) => (a, b) => {
  const prevValue = a[key] === null ? Infinity : a[key];
  const nextValue = b[key] === null ? Infinity : b[key];
  if (direction === CONSTANTS.OPTIMIZE_SORTER_DIRECTION.DESCENDING) {
    return nextValue - prevValue;
  }

  return prevValue - nextValue;
};

export const getCurrentTab = (tabs, pathName) => {
  return tabs.find(tab =>
    ReactRouterDom.matchPath(pathName, {
      path: tab.path,
      exact: true,
      strict: true
    })
  );
};

export const getMatchesDetailsPagePath = nctNumber => {
  return ReactRouterDom.generatePath(ROUTE_PATH[ROUTE_NAME.MATCHES_DETAILS], {
    nctNumber
  });
};

export const getFirstTrialNctNumber = trials => {
  if (trials && trials?.length) {
    return trials[0]?.nct_number;
  }

  return 1;
};
