import _ from 'lodash';
import { flattenAnswers } from './flattenAnswers';

export function checkIfEqualTo(profile, condition) {
  // Flatten data in case of questions containing subquestions
  const clonedProfile = flattenAnswers(profile);
  // Check in store if the condition equals
  const { ifId, ifValue } = condition;
  const profileValue = clonedProfile[ifId];

  if (!_.hasIn(clonedProfile, ifId)) {
    // In case the ifId is not in profile
    // console.log(ifId, 'not exist');
    return false;
  }
  if (_.isArray(profileValue) && !_.isArray(ifValue)) {
    // In case the attribute is array of objects ({label, value})
    return _.includes(profileValue, ifValue);
  }
  const checkIf = _.isEqual(profileValue, ifValue);

  // console.log(`Check if ${ifId} equals to ${ifValue}. It is ${checkIf}`);

  return checkIf;
}
