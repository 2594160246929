export const FB_EVENTS = {
  REGISTRATION_EVENT: 'CompleteRegistration',
  PAGE_VIEW: 'PageView',
  QUICK_SIGNUP_WITHOUT_ENGAGEMENT: 'QuickSignupWithoutEngagement',
  QUICK_SIGNUP_HASNT_FINISHED: 'QuickSignupHasntFinished',
};

export const FB_TRACK_TYPE = {
  TRACK_ALL: 'track',
  TRACK_SINGLE: 'trackSingle',
};
