import React, { useState } from 'react';
import './Radio.css';
import PropTypes from 'prop-types';

const Radio = (props) => {
  const {
    options, value, onChange, api = {}, className,
  } = props;
  const [currentValue, setCurrentValue] = useState(value);
  api.setValue = setCurrentValue;
  const setValue = (newValue) => {
    setCurrentValue(newValue);
    onChange(newValue);
  };
  const renderOptions = () => options.map((option, key) => (
    <div
      key={key}
      className={`radio-option ${currentValue === option.value ? 'selected' : ''} ${className || ''}`}
      onClick={() => { setValue(option.value); }}
    >
      <div className="radio-option-value">
        <div className="radio-option-value-inner" />
      </div>
      <div className="radio-option-title">{option.title}</div>
    </div>
  ));
  return (
    <div className="radio">
      {renderOptions()}
    </div>
  );
};

Radio.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  api: PropTypes.any,
  className: PropTypes.string,
};

export default Radio;
