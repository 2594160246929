import React, { useState } from 'react';

import CheckIcon from 'new-ui/assets/icons/success.svg';
import XIcon from 'new-ui/assets/icons/x.svg';

import app from 'new-ui/app';
import './Alert.css';

export const ALERT_TYPES = {
  INFO: 'INFO',
  OK: 'OK',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
};

// const ALERT_TYPE_COLORS = {
//   [ALERT_TYPES.INFO]: 'black',
//   [ALERT_TYPES.OK]: 'green',
//   [ALERT_TYPES.WARNING]: 'orange',
//   [ALERT_TYPES.ERROR]: 'red',
// };

const Alert = () => {
  const [alert, setAlert] = useState(null);

  const hideAlert = () => {
    setAlert(null);
  };

  const showAlert = (text = 'Alert Text', time = 10000, type = ALERT_TYPES.OK) => {
    setAlert({
      text,
      type,
    });
    if (time === null) return;
    setTimeout(() => {
      hideAlert();
    }, time);
  };

  app.alert = showAlert;
  app.alertHide = hideAlert;

  return (
    <div className="alerts">
      {
        alert && (
          <div className="alert">
            <div className="icon">
              <img alt="check" src={CheckIcon} />
            </div>
            <div className="text">{alert.text}</div>
            <div className="close" onClick={() => { hideAlert(); }}>
              <img alt="close" src={XIcon} />
            </div>
          </div>
        )
      }
    </div>
  );
};

export default Alert;
