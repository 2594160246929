import { configurationService } from './api';
import {
  APP_CONFIGURATION_REQUEST,
  APP_CONFIGURATION_SUCCESS,
  APP_CONFIGURATION_FAILURE,
} from './constants';

const getConfiguration = (options) => {
  return async (dispatch) => {
    dispatch({type: APP_CONFIGURATION_REQUEST});
    try {
      const configuration = await configurationService.getConfiguration(options);
      dispatch({type: APP_CONFIGURATION_SUCCESS, payload: configuration});
    } catch (ex) {
      console.error('Error occurred, while getting app configuration:', ex);
      dispatch({type: APP_CONFIGURATION_FAILURE});
    }
  }
};

export const appConfigurationActions = {
  getConfiguration,
};
