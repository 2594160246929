import configs from 'configs';
import { authHeaders } from 'functions/authHeader';
import getUrlWithQueryString from 'functions/getUrlWithQueryString';
import { formatMatchesResultFromApi } from 'functions/formatMatchesResultFromApi';
import request from 'functions/api/request';
import { MATCH_FLOWS } from 'new-ui/constants';
import { getQuestionnaireRoute } from 'functions/getQuestionnaireRoute';
import * as queryString from 'query-string';
import download from 'downloadjs';
import { invoke } from 'functions/api/invokeRequest';
// import app from 'new-ui/app';
import { supportedCancerTypes } from './data/cancerTypes';

const apiUrl = configs.beApiUrl;

export const getUser = async (data) => {
  let response = null;

  const requestOptions = {
    url: `${apiUrl}/user/profile`,
    qs: {
      lang: data?.lang || null,
    },
    headers: await authHeaders(),
    json: true,
  };

  try {
    response = await request.get(requestOptions);
  } catch (ex) {
    console.error('Error occurred, while getting user profile', ex);
    throw ex;
  }

  return response;
};

const getTrialsMatches = async (data) => {
  const {
    patientId, lat, lng, distance, lang, matchFlow, newest,
  } = data;
  const params = {
    patientId, lat, lng, distance, lang, matchFlow,
  };
  if (newest) {
    Object.assign(params, { newest });
  }
  let response = null;
  const url = getUrlWithQueryString(`${apiUrl}/profile/matches`, params);
  const requestOptions = {
    url,
    headers: await authHeaders(),
    json: true,
  };
  try {
    response = await request.get(requestOptions);
  } catch (error) {
    console.error('Error occurred, while getting trials matches:', error);
    throw error;
  }
  return formatMatchesResultFromApi(response);
};

export const getTrialByNCT = async ({
  nctNumber, usersCountry, conditions = {
    isMobile: false,
    isForPDF: false,
    lang: 'en',
  },
}) => {
  const conditionsParam = {
    isMobile: false,
    isForPDF: false,
    lang: 'en',
    ...conditions,
  };
  let response = null;
  let url = `${apiUrl}/public/trial/findByNctNumber/${nctNumber}`;
  let queryParams = {
    conditions: JSON.stringify(conditionsParam),
  };
  if (usersCountry) {
    const { lat, lng } = usersCountry;
    queryParams = {
      ...queryParams,
      lat,
      lng,
    };
  }
  url = `${url}?${queryString.stringify(queryParams)}`;

  const requestOptions = {
    url,
    headers: await authHeaders(),
    json: true,
  };
  try {
    response = await request.get(requestOptions);
  } catch (ex) {
    console.error('Error occurred, while getting trials matches:', ex);
    throw ex;
  }
  return response?.success ? response.data : null;
};

export const getTrials = async (user, matchFlow = MATCH_FLOWS.LATEST, lang = 'en', newest = null) => getTrialsMatches({
  patientId: user.personal.patient_id,
  lat: user.info.country?.location?.lat,
  lng: user.info.country?.location?.lng,
  distance: user.personal.travel_limit,
  lang,
  matchFlow,
  newest,
});

const getTreatmentFinderData = async () => {
  let response = null;

  const requestOptions = {
    url: `${apiUrl}/user/statistic`,
    headers: await authHeaders(),
    json: true,
  };

  try {
    response = await request.get(requestOptions);
  } catch (ex) {
    console.error('Error occurred, while getting profile data:', ex);
    throw ex;
  }

  return response;
};

const saveProviderInfo = async (data) => {
  let response = null;

  const requestOptions = {
    url: `${apiUrl}/user/statistic/saveProviderInfo`,
    headers: await authHeaders(),
    data,
    json: true,
  };

  try {
    response = await request.post(requestOptions);
  } catch (ex) {
    console.error('Error occurred, while trying to save provider info', ex);
    throw ex;
  }

  return response;
};

const detachUserProvider = async (data) => {
  let response = null;

  const requestOptions = {
    url: `${apiUrl}/user/provider/detach`,
    headers: await authHeaders(),
    data,
    json: true,
  };

  try {
    response = await request.post(requestOptions);
  } catch (ex) {
    console.error('Error occurred, while trying to save provider info', ex);
    throw ex;
  }

  return response;
};

const getConfiguration = async (options) => {
  let user_wix_id = null;
  try {
    user_wix_id = JSON.parse(localStorage.getItem('user')).user_wix_id;
  } catch (e) {}

  const url = getUrlWithQueryString(`${apiUrl}/configuration${user_wix_id ? `/${user_wix_id}` : ''}`, options);

  const requestOptions = {
    url,
    headers: await authHeaders(),
    json: true,
  };

  try {
    return await request.get(requestOptions);
  } catch (ex) {
    console.error('Error occurred, while getting app configuration:', ex);
    throw ex;
  }
};

const setConfiguration = async (configuration) => {
  let user_wix_id = null;
  try {
    user_wix_id = JSON.parse(localStorage.getItem('user')).user_wix_id;
  } catch (e) {}

  if (!configuration || typeof configuration !== 'object' || Array.isArray(configuration)) {
    throw new Error('Configuration is not an object');
  }

  const url = getUrlWithQueryString(`${apiUrl}/configuration/${user_wix_id}`);

  const requestOptions = {
    url,
    headers: await authHeaders(),
    data: configuration,
    json: true,
  };

  try {
    return await request.post(requestOptions);
  } catch (ex) {
    console.error('Error occurred, while setting app configuration:', ex);
    throw ex;
  }
};

export const sendContactUs = async (data) => {
  let response = null;

  const requestOptions = {
    url: `${apiUrl}/public/email/contactus`,
    headers: await authHeaders(),
    data,
    json: true,
  };

  try {
    response = await request.post(requestOptions);
  } catch (ex) {
    console.error('Error occurred, while trying to send contact us info', ex);
    throw ex;
  }

  return response;
};

export const createEmailsToken = async () => {
  let response = null;

  const requestOptions = {
    url: `${apiUrl}/user/email/token`,
    headers: await authHeaders(),
    json: true,
  };

  try {
    response = await request.get(requestOptions);
  } catch (ex) {
    console.error('Error occurred, while trying to create emails token', ex);
    throw ex;
  }

  if (!response.success) {
    throw new Error('Failed while trying to create emails token');
  }

  return response.data;
};

export const getSearchParamsApi = async () => {
  let response = null;

  const requestOptions = {
    url: `${apiUrl}/public/trials/searchParams`,
    headers: await authHeaders(),
    json: true,
  };

  try {
    response = await request.get(requestOptions);
  } catch (ex) {
    console.error('Error occurred, while getting profile data:', ex);
    throw ex;
  }

  return response;
};

let _indications = null;

export const getIndications = async () => {
  if (_indications) return _indications;
  const response = await fetch(apiUrl + getQuestionnaireRoute({ questionId: 'diseases' }));
  const { data } = await response.json();
  _indications = [
    ...supportedCancerTypes,
    ...data.map((i) => ({ title: i.label, value: i.name })),
  ];
  return _indications;
};

export const getBiomarkers = async (condition) => {
  const response = await fetch(apiUrl + getQuestionnaireRoute(
    { questionId: `question/biomarkers${condition ? `/${condition}` : ''}` },
  ));
  const { data } = await response.json();
  return data;
};

export const getFieldsUpdateDates = async () => {
  const url = getUrlWithQueryString(`${apiUrl}/profile/userAnswersDates`, {});

  const requestOptions = {
    url,
    headers: await authHeaders(),
    json: true,
  };

  try {
    return await request.get(requestOptions);
  } catch (ex) {
    console.error('Error occurred, while getting app configuration:', ex);
    throw ex;
  }
};

window.__getFieldsUpdateDates = getFieldsUpdateDates;

export const downloadPDF = async (nctNumbers, pdfFor = 'trials') => {
  const data = await invoke('/public/pdf/trialsByNctNumbers', {}, {
    nctNumbers,
    // pdfFor: pdfFor ? String(pdfFor).toLowerCase() : null,
  });
  download(data, 'Report.pdf', 'application/pdf');

  // let response = null;

  // const requestOptions = {
  //   url: `${apiUrl}/public/pdf/trialsByNctNumbers`,
  //   data: {
  //     nctNumbers,
  //     pdfFor,
  //   },
  //   json: true,
  // };

  // try {
  //   response = await request.post(requestOptions);
  // } catch (ex) {
  //   console.error('Error occurred, while trying to save provider info', ex);
  //   throw ex;
  // }

  // download(response, 'Report.pdf', 'application/pdf');
};

window.__downloadPDF = downloadPDF;

export const getOptions = async (id) => {
  let response = null;

  const requestOptions = {
    url: `${apiUrl}/questionnaire/question/${id}`,
    json: true,
  };

  try {
    response = await request.get(requestOptions);
  } catch (ex) {
    console.error('Error occurred, while getting profile data:', ex);
    throw ex;
  }

  return response;
};

export default {
  getUser,
  getTrialsMatches,
  getTrials,
  getTreatmentFinderData,
  saveProviderInfo,
  getConfiguration,
  setConfiguration,
  detachUserProvider,
  sendContactUs,
};
