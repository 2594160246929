export default {
  EQUAL_TO: 'equalTo',
  NOT_EQUAL_TO_OR_SKIPPED: 'notEqualToOrSkipped',
  CONTAIN_ONE_OF: 'containOneOf',
  CONTAINS_ONE_OF_OR_SKIPPED: 'containsOneOfOrSkipped',
  DOESNT_CONTAIN_ONE_OF_OR_SKIPPED: 'OrSkipped',
  AGE_LESS_THAN: 'ageLessThan',
  IS_SKIPPED: 'isSkipped',
  IS_STATE_VALUE_TRUE: 'isStateValueTrue',
};
