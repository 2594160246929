import isEmpty from '../../../functions/isEmpty';
import { TRIPLE_QUESTION_DROP_DOWN } from '../constants/questionTypes';
import { tripleSingleSelectQuestions } from '../constants/trippleSelectQuestions';
import { conditionProfileValueToFormValue } from './conditionProfileValueToFormValue';

const mapQuestionByIds = (questions) => {
  const mapping = {};

  for (const question of questions) {
    mapping[question.id] = {
      ...question,
    };
  }

  return mapping;
};

// We need to map the values from the server back to the format our components understand
export const mapConditionProfileToForm = ({
  allQuestions, qstack, conditionProfile, optionsFromServer, condition, dispatch,
}) => {
  const questionByIds = mapQuestionByIds(allQuestions);
  // Data structure needed for components
  const form = {};

  // Key will be the section, while value array of questionIds
  qstack.forEach((questionId) => {
    const value = conditionProfile[questionId];
    const question = questionByIds[questionId];

    if (!question) {
      // do nothing; just assign as is
      form[questionId] = value;
      return;
    }

    const { type: questionType } = question;

    if (questionType === TRIPLE_QUESTION_DROP_DOWN) {
      const { options } = question;
      tripleSingleSelectQuestions.forEach((tnm) => {
        const tnmOptions = options.find((option) => option.name === tnm)?.options;
        if (conditionProfile[tnm]) {
          form[tnm] = tnmOptions && [tnmOptions.find((option) => option.value === String(conditionProfile[tnm]))];
        }
      });
    }

    if (isEmpty(value)) {
      form[questionId] = null;
      return;
    }

    form[questionId] = conditionProfileValueToFormValue({
      conditionProfileValue: value,
      question,
      optionsFromServer,
      condition,
      dispatch,
    });
  });

  return form;
};
