import React, { useEffect, useState } from 'react';
import './style.css';
import { useTranslation } from 'utils/modifiedTranslation';
import app from 'new-ui/app';
import { getRandomQuotes } from '../api';
import img_1 from './images/img_1.png';
import img_2 from './images/img_2.png';
import img_3 from './images/img_3.png';
import img_4 from './images/img_4.png';
import img_5 from './images/img_5.png';
import { FEED_GA } from '..';

const QuotesWidget = ({ data }) => {
  const { t } = useTranslation();
  const [quote, setQuote] = useState(null);
  const [randomImage, setRandomImage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    app.sendGoogleAnalyticsEvent(FEED_GA.category, FEED_GA.events.quotesViewed, {
      widget: 'quotes',
    });
    const fetch = async () => {
      const quotes = await getRandomQuotes();
      setQuote(quotes[0]);
      setIsLoading(false);
    };
    const images = [img_1, img_2, img_3, img_4, img_5];
    const randomIndex = Math.floor(Math.random() * images.length);
    setRandomImage(images[randomIndex]);
    fetch();
  }, []);

  return (
    <>
      {!isLoading && (
        <div className="quotes-widget">
          <div className="image-cont">
            <img alt="icon" src={randomImage} />
          </div>
          <div className="quote-cont">
            <div className="quote">
              “
              {t(quote?.content?.text)}
              ”
            </div>
            <div className="author">{t(quote?.content?.author)}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default QuotesWidget;
