import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import LANGUAGE, { pfizerLanguages } from 'components/AppTranslationWrapper/constants/language';
import { changeLanguage } from 'components/AppTranslationWrapper/action';
import { ReactComponent as ArrowLeft } from 'new-ui/assets/icons/arrow_left.svg';
import app from 'new-ui/app';
import Form from '../Form';

import './LanguageMenu.css';

const languageFilters = {
  pfizer: pfizerLanguages,
};

const options = [];

for (const lang in LANGUAGE) {
  options.push({
    value: lang,
    title: lang.toUpperCase(),
  });
}

const inputs = {
  lang: {
    type: 'radio',
    options,
  },
};

const LanguageMenu = ({ fullWidth }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const setLanguage = (lang) => {
    dispatch(changeLanguage(lang));
  };
  const { lang } = useSelector((state) => ({
    lang: state?.main?.lang,
  }));

  const data = {
    lang,
  };

  if (app.provider?.code && languageFilters[app.provider?.code]) {
    const filteredLanguages = Object.keys(languageFilters[app.provider?.code]);
    inputs.lang.options = inputs.lang.options.filter((language) => filteredLanguages.indexOf(language.value) !== -1);
  }

  return (
    <div className={`language-menu ${isOpen ? 'open' : ''}`}>
      {fullWidth
        ? (
          <div className="fullwidth-wrapper" onClick={() => { setIsOpen(!isOpen); }}>
            <div className="current">
              <div className="title-wrapper">
                <p>
                  {t('medical_profile.system_language')}
                  :
                </p>
                <span>{lang}</span>
              </div>
              <ArrowLeft />
            </div>
          </div>
        ) : (
          <div onClick={() => { setIsOpen(!isOpen); }}>
            <div className="current">
              <span>{lang}</span>
              {' '}
              <ArrowLeft />
            </div>
          </div>
        )}
      <div className="language-popup">
        <Form
          inputs={inputs}
          data={data}
          onChange={(data) => {
            setLanguage(data.lang);
            setIsOpen(false);
          }}
        />
      </div>
    </div>
  );
};

export default LanguageMenu;

LanguageMenu.propTypes = {
  fullWidth: PropTypes.bool,
};
