import { TRIAL_ACTION as TA } from './constants';

const initialState = {
  isFetching: false,
  error: null,
  trials: [],
  pagination: {
    page: 1,
    total: 0,
    perPage: 12,
    pagesCount: 0
  },
  filters: {
    cancerTypes: [],
    nctIds: []
  }
};

export const publicTrials = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case TA.GET_TRIALS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null
      };
    case TA.GET_TRIALS_SUCCESS:
      return {
        ...state,
        ...payload,
        pagination: {
          ...state.pagination,
          ...(payload?.pagination || {})
        },
        isFetching: false,
        error: null
      };
    case TA.GET_TRIALS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: payload
      };
    case TA.TRIALS_CHANGE_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: payload.page
        }
      };
    case TA.TRIALS_CHANGE_FILTERS:
      const itemKey = Object.keys(payload).pop();

      return {
        ...state,
        filters: {
          ...state.filters,
          [itemKey]: [
            ...(state.filters[itemKey].includes(payload[itemKey])
              ? state.filters[itemKey].filter(item => item !== payload[itemKey])
              : [...state.filters[itemKey], payload[itemKey]])
          ]
        }
      };
    case TA.TRIALS_CLEAR_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [payload]: []
        }
      };
    default:
      return state;
  }
};
