import React, { useEffect, useState } from 'react';
import './AddPatient.css';
import Form, { FORM_INPUT_TYPES } from 'new-ui/Components/Form';
import { PopupFooter } from 'new-ui/Components/Popup';
import Button from 'new-ui/Components/Button';
import { useTranslation } from 'utils/modifiedTranslation';
import app from 'new-ui/app';
import useRecaptcha from 'utils/hooks/useRecaptcha';
import { getInitialConditionProfileByIndication, setUser, signup } from 'new-ui/functions';
// import { hcpApi } from 'new-ui/Search/api';
import { PLATFORM, USER_TYPES, USER_TYPES_VALUES } from 'new-ui/Components/QuestionRenderer/renderers/IntakeAboutYou';

const ERROR_TYPES = {
  INDICATION_REQUIRED: 'INDICATION_REQUIRED',
  NAME_REQUIRED: 'NAME_REQUIRED',
};

const GA = {
  category: 'HCP',
  events: {
    error: 'Could not signup',
  },
};

const AddPatient = (props)=>{
  const {
    caregiver,
    onSignup,
    indication,
    onBeforeSignup,
    name,
    email,
    defaultEmail,
    emailRequired = false,
    indicationTitle,
    nameTitle,
    emailTitle,
    buttonTitle,
    title,
    subtitle,
  } = props;

  const [form, setForm] = useState(null);
  const [error, setError] = useState(null);
  const { recaptcha, getRecaptchaTokenAsync } = useRecaptcha();

  window.__addPatientCaregiver = caregiver;

  const { t } = useTranslation();

  const ERRORS = {
    [ERROR_TYPES.NAME_REQUIRED]: t('general.name_required'),
    [ERROR_TYPES.INDICATION_REQUIRED]: t('general.indication_required'),
  };

  useEffect(()=>{
    const fetch = async ()=>{
      const _form = {
        inputs: {
          indication: {
            required: true,
            title: indicationTitle || t('add_patient.indication_input_title'),
            type: FORM_INPUT_TYPES.INDICATION_CHOOSER,
            hidden: !!indication,
          },
          name: {
            required: true,
            title: nameTitle || t('add_patient.name_input_title'),
            type: FORM_INPUT_TYPES.INPUT,
          },
          email: {
            title: emailTitle || t('add_patient.email_input_title'),
            type: FORM_INPUT_TYPES.INPUT,
            required: emailRequired,
          },
        },
        data: {
          indication,
          name,
          email,
        },
      };
      setForm(_form);
    };
    fetch();
    // eslint-disable-next-line
  }, []);

  const onChange = (newData)=>{

  };

  const add = async ()=>{
    const token_data = {
      access_token: localStorage.getItem('access_token'),
      refresh_token: localStorage.getItem('access_token'),
    };
    if (!form?.data?.indication) {
      return setError(ERRORS[ERROR_TYPES.INDICATION_REQUIRED]);
    }
    if (!form?.data?.name) {
      return setError(ERRORS[ERROR_TYPES.NAME_REQUIRED]);
    }
    app.setShowPreloader(true);
    const condition_profile = await getInitialConditionProfileByIndication(form.data.indication);
    const patient = {
      condition_profile,
      info: {
        family_name: form.data.name,
        email: form.data.email || defaultEmail,
        user_type: USER_TYPES_VALUES[USER_TYPES.PATIENT],
        platform: PLATFORM.HCP,
        organization: caregiver?.info?.organization,
      },
      personal: {
        condition: form.data.indication,
      },
    };
    if (onBeforeSignup) await onBeforeSignup(patient);
    signup({
      email: form.data.email || defaultEmail,
      indication: form.data.indication,
      recaptchaToken: await getRecaptchaTokenAsync(),
      condition_profile: patient.condition_profile,
      info: patient.info,
      onsignup: async (user) => {
        console.log('user signup success :: addPatient', user);
        if (caregiver) {
          setUser({
            ...token_data,
            user: JSON.stringify(caregiver),
          });
          app.user = await app.getUser();
        }
        // if (doLogin) {
        //   debugger;
        //   setUser({
        //     ...token_data,
        //     user: JSON.stringify(user),
        //   });
        //   app.user = await app.getUser();
        // }
        app.intercom.updateUserAttributes({
          email: form.data.email,
          condition: indication,
          user_type: `${PLATFORM.HCP}_${USER_TYPES_VALUES[USER_TYPES.PATIENT]}`,
        }, user.info.user_wix_id);
        // app.sendGoogleAnalyticsEvent(GA.category, GA.events.success, { name: 'user_signup_success' });
        onSignup(user, setError);
      },
      onerror: (e) => {
        debugger;
        app.setShowPreloader(false);
        setError('Signup Error');
        app.sendGoogleAnalyticsEvent(GA.category, GA.events.error, { name: 'caregiver_patient_signup_error' });
      },
    });
  };

  return (
    <div className="add-patient">
      {recaptcha}
      <div className="add-patient-title-holder">
        <div className="add-patient-title">
          { title || (
          app?.user?.patients?.length ? t('general.add_patient') : t('add_patient.add_first')
          ) }
        </div>
        {
          subtitle ? <div className="add-patient-subtitle">{subtitle}</div> : null
        }
      </div>
      <Form form={form} onChange={onChange} />
      {error ? <div className="error">{error}</div> : null}
      <PopupFooter>
        <Button title={buttonTitle || t('add_patient.add')} action={add} />
      </PopupFooter>
    </div>
  );
};

export default AddPatient;
