export const GA_GENERAL_CATEGORY = 'General';

export const GA_GENERAL_EVENTS = {
  TREATMENTS_TAB_SELECTED: 'SOC_TAB_SELECTED',
  TRIALS_TAB_SELECTED: 'TRIALS_TAB_SELECTED',
  POPUP_OPEN: 'POPUP_OPEN',
};

export const GA_GENERAL_POPUPS_SOURCES = {
  URL: 'URL',
  DASHBOARD: 'DASHBOARD',
};

export const GA_GENERAL_POPUPS_EVENTS = {
  PHONE_NUBMER_UPDATE: 'PHONE_NUBMER_UPDATE',
  PHONE_NUBMER_UPDATE_SUCCESS: 'PHONE_NUBMER_UPDATE_SUCCESS',
};

export const GA_QUESTIONNAIRE_CATEGORY = 'Questionnaire';

export const GA_QUESTIONNAIRE_EVENTS = {
  QUESTIONNAIRE_STARTED: 'QUESTIONNAIRE_STARTED',
  QUESTIONNAIRE_STARTED_INTAKE: 'QUESTIONNAIRE_STARTED_INTAKE',
  QUICK_SIGNUP_POPUP_VIEW: 'QUICK_SIGNUP_POPUP_VIEW',
  QUICK_SIGNUP_POPUP_VIEW_INTAKE: 'QUICK_SIGNUP_POPUP_VIEW_INTAKE',
  QUICK_SIGNUP_SUCCESS: 'QUICK_SIGNUP_SUCCESS',
  QUICK_SIGNUP_SUCCESS_INTAKE: 'QUICK_SIGNUP_SUCCESS_INTAKE',
  QUESTIONNAIRE_ENDED: 'QUESTIONNAIRE_ENDED',
};

export const GA_INTAKE_CATEGORY = 'Intake';

export const GA_INTAKE_EVENTS = {
  INTAKE_BOT_VIEW: 'INTAKE_BOT_VIEW',
  INTAKE_PAGE_VIEW: 'INTAKE_PAGE_VIEW',
  INTAKE_QUESTION_ANSWERED: 'INTAKE_QUESTION_ANSWERED',
  INTAKE_MEDICAL_DONE: 'INTAKE_MEDICAL_DONE',
  INTAKE_ABOUT_DONE: 'INTAKE_ABOUT_DONE',
};

export const GA_LOGIN_CATEGORY = 'Login';

export const GA_LOGIN_EVENTS = {
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_SUCCESS_TOKEN: 'LOGIN_SUCCESS_TOKEN',
};

export const GA_CHAT_EVENTS = {
  PROFILE_CHAT_OPEN: 'PROFILE_CHAT_OPEN',
  PROFILE_CHAT_CLOSE: 'PROFILE_CHAT_CLOSE',
  PROFILE_CHAT_STARTED: 'PROFILE_CHAT_STARTED',
  PROFILE_CHAT_USER_UPDATE_SCAN: 'PROFILE_CHAT_USER_UPDATE_SCAN',
  PROFILE_CHAT_USER_CLICKED_ACTION_PANEL: 'PROFILE_CHAT_USER_CLICKED_ACTION_PANEL',
  PROFILE_CHAT_USER_UPDATE_SCORING: 'PROFILE_CHAT_USER_UPDATE_SCORING',
  PROFILE_CHAT_USER_SEND_MESSAGE_TO_CHAT: 'PROFILE_CHAT_USER_SEND_MESSAGE_TO_CHAT',
  PROFILE_CHAT_VIEW_BY_USER: 'PROFILE_CHAT_VIEW_BY_USER',
};

export const GA_UPLOAD_CHAT_EVENTS = {
  UPLOAD_CHAT_OPEN: 'UPLOAD_CHAT_OPEN',
  UPLOAD_CHAT_CLOSE: 'UPLOAD_CHAT_CLOSE',
  UPLOAD_CHAT_USER_UPDATE_SCORING: 'UPLOAD_CHAT_USER_UPDATE_SCORING',
  UPLOAD_CHAT_USER_SEND_MESSAGE_TO_CHAT: 'UPLOAD_CHAT_USER_SEND_MESSAGE_TO_CHAT',
  UPLOAD_CHAT_VIEW_BY_USER: 'UPLOAD_CHAT_VIEW_BY_USER',
};

export const GA_UPLOAD_EXPLAIN_CHAT_EVENTS = {
  UPLOAD_EXPLAIN_CHAT_OPEN: 'UPLOAD_EXPLAIN_CHAT_OPEN',
  UPLOAD_EXPLAIN_CHAT_CLOSE: 'UPLOAD_EXPLAIN_CHAT_CLOSE',
  UPLOAD_EXPLAIN_CHAT_USER_UPDATE_SCORING: 'UPLOAD_EXPLAIN_CHAT_USER_UPDATE_SCORING',
  UPLOAD_EXPLAIN_CHAT_USER_SEND_MESSAGE_TO_CHAT: 'UPLOAD_EXPLAIN_CHAT_USER_SEND_MESSAGE_TO_CHAT',
  UPLOAD_EXPLAIN_CHAT_VIEW_BY_USER: 'UPLOAD_EXPLAIN_CHAT_VIEW_BY_USER',
};
