import configs from '../configs';
import handleResponse from './handleResponse';
import LOCAL_STORAGE_KEYS from '../utils/constants/localStorageKeys';
import { ROUTE_NAME, ROUTE_PATH } from '../components/AppRoutes/constants';

export default () => {
  const refreshToken = localStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
  if (!refreshToken) {
    window.location.replace(`${window.location.origin}${ROUTE_PATH[ROUTE_NAME.LOGIN]}`);
    return null;
  }

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET',
      'Access-Control-Allow-Headers': 'content-type',
      Authorization: `Bearer ${refreshToken}`,
    },
  };

  return fetch(`${configs.beApiUrl}/token`, requestOptions)
    .then(handleResponse)
    .then((result) => {
      const { token } = result;
      localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, token);
      return token;
    })
    .catch((ex) => {
      console.error('Error occurred, while getting new "access_token":', ex);
    });
};
