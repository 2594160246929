import React from 'react';
import { PopupContent } from 'new-ui/Components/Popup';
import AiAssistant from 'new-ui/Components/AiAssistent';

const AiAssistantPopup = ()=>{
  return (
    <>
      <PopupContent>
        <AiAssistant />
      </PopupContent>
    </>
  );
};

export default AiAssistantPopup;
