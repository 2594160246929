import React, { useState } from 'react';
import { useTranslation } from 'utils/modifiedTranslation';
import CustomSelect from '../CustomSelect';
import Button from 'new-ui/Components/Button';
import './style.css';
import { EVENT_TYPES, REPEAT_OPTIONS } from '../../options';

const EventAddForm = ({ initialEventType = '', onAddEvent }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    month: '',
    day: '',
    year: '',
    cycle: '',
    eventType: initialEventType,
    repeatStatus: 'no_repeat'
  });

  const [errors, setErrors] = useState({
    month: false,
    day: false,
    year: false,
    cycle: false,
    eventType: false,
    repeatStatus: false
  });

  const handleChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: !value,
    }));
  };

  const isFormValid = Object.values(formData).every((value) => value.trim() !== '');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isFormValid) {
      console.log('Event Data:', formData);
      onAddEvent(formData);
    } else {
      console.log('Please fill all fields');
    }
  };

  const arrangeTypeOptions = () => {
    return Object.values(EVENT_TYPES).map(option => {
      option.value = option.key;
      option.label = t(option.label);
      return option;
    });
  }; 

  const arrangeRepeatOptions = (options) => {
    return options.map(option => {
      option.label = t(option.label);
      return option;
    });
  };

  return (
    <form onSubmit={handleSubmit} className='event-add-form'>
      <div className='form-group-wrapper'>
        <div className='form-group form-group--month' style={{ borderColor: errors.month ? 'red' : '#FF8C69' }}>
          <input
            type="text"
            id="month"
            name="month"
            value={formData.month}
            placeholder={t('events_widget.placeholder.month')}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />
        </div>
        <div className='form-group form-group--day' style={{ borderColor: errors.day ? 'red' : '#FF8C69' }}>
          <input
            placeholder={t('events_widget.placeholder.day')}
            type="text"
            id="day"
            name="day"
            value={formData.day}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />
        </div>
        <div className='form-group form-group--year' style={{ borderColor: errors.year ? 'red' : '#FF8C69' }}>
          <input
            placeholder={t('events_widget.placeholder.year')}
            type="text"
            id="year"
            name="year"
            value={formData.year}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />
        </div>
      </div>

      <CustomSelect
        label="Select"
        className={'event-select'}
        options={arrangeTypeOptions()}
        selectedValue={formData.eventType}
        onSelect={(value) => handleChange('eventType', value)} 
      />

      <div className='form-group form-group--cycle' style={{ borderColor: errors.cycle ? 'red' : '#FF8C69' }}>
        <input
          type="text"
          id="cycle"
          name="cycle"
          placeholder={t('events_widget.placeholder.cycle')}
          value={formData.cycle}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        />
      </div>

      <CustomSelect
        label="Repeat status"
        className={'status-select'}
        options={arrangeRepeatOptions(REPEAT_OPTIONS)}
        selectedValue={formData.repeatStatus}
        onSelect={(value) => handleChange('repeatStatus', value)} 
      />

      <Button
        backgroundColor="#213332"
        color="#FFF"
        width="75px"
        className="add-btn"
        title={t('events_widget.button.add')}
        action={handleSubmit}
        disabled={!isFormValid}
      />
    </form>
  );
};

export default EventAddForm;