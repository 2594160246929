import React from 'react';
import './style.css';
import PropTypes from 'prop-types';

const CustomRadio = ({ value, name, checked, onChange, disabled }) => {
  return (
    <div className={`custom-radio-v2 ${disabled ? 'disabled' : ''}`}>
      <input
        type="radio"
        id={value}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        className="radio-input"
      />
      <label htmlFor={value} className="radio-label">
        <span className="custom-radio-button"></span>
      </label>
    </div>
  );
};

CustomRadio.propTypes = {
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default CustomRadio;
