import React from "react";
import "./style.css";
import ShareIcon from 'new-ui/assets/icons/share.svg';

const ShareButton = ({title='Share Colleague',onClick}) => {
  return (
    <div className="share-btn" onClick={onClick}>
      <img alt="icon" src={ShareIcon}/>
      <span>{title}</span> 
    </div>
  );
};

export default ShareButton;