import { createTheme as createMuiTheme } from '@material-ui/core/styles';

const customMuiTheme = createMuiTheme({
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500
  },
  palette: {
    primary: {
      dark: '#fff4f1',
      main: '#FF8C69',
      contrastText: '#fff'
    },
    secondary: {
      light: '#fff4f1',
      main: '#FF8C69',
      contrastText: '#fff'
    }
  },
  overrides: {
    MuiNativeSelect: {
      root: {
        fontSize: 18
      }
    },
    MuiFormLabel: {
      root: {
        fontSize: 18
      }
    },
    MuiListItemText: {
      primary: {
        fontSize: 18
      }
    },
    MuiChip: {
      label: {
        fontSize: 18
      }
    },
    MuiFormControlLabel: {
      label: {
        fontSize: 18
      }
    }
  }
});

export default customMuiTheme;
