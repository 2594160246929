import React, { useState } from 'react';

import './Text.css';
import PropTypes from 'prop-types';

const Text = (props) => {
  const {
    value, height = 'initial', onChange, required = false,
  } = props;
  const [val, setValue] = useState(value);
  return (
    <div className="text">
      <textarea
        value={val}
        required={required}
        onChange={(ev) => {
          onChange(ev.currentTarget.value);
          setValue(ev.currentTarget.value);
        }}
        style={{
          height,
        }}
      >
        {val}
      </textarea>
    </div>
  );
};

Text.propTypes = {
  value: PropTypes.string,
  height: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
};

export default Text;
