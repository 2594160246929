import * as React from "react";

const MicroscopeIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="85" height="118" {...props} viewBox="0 0 85 118" fill="none">
    <g clipPath="url(#clip0_23667_19595)">
      <path d="M45.492 105.826H16.8008V101.767H45.492C64.6872 101.767 80.3111 86.1435 80.3111 66.9484C80.3111 53.7593 73.9398 42.7617 62.861 36.7556L61.0754 35.7816L63.0233 32.2104L64.8089 33.1844C77.0646 39.7992 84.3693 52.4201 84.3693 66.9484C84.3693 88.3755 66.9191 105.826 45.492 105.826Z" fill="#213332"/>
      <path d="M45.2891 95.7613H16.8008V91.7031H45.2891C58.2347 91.7031 68.7453 81.1924 68.7453 68.2469C68.7453 59.6842 65.1741 52.9476 58.4376 48.8083L56.6926 47.7532L58.8028 44.3037L60.5478 45.3588C68.4613 50.1881 72.8035 58.3044 72.8035 68.2469C72.8035 83.4244 60.4667 95.7613 45.2891 95.7613Z" fill="#213332"/>
      <path d="M35.3468 71.737L15.1777 60.0901L49.8345 0.110352L70.0036 11.7167L58.6407 31.3988L55.1507 29.3697L64.4439 13.2182L51.336 5.62946L20.7374 58.5885L33.8859 66.1773L46.2227 44.7908L49.7533 46.8199L35.3468 71.737Z" fill="#213332"/>
      <path d="M25.323 72.1428C24.2273 72.1428 23.1722 71.8587 22.1576 71.2905C19.114 69.5455 18.0994 65.6497 19.8445 62.6061L21.7924 59.2378L32.79 65.5685L30.8421 68.9368C29.6652 70.9659 27.5144 72.1022 25.323 72.1022V72.1428ZM24.1867 67.7599C25.2418 68.3687 26.581 68.044 27.2303 67.0295L23.2533 64.7569C22.7258 65.812 23.091 67.1512 24.1461 67.7599H24.1867Z" fill="#213332"/>
      <path d="M52.6345 49.9039C45.8979 49.9039 40.46 44.4254 40.46 37.7294C40.46 31.0335 45.9385 25.5549 52.6345 25.5549C59.3304 25.5549 64.809 31.0335 64.809 37.7294C64.809 44.4254 59.3304 49.9039 52.6345 49.9039ZM52.6345 29.6131C48.1705 29.6131 44.5181 33.2654 44.5181 37.7294C44.5181 42.1934 48.1705 45.8458 52.6345 45.8458C57.0984 45.8458 60.7508 42.1934 60.7508 37.7294C60.7508 33.2654 57.0984 29.6131 52.6345 29.6131Z" fill="#213332"/>
      <path d="M20.8179 79.8127H16.7598V105.866H20.8179V79.8127Z" fill="#213332"/>
      <path d="M37.6192 79.8127H0V83.8709H37.6192V79.8127Z" fill="#213332"/>
      <path d="M83.8823 113.942H0V118H83.8823V113.942Z" fill="#213332"/>
      <path d="M50.8488 118H27.7578V101.767H50.8488V118ZM31.816 113.942H46.7906V105.826H31.816V113.942Z" fill="#213332"/>
    </g>
    <defs>
      <clipPath id="clip0_23667_19595">
        <rect width="85" height="118" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);
export default MicroscopeIcon;
