import React, { useEffect, useState } from 'react';
import app from 'new-ui/app';
import { getBiomarkers } from 'new-ui/api';
import PropTypes from 'prop-types';
import { useTranslation } from 'utils/modifiedTranslation';
import Form, { FORM_INPUT_TYPES } from '../Form';

const Biomarkers = (props) => {
  const { onChange, biomarkers = [], staticOptions } = props;
  const [form, setForm] = useState(null);
  const [init, setInit] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const fetch = async () => {
      const all_biomarkers = app?.user?.personal?.condition
        ? await getBiomarkers(app?.user?.personal?.condition)
        : (app.biomarkers || await getBiomarkers());
      app.biomarkers = all_biomarkers;
      setForm({
        inputs: {
          biomarkers: {
            type: FORM_INPUT_TYPES.SELECT_V2,
            initOpened: true,
            multi: true,
            staticOptions,
            options: all_biomarkers.map((b) => ({
              title: t(b.name),
              value: b.code,
            })),
          },
        },
        data: {
          biomarkers,
        },
      });
      setInit(true);
    };
    if (!init) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [biomarkers, staticOptions, init]);
  return (
    <div className="biomarkers">
      {form && <Form form={form} onChange={(data) => { onChange(data.biomarkers); }} />}
    </div>
  );
};

Biomarkers.propTypes = {
  onChange: PropTypes.func,
  biomarkers: PropTypes.any,
  staticOptions: PropTypes.bool,
};

export default Biomarkers;
