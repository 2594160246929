import jwtDecode from 'jwt-decode';
import LOCAL_STORAGE_KEYS from 'utils/constants/localStorageKeys';
import getAccessToken from 'functions/getAccessToken';
import clearAuthDataInLocalStorage from 'functions/clearAuthDataInLocalStorage';
import jwt from 'jsonwebtoken';
import configs from 'configs';

// eslint-disable-next-line import/no-unresolved

function isExpired(token) {
  let _isExpired = true;

  try {
    _isExpired = jwtDecode(token).expires < Date.now() / 1000;
  } catch (e) {
    console.error(e);
    return _isExpired;
  }

  return _isExpired;
}

export async function authHeaders() {
  let accessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
  const refreshToken = localStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
  const basicHeaders = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, PUT, POST',
    'Access-Control-Allow-Headers': 'content-type',
  };

  if (!accessToken) {
    return basicHeaders;
  }

  if (isExpired(accessToken)) {
    accessToken = await getAccessToken();
  }

  if (isExpired(refreshToken)) {
    clearAuthDataInLocalStorage();
  }

  return {
    ...basicHeaders,
    Authorization: `Bearer ${accessToken}`,
  };
}
export async function createTempHeader() {
  const secretKey = configs.tokenSecret;
  const payload = {
    user: 'exampleuser',
  };
  const options = {
    expiresIn: '2m', // Token expiration time (e.g., 1 hour)
    // Other options like algorithm ('HS256' is the default)
  };

  const token = jwt.sign(payload, secretKey, options);
  return {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Methods': 'GET, PUT, POST',
      'Access-Control-Allow-Headers': 'content-type',
      Authorization: `Bearer ${token}`,
    },
  };
}
