import configs from 'configs';
import { authHeaders } from 'functions/authHeader';
import request from 'functions/api/request';
import app from 'new-ui/app';

const apiUrl = configs.financeApiUrl;

/*
GET /patients
*/

export const getFinancePatients = async () => {
  let response = null;

  // const requestOptions = {
  //   url: `${apiUrl}/webapp/patients`,
  //   headers: await authHeaders(),
  //   json: true,
  // };

  try {
    // response = await request.get(requestOptions);
    // return await getMockData(0, patientsMock);
  } catch (ex) {
    console.error('Error occurred, while getting patients', ex);
    throw ex;
  }

  // eslint-disable-next-line no-unreachable
  return response?.patients;
};

export const getPatients = async () => {
  // const patients = await getFinancePatients();
  const patients = app.user.patients || [];
  let careAssistandPatients = [];
  try {
    careAssistandPatients = await getCareAssistantPatients();
  }catch(err) {

  }
  const careAssistandPatientsMap = {};

  for(const patient of careAssistandPatients) {
    careAssistandPatientsMap[patient.userWixId] = patient;
  }

  for(const patient of patients) {
    patient._details = careAssistandPatientsMap[patient.info.user_wix_id] || null;
  }

  return patients;
};

window.__getFinancePatients = getFinancePatients;

// const getMockData = async (delay = 0, options) => {
//   return await new Promise((resolve, reject) => {
//     setTimeout(() => {
//       resolve(options);
//     }, delay);
//   });
// };

export const getCareAssistantPatients = async () => {
  let response = null;

  const requestOptions = {
    url: `${apiUrl}/webapp/care-assistants/me`,
    headers: await authHeaders(),
    json: true,
  };

  try {
    response = await request.get(requestOptions);;
  } catch (ex) {
    console.error('Error occurred, while getting patients', ex);
    throw ex;
  }

  // eslint-disable-next-line no-unreachable
  return response.patients;
};
