/**
 * Concats current search param to path
 *
 * @param {String} rootPath URL string
 * @returns {String} URL string with location search parameter
 *
 * @example <caption>Example usage</caption>
 *  // if the current url is '/treatment-finder?dialog=learnMore'
 *  preserveQueryParam('/matches') // '/matches?dialog=learnMore'
 */
export default rootPath => rootPath + window.location.search;
