import { push } from 'connected-react-router';
import { authenticationActions } from 'components/Authentication/actions';
import { authenticationService } from 'components/Authentication/api';
import CONSTANTS, { FILTERS, SORT_ITEMS } from 'components/Matches/constants';
import { ROUTE_PATH, ROUTE_NAME } from 'components/AppRoutes/constants';
import preserveQueryParam from 'functions/preserveQueryParam';
import { shouldHideCardByGroupFilter } from 'components/TreatmentFinder/functions';
import { getOptimizeSortItems } from 'components/Matches/components/List/functions';
import { MatchFlow } from 'domains/Matches/MatchFlow';

function requestMatches() {
  return { type: CONSTANTS.GET_MATCHES_REQUEST };
}

function successMatches(result) {
  return { type: CONSTANTS.GET_MATCHES_SUCCESS, payload: result };
}

function failureMatches(error) {
  return { type: CONSTANTS.GET_MATCHES_FAILURE, payload: error };
}

const getTrialsMatches = (parameters, hidePreloader = false) => async (dispatch) => {
  if (!hidePreloader) {
    dispatch(requestMatches());
  }
  try {
    const result = await authenticationService.getTrialsMatches(parameters);
    dispatch(successMatches(result));
  } catch (ex) {
    console.error('Error occurred, while getting trials matches:', ex);
    dispatch(failureMatches(`Error occurred, while getting trials matches: ${ex.message || ex}`));
  }
};

const fetchMatches = (_userData = null, matchFlow = MatchFlow.getMatchFlow()) => {
  let userData = _userData;

  return async (dispatch, getState) => {
    if (!userData) {
      try {
        userData = await dispatch(authenticationActions.getUser());
      } catch (ex) {
        console.error('Exception:', ex);
        return;
      }
    }

    if (!userData) {
      return;
    }
    const {
      info: { country },
      personal: { patient_id, user_wix_id, travel_limit }
    } = userData;
    const { geo_location, main } = getState();

    const data = {
      userWixId: user_wix_id,
      patientId: patient_id,
      lat: country?.location?.lat || geo_location?.location?.lat,
      lng: country?.location?.lng || geo_location?.location?.lng,
      distance: travel_limit,
      lang: main?.lang,
      matchFlow
    };

    await dispatch(getTrialsMatches(data));

    const { matches: { error: gettingTrialsMatchesError } } = getState();

    if (gettingTrialsMatchesError) {
      dispatch(authenticationActions.logout());
      return;
    }

    let matchesURL = preserveQueryParam(ROUTE_PATH[ROUTE_NAME.MATCHES]);
    if (window.location.pathname.includes(ROUTE_PATH[ROUTE_NAME.MATCHES])) {
      matchesURL = window.location.pathname;
    }

    dispatch(push(matchesURL));
  };
};

const toggleMobileView = view => dispatch => dispatch({ type: CONSTANTS.TOGGLE_MATCHES_VIEW, payload: view });

const applyFilter = (filter, data) => (dispatch, getState) => {
  dispatch({ type: filter, payload: data });

  const { result, filters } = getState()?.matches;
  let filteredTrials = [...result];

  const filterHandlers = {
    [FILTERS.TREATMENT_TYPES]: (trials, filterItems) =>
      trials.filter(trial => !shouldHideCardByGroupFilter(trial?.groups, filterItems)),
    [FILTERS.TRIAL_PHASES]: (trials, filterItems) => trials.filter(trial => filterItems.includes(trial?.phases)),
    [FILTERS.TRIAL_VIEW]: (trials, filterItem) =>
      trials.sort(getOptimizeSortItems().find(item => item.value === filterItem)?.sort)
  };

  for (const filterName in filters) {
    if (filters[filterName]?.length && filterHandlers[filterName]) {
      filteredTrials = filterHandlers[filterName](filteredTrials, filters[filterName]);
    }
  }

  dispatch({ type: CONSTANTS.UPDATE_TRIALS_RESULT, payload: filteredTrials });
};

const flushMatchesFilters = () => dispatch => {
  dispatch({ type: CONSTANTS.FLUSH_MATCHES_FILTERS });
};

const setInitialSortViewValue = () => (dispatch, getState) => {
  dispatch({
    type: CONSTANTS.INITIAL_SORT_SET,
    payload: getState()?.geo_location?.location ? SORT_ITEMS.OPTIMIZED_SCORE : SORT_ITEMS.SCORE
  });
};

export const matchesActions = {
  fetchMatches,
  toggleMobileView,
  applyFilter,
  flushMatchesFilters,
  setInitialSortViewValue,
  getTrialsMatches,
};
