import React, { useState } from 'react';
import './Biomarkers.css';

import app from 'new-ui/app';

import NGS from 'new-ui/Components/NGS';
// import { useTranslation } from 'utils/modifiedTranslation';

import PropTypes from 'prop-types';
import deleteIcon from 'new-ui/assets/icons/delete_icon.svg';
import Button from 'new-ui/Components/Button';
import { POPUP_TYPES, THEMES } from 'new-ui/constants';
import DevMode from 'new-ui/Components/DevMode';
import { UPLOADER_STATES } from 'new-ui/Components/FilesUploader';
import { getBiomarkerTitle } from 'new-ui/functions';
import { useTranslation } from 'utils/modifiedTranslation';
import { CLIENT_IM_NOT_SURE } from 'new-ui/Components/Intake';
import { BulletButton } from '../SingleOptionSelect';

const Biomarkers = (props) => {
  const {
    // q,
    user,
    onselect,
  } = props;
  const [biomarkers, setBiomarkers] = useState(user?.condition_profile?.biomarkers ?? []);
  const controller = {};
  const { t } = useTranslation();
  window.__controller = controller;
  const gotoSelect = (biomarkers = [])=>{
    setBiomarkers(biomarkers);
    if (controller?.uploader.setFiles) controller.uploader.setFiles([]);
    if (controller?.uploader.setState) controller.uploader.setState(UPLOADER_STATES.SELECT);
  };
  const isNoneSelected = ()=> biomarkers?.length === 1 && biomarkers[0] === 'none';
  return (
    <div className="biomarkers-question">
      <NGS
        buttonMode
        controller={controller}
        saveButtonText={t('general.next')}
        isSingle
        selectButton={(
          <Button
            action={()=>{
              app.setPopup(POPUP_TYPES.BIOMARKERS, {
                biomarkers,
                onSelect: (biomarkers)=>{
                  const _biomarkers = [...biomarkers];
                  onselect(_biomarkers);
                  gotoSelect(_biomarkers);
                },
              });
            }}
            title="Answer Manually"
            theme={THEMES.ORANGE}
          />
        )}
        selectHeader={(
          <div className="biomarkers-question-title">
            {t('intake.biomarkers_subtitle')}
            {
              biomarkers && biomarkers.length && !isNoneSelected() ? (
                <div className="biomarkers-question-selecteds">
                  <div className="biomarkers-question-selecteds-title">
                    {biomarkers.length}
                    {' '}
                    Selected
                  </div>
                  <div className="biomarkers-question-selecteds-body">
                    { biomarkers.map((biomarker, k)=>{
                      return (
                        <div key={k} className="biomarkers-question-selected">
                          <div className="biomarkers-question-selected-title">
                            { getBiomarkerTitle(biomarker) }
                            {' '}
                          </div>
                          <img
                            src={deleteIcon}
                            alt=""
                            onClick={()=>{
                              const v = [...biomarkers];
                              v.splice(biomarkers.indexOf(biomarker), 1);
                              setBiomarkers(v);
                              onselect(v, true);
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null
            }
          </div>
        )}
        onSuccess={(biomarkers)=>{
          onselect(biomarkers);
          gotoSelect(biomarkers);
        }}
        successFooterRenderer={(_biomarkers)=>{
          const mergedBiomarkers = [...new Set([...biomarkers, ..._biomarkers])];
          onselect(mergedBiomarkers, true);
          return (
            <div className="success-footer">
              <Button
                title={t('general.next')}
                action={() => {
                  gotoSelect(mergedBiomarkers);
                }}
              />
            </div>
          );
        }}
      />
      {
        biomarkers.length ? null : (
          <div className="multi-pages-submenu margin-top-10">
            <BulletButton
              label={t('questionnaire.biomarkers.skip')}
              isSelected={isNoneSelected()}
              action={
                ()=>{
                  onselect(['none']);
                }
              }
            />
            <BulletButton
              label={t('intake.im_not_sure')}
              action={
                ()=>{
                  onselect(CLIENT_IM_NOT_SURE);
                }
              }
            />
          </div>
        )
      }
      <DevMode>
        <div onClick={()=>{
          onselect(null);
          gotoSelect();
        }}
        >
          set null
        </div>
      </DevMode>
    </div>
  );
};

Biomarkers.propTypes = {
  q: PropTypes.any,
  user: PropTypes.any,
  onselect: PropTypes.func,
};

export default Biomarkers;
