import { ROUTE_NAME, ROUTE_PATH, REDIRECT_TO_ACTIONS } from './constants';

const initialState = {
  after: {
    [REDIRECT_TO_ACTIONS.EVENTS.SIGN_UP]: ROUTE_PATH[ROUTE_NAME.RESULTS],
    [REDIRECT_TO_ACTIONS.EVENTS.QUESTIONNAIRE]: ROUTE_PATH[ROUTE_NAME.RESULTS],
    [REDIRECT_TO_ACTIONS.EVENTS.LOGIN]: ROUTE_PATH[ROUTE_NAME.RESULTS],
    [REDIRECT_TO_ACTIONS.EVENTS.EDIT_QUESTIONNAIRE]: ROUTE_PATH[ROUTE_NAME.RESULTS],
  },
  before: {},
};

export function redirectTo(state = initialState, action) {
  switch (action.type) {
    case REDIRECT_TO_ACTIONS.SET_AFTER:
      return {
        ...state,
        after: {
          ...state.after,
          [action.payload.eventName]: action.payload.routePath,
        },
      };
    case REDIRECT_TO_ACTIONS.RESET_AFTER:
      return {
        ...state,
        after: {
          ...state.after,
          [action.payload]: initialState.after[action.payload],
        },
      };
    default:
      return state;
  }
}
