import React, { useEffect } from 'react';
import './AnalysSkeleton.css';
import PropTypes from 'prop-types';

const ResultsSkeleton = ({ finishUploading, onDone })=>{
  useEffect(() => {
    if (finishUploading) {
      onDone(true);
    }
    // eslint-disable-next-line
  }, [finishUploading]);
  return (
    <div className="results-skeleton">
      <div className="skeleton-header">
        <div className="skeleton-box skeleton-box1 skeleton-effect" />
        <div className="space-between skeleton-line">
          <div className="skeleton-box skeleton-box2 skeleton-effect-light" />
        </div>
      </div>
    </div>
  );
};

ResultsSkeleton.propTypes = {
  onDone: PropTypes.func,
  finishUploading: PropTypes.bool,
};

export default ResultsSkeleton;
