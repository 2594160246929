import React from "react";
import "./style.css";
import { Button } from '@material-ui/core';
import SearchIcon from 'new-ui/assets/icons/search-zoom-in.svg';

const NewButton = ({onClick, bgColor='#FF8C69'}) => {
  return (
    <Button onClick={onClick} className='new-button' style={{ background: bgColor }}> 
      <img src={SearchIcon} alt="icon" />
      <span className='label'>New</span>
    </Button>
  );
};

export default NewButton;