import React from 'react';

export const GoogleOneTapAuth = () => (
  <div
    id="g_id_onload"
    style={{
      position: 'absolute',
      top: 25,
      right: 25,
      height: 'unset',
      zIndex: 9999,
    }}
  />
);
