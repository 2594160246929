/* eslint-disable import/no-named-as-default */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import './Patients.css';
import app from 'new-ui/app';
import { useTranslation } from 'utils/modifiedTranslation';
import PatientsHeader from './Header';
import PatientsFooter from './footer';
import PatientsList from './Patientlist';
import Preloader from '../../Components/Preloader';
import { getPatients } from './api';

const Patients = ()=> {
  const { t } = useTranslation();
  const [patients, setPatients] = useState(null);
  const [indications, setIndications] = useState(null);
  const [indicationsMap, setIndicationsMap] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(()=>{
    app.intercom.hidden = true;
    setIsLoading(true);
    const fetch = async ()=>{
      const patients = await getPatients();
      let indications = await app.getIndications();
      indications = indications.map((i) => ({
        title: i.title.indexOf('disease_name') !== -1 ? t(i.title) : i.title,
        value: i.value,
      }));
      const indicationsMap = indications.reduce((acc, item) => {
        acc[item.value] = item;
        return acc;
      }, {});
      setIndications(indications);
      setIndicationsMap(indicationsMap);
      setPatients(patients);
      setIsLoading(false);
    };
    fetch();
    // eslint-disable-next-line
  }, []);

  const handleSearchChange = (value) => {
    setSearchValue(value);
  };

  const filteredPatients = patients ? patients.filter((patient) => patient.info.family_name.toLowerCase().includes(searchValue.toLowerCase())) : [];

  return (
    <div className="patients-page">
      {isLoading ? <Preloader /> : (
        <>
          <PatientsHeader patient_count={filteredPatients?.length} onSearchPatient={handleSearchChange} />
          {patients && <PatientsList patients={filteredPatients} indications={indicationsMap} />}
          <PatientsFooter indications={indications} />
        </>
      )}
    </div>
  );
};

export default Patients;
