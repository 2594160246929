export const fixedOptionsValues = [
  'none',
  'other_drugs_or_drug_combinations',
  'not_on_the_list',
  'not_relevant',
  'dont_have',
  'international_patient',
  'other',
  'not_listed_combination',
  'not_sure',
];
