import React, { useState } from 'react';
import './SingleOptionSelect.css';

import { useTranslation } from 'utils/modifiedTranslation';

import PropTypes from 'prop-types';
import DevMode from 'new-ui/Components/DevMode';
import { randomString } from 'new-ui/functions';
import { QuestionTitle } from '../..';

export const BulletButton = (props)=>{
  const {
    label,
    action,
    isSelected,
    className,
  } = props;

  return (
    <div
      key={randomString()}
      className={`select-option ${isSelected ? 'option-selected' : ''} ${className || ''}`}
      onClick={action}
    >
      <div className="option-selector">
        <div className="option-selector-circle" />
      </div>
      <div className="option-label">
        {label}
      </div>
    </div>
  );
};

BulletButton.propTypes = {
  label: PropTypes.string,
  action: PropTypes.func,
  isSelected: PropTypes.bool,
  className: PropTypes.string,
};

const SingleOptionSelect = (props) => {
  const {
    q,
    user,
    onselect,
    showTitle = false,
  } = props;

  const {
    initValue = user?.condition_profile[q.id],
  } = props;

  const [value, setValue] = useState(Array.isArray(initValue) ? initValue[0] : initValue);
  const { t } = useTranslation();
  const isOptionSelected = (v) => String(value) === String(v);

  const {
    subtitle,
    showSubtitle,
  } = q;

  return (
    <div className="single-option-select">
      { showTitle ? <QuestionTitle q={q} /> : null}
      { (showSubtitle && subtitle) ? <div className="single-option-select-subtitle">{t(subtitle)}</div> : null}
      {
        q.options.map((option) => (
          <BulletButton
            key={randomString()}
            label={t(option.label)}
            isSelected={isOptionSelected(option.value)}
            action={
              () => {
                if (isOptionSelected(option.value)) {
                  setValue(null);
                  onselect(null);
                } else {
                  setValue(option.value);
                  onselect(option.value);
                }
              }
            }
          />
        ))
      }
      <DevMode>
        <div onClick={() => {
          setValue(null);
          onselect(null);
        }}
        >
          set null
        </div>
      </DevMode>
    </div>
  );
};

SingleOptionSelect.propTypes = {
  q: PropTypes.any,
  user: PropTypes.any,
  onselect: PropTypes.func,
  initValue: PropTypes.any,
  showTitle: PropTypes.bool,
};

export default SingleOptionSelect;
