import React from 'react';
import './EditEmail.css';

import { PopupTitle, PopupContent, PopupButton } from 'new-ui/Components/Popup';

import { useTranslation } from 'utils/modifiedTranslation';

import app from 'new-ui/app';

const EditEmail = () => {
  const { t } = useTranslation();
  return (
    <div className="edit-email">
      <PopupTitle title='Edit Email' />
      <PopupContent>{t('text')}</PopupContent>
      <PopupButton title='Save' action={() => { app.setPopup(false) }}></PopupButton>
    </div>
  );
};

export default EditEmail;
