export const Profile = {
  dob: 'dob',
  gender: 'gender',
  disease_status: 'disease_status',
  race: 'race',
  type_of_bladder_cancer: 'type_of_bladder_cancer',
  recurrence: 'recurrence',
  patient_stage: 'patient_stage',
  tnm_t: 'tnm_t',
  tnm_n: 'tnm_n',
  tnm_m: 'tnm_m',
  metastasis_location: 'metastasis_location',
  metastasis_location_brain_leptomeningeal: 'metastasis_location_brain_leptomeningeal',
  is_brain_metastasis_active: 'is_brain_metastasis_active',
  disease_measurable: 'disease_measurable',
  have_biopsy: 'have_biopsy',
  disease_resectable: 'disease_resectable',
  undergo_surgery: 'undergo_surgery',
  initial_surgery_date: 'initial_surgery_date',
  neoadjuvant_chemo_1st_surgery: 'neoadjuvant_chemo_1st_surgery',
  undergo_surgery_for_recurrence: 'undergo_surgery_for_recurrence',
  recurrence_surgery_date: 'recurrence_surgery_date',
  neoadjuvant_chemo_additional_surgery: 'neoadjuvant_chemo_additional_surgery',
  undergo_radiation: 'undergo_radiation',
  radiation_treatment_end_date: 'radiation_treatment_end_date',
  treatment_for_bladder_cancer: 'treatment_for_bladder_cancer',
  treatment_type: 'treatment_type',
  chemotherapy_type: 'chemotherapy_type',
  checkpoint_inhibitor_type: 'checkpoint_inhibitor_type',
  chemotherapy_treatment_end_date: 'chemotherapy_treatment_end_date',
  checkpoint_inhibitor_treatment_end_date: 'checkpoint_inhibitor_treatment_end_date',
  treatment_end_date: 'treatment_end_date',
  balversa_treatment_end_date: 'balversa_treatment_end_date',
  padcev_treatment_end_date: 'padcev_treatment_end_date',
  chemotherapy_treatment_stop_reason: 'chemotherapy_treatment_stop_reason',
  checkpoint_inhibitor_treatment_stop_reason: 'checkpoint_inhibitor_treatment_stop_reason',
  erdafitinib_treatment_stop_reason: 'erdafitinib_treatment_stop_reason',
  intravesical_bcg_treatment_stop_reason: 'intravesical_bcg_treatment_stop_reason',
  padcev_treatment_stop_reason: 'padcev_treatment_stop_reason',
  number_of_lines_for_advanced_disease: 'number_of_lines_for_advanced_disease',
  number_of_chemotherapy_lines_for_advanced_disease: 'number_of_chemotherapy_lines_for_advanced_disease',
  received_bone_metastasis_treatment: 'received_bone_metastasis_treatment',
  bone_metastasis_related_complication: 'bone_metastasis_related_complication',
  received_investigational_therapy: 'received_investigational_therapy',
  investigational_therapy_end_date: 'investigational_therapy_end_date',
  current_treatment_systemic_steroids: 'current_treatment_systemic_steroids',
  systemic_steroids_treatment_end_date: 'systemic_steroids_treatment_end_date',
  ecog: 'ecog',
  pd_l1_carrier_status: 'pd_l1_carrier_status',
  ngs: 'ngs',
  other_medical_condition: 'other_medical_condition',
  omc_liver: 'omc_liver',
  omc_heart: 'omc_heart',
  omc_stroke_date: 'omc_stroke_date',
  omc_kidney: 'omc_kidney',
  creatinine_level: 'creatinine_level',
  omc_autoimmune: 'omc_autoimmune',
  omc_mental: 'omc_mental',
  omc_allergy: 'omc_allergy',
  omc_chronic_infection_inflammation: 'omc_chronic_infection_inflammation',
  omc_gastro_problem: 'omc_gastro_problem',
  omc_lung_disease: 'omc_lung_disease',
  omc_abnormal_blood_results: 'omc_abnormal_blood_results',
  other_medications: 'other_medications',
  is_pregnant: 'is_pregnant',
  is_breastfeeding: 'is_breastfeeding',
  triple_question_drop_down: 'tripleQuestionDropDown',
};
