import React from 'react';
import './ResultsSkeleton.css';

const ResultsSkeleton = ()=>{
  return (
    <div className="results-skeleton">
      <div className="skeleton-header">
        <div className="skeleton-box skeleton-box1 skeleton-effect" />
        <div className="space-between">
          <div className="skeleton-box skeleton-box2 skeleton-effect-light" />
          <div className="flex-end">
            <div className="skeleton-box skeleton-box3 skeleton-effect-light" />
            <div className="skeleton-box skeleton-box4 skeleton-effect-light" />
          </div>
        </div>
      </div>
      <div className="results-skeleton-result">
        <div className="skeleton-box skeleton-box1 skeleton-effect" />
        <div className="space-between">
          <div className="skeleton-box skeleton-box2 skeleton-effect-light" />
          <div className="flex-end">
            <div className="skeleton-box skeleton-box3 skeleton-effect-light" />
          </div>
        </div>
        <div className="skeleton-box skeleton-box6 skeleton-effect-light" />
        <div className="skeleton-box skeleton-box7 skeleton-effect-light" />
      </div>
      <div className="results-skeleton-result">
        <div className="skeleton-box skeleton-box1 skeleton-effect" />
        <div className="space-between">
          <div className="skeleton-box skeleton-box2 skeleton-effect-light" />
          <div className="flex-end">
            <div className="skeleton-box skeleton-box3 skeleton-effect-light" />
          </div>
        </div>
        <div className="skeleton-box skeleton-box6 skeleton-effect-light" />
        <div className="skeleton-box skeleton-box7 skeleton-effect-light" />
      </div>
    </div>
  );
};

export default ResultsSkeleton;
