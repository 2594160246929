import React from "react";
import './style.css';

const SvgCircle = ({
    startPoint = 0,
    endPoint = 0,
    size = 138, // Default size of 138px
    strokeWidth = 25,
    color = "blue",
    backgroundColor = "gray",
}) => {
    // Calculate the radius from the size
    const radius = (size - strokeWidth) / 2;

    // Calculate the total circumference of the circle
    const circumference = 2 * Math.PI * radius;

    // Check if there is any fill to display
    const isFilled = startPoint !== endPoint;

    // Calculate the starting and ending angles in degrees
    const startAngle = (startPoint / 100) * 360;
    // const endAngle = (endPoint / 100) * 360;

    // Calculate the stroke-dasharray and stroke-dashoffset for the filled part
    const filledPercentage = endPoint - startPoint;
    const filledLength = isFilled ? (filledPercentage / 100) * circumference : 0;
    const dashArray = isFilled ? `${filledLength} ${circumference - filledLength}` : `${circumference} ${circumference}`;
    const offset = isFilled ? -(startAngle / 360) * circumference : 0;

    return (
        <div className="svg-circle">
            <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
                {/* Background Circle */}
                <circle
                    cx={size / 2}
                    cy={size / 2}
                    r={radius}
                    stroke={backgroundColor}
                    strokeWidth={strokeWidth}
                    fill="none"
                />
                {/* Foreground Circle (Segment) with animation */}
                <circle
                    cx={size / 2}
                    cy={size / 2}
                    r={radius}
                    stroke={color}
                    strokeWidth={strokeWidth}
                    fill="none"
                    strokeDasharray={dashArray}
                    strokeDashoffset={offset}
                    strokeLinecap="round" // Make the stroke ends rounded
                    transform={`rotate(-90 ${size / 2} ${size / 2})`}
                    style={{
                        transition: 'stroke-dashoffset 1s ease, stroke-dasharray 1s ease',
                        opacity: isFilled ? 1 : 0, // Ensure the filled circle is invisible if no fill
                    }}
                />
            </svg>
            <span className="circle-text">{endPoint - startPoint}%</span>
        </div>
    );
};

export default SvgCircle;