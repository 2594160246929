import React from 'react';
import './DevMode.css';

const DevMode = (props) => {
  const { children } = props;
  return (
    localStorage.getItem('isDev')
      ? (
        <div className="dev-mode">
          <div className="dev-mode-title">Developer mode only</div>
          { children }
        </div>
      ) : null
  );
};

export default DevMode;
