import React from 'react';
import { useEffect } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
function WithoutNavRoute(props) {
  const { setShowNav } = props;

  useEffect(() => {
    setShowNav(false);
    return () => {
      setShowNav(true);
    };
  }, [setShowNav]);

  return <Route {...props} />;
}
WithoutNavRoute.propTypes = {
  setShowNav: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  component: PropTypes.elementType.isRequired
};
export default WithoutNavRoute;
