/* eslint-disable import/no-cycle */
import { getMissingCriticalQuestions, getTopGroups } from 'new-ui/functions';
import missingQuestionsTransformer, { MISSING_CRITICAL_TO_QUESTION } from 'new-ui/functions/missingQuestionsTransformer';

export const getIntercomMissingCriticalObject = (user, trials) => {
  let missing_critical_info = getMissingCriticalQuestions(user, trials) || [];
  missing_critical_info = missing_critical_info.map((prop) => {
    for (const x in missingQuestionsTransformer) {
      const d = missingQuestionsTransformer[x];
      if (d.includes(prop) && MISSING_CRITICAL_TO_QUESTION[x]) return x;
    }
    return prop;
  });
  missing_critical_info = missing_critical_info.map((prop) => {
    if (
      Object.keys(MISSING_CRITICAL_TO_QUESTION).includes(prop)
    ) return MISSING_CRITICAL_TO_QUESTION[prop];
    return prop;
  });
  missing_critical_info = [...new Set(missing_critical_info)];
  window.__lastMissingCriticalQuestions = missing_critical_info;
  const o = {};
  for (let x = 0; x < 10; x += 1) {
    const q = missing_critical_info[x];
    o[`missing_${x + 1}`] = q || '';
    o[`missing_${x + 1}t`] = q ? window.t(`missing_critical.question.${q}`) : '';
  }
  return o;
};

export const getTopGroupsForIntercom = (trials) => {
  const groups = getTopGroups(trials);
  const len = Object.keys(groups).length;
  let s = '';
  let i = 0;
  if (len === 1) return `${groups[Object.keys(groups)[0]]} ${Object.keys(groups)[0]}`;
  for (const x in groups) {
    if (i === 0) s += `${groups[x]} ${x}`;
    else if (i === len - 1) s += ` and ${groups[x]} ${x}`;
    else s += `, ${groups[x]} ${x}`;
    i++;
  }
  return s;
};

export const isEmpty = (value) => value === undefined || value === null || (typeof value === 'object' && Object.keys(value).length === 0);
