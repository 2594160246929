import userConstants from '../constants';

const initialState = {
  isSuperUser: false
};

export function superUser(state = initialState, action) {
  const { type } = action;

  switch (type) {
    case userConstants.SET_SUPER_USER:
      return {
        isSuperUser: true
      };
    default:
      return state;
  }
}
