import React, { useState } from 'react';
import './Checkbox.css';

import PropTypes from 'prop-types';
import OrangeCheckOff from 'new-ui/assets/icons/tick-off.svg';
import OrangeCheckOn from 'new-ui/assets/icons/tick-on.svg';
import { ReactComponent as CheckIcon } from './assets/check.svg';
import { FORM_THEMES } from '..';

const Checkbox = (props) => {
  const {
    checked, title, onChange, theme = FORM_THEMES.DEFAULT,
  } = props;
  const [isChecked, setIsChecked] = useState(checked);
  const getCheckIcon = () => {
    if (theme === FORM_THEMES.ORANGE) {
      return <img alt="checkbox" src={isChecked ? OrangeCheckOn : OrangeCheckOff} />;
    } return <CheckIcon />;
  };
  return (
    <div
      className={`checkbox ${isChecked && 'checked'} theme-${theme}`}
      onClick={() => {
        const value = !isChecked;
        setIsChecked(value);
        onChange(value);
      }}
    >
      <div className="checkbox-title">{title}</div>
      <div className="checkbox-check">{getCheckIcon()}</div>
    </div>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  title: PropTypes.string,
  theme: PropTypes.string,
  onChange: PropTypes.func,
};

export default Checkbox;
