import GLOBAL from '../utils/constants/global';

/**
 * Documentation https://developer.zendesk.com/embeddables/docs/widget/core#commands
 * Code snippets https://develop.zendesk.com/hc/en-us/community/posts/360001652887-Web-Widget-API-Hide-Launcher-Button
 */
export function openZendeskChat() {
  if (window.zE) return window.zE('webWidget', 'open');

  // if Zendesk Chat wasn't loaded use mailto
  window.location = `mailto:${GLOBAL.footer.email}?subject=Contact%20Us%20from%20Webapp`;

  return window.location;
}
