import React from 'react';
import './style.css';
import Button from 'new-ui/Components/Button';
import { useTranslation } from 'utils/modifiedTranslation';

const Actions = ({ onSave, onCancel }) => {
  const { t } = useTranslation();

  return (
    <div className='treatment-actions'>
      <Button
          backgroundColor="#FF8C69"
          color="#FFF"
          width="100%"
          className="btn btn-done"
          title={t('treatment_options.action.save')}
          action={onSave}
        />
        <Button
          backgroundColor="#FFF"
          color="#FF8C69"
          width="100%"
          className="btn btn-done"
          title={t('treatment_options.action.cancel')}
          action={onCancel}
        />
    </div>
  );
};

export default Actions;