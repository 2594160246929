import React from 'react';
import PropTypes from 'prop-types';
import './animate.css';
import Vimeo from '@u-wave/react-vimeo';
import app from 'new-ui/app';
import NextIcon from 'new-ui/assets/icons/next.svg';
import { useTranslation } from 'utils/modifiedTranslation';
import { THEMES } from 'new-ui/constants';
import Button from '../Button';
import InfinityLoader from '../InfinityLoader';

const Animate = ({
  onDone, showForm, GA, onSkip,
}) => {
  const { t } = useTranslation();
  const handleVideoEnd = () => {
    app.sendGoogleAnalyticsEvent(GA.category, GA.events.endVideo, { name: 'Upload file video finished' });
    onDone(true);
  };

  app.sendGoogleAnalyticsEvent('ai_profile', 'show-video');

  // long video example 454483255
  // correct video 943598186

  return (
    <div className="animate-wrapper">
      <div className="animate-header">
        <div className="animate-title">{window.t('upload_page.analyzing_your_files')}</div>
        <div className="animate-loader"><InfinityLoader /></div>
      </div>
      <div className="animate-video">
        {!showForm ? (
          <>
            <Vimeo
              video="943598186"
              autoplay
              onEnd={handleVideoEnd}
              onPlay={()=>app.sendGoogleAnalyticsEvent(GA.category, GA.events.startVideo, { name: 'Upload file video started' })}
              style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
            />
            { (onSkip) ? (
              <div className="animate-skip">
                <Button
                  theme={THEMES.LIGHT}
                  forwardIcon={NextIcon}
                  title={t('general.skip')}
                  action={()=>{
                    app.sendGoogleAnalyticsEvent('ai_profile', 'animate-skip');
                    onSkip();
                  }}
                />
              </div>
            ) : null}
          </>
        ) : null}
      </div>

    </div>
  );
};

Animate.propTypes = {
  onDone: PropTypes.func,
  showForm: PropTypes.bool,
  GA: PropTypes.object,
};

export default Animate;
