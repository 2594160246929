import React, { useEffect, useState } from 'react';
import './style.css';
import Radio from 'new-ui/Components/Form/Radio';
import Button from 'new-ui/Components/Button';
import { useTranslation } from 'utils/modifiedTranslation';

const StepFour = ({ statuses, reasons, onChangeStatus, onChangeReason, onClickDone, onClickPrev, selectedOption }) => {
    const { t } = useTranslation();

    const [selectedStatus, setSelectedStatus] = useState(selectedOption);
    const [selectedReason, setSelectedReason] = useState(null);

    const handleStatusSelect = (value) => {
        setSelectedStatus(value);
        onChangeStatus(value);
    };

    const handleReasonSelect = (value) => {
        setSelectedReason(value)
        onChangeReason(value);
    };

    useEffect(() => {
        if(selectedOption === 'taking') {
            handleStatusSelect('taking');
        }
        // eslint-disable-next-line
    },[])

    const getButtonTitle = (selectedOption) => {
        if (selectedOption === 'first') {
          return 'Add next treatment';
        } else if (selectedOption === 'last') {
          return t('treatment_widget.actions.add_previous.button');
        } else {
          return t('treatment_widget.actions.add_previous.button');
        }
    };      

    return (
        <div className='step step-4'>
            {/* <h2 className='drug-title'>Cisplatin</h2> */}
            <h2 className='title'>{t('treatment_widget.steps.status.title')}</h2>
            <div className='treatment-list'>
                <Radio
                    key={'status'}
                    options={statuses}
                    value={selectedStatus}
                    onChange={handleStatusSelect}
                />
            </div>
            {selectedStatus === 'finished' && (
                <div className='treatment-reasons'>
                    <h2 className='title'>{t('treatment_widget.steps.stop_reason.title')}</h2>
                    <ul className='option-list'>
                        {reasons.map((option) => (
                            <li className={`option-item ${selectedReason === option.value ? 'selected' : ''}`} key={option.value}>
                                <label className='label'>
                                    <input
                                        type='radio'
                                        name='reason'
                                        value={option.value}
                                        checked={selectedReason === option.value}
                                        onChange={() => handleReasonSelect(option.value)}
                                    />
                                    {option.label}
                                </label>
                            </li>
                        ))}
                    </ul>
                </div>
            )}

            {selectedStatus && (
                <div className='actions'>
                    <Button
                        backgroundColor={'#FFF'} 
                        color={'#FF8C69'}
                        width='250px'
                        className='btn' 
                        title={t('treatment_widget.actions.done.button')}
                        action={onClickDone}
                    />
                    <Button
                        backgroundColor={'#FF8C69'} 
                        color={'#FFF'}
                        width='250px'
                        className='btn' 
                        title={getButtonTitle(selectedOption)}
                        action={onClickPrev}
                    />
                </div>
            )}

        </div>
    );
};

export default StepFour;