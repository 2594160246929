import React, { useState } from 'react';

import './Input.css';
import PropTypes from 'prop-types';

import VisibilityIcon from 'new-ui/assets/icons/visibility.svg';
import VisibilityOffIcon from 'new-ui/assets/icons/visibility_off.svg';

import { FORM_THEMES } from '..';

const Input = (props) => {
  const {
    value,
    onChange,
    required = false,
    email,
    isPassword = false,
    placeholder,
    keyup,
    onenter,
    input,
    className,
    theme = FORM_THEMES.DEFAULT,
    tabindex,
  } = props;
  let { icon } = props;
  const [val, setValue] = useState(value);

  if (isPassword) icon = 'visibility';

  const iconClick = (e) => {
    if (isPassword) {
      const $icon = e.target;
      const $input = $icon.previousElementSibling;

      if ($input.type === 'text') {
        $input.type = 'password';
        $icon.src = VisibilityIcon;
      } else {
        $input.type = 'text';
        $icon.src = VisibilityOffIcon;
      }
    }
  };

  const getType = () => {
    if (input.type === 'date') return 'date';
    return isPassword ? 'password' : (email ? 'email' : 'text');
  };

  return (
    <div className={`form-input-holder ${className || ''} ${theme ? `theme-${theme}` : ''}`}>
      <input
        type={getType()}
        tabIndex={tabindex}
        value={val}
        required={required}
        placeholder={placeholder || null}
        onKeyUp={(ev) => {
          ev.preventDefault();
          ev.stopPropagation();
          if (keyup) keyup(ev);
          if (onenter && ev.key === 'Enter') onenter();
        }}
        onChange={(ev) => {
          onChange(ev.currentTarget.value);
          setValue(ev.currentTarget.value);
        }}
      />
      {
        icon ? <img className="icon" onClick={iconClick} src={require(`new-ui/assets/icons/${icon}.svg`)} alt="" /> : null
      }
    </div>
  );
};

Input.propTypes = {
  value: PropTypes.any,
  tabindex: PropTypes.any,
  className: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  email: PropTypes.bool,
  isPassword: PropTypes.bool,
  icon: PropTypes.string,
  placeholder: PropTypes.string,
  theme: PropTypes.string,
  keyup: PropTypes.func,
  onenter: PropTypes.func,
  input: PropTypes.object,
};

export default Input;
