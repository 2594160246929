export const Profile = {
  // global questions from "user_personal_record" table
  is_pregnant: 'is_pregnant',
  is_breastfeeding: 'is_breastfeeding',
  gender: 'gender',
  ecog: 'ecog',
  race: 'race',
  triple_question_drop_down: 'triple_question_drop_down',
  omc_myocardial_infarction_date: 'omc_myocardial_infarction_date',
  // all the fields from api table
  user_type: 'user_type',
  disease_status: 'disease_status',
  type_of_liver: 'type_of_liver',
  current_stage: 'current_stage',
  current_score: 'current_score',
  number_lesions: 'number_lesions',
  tnm: 'tnm',
  disease_location: 'disease_location',
  metastasis_location_brain_leptomeningeal: 'metastasis_location_brain_leptomeningeal',
  brain_metastasis_active: 'brain_metastasis_active',
  disease_measurable: 'disease_measurable',
  tumor_size_mm: 'tumor_size_mm',
  have_biopsy: 'have_biopsy',
  pd_l1_carrier_status: 'pd_l1_carrier_status',
  pd_l1_expression: 'pd_l1_expression',
  brca_mutation: 'brca_mutation',
  ngs: 'ngs',
  biomarkers: 'biomarkers',
  disease_resectable: 'disease_resectable',
  had_procedure: 'had_procedure',
  had_transplantation: 'had_transplantation',
  transplantation_date: 'transplantation_date',
  transplant_outcome: 'transplant_outcome',
  procedures_received: 'procedures_received',
  procedure_date: 'procedure_date',
  procedure_outcome: 'procedure_outcome',
  treatment_for_liver: 'treatment_for_liver',
  treatment_for_advanced_metastatic_disease: 'treatment_for_advanced_metastatic_disease',
  drugs_received: 'drugs_received',
  cycles: 'cycles',
  treatment_admin: 'treatment_admin',
  treatment_end_date: 'treatment_end_date',
  treatment_stop_reason: 'treatment_stop_reason',
  hcc_related_health_problems: 'hcc_related_health_problems',
  omc_encephalopathy_date: 'omc_encephalopathy_date',
  omc_acites_date: 'omc_acites_date',
  omc_varices_date: 'omc_varices_date',
  liver_omc: 'liver_omc',
  current_treatment_systemic_steroids: 'current_treatment_systemic_steroids',
  systemic_steroids_treatment_end_date: 'systemic_steroids_treatment_end_date',
  other_medical_condition: 'other_medical_condition',
  omc_heart: 'omc_heart',
  omc_stroke_date: 'omc_stroke_date',
  omc_kidney: 'omc_kidney',
  omc_autoimmune: 'omc_autoimmune',
  omc_mental: 'omc_mental',
  omc_allergy: 'omc_allergy',
  omc_chronic_infection_inflammation: 'omc_chronic_infection_inflammation',
  omc_gastro_problem: 'omc_gastro_problem',
  omc_lung_disease: 'omc_lung_disease',
  omc_abnormal_blood_results: 'omc_abnormal_blood_results',
  other_medications: 'other_medications',
  // additional questions which are not listed in api table
  have_insurance: 'have_insurance',
  insurances: 'insurances',
  qstack: 'qstack',
  treatments: 'treatments',
  procedures: 'procedures',
};
