import React from "react";
import "./style.css";
import GearIcon from 'new-ui/assets/icons/gear.svg';

const ManageButton = React.forwardRef(({onClick}, ref) => {
  return (
    <div ref={ref} className="manage-btn" onClick={onClick}>
      <img alt="icon" src={GearIcon}/>
      <span>Manage Colleague</span> 
    </div>
  );
});

export default ManageButton;