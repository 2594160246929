import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as ReactRedux from 'react-redux';
import { makeStyles, IconButton, Dialog, DialogActions, DialogContent, CircularProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Clear';
import { toggleMatchesMenu, MATCHES_MENU } from 'modules/matchesMenu';
import configs from '../../../configs';

const { calendly } = configs;

const useStyles = makeStyles(theme => ({
  content: {
    position: 'relative',
    padding: 5
  },
  widget: {
    height: 640,
    minWidth: 540,
    [theme.breakpoints.down('sm')]: {
      minWidth: 340
    }
  },
  spinner: {
    position: 'absolute',
    top: '40%',
    left: '45%'
  }
}));

export const CALENDLY_EVENTS = {
  EXPLORE_CLINICAL_TRIALS: 'exploring-clinical-trials-with-us'
  // LEARN_MORE_TRIALS: 'learn-more-about-your-matched-trials'
};

const CalendlyDialog = ({ isMobile, eventName }) => {
  const classes = useStyles();
  const dispatch = ReactRedux.useDispatch();
  const {
    openCalendlyDialog,
    authentication: { loggedIn, user }
  } = ReactRedux.useSelector(({ matchesMenu, authentication }) => ({
    openCalendlyDialog: matchesMenu.menuTriggers.openCalendlyDialog,
    authentication
  }));

  let dataUrl = `https://calendly.com/${calendly.account}/${eventName}`;
  // Pass actual user name, email, phone number in case was provided during authorisation
  if (loggedIn && user) {
    dataUrl += `?email=${user.email}`;
    if (user.family_name) {
      dataUrl += `&name=${user.family_name}`;
    }
    if (user.phone) {
      dataUrl += `&location=${user.phone}`;
    }
  }

  const headTag = useRef(null);
  const scriptTag = useRef(null);

  // componentDidMount()
  useEffect(() => {
    headTag.current = document.querySelector('head');
    scriptTag.current = document.createElement('script');

    scriptTag.current.setAttribute('src', calendly.calendlyScriptSrc);
    headTag.current.appendChild(scriptTag.current);

    // componentWillUnMount()
    return () => {
      if (!!headTag.current && !!scriptTag.current) {
        headTag.current.removeChild(scriptTag.current);
      }
    };
  });

  const handleClose = () => {
    dispatch(toggleMatchesMenu(MATCHES_MENU.OPEN_CALENDLY_DIALOG, false));
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="schedule-time-with-trialjectory"
      open={openCalendlyDialog}
      fullScreen={isMobile}
      style={{ zIndex: 13000 }}
    >
      <DialogActions>
        <IconButton onClick={handleClose}>
          <CloseIcon color="primary" />
        </IconButton>
      </DialogActions>
      <DialogContent className={classes.content}>
        <div className={classes.spinner}>
          <CircularProgress size={30} />
        </div>
        <div className={`calendly-inline-widget ${classes.widget}`} data-url={dataUrl} />
      </DialogContent>
    </Dialog>
  );
};

CalendlyDialog.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  eventName: PropTypes.string.isRequired
};

export default CalendlyDialog;
