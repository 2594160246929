import React, { useState, useEffect, useRef } from 'react';
import './style.css';
import Arrow from 'new-ui/assets/icons/down_arrow.svg';

const CustomSelect = ({ options, selectedValue, onSelect, label, className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(null);
  const selectRef = useRef(null);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleClickOutside = (e) => {
    if (selectRef.current && !selectRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  const handleOptionClick = (option) => {
    onSelect(option.value);
    setIsOpen(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      setHighlightedIndex((prev) => (prev === null || prev === options.length - 1 ? 0 : prev + 1));
    } else if (e.key === 'ArrowUp') {
      setHighlightedIndex((prev) => (prev === null || prev === 0 ? options.length - 1 : prev - 1));
    } else if (e.key === 'Enter' && highlightedIndex !== null) {
      onSelect(options[highlightedIndex].value);
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const selectedOption = options.find(option => option.value === selectedValue);
  const selectedOptionColor = selectedOption ? selectedOption.color : '#ebebeb';

  return (
    <div className={`custom-select ${className || ''}`} onKeyDown={handleKeyDown} tabIndex="0" ref={selectRef}>
      <label>{label}</label>
      <div className="custom-select-container" onClick={toggleDropdown} style={{ backgroundColor: selectedOptionColor }} >
        <div className="selected-value">
          {selectedValue ? selectedOption?.label : label}
        </div>
        <img src={Arrow} className={`arrow ${isOpen ? 'open' : 'closed'}`} />
      </div>
      {isOpen && (
        <ul className="options">
          {options.map((option, index) => (
            <li
              key={option.value}
              className={`${
                highlightedIndex === index ? 'highlighted' : ''
              } ${selectedValue === option.value ? 'selected-option' : ''}`}
              onClick={() => handleOptionClick(option)}
              style={{
                backgroundColor: option.color
              }}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomSelect;
