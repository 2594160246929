/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import './TrialDetails.css';

import { useTranslation } from 'utils/modifiedTranslation';

const TrialDetails = (props) => {
  const { trial, content } = props;
  trial.url = `https://clinicaltrials.gov/study/${trial.nct}`;
  const { t } = useTranslation();
  const htmlContent = content || trial.descriptionContent;
  return (
    <div className="trial-details">
      <h2>{t('trial.details.title')}</h2>
      <div className="box">
        <div className="nct">
          <a href={trial.url} target="_blank" rel="noopener noreferrer"> 
            {trial.nct}
          </a>
        </div>
        <div className="content">
          {htmlContent
            ? <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
            : trial.brief_summary}
        </div>
      </div>
    </div>
  );
};

TrialDetails.propTypes = {
  trial: PropTypes.object,
  content: PropTypes.string,
};

export default TrialDetails;
