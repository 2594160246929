export const MISSING_CRITICAL_TO_QUESTION = {
  biomarkers: 'biomarkers',
  treatmentHistory: 'treatments',
  ecog: 'ecog',
  riskGroup: 'risk_group',
  preferredLocation: 'preferred_location',
  cancerSubTypeForBc: 'type_of_bc',
};

const missingQuestionsTransformer = {
  cancerSubType: [
    'type_of_bladder_cancer',
    'melanoma_subtype',
    'type_of_crc',
    'type_of_mds',
    'type_of_uterine_cancer',
    'type_of_carcinoma',
    'type_of_sarcoma',
    'type_of_mpn',
    'brain_cancer_type',
    'type_of_mm',
    'type_of_lung_cancer',
    'type_of_gi',
    'type_of_ovarian_cancer',
    'type_of_liver',
    'type_of_lymphoma',
    'type_of_bcell_lymphoma',
    'type_of_crc',
    'type_of_familial_cancer',
    'type_of_pc',
    'type_of_aml',
    'type_of_hnscc',
  ],
  cancerSubTypeForBc: [
    'type_of_bc',
    'molecular_subtype',
  ],
  treatmentHistory: [
    'treatment_for_bladder_cancer',
    'treatment_for_cll',
    'treatment_for_melanoma',
    'received_any_treatments',
    'treatment_for_mds',
    'adjuvant_treatment_type',
    'treatment_for_uterine_cancer',
    'treatment_for_mpn',
    'treatment_for_brain_cancer',
    'treatment_for_mm',
    'treatment_for_lung_cancer',
    'treatment_for_gi_tract',
    'treatment_for_ovarian_cancer',
    'treatment_for_advanced_metastatic_disease',
    'treatments',
    'treatment_for_liver',
    'treatment_for_breast_cancer',
    'treatment_for_lymphoma',
    'treatment_for_crc_cancer',
    'treatment_for_prostate_cancer',
    'treatment_for_aml',
    'treatment_for_hnscc',
  ],
  currentStage: [
    'patient_stage',
    'patient_phase',
    'current_stage',
    'diagnosis_stage',
    'type_of_lung_cancer',
    'type_of_hnscc',
  ],
  cllCharasterictics: [
    'cll_characteristics',
  ],
  riskGroup: [
    'risk_group',
  ],
  mProteinDetection: [
    'has_mprotein',
  ],
  transplantationStatus: [
    'received_transplant',
  ],
  percentageOfBmBlasts: [
    'percentage_of_bm_blasts',
  ],
};

export const questionToURL = (question) => {
  if (MISSING_CRITICAL_TO_QUESTION[question]) return MISSING_CRITICAL_TO_QUESTION[question];
  return question;
};

// window.__questionToURL = questionToURL;

export default missingQuestionsTransformer;
