const MENU = {
  OPEN_SHARE_WITH_PHYSICIAN: 'openShareWithPhysician',
  OPEN_SHARE_WITH_PHYSICIAN_MINIMAL: 'openShareWithPhysicianMinimal',
  OPEN_MAP_FILTER: 'openMapFilter',
  OPEN_CHANGE_LOCATION: 'openChangeLocation',
  OPEN_PROFILE: 'openProfile',
  OPEN_QUESTIONNAIRE_IN_EDIT_MODE: 'openQuestionnaireInEditMode',
  DOWNLOAD_PROFILE: 'downloadProfile',
  DOWNLOAD_TRIAL_LIST: 'downloadTrialList',
  START_NEW_MEDICAL_PROFILE: 'startNewMedicalProfile',
  OPEN_PROFILE_DATA_VIEWER: 'openProfileDataViewer',
  OPEN_CALENDLY_DIALOG: 'openCalendlyDialog',
  OPEN_YOUTUBE_DIALOG: 'openYoutubeDialog',
};

export const menuConstants = {
  MENU,
  TOGGLE_MATCHES_MENU_TRIGGER: 'TOGGLE_MATCHES_MENU_TRIGGER',
  CLEAR_MATCHES_MENU: 'CLEAR_MATCHES_MENU',
};
