export const PROGRESS_STATE = {
  IDLE: 'idle',
  FILES_UPLOAD: 'filesUpload',
  FILES_PROCESSING: 'filesProcessing',
  FINISHED: 'finished',
  ERROR: 'error',
};

export const JOB_STATUS = {
  ERROR: 'error',
  COMPLETED: 'completed',
  IN_PROGRESS: 'in_progress',
  UPDATED: 'updated',
  COMPLETED_WITH_ALERT: 'completed_with_alert',
};

export const EXTRACTION_STRATEGY = {
  FULL_PROFILE: 'full_profile',
  BIOMARKERS: 'biomarkers',
};
