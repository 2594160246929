import isObject from 'lodash/isObject';

export const findQuestion = (questions, questionId) => {
  if (!Array.isArray(questions)) {
    return null;
  }

  for (const question of questions) {
    if (question?.id === questionId) {
      return question;
    }

    for (const key of ['subQuestions']) {
      const subQuestions = isObject(question) ? question[key] : null;

      if (Array.isArray(subQuestions)) {
        const subQuestion = findQuestion(subQuestions, questionId);

        if (isObject(subQuestion)) {
          return subQuestion;
        }
      }
    }
  }

  return null;
};
