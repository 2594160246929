export const Profile = {
  // global questions from "user_personal_record" table
  user_type: 'user_type',
  dob: 'dob',
  gender: 'gender',

  // all the fields from api table
  race: 'race',
  procedures: 'procedures',
  disease_status: 'disease_status',
  type_of_disease: 'type_of_disease',
  disease_stage: 'disease_stage',
  risk_group_ipi: 'risk_group_ipi',
  lymphocyte_count: 'lymphocyte_count',
  platelet_count: 'platelet_count',
  neutrophil_count: 'neutrophil_count',
  cll_characteristics: 'cll_characteristics',
  cll_lesion_measurement: 'cll_lesion_measurement',
  cns_richter: 'cns_richter',
  have_biopsy: 'have_biopsy',
  ngs: 'ngs',
  received_transplant: 'received_transplant',
  transplantation_treatment_date: 'transplantation_treatment_date',
  transplant_outcome: 'transplant_outcome',
  treatment_for_cll: 'treatment_for_cll',
  treatments: 'treatments',
  drugs_received: 'drugs_received',
  cycles: 'cycles',
  treatment_end_date: 'treatment_end_date',
  treatment_stop_reason: 'treatment_stop_reason',
  supportive_care_type: 'supportive_care_type',
  current_treatment_systemic_steroids: 'current_treatment_systemic_steroids',
  systemic_steroids_treatment_end_date: 'systemic_steroids_treatment_end_date',
  ecog: 'ecog',
  karnofsky_score: 'karnofsky_score',
  gvhd: 'gvhd',
  other_medical_condition: 'other_medical_condition',
  omc_liver: 'omc_liver',
  omc_heart: 'omc_heart',
  omc_stroke_date: 'omc_stroke_date',
  omc_kidney: 'omc_kidney',
  omc_autoimmune: 'omc_autoimmune',
  omc_mental: 'omc_mental',
  omc_allergy: 'omc_allergy',
  omc_chronic_infection_inflammation: 'omc_chronic_infection_inflammation',
  omc_gastro_problem: 'omc_gastro_problem',
  omc_lung_disease: 'omc_lung_disease',
  omc_abnormal_blood_results: 'omc_abnormal_blood_results',
  other_medications: 'other_medications',
  is_pregnant: 'is_pregnant',
  is_breastfeeding: 'is_breastfeeding',
  have_insurance: 'have_insurance',
  insurances: 'insurances',
};
