export function isMoreThan30DaysApart(date1, date2) {
  if (!date1 || !date2) {
    return false;
  }
  // Convert timestamps to milliseconds (assuming they are in milliseconds)
  const date1Ms = Number(date1);
  const date2Ms = Number(date2);

  // Calculate the difference in milliseconds
  const differenceMs = Math.abs(date2Ms - date1Ms);

  // Convert milliseconds to days
  const differenceDays = differenceMs / (1000 * 60 * 60 * 24);

  // Check if the difference is more than 30 days
  return differenceDays > 30;
}
