import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InfoNote from '../InfoNote/InfoNote';

const ResultNote = ({ tabName }) => {
  const { t } = useTranslation();
  const storageKey = `${tabName}_info_closed`;
  const storageValue = localStorage.getItem(storageKey) === 'closed';
  const [isClosed, setIsClosed] = useState(storageValue);

  const onClose = useCallback(() => {
    setIsClosed(true);
    localStorage.setItem(storageKey, 'closed');
  }, [storageKey]);

  useEffect(() => {
    let timeout = null;
    if (!isClosed) {
      timeout = setTimeout(() => {
        onClose();
      }, 30000);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    }
  }, [isClosed, onClose]);

  useEffect(() => {
    setIsClosed(storageValue);
  }, [storageValue]);

  if (isClosed) return null;

  return <InfoNote info={t(`match.list.${tabName}`)} onClose={onClose} />;
};

ResultNote.propTypes = {
  tabName: PropTypes.string.isRequired,
};

export default ResultNote;