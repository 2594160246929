export function getQuestionnaireRoute({ questionId, popupType = null }) {
  const params = new URLSearchParams();

  if (popupType) {
    params.set('popup_type', popupType);
    return `/questionnaire/${questionId}?${params}`;
  }

  return `/questionnaire/${questionId}`;
}
