import React, { useState, useEffect } from 'react';
import './ConsultWithUs.css';

import app from 'new-ui/app';

import { InlineWidget, useCalendlyEventListener } from 'react-calendly';
import { CALENDLY_EVENTS } from 'components/Dialogs/components/CalendlyDialog';
import { authenticationService } from 'components/Authentication/api';
import PropTypes from 'prop-types';
import { INTERCOM_EVENTS, CRM_EVENTS } from 'new-ui/constants';
import configs from '../../../configs';

const { calendly } = configs;

let sendIntercomEvent = false;

const ConsultWithUs = (props) => {
  const { source } = props;
  sendIntercomEvent = false;
  const {
    onEventScheduled = () => {
      console.log('calendly event scheduled');
      sendIntercomEvent = true;
      app.setConfiguration({
        lastConsultWithUs: new Date().toISOString(),
      });
      app.renderPage(); // required for flows such as user_flow
    },
  } = props;
  const user = app.user.info;
  const { email, family_name } = user;

  app.onpopupclose = () => {
    if (sendIntercomEvent && source) {
      app.intercom.sendEvent(INTERCOM_EVENTS.CONSULT_WITH_US.POPUP_SUCCESS, { source });
    }
  };

  const [calendlyUrl, setCalendlyUrl] = useState(false);
  // eslint-disable-next-line
  const [timeLeft, setTimeLeft] = useState(15 * 60);

  useCalendlyEventListener({
    onEventScheduled,
  });

  useEffect(() => {
    let url = `${calendly.calendlySiteUrl}/${calendly.account}/${CALENDLY_EVENTS.EXPLORE_CLINICAL_TRIALS}?hide_gdpr_banner=1`;
    // Pass actual user name, email, phone number in case was provided during authorisation

    if (user) {
      url += `&email=${user.email}`;
      if (user.family_name) url += `&name=${user.family_name}`;
      if (user.phone) url += `&location=${user.phone}`;
    }
    setCalendlyUrl(url);
  }, [user]);

  useEffect(() => {
    const timeoutAction = () => {
      authenticationService
      .registerUserRequests({
        user: {
          email,
          name: family_name ?? email,
        },
        type: CRM_EVENTS.TRIED_TO_SCHEDULE_A_CALL,
        content: 'The patient tried to schedule a calls',
      }).catch(ex => console.error(ex));

    authenticationService.updateIntercomUser({
      data:{
        tags: ['tried_to_schedule_a_call'],
      },
    }).catch(ex => console.error(ex));
    };

    const interval = setInterval(() => {
      setTimeLeft((timeLeft) => {
        if (timeLeft === 1) {
          clearInterval(interval);
          timeoutAction();
        }
        return timeLeft - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [email, family_name]);

  return (
    <div className="consult-with-us">
      <div>
        { calendlyUrl ? <InlineWidget url={calendlyUrl} /> : null }
      </div>
    </div>
  );
};

ConsultWithUs.propTypes = {
  onEventScheduled: PropTypes.func,
  source: PropTypes.string,
};

export default ConsultWithUs;
