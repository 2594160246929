import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { getFallbackLng } from '../../components/AppTranslationWrapper/functions';
import configs from '../../configs';

let instance = null;
let initializationPromise = null;

export const initI18n = () => {
  if (initializationPromise) {
    return initializationPromise;
  }
  initializationPromise = i18next
    .use(Backend)
    .use(initReactI18next)
    .init({
      backend: {
        loadPath: `${configs.beApiUrl}/translations?lang={{lng}}`,
      },
      debug: false,
      fallbackLng: getFallbackLng(),
      interpolation: {
        escapeValue: false,
      },
      react: {
        useSuspense: false,
      },
    });

  initializationPromise
    .then((result) => {
      instance = result;
    })
    .catch((error) => {
      console.error('i18next initialization error', error);
    });

  return initializationPromise;
};

export const getTranslationFunction = async () => {
  if (!initializationPromise) {
    throw new Error('i18next in not initialized');
  }

  await initializationPromise;
  if (!instance) {
    throw new Error('i18next was not initialized due to error');
  }
  return instance;
};
