import {
  MONTH_PICKER,
  SINGLE_OPTION_SELECT,
  MULTIPLE_OPTION_SELECT,
  NUMBER_INPUT,
  TRIPLE_QUESTION_DROP_DOWN,
  BIOMARKERS,
} from 'components/Questionnaire/constants/questionTypes';
import { Profile as ID } from 'components/Questionnaire/cancers/bladder/BladderProfile.enum';
import ConditionTypes from 'components/Questionnaire/constants/conditions';
import i from 'components/Questionnaire/constants/icons';
import {
  preferredLocation,
  travelLimit,
  userType,
  diseaseStatus,
  race,
  initialDiagnosisDate,
} from 'components/Questionnaire/constants/globalQuestions';
import SECTIONS from 'components/Questionnaire/constants/questionSections';
import dateType from 'utils/constants/dateTypes';
import numberType from 'utils/constants/numberTypes';
import questionOptions from 'components/Questionnaire/constants/questionOptions';
import { customPaths } from 'utils/constants/customPaths';
import GLOBAL_ENUMS from '../../constants/globalEnums';

const { biomarkers: BIOMARKERS_QUESTION_ID } = GLOBAL_ENUMS;

const noneOption = questionOptions.NONE;
const {
  EQUAL_TO, CONTAIN_ONE_OF, AGE_LESS_THAN, IS_SKIPPED, DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
} = ConditionTypes;

const ImNotSure = {
  label: 'questionnaire.questions.bladder.im_not_sure.label',
  value: 'NS',
};

const Other = {
  label: 'questionnaire.questions.bladder.other.label',
  value: 'other',
};

const stopReasonOptions = [
  {
    label: 'questionnaire.questions.bladder.stop_reason_options.treatment_ongoing.label',
    value: 'treatment_ongoing',
  },
  {
    label: 'questionnaire.questions.bladder.stop_reason_options.remission.label',
    value: 'remission',
  },
  {
    label: 'questionnaire.questions.bladder.stop_reason_options.progression.label',
    value: 'progression',
  },
  {
    label: 'questionnaire.questions.bladder.stop_reason_options.toxicity.label',
    value: 'toxicity',
  },
  {
    label: 'questionnaire.questions.bladder.stop_reason_options.end_of_protocol.label',
    value: 'end_of_protocol',
  },
  Other,
];

const Questions = [
  diseaseStatus,
  {
    q: 'Q6',
    id: ID.type_of_bladder_cancer,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.bladder.3_type_of_bladder_cancer.title',

    info: {
      title: 'questionnaire.questions.bladder.3_type_of_bladder_cancer.info.title',
      content: 'questionnaire.questions.bladder.3_type_of_bladder_cancer.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.bladder.3_type_of_bladder_cancer.options.urothelial_carcinoma',
        value: 'urothelial_carcinoma',
      },
      {
        label: 'questionnaire.questions.bladder.3_type_of_bladder_cancer.options.squamous_cell_carcinoma',
        value: 'squamous_cell_carcinoma',
      },
      {
        label: 'questionnaire.questions.bladder.3_type_of_bladder_cancer.options.adenocarcinoma',
        value: 'adenocarcinoma',
      },
      {
        label: 'questionnaire.questions.bladder.3_type_of_bladder_cancer.options.small_cell_carcinoma',
        value: 'small_cell_carcinoma',
      },
      {
        label: 'questionnaire.questions.bladder.3_type_of_bladder_cancer.options.mixed',
        value: 'mixed',
      },
    ],
    conditions: [],
    isRequired: true,
  },
  {
    q: 'Q60',
    id: ID.recurrence,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.bladder.4_recurrence.title',

    info: {
      title: 'questionnaire.questions.bladder.4_recurrence.info.title',
      content: 'questionnaire.questions.bladder.4_recurrence.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.bladder.4_recurrence.options.new_disease',
        value: 'new_disease',
      },
      {
        label: 'questionnaire.questions.bladder.4_recurrence.options.recurrent_disease',
        value: 'recurrent_disease',
      },
      {
        label: 'questionnaire.questions.bladder.4_recurrence.options.NS',
        value: 'NS',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q10',
    id: ID.patient_stage,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.bladder.5_patient_stage.title',

    info: {
      title: 'questionnaire.questions.bladder.5_patient_stage.info.title',
      content: 'questionnaire.questions.bladder.5_patient_stage.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.bladder.5_patient_stage.options.0.1',
        value: '0.1',
      },
      {
        label: 'questionnaire.questions.bladder.5_patient_stage.options.0.2',
        value: '0.2',
      },
      {
        label: 'questionnaire.questions.bladder.5_patient_stage.options.1',
        value: '1',
      },
      {
        label: 'questionnaire.questions.bladder.5_patient_stage.options.2',
        value: '2',
      },
      {
        label: 'questionnaire.questions.bladder.5_patient_stage.options.3',
        value: '3',
      },
      {
        label: 'questionnaire.questions.bladder.5_patient_stage.options.3_1',
        value: '3.1',
      },
      {
        label: 'questionnaire.questions.bladder.5_patient_stage.options.3_2',
        value: '3.2',
      },
      {
        label: 'questionnaire.questions.bladder.5_patient_stage.options.4',
        value: '4',
      },
      {
        label: 'questionnaire.questions.bladder.5_patient_stage.options.4_1',
        value: '4.1',
      },
      {
        label: 'questionnaire.questions.bladder.5_patient_stage.options.4_2',
        value: '4.2',
      },
      ImNotSure,
    ],
    conditions: [],
    isRequired: true,
  },
  {
    q: 'Q10a',
    id: ID.triple_question_drop_down,
    type: TRIPLE_QUESTION_DROP_DOWN,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.bladder.6_tripleQuestionDropDown.title',

    info: {
      title: 'questionnaire.questions.bladder.6_tripleQuestionDropDown.info.title',
      content: 'questionnaire.questions.bladder.6_tripleQuestionDropDown.info.content',
    },
    options: [
      {
        title: 'questionnaire.questions.bladder.6_tripleQuestionDropDown.options.tnm_t.title',
        name: ID.tnm_t,
        options: [
          {
            label: '',
            value: '',
          },
          {
            label: 'questionnaire.questions.bladder.6_tripleQuestionDropDown.options.tnm_t.1',
            value: '1',
          },
          {
            label: 'questionnaire.questions.bladder.6_tripleQuestionDropDown.options.tnm_t.2',
            value: '2',
          },
          {
            label: 'questionnaire.questions.bladder.6_tripleQuestionDropDown.options.tnm_t.3',
            value: '3',
          },
          {
            label: 'questionnaire.questions.bladder.6_tripleQuestionDropDown.options.tnm_t.4a',
            value: '4.1',
          },
          {
            label: 'questionnaire.questions.bladder.6_tripleQuestionDropDown.options.tnm_t.4b',
            value: '4.2',
          },
        ],
      },
      {
        title: 'questionnaire.questions.bladder.6_tripleQuestionDropDown.options.tnm_n.title',
        name: ID.tnm_n,
        options: [
          {
            label: '',
            value: '',
          },
          {
            label: 'questionnaire.questions.bladder.6_tripleQuestionDropDown.options.tnm_n.0',
            value: '0',
          },
          {
            label: 'questionnaire.questions.bladder.6_tripleQuestionDropDown.options.tnm_n.1',
            value: '1',
          },
          {
            label: 'questionnaire.questions.bladder.6_tripleQuestionDropDown.options.tnm_n.2',
            value: '2',
          },
          {
            label: 'questionnaire.questions.bladder.6_tripleQuestionDropDown.options.tnm_n.3',
            value: '3',
          },
        ],
      },
      {
        title: 'questionnaire.questions.bladder.6_tripleQuestionDropDown.options.tnm_m.title',
        name: ID.tnm_m,
        options: [
          {
            label: '',
            value: '',
          },
          {
            label: 'questionnaire.questions.bladder.6_tripleQuestionDropDown.options.tnm_m.0',
            value: '0',
          },
          {
            label: 'questionnaire.questions.bladder.6_tripleQuestionDropDown.options.tnm_m.1',
            value: '1',
          },
        ],
      },
    ],
    conditions: [
      {
        ifId: ID.patient_stage,
        type: IS_SKIPPED,
      },
    ],
  },
  {
    q: 'Q12',
    id: ID.undergo_surgery,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.bladder.7_undergo_surgery.title',

    info: {
      title: 'questionnaire.questions.bladder.7_undergo_surgery.info.title',
      content: 'questionnaire.questions.bladder.7_undergo_surgery.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.bladder.7_undergo_surgery.options.none',
        value: 'none',
      },
      {
        label: 'questionnaire.questions.bladder.7_undergo_surgery.options.turbt',
        value: 'turbt',
      },
      {
        label: 'questionnaire.questions.bladder.7_undergo_surgery.options.partial_cystectomy',
        value: 'partial_cystectomy',
      },
      {
        label: 'questionnaire.questions.bladder.7_undergo_surgery.options.radical_cystectomy',
        value: 'radical_cystectomy',
      },
      {
        label: 'questionnaire.questions.bladder.7_undergo_surgery.options.scheduled',
        value: 'scheduled',
      },
      ImNotSure,
    ],
    conditions: [],
  },
  {
    q: 'Q12a',
    id: ID.initial_surgery_date,
    section: SECTIONS.TREATMENT,
    type: MONTH_PICKER,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.bladder.8_initial_surgery_date.title',

    info: {
      title: 'questionnaire.questions.bladder.8_initial_surgery_date.info.title',
      content: 'questionnaire.questions.bladder.8_initial_surgery_date.info.content',
    },
    options: [],
    conditions: [
      {
        ifId: ID.undergo_surgery,
        type: CONTAIN_ONE_OF,
        ifValue: ['turbt', 'partial_cystectomy', 'radical_cystectomy'],
      },
    ],
    dateType: {
      type: dateType.stopTreatment,
    },
  },
  {
    q: 'Q12b',
    id: ID.neoadjuvant_chemo_1st_surgery,
    section: SECTIONS.TREATMENT,
    type: SINGLE_OPTION_SELECT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.bladder.9_neoadjuvant_chemo_1st_surgery.title',

    info: {
      title: 'questionnaire.questions.bladder.9_neoadjuvant_chemo_1st_surgery.info.title',
      content: 'questionnaire.questions.bladder.9_neoadjuvant_chemo_1st_surgery.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.bladder.9_neoadjuvant_chemo_1st_surgery.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.bladder.9_neoadjuvant_chemo_1st_surgery.options.no',
        value: 'no',
      },
      ImNotSure,
    ],
    conditions: [
      {
        ifId: ID.undergo_surgery,
        type: CONTAIN_ONE_OF,
        ifValue: ['turbt', 'partial_cystectomy', 'radical_cystectomy', 'scheduled'],
      },
    ],
  },
  {
    q: 'Q13',
    id: ID.undergo_surgery_for_recurrence,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.bladder.13_undergo_surgery_for_recurrence.title',
    subtitle: 'questionnaire.questions.bladder.13_undergo_surgery_for_recurrence.subtitle',
    info: {
      title: 'questionnaire.questions.bladder.13_undergo_surgery_for_recurrence.info.title',
      content: 'questionnaire.questions.bladder.13_undergo_surgery_for_recurrence.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.bladder.13_undergo_surgery_for_recurrence.options.none',
        value: 'none',
      },
      {
        label: 'questionnaire.questions.bladder.13_undergo_surgery_for_recurrence.options.turbt',
        value: 'turbt',
      },
      {
        label: 'questionnaire.questions.bladder.13_undergo_surgery_for_recurrence.options.partial_cystectomy',
        value: 'partial_cystectomy',
      },
      {
        label: 'questionnaire.questions.bladder.13_undergo_surgery_for_recurrence.options.radical_cystectomy',
        value: 'radical_cystectomy',
      },
      {
        label: 'questionnaire.questions.bladder.13_undergo_surgery_for_recurrence.options.scheduled',
        value: 'scheduled',
      },
      ImNotSure,
    ],
    conditions: [
      {
        ifId: ID.recurrence,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['new_disease'],
      },
      {
        ifId: ID.undergo_surgery,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['radical_cystectomy'],
      },
    ],
  },
  {
    q: 'Q13a',
    id: ID.recurrence_surgery_date,
    type: MONTH_PICKER,
    section: SECTIONS.TREATMENT,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.bladder.14_recurrence_surgery_date.title',

    info: {
      title: 'questionnaire.questions.bladder.14_recurrence_surgery_date.info.title',
      content: 'questionnaire.questions.bladder.14_recurrence_surgery_date.info.content',
    },
    options: [],
    conditions: [
      {
        ifId: ID.undergo_surgery_for_recurrence,
        type: CONTAIN_ONE_OF,
        ifValue: ['turbt', 'partial_cystectomy', 'radical_cystectomy'],
      },
      {
        ifId: ID.undergo_surgery,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['radical_cystectomy', 'scheduled'],
      },
    ],
    dateType: {
      type: dateType.stopTreatment,
    },
  },
  {
    q: 'Q13b',
    id: ID.neoadjuvant_chemo_additional_surgery,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.bladder.15_neoadjuvant_chemo_additional_surgery.title',

    info: {
      title: 'questionnaire.questions.bladder.15_neoadjuvant_chemo_additional_surgery.info.title',
      content: 'questionnaire.questions.bladder.15_neoadjuvant_chemo_additional_surgery.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.bladder.15_neoadjuvant_chemo_additional_surgery.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.bladder.15_neoadjuvant_chemo_additional_surgery.options.no',
        value: 'no',
      },
      ImNotSure,
    ],
    conditions: [
      {
        ifId: ID.undergo_surgery_for_recurrence,
        type: CONTAIN_ONE_OF,
        ifValue: ['turbt', 'partial_cystectomy', 'radical_cystectomy', 'scheduled'],
      },
      {
        ifId: ID.undergo_surgery,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['radical_cystectomy', 'scheduled'],
      },
    ],
  },
  {
    q: 'Q17',
    id: ID.metastasis_location,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.bladder.19_metastasis_location.title',

    info: {
      title: 'questionnaire.questions.bladder.19_metastasis_location.info.title',
      content: 'questionnaire.questions.bladder.19_metastasis_location.info.content',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.bladder.19_metastasis_location.options.bladder',
        value: 'bladder',
      },
      {
        label: 'questionnaire.questions.bladder.19_metastasis_location.options.distant_lymph_nodes',
        value: 'distant_lymph_nodes',
      },
      {
        label: 'questionnaire.questions.bladder.19_metastasis_location.options.lung',
        value: 'lung',
      },
      {
        label: 'questionnaire.questions.bladder.19_metastasis_location.options.bone',
        value: 'bone',
      },
      {
        label: 'questionnaire.questions.bladder.19_metastasis_location.options.liver',
        value: 'liver',
      },
      {
        label: 'questionnaire.questions.bladder.19_metastasis_location.options.peritoneum',
        value: 'peritoneum',
      },
      {
        label: 'questionnaire.questions.bladder.19_metastasis_location.options.brain',
        value: 'brain',
      },
      {
        label: 'questionnaire.questions.bladder.19_metastasis_location.options.other',
        value: 'other',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q18',
    id: ID.metastasis_location_brain_leptomeningeal,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.bladder.20_metastasis_location_brain_leptomeningeal.title',

    info: {
      title: 'questionnaire.questions.bladder.20_metastasis_location_brain_leptomeningeal.info.title',
      content: 'questionnaire.questions.bladder.20_metastasis_location_brain_leptomeningeal.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.bladder.20_metastasis_location_brain_leptomeningeal.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.bladder.20_metastasis_location_brain_leptomeningeal.options.no',
        value: 'no',
      },
      ImNotSure,
    ],
    conditions: [
      {
        ifId: ID.metastasis_location,
        type: CONTAIN_ONE_OF,
        ifValue: ['brain'],
      },
    ],
  },
  {
    q: 'Q19',
    id: ID.is_brain_metastasis_active,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.bladder.21_is_brain_metastasis_active.title',

    info: {
      title: 'questionnaire.questions.bladder.21_is_brain_metastasis_active.info.title',
      content: 'questionnaire.questions.bladder.21_is_brain_metastasis_active.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.bladder.21_is_brain_metastasis_active.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.bladder.21_is_brain_metastasis_active.options.no',
        value: 'no',
      },
      ImNotSure,
    ],
    conditions: [
      {
        ifId: ID.metastasis_location,
        type: CONTAIN_ONE_OF,
        ifValue: ['brain'],
      },
    ],
  },
  {
    q: 'Q20',
    id: ID.disease_measurable,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.bladder.22_metastatic_disease_measurable.title',
    info: {
      title: 'questionnaire.questions.bladder.22_metastatic_disease_measurable.info.title',
      content: 'questionnaire.questions.bladder.22_metastatic_disease_measurable.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.bladder.22_metastatic_disease_measurable.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.bladder.22_metastatic_disease_measurable.options.no_measurable',
        value: 'no_measurable',
      },
      {
        label: 'questionnaire.questions.bladder.22_metastatic_disease_measurable.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.bladder.22_metastatic_disease_measurable.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q21',
    id: ID.have_biopsy,
    path: customPaths.biopsy,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.bladder.23_biopsy_metastatic_disease.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.bladder.23_biopsy_metastatic_disease.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.bladder.23_biopsy_metastatic_disease.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.bladder.23_biopsy_metastatic_disease.options.biopsy_possible',
        value: 'biopsy_possible',
      },
      ImNotSure,
    ],
    conditions: [],
  },
  {
    q: 'Q90',
    id: ID.pd_l1_carrier_status,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.molecular,
    order: 0,
    title: 'questionnaire.questions.bladder_2.pd_l1_carrier_status.title',
    info: {
      title: 'questionnaire.questions.bladder_2.pd_l1_carrier_status.info.title',
      content: 'questionnaire.questions.bladder_2.pd_l1_carrier_status.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.bladder_2.pd_l1_carrier_status.options.positive',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.bladder_2.pd_l1_carrier_status.options.negative',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.bladder_2.pd_l1_carrier_status.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: ID.have_biopsy,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['no', 'biopsy_possible'],
      },
    ],
  },
  {
    q: 'Q80',
    id: ID.ngs,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.molecular,
    order: 0,
    title: 'questionnaire.questions.bladder.67_ngs.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.bladder.67_ngs.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.bladder.67_ngs.options.no',
        value: 'no',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q22',
    id: BIOMARKERS_QUESTION_ID,
    type: BIOMARKERS,
    section: SECTIONS.TREATMENT,
    bgIcon: i.molecular,
    order: 0,
    title: 'questionnaire.questions.bladder.24_biomarkers.title',
    subtitle: 'questionnaire.questions.bladder.24_biomarkers.subtitle',
    placeholder: 'questionnaire.questions.biomarkers.placeholder',
    info: {
      title: '',
      content: '',
    },
    conditions: [
      {
        ifId: ID.have_biopsy,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['no', 'biopsy_possible'],
      },
    ],
  },
  {
    q: 'Q23',
    id: ID.disease_resectable,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.bladder.25_disease_resectable.title',
    info: {
      title: 'questionnaire.questions.bladder.25_disease_resectable.info.title',
      content: 'questionnaire.questions.bladder.25_disease_resectable.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.bladder.25_disease_resectable.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.bladder.25_disease_resectable.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.bladder.25_disease_resectable.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q14',
    id: ID.undergo_radiation,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.bladder.26_undergo_radiation.title',
    subtitle: 'questionnaire.questions.bladder.26_undergo_radiation.subtitle',
    info: {
      title: 'questionnaire.questions.bladder.26_undergo_radiation.info.title',
      content: 'questionnaire.questions.bladder.26_undergo_radiation.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.bladder.26_undergo_radiation.options.none',
        value: 'none',
        exclusive: true,
      },
      {
        label: 'questionnaire.questions.bladder.26_undergo_radiation.options.yes_pelvic',
        value: 'yes_pelvic',
      },
      {
        label: 'questionnaire.questions.bladder.26_undergo_radiation.options.yes_other_than_pelvic',
        value: 'yes_other_than_pelvic',
      },
      {
        ...ImNotSure,
        exclusive: true,
      },
    ],
    conditions: [],
  },
  {
    q: 'Q14a',
    id: ID.radiation_treatment_end_date,
    type: MONTH_PICKER,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.bladder.27_radiation_treatment_end_date.title',

    info: {
      title: '',
      content: '',
    },
    options: [],
    conditions: [
      {
        ifId: ID.undergo_radiation,
        type: CONTAIN_ONE_OF,
        ifValue: ['yes_pelvic', 'yes_other_than_pelvic'],
      },
    ],
    dateType: {
      type: dateType.stopTreatment,
    },
  },
  {
    q: 'Q15',
    id: ID.treatment_for_bladder_cancer,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.bladder.28_treatment_for_bladder_cancer.title',

    info: {
      title: 'questionnaire.questions.bladder.28_treatment_for_bladder_cancer.info.title',
      content: 'questionnaire.questions.bladder.28_treatment_for_bladder_cancer.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.bladder.28_treatment_for_bladder_cancer.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.bladder.28_treatment_for_bladder_cancer.options.no',
        value: 'no',
      },
      ImNotSure,
    ],
    conditions: [],
    isRequired: true,
  },
  {
    q: 'Q16',
    id: ID.treatment_type,
    path: customPaths.treatments,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.bladder.29_treatment_types.title',
    subtitle: 'questionnaire.questions.bladder.29_treatment_types.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.bladder.29_treatment_types.options.intravesical_chemotherapy',
        value: 'intravesical_chemotherapy',
      },
      {
        label: 'questionnaire.questions.bladder.29_treatment_types.options.intravesical_bcg',
        value: 'intravesical_bcg',
      },
      {
        label: 'questionnaire.questions.bladder.29_treatment_types.options.systemic_chemotherapy',
        value: 'systemic_chemotherapy',
      },
      {
        label: 'questionnaire.questions.bladder.29_treatment_types.options.checkpoint_inhibitor',
        value: 'checkpoint_inhibitor',
      },
      {
        label: 'questionnaire.questions.bladder.29_treatment_types.options.erdafitinib',
        value: 'erdafitinib',
      },
      {
        label: 'questionnaire.questions.bladder.29_treatment_types.options.padcev',
        value: 'padcev',
      },
      {
        label: 'questionnaire.questions.bladder.29_treatment_types.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.treatment_for_bladder_cancer,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['no'],
      },
    ],
    isRequired: true,
  },
  {
    q: 'Q16a',
    id: ID.chemotherapy_type,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.bladder.30_chemotherapy_types.title',
    subtitle: 'questionnaire.questions.bladder.30_chemotherapy_types.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.bladder.30_chemotherapy_types.options.capecitabine',
        value: 'capecitabine',
      },
      {
        label: 'questionnaire.questions.bladder.30_chemotherapy_types.options.carboplatin',
        value: 'carboplatin',
      },
      {
        label: 'questionnaire.questions.bladder.30_chemotherapy_types.options.cisplatin',
        value: 'cisplatin',
      },
      {
        label: 'questionnaire.questions.bladder.30_chemotherapy_types.options.docetaxel',
        value: 'docetaxel',
      },
      {
        label: 'questionnaire.questions.bladder.30_chemotherapy_types.options.5fu',
        value: '5fu',
      },
      {
        label: 'questionnaire.questions.bladder.30_chemotherapy_types.options.gemcitabine',
        value: 'gemcitabine',
      },
      {
        label: 'questionnaire.questions.bladder.30_chemotherapy_types.options.isoflamide',
        value: 'isoflamide',
      },
      {
        label: 'questionnaire.questions.bladder.30_chemotherapy_types.options.methotrexate',
        value: 'methotrexate',
      },
      {
        label: 'questionnaire.questions.bladder.30_chemotherapy_types.options.mitomycin',
        value: 'mitomycin',
      },
      {
        label: 'questionnaire.questions.bladder.30_chemotherapy_types.options.paclitaxel',
        value: 'paclitaxel',
      },
      {
        label: 'questionnaire.questions.bladder.30_chemotherapy_types.options.pemetrexed',
        value: 'pemetrexed',
      },
      {
        label: 'questionnaire.questions.bladder.30_chemotherapy_types.options.thiotepa',
        value: 'thiotepa',
      },
      {
        label: 'questionnaire.questions.bladder.30_chemotherapy_types.options.valrubicin',
        value: 'valrubicin',
      },
      {
        label: 'questionnaire.questions.bladder.30_chemotherapy_types.options.ddmvac',
        value: 'ddmvac',
      },
      {
        label: 'questionnaire.questions.bladder.30_chemotherapy_types.options.cmv',
        value: 'cmv',
      },
      {
        label: 'questionnaire.questions.bladder.30_chemotherapy_types.options.vinflunine',
        value: 'vinflunine',
      },
      {
        label: 'questionnaire.questions.bladder.30_chemotherapy_types.options.irinotecan',
        value: 'irinotecan',
      },
      {
        label: 'questionnaire.questions.bladder.30_chemotherapy_types.options.aclarubicin',
        value: 'aclarubicin',
      },
      {
        label: 'questionnaire.questions.bladder.30_chemotherapy_types.options.topotecan',
        value: 'topotecan',
      },
      {
        label: 'questionnaire.questions.bladder.30_chemotherapy_types.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.treatment_type,
        type: CONTAIN_ONE_OF,
        ifValue: ['intravesical_chemotherapy', 'systemic_chemotherapy'],
      },
    ],
    isRequired: true,
  },
  {
    q: 'Q16b',
    id: ID.checkpoint_inhibitor_type,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.bladder.31_checkpoint_inhibitor_types.title',
    subtitle: 'questionnaire.questions.bladder.31_checkpoint_inhibitor_types.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.bladder.31_checkpoint_inhibitor_types.options.pembrolizumab',
        value: 'pembrolizumab',
      },
      {
        label: 'questionnaire.questions.bladder.31_checkpoint_inhibitor_types.options.atezolizumab',
        value: 'atezolizumab',
      },
      {
        label: 'questionnaire.questions.bladder.31_checkpoint_inhibitor_types.options.nivolumab',
        value: 'nivolumab',
      },
      {
        label: 'questionnaire.questions.bladder.31_checkpoint_inhibitor_types.options.durvalumab',
        value: 'durvalumab',
      },
      {
        label: 'questionnaire.questions.bladder.31_checkpoint_inhibitor_types.options.avelumab',
        value: 'avelumab',
      },
      {
        label: 'questionnaire.questions.bladder.31_checkpoint_inhibitor_types.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.treatment_type,
        type: CONTAIN_ONE_OF,
        ifValue: ['checkpoint_inhibitor'],
      },
    ],
    isRequired: true,
  },
  {
    q: 'Q16c',
    id: ID.chemotherapy_treatment_end_date,
    type: MONTH_PICKER,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.bladder.32_chemotherapy_treatment_end_date.title',

    info: {
      title: '',
      content: '',
    },
    options: [],
    conditions: [
      {
        ifId: ID.treatment_type,
        type: CONTAIN_ONE_OF,
        ifValue: ['intravesical_chemotherapy', 'systemic_chemotherapy'],
      },
    ],
    dateType: {
      type: dateType.stopTreatment,
    },
  },
  {
    q: 'Q16d',
    id: ID.checkpoint_inhibitor_treatment_end_date,
    type: MONTH_PICKER,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.bladder.33_checkpoint_inhibitor_treatment_end_date.title',

    info: {
      title: '',
      content: '',
    },
    options: [],
    conditions: [
      {
        ifId: ID.treatment_type,
        type: EQUAL_TO,
        ifValue: 'checkpoint_inhibitor',
      },
    ],
    dateType: {
      type: dateType.stopTreatment,
    },
  },
  {
    q: 'Q16e',
    id: ID.treatment_end_date,
    type: MONTH_PICKER,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.bladder.34_treatment_end_date.title',

    info: {
      title: '',
      content: '',
    },
    options: [],
    conditions: [
      {
        ifId: ID.treatment_type,
        type: CONTAIN_ONE_OF,
        ifValue: ['intravesical_bcg'],
      },
    ],
    dateType: {
      type: dateType.stopTreatment,
    },
  },
  {
    q: 'Q16k',
    id: ID.balversa_treatment_end_date,
    type: MONTH_PICKER,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.bladder.35_balversa_treatment_end_date.title',

    info: {
      title: '',
      content: '',
    },
    options: [],
    conditions: [
      {
        ifId: ID.treatment_type,
        type: CONTAIN_ONE_OF,
        ifValue: ['erdafitinib'],
      },
    ],
    dateType: {
      type: dateType.stopTreatment,
    },
  },
  {
    q: 'Q16l',
    id: ID.padcev_treatment_end_date,
    type: MONTH_PICKER,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.bladder.36_padcev_treatment_end_date.title',

    info: {
      title: '',
      content: '',
    },
    options: [],
    conditions: [
      {
        ifId: ID.treatment_type,
        type: CONTAIN_ONE_OF,
        ifValue: ['padcev'],
      },
    ],
    dateType: {
      type: dateType.stopTreatment,
    },
  },
  {
    q: 'Q16f',
    id: ID.chemotherapy_treatment_stop_reason,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.bladder.37_chemotherapy_treatment_stop_reason.title',

    info: {
      title: 'questionnaire.questions.bladder.37_chemotherapy_treatment_stop_reason.info.title',
      content: 'questionnaire.questions.bladder.37_chemotherapy_treatment_stop_reason.info.content',
    },
    options: stopReasonOptions,
    conditions: [
      {
        ifId: ID.treatment_type,
        type: CONTAIN_ONE_OF,
        ifValue: ['intravesical_chemotherapy', 'systemic_chemotherapy'],
      },
    ],
  },
  {
    q: 'Q16g',
    id: ID.checkpoint_inhibitor_treatment_stop_reason,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.bladder.38_checkpoint_inhibitor_treatment_stop_reason.title',

    info: {
      title: 'questionnaire.questions.bladder.38_checkpoint_inhibitor_treatment_stop_reason.info.title',
      content: 'questionnaire.questions.bladder.38_checkpoint_inhibitor_treatment_stop_reason.info.content',
    },
    options: stopReasonOptions,
    conditions: [
      {
        ifId: ID.treatment_type,
        type: CONTAIN_ONE_OF,
        ifValue: ['checkpoint_inhibitor'],
      },
    ],
  },
  {
    q: 'Q16h',
    id: ID.erdafitinib_treatment_stop_reason,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.bladder.39_erdafitinib_treatment_stop_reason.title',

    info: {
      title: 'questionnaire.questions.bladder.39_erdafitinib_treatment_stop_reason.info.title',
      content: 'questionnaire.questions.bladder.39_erdafitinib_treatment_stop_reason.info.content',
    },
    options: stopReasonOptions,
    conditions: [
      {
        ifId: ID.treatment_type,
        type: CONTAIN_ONE_OF,
        ifValue: ['erdafitinib'],
      },
    ],
  },
  {
    q: 'Q16i',
    id: ID.intravesical_bcg_treatment_stop_reason,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.bladder.40_intravesical_bcg_treatment_stop_reason.title',

    info: {
      title: 'questionnaire.questions.bladder.40_intravesical_bcg_treatment_stop_reason.info.title',
      content: 'questionnaire.questions.bladder.40_intravesical_bcg_treatment_stop_reason.info.content',
    },
    options: [
      {
        label:
          'questionnaire.questions.bladder.40_intravesical_bcg_treatment_stop_reason.options.im_still_on_treatment',
        value: 'treatment_ongoing',
      },
      {
        label: 'questionnaire.questions.bladder.40_intravesical_bcg_treatment_stop_reason.options.remission',
        value: 'remission',
      },
      {
        label: 'questionnaire.questions.bladder.40_intravesical_bcg_treatment_stop_reason.options.progression',
        value: 'progression',
      },
      {
        label: 'questionnaire.questions.bladder.40_intravesical_bcg_treatment_stop_reason.options.toxicity',
        value: 'toxicity',
      },
      {
        label: 'questionnaire.questions.bladder.40_intravesical_bcg_treatment_stop_reason.options.end_of_protocol',
        value: 'end_of_protocol',
      },
      ImNotSure,
    ],
    conditions: [
      {
        ifId: ID.treatment_type,
        type: CONTAIN_ONE_OF,
        ifValue: ['intravesical_bcg'],
      },
    ],
  },
  {
    q: 'Q16j',
    id: ID.padcev_treatment_stop_reason,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.bladder.41_intravesical_bcg_treatment_stop_reason.title',

    info: {
      title: 'questionnaire.questions.bladder.41_intravesical_bcg_treatment_stop_reason.info.title',
      content: 'questionnaire.questions.bladder.41_intravesical_bcg_treatment_stop_reason.info.content',
    },
    options: stopReasonOptions,
    conditions: [
      {
        ifId: ID.treatment_type,
        type: CONTAIN_ONE_OF,
        ifValue: ['padcev'],
      },
    ],
  },
  {
    q: 'Q35',
    id: ID.number_of_lines_for_advanced_disease,
    type: NUMBER_INPUT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.bladder.42_number_of_lines_for_advanced_disease.title',

    info: {
      title: 'questionnaire.questions.bladder.42_number_of_lines_for_advanced_disease.info.title',
      content: 'questionnaire.questions.bladder.42_number_of_lines_for_advanced_disease.info.content',
    },
    options: [],
    conditions: [
      {
        ifId: ID.treatment_for_bladder_cancer,
        type: EQUAL_TO,
        ifValue: 'yes',
      },
    ],
    numberType: numberType.intWithoutUnit,
    numberRange: {
      min: 0,
      max: 200,
    },
  },
  {
    q: 'Q37',
    id: ID.number_of_chemotherapy_lines_for_advanced_disease,
    type: NUMBER_INPUT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.bladder.43_number_of_chemotherapy_lines_for_advanced_disease.title',

    info: {
      title: 'questionnaire.questions.bladder.43_number_of_chemotherapy_lines_for_advanced_disease.info.title',
      content: 'questionnaire.questions.bladder.43_number_of_chemotherapy_lines_for_advanced_disease.info.content',
    },
    options: [],
    conditions: [
      {
        ifId: ID.treatment_type,
        type: CONTAIN_ONE_OF,
        ifValue: ['systemic_chemotherapy'],
      },
    ],
    numberType: numberType.intWithoutUnit,
  },
  {
    q: 'Q32',
    id: ID.received_bone_metastasis_treatment,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.bladder.44_received_bone_metastasis_treatment.title',
    subtitle: 'questionnaire.questions.bladder.44_received_bone_metastasis_treatment.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.bladder.44_received_bone_metastasis_treatment.options.none',
        value: 'none',
        exclusive: true,
      },
      {
        label: 'questionnaire.questions.bladder.44_received_bone_metastasis_treatment.options.zoledronic_acid',
        value: 'zoledronic_acid',
      },
      {
        label: 'questionnaire.questions.bladder.44_received_bone_metastasis_treatment.options.pamidronate',
        value: 'pamidronate',
      },
      {
        label: 'questionnaire.questions.bladder.44_received_bone_metastasis_treatment.options.denosumab',
        value: 'denosumab',
      },
      {
        label: 'questionnaire.questions.bladder.44_received_bone_metastasis_treatment.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.metastasis_location,
        type: CONTAIN_ONE_OF,
        ifValue: ['bone'],
      },
      {
        ifId: ID.treatment_for_bladder_cancer,
        type: EQUAL_TO,
        ifValue: 'yes',
      },
    ],
  },
  {
    q: 'Q45',
    id: ID.bone_metastasis_related_complication,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.bladder.45_bone_metastasis_related_complications.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.bladder.45_bone_metastasis_related_complications.options.none',
        value: 'none',
      },
      {
        label:
          'questionnaire.questions.bladder.45_bone_metastasis_related_complications.options.pathological_bone_fractures',
        value: 'pathological_bone_fractures',
      },
      {
        label: 'questionnaire.questions.bladder.45_bone_metastasis_related_complications.options.hypercalcemia',
        value: 'hypercalcemia',
      },
      {
        label:
          'questionnaire.questions.bladder.45_bone_metastasis_related_complications.options.spinal_cord_compression',
        value: 'spinal_cord_compression',
      },
      {
        label: 'questionnaire.questions.bladder.45_bone_metastasis_related_complications.options.other',
        value: 'other',
      },
      {
        label: 'questionnaire.questions.bladder.45_bone_metastasis_related_complications.options.NS',
        value: 'NS',
      },
    ],
    conditions: [
      {
        ifId: ID.metastasis_location,
        type: CONTAIN_ONE_OF,
        ifValue: ['bone'],
      },
    ],
  },
  {
    q: 'Q43',
    id: ID.received_investigational_therapy,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: '',
    order: 0,
    title: 'questionnaire.questions.bladder.46_received_investigational_therapy.title',
    subtitle: 'questionnaire.questions.bladder.46_received_investigational_therapy.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.bladder.46_received_investigational_therapy.options.none',
        value: 'none',
        exclusive: true,
      },
      {
        label: 'questionnaire.questions.bladder.46_received_investigational_therapy.options.immunotherapy',
        value: 'immunotherapy',
      },
      {
        label: 'questionnaire.questions.bladder.46_received_investigational_therapy.options.hdac_inhibitor',
        value: 'hdac_inhibitor',
      },
      {
        label: 'questionnaire.questions.bladder.46_received_investigational_therapy.options.tlr_agonist',
        value: 'tlr_agonist',
      },
      {
        label: 'questionnaire.questions.bladder.46_received_investigational_therapy.options.lag3_inhibitor',
        value: 'lag3_inhibitor',
      },
      {
        label: 'questionnaire.questions.bladder.46_received_investigational_therapy.options.cancer_vaccine',
        value: 'cancer_vaccine',
      },
      {
        label: 'questionnaire.questions.bladder.46_received_investigational_therapy.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.treatment_for_bladder_cancer,
        type: EQUAL_TO,
        ifValue: 'yes',
      },
    ],
  },
  {
    q: 'Q43a',
    id: ID.investigational_therapy_end_date,
    type: MONTH_PICKER,
    bgIcon: i.treatment,
    section: SECTIONS.TREATMENT,
    order: 0,
    title: 'questionnaire.questions.bladder.47_investigational_therapy_end_date.title',

    info: {
      title: '',
      content: '',
    },
    options: [],
    conditions: [
      {
        ifId: ID.received_investigational_therapy,
        type: CONTAIN_ONE_OF,
        ifValue: ['immunotherapy', 'hdac_inhibitor', 'tlr_agonist', 'cancer_vaccine'],
      },
    ],
    dateType: {
      type: dateType.stopTreatment,
    },
  },
  {
    q: 'Q42',
    id: ID.current_treatment_systemic_steroids,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.bladder.48_current_treatment_systemic_steroids.title',
    subtitle: 'questionnaire.questions.bladder.48_current_treatment_systemic_steroids.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.bladder.48_current_treatment_systemic_steroids.options.none',
        value: 'none',
      },
      {
        label: 'questionnaire.questions.bladder.48_current_treatment_systemic_steroids.options.under_10_mg_daily',
        value: 'under_10_mg_daily',
      },
      {
        label: 'questionnaire.questions.bladder.48_current_treatment_systemic_steroids.options.over_10_mg_daily',
        value: 'over_10_mg_daily',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q42a',
    id: ID.systemic_steroids_treatment_end_date,
    type: MONTH_PICKER,
    section: SECTIONS.TREATMENT,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.bladder.49_systemic_steroids_treatment_end_date.title',

    info: {
      title: '',
      content: '',
    },
    options: [],
    conditions: [
      {
        ifId: ID.current_treatment_systemic_steroids,
        type: CONTAIN_ONE_OF,
        ifValue: ['under_10_mg_daily', 'over_10_mg_daily'],
      },
    ],
    dateType: {
      type: dateType.stopTreatment,
    },
  },
  // Health part
  // TODO: check changes
  {
    q: 'Q44',
    id: ID.ecog,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.bladder.50_ecog.title',
    info: {
      title: 'questionnaire.questions.bladder.50_ecog.info.title',
      content: 'questionnaire.questions.bladder.50_ecog.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.bladder.50_ecog.options.0',
        value: '0',
      },
      {
        label: 'questionnaire.questions.bladder.50_ecog.options.1',
        value: '1',
      },
      {
        label: 'questionnaire.questions.bladder.50_ecog.options.2',
        value: '2',
      },
      {
        label: 'questionnaire.questions.bladder.50_ecog.options.3',
        value: '3',
      },
      {
        label: 'questionnaire.questions.bladder.50_ecog.options.4',
        value: '4',
      },
      ImNotSure,
    ],
    conditions: [],
    isRequired: true,
  },
  {
    q: 'Q46',
    id: ID.other_medical_condition,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.bladder.51_other_medical_condition.title',
    subtitle: 'questionnaire.questions.bladder.51_other_medical_condition.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.bladder.51_other_medical_condition.options.liver',
        value: 'liver',
      },
      {
        label: 'questionnaire.questions.bladder.51_other_medical_condition.options.heart',
        value: 'heart',
      },
      {
        label: 'questionnaire.questions.bladder.51_other_medical_condition.options.kidney',
        value: 'kidney',
      },
      {
        label: 'questionnaire.questions.bladder.51_other_medical_condition.options.autoimmune',
        value: 'autoimmune',
      },
      {
        label: 'questionnaire.questions.bladder.51_other_medical_condition.options.mental',
        value: 'mental',
      },
      {
        label: 'questionnaire.questions.bladder.51_other_medical_condition.options.allergies',
        value: 'allergies',
      },
      {
        label: 'questionnaire.questions.bladder.51_other_medical_condition.options.chronic_infection_or_inflammation',
        value: 'chronic_infection_or_inflammation',
      },
      {
        label: 'questionnaire.questions.bladder.51_other_medical_condition.options.gastrointestinal',
        value: 'gastrointestinal',
      },
      {
        label: 'questionnaire.questions.bladder.51_other_medical_condition.options.other_cancer_type',
        value: 'other_cancer_type',
      },
      {
        label: 'questionnaire.questions.bladder.51_other_medical_condition.options.anemia',
        value: 'anemia',
      },
      {
        label: 'questionnaire.questions.bladder.51_other_medical_condition.options.thyroid',
        value: 'thyroid',
      },
      {
        label: 'questionnaire.questions.bladder.51_other_medical_condition.options.hypertension',
        value: 'hypertension',
      },
      {
        label: 'questionnaire.questions.bladder.51_other_medical_condition.options.epilepsy',
        value: 'epilepsy',
      },
      {
        label: 'questionnaire.questions.bladder.51_other_medical_condition.options.diabetes',
        value: 'diabetes',
      },
      {
        label: 'questionnaire.questions.bladder.51_other_medical_condition.options.organ_transplant',
        value: 'organ_transplant',
      },
      {
        label: 'questionnaire.questions.bladder.51_other_medical_condition.options.extreme_obesity',
        value: 'extreme_obesity',
      },
      {
        label: 'questionnaire.questions.bladder.51_other_medical_condition.options.stroke',
        value: 'stroke',
      },
      {
        label: 'questionnaire.questions.bladder.51_other_medical_condition.options.lung',
        value: 'lung',
      },
      {
        label: 'questionnaire.questions.bladder.51_other_medical_condition.options.neuropathy',
        value: 'neuropathy',
      },
      {
        label: 'questionnaire.questions.bladder.51_other_medical_condition.options.polyuria',
        value: 'polyuria',
      },
      {
        label: 'questionnaire.questions.bladder.51_other_medical_condition.options.other',
        value: 'other',
      },
      {
        label: 'questionnaire.questions.bladder.51_other_medical_condition.options.no_other_health_issues',
        value: 'no_other_health_issues',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q48',
    id: ID.omc_liver,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.bladder.52_omc_liver.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.bladder.52_omc_liver.options.elevated_alt_ast',
        value: 'elevated_alt_ast',
      },
      {
        label: 'questionnaire.questions.bladder.52_omc_liver.options.elevated_bilirubin',
        value: 'elevated_bilirubin',
      },
      {
        label: 'questionnaire.questions.bladder.52_omc_liver.options.cirrhosis',
        value: 'cirrhosis',
      },
      {
        label: 'questionnaire.questions.bladder.52_omc_liver.options.other',
        value: 'other',
      },
      ImNotSure,
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['liver'],
      },
    ],
  },
  {
    q: 'Q49',
    id: ID.omc_heart,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.bladder.53_omc_heart.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.bladder.53_omc_heart.options.class_at_least_nyha_ii',
        value: 'class_at_least_nyha_ii',
      },
      {
        label: 'questionnaire.questions.bladder.53_omc_heart.options.condition_at_least_grade_2',
        value: 'condition_at_least_grade_2',
      },
      {
        label: 'questionnaire.questions.bladder.53_omc_heart.options.cardiac_ventricular_arrhythmia',
        value: 'cardiac_ventricular_arrhythmia',
      },
      {
        label: 'questionnaire.questions.bladder.53_omc_heart.options.heart_failure',
        value: 'heart_failure',
      },
      {
        label: 'questionnaire.questions.bladder.53_omc_heart.options.lvef_under_50_percent',
        value: 'lvef_under_50_percent',
      },
      {
        label: 'questionnaire.questions.bladder.53_omc_heart.options.qtcf_abnormal',
        value: 'qtcf_abnormal',
      },
      {
        label: 'questionnaire.questions.bladder.53_omc_heart.options.angina',
        value: 'angina',
      },
      {
        label: 'questionnaire.questions.bladder.53_omc_heart.options.myocardial_infarction',
        value: 'myocardial_infarction',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['heart'],
      },
    ],
  },
  {
    q: 'Q50',
    id: ID.omc_stroke_date,
    type: MONTH_PICKER,
    bgIcon: i.doctor_book_kit,
    section: SECTIONS.MY_HEALTH,
    order: 0,
    title: 'questionnaire.questions.bladder.54_omc_stroke_date.title',
    info: {
      title: '',
      content: '',
    },
    options: [],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['stroke'],
      },
    ],
    dateType: {
      type: dateType.stopTreatment,
    },
  },
  {
    q: 'Q51',
    id: ID.omc_kidney,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.bladder.55_omc_kidney.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.bladder.55_omc_kidney.options.low_glomerular_filtration_rate',
        value: 'low_glomerular_filtration_rate',
      },
      {
        label: 'questionnaire.questions.bladder.55_omc_kidney.options.dialysis',
        value: 'dialysis',
      },
      {
        label: 'questionnaire.questions.bladder.55_omc_kidney.options.kidney_stones',
        value: 'kidney_stones',
      },
      {
        label: 'questionnaire.questions.bladder.55_omc_kidney.options.creatinine_high',
        value: 'creatinine_high',
      },
      {
        label: 'questionnaire.questions.bladder.55_omc_kidney.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['kidney'],
      },
    ],
  },
  {
    q: 'Q51a',
    id: ID.creatinine_level,
    type: NUMBER_INPUT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.bladder.66_creatinine_level.title',
    subtitle: 'questionnaire.questions.bladder.66_creatinine_level.subtitle',
    info: {
      title: 'questionnaire.questions.bladder.66_creatinine_level.info.title',
      content: 'questionnaire.questions.bladder.66_creatinine_level.info.content',
    },
    options: [],
    conditions: [
      {
        ifId: ID.omc_kidney,
        type: CONTAIN_ONE_OF,
        ifValue: ['creatinine_high'],
      },
    ],
    instruction: 'questionnaire.questions.bladder.66_creatinine_level.instruction',
    numberType: numberType.fractionWithoutUnit,
  },
  {
    q: 'Q52',
    id: ID.omc_autoimmune,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.bladder.56_omc_autoimmune.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.bladder.56_omc_autoimmune.options.congenital_or_acquired_immunosuppression',
        value: 'congenital_or_acquired_immunosuppression',
      },
      {
        label: 'questionnaire.questions.bladder.56_omc_autoimmune.options.hepatitis',
        value: 'hepatitis',
      },
      {
        label: 'questionnaire.questions.bladder.56_omc_autoimmune.options.granulomatous_disease',
        value: 'granulomatous_disease',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['autoimmune'],
      },
    ],
  },
  {
    q: 'Q53',
    id: ID.omc_mental,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.bladder.57_omc_mental.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.bladder.57_omc_mental.options.dementia',
        value: 'dementia',
      },
      {
        label: 'questionnaire.questions.bladder.57_omc_mental.options.dysphrenia',
        value: 'dysphrenia',
      },
      {
        label: 'questionnaire.questions.bladder.57_omc_mental.options.neuropsychyatric',
        value: 'neuropsychyatric',
      },
      {
        label: 'questionnaire.questions.bladder.57_omc_mental.options.depression',
        value: 'depression',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['mental'],
      },
    ],
  },
  {
    q: 'Q54',
    id: ID.omc_allergy,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.bladder.58_omc_allergy.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.bladder.58_omc_allergy.options.interferon',
        value: 'interferon',
      },
      {
        label: 'questionnaire.questions.bladder.58_omc_allergy.options.metformin',
        value: 'metformin',
      },
      {
        label: 'questionnaire.questions.bladder.58_omc_allergy.options.vitD',
        value: 'vitD',
      },
      {
        label: 'questionnaire.questions.bladder.58_omc_allergy.options.penicillin',
        value: 'penicillin',
      },
      {
        label: 'questionnaire.questions.bladder.58_omc_allergy.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['allergies'],
      },
    ],
  },
  {
    q: 'Q55',
    id: ID.omc_chronic_infection_inflammation,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.bladder.59_omc_chronic_infection_inflammation.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.bladder.59_omc_chronic_infection_inflammation.options.hepatitis',
        value: 'hepatitis',
      },
      {
        label: 'questionnaire.questions.bladder.59_omc_chronic_infection_inflammation.options.hiv',
        value: 'hiv',
      },
      {
        label: 'questionnaire.questions.bladder.59_omc_chronic_infection_inflammation.options.other',
        value: 'other',
      },
      {
        label: 'questionnaire.questions.bladder.59_omc_chronic_infection_inflammation.options.uncontrolled_infection',
        value: 'uncontrolled_infection',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['chronic_infection_or_inflammation'],
      },
    ],
  },
  {
    q: 'Q58',
    id: ID.omc_gastro_problem,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.bladder.60_omc_gastro_problem.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.bladder.60_omc_gastro_problem.options.gastrointestinal_bleeding',
        value: 'gastrointestinal_bleeding',
      },
      {
        label: 'questionnaire.questions.bladder.60_omc_gastro_problem.options.crohns',
        value: 'crohns',
      },
      {
        label: 'questionnaire.questions.bladder.60_omc_gastro_problem.options.colitis',
        value: 'colitis',
      },
      {
        label: 'questionnaire.questions.bladder.60_omc_gastro_problem.options.ibs',
        value: 'ibs',
      },
      {
        label: 'questionnaire.questions.bladder.60_omc_gastro_problem.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['gastrointestinal'],
      },
    ],
  },
  {
    q: 'Q59',
    id: ID.omc_lung_disease,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.bladder.61_omc_lung_disease.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.bladder.61_omc_lung_disease.options.interstitial_lung_disease',
        value: 'interstitial_lung_disease',
      },
      {
        label: 'questionnaire.questions.bladder.61_omc_lung_disease.options.pneumonitis',
        value: 'pneumonitis',
      },
      {
        label: 'questionnaire.questions.bladder.61_omc_lung_disease.options.dyspnea',
        value: 'dyspnea',
      },
      {
        label: 'questionnaire.questions.bladder.61_omc_lung_disease.options.tuberculosis',
        value: 'tuberculosis',
      },
      {
        label: 'questionnaire.questions.bladder.61_omc_lung_disease.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['lung'],
      },
    ],
  },
  {
    q: 'Q56',
    id: ID.omc_abnormal_blood_results,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.bladder.62_omc_abnormal_blood_results.title',
    subtitle: 'questionnaire.questions.bladder.62_omc_abnormal_blood_results.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.bladder.62_omc_abnormal_blood_results.options.none',
        value: 'none',
        exclusive: true,
      },
      {
        label: 'questionnaire.questions.bladder.62_omc_abnormal_blood_results.options.low_wbc',
        value: 'low_wbc',
      },
      {
        label: 'questionnaire.questions.bladder.62_omc_abnormal_blood_results.options.low_hemoglobin',
        value: 'low_hemoglobin',
      },
      {
        label: 'questionnaire.questions.bladder.62_omc_abnormal_blood_results.options.low_neutrophil_count',
        value: 'low_neutrophil_count',
      },
      {
        label: 'questionnaire.questions.bladder.62_omc_abnormal_blood_results.options.low_platelet_count',
        value: 'low_platelet_count',
      },
      {
        label: 'questionnaire.questions.bladder.62_omc_abnormal_blood_results.options.low_potassium',
        value: 'low_potassium',
      },
      {
        label: 'questionnaire.questions.bladder.62_omc_abnormal_blood_results.options.low_magnesium',
        value: 'low_magnesium',
      },
      {
        label: 'questionnaire.questions.bladder.62_omc_abnormal_blood_results.options.clotting_coagulation_platelets',
        value: 'clotting_coagulation_platelets',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q47',
    id: ID.other_medications,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.bladder.63_other_medications.title',
    subtitle: 'questionnaire.questions.bladder.63_other_medications.subtitle',
    info: {
      title: 'questionnaire.questions.bladder.63_other_medications.info.title',
      content: 'questionnaire.questions.bladder.63_other_medications.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.bladder.63_other_medications.options.CYP3A4_inhibitors',
        value: 'cyp3a4_inhibitors',
      },
      {
        label: 'questionnaire.questions.bladder.63_other_medications.options.CYP3A4_inducers',
        value: 'cyp3a4_inducers',
      },
      {
        label: 'questionnaire.questions.bladder.63_other_medications.options.immunosuppressive medications',
        value: 'immunosuppressive medications',
      },
      {
        label: 'questionnaire.questions.bladder.63_other_medications.options.warfarin',
        value: 'warfarin',
      },
      {
        label: 'questionnaire.questions.bladder.63_other_medications.options.anticonvulsants',
        value: 'anticonvulsants',
      },
      {
        label: 'questionnaire.questions.bladder.63_other_medications.options.anti_retroviral_therapy',
        value: 'anti_retroviral_therapy',
      },
      {
        label: 'questionnaire.questions.bladder.63_other_medications.options.none',
        value: 'none',
        exclusive: true,
      },
    ],
    conditions: [],
  },
  initialDiagnosisDate('Q5'),
  userType,
  {
    q: 'Q1',
    id: ID.dob,
    type: MONTH_PICKER,
    section: SECTIONS.ABOUT_ME,
    bgIcon: i.user,
    order: 0,
    title: 'questionnaire.questions.bladder.0_dob.title',
    info: {
      title: '',
      content: '',
    },
    options: [],
    conditions: [],
    dateType: {
      type: dateType.dob,
    },
  },
  {
    q: 'Q2',
    id: ID.gender,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.ABOUT_ME,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.bladder.1_gender.title',
    subtitle: '',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.bladder.1_gender.options.female',
        value: 'female',
      },
      {
        label: 'questionnaire.questions.bladder.1_gender.options.male',
        value: 'male',
      },
      {
        label: 'questionnaire.questions.global.gender.options.other',
        value: 'other',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q3',
    id: ID.is_pregnant,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.ABOUT_ME,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.bladder.64_is_pregnant.title',
    info: {
      title: 'questionnaire.questions.bladder.64_is_pregnant.info.title',
      content: 'questionnaire.questions.bladder.64_is_pregnant.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.bladder.64_is_pregnant.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.bladder.64_is_pregnant.options.no',
        value: 'no',
      },
      ImNotSure,
    ],
    conditions: [
      {
        ifId: ID.gender,
        type: EQUAL_TO,
        ifValue: 'female',
      },
      {
        type: AGE_LESS_THAN,
        ifValue: 50,
      },
    ],
  },
  {
    q: 'Q4',
    id: ID.is_breastfeeding,
    section: SECTIONS.ABOUT_ME,
    type: SINGLE_OPTION_SELECT,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.bladder.65_is_breastfeeding.title',
    info: {
      title: 'questionnaire.questions.bladder.65_is_breastfeeding.info.title',
      content: 'questionnaire.questions.bladder.65_is_breastfeeding.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.bladder.65_is_breastfeeding.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.bladder.65_is_breastfeeding.options.no',
        value: 'no',
      },
    ],
    conditions: [
      {
        ifId: ID.gender,
        type: EQUAL_TO,
        ifValue: 'female',
      },
      {
        type: AGE_LESS_THAN,
        ifValue: 50,
      },
    ],
  },
  race,
  preferredLocation,
  travelLimit,
];

export default Questions;
