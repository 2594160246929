export const Profile = {
  // global questions from "user_personal_record" table
  user_type: 'user_type',
  dob: 'dob',
  gender: 'gender',

  // all the fields from api table
  race: 'race',
  disease_status: 'disease_status',
  type_of_aml: 'type_of_aml',
  aml_origin: 'aml_origin',
  risk_group: 'risk_group',
  risk_group_apl: 'risk_group_apl',
  percentage_of_bm_blasts: 'percentage_of_bm_blasts',
  blast_count: 'blast_count',
  wbc_count: 'wbc_count',
  blast_platelet_count: 'blast_platelet_count',
  has_biopsy: 'have_biopsy',
  ngs: 'ngs',
  received_transplant: 'received_transplant',
  transplantation_treatment_date: 'transplantation_treatment_date',
  transplant_outcome: 'transplant_outcome',
  supportive_care_type: 'supportive_care_type',
  current_treatment_systemic_steroids: 'current_treatment_systemic_steroids',
  systemic_steroids_treatment_end_date: 'systemic_steroids_treatment_end_date',
  aml_health_problems: 'aml_health_problems',
  treatments: 'treatments',
  ecog: 'ecog',
  gvhd: 'gvhd',
  other_medical_condition: 'other_medical_condition',
  omc_liver: 'omc_liver',
  omc_heart: 'omc_heart',
  omc_stroke_date: 'omc_stroke_date',
  omc_kidney: 'omc_kidney',
  omc_autoimmune: 'omc_autoimmune',
  omc_mental: 'omc_mental',
  omc_allergy: 'omc_allergy',
  omc_chronic_infection_inflammation: 'omc_chronic_infection_inflammation',
  omc_gastro_problem: 'omc_gastro_problem',
  omc_lung_disease: 'omc_lung_disease',
  omc_abnormal_blood_results: 'omc_abnormal_blood_results',
  other_medications: 'other_medications',
  is_pregnant: 'is_pregnant',
  is_breastfeeding: 'is_breastfeeding',
  have_insurance: 'have_insurance',
  insurances: 'insurances',

  // additional questions which are not listed in api table
  drugs_received: 'drugs_received',
  cycles: 'cycles',
  treatment_for_aml: 'treatment_for_aml',
  treatment_end_date: 'treatment_end_date',
  treatment_stop_reason: 'treatment_stop_reason',
};
