import React, { useState, useEffect, useRef } from 'react';
import './style.css';
import Actions from '../Actions';

const OptionBox = ({ title, contentComponent, selectedCount, onOpen, withTitle=true, initiallyOpen=false, previewComponent }) => {
    const [isModalVisible, setIsModalVisible] = useState(initiallyOpen);
    const modalRef = useRef(null);
    const buttonRef = useRef(null);

     useEffect(() => {
        setIsModalVisible(initiallyOpen);
    }, [initiallyOpen]);

    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target) &&
            buttonRef.current && !buttonRef.current.contains(event.target)) {
            setIsModalVisible(false);
        }
    };

    const toggleModal = () => {
        setIsModalVisible(!isModalVisible);

        if(!isModalVisible) {
            onOpen();
        };
    };

    const removeOpenState = () => {
        const currentParams = new URLSearchParams(window.location.search);
        currentParams.delete('open');
        const updatedUrl = `${window.location.pathname}?${currentParams.toString()}`;
        window.history.replaceState({}, '', updatedUrl);
    };

    const closeModal = () => {
        setIsModalVisible(false);
        removeOpenState();
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const optionBoxClassName = `option-box ${isModalVisible ? 'selected' : ''} ${selectedCount > 0 ? 'has-count' : ''}`;
    return (
        <div className={optionBoxClassName}>
            {previewComponent && React.cloneElement(previewComponent, { onClickMore: toggleModal })}
            <div
                className='option-button'
                ref={buttonRef}
                onClick={toggleModal}
            >
                <span className='title'>{title}</span>
                {selectedCount > 0 && <span className='selected-count'>{selectedCount}</span>}
            </div>
            {isModalVisible && (
                <div className='option-modal' ref={modalRef}>
                    <div className="close-icon" onClick={closeModal}></div>
                    {withTitle && <h3 className='title'>{title}</h3>}
                    <div className='content'>
                        {React.cloneElement(contentComponent, { onOptionChange: contentComponent.props.onOptionChange })}
                        <Actions onSave={closeModal} onCancel={closeModal} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default OptionBox;