import {
  SINGLE_OPTION_SELECT,
  MULTIPLE_OPTION_SELECT,
  MONTH_PICKER,
  TREATMENT_HISTORY,
  NUMBER_INPUT,
  // MESSAGE,
  OPTIONS_FROM_SERVER,
} from 'components/Questionnaire/constants/questionTypes';
import dateType from 'utils/constants/dateTypes';
import { Profile as ID } from 'components/Questionnaire/cancers/mm/MmProfile.enum';
import numberType from 'utils/constants/numberTypes';
import ConditionTypes from 'components/Questionnaire/constants/conditions';
import i from 'components/Questionnaire/constants/icons';
import {
  userType,
  dateOfBirth,
  diseaseStatus,
  preferredLocation,
  travelLimit,
  haveInsurance,
  insurance,
  initialDiagnosisDate,
} from 'components/Questionnaire/constants/globalQuestions';
import {
  treatmentCyclesOptions,
  // takingStateOptions,
  yesAndNoOptions,
} from 'components/Questionnaire/constants/globalOptions';
import SECTIONS from 'components/Questionnaire/constants/questionSections';
import GLOBAL_ENUMS from 'components/Questionnaire/constants/globalEnums';
import { QUESTION_VIEW_TYPES } from 'components/Questionnaire/constants/questionViewTypes';

const { OPTIONS_LIST_WITH_SHOW_MORE } = QUESTION_VIEW_TYPES;
const {
  NOT_EQUAL_TO_OR_SKIPPED, DOESNT_CONTAIN_ONE_OF_OR_SKIPPED, CONTAIN_ONE_OF, AGE_LESS_THAN, EQUAL_TO,
} = ConditionTypes;

const Questions = [
  diseaseStatus,
  {
    q: 'Q16',
    id: ID.type_of_mm,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.mm.2_type_of_mm.title',
    info: {
      title: 'questionnaire.questions.mm.2_type_of_mm.info.title',
      content: 'questionnaire.questions.mm.2_type_of_mm.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.mm.2_type_of_mm.options.smoldering_mm',
        value: 'smoldering_mm',
      },
      {
        label: 'questionnaire.questions.mm.2_type_of_mm.options.active_mm',
        value: 'active_mm',
      },
    ],
    conditions: [],
  },
  // {
  //   q: 'Q5',
  //   id: ID.has_mprotein_pre_message,
  //   type: MESSAGE,
  //   section: SECTIONS.MY_CANCER,
  //   bgIcon: '',
  //   order: 0,
  //   title: 'questionnaire.questions.mm.3_has_mprotein_pre_message.title',
  //   info: {
  //     title: '',
  //     content: '',
  //   },
  //   options: [],
  //   conditions: [],
  // },
  {
    q: 'Q11',
    id: ID.treatment_for_mm,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.mm.19_treatment_for_mm.title',
    info: {
      title: 'questionnaire.questions.mm.19_treatment_for_mm.info.title',
      content: 'questionnaire.questions.mm.19_treatment_for_mm.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.mm.19_treatment_for_mm.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.mm.19_treatment_for_mm.options.no',
        value: 'no',
      },
    ],
    conditions: [],
    isRequired: true,
  },
  {
    q: 'Q6',
    id: ID.has_mprotein,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.mm.4_has_mprotein.title',
    subtitle: 'questionnaire.questions.mm.4_has_mprotein.subtitle',
    info: {
      title: 'questionnaire.questions.mm.4_has_mprotein.info.title',
      content: 'questionnaire.questions.mm.4_has_mprotein.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.mm.4_has_mprotein.options.yes_blood',
        value: 'yes_blood',
      },
      {
        label: 'questionnaire.questions.mm.4_has_mprotein.options.yes_blood_and_urine',
        value: 'yes_blood_and_urine',
      },
      {
        label: 'questionnaire.questions.mm.4_has_mprotein.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.mm.4_has_mprotein.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [],
    isRequired: true,
  },
  {
    q: 'Q6a',
    id: ID.serum_mprotein_level,
    type: NUMBER_INPUT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.mm.5_serum_mprotein_level.title',
    subtitle: 'questionnaire.questions.mm.5_serum_mprotein_level.subtitle',
    info: {
      title: 'questionnaire.questions.mm.5_serum_mprotein_level.info.title',
      content: 'questionnaire.questions.mm.5_serum_mprotein_level.info.content',
    },
    options: [],
    conditions: [
      {
        ifId: ID.has_mprotein,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'no',
      },
    ],
    numberType: numberType.gDL,
  },
  {
    q: 'Q6b',
    id: ID.ig_subtype,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.mm.6_ig_subtype.title',
    info: {
      title: 'questionnaire.questions.mm.6_ig_subtype.info.title',
      content: 'questionnaire.questions.mm.6_ig_subtype.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.mm.6_ig_subtype.options.iga',
        value: 'iga',
      },
      {
        label: 'questionnaire.questions.mm.6_ig_subtype.options.igg',
        value: 'igg',
      },
      {
        label: 'questionnaire.questions.mm.6_ig_subtype.options.igm',
        value: 'igm',
      },
      {
        label: 'questionnaire.questions.mm.6_ig_subtype.options.igd',
        value: 'igd',
      },
      {
        label: 'questionnaire.questions.mm.6_ig_subtype.options.ige',
        value: 'ige',
      },
      {
        label: 'questionnaire.questions.mm.6_ig_subtype.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: ID.has_mprotein,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'no',
      },
    ],
  },
  {
    q: 'Q6c',
    id: ID.urine_mprotein_level,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.mm.7_urine_mprotein_level.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.mm.7_urine_mprotein_level.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.mm.7_urine_mprotein_level.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.mm.7_urine_mprotein_level.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: ID.has_mprotein,
        type: EQUAL_TO,
        ifValue: 'yes_blood_and_urine',
      },
    ],
  },
  {
    q: 'Q6d',
    id: ID.has_serum_flc,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.mm.8_has_serum_flc.title',
    info: {
      title: 'questionnaire.questions.mm.8_has_serum_flc.info.title',
      content: 'questionnaire.questions.mm.8_has_serum_flc.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.mm.8_has_serum_flc.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.mm.8_has_serum_flc.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.mm.8_has_serum_flc.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q6e',
    id: ID.serum_kappa_flc_level,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.mm.9_serum_kappa_flc_level.title',
    subtitle: 'questionnaire.questions.mm.9_serum_kappa_flc_level.subtitle',
    info: {
      title: 'questionnaire.questions.mm.9_serum_kappa_flc_level.info.title',
      content: 'questionnaire.questions.mm.9_serum_kappa_flc_level.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.mm.9_serum_kappa_flc_level.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.mm.9_serum_kappa_flc_level.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.mm.9_serum_kappa_flc_level.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: ID.has_serum_flc,
        type: EQUAL_TO,
        ifValue: 'yes',
      },
    ],
  },
  {
    q: 'Q7',
    id: ID.has_biopsy,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.mm.10_has_biopsy.title',
    info: {
      title: 'questionnaire.questions.mm.10_has_biopsy.info.title',
      content: 'questionnaire.questions.mm.10_has_biopsy.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.mm.10_has_biopsy.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.mm.10_has_biopsy.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.mm.10_has_biopsy.options.biopsy_possible',
        value: 'biopsy_possible',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q80',
    id: ID.ngs,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.molecular,
    order: 0,
    title: 'questionnaire.questions.mm.42_ngs.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.mm.42_ngs.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.mm.42_ngs.options.yes_waiting_for_the_results',
        value: 'yes_waiting_for_the_results',
      },
      {
        label: 'questionnaire.questions.mm.42_ngs.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.mm.42_ngs.options.no_but_planning',
        value: 'no_but_planning',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q8',
    id: ID.chromosomal_alterions,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.molecular,
    order: 0,
    title: 'questionnaire.questions.mm.11_chromosomal_alterions.title',
    subtitle: 'questionnaire.questions.mm.11_chromosomal_alterions.subtitle',
    info: {
      title: 'questionnaire.questions.mm.11_chromosomal_alterions.info.title',
      content: 'questionnaire.questions.mm.11_chromosomal_alterions.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.mm.11_chromosomal_alterions.options.none',
        value: 'none',
        exclusive: true,
      },
      {
        label: 'questionnaire.questions.mm.11_chromosomal_alterions.options.17p_deletion',
        value: '17p_deletion',
      },
      {
        label: 'questionnaire.questions.mm.11_chromosomal_alterions.options.1p_deletion',
        value: '1p_deletion',
      },
      {
        label: 'questionnaire.questions.mm.11_chromosomal_alterions.options.1q_amplification',
        value: '1q_amplification',
      },
      {
        label: 'questionnaire.questions.mm.11_chromosomal_alterions.options.4_14_translocation',
        value: '4_14_translocation',
      },
      {
        label: 'questionnaire.questions.mm.11_chromosomal_alterions.options.11_14_translocation',
        value: '11_14_translocation',
      },
      {
        label: 'questionnaire.questions.mm.11_chromosomal_alterions.options.16_14_translocation',
        value: '16_14_translocation',
      },
      {
        label: 'questionnaire.questions.mm.11_chromosomal_alterions.options.20_14_translocation',
        value: '20_14_translocation',
      },
      {
        label: 'questionnaire.questions.mm.11_chromosomal_alterions.options.trisomy',
        value: 'trisomy',
      },
      {
        label: 'questionnaire.questions.mm.11_chromosomal_alterions.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: ID.has_biopsy,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['no', 'biopsy_possible'],
      },
    ],
  },
  {
    q: 'Q9',
    id: ID.percentage_of_plasma_cells,
    type: NUMBER_INPUT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.mm.12_percentage_of_plasma_cells.title',
    info: {
      title: 'questionnaire.questions.mm.12_percentage_of_plasma_cells.info.title',
      content: 'questionnaire.questions.mm.12_percentage_of_plasma_cells.info.content',
    },
    options: [],
    conditions: [
      {
        ifId: ID.has_biopsy,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['no', 'biopsy_possible'],
      },
    ],
    numberType: numberType.percentage,
  },
  {
    q: 'Q17',
    id: ID.plasmacytoma_tumor,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.mm.13_plasmacytoma_tumor.title',
    info: {
      title: 'questionnaire.questions.mm.13_plasmacytoma_tumor.info.title',
      content: 'questionnaire.questions.mm.13_plasmacytoma_tumor.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.mm.13_plasmacytoma_tumor.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.mm.13_plasmacytoma_tumor.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.mm.13_plasmacytoma_tumor.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: ID.type_of_mm,
        type: EQUAL_TO,
        ifValue: 'active_mm',
      },
    ],
  },
  {
    q: 'Q17a',
    id: ID.plasmocytoma_measurable,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.mm.14_plasmocytoma_measurable.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.mm.14_plasmocytoma_measurable.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.mm.14_plasmocytoma_measurable.options.local_disease_measurable',
        value: 'local_disease_measurable',
      },
      {
        label: 'questionnaire.questions.mm.14_plasmocytoma_measurable.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.mm.14_plasmocytoma_measurable.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: ID.plasmacytoma_tumor,
        type: EQUAL_TO,
        ifValue: 'yes',
      },
    ],
  },
  {
    q: 'Q17b',
    id: ID.plasmacytoma_size_mm,
    type: NUMBER_INPUT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.mm.15_plasmacytoma_size_mm.title',
    subtitle: 'questionnaire.questions.mm.15_plasmacytoma_size_mm.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [],
    conditions: [
      {
        ifId: ID.plasmocytoma_measurable,
        type: EQUAL_TO,
        ifValue: 'yes',
      },
    ],
    numberType: numberType.int,
  },
  {
    q: 'Q10',
    id: ID.received_transplant,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.mm.16_received_transplant.title',
    info: {
      title: 'questionnaire.questions.mm.16_received_transplant.info.title',
      content: 'questionnaire.questions.mm.16_received_transplant.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.mm.16_received_transplant.options.autologous_transplant',
        value: 'autologous_transplant',
      },
      {
        label: 'questionnaire.questions.mm.16_received_transplant.options.allogeneic_transplant',
        value: 'allogeneic_transplant',
      },
      {
        label: 'questionnaire.questions.mm.16_received_transplant.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.mm.16_received_transplant.options.candidate_not_scheduled',
        value: 'candidate_not_scheduled',
      },
      {
        label: 'questionnaire.questions.mm.16_received_transplant.options.candidate_scheduled',
        value: 'candidate_scheduled',
      },
    ],
    conditions: [],
    isRequired: true,
  },
  {
    q: 'Q10a',
    id: ID.transplant_date,
    type: MONTH_PICKER,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.mm.17_transplant_date.title',
    info: {
      title: 'questionnaire.questions.mm.17_transplant_date.info.title',
      content: 'questionnaire.questions.mm.17_transplant_date.info.content',
    },
    options: [],
    conditions: [
      {
        ifId: ID.received_transplant,
        type: CONTAIN_ONE_OF,
        ifValue: ['autologous_transplant', 'allogeneic_transplant'],
      },
    ],
    dateType: {
      type: dateType.currentDate,
    },
  },
  {
    q: 'Q10b',
    id: ID.transplant_outcome,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.mm.18_transplant_outcome.title',
    info: {
      title: 'questionnaire.questions.mm.18_transplant_outcome.info.title',
      content: 'questionnaire.questions.mm.18_transplant_outcome.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.mm.18_transplant_outcome.options.remission',
        value: 'remission',
      },
      {
        label: 'questionnaire.questions.mm.18_transplant_outcome.options.no_response',
        value: 'no_response',
      },
      {
        label: 'questionnaire.questions.mm.18_transplant_outcome.options.relapse',
        value: 'relapse',
      },
    ],
    conditions: [
      {
        ifId: ID.received_transplant,
        type: CONTAIN_ONE_OF,
        ifValue: ['autologous_transplant', 'allogeneic_transplant'],
      },
    ],
  },
  {
    q: 'Q60',
    id: ID.treatments,
    type: TREATMENT_HISTORY,
    section: SECTIONS.TREATMENT,
    bgIcon: null,
    order: 0,
    title: 'questionnaire.questions.mm.20_treatments.title',
    requiredSubQuestionsId: [ID.drugs_received],
    footer: 'questionnaire.questions.mm.20_treatments.footer',
    info: {
      title: '',
      content: '',
    },
    conditions: [
      {
        ifId: ID.treatment_for_mm,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'no',
      },
    ],
    submitButtonLabel: 'questionnaire.questions.mm.20_treatments.button.submit',
    options: [
      {
        iconText: 'A',
        label: 'questionnaire.questions.mm.20_treatments.options.treatment_name',
        value: null,
      },
      {
        iconText: 'B',
        label: 'questionnaire.questions.mm.20_treatments.options.when_treatment_stopped',
        value: null,
      },
      {
        iconText: 'C',
        label: 'questionnaire.questions.mm.20_treatments.options.reason_treatment_stopped',
        value: null,
      },
    ],
    subQuestions: [
      {
        q: 'Q60a',
        id: ID.drugs_received,
        type: OPTIONS_FROM_SERVER,
        viewType: OPTIONS_LIST_WITH_SHOW_MORE,
        section: SECTIONS.TREATMENT,
        bgIcon: null,
        order: 0,
        titleIconText: 'A',
        title: 'questionnaire.questions.mm.20_treatments.sub_questions.drugs_received.title',
        subtitle: 'questionnaire.questions.mm.20_treatments.sub_questions.drugs_received.subtitle',
        placeholder: 'questionnaire.questions.drugs_received.placeholder',
        info: {
          title: '',
          content: '',
        },
        questionProps: {
          isSearchableByOptionGroupName: true,
        },
      },
      {
        q: 'Q60f',
        id: ID.cycles,
        type: SINGLE_OPTION_SELECT,
        section: SECTIONS.TREATMENT,
        title: 'questionnaire.questions.mm.20_treatments.sub_questions.cycles.title',
        disableNextOnOptionSelect: false,
        options: [...treatmentCyclesOptions],
      },
      {
        q: 'Q60b',
        id: ID.treatment_end_date,
        type: MONTH_PICKER,
        section: SECTIONS.TREATMENT,
        bgIcon: null,
        order: 0,
        titleIconText: 'B',
        title: 'questionnaire.questions.mm.20_treatments.sub_questions.treatment_end_date.title',
        subtitle: '',
        info: {
          title: '',
          content: '',
        },
        options: [],
        conditions: [],
        dateType: {
          type: dateType.stopTreatment,
        },
      },
      {
        q: 'Q60c',
        id: ID.treatment_stop_reason,
        type: SINGLE_OPTION_SELECT,
        section: SECTIONS.TREATMENT,
        bgIcon: null,
        order: 0,
        titleIconText: 'C',
        title: 'questionnaire.questions.mm.20_treatments.sub_questions.treatment_stop_reason.title',
        subtitle: '',
        info: {
          title: '',
          content: '',
        },
        disableNextOnOptionSelect: false,
        options: [
          {
            label:
                'questionnaire.questions.mm.20_treatments.sub_questions.treatment_stop_reason.options.treatment_ongoing',
            value: 'treatment_ongoing',
          },
          {
            label:
                'questionnaire.questions.mm.20_treatments.sub_questions.treatment_stop_reason.options.end_of_protocol',
            value: 'end_of_protocol',
          },
          {
            label: 'questionnaire.questions.mm.20_treatments.sub_questions.treatment_stop_reason.options.progression',
            value: 'progression',
          },
          {
            label: 'questionnaire.questions.mm.20_treatments.sub_questions.treatment_stop_reason.options.no_response',
            value: 'no_response',
          },
          {
            label: 'questionnaire.questions.mm.20_treatments.sub_questions.treatment_stop_reason.options.relapse',
            value: 'relapse',
          },
          {
            label: 'questionnaire.questions.mm.20_treatments.sub_questions.treatment_stop_reason.options.remission',
            value: 'remission',
          },
          {
            label: 'questionnaire.questions.mm.20_treatments.sub_questions.treatment_stop_reason.options.toxicity',
            value: 'toxicity',
          },
          {
            label: 'questionnaire.questions.mm.20_treatments.sub_questions.treatment_stop_reason.options.other_medical_conditions',
            value: 'other_medical_conditions',
          },
          {
            label: 'questionnaire.questions.mm.20_treatments.sub_questions.treatment_stop_reason.options.dosing_issues',
            value: 'dosing_issues',
          },
          {
            label: 'questionnaire.questions.mm.20_treatments.sub_questions.treatment_stop_reason.options.scheduling',
            value: 'scheduling',
          },
          {
            label: 'questionnaire.questions.mm.20_treatments.sub_questions.treatment_stop_reason.options.travel_limitation',
            value: 'travel_limitation',
          },
          {
            label: 'questionnaire.questions.mm.20_treatments.sub_questions.treatment_stop_reason.options.financial_reasons_or_insurance',
            value: 'financial_reasons_or_insurance',
          },
          {
            label: 'questionnaire.questions.mm.20_treatments.sub_questions.treatment_stop_reason.options.other',
            value: 'other',
          },
        ],
      },
    ],
    // shouldAutoOpenSubQuestions: [{
    //   ifId: ID.treatment_for_mm,
    //   type: EQUAL_TO,
    //   ifValue: 'yes',
    // }],
    // title: '',
    // info: {
    //   title: '',
    //   content: '',
    // },
    // footer: '',
    // type: TREATMENT_HISTORY,
    // section: SECTIONS.TREATMENT,
    // bgIcon: null,
    // order: 0,
    // subQuestionsIds: [
    //   ID.drugs_received,
    //   ID.treatment_stop_reason,
    // ],
    // options: [],
    // subQuestionsOptions: {
    //   treatment_stop_reason: [
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.no_response',
    //       value: 'no_response',
    //     },
    //     {
    //       label:
    //         'questionnaire.questions.treatment_stop_reason.options.end_of_protocol',
    //       value: 'end_of_protocol',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.toxicity',
    //       value: 'toxicity',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.progression',
    //       value: 'progression',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.relapse',
    //       value: 'relapse',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.remission',
    //       value: 'remission',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.other',
    //       value: 'other',
    //     },
    //   ],
    //   taking_state: takingStateOptions,
    // },
    // ],
  },
  {
    q: 'Q12',
    id: ID.myeloma_health_problems,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.mm.21_myeloma_health_problems.title',
    subtitle: 'questionnaire.questions.mm.21_myeloma_health_problems.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.mm.21_myeloma_health_problems.options.none',
        value: 'none',
        exclusive: true,
      },
      {
        label: 'questionnaire.questions.mm.21_myeloma_health_problems.options.hypercalcemia',
        value: 'hypercalcemia',
      },
      {
        label: 'questionnaire.questions.mm.21_myeloma_health_problems.options.bone_damage',
        value: 'bone_damage',
      },
      {
        label: 'questionnaire.questions.mm.21_myeloma_health_problems.options.kidney_damage',
        value: 'kidney_damage',
      },
      {
        label: 'questionnaire.questions.mm.21_myeloma_health_problems.options.amyloidosis',
        value: 'amyloidosis',
      },
      {
        label: 'questionnaire.questions.mm.21_myeloma_health_problems.options.anemia',
        value: 'anemia',
      },
      {
        label: 'questionnaire.questions.mm.21_myeloma_health_problems.options.bruising_and_bleeding',
        value: 'bruising_and_bleeding',
      },
      {
        label: 'questionnaire.questions.mm.21_myeloma_health_problems.options.infections',
        value: 'infections',
      },
      {
        label: 'questionnaire.questions.mm.21_myeloma_health_problems.options.poems',
        value: 'poems',
      },
      {
        label: 'questionnaire.questions.mm.21_myeloma_health_problems.options.cns_involvement',
        value: 'cns_involvement',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q12a',
    id: ID.serum_calcium_level,
    type: NUMBER_INPUT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.mm.22_serum_calcium_level.title',
    info: {
      title: '',
      content: '',
    },
    options: [],
    conditions: [
      {
        ifId: ID.myeloma_health_problems,
        type: CONTAIN_ONE_OF,
        ifValue: ['hypercalcemia'],
      },
    ],
    numberType: numberType.int,
  },
  {
    q: 'Q12b',
    id: ID.serum_hgb_level,
    type: NUMBER_INPUT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.mm.23_serum_hgb_level.title',
    info: {
      title: '',
      content: '',
    },
    options: [],
    conditions: [
      {
        ifId: ID.myeloma_health_problems,
        type: CONTAIN_ONE_OF,
        ifValue: ['anemia'],
      },
    ],
    numberType: numberType.gDL,
  },
  {
    q: 'Q12c',
    id: ID.platelet_count,
    type: NUMBER_INPUT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.mm.24_platelet_count.title',
    info: {
      title: 'questionnaire.questions.mm.24_platelet_count.info.title',
      content: 'questionnaire.questions.mm.24_platelet_count.info.content',
    },
    options: [],
    conditions: [],
    numberType: numberType.intWithoutUnit,
  },
  {
    q: 'Q61',
    id: ID.neutrophil_count,
    type: NUMBER_INPUT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.mm.neutrophil_count.title',
    info: {
      title: '',
      content: '',
    },
    options: [],
    conditions: [],
    numberType: numberType.intWithoutUnit,
  },
  {
    q: 'Q12d',
    id: ID.omc_kidney,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.mm.25_omc_kidney.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.mm.25_omc_kidney.options.creatinine_high',
        value: 'creatinine_high',
      },
      {
        label: 'questionnaire.questions.mm.25_omc_kidney.options.dialysis',
        value: 'dialysis',
      },
      {
        label: 'questionnaire.questions.mm.25_omc_kidney.options.cast_nephropathy',
        value: 'cast_nephropathy',
        // conditions: [
        //   {
        //     stateId: 'main.lang',
        //     type: NOT_EQUAL_TO_OR_SKIPPED,
        //     ifValue: LANGUAGE.he.SHORT
        //   }
        // ]
      },
      {
        label: 'questionnaire.questions.mm.25_omc_kidney.options.midd',
        value: 'midd',
        // conditions: [
        //   {
        //     stateId: 'main.lang',
        //     type: NOT_EQUAL_TO_OR_SKIPPED,
        //     ifValue: LANGUAGE.he.SHORT
        //   }
        // ]
      },
      {
        label: 'questionnaire.questions.mm.25_omc_kidney.options.low_glomerular_filtration_rate',
        value: 'low_glomerular_filtration_rate',
        // conditions: [
        //   {
        //     stateId: 'main.lang',
        //     type: NOT_EQUAL_TO_OR_SKIPPED,
        //     ifValue: LANGUAGE.he.SHORT
        //   }
        // ]
      },
      {
        label: 'questionnaire.questions.mm.25_omc_kidney.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.myeloma_health_problems,
        type: CONTAIN_ONE_OF,
        ifValue: ['kidney_damage'],
      },
    ],
  },
  {
    q: 'Q13',
    id: ID.supportive_care_type,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.mm.26_supportive_care_type.title',
    subtitle: 'questionnaire.questions.mm.26_supportive_care_type.subtitle',
    info: {
      title: 'questionnaire.questions.mm.26_supportive_care_type.info.title',
      content: 'questionnaire.questions.mm.26_supportive_care_type.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.mm.26_supportive_care_type.options.none',
        value: 'none',
        exclusive: true,
      },
      {
        label: 'questionnaire.questions.mm.26_supportive_care_type.options.bisphosphonates',
        value: 'bisphosphonates',
      },
      {
        label: 'questionnaire.questions.mm.26_supportive_care_type.options.denosumab',
        value: 'denosumab',
      },
      {
        label: 'questionnaire.questions.mm.26_supportive_care_type.options.radiation',
        value: 'radiation',
      },
      {
        label: 'questionnaire.questions.mm.26_supportive_care_type.options.erythropoietin',
        value: 'erythropoietin',
      },
      {
        label: 'questionnaire.questions.mm.26_supportive_care_type.options.blood_thinners',
        value: 'blood_thinners',
      },
      {
        label: 'questionnaire.questions.mm.26_supportive_care_type.options.anti_platelet_agents',
        value: 'anti_platelet_agents',
      },
      {
        label: 'questionnaire.questions.mm.26_supportive_care_type.options.immunoglobulins',
        value: 'immunoglobulins',
      },
      {
        label: 'questionnaire.questions.mm.26_supportive_care_type.options.other',
        value: 'other',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q45',
    id: ID.ecog,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.mm.27_ecog.title',
    info: {
      title: 'questionnaire.questions.mm.27_ecog.info.title',
      content: 'questionnaire.questions.mm.27_ecog.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.mm.27_ecog.options.0',
        value: '0',
      },
      {
        label: 'questionnaire.questions.mm.27_ecog.options.1',
        value: '1',
      },
      {
        label: 'questionnaire.questions.mm.27_ecog.options.2',
        value: '2',
      },
      {
        label: 'questionnaire.questions.mm.27_ecog.options.3',
        value: '3',
      },
      {
        label: 'questionnaire.questions.mm.27_ecog.options.4',
        value: '4',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q15',
    id: ID.gvhd,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.mm.28_gvhd.title',
    info: {
      title: 'questionnaire.questions.mm.28_gvhd.info.title',
      content: 'questionnaire.questions.mm.28_gvhd.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.mm.28_gvhd.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.mm.28_gvhd.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.mm.28_gvhd.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: ID.received_transplant,
        type: EQUAL_TO,
        ifValue: 'allogeneic_transplant',
      },
    ],
  },
  {
    q: 'Q46',
    id: ID.other_medical_condition,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.mm.29_other_medical_condition.title',
    subtitle: 'questionnaire.questions.mm.29_other_medical_condition.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.mm.29_other_medical_condition.options.none',
        value: 'none',
        exclusive: true,
      },
      {
        label: 'questionnaire.questions.mm.29_other_medical_condition.options.liver',
        value: 'liver',
      },
      {
        label: 'questionnaire.questions.mm.29_other_medical_condition.options.heart',
        value: 'heart',
      },
      {
        label: 'questionnaire.questions.mm.29_other_medical_condition.options.autoimmune',
        value: 'autoimmune',
      },
      {
        label: 'questionnaire.questions.mm.29_other_medical_condition.options.mental',
        value: 'mental',
      },
      {
        label: 'questionnaire.questions.mm.29_other_medical_condition.options.allergies',
        value: 'allergies',
      },
      {
        label: 'questionnaire.questions.mm.29_other_medical_condition.options.chronic_infection',
        value: 'chronic_infection',
      },
      {
        label: 'questionnaire.questions.mm.29_other_medical_condition.options.gastrointestinal',
        value: 'gastrointestinal',
      },
      {
        label: 'questionnaire.questions.mm.29_other_medical_condition.options.other_cancer_type',
        value: 'other_cancer_type',
      },
      {
        label: 'questionnaire.questions.mm.29_other_medical_condition.options.thyroid',
        value: 'thyroid',
      },
      {
        label: 'questionnaire.questions.mm.29_other_medical_condition.options.hypertension',
        value: 'hypertension',
      },
      {
        label: 'questionnaire.questions.mm.29_other_medical_condition.options.epilepsy',
        value: 'epilepsy',
      },
      {
        label: 'questionnaire.questions.mm.29_other_medical_condition.options.uncontrolled_diabetes',
        value: 'diabetes',
      },
      {
        label: 'questionnaire.questions.mm.29_other_medical_condition.options.organ_transplant',
        value: 'organ_transplant',
      },
      {
        label: 'questionnaire.questions.mm.29_other_medical_condition.options.extreme_obesity',
        value: 'extreme_obesity',
      },
      {
        label: 'questionnaire.questions.mm.29_other_medical_condition.options.stroke',
        value: 'stroke',
      },
      {
        label: 'questionnaire.questions.mm.29_other_medical_condition.options.lung',
        value: 'lung',
      },
      {
        label: 'questionnaire.questions.mm.29_other_medical_condition.options.neuropathy',
        value: 'neuropathy',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q48',
    id: ID.omc_liver,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.mm.30_omc_liver.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.mm.30_omc_liver.options.elevated_alt_ast',
        value: 'elevated_alt_ast',
      },
      {
        label: 'questionnaire.questions.mm.30_omc_liver.options.elevated_bilirubin',
        value: 'elevated_bilirubin',
      },
      {
        label: 'questionnaire.questions.mm.30_omc_liver.options.cirrhosis',
        value: 'cirrhosis',
      },
      {
        label: 'questionnaire.questions.mm.30_omc_liver.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['liver'],
      },
    ],
  },
  {
    q: 'Q49',
    id: ID.omc_heart,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.mm.31_omc_heart.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.mm.31_omc_heart.options.class_at_least_nyha_ii',
        value: 'class_at_least_nyha_ii',
      },
      {
        label: 'questionnaire.questions.mm.31_omc_heart.options.condition_at_least_grade_2',
        value: 'condition_at_least_grade_2',
        // conditions: [
        //   {
        //     stateId: 'main.lang',
        //     type: NOT_EQUAL_TO_OR_SKIPPED,
        //     ifValue: LANGUAGE.he.SHORT
        //   }
        // ]
      },
      {
        label: 'questionnaire.questions.mm.31_omc_heart.options.cardiac_ventricular_arrhythmia',
        value: 'cardiac_ventricular_arrhythmia',
      },
      {
        label: 'questionnaire.questions.mm.31_omc_heart.options.heart_failure',
        value: 'heart_failure',
      },
      {
        label: 'questionnaire.questions.mm.31_omc_heart.options.lvef_under_50_percent',
        value: 'lvef_under_50_percent',
      },
      {
        label: 'questionnaire.questions.mm.31_omc_heart.options.qtcf_abnormal',
        value: 'qtcf_abnormal',
        // conditions: [
        //   {
        //     stateId: 'main.lang',
        //     type: NOT_EQUAL_TO_OR_SKIPPED,
        //     ifValue: LANGUAGE.he.SHORT
        //   }
        // ]
      },
      {
        label: 'questionnaire.questions.mm.31_omc_heart.options.angina',
        value: 'angina',
      },
      {
        label: 'questionnaire.questions.mm.31_omc_heart.options.myocardial_infarction_within_6_months',
        value: 'myocardial_infarction_within_6_months',
      },
      {
        label: 'questionnaire.questions.mm.31_omc_heart.options.myocardial_infarction_more_than_6_months',
        value: 'myocardial_infarction_more_than_6_months',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['heart'],
      },
    ],
  },
  {
    q: 'Q50',
    id: ID.omc_stroke_date,
    type: MONTH_PICKER,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.mm.32_omc_stroke_date.title',
    info: {
      title: '',
      content: '',
    },
    options: [],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['stroke'],
      },
    ],
    dateType: {
      type: dateType.currentDate,
    },
  },
  // {
  //   q: 'Q52',
  //   id: ID.omc_autoimmune,
  //   type: SINGLE_OPTION_SELECT,
  //   section: SECTIONS.MY_HEALTH,
  //   bgIcon: i.doctor_book_kit,
  //   order: 0,
  //   title: 'questionnaire.questions.mm.33_omc_autoimmune.title',
  //   info: {
  //     title: '',
  //     content: ''
  //   },
  //   options: [
  //     {
  //       label:
  //         'questionnaire.questions.mm.33_omc_autoimmune.options.congenital_or_acquired_immunosuppression',
  //       value: 'congenital_or_acquired_immunosuppression'
  //     },
  //     {
  //       label: 'questionnaire.questions.mm.33_omc_autoimmune.options.hepatitis',
  //       value: 'hepatitis'
  //     },
  //     {
  //       label:
  //         'questionnaire.questions.mm.33_omc_autoimmune.options.granulomatous_disease',
  //       value: 'granulomatous_disease'
  //     }
  //   ],
  //   conditions: [
  //     {
  //       ifId: ID.other_medical_condition,
  //       type: CONTAIN_ONE_OF,
  //       ifValue: ['autoimmune']
  //     }
  //   ]
  // },
  {
    q: 'Q53',
    id: ID.omc_mental,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.mm.34_omc_mental.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.mm.34_omc_mental.options.dementia',
        value: 'dementia',
      },
      {
        label: 'questionnaire.questions.mm.34_omc_mental.options.neuropsychiatric',
        value: 'neuropsychyatric',
      },
      {
        label: 'questionnaire.questions.mm.34_omc_mental.options.depression',
        value: 'depression',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['mental'],
      },
    ],
  },
  {
    q: 'Q55',
    id: ID.omc_chronic_infection_inflammation,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.mm.35_omc_chronic_infection_inflammation.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.mm.35_omc_chronic_infection_inflammation.options.hepatitis',
        value: 'hepatitis',
      },
      {
        label: 'questionnaire.questions.mm.35_omc_chronic_infection_inflammation.options.hiv',
        value: 'hiv',
      },
      {
        label: 'questionnaire.questions.mm.35_omc_chronic_infection_inflammation.options.other',
        value: 'other',
      },
      {
        label: 'questionnaire.questions.mm.35_omc_chronic_infection_inflammation.options.uncontrolled_infection',
        value: 'uncontrolled_infection',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['chronic_infection'],
      },
    ],
  },
  {
    q: 'Q58',
    id: ID.omc_gastro_problem,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.mm.36_omc_gastro_problem.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.mm.36_omc_gastro_problem.options.gastrointestinal_bleeding',
        value: 'gastrointestinal_bleeding',
      },
      {
        label: 'questionnaire.questions.mm.36_omc_gastro_problem.options.crohns',
        value: 'crohns',
      },
      {
        label: 'questionnaire.questions.mm.36_omc_gastro_problem.options.colitis',
        value: 'colitis',
      },
      {
        label: 'questionnaire.questions.mm.36_omc_gastro_problem.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['gastrointestinal'],
      },
    ],
  },
  {
    q: 'Q59',
    id: ID.omc_lung_disease,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.mm.37_omc_lung_disease.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.mm.37_omc_lung_disease.options.dyspnea',
        value: 'dyspnea',
      },
      {
        label: 'questionnaire.questions.mm.37_omc_lung_disease.options.copd',
        value: 'copd',
      },
      {
        label: 'questionnaire.questions.mm.37_omc_lung_disease.options.Asthma',
        value: 'asthma',
      },
      {
        label: 'questionnaire.questions.mm.37_omc_lung_disease.options.pneumonitis',
        value: 'pneumonitis',
      },
      {
        label: 'questionnaire.questions.mm.37_omc_lung_disease.options.tuberculosis',
        value: 'tuberculosis',
      },
      {
        label: 'questionnaire.questions.mm.37_omc_lung_disease.options.interstitial_lung_disease',
        value: 'interstitial_lung_disease ',
      },
      {
        label: 'questionnaire.questions.mm.37_omc_lung_disease.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['lung'],
      },
    ],
  },
  {
    q: 'Q56',
    id: ID.omc_abnormal_blood_results,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.mm.38_omc_abnormal_blood_results.title',
    subtitle: 'questionnaire.questions.mm.38_omc_abnormal_blood_results.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.mm.38_omc_abnormal_blood_results.options.none',
        value: 'none',
        exclusive: true,
      },
      {
        label: 'questionnaire.questions.mm.38_omc_abnormal_blood_results.options.low_wbc',
        value: 'low_wbc',
      },
      {
        label: 'questionnaire.questions.mm.38_omc_abnormal_blood_results.options.low_neutrophil_count',
        value: 'low_neutrophil_count',
      },
      {
        label: 'questionnaire.questions.mm.38_omc_abnormal_blood_results.options.low_potassium',
        value: 'low_potassium',
      },
      {
        label: 'questionnaire.questions.mm.38_omc_abnormal_blood_results.options.low_magnesium',
        value: 'low_magnesium',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q47',
    id: ID.other_medications,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.mm.39_other_medications.title',
    info: {
      title: 'questionnaire.questions.mm.39_other_medications.info.title',
      content: 'questionnaire.questions.mm.39_other_medications.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.mm.39_other_medications.options.cyp3a4_inhibitors',
        value: 'cyp3a4_inhibitors',
      },
      {
        label: 'questionnaire.questions.mm.39_other_medications.options.cyp3a4_inducers',
        value: 'cyp3a4_inducers',
      },
      {
        label: 'questionnaire.questions.mm.39_other_medications.options.immunosuppressive_medications',
        value: 'immunosuppressive_medications',
      },
      {
        label: 'questionnaire.questions.mm.39_other_medications.options.anticonvulsants',
        value: 'anticonvulsants',
      },
      {
        label: 'questionnaire.questions.mm.39_other_medications.options.none',
        value: 'none',
      },
    ],
    conditions: [],
  },
  initialDiagnosisDate('Q14'),
  userType,
  dateOfBirth,
  {
    q: 'Q2',
    id: ID.gender,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.ABOUT_ME,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.mm.0_gender.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.mm.0_gender.options.female',
        value: 'female',
      },
      {
        label: 'questionnaire.questions.mm.0_gender.options.male',
        value: 'male',
      },
      {
        label: 'questionnaire.questions.global.gender.options.other',
        value: 'other',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q17',
    id: ID.is_pregnant,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.ABOUT_ME,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.mm.40_is_pregnant.title',
    info: {
      title: 'questionnaire.questions.mm.40_is_pregnant.info.title',
      content: 'questionnaire.questions.mm.40_is_pregnant.info.content',
    },
    options: yesAndNoOptions,
    conditions: [
      {
        ifId: ID.gender,
        type: CONTAIN_ONE_OF,
        ifValue: ['female'],
      },
      {
        type: AGE_LESS_THAN,
        ifValue: 50,
      },
    ],
  },
  {
    q: 'Q18',
    id: ID.is_breastfeeding,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.ABOUT_ME,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.mm.41_is_breastfeeding.title',
    info: {
      title: 'questionnaire.questions.mm.41_is_breastfeeding.info.title',
      content: 'questionnaire.questions.mm.41_is_breastfeeding.info.content',
    },
    options: yesAndNoOptions,
    conditions: [
      {
        ifId: ID.gender,
        type: CONTAIN_ONE_OF,
        ifValue: ['female'],
      },
      {
        type: AGE_LESS_THAN,
        ifValue: 50,
      },
    ],
  },
  {
    q: 'Q03',
    id: GLOBAL_ENUMS.race,
    type: MULTIPLE_OPTION_SELECT,
    bgIcon: i.medical_profile,
    section: SECTIONS.ABOUT_ME,
    order: 0,
    title: 'questionnaire.questions.global.race.title',
    subtitle: 'questionnaire.questions.global.race.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.global.race.options.native_american',
        value: 'native_american',
      },
      {
        label: 'questionnaire.questions.global.race.options.asian',
        value: 'asian',
      },
      {
        label: 'questionnaire.questions.global.race.options.african_american',
        value: 'african_american',
      },
      {
        label: 'questionnaire.questions.global.race.options.hispanic_latino',
        value: 'hispanic_latino',
      },
      {
        label: 'questionnaire.questions.global.race.options.pacific_islander',
        value: 'pacific_islander',
      },
      {
        label: 'questionnaire.questions.global.race.options.white',
        value: 'white',
      },
      {
        label: 'questionnaire.questions.global.race.options.other',
        value: 'other',
      },
      {
        label: 'questionnaire.questions.global.race.options.decline_to_answer',
        value: 'decline_to_answer',
        exclusive: true,
      },
    ],
    conditions: [],
    // conditions: [
    //   {
    //     ifId: 'appSettings.showRaceQuestion',
    //     type: IS_STATE_VALUE_TRUE
    //   }
    // ]
  },
  haveInsurance,
  insurance,
  preferredLocation,
  travelLimit,
];

export default Questions;
