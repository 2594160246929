import React from 'react';
import { useTranslation } from 'utils/modifiedTranslation';
import Button from 'new-ui/Components/Button';
import ArrowLeft from 'new-ui/assets/icons/arrow_left_2.svg';
import './style.css';
import { STATUSES } from '../options';

const StatusCards = ({ onClickBack }) => {
  const { t } = useTranslation();
  return (
    <div className='status-cards'>
      <ul className='status-list'>
        {STATUSES.map((status, index) => (
          <li key={index}>
            <img className='icon' src={status.icon} alt='icon' />
            <span className='label'>{t(status.label)}</span>
          </li>
        ))}
      </ul>

      <Button
        backgroundColor="#F2F2F7"
        color="rgba(33, 51, 50, 0.80)"
        width="initial"
        className="back-btn"
        title={t('events_widget.button.back')}
        action={onClickBack}
        icon={ArrowLeft}
      />
    </div>
  );
};

export default StatusCards;