import _ from 'lodash';

export function checkIfSkipped(profile, condition) {
  const { ifId } = condition;
  const profileValue = profile[ifId];

  if (!_.hasIn(profile, ifId)) {
    // In case the ifId is not in profile
    return true;
  }

  return _.isNull(profileValue);
}
