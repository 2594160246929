import React from 'react';
import PropTypes from 'prop-types';
import LightBulbIcon from 'new-ui/assets/icons/light_bulb.svg';
import './style.css';

const InfoNote = ({ info, theme }) => {
  const themeClass = theme ? `info-note--${theme}` : '';

  return (
    <div className={`info-note ${themeClass}`}>
      <img className='list-description-bulb' src={LightBulbIcon} alt='light-bulb' />
      <div className='content'>
        {info}
      </div>
    </div>
  );
};

InfoNote.propTypes = {
  info: PropTypes.string.isRequired
};

export default InfoNote;