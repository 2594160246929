import React from 'react';
import MaintenanceImg from './img/maintenance.png';
import { useTranslation } from 'utils/modifiedTranslation';
import './style.css';

const Maintenance = () => {
  const { t } = useTranslation();
  return (
    <div className='maintenance'>
      <p>{t('maintenance.title')}</p>
      <img src={MaintenanceImg} alt='Maintenance' />
    </div>
  );
};

export default Maintenance;
