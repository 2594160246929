import React, { useEffect, useRef, useState } from 'react';
import './IndicationChooser.css';
import app from 'new-ui/app';
import { useTranslation } from 'utils/modifiedTranslation';
import SearchIcon from 'new-ui/assets/icons/search_text.svg';
import CloseIcon from 'new-ui/assets/icons/close.svg';
import { waitFor } from 'new-ui/functions';

let _indications = null;

const IndicationChooser = (props)=>{
  const {
    onChange,
    value,
  } = props;
  const [show, setShow] = useState(false);
  const [indication, setIndication] = useState(value || null);
  const [indications, setIndications] = useState(null);
  const { t } = useTranslation();
  const $input = useRef();
  useEffect(()=>{
    const fetch = async ()=>{
      _indications = _indications || await app.getIndications();
      _indications = _indications.map((indication)=>{
        return {
          title: t(indication.title),
          value: indication.value,
        };
      });
      waitFor(()=>{
        return $input.current;
      }, ()=>{
        const indication = _indications.find((a)=>a.value === value);
        $input.current.value = indication?.title || '';
      }, ()=>{});
      setIndications(_indications);
    };
    fetch();
    // eslint-disable-next-line
  }, []);
  return indications ? (
    <div className={`indication-chooser-input ${show ? 'indication-chooser-input-show' : ''}`}>
      {/* <Select onChange={onChange} options={indications} autocomplete value={[]} /> */}
      <input
        ref={$input}
        onChange={
          (e)=>{
            const v = e.target.value;
            if (!v) {
              setShow(false);
              return;
            }
            const filtered = _indications.filter((indication)=>{
              return indication.title.toLowerCase().indexOf(v.toLowerCase()) !== -1;
            });
            setIndications(filtered);
            setShow(true);
          }
        }
        placeholder="Start typing indication..."
      />
      { indication ? (
        <img
          className="indication-chooser-close-icon"
          src={CloseIcon}
          alt="icon"
          onClick={()=>{
            setIndications([..._indications]);
            setIndication('');
            $input.current.value = '';
            setShow(false);
          }}
        />
      ) : <img className="indication-chooser-list-icon" src={SearchIcon} alt="icon" /> }
      <div className="indication-chooser-items">
        {
          indications.map((indication)=>{
            return (
              <div
                className="indication-chooser-item"
                onClick={()=>{
                  setIndication(indication);
                  setShow(false);
                  onChange(indication.value);
                  $input.current.value = indication.title;
                }}
              >
                {indication.title}
              </div>
            );
          })
        }
      </div>
    </div>
  ) : null;
};

export default IndicationChooser;
