import CONSTANTS from './constants';
import * as ICONS from '../../utils/static/icons';

const { treatmentGroupFilterOption, treatmentAdminTypes } = CONSTANTS;

export const shouldHideCardByGroupFilter = (groups, selectedGroups) => {
  if (selectedGroups?.includes(treatmentGroupFilterOption.SHOW_ALL)) {
    return false;
  }

  return groups?.every(group => !selectedGroups?.includes(group));
};

const colors = {
  [treatmentAdminTypes.oralTreatment.toLowerCase()]: ICONS.oralTreatment,
  [treatmentAdminTypes.infusion.toLowerCase()]: ICONS.infusion,
  [treatmentAdminTypes.tumor.toLowerCase()]: ICONS.tumor,
  [treatmentAdminTypes.injection.toLowerCase()]: ICONS.injection,
  [treatmentAdminTypes.device.toLowerCase()]: ICONS.device
};

export const getTreatmentAdminTypeIcon = type => {
  return colors[type] ? colors[type] : ICONS.oralTreatment;
};
