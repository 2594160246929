import { QUESTION_TYPES } from 'new-ui/Components/QuestionRenderer';
import { MULTI_PAGES_SUBQUESTIONS, MULTI_PAGES_QUESTIONS } from 'new-ui/Components/QuestionRenderer/renderers/MultiPages';
import { getOptions } from 'new-ui/api';

const OPTIONS_TO_TYPES = {
  [MULTI_PAGES_SUBQUESTIONS[MULTI_PAGES_QUESTIONS.TREATMENTS].DRUGS_RECEIVED]: QUESTION_TYPES.MULTIPLE_OPTION_SEARCH_SELECT,
  [MULTI_PAGES_SUBQUESTIONS[MULTI_PAGES_QUESTIONS.PROCEDURES].PROCEDURES_RECEIVED]: QUESTION_TYPES.MULTIPLE_OPTION_SEARCH_SELECT,
  insurances: QUESTION_TYPES.MULTIPLE_OPTION_SEARCH_SELECT,
};

/**
 * loadOptions
 * this gets
 * @param {*} q
 */
export const loadOptions = async (q)=>{
  if (q.options?.radioOptions) q.radioOptions = q.options.radioOptions;
  if (q.options?.dropDownOptions) q.options = q.options.dropDownOptions;
  if (q.type !== QUESTION_TYPES.OPTIONS_FROM_SERVER) return;
  const r = await getOptions(q.id);
  q.options = r.data;
  q.type = OPTIONS_TO_TYPES[q.id];
  q.options.forEach((o)=>{
    if (o.code) o.value = o.code;
    if (o.name) o.label = o.name;
  });
};
