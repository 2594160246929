//Fake API

const events = [
  { id: 1, type: 'treatment', date: '04/24/2024', status: 'upcoming' },
  { id: 2, type: 'clinic_visit', date: '05/09/2024', status: 'upcoming' },
  { id: 3, type: 'scan', date: '05/09/2024', status: 'upcoming' },
  { id: 4, type: 'treatment', date: '04/24/2024', cycle: 'Cycle 2', status: 'expired' },
  { id: 5, type: 'clinic_visit', date: '04/24/2024', status: 'expired' }
];

export const addEvent = async (delay=0, data) => {
  return await new Promise((resolve, reject) => {
    setTimeout(() => {
      const { cycle, eventType, repeatStatus, month, day, year } = data;
      const newEvent = { 
        id: new Date().getTime(), 
        type: eventType,
        status: 'upcoming',
        date: `${month}/${day}/${year}`,
        cycle, 
        repeatStatus, 
      };
      events.push(newEvent);
      resolve({message: 'Event Added ',newEvent});
    }, delay);
  });
};

export const deleteEvent = async (delay, id) => {
  return await new Promise((resolve, reject) => {
    setTimeout(() => {
      const index = events.findIndex(event => event.id === id);
      events.splice(index, 1);
      resolve({message: 'Event Deleted ',id});
    }, delay);
  });
};

export const getEvents = async (delay=0) => {
  return await new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(events);
    }, delay);
  });
};