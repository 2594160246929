import React from 'react';
import { useTranslation } from 'utils/modifiedTranslation';
import Button from 'new-ui/Components/Button';
import './style.css';

const SaveSearchPopup = ({ handleContinueWithoutSaving, handleSaveAndContinue }) => {
    const { t } = useTranslation();
    return (
        <div className="save-search">
            <div className='description'>
                {t('save_profile.description')}
            </div>
            <div className='actions'>
                <Button
                    backgroundColor={'#FFF'}
                    color={'#ff8c69'}
                    width='250px'
                    className='btn'
                    title={t('save_profile.actions.without_saving.button')}
                    action={handleContinueWithoutSaving}
                />
                <Button
                    className=""
                    title={t('save_profile.actions.with_saving.button')}
                    action={handleSaveAndContinue}
                />
            </div>
        </div>
    );
};

export default SaveSearchPopup;