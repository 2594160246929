export const NCT_IDS_WITH_MATCHED_TRIAL_MESSAGES = {
  NCT04069936: 'NCT04069936'
};

export const matchedTrialMessages = {
  [NCT_IDS_WITH_MATCHED_TRIAL_MESSAGES.NCT04069936]: {
    title: 'YOU HAVE MATCHED TO THE MILs™- NSCLC TRIAL',
    message:
      'A treatment decision for your Non-Small Cell Lung Cancer must be made quickly. Please apply to the trial by choosing one of the following options. We look forward to being in touch.'
  }
};

export const SORT_ITEMS = {
  OPTIMIZED_SCORE: 'optimizedScore',
  NEAREST_FACILITY_DISTANCE: 'nearest_facility_distance',
  SCORE: 'score'
};

export const FILTERS = {
  TREATMENT_TYPES: 'treatment_types',
  TRIAL_PHASES: 'trial_phases',
  TRIAL_VIEW: 'trial_view'
};

export const MATCH_FLOW_LOCAL_KEY = 'match_flow';

export const MATCH_FLOW = {
  BASIC: 'basic',
  LATEST: 'latest'
};

export default {
  FILTERS,
  GET_MATCHES_REQUEST: 'GET_MATCHES_REQUEST',
  GET_MATCHES_SUCCESS: 'GET_MATCHES_SUCCESS',
  GET_MATCHES_FAILURE: 'GET_MATCHES_FAILURE',
  CLEAR_MATCHES: 'CLEAR_MATCHES',
  TOGGLE_MATCHES_VIEW: 'TOGGLE_MATCHES_VIEW',
  MAP_VIEW: 'map',
  LIST_VIEW: 'list',
  OPTIMIZE_SORTER_DIRECTION: {
    ASCENDING: 'ASC',
    DESCENDING: 'DESC'
  },
  CHANGE_MATCHES_FILTERS: 'CHANGE_MATCHES_FILTERS',
  UPDATE_TRIALS_RESULT: 'UPDATE_TRIALS_RESULT',
  FLUSH_MATCHES_FILTERS: 'FLUSH_MATCHES_FILTERS',
  INITIAL_SORT_SET: 'INITIAL_SORT_SET',
  SET_MATCH_FLOW: 'SET_MATCH_FLOW'
};
