import React from "react";
import "./style.css";

const TooltipButton = ({icon, title, onClick}) => {
  return (
    <div className="tooltip-button" onClick={onClick}>
        <img alt="icon" src={icon} />
        {title}
    </div>
  );
};

export default TooltipButton;