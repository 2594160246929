import partnerConstants from '../constants/partner';

const initialState = {
  isPartner: false,
  name: null
};

export function partner(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case partnerConstants.SET_PARTNER:
      return {
        isPartner: true,
        name: payload
      };
    default:
      return state;
  }
}
