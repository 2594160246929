import React, { useEffect } from 'react';
import './style.css';
import { Button } from '@material-ui/core';
import app from 'new-ui/app';
import { PAGES, URI } from 'new-ui/constants';
import { useTranslation } from 'utils/modifiedTranslation';
import EligibleBtn from './EligibleBtn';
import MissingEligibilityBtn from './MissingEligibleBtn';
import ImageWrapper from './image-wrapper';
import { categoriesMap } from '../utils';
import configs from '../../../../configs';
import { isProgramEligible } from '../functions';

const onClickDetails = (program) => {
  window.open(program.linkToApply, '_blank');
};

const SupportPrograms = ({ programs, GA }) => {
  useEffect(()=>{
    app.sendGoogleAnalyticsEvent(GA.category, GA.events.presentedResults, { name: 'Financial results viewed' });
  });
  const { t } = useTranslation();
  return (
    <div className="support-programs">
      <div className="programs-header">
        <h2 className="title">Available Patient Support Programs</h2>
        <div className="count">
          {programs.length}
          {' '}
          {t('general.programs')}
        </div>
      </div>
      <ul className="program-list">
        <li>
          <div className="heading-program-wrapper">
            <span className="program-title">Programs</span>
          </div>
          <div />
        </li>
        {programs.map((program, index) => (
          <li className="program-item" key={index}>
            <div className="program-info-wrapper">
              <div className="program-info">
                {/* <StarIcon /> */}
                <div className="img-cont">
                  <ImageWrapper
                    imgSrc={`${configs.financeApiClientUrl}/fam-logos/${program.logoName}`}
                    defaultImgSrc={`${configs.financeApiClientUrl}/fam-logos/default.png`}
                  />
                </div>
                <span className="program-title">{program.programName}</span>
              </div>

              <div className="category">
                <img alt="icon" src={categoriesMap[program.category]?.icon} />
                <span>{program.category}</span>
              </div>
            </div>
            <div className="actions">
              {isProgramEligible(program)
                ? <EligibleBtn />
                : (
                  <MissingEligibilityBtn onClick={()=> {
                    app.sendGoogleAnalyticsEvent(GA.category, GA.events.missingInfo, { name: 'missing info clicked' });

                    app.history.push(URI[PAGES.FINANCE_INTAKE]);
                  }}
                  />
                )}
              <Button
                onClick={() => {
                  app.sendGoogleAnalyticsEvent(GA.category, GA.events.seeDetails, { name: 'See details clicked', metaDate: program.category });

                  onClickDetails(program);
                }}
                className="details-button"
              >
                <span className="label">{t('general.see_details')}</span>
              </Button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SupportPrograms;
