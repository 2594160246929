import {
  SINGLE_OPTION_SELECT,
  MULTIPLE_OPTION_SELECT,
  MONTH_PICKER,
  TREATMENT_HISTORY,
  NUMBER_INPUT,
  PROCEDURES,
  TRIPLE_QUESTION_DROP_DOWN,
  MULTIPLE_OPTION_SEARCH_SELECT,
  BIOMARKERS,
  SUB_STAGE_SELECT,
  OPTIONS_FROM_SERVER,
} from 'components/Questionnaire/constants/questionTypes';
import dateType from 'utils/constants/dateTypes';
import { Profile as ID } from 'components/Questionnaire/cancers/prostate/ProstateProfile.enum';
import numberType from 'utils/constants/numberTypes';
import ConditionTypes from 'components/Questionnaire/constants/conditions';
import i from 'components/Questionnaire/constants/icons';
import {
  preferredLocation,
  travelLimit,
  userType,
  dateOfBirth,
  race,
  insurance,
  haveInsurance,
  diseaseStatus,
  pdL1ExpressionByQNumber,
  initialDiagnosisDate,
} from 'components/Questionnaire/constants/globalQuestions';
import SECTIONS from 'components/Questionnaire/constants/questionSections';
import { customPaths } from 'utils/constants/customPaths';
import {
  treatmentCyclesOptions,
  // takingStateOptions,
  yesAndNoOptions,
} from 'components/Questionnaire/constants/globalOptions';
import questionOptions from '../../constants/questionOptions';
import { QUESTION_VIEW_TYPES } from '../../constants/questionViewTypes';
import GLOBAL_ENUMS from '../../constants/globalEnums';

const { biomarkers: BIOMARKERS_QUESTION_ID } = GLOBAL_ENUMS;

const { OPTIONS_LIST_WITH_SHOW_MORE } = QUESTION_VIEW_TYPES;

const noneOption = questionOptions.NONE;
const {
  NOT_EQUAL_TO_OR_SKIPPED,
  DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
  CONTAIN_ONE_OF,
  EQUAL_TO,
  IS_SKIPPED,
  CONTAINS_ONE_OF_OR_SKIPPED,
} = ConditionTypes;

const Questions = [
  diseaseStatus,
  {
    q: 'Q8',
    id: ID.current_stage,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.prostate.current_stage.title',
    subtitle: 'questionnaire.questions.prostate.current_stage.subtitle',
    info: {
      title: 'questionnaire.questions.prostate.current_stage.info.title',
      content: 'questionnaire.questions.prostate.current_stage.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.prostate.current_stage.options.1',
        value: '1',
      },
      {
        label: 'questionnaire.questions.prostate.current_stage.options.2',
        value: '2',
      },
      {
        label: 'questionnaire.questions.prostate.current_stage.options.3',
        value: '3',
      },
      {
        label: 'questionnaire.questions.prostate.current_stage.options.4',
        value: '4',
      },
    ],
    conditions: [],
    isRequired: true,
  },
  {
    q: 'Q2a',
    id: ID.crpc,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.prostate.crpc.title',
    info: {
      title: 'questionnaire.questions.prostate.crpc.info.title',
      content: 'questionnaire.questions.prostate.crpc.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.prostate.crpc.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.prostate.crpc.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.prostate.crpc.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [],
    isRequired: true,
  },
  {
    q: 'Q6',
    id: ID.type_of_pc,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.prostate.type_of_pc.title',
    info: {
      title: 'questionnaire.questions.prostate.type_of_pc.info.title',
      content: 'questionnaire.questions.prostate.type_of_pc.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.prostate.type_of_pc.options.adenocarcinoma',
        value: 'adenocarcinoma',
      },
      {
        label: 'questionnaire.questions.prostate.type_of_pc.options.scc',
        value: 'scc',
      },
      {
        label: 'questionnaire.questions.prostate.type_of_pc.options.intralobular_acinar',
        value: 'intralobular_acinar',
      },
      {
        label: 'questionnaire.questions.prostate.type_of_pc.options.ductal_carcinoma',
        value: 'ductal_carcinoma',
      },
      {
        label: 'questionnaire.questions.prostate.type_of_pc.options.clear_cell',
        value: 'clear_cell',
      },
      {
        label: 'questionnaire.questions.prostate.type_of_pc.options.mucinous_carcinoma',
        value: 'mucinous_carcinoma',
      },
      {
        label: 'questionnaire.questions.prostate.type_of_pc.options.iac',
        value: 'iac',
      },
      {
        label: 'questionnaire.questions.prostate.type_of_pc.options.sarcomatoid',
        value: 'sarcomatoid',
      },
      {
        label: 'questionnaire.questions.prostate.type_of_pc.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [],
    isRequired: true,
  },
  {
    q: 'Q11',
    id: ID.treatment_for_prostate_cancer,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.prostate.treatment_for_prostate_cancer.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.prostate.treatment_for_prostate_cancer.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.prostate.treatment_for_prostate_cancer.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.prostate.treatment_for_prostate_cancer.options.scheduled',
        value: 'scheduled',
      },
    ],
    conditions: [],
    isRequired: true,
  },
  // {
  //   q: 'Q8',
  //   id: ID.current_stage,
  //   type: SINGLE_OPTION_SELECT,
  //   section: SECTIONS.MY_CANCER,
  //   bgIcon: i.medical_report,
  //   order: 0,
  //   title: 'questionnaire.questions.prostate.current_stage.title',
  //   subtitle: 'questionnaire.questions.prostate.current_stage.subtitle',
  //   info: {
  //     title: 'questionnaire.questions.prostate.current_stage.info.title',
  //     content: 'questionnaire.questions.prostate.current_stage.info.content',
  //   },
  //   options: [
  //     {
  //       label: 'questionnaire.questions.prostate.current_stage.options.1',
  //       value: '1',
  //     },
  //     {
  //       label: 'questionnaire.questions.prostate.current_stage.options.2',
  //       value: '2',
  //     },
  //     {
  //       label: 'questionnaire.questions.prostate.current_stage.options.3',
  //       value: '3',
  //     },
  //     {
  //       label: 'questionnaire.questions.prostate.current_stage.options.4',
  //       value: '4',
  //     },
  //   ],
  //   conditions: [],
  //   isRequired: true,
  // },
  {
    q: 'Q8a',
    id: ID.sub_stage,
    type: SUB_STAGE_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.prostate.sub_stage.title',
    info: {
      title: 'questionnaire.questions.prostate.sub_stage.info.title',
      content: 'questionnaire.questions.prostate.sub_stage.info.content',
    },
    parentStageId: ID.current_stage,
    options: {
      2: [
        {
          label: 'questionnaire.questions.prostate.sub_stage.options.2_1',
          value: '2.1',
        },
        {
          label: 'questionnaire.questions.prostate.sub_stage.options.2_2',
          value: '2.2',
        },
        {
          label: 'questionnaire.questions.prostate.sub_stage.options.2_3',
          value: '2.3',
        },
      ],
      3: [
        {
          label: 'questionnaire.questions.prostate.sub_stage.options.3_1',
          value: '3.1',
        },
        {
          label: 'questionnaire.questions.prostate.sub_stage.options.3_2',
          value: '3.2',
        },
        {
          label: 'questionnaire.questions.prostate.sub_stage.options.3_3',
          value: '3.3',
        },
      ],
      4: [
        {
          label: 'questionnaire.questions.prostate.sub_stage.options.4_1',
          value: '4.1',
        },
        {
          label: 'questionnaire.questions.prostate.sub_stage.options.4_2',
          value: '4.2',
        },
      ],
    },
    conditions: [
      {
        ifId: ID.current_stage,
        type: CONTAIN_ONE_OF,
        ifValue: ['2', '3', '4'],
      },
    ],
  },
  {
    q: 'Q9',
    id: ID.triple_question_drop_down,
    type: TRIPLE_QUESTION_DROP_DOWN,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.prostate.triple_question_drop_down.title',
    subtitle: 'questionnaire.questions.prostate.triple_question_drop_down.subtitle',
    options: [
      {
        title: 'questionnaire.questions.prostate.triple_question_drop_down.tnm_t.title',
        name: 'tnm_t',
        options: [
          {
            label: '',
            value: '',
          },
          {
            label: 'questionnaire.questions.prostate.triple_question_drop_down.options.1',
            value: '1',
          },
          {
            label: 'questionnaire.questions.prostate.triple_question_drop_down.options.2',
            value: '2',
          },
          {
            label: 'questionnaire.questions.prostate.triple_question_drop_down.options.3',
            value: '3',
          },
          {
            label: 'questionnaire.questions.prostate.triple_question_drop_down.options.4',
            value: '4',
          },
        ],
      },
      {
        title: 'questionnaire.questions.prostate.triple_question_drop_down.tnm_n.title',
        name: 'tnm_n',
        options: [
          {
            label: '',
            value: '',
          },
          {
            label: 'questionnaire.questions.prostate.triple_question_drop_down.options.0',
            value: '0',
          },
          {
            label: 'questionnaire.questions.prostate.triple_question_drop_down.options.1',
            value: '1',
          },
          {
            label: 'questionnaire.questions.prostate.triple_question_drop_down.options.2',
            value: '2',
          },
          {
            label: 'questionnaire.questions.prostate.triple_question_drop_down.options.3',
            value: '3',
          },
        ],
      },
      {
        title: 'questionnaire.questions.prostate.triple_question_drop_down.tnm_m.title',
        name: 'tnm_m',
        options: [
          {
            label: '',
            value: '',
          },
          {
            label: 'questionnaire.questions.prostate.triple_question_drop_down.options.0',
            value: '0',
          },
          {
            label: 'questionnaire.questions.prostate.triple_question_drop_down.options.1',
            value: '1',
          },
        ],
      },
    ],
    info: {
      title: 'questionnaire.questions.prostate.triple_question_drop_down.info.title',
      content: 'questionnaire.questions.prostate.triple_question_drop_down.info.content',
    },
    conditions: [
      {
        ifId: ID.current_stage,
        type: IS_SKIPPED,
        ifValue: null,
      },
    ],
  },
  {
    q: 'Q7',
    id: ID.gleason_score,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.prostate.gleason_score.title',
    info: {
      title: 'questionnaire.questions.prostate.gleason_score.info.title',
      content: 'questionnaire.questions.prostate.gleason_score.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.prostate.gleason_score.options.lower_or_equal_to_5',
        value: 'lower_or_equal_to_5',
      },
      {
        label: 'questionnaire.questions.prostate.gleason_score.options.6',
        value: '6',
      },
      {
        label: 'questionnaire.questions.prostate.gleason_score.options.7',
        value: '7',
      },
      {
        label: 'questionnaire.questions.prostate.gleason_score.options.8',
        value: '8',
      },
      {
        label: 'questionnaire.questions.prostate.gleason_score.options.9_or_10',
        value: '9_or_10',
      },
      {
        label: 'questionnaire.questions.prostate.gleason_score.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q7a',
    id: ID.gleason_score_exact,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.prostate.gleason_score_exact.title',
    info: {
      title: 'questionnaire.questions.prostate.gleason_score_exact.info.title',
      content: 'questionnaire.questions.prostate.gleason_score_exact.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.prostate.gleason_score_exact.options.73p4',
        value: '73p4',
      },
      {
        label: 'questionnaire.questions.prostate.gleason_score_exact.options.74p3',
        value: '74p3',
      },
      {
        label: 'questionnaire.questions.prostate.gleason_score_exact.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: ID.gleason_score,
        type: EQUAL_TO,
        ifValue: '7',
      },
    ],
  },
  {
    q: 'Q13',
    id: ID.psa_level,
    type: NUMBER_INPUT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.prostate.psa_level.title',
    info: {
      title: 'questionnaire.questions.prostate.psa_level.info.title',
      content: 'questionnaire.questions.prostate.psa_level.info.content',
    },
    options: [],
    conditions: [],
    numberType: numberType.fractionWithoutUnit,
  },
  {
    q: 'Q13a',
    id: ID.progression_recurrence,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.prostate.progression_recurrence.title',
    info: {
      title: 'questionnaire.questions.prostate.progression_recurrence.info.title',
      content: 'questionnaire.questions.prostate.progression_recurrence.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.prostate.progression_recurrence.options.biochemocal_progression',
        value: 'biochemocal_progression',
      },
      {
        label: 'questionnaire.questions.prostate.progression_recurrence.options.radiological_biochemical_progression',
        value: 'radiological_biochemical_progression',
      },
      {
        label: 'questionnaire.questions.prostate.progression_recurrence.options.radiological_progression',
        value: 'radiological_progression',
      },
      {
        label: 'questionnaire.questions.prostate.progression_recurrence.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: ID.disease_status,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['remission', 'new_disease'],
      },
    ],
  },
  {
    q: 'Q13b',
    id: ID.testosterone_level,
    type: NUMBER_INPUT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.prostate.testosterone_level.title',
    subtitle: 'questionnaire.questions.prostate.testosterone_level.subtitle',
    info: {
      title: 'questionnaire.questions.prostate.testosterone_level.info.title',
      content: 'questionnaire.questions.prostate.testosterone_level.info.content',
    },
    options: [],
    conditions: [],
    numberType: numberType.fractionWithoutUnit,
  },
  {
    q: 'Q10',
    id: ID.disease_location,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.prostate.disease_location.title',
    subtitle: 'questionnaire.questions.prostate.disease_location.subtitle',
    info: {
      title: 'questionnaire.questions.prostate.disease_location.info.title',
      content: 'questionnaire.questions.prostate.disease_location.info.content',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.prostate.disease_location.options.prostate',
        value: 'prostate',
      },
      {
        label: 'questionnaire.questions.prostate.disease_location.options.sentinel_ln',
        value: 'sentinel_ln',
      },
      {
        label: 'questionnaire.questions.prostate.disease_location.options.lymph_nodes',
        value: 'lymph_nodes',
      },
      {
        label: 'questionnaire.questions.prostate.disease_location.options.bones',
        value: 'bones',
      },
      {
        label: 'questionnaire.questions.prostate.disease_location.options.bone_superscan',
        value: 'bone_superscan',
      },
      {
        label: 'questionnaire.questions.prostate.disease_location.options.pelvis',
        value: 'pelvis',
      },
      {
        label: 'questionnaire.questions.prostate.disease_location.options.liver',
        value: 'liver',
      },
      {
        label: 'questionnaire.questions.prostate.disease_location.options.lungs',
        value: 'lungs',
      },
      {
        label: 'questionnaire.questions.prostate.disease_location.options.brain',
        value: 'brain',
      },
      {
        label: 'questionnaire.questions.prostate.disease_location.options.kidney',
        value: 'kidney',
      },
      {
        label: 'questionnaire.questions.prostate.disease_location.options.adrenal_glands',
        value: 'adrenal_glands',
      },
      {
        label: 'questionnaire.questions.prostate.disease_location.options.spleen',
        value: 'spleen',
      },
      {
        label: 'questionnaire.questions.prostate.disease_location.options.bladder',
        value: 'bladder',
      },
      {
        label: 'questionnaire.questions.prostate.disease_location.options.spine',
        value: 'spine',
      },
      {
        label: 'questionnaire.questions.prostate.disease_location.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.disease_status,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'remission',
      },
    ],
    isRequired: true,
  },
  {
    q: 'Q18',
    id: ID.metastasis_location_brain_leptomeningeal,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.prostate.metastasis_location_brain_leptomeningeal.title',
    info: {
      title: 'questionnaire.questions.prostate.metastasis_location_brain_leptomeningeal.info.title',
      content: 'questionnaire.questions.prostate.metastasis_location_brain_leptomeningeal.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.prostate.metastasis_location_brain_leptomeningeal.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.prostate.metastasis_location_brain_leptomeningeal.options.no',
        value: 'no',
      },
    ],
    conditions: [
      {
        ifId: ID.disease_location,
        type: CONTAIN_ONE_OF,
        ifValue: ['brain'],
      },
    ],
  },
  {
    q: 'Q19',
    id: ID.brain_metastasis_active,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.prostate.brain_metastasis_active.title',
    info: {
      title: 'questionnaire.questions.prostate.brain_metastasis_active.info.title',
      content: 'questionnaire.questions.prostate.brain_metastasis_active.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.prostate.brain_metastasis_active.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.prostate.brain_metastasis_active.options.no',
        value: 'no',
      },
    ],
    conditions: [
      {
        ifId: ID.disease_location,
        type: CONTAIN_ONE_OF,
        ifValue: ['brain'],
      },
    ],
  },
  {
    q: 'Q15',
    id: ID.disease_measurable,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.prostate.disease_measurable.title',
    info: {
      title: 'questionnaire.questions.prostate.disease_measurable.info.title',
      content: 'questionnaire.questions.prostate.disease_measurable.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.prostate.disease_measurable.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.prostate.disease_measurable.options.no_but_it_can_be',
        value: 'no_but_it_can_be',
      },
      {
        label: 'questionnaire.questions.prostate.disease_measurable.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.prostate.disease_measurable.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: ID.disease_status,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'remission',
      },
    ],
  },
  {
    q: 'Q16',
    id: ID.primary_tumor_size,
    type: NUMBER_INPUT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.prostate.primary_tumor_size.title',
    subtitle: 'questionnaire.questions.prostate.primary_tumor_size.subtitle',
    info: {
      title: 'questionnaire.questions.prostate.primary_tumor_size.info.title',
      content: 'questionnaire.questions.prostate.primary_tumor_size.info.content',
    },
    options: [],
    conditions: [
      {
        ifId: ID.disease_measurable,
        type: EQUAL_TO,
        ifValue: 'yes',
      },
      {
        ifId: ID.disease_location,
        type: CONTAIN_ONE_OF,
        ifValue: ['prostate'],
      },
    ],
    numberType: numberType.int,
  },
  {
    q: 'Q17',
    id: ID.metastasis_size,
    type: NUMBER_INPUT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.prostate.metastasis_size.title',
    subtitle: 'questionnaire.questions.prostate.metastasis_size.subtitle',
    info: {
      title: 'questionnaire.questions.prostate.metastasis_size.info.title',
      content: 'questionnaire.questions.prostate.metastasis_size.info.content',
    },
    options: [],
    conditions: [
      {
        ifId: ID.disease_measurable,
        type: EQUAL_TO,
        ifValue: 'yes',
      },
      {
        ifId: ID.current_stage,
        type: EQUAL_TO,
        ifValue: '4',
      },
    ],
    numberType: numberType.int,
  },
  {
    q: 'Q21',
    id: ID.have_biopsy,
    path: customPaths.biopsy,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.prostate.have_biopsy.title',
    info: {
      title: 'questionnaire.questions.prostate.have_biopsy.info.title',
      content: 'questionnaire.questions.prostate.have_biopsy.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.prostate.have_biopsy.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.prostate.have_biopsy.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.prostate.have_biopsy.options.biopsy_possible',
        value: 'biopsy_possible',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q90',
    id: ID.pd_l1_carrier_status,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.molecular,
    order: 0,
    title: 'questionnaire.questions.prostate.pd_l1_carrier_status.title',
    info: {
      title: 'questionnaire.questions.prostate.pd_l1_carrier_status.info.title',
      content: 'questionnaire.questions.prostate.pd_l1_carrier_status.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.prostate.pd_l1_carrier_status.options.positive',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.prostate.pd_l1_carrier_status.options.negative',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.prostate.pd_l1_carrier_status.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: ID.have_biopsy,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['no', 'biopsy_possible'],
      },
    ],
  },
  pdL1ExpressionByQNumber('Q90a'),
  {
    q: 'Q80',
    id: ID.ngs,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.molecular,
    order: 0,
    title: 'questionnaire.questions.prostate.ngs.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.prostate.ngs.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.prostate.ngs.options.yes_waiting_for_the_results',
        value: 'yes_waiting_for_the_results',
      },
      {
        label: 'questionnaire.questions.prostate.ngs.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.prostate.ngs.options.no_but_planning',
        value: 'no_but_planning',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q22',
    id: BIOMARKERS_QUESTION_ID,
    type: BIOMARKERS,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.molecular,
    order: 0,
    title: 'questionnaire.questions.biomarkers.title',
    subtitle: 'questionnaire.questions.prostate.biomarkers.subtitle',
    placeholder: 'questionnaire.questions.biomarkers.placeholder',
    info: {
      title: '',
      content: '',
    },
    conditions: [
      {
        ifId: ID.have_biopsy,
        type: CONTAINS_ONE_OF_OR_SKIPPED,
        ifValue: ['yes'],
      },
    ],
  },
  {
    q: 'Q23',
    id: ID.disease_resectable,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.prostate.disease_resectable.title',
    info: {
      title: 'questionnaire.questions.prostate.disease_resectable.info.title',
      content: 'questionnaire.questions.prostate.disease_resectable.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.prostate.disease_resectable.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.prostate.disease_resectable.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.prostate.disease_resectable.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: ID.disease_status,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'remission',
      },
      {
        ifId: ID.current_stage,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['4'],
      },
    ],
  },
  {
    q: 'Q12',
    id: ID.had_procedure_for_prostate_cancer,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.prostate.had_procedure_for_prostate_cancer.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.prostate.had_procedure_for_prostate_cancer.options.yes',
        value: 'yes',
        disableConditions: [
          {
            ifId: ID.had_procedure_for_prostate_cancer,
            type: EQUAL_TO,
            ifValue: 'no',
          },
        ],
      },
      {
        label: 'questionnaire.questions.prostate.had_procedure_for_prostate_cancer.options.no',
        value: 'no',
        disableConditions: [
          {
            ifId: ID.had_procedure_for_prostate_cancer,
            type: CONTAIN_ONE_OF,
            ifValue: ['yes', 'scheduled'],
          },
        ],
      },
      {
        label: 'questionnaire.questions.prostate.had_procedure_for_prostate_cancer.options.scheduled',
        value: 'scheduled',
        disableConditions: [
          {
            ifId: ID.had_procedure_for_prostate_cancer,
            type: EQUAL_TO,
            ifValue: 'no',
          },
        ],
      },
    ],
    conditions: [],
    isRequired: true,
  },
  {
    q: 'Q70',
    id: ID.procedures,
    type: PROCEDURES,
    section: SECTIONS.TREATMENT,
    bgIcon: '',
    order: 0,
    title: 'questionnaire.questions.prostate.procedures.title',
    subtitle: 'questionnaire.questions.prostate.procedures.subtitle',
    footer: 'questionnaire.questions.prostate.procedures.footer',
    requiredSubQuestionsId: [ID.procedures_received],
    info: {
      title: '',
      content: '',
    },
    submitButtonLabel: 'questionnaire.questions.prostate.procedures.submit_button',
    options: [
      {
        iconText: 'A',
        label: 'questionnaire.questions.prostate.procedures.options.type.label',
        value: null,
      },
      {
        iconText: 'B',
        label: 'questionnaire.questions.prostate.procedures.options.when_procedure.label',
        value: null,
      },
      {
        iconText: 'C',
        label: 'questionnaire.questions.prostate.procedures.options.procedure_outcome.label',
        value: null,
      },
    ],
    shouldAutoOpenSubQuestions: [{
      ifId: ID.had_procedure_for_prostate_cancer,
      type: EQUAL_TO,
      ifValue: 'yes',
    }],
    conditions: [
      {
        ifId: ID.had_procedure_for_prostate_cancer,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'no',
      },
    ],
    subQuestions: [
      {
        q: 'Q70a',
        id: ID.procedures_received,
        type: MULTIPLE_OPTION_SEARCH_SELECT,
        viewType: OPTIONS_LIST_WITH_SHOW_MORE,
        section: SECTIONS.TREATMENT,
        bgIcon: i.treatment,
        order: 0,
        titleIconText: 'A',
        title: 'questionnaire.questions.prostate.procedures.sub_questions.procedures_received.title',
        subtitle: '',
        placeholder: 'questionnaire.questions.procedures_received.placeholder',
        info: {
          title: '',
          content: '',
        },
        options: {
          dropDownOptions: [
            {
              label:
                'questionnaire.questions.prostate.procedures.sub_questions.procedures_received.options.radical_prostatectomy',
              value: 'radical_prostatectomy',
            },
            {
              label: 'questionnaire.questions.prostate.procedures.sub_questions.procedures_received.options.turp',
              value: 'turp',
            },
            {
              label:
                'questionnaire.questions.prostate.procedures.sub_questions.procedures_received.options.pelvic_lymph_node_dissection',
              value: 'pelvic_lymph_node_dissection',
            },
            {
              label:
                'questionnaire.questions.prostate.procedures.sub_questions.procedures_received.options.cryoablation',
              value: 'cryoablation',
            },
            {
              label:
                'questionnaire.questions.prostate.procedures.sub_questions.procedures_received.options.hyperthermia',
              value: 'hyperthermia',
            },
            {
              label:
                'questionnaire.questions.prostate.procedures.sub_questions.procedures_received.options.surgical_castration',
              value: 'surgical_castration',
            },
            {
              label: 'questionnaire.questions.prostate.procedures.sub_questions.procedures_received.options.ebrt',
              value: 'ebrt',
            },
            {
              label:
                'questionnaire.questions.prostate.procedures.sub_questions.procedures_received.options.brachytherapy',
              value: 'brachytherapy',
            },
            {
              label:
                'questionnaire.questions.prostate.procedures.sub_questions.procedures_received.options.cranial_irradiation',
              value: 'cranial_irradiation',
            },
            {
              label:
                'questionnaire.questions.prostate.procedures.sub_questions.procedures_received.options.pelvic_irradiation',
              value: 'pelvic_irradiation',
            },
            {
              label: 'questionnaire.questions.prostate.procedures.sub_questions.procedures_received.options.hifu',
              value: 'hifu',
            },
            {
              label:
                'questionnaire.questions.prostate.procedures.sub_questions.procedures_received.options.laser_abalation',
              value: 'laser_abalation',
            },
            {
              label:
                'questionnaire.questions.prostate.procedures.sub_questions.procedures_received.options.microwave_abalation',
              value: 'microwave_abalation',
            },
          ],
          radioOptions: [
            {
              label: 'questionnaire.questions.prostate.procedures.sub_questions.procedures_received.options.other',
              value: 'other',
            },
          ],
        },
        conditions: [],
      },
      {
        q: 'Q70b',
        id: ID.procedure_date,
        type: MONTH_PICKER,
        section: SECTIONS.TREATMENT,
        bgIcon: '',
        order: 0,
        titleIconText: 'B',
        title: 'questionnaire.questions.prostate.procedures.sub_questions.procedure_date.title',
        subtitle: '',
        info: {
          title: '',
          content: '',
        },
        options: [],
        conditions: [],
        dateType: {
          type: dateType.stopTreatment,
        },
      },
      {
        q: 'Q70c',
        id: ID.procedure_outcome,
        type: SINGLE_OPTION_SELECT,
        section: SECTIONS.TREATMENT,
        bgIcon: '',
        order: 0,
        titleIconText: 'C',
        title: 'questionnaire.questions.prostate.procedures.sub_questions.procedure_outcome.title',
        subtitle: '',
        info: {
          title: '',
          content: '',
        },
        disableNextOnOptionSelect: false,
        options: [
          {
            label: 'questionnaire.questions.prostate.procedures.sub_questions.procedure_outcome.options.disease_free',
            value: 'disease_free',
          },
          {
            label:
              'questionnaire.questions.prostate.procedures.sub_questions.procedure_outcome.options.local_residual_disease',
            value: 'local_residual_disease',
          },
          {
            label:
              'questionnaire.questions.prostate.procedures.sub_questions.procedure_outcome.options.residual_systemic_disease',
            value: 'residual_systemic_disease',
          },
        ],
        answerResetable: true,
        conditions: [],
      },
    ],
  },
  {
    q: 'Q11a',
    id: ID.treatment_for_advanced_metastatic_disease,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.prostate.treatment_for_advanced_metastatic_disease.title',
    subtitle: 'questionnaire.questions.prostate.treatment_for_advanced_metastatic_disease.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: yesAndNoOptions,
    conditions: [
      {
        ifId: ID.treatment_for_prostate_cancer,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'no',
      },
      {
        ifId: ID.current_stage,
        type: CONTAIN_ONE_OF,
        ifValue: ['3', '4'],
      },
    ],
  },
  {
    q: 'Q60',
    id: ID.treatments,
    type: TREATMENT_HISTORY,
    section: SECTIONS.TREATMENT,
    bgIcon: '',
    order: 0,
    title: 'questionnaire.questions.prostate.treatments.title',
    footer: 'questionnaire.questions.prostate.treatments.footer',
    requiredSubQuestionsId: [ID.drugs_received],
    info: {
      title: '',
      content: '',
    },
    submitButtonLabel: 'questionnaire.questions.prostate.treatments.button.submit',
    options: [
      {
        iconText: 'A',
        label: 'questionnaire.questions.prostate.treatments.options.treatment_name',
        value: null,
      },
      {
        iconText: 'B',
        label: 'questionnaire.questions.prostate.treatments.options.course_received',
        value: null,
      },
      {
        iconText: 'C',
        label: 'questionnaire.questions.prostate.treatments.options.treatment_stopped',
        value: null,
      },
      {
        iconText: 'D',
        label: 'questionnaire.questions.prostate.treatments.options.stop_reason',
        value: null,
      },
    ],
    // shouldAutoOpenSubQuestions: [{
    //   ifId: ID.treatment_for_prostate_cancer,
    //   type: EQUAL_TO,
    //   ifValue: 'yes',
    // }],
    conditions: [
      {
        ifId: ID.treatment_for_prostate_cancer,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'no',
      },
    ],
    isRequired: true,
    subQuestions: [
      {
        q: 'Q60a',
        id: ID.drugs_received,
        type: OPTIONS_FROM_SERVER,
        viewType: OPTIONS_LIST_WITH_SHOW_MORE,
        section: SECTIONS.TREATMENT,
        bgIcon: i.treatment,
        order: 0,
        titleIconText: 'A',
        title: 'questionnaire.questions.prostate.treatments.sub_questions.drugs_received.title',
        subtitle: 'questionnaire.questions.prostate.treatments.sub_questions.drugs_received.subtitle',
        placeholder: 'questionnaire.questions.drugs_received.placeholder',
        info: {
          title: '',
          content: '',
        },
        questionProps: {
          isSearchableByOptionGroupName: true,
        },
      },
      {
        q: 'Q60f',
        id: ID.cycles,
        type: SINGLE_OPTION_SELECT,
        section: SECTIONS.TREATMENT,
        title: 'questionnaire.questions.prostate.treatments.sub_questions.cycles.title',
        disableNextOnOptionSelect: false,
        options: [...treatmentCyclesOptions],
      },
      {
        q: 'Q60b',
        id: ID.treatment_admin,
        type: SINGLE_OPTION_SELECT,
        section: SECTIONS.TREATMENT,
        bgIcon: i.treatment,
        order: 0,
        titleIconText: 'B',
        title: 'questionnaire.questions.prostate.treatments.sub_questions.treatment_admin.title',
        subtitle: '',
        info: {
          title: '',
          content: '',
        },
        disableNextOnOptionSelect: true,
        options: [
          {
            label: 'questionnaire.questions.prostate.treatments.sub_questions.treatment_admin.options.neoadjuvant',
            value: 'neoadjuvant',
          },
          {
            label: 'questionnaire.questions.prostate.treatments.sub_questions.treatment_admin.options.adjuvant',
            value: 'adjuvant',
          },
          {
            label: 'questionnaire.questions.prostate.treatments.sub_questions.treatment_admin.options.systemic',
            value: 'systemic',
          },
        ],
        conditions: [],
      },
      {
        q: 'Q60c',
        id: ID.treatment_end_date,
        type: MONTH_PICKER,
        section: SECTIONS.TREATMENT,
        bgIcon: '',
        order: 0,
        titleIconText: 'C',
        title: 'questionnaire.questions.prostate.treatments.sub_questions.treatment_end_date.title',
        subtitle: '',
        info: {
          title: '',
          content: '',
        },
        options: [],
        conditions: [],
        dateType: {
          type: dateType.stopTreatment,
        },
      },
      {
        q: 'Q60d',
        id: ID.treatment_stop_reason,
        type: SINGLE_OPTION_SELECT,
        section: SECTIONS.TREATMENT,
        bgIcon: '',
        order: 0,
        titleIconText: 'D',
        title: 'questionnaire.questions.prostate.treatments.sub_questions.treatment_stop_reason.title',
        subtitle: '',
        info: {
          title: '',
          content: '',
        },
        disableNextOnOptionSelect: false,
        options: [
          {
            label:
              'questionnaire.questions.prostate.treatments.sub_questions.treatment_stop_reason.options.treatment_ongoing',
            value: 'treatment_ongoing',
          },
          {
            label:
              'questionnaire.questions.prostate.treatments.sub_questions.treatment_stop_reason.options.end_of_protocol',
            value: 'end_of_protocol',
          },
          {
            label:
              'questionnaire.questions.prostate.treatments.sub_questions.treatment_stop_reason.options.progression',
            value: 'progression',
          },
          {
            label:
              'questionnaire.questions.prostate.treatments.sub_questions.treatment_stop_reason.options.no_response',
            value: 'no_response',
          },
          {
            label:
              'questionnaire.questions.prostate.treatments.sub_questions.treatment_stop_reason.options.loss_of_response',
            value: 'loss_of_response',
          },
          {
            label: 'questionnaire.questions.prostate.treatments.sub_questions.treatment_stop_reason.options.remission',
            value: 'remission',
          },
          {
            label: 'questionnaire.questions.prostate.treatments.sub_questions.treatment_stop_reason.options.toxicity',
            value: 'toxicity',
          },
          {
            label: 'questionnaire.questions.prostate.treatments.sub_questions.treatment_stop_reason.options.other_medical_conditions',
            value: 'other_medical_conditions',
          },
          {
            label: 'questionnaire.questions.prostate.treatments.sub_questions.treatment_stop_reason.options.dosing_issues',
            value: 'dosing_issues',
          },
          {
            label: 'questionnaire.questions.prostate.treatments.sub_questions.treatment_stop_reason.options.scheduling',
            value: 'scheduling',
          },
          {
            label: 'questionnaire.questions.prostate.treatments.sub_questions.treatment_stop_reason.options.travel_limitation',
            value: 'travel_limitation',
          },
          {
            label: 'questionnaire.questions.prostate.treatments.sub_questions.treatment_stop_reason.options.financial_reasons_or_insurance',
            value: 'financial_reasons_or_insurance',
          },
          {
            label: 'questionnaire.questions.prostate.treatments.sub_questions.treatment_stop_reason.options.other',
            value: 'other',
          },
        ],
        conditions: [],
      },
    ],
    // title: '',
    // info: {
    //   title: '',
    //   content: '',
    // },
    // footer: '',
    // type: TREATMENT_HISTORY,
    // section: SECTIONS.TREATMENT,
    // bgIcon: '',
    // order: 0,
    // conditions: [
    //   {
    //     ifId: ID.treatment_for_prostate_cancer,
    //     type: NOT_EQUAL_TO_OR_SKIPPED,
    //     ifValue: 'no',
    //   },
    // ],
    // subQuestionsIds: [
    //   ID.treatment_for_advanced_metastatic_disease,
    //   ID.drugs_received,
    //   ID.treatment_stop_reason,
    //   ID.treatment_admin,
    // ],
    stageIds: [ID.current_stage],
    // surgeryId: ID.had_procedure_for_prostate_cancer,
    // options: [],
    // subQuestionsOptions: {
    //   treatment_stop_reason: [
    //     {
    //       label:
    //         'questionnaire.questions.treatment_stop_reason.options.no_response',
    //       value: 'no_response',
    //     },
    //     {
    //       label:
    //         'questionnaire.questions.treatment_stop_reason.options.end_of_protocol',
    //       value: 'end_of_protocol',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.toxicity',
    //       value: 'toxicity',
    //     },
    //     {
    //       label:
    //         'questionnaire.questions.treatment_stop_reason.options.progression',
    //       value: 'progression',
    //     },
    //     {
    //       label:
    //         'questionnaire.questions.treatment_stop_reason.options.loss_of_response',
    //       value: 'loss_of_response',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.remission',
    //       value: 'remission',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.other',
    //       value: 'other',
    //     },
    //   ],
    //   taking_state: takingStateOptions,
    //   treatment_admin: [
    //     {
    //       label: 'questionnaire.questions.crc_2.treatments.sub_questions.treatment_admin.options.neoadjuvant',
    //       value: 'neoadjuvant',
    //     },
    //     {
    //       label: 'questionnaire.questions.crc_2.treatments.sub_questions.treatment_admin.options.adjuvant',
    //       value: 'adjuvant',
    //     },
    //     {
    //       label: 'questionnaire.questions.crc_2.treatments.sub_questions.treatment_admin.options.systemic',
    //       value: 'systemic',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatments.options.i_dont_know',
    //       value: 'i_dont_know',
    //     },
    //   ],
    // },
  },
  {
    q: 'Q44',
    id: ID.current_treatment_systemic_steroids,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.prostate.current_treatment_systemic_steroids.title',
    subtitle: 'questionnaire.questions.prostate.current_treatment_systemic_steroids.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.prostate.current_treatment_systemic_steroids.options.under_10_mg_daily',
        value: 'under_10_mg_daily',
      },
      {
        label: 'questionnaire.questions.prostate.current_treatment_systemic_steroids.options.over_10_mg_daily',
        value: 'over_10_mg_daily',
      },
      {
        label: 'questionnaire.questions.prostate.current_treatment_systemic_steroids.options.none',
        value: 'none',
        exclusive: true,
      },
    ],
    conditions: [],
    isRequired: true,
  },
  {
    q: 'Q44a',
    id: ID.systemic_steroids_treatment_end_date,
    type: MONTH_PICKER,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.prostate.systemic_steroids_treatment_end_date.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: '',
        value: '',
      },
    ],
    conditions: [
      {
        ifId: ID.current_treatment_systemic_steroids,
        type: CONTAIN_ONE_OF,
        ifValue: ['under_10_mg_daily', 'over_10_mg_daily'],
      },
    ],
    dateType: {
      type: dateType.stopTreatment,
    },
  },
  {
    q: 'Q45',
    id: ID.ecog,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.prostate.ecog.title',
    info: {
      title: 'questionnaire.questions.prostate.ecog.info.title',
      content: 'questionnaire.questions.prostate.ecog.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.prostate.ecog.options.0',
        value: '0',
      },
      {
        label: 'questionnaire.questions.prostate.ecog.options.1',
        value: '1',
      },
      {
        label: 'questionnaire.questions.prostate.ecog.options.2',
        value: '2',
      },
      {
        label: 'questionnaire.questions.prostate.ecog.options.3',
        value: '3',
      },
      {
        label: 'questionnaire.questions.prostate.ecog.options.4',
        value: '4',
      },
    ],
    conditions: [],
    isRequired: true,
  },
  {
    q: 'Q46',
    id: ID.other_medical_condition,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.prostate.other_medical_condition.title',
    subtitle: 'questionnaire.questions.prostate.other_medical_condition.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        ...noneOption,
        label: 'questionnaire.questions.prostate.other_medical_condition.options.no_other_health_issues',
      },
      {
        label: 'questionnaire.questions.prostate.other_medical_condition.options.liver',
        value: 'liver',
      },
      {
        label: 'questionnaire.questions.prostate.other_medical_condition.options.heart',
        value: 'heart',
      },
      {
        label: 'questionnaire.questions.prostate.other_medical_condition.options.kidney',
        value: 'kidney',
      },
      {
        label: 'questionnaire.questions.prostate.other_medical_condition.options.autoimmune',
        value: 'autoimmune',
      },
      {
        label: 'questionnaire.questions.prostate.other_medical_condition.options.mental',
        value: 'mental',
      },
      {
        label: 'questionnaire.questions.prostate.other_medical_condition.options.allergies',
        value: 'allergies',
      },
      {
        label: 'questionnaire.questions.prostate.other_medical_condition.options.chronic_infection_or_inflammation',
        value: 'chronic_infection_or_inflammation',
      },
      {
        label: 'questionnaire.questions.prostate.other_medical_condition.options.gastrointestinal',
        value: 'gastrointestinal',
      },
      {
        label: 'questionnaire.questions.prostate.other_medical_condition.options.other_cancer_type',
        value: 'other_cancer_type',
      },
      {
        label: 'questionnaire.questions.prostate.other_medical_condition.options.anemia',
        value: 'anemia',
      },
      {
        label: 'questionnaire.questions.prostate.other_medical_condition.options.thyroid',
        value: 'thyroid',
      },
      {
        label: 'questionnaire.questions.prostate.other_medical_condition.options.hypertension',
        value: 'hypertension',
      },
      {
        label: 'questionnaire.questions.prostate.other_medical_condition.options.hypotension',
        value: 'hypotension',
      },
      {
        label: 'questionnaire.questions.prostate.other_medical_condition.options.epilepsy',
        value: 'epilepsy',
      },
      {
        label: 'questionnaire.questions.prostate.other_medical_condition.options.diabetes',
        value: 'diabetes',
      },
      {
        label: 'questionnaire.questions.prostate.other_medical_condition.options.organ_transplant',
        value: 'organ_transplant',
      },
      {
        label: 'questionnaire.questions.prostate.other_medical_condition.options.extreme_obesity',
        value: 'extreme_obesity',
      },
      {
        label: 'questionnaire.questions.prostate.other_medical_condition.options.stroke',
        value: 'stroke',
      },
      {
        label: 'questionnaire.questions.prostate.other_medical_condition.options.lung',
        value: 'lung',
      },
      {
        label: 'questionnaire.questions.prostate.other_medical_condition.options.neuropathy',
        value: 'neuropathy',
      },
      {
        label: 'questionnaire.questions.prostate.other_medical_condition.options.bleeding_disorder',
        value: 'bleeding_disorder',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q48',
    id: ID.omc_liver,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.prostate.omc_liver.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.prostate.omc_liver.options.elevated_alt_ast',
        value: 'elevated_alt_ast',
      },
      {
        label: 'questionnaire.questions.prostate.omc_liver.options.elevated_bilirubin',
        value: 'elevated_bilirubin',
      },
      {
        label: 'questionnaire.questions.prostate.omc_liver.options.cirrhosis',
        value: 'cirrhosis',
      },
      {
        label: 'questionnaire.questions.prostate.omc_liver.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['liver'],
      },
    ],
  },
  {
    q: 'Q49',
    id: ID.omc_heart,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.prostate.omc_heart.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.prostate.omc_heart.options.class_at_least_nyha_ii',
        value: 'class_at_least_nyha_ii',
      },
      {
        label: 'questionnaire.questions.prostate.omc_heart.options.condition_at_least_grade_2',
        value: 'condition_at_least_grade_2',
      },
      {
        label: 'questionnaire.questions.prostate.omc_heart.options.cardiac_ventricular_arrhythmia',
        value: 'cardiac_ventricular_arrhythmia',
      },
      {
        label: 'questionnaire.questions.prostate.omc_heart.options.heart_failure',
        value: 'heart_failure',
      },
      {
        label: 'questionnaire.questions.prostate.omc_heart.options.lvef_under_50_percent',
        value: 'lvef_under_50_percent',
      },
      {
        label: 'questionnaire.questions.prostate.omc_heart.options.qtcf_abnormal',
        value: 'qtcf_abnormal',
      },
      {
        label: 'questionnaire.questions.prostate.omc_heart.options.angina',
        value: 'angina',
      },
      {
        label: 'questionnaire.questions.prostate.omc_heart.options.myocardial_infarction',
        value: 'myocardial_infarction',
      },
      {
        label: 'questionnaire.questions.prostate.omc_heart.options.tachycardia',
        value: 'tachycardia',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['heart'],
      },
    ],
  },
  {
    q: 'Q49a',
    id: ID.omc_myocardial_infarction_date,
    type: MONTH_PICKER,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.prostate.omc_myocardial_infarction_date.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: '',
        value: '',
      },
    ],
    conditions: [
      {
        ifId: ID.omc_heart,
        type: CONTAIN_ONE_OF,
        ifValue: ['myocardial_infarction'],
      },
    ],
    dateType: {
      type: dateType.stopTreatment,
    },
  },
  {
    q: 'Q50',
    id: ID.omc_stroke_date,
    type: MONTH_PICKER,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.prostate.omc_stroke_date.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: '',
        value: '',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['stroke'],
      },
    ],
    dateType: {
      type: dateType.stopTreatment,
    },
  },
  {
    q: 'Q51',
    id: ID.omc_kidney,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.prostate.omc_kidney.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.prostate.omc_kidney.options.low_glomerular_filtration_rate',
        value: 'low_glomerular_filtration_rate',
      },
      {
        label: 'questionnaire.questions.prostate.omc_kidney.options.dialysis',
        value: 'dialysis',
      },
      {
        label: 'questionnaire.questions.prostate.omc_kidney.options.kidney_stones',
        value: 'kidney_stones',
      },
      {
        label: 'questionnaire.questions.prostate.omc_kidney.options.creatinine_high',
        value: 'creatinine_high',
      },
      {
        label: 'questionnaire.questions.prostate.omc_kidney.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['kidney'],
      },
    ],
  },
  {
    q: 'Q52',
    id: ID.omc_autoimmune,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.prostate.omc_autoimmune.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.prostate.omc_autoimmune.options.congenital_or_acquired_immunosuppression',
        value: 'congenital_or_acquired_immunosuppression',
      },
      {
        label: 'questionnaire.questions.prostate.omc_autoimmune.options.hepatitis',
        value: 'hepatitis',
      },
      {
        label: 'questionnaire.questions.prostate.omc_autoimmune.options.granulomatous_disease',
        value: 'granulomatous_disease',
      },
      {
        label: 'questionnaire.questions.prostate.omc_autoimmune.options.lupus_disease',
        value: 'lupus_disease',
      },
      {
        label: 'questionnaire.questions.prostate.omc_autoimmune.options.scleroderma_disease',
        value: 'scleroderma_disease',
      },
      {
        label: 'questionnaire.questions.prostate.omc_autoimmune.options.calcinosis_disease',
        value: 'calcinosis_disease',
      },
      {
        label: 'questionnaire.questions.prostate.omc_autoimmune.options.raynaud_s_phenomenon_disease',
        value: 'raynaud_s_phenomenon_disease',
      },
      {
        label: 'questionnaire.questions.prostate.omc_autoimmune.options.esophageal_dysmotility_disease',
        value: 'esophageal_dysmotility_disease',
      },
      {
        label: 'questionnaire.questions.prostate.omc_autoimmune.options.sclerodactyly_disease',
        value: 'sclerodactyly_disease',
      },
      {
        label: 'questionnaire.questions.prostate.omc_autoimmune.options.telangiectasia_syndrome_disease',
        value: 'telangiectasia_syndrome_disease',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['autoimmune'],
      },
    ],
  },
  {
    q: 'Q53',
    id: ID.omc_mental,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.prostate.omc_mental.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.prostate.omc_mental.options.dementia',
        value: 'dementia',
      },
      {
        label: 'questionnaire.questions.prostate.omc_mental.options.neuropsychyatric',
        value: 'neuropsychyatric',
      },
      {
        label: 'questionnaire.questions.prostate.omc_mental.options.depression',
        value: 'depression',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['mental'],
      },
    ],
  },
  {
    q: 'Q54',
    id: ID.omc_allergy,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.prostate.omc_allergy.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.prostate.omc_allergy.options.interferon',
        value: 'interferon',
      },
      {
        label: 'questionnaire.questions.prostate.omc_allergy.options.metformin',
        value: 'metformin',
      },
      {
        label: 'questionnaire.questions.prostate.omc_allergy.options.vitd',
        value: 'vitd',
      },
      {
        label: 'questionnaire.questions.prostate.omc_allergy.options.penicillin',
        value: 'penicillin',
      },
      {
        label: 'questionnaire.questions.prostate.omc_allergy.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['allergies'],
      },
    ],
  },
  {
    q: 'Q55',
    id: ID.omc_chronic_infection_inflammation,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.prostate.omc_chronic_infection_inflammation.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.prostate.omc_chronic_infection_inflammation.options.hepatitis',
        value: 'hepatitis',
      },
      {
        label: 'questionnaire.questions.prostate.omc_chronic_infection_inflammation.options.hiv',
        value: 'hiv',
      },
      {
        label: 'questionnaire.questions.prostate.omc_chronic_infection_inflammation.options.other',
        value: 'other',
      },
      {
        label: 'questionnaire.questions.prostate.omc_chronic_infection_inflammation.options.uncontrolled_infection',
        value: 'uncontrolled_infection',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['chronic_infection_or_inflammation'],
      },
    ],
  },
  {
    q: 'Q58',
    id: ID.omc_gastro_problem,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.prostate.omc_gastro_problem.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.prostate.omc_gastro_problem.options.gastrointestinal_bleeding',
        value: 'gastrointestinal_bleeding',
      },
      {
        label: 'questionnaire.questions.prostate.omc_gastro_problem.options.crohns',
        value: 'crohns',
      },
      {
        label: 'questionnaire.questions.prostate.omc_gastro_problem.options.colitis',
        value: 'colitis',
      },
      {
        label: 'questionnaire.questions.prostate.omc_gastro_problem.options.ibs',
        value: 'ibs',
      },
      {
        label: 'questionnaire.questions.prostate.omc_gastro_problem.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['gastrointestinal'],
      },
    ],
  },
  {
    q: 'Q59',
    id: ID.omc_lung_disease,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.prostate.omc_lung_disease.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.prostate.omc_lung_disease.options.asthma',
        value: 'asthma',
      },
      {
        label: 'questionnaire.questions.prostate.omc_lung_disease.options.interstitial_lung_disease',
        value: 'interstitial_lung_disease',
      },
      {
        label: 'questionnaire.questions.prostate.omc_lung_disease.options.pneumonitis',
        value: 'pneumonitis',
      },
      {
        label: 'questionnaire.questions.prostate.omc_lung_disease.options.dyspnea',
        value: 'dyspnea',
      },
      {
        label: 'questionnaire.questions.prostate.omc_lung_disease.options.tuberculosis',
        value: 'tuberculosis',
      },
      {
        label: 'questionnaire.questions.prostate.omc_lung_disease.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['lung'],
      },
    ],
  },
  {
    q: 'Q56',
    id: ID.omc_abnormal_blood_results,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.prostate.omc_abnormal_blood_results.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.prostate.omc_abnormal_blood_results.options.low_wbc',
        value: 'low_wbc',
      },
      {
        label: 'questionnaire.questions.prostate.omc_abnormal_blood_results.options.low_hemoglobin',
        value: 'low_hemoglobin',
      },
      {
        label: 'questionnaire.questions.prostate.omc_abnormal_blood_results.options.low_neutrophil_count',
        value: 'low_neutrophil_count',
      },
      {
        label: 'questionnaire.questions.prostate.omc_abnormal_blood_results.options.low_platelet_count',
        value: 'low_platelet_count',
      },
      {
        label: 'questionnaire.questions.prostate.omc_abnormal_blood_results.options.low_potassium',
        value: 'low_potassium',
      },
      {
        label: 'questionnaire.questions.prostate.omc_abnormal_blood_results.options.low_magnesium',
        value: 'low_magnesium',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q47',
    id: ID.other_medications,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.prostate.other_medications.title',
    info: {
      title: 'questionnaire.questions.prostate.other_medications.info.title',
      content: 'questionnaire.questions.prostate.other_medications.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.prostate.other_medications.options.cyp3a4_inhibitors',
        value: 'cyp3a4_inhibitors',
      },
      {
        label: 'questionnaire.questions.prostate.other_medications.options.cyp3a4_inducers',
        value: 'cyp3a4_inducers',
      },
      {
        label: 'questionnaire.questions.prostate.other_medications.options.immunosuppressive_medications',
        value: 'immunosuppressive_medications',
      },
      {
        label: 'questionnaire.questions.prostate.other_medications.options.warfarin',
        value: 'warfarin',
      },
      {
        label: 'questionnaire.questions.prostate.other_medications.options.anticonvulsants',
        value: 'anticonvulsants',
      },
      {
        label: 'questionnaire.questions.prostate.other_medications.options.anti_retroviral_therapy',
        value: 'anti_retroviral_therapy',
      },
      {
        label: 'questionnaire.questions.prostate.other_medications.options.none',
        value: 'none',
        exclusive: true,
      },
    ],
    conditions: [],
  },
  initialDiagnosisDate('Q5'),
  userType,
  dateOfBirth,
  race,
  haveInsurance,
  insurance,
  preferredLocation,
  travelLimit,
];

export default Questions;
