import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class ProtectedRoute extends React.Component {
  shouldComponentUpdate(nextProps) {
    return !(
      this.props.personal.condition === nextProps.personal.condition
      && this.props.loggedIn === nextProps.loggedIn
      && this.props.component === nextProps.component
    );
  }

  render() {
    const {
      personal, loggedIn, component: Component, isProfileRequired = false, ...rest
    } = this.props;
    if (!(personal.condition || loggedIn) || (isProfileRequired && (!personal.patient_id || !personal.profile_id))) {
      return <Redirect to="/" />;
    }

    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }
}

const mapStateToProps = (state) => ({
  personal: state.profile.personal && state.profile.personal.condition ? state.profile.personal : false,
  loggedIn: !!(state.authentication && state.authentication.loggedIn && state.authentication.user),
});

ProtectedRoute.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  isProfileRequired: PropTypes.bool,
  personal: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  loggedIn: PropTypes.bool,
};

export default withRouter(connect(mapStateToProps)(ProtectedRoute));
