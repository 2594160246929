import React from 'react';
import './Preloader.css';
import Lottie from 'lottie-react';
import LottieAnimation from 'new-ui/assets/lottie/loader.json';
import PropTypes from 'prop-types';

const Preloader = (props) => {
  const {
    isVisible,
    // size = 0.6,
    zIndex = 2000,
    inline = false,
    height = '100%',
  } = props;
  return (
    <div className={`preloader ${isVisible && 'visible'} ${inline && 'inline-preloader'}`} style={{ zIndex, height }}>
      <div className="preloader-middle">
        <Lottie animationData={LottieAnimation} loop />
      </div>
    </div>
  );
};

Preloader.propTypes = {
  isVisible: PropTypes.bool,
  size: PropTypes.number,
  zIndex: PropTypes.number,
  inline: PropTypes.bool,
  height: PropTypes.string,
};

export default Preloader;
