import React from 'react';

import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';

import config from '../../../configs';
import './Map.css';

const defaultProps = {
  center: {
    lat: 40.9135,
    lng: -98.8325,
  },
  zoom: 4,
};

const createMapOptions = (maps) => ({
  zoomControl: true,
  fullscreenControl: false,
  zoomControlOptions: {
    position: maps.ControlPosition.LEFT_TOP,
  },
});

const Marker = (props) => {
  const { icon, onClick } = props;
  return (
    <div
      onClick={onClick}
      className="marker"
    >
      <img src={icon} alt="icon" />
    </div>
  );
};

const Map = (props) => {
  const { center, zoom, markers = [] } = props;

  if (center && center.lat) center.lat = Number(center.lat);
  if (center && center.lng) center.lng = Number(center.lng);

  return (
    <div className="map-container" dir='ltr'>
      <GoogleMapReact
        bootstrapURLKeys={{ key: config.googleMapReact.bootstrapURLKeys }}
        center={center || defaultProps.center}
        zoom={zoom || defaultProps.zoom}
        options={createMapOptions}
      >
        {markers.map((marker, key) => (
          <Marker
            key={key}
            {...marker}
          />
        ))}
      </GoogleMapReact>
    </div>
  );
};

Marker.propTypes = {
  icon: PropTypes.string,
  onClick: PropTypes.func,
};

Map.propTypes = {
  center: PropTypes.object,
  zoom: PropTypes.any,
  markers: PropTypes.array,
};

export default Map;
