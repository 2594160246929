import { checkIfEqualTo } from './checkIfEqualTo';
import { checkIfNotEqualTo } from './checkIfNotEqualTo';
import { checkIfContainsOneOf } from './checkIfContainsOneOf';
import { checkIfDoesntContainsOneOf } from './checkIfDoesntConainsOneOf';
import { ageLessThan } from './ageLessThan';
import { checkIfSkipped } from './checkIfSkipped';
import { checkIfStateValueIsTrue } from './checkIfStateValueIsTrue';
import { QUESTION_CONDITIONS } from '../constants';

export function conditionCheckByType(profile, condition, state) {
  switch (condition.type) {
    case QUESTION_CONDITIONS.EQUAL_TO:
      return checkIfEqualTo(profile, condition);
    case QUESTION_CONDITIONS.NOT_EQUAL_TO_OR_SKIPPED:
      return checkIfNotEqualTo(profile, condition, state);
    case QUESTION_CONDITIONS.CONTAIN_ONE_OF:
      return checkIfContainsOneOf(profile, condition);
    case QUESTION_CONDITIONS.CONTAINS_ONE_OF_OR_SKIPPED:
      return checkIfContainsOneOf(profile, condition) || checkIfSkipped(profile, condition);
    case QUESTION_CONDITIONS.DOESNT_CONTAIN_ONE_OF_OR_SKIPPED:
      return checkIfDoesntContainsOneOf(profile, condition);
    case QUESTION_CONDITIONS.AGE_LESS_THAN:
      return ageLessThan(profile, condition);
    case QUESTION_CONDITIONS.IS_SKIPPED:
      return checkIfSkipped(profile, condition);
    case QUESTION_CONDITIONS.IS_STATE_VALUE_TRUE:
      return checkIfStateValueIsTrue(state, condition);
    default:
      return new Error("Condition type couldn't be found");
  }
}
