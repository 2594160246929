import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import LightBulbIcon from 'new-ui/assets/icons/light_bulb.svg';
import CloseIcon from 'new-ui/assets/icons/close.svg';
import { conditionalClasses } from 'helpers';
import './InfoNote.css';

const InfoNote = ({ info, onClose, closed = false, isExpanded = true, onInfoBoxClick }) => {
  const [isClosed, setIsClosed] = useState(closed);
  const [expanded, setExpanded] = useState(isExpanded);

  useEffect(() => {
    setIsClosed(closed);
  }, [closed]);
  
  const closeNote = () => {
    setIsClosed(true);
    setExpanded(false);
    if (onClose) {
      onClose();
    }
  };

  const infoBoxClick = () => {
    if (onInfoBoxClick) {
      onInfoBoxClick();
    }
  }

  if (isClosed) {
    return null;
  };
  
  return (
    <div className='result-list-description tab-info'>
      <img className='list-description-bulb' src={LightBulbIcon} alt='light-bulb' />
      <span
        onClick={infoBoxClick}
        className={conditionalClasses('list-description-text', {
          expanded,
        })}>
        {info}
      </span>
      <img className='list-description-btn-close' src={CloseIcon} alt='close' onClick={closeNote} />
    </div>
  );
};

InfoNote.propTypes = {
  info: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  closed: PropTypes.bool,
  isExpanded: PropTypes.bool,
  onInfoBoxClick: PropTypes.func,
};

export default InfoNote;
