import React, { useState, useEffect } from 'react';

import { PAGES, URI } from 'new-ui/constants';
import Form, { FORM_INPUT_TYPES } from 'new-ui/Components/Form';
import Button from 'new-ui/Components/Button';
import validatePassword from 'functions/formValidations/validatePassword';

import * as queryString from 'query-string';
import { authenticationService } from 'components/Authentication/api';
import useRecaptcha from 'utils/hooks/useRecaptcha';
import { useTranslation } from 'utils/modifiedTranslation';
import { useHistory } from 'react-router-dom';

const resetPasswordForm = {
  inputs: {
    password: {
      type: FORM_INPUT_TYPES.PASSWORD,
      placeholder: 'New password',
    },
    repeatPassword: {
      type: FORM_INPUT_TYPES.PASSWORD,
      placeholder: 'Repeat password',
    },
  },
  data: {
    password: '',
    repeatPassword: '',
  },
};

const ResetPassword = (props) => {
  const [showError, setError] = useState(null);
  const [showSuccess, setSuccess] = useState(null);
  const { recaptcha, getRecaptchaTokenAsync } = useRecaptcha();
  const { t } = useTranslation();
  const history = useHistory();
  const { token } = queryString.parse(window.location.search);

  useEffect(() => {
    if (!token) history.push(URI[PAGES.LOGIN]);
  }, [history, token]);

  const resetPassword = async () => {
    const password = resetPasswordForm?.data?.password;
    const repeatPassword = resetPasswordForm?.data?.repeatPassword;

    if (!password) return setError('Please fill password');

    const passwordError = validatePassword(password);

    if (passwordError) return setError(t(passwordError));

    if (password !== repeatPassword) return setError('Please fill password');

    setError(null);

    const result = await authenticationService.resetPassword({
      password,
      repeatPassword,
      token,
      recaptchaToken: await getRecaptchaTokenAsync(),
    });

    if (!result || !result.success) {
      return setError(t('authentication.error.password_reset_failure'));
    }

    setSuccess(true);
  };

  return (
    <div className="login center">
      {recaptcha}
      <div className="reset-password">
        <div className="login-form">
          <div className="title">Reset Password</div>
          {showSuccess ? (
            <div>
              Password change success, you can now
              {' '}
              <span className="link" onClick={() => { history.push(`${URI[PAGES.LOGIN]}?type=email`); }}>login</span>
              {' '}
              with your new password
            </div>
          ) : (
            <div>
              <div className="text">
                Please create a new password for your account
              </div>
              <Form
                form={resetPasswordForm}
                onenter={resetPassword}
              />
              {showError ? <div className="login-error">{showError}</div> : null}
              <div className="login-button-holder">
                <Button action={resetPassword} title="Submit" />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
