export default treatments => {
  const defaultSelectedGroups = [];
  for (const treatment of treatments) {
    if (treatment.groups && treatment.groups.length) {
      for (const group of treatment.groups) {
        if (!defaultSelectedGroups.includes(group)) {
          defaultSelectedGroups.push(group);
        }
      }
    }
  }

  return defaultSelectedGroups;
};
