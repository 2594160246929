import React from 'react';
import './style.css';
import { useTranslation } from "utils/modifiedTranslation";
import Phase1 from 'new-ui/assets/icons/phase_1.svg';
import Phase2 from 'new-ui/assets/icons/phase_2.svg';
import Phase3 from 'new-ui/assets/icons/phase_3.svg';
import Phase4 from 'new-ui/assets/icons/phase_4.svg';

const phaseIcons = {
  1: Phase1,
  2: Phase2,
  3: Phase3,
  4: Phase4,
};

const Phases = ({ options }) => {
  const { t } = useTranslation();

  if (options.length === 0) return null;

  const firstOption = options[0];
  const lastOption = options[options.length - 1];

  return (
    <ul className='phases-list'>
      {options.length === 1 ? (
        <li key={firstOption}>
          <img src={phaseIcons[firstOption]} alt={`Phase ${firstOption}`} />
        </li>
      ) : (
        <>
          <li key={firstOption}>
            <img src={phaseIcons[firstOption]} alt={`Phase ${firstOption}`} />
          </li>
          <li className='dots'>...</li>
          <li key={lastOption}>
            <img src={phaseIcons[lastOption]} alt={`Phase ${lastOption}`} />
          </li>
        </>
      )}
      <span>{t("list_item.label.phase")}</span>
    </ul>
  );
};

export default Phases;