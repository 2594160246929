/**
 * List of possible error codes which is can be returned by server side
 */

export default {
  /**
   * Means attempt to modify previous/inactive profile rejected by server side
   */
  INACTIVE_PROFILE_ERROR: 'InactiveProfileUpdateError',
  USER_EXISTS: 'userAlreadyExists',
};
