import React, { useEffect, useState } from 'react';
import './TravelingPreferences.css';

import {
  PopupTitle, PopupContent, PopupButton, PopupSubtitle,
} from 'new-ui/Components/Popup';

import { useTranslation } from 'utils/modifiedTranslation';

import app from 'new-ui/app';
import Provider from 'new-ui/Components/Provider/Provider';
import Form from 'new-ui/Components/Form';
import { AUDIT_COMMENTS } from 'new-ui/constants';

const TravelingPreferences = ({ closePopup }) => {
  const { t } = useTranslation();
  const { coniguration, user } = app;
  const [disabled, setDisabled] = useState(true);
  const [form, setForm] = useState(null);
  const distance = user.personal?.travel_limit || 100;
  useEffect(() => {
    setForm({
      inputs: {
        TravelingPreferences: {
          type: 'radio',
          options: [
            {
              value: 'YES',
              title: t('popup.traveling_preferences.yes_option'),
            },
            {
              value: 'NO',
              title: t('popup.traveling_preferences.no_option', {
                PARTNER_NAME: coniguration?.providerSpecific?.name,
              }),
            },
          ],
        },
      },
      data: {
        TravelingPreferences: '',
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (data) => {
    setDisabled(data.TravelingPreferences === '');
  };

  const save = () => {
    if (form.data.TravelingPreferences === 'YES') {
      closePopup();
      Provider.detach(AUDIT_COMMENTS.TRAVELING_PREFERENCES);
    }
    if (form.data.TravelingPreferences === 'NO') {
      closePopup();
    }
  };
  return (
    <div className="traveling-preferences">
      <PopupTitle title={t('popup.traveling_preferences.title')} />
      <PopupSubtitle subtitle={t('popup.traveling_preferences.subtitle', {
        PARTNER_NAME: coniguration?.providerSpecific?.name,
        INDICATION_NAME: user.personal.condition,
        DISTANCE: distance === 'none' ? 100 : distance,
      })}
      />
      <PopupContent>
        {form && <Form onChange={onChange} inputs={form.inputs} data={form.data} />}
      </PopupContent>
      <PopupButton disabled={disabled} title={t('popup.traveling_preferences.btn')} action={save} />
    </div>
  );
};

export default TravelingPreferences;
