import React, { useState, useEffect, useRef } from 'react';
import './SearchSelectBox.css';
import ArrowDown from 'new-ui/assets/icons/nav_item_arrow.svg';
import CloseIcon from 'new-ui/assets/icons/close.svg';
import MagnifyingIcon from 'new-ui/assets/icons/mag-icon.svg';

import PropTypes from 'prop-types';

const SearchSelectBox = (props)=>{
  const {
    title,
    items,
    onSelect,
    onClose,
    placeholder = 'Select from the list...',
  } = props;

  const [collapsed, setCollapsed] = useState(false);
  const [_items, setItems] = useState(items);
  const [search, setSearch] = useState('');

  const $input = useRef();

  useEffect(()=>{
    setTimeout(()=>{
      if ($input?.current) $input.current.focus();
    }, 100);
    // eslint-disable-next-line
  }, []);

  const getItems = ()=>{
    return search ? _items.filter((a)=>{
      return a.title.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    }) : _items;
  };

  return (
    <div className={`search-select-box ${collapsed ? 'search-select-box-collapsed' : ''}`}>
      <div className="search-select-box-title">
        <div className="search-select-box-title-header">
          {title}
        </div>
        {
          onClose ? (
            <div className="search-select-box-title-close" onClick={onClose}>
              <img src={CloseIcon} alt="icon" />
            </div>
          ) : (
            <div
              className="search-select-box-title-collapser"
              onClick={()=>{
                setCollapsed(!collapsed);
              }}
            >
              <img src={ArrowDown} alt="collapser" />
            </div>
          )
        }
      </div>
      <div className="search-select-box-items">
        <div className="search-select-box-items-search">
          <div className="search-select-box-items-search-input">
            <input
              ref={$input}
              placeholder={placeholder}
              onChange={($ev)=>{
                setSearch($ev.target.value);
              }}
            />
          </div>
          <div className="search-select-box-items-search-icon">
            <img src={MagnifyingIcon} alt="icon2" />
          </div>
        </div>
        <div className="search-select-box-items-search-list">
          {
            _items ? getItems().map((item, k)=>{
              return (
                <div
                  key={k}
                  className={`search-select-box-items-search-list-item ${item.selected ? 'search-select-box-items-search-list-item-selected' : ''}`}
                  onClick={()=>{
                    item.selected = !item.selected;
                    if (onSelect) onSelect(item);
                    setItems([..._items]);
                  }}
                >
                  <div className="search-select-box-items-search-list-item-bullet" />
                  <div className="search-select-box-items-search-list-item-title">
                    {item.title}
                  </div>
                </div>
              );
            }) : null
          }
        </div>
      </div>
    </div>
  );
};

SearchSelectBox.propTypes = {
  title: PropTypes.any,
  items: PropTypes.any,
  onSelect: PropTypes.any,
  onClose: PropTypes.any,
  placeholder: PropTypes.any,
};

export default SearchSelectBox;
