export default {
  showTreatmentFinder: true,
  showLocationQuestion: true,
  showDistanceQuestion: true,
  showRaceQuestion: true,
  showChatBot: true,
  showMatchesDetailTab: true,
  showChangeLocation: true,
  showMatchesTalkToOncologistButton: true,
  openTrialInDetailsTab: true,
  showDownloadTrialList: true,
  showUpdateMedicalCondition: true,
  showLastMatchesAlert: true,
  showNextButton: true,
  showSkipButton: true,
};
