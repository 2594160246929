import React from 'react';
import './NewMatches.css';
import app from 'new-ui/app';
import Button from 'new-ui/Components/Button';

const NewMatchesPopup = ()=>{
  const results = app.numResults(true);
  return (
    <div className="new-matches-popup padding-20">
      <div className="new-matches-popup-title">
        {`${results} Personalized Results`}
      </div>
      <div className="new-matches-popup-text">
        {`After editing your medical profile you are now matched to ${results} treatment options`}
      </div>
      <div className="new-matches-popup-button">
        <Button title="View Results" action={()=>{ app.setPopup(false); }} />
      </div>
    </div>
  );
};

export default NewMatchesPopup;
