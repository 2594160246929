import React, { useState, useEffect } from 'react';
import { useTranslation } from 'utils/modifiedTranslation';
import './Results.css';

import app from 'new-ui/app';

import ResultList from 'new-ui/Results/ResultList';
import Action from 'new-ui/Widgets/Action';
import {
  ACTION_TYPES, URI, PAGES, POPUP_TYPES, SUBTYPE_FIELD_BY_CONDITION,
} from 'new-ui/constants';
import TextWidget from 'new-ui/Widgets/Text';
import { OpenInNewTab } from 'new-ui/Components/OpenInNewTab';
import ProfileResultsIcon from 'new-ui/assets/icons/profile_results.svg';
import { PFIZER } from 'utils/constants/healtProviders';

import { indicationChooser } from 'components/Questionnaire/functions';
import {
  isCallUsMode, isCrmChatAllowed, isCurrentTimeInESTWorkingRange, getIndicationDetailsTitle,
} from 'new-ui/functions';
import StartupFlow from 'new-ui/Flows/startup';
import CrmChat from '../../modules/crmChat';
import { authenticationService } from '../../components/Authentication/api';

let started = false;

const Results = () => {
  const { t } = useTranslation();
  const {
    trials = [], treatments = [], treatmentsCategories = [], user,
  } = app;
  const diseasesSupportSOC = app?.coniguration?.drugIntegrations || [];
  const hasPartnerResult = user?.personal?.providerCode && user.providersPopup?.hasPartnerResult;
  const condition = user?.personal?.condition;
  const [showSkeleton, setShowSkeleton] = useState(!app?.trials?.length);
  const [crmChatVisible, setCrmChatVisible] = useState(
    isCrmChatAllowed()
    && (isCallUsMode() ? app.callUsPopupPopped : true),
  );

  useEffect(()=>{
    // setShowSkeleton(false);
    app.onupdatestart = ()=>{
      setShowSkeleton(true);
    };
    app.onupdateend = ()=>{
      setShowSkeleton(false);
      if (!started) {
        StartupFlow();
        started = true;
      }
    };
    return ()=>{
      delete app.onupdatestart;
      delete app.onupdateend;
    };
  }, []);

  app.setCrmChatVisible = setCrmChatVisible;

  const openMedicalProfile = () => app.setPopup(POPUP_TYPES.MEDICAL_PROFILE);

  const getDetails = () => {
    const disease_status = user?.condition_profile?.disease_status;
    const ecog = user?.condition_profile?.ecog;
    const subDisease = user?.condition_profile?.type_of_ca;

    const subtype = SUBTYPE_FIELD_BY_CONDITION[condition]
      ? user?.condition_profile[SUBTYPE_FIELD_BY_CONDITION[condition]]
      : null;

    let subtypeText = '';

    const stage = '';

    const indication = indicationChooser(condition);

    if (subtype) {
      const subtypeQuestion = indication.filter((q) => q.id === SUBTYPE_FIELD_BY_CONDITION[condition])[0];
      if (subtypeQuestion && subtypeQuestion.options) {
        const option = subtypeQuestion.options.filter((o) => o.value === subtype)[0];
        if (option) subtypeText = t(option.label);
      }
    }

    const details = [];

    if (condition) details.push(getIndicationDetailsTitle(condition, subDisease));
    if (subtypeText) details.push(subtypeText);
    if (disease_status) details.push(t(`medical_profile.disease_status.global.${disease_status}`));
    if (ecog !== undefined && ecog !== null) details.push(`Activity level ${ecog}`);
    if (stage) details.push(stage);
    return details.map((i, k) => <p key={k}>{i}</p>);
  };

  const renderAllOptions = () => (hasPartnerResult && (
    <Action
      className="see-all-options"
      type={ACTION_TYPES.SEE_ALL_OPTIONS}
      hrefOnClick={() => {
        OpenInNewTab();
      }}
      href={`${URI[PAGES.RESULTS]}?detach=1&rematch=1`}
      key="action"
    />
  ));

  return (
    <div className="results">
      {app.provider?.code === PFIZER && trials.length ? <TextWidget text={t('provider.note', { number: trials.length })} /> : null}
      <div className="grid">
        {app.provider && !trials.length && !treatments.length
          ? (
            <>
              {renderAllOptions()}
            </>
          ) : (
            <>
              <ResultList
                trials={trials}
                treatments={treatments}
                treatmentsCategories={treatmentsCategories}
                showSkeleton={showSkeleton}
                stickyHeader
              />
              {renderAllOptions()}
            </>
          )}
      </div>
      <div
        className={`results-details-header ${!diseasesSupportSOC.includes(condition) && trials.length ? '' : ''}`}
      >
        <div className="results-header-name">
          <img
            src={ProfileResultsIcon}
            alt={t('navbar.menu.item.medical_profile')}
          />
          {t('results.header')}
        </div>
        <div className="results-header-details">
          {' '}
          {getDetails()}
        </div>
        {trials.length || treatments.length ? (
          <span className="results-see-more" onClick={openMedicalProfile}>
            {t('results.header_see_more')}
          </span>
        ) : null}
      </div>
      <CrmChat
        defer
        visible={crmChatVisible && isCurrentTimeInESTWorkingRange()}
        getToken={authenticationService.getCrmChatAuthToken}
      />
    </div>
  );
};

export default Results;
