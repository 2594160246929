export const Profile = {
  condition: 'condition',
  dob: 'dob',
  gender: 'gender',
  race: 'race',
  pregnant: 'pregnant',
  breastfeeding: 'breastfeeding',
  current_disease_status: 'current_disease_status',
  diagnosis_stage: 'diagnosis_stage',
  type_of_crc: 'type_of_crc',
  type_of_familial_cancer: 'type_of_familial_cancer',
  metastasis_location_1: 'metastasis_location_1',
  metastasis_location_2: 'metastasis_location_2',
  metastasis_location_3: 'metastasis_location_3',
  metastasis_location_4: 'metastasis_location_4',
  metastasis_in_brain: 'metastasis_in_brain',
  is_brain_metastasis_leptomeningeal: 'is_brain_metastasis_leptomeningeal',
  is_brain_metastasis_active: 'is_brain_metastasis_active',
  have_biopsy: 'have_biopsy',
  biomarker1: 'biomarker1',
  biomarker2: 'biomarker2',
  biomarker3: 'biomarker2',
  tumor_meausrable: 'tumor_meausrable',
  tumor_size: 'tumor_size',
  metastatic_disease_measurable: 'metastatic_disease_measurable',
  primary_tumor_unresectable: 'primary_tumor_unresectable',
  metastases_unresectable: 'metastases_unresectable',
  distance_from_anal_verge: 'distance_from_anal_verge',
  received_any_treatments: 'received_any_treatments',
  undergo_surgery: 'undergo_surgery',
  surgery_date: 'surgery_date',
  status_after_surgery: 'status_after_surgery',
  received_immunotherapy_treatment: 'received_immunotherapy_treatment',
  immunotheraphy_type_1: 'immunotheraphy_type_1',
  immunotheraphy_type_2: 'immunotheraphy_type_2',
  immunotheraphy_type_3: 'immunotheraphy_type_3',
  immunotheraphy_treatment_end_date: 'immunotheraphy_treatment_end_date',
  immunotherapy_treatment_stop_reason: 'immunotherapy_treatment_stop_reason',
  received_target_drug_treatment: 'received_target_drug_treatment',
  target_drug_category_1: 'target_drug_category_1',
  target_drug_category_2: 'target_drug_category_2',
  target_drug_category_3: 'target_drug_category_3',
  target_drug_treatment_end_date: 'target_drug_treatment_end_date',
  target_drug_treatment_stop_reason: 'target_drug_treatment_stop_reason',
  received_chemotherapy_treatment_administration_neoadjuvant:
    'received_chemotherapy_treatment_administration_neoadjuvant',
  received_chemotherapy_treatment_administration_adjuvant: 'received_chemotherapy_treatment_administration_adjuvant',
  received_chemotherapy_treatment_administration_advanced_disease:
    'received_chemotherapy_treatment_administration_advanced_disease',
  chemotherapy_treatment_administration_advanced_disease_type_1:
    'chemotherapy_treatment_administration_advanced_disease_type_1',
  chemotherapy_treatment_administration_advanced_disease_type_2:
    'chemotherapy_treatment_administration_advanced_disease_type_2',
  chemotherapy_treatment_administration_advanced_disease_type_3:
    'chemotherapy_treatment_administration_advanced_disease_type_3',
  chemotherapy_treatment_administration_advanced_disease_type_4:
    'chemotherapy_treatment_administration_advanced_disease_type_4',
  chemotherapy_treatment_administration_advanced_disease_type_5:
    'chemotherapy_treatment_administration_advanced_disease_type_5',
  chemotherapy_lines_for_advanced_disease: 'chemotherapy_lines_for_advanced_disease',
  adjuvant_type: 'adjuvant_type',
  chemotherapy_treatment_administration_advanced_disease_end_date:
    'chemotherapy_treatment_administration_advanced_disease_end_date',
  chemotherapy_treatment_administration_advanced_disease_stop_reas:
    'chemotherapy_treatment_administration_advanced_disease_stop_reas',
  received_ablation_or_embolization_treatment: 'received_ablation_or_embolization_treatment',
  status_after_ablation_or_embolization: 'status_after_ablation_or_embolization',
  received_investigational_therapy_treatment: 'received_investigational_therapy_treatment',
  investigational_therapy_treatment_end_date: 'investigational_therapy_treatment_end_date',
  combo_chemotherapy_targeted_drug_chemotherapy_type: 'combo_chemotherapy_targeted_drug_chemotherapy_type',
  combo_chemotherapy_targeted_drug_targeted_drug_type: 'combo_chemotherapy_targeted_drug_targeted_drug_type',
  received_radiation_treatment: 'received_radiation_treatment',
  radiation_treatment_end_date: 'radiation_treatment_end_date',
  prior_treatment_for_metastatic_disease: 'prior_treatment_for_metastatic_disease',
  treatment_lines_received_for_advanced_disease: 'treatment_lines_received_for_advanced_disease',
  other_medical_condition_1: 'other_medical_condition_1',
  other_medical_condition_2: 'other_medical_condition_2',
  other_medical_condition_3: 'other_medical_condition_3',
  is_pregnant_or_breastfeeding: 'is_pregnant_or_breastfeeding',
  ecog: 'ecog',
  ngs: 'ngs',
  karnofsky_score: 'karnofsky_score',
  other_medications: 'other_medications',
  liver_problem: 'liver_problem',
  heart_problem: 'heart_problem',
  stroke_date: 'stroke_date',
  kidney_problem: 'kidney_problem',
  autoimmune_condition: 'autoimmune_condition',
  mental_disorder: 'mental_disorder',
  infection_or_inflammation: 'infection_or_inflammation',
  blood_test: 'blood_test',
  gastro_issue: 'gastro_issue',
  kras_mut: 'kras_mut',

  // multi attributes:
  metastasis_locations: 'metastasis_locations',
  other_medical_conditions: 'other_medical_conditions',
  chemotherapy_treatment_administration_advanced_disease_types:
    'chemotherapy_treatment_administration_advanced_disease_types',
  target_drug_categories: 'target_drug_categories',
  immunotheraphy_types: 'immunotheraphy_types',
  allergies: 'allergies',
};
