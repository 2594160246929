export const Profile = {
  user_type: 'user_type',
  disease_status: 'disease_status',
  dob: 'dob',
  gender: 'gender',
  race: 'race',
  brain_cancer_type: 'brain_cancer_type',
  tumor_surgery: 'tumor_surgery',
  last_surgery_date: 'last_surgery_date',
  measurable_tumor: 'measurable_tumor',
  brain_tumor_size_mm: 'brain_tumor_size_mm',
  brain_cancer_characteristics: 'brain_cancer_characteristics',
  has_biopsy: 'has_biopsy',
  mgmt_status: 'mgmt_status',
  braf_mutation: 'braf_mutation',
  brain_radiation: 'brain_radiation',
  brain_radiation_date: 'brain_radiation_date',
  treatment_for_brain_cancer: 'treatment_for_brain_cancer',
  tmx_rad_candidate: 'tmx_rad_candidate',
  drugs_received: 'drugs_received',
  cycles: 'cycles',
  treatment_end_date: 'treatment_end_date',
  treatment_stop_reason: 'treatment_stop_reason',
  intratumoral_therapy: 'intratumoral_therapy',
  ecog: 'ecog',
  pd_l1_carrier_status: 'pd_l1_carrier_status',
  pd_l1_expression: 'pd_l1_expression',
  ngs: 'ngs',
  karnofsky: 'karnofsky',
  other_medical_condition: 'other_medical_condition',
  omc_liver: 'omc_liver',
  omc_heart: 'omc_heart',
  omc_stroke_date: 'omc_stroke_date',
  omc_kidney: 'omc_kidney',
  omc_autoimmune: 'omc_autoimmune',
  omc_mental: 'omc_mental',
  omc_allergy: 'omc_allergy',
  omc_chronic_infection_inflammation: 'omc_chronic_infection_inflammation',
  omc_gastro_problem: 'omc_gastro_problem',
  omc_lung_disease: 'omc_lung_disease',
  omc_abnormal_blood_results: 'omc_abnormal_blood_results',
  other_medications: 'other_medications',
  is_pregnant: 'is_pregnant',
  is_breastfeeding: 'is_breastfeeding',
  treatments: 'treatments',
  have_insurance: 'have_insurance',
  insurances: 'insurances',
};
