import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { MuiThemeProvider } from '@material-ui/core/styles';
import AppRoutes from './components/AppRoutes';
import ErrorBoundary from './components/ErrorBoundary';
import { history } from './functions';
import customMuiTheme from './utils/constants/customMuiTheme';
import { initI18n } from './utils/actions/intl';
import AppTranslationWrapper from './components/AppTranslationWrapper';

initI18n();

export default () => (
  <ErrorBoundary>
    <MuiThemeProvider theme={customMuiTheme}>
      <ConnectedRouter history={history}>
        <AppTranslationWrapper>
          <AppRoutes />
        </AppTranslationWrapper>
      </ConnectedRouter>
    </MuiThemeProvider>
  </ErrorBoundary>
);
