import React, { useRef, useState } from 'react';
import './TextInput.css';
import PropTypes from 'prop-types';
import Button from 'new-ui/Components/Button';
import { useTranslation } from 'utils/modifiedTranslation';
import { QuestionTitle } from '../..';

const TextInput = (props)=>{
  const {
    q,
    user,
    onselect,
    initValue,
    showTitle = false,
  } = props;
  // eslint-disable-next-line
  const [value, setValue] = useState(q.getQuestionValue ? q.getQuestionValue(user) : (initValue || ''));
  const { t } = useTranslation();
  const $input = useRef(null);
  return (
    <div className="text-input-question">
      { (showTitle || q?.showTitle) ? <QuestionTitle q={q} /> : null}
      <div className="text-input-question-input">
        <input
          ref={$input}
          defaultValue={value}
        />
      </div>
      <Button
        title={t('general.next')}
        action={()=>{
          onselect($input.current.value);
        }}
      />
    </div>
  );
};

TextInput.propTypes = {
  q: PropTypes.any,
  user: PropTypes.any,
  onselect: PropTypes.func,
  initValue: PropTypes.any,
  showTitle: PropTypes.bool,
};

export default TextInput;
