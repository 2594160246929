import { DOM } from 'new-ui/functions';
import { URI, PAGES } from 'new-ui/constants';
import './OpenInNewTab.css';

export const OpenInNewTab = () => {
  const { t } = window;
  const $oint = DOM('.open-in-new-tab', {
    '/.oint-window': {
      '/.oint-text': {
        '/.oint-text-title': {
          innerHTML: t('oint.title'),
        },
        '/.oint-text-description': {
          innerHTML: t('oint.description'),
        },
      },
      '/.oint-button': {
        '/a.oint-text-button.button': {
          innerHTML: t('oint.button'),
          href: URI[PAGES.RESULTS],
          target: '_blank',
        },
      },
    },
  });
  document.body.appendChild($oint);
};

export const preventIframeInteraction = () => {
  if (window.self !== window.top) {
    OpenInNewTab();
  }
};
