export const flashIcon = (icon, $prop) => {
  const $side = $prop.querySelector('.popup-property-side');
  $prop.removeChild($side);
  const $img = new Image();
  $img.className = 'v-mark blink';
  $img.src = icon;
  $prop.appendChild($img);
  setTimeout(() => {
    $prop.removeChild($img);
    $prop.classList.remove('highlighted');
    $prop.appendChild($side);
  }, 6000);
};