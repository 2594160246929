import React from "react";
import "./style.css";
import ShareButton from "../ShareButton";
import TrashIcon from 'new-ui/assets/icons/trash_orange.svg';
import TooltipButton from '../Patientlist/TooltipButton';

const ManageColleagueCard = ({colleague_email='colleague@colleague.com', onArchiveClick}) => {
  return (
    <div className="manage-card">
      <h2>Manage Colleague </h2>
      <div className="colleague"> 
        <div className="colleague-info">
          <div className="title">Colleague name</div>
          <div className="email">{colleague_email}</div>
        </div>
        <div className="actions">
          <TooltipButton icon={TrashIcon} title={'Archive'} onClick={onArchiveClick} />
        </div>
      </div>
      <ShareButton onClick={()=>{}} />
    </div>
  );
};

export default ManageColleagueCard;