import send from './send';
import { eventsKeys } from '../constants';

const bannerClick = () => {
  send(eventsKeys.BANNER_CLICk);
};

const brochureDownloaded = () => {
  send(eventsKeys.BROSHURE_DOWNLOADED);
};

const openForm = () => {
  send(eventsKeys.OPEN_FORM);
};

const formSent = () => {
  send(eventsKeys.FORM_SENT);
};

export default {
  bannerClick,
  brochureDownloaded,
  openForm,
  formSent,
};
