import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import progressSrc from './progress.svg';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

export const SimpleBackdrop = () => {
  const classes = useStyles();

  return (
    <Backdrop
      open
      className={classes.backdrop}
    >
      <img src={progressSrc} alt="progress" />
    </Backdrop>
  );
};
