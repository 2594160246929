import React, { useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import './NextAppointment.css';

import { INTERCOM_EVENTS, POPUP_TYPES } from 'new-ui/constants';

import { useTranslation } from 'utils/modifiedTranslation';
import { getSurvey, setSurvey } from 'components/Surveys/api';

import Form from 'new-ui/Components/Form';
import { PopupTitle, PopupContent, PopupButton } from 'new-ui/Components/Popup';

import app from 'new-ui/app';
import useForceUpdate from 'use-force-update';

import { ALERT_TYPES } from 'new-ui/Components/Alert';

const SURVEY_NAME = 'nextAppointment';

let renderKey = 1;

const dateFormatter = (date = new Date()) => moment(date).format('MM/DD/YYYY');

const NextAppointment = (props) => {
  const { closePopup, source } = props;
  const [form, setForm] = useState(false);
  const { t } = useTranslation();
  const { user_wix_id } = app?.user?.info;
  const update = useForceUpdate();

  useEffect(() => {
    const fetch = async () => {
      const r = await getSurvey(SURVEY_NAME, app?.user?.info.user_wix_id);
      const nextAppointment = r?.data?.answers[0]?.value || '';

      const formData = {
        inputs: {
          nextAppointment: {
            type: 'date',
            minDate: new Date(),
            default: 'MM/DD/YYYY',
            formatter: dateFormatter,
          },
          notScheduled: {
            type: 'radio',
            noHeader: true,
            options: [
              {
                value: true,
                title: t('next_appointment.not_scheduled'),
              },
            ],
          },
        },
        data: {
          nextAppointment,
          notScheduled: null,
        },
      };

      setForm(formData);
    };

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const send = async () => {
    const answers = [
      {
        surveyKey: 'general',
        key: 'nextAppointment',
        value: form.data.nextAppointment,
      },
    ];
    try {
      await setSurvey(SURVEY_NAME, answers, user_wix_id);
      app.setConfiguration({
        lastNextAppointment: form.data.nextAppointment ? new Date().toISOString() : null,
      });
      if (source) app.intercom.sendEvent(INTERCOM_EVENTS.NEXT_APPOINTMENT.POPUP_SUCCESS, { source });
      app.intercom.update({
        user: app.user,
        data: {
          next_appointment_date: form.data.nextAppointment
            ? Math.floor(new Date(form.data.nextAppointment).getTime() / 1000)
            : null,
        },
      });
      app.renderPage();
      closePopup();
      app.alert([
        <div key="alert">
          {form.data.nextAppointment ? t('next_appointment.your_next_appointment', {
            date: dateFormatter(form.data.nextAppointment),
          }) : t('next_appointment.no_next_appointment')}
          {' '}
          <span
            className="edit-button"
            onClick={() => {
              app.alertHide();
              app.setPopup(POPUP_TYPES.NEXT_APPOINTMENT);
            }}
          >
            {t('medical_profile.edit')}
          </span>
        </div>,
      ]);
    } catch (e) {
      app.alert('Oops, something was wrong with saving the data!', ALERT_TYPES.ERROR);
    }
  };

  const onChange = (data, inputName) => {
    renderKey += 1;
    if (inputName === 'nextAppointment') {
      form.data.notScheduled = false;
    } else if (form.data.notScheduled) form.data.nextAppointment = '';
    update();
    // document.querySelector('input[type="date"]').value = '';
  };

  return (
    <div className="next-appointment">
      <PopupTitle title={t('next_appointment.title')} />
      <PopupContent>
        <div className="next-appointment-description">
          {t('next_appointment.description')}
        </div>
        {form ? <Form key={renderKey} form={form} onChange={onChange} popupMode /> : null}
      </PopupContent>
      <PopupButton isVisible={form?.data?.nextAppointment || form?.data?.notScheduled} title={t('share_with_doctor.send')} action={send} />
    </div>
  );
};

NextAppointment.propTypes = {
  closePopup: PropTypes.func,
  source: PropTypes.string,
};

export default NextAppointment;
