import React from 'react';
import DrugIcon from 'new-ui/assets/icons/drug.svg';
import CellIcon from 'new-ui/assets/icons/cell.svg';
import './style.css';
import { useTranslation } from "utils/modifiedTranslation";

const TypeLabel = ({ item }) => {
  const { t } = useTranslation();

  let icon = null;
  let label = null;

  if (item.trialType === 'drug') {
    icon = DrugIcon;
    label = t("list_item.label.treatment");
  } else {
    icon = CellIcon;
    label = t("list_item.label.trial");
  }

  return (
    <div className='type-label'>
      <img src={icon} alt='icon' />
      <span className='title'>{label}</span>
    </div>
  );
};

export default TypeLabel;