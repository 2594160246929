/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
import { batch } from 'react-redux';
import * as ReactRouter from 'connected-react-router';
import * as queryString from 'query-string';
import MATCHES_CONSTANTS, { MATCH_FLOW } from 'components/Matches/constants';
import { clearMatchesMenu } from 'modules/matchesMenu';
import { providerActions } from 'components/Provider/actions';
import GEO_CONSTANTS from 'utils/constants/geo';
import MAIN_CONSTANTS from 'utils/constants/main';
import PARTNER_CONSTANTS from 'utils/constants/partner';
import INTERNAL_CONSTANTS from 'utils/constants/internal';
import { ROUTE_NAME, ROUTE_PATH } from 'components/AppRoutes/constants';
import { appConfigurationActions } from 'components/AppConfiguration/actions';
import RESPONSE_ERROR_CONSTANTS from 'utils/constants/responseError';
import SERVER_STATUS_CONSTANTS from 'utils/constants/serverStatus';
import GLOBAL_CONSTANTS from 'utils/constants/global';
import LOCAL_STORAGE_KEYS from 'utils/constants/localStorageKeys';
import AuthenticationType from 'utils/constants/AuthenticationType';
import { indicationChooser } from 'components/Questionnaire/functions';
import { isInlineEditing } from 'components/AppRoutes/functions';
import setJoinedAt from 'functions/setJoinedAt';
import preserveQueryParam from 'functions/preserveQueryParam';
import clearAuthDataInLocalStorage from 'functions/clearAuthDataInLocalStorage';
import { uploadQuestionnaireFiles, resetUploadedQuestionnaireFiles, setIsFetching } from 'components/Questionnaire/actions';
import { showBackdrop, hideBackdrop } from 'modules/backdrop/actions';
import { BACKDROP_TYPES } from 'modules/backdrop/constants';
import { selectQuestionnaireFilesToUpload } from 'components/Questionnaire/selectors';
import { MatchFlow } from 'domains/Matches/MatchFlow';
import sessionStorageKeys from 'utils/constants/sessionStorageKeys';
import analytics from 'functions/analytics';
import {
  INTAKE_REDIRECT_INDICATIONS, INTERCOM_EVENTS, PAGES, URI,
} from 'new-ui/constants';
import { UNKNOWN_CANCER, ALL_CANCER } from 'utils/constants/cancerTypes';
import Intercom from 'new-ui/Components/Intercom';
import { matchesActions } from 'components/Matches/actions';
import { getQuestionnaireRoute } from 'functions/getQuestionnaireRoute';
import phone from 'phone';
import app from 'new-ui/app';
import { createEmailsToken } from 'new-ui/api';
import {
  GA_LOGIN_CATEGORY, GA_LOGIN_EVENTS, GA_QUESTIONNAIRE_CATEGORY, GA_QUESTIONNAIRE_EVENTS,
} from 'new-ui/Components/GoogleAnalytics/generalEvents';
import { isArray, isEqual } from 'lodash';
import sendAuthEvent from '../../functions/analytics/functions/sendAuthEvent';
import { authenticationService } from './api';
import CONSTANTS from './constants';
import { createQuestionnaireRedirectURL } from './functions';

const sendFormRequest = () => ({ type: CONSTANTS.SEND_FORM_REQUEST });
const sendFormSuccess = () => ({ type: CONSTANTS.SEND_FORM_SUCCESS });

const sendVerificationCodeRequest = () => ({ type: CONSTANTS.SEND_VERIFICATION_CODE_REQUEST });
const sendVerificationCodeSuccess = () => ({ type: CONSTANTS.SEND_VERIFICATION_CODE_SUCCESS });

function setProfileId(value) {
  return { type: CONSTANTS.SET_PROFILE_ID, payload: value };
}

const setProfileDetails = (profileDetails) => ({ type: CONSTANTS.SET_PROFILE_DETAILS, payload: profileDetails });

const userAuthenticationFailure = (errorMessage) => {
  localStorage.removeItem('token_login');
  return { type: CONSTANTS.USER_AUTHENTICATION_FAILURE, payload: errorMessage || null };
};

const userAuthenticationSuccess = (user, justSignedUp) => ({
  type: CONSTANTS.SET_AUTHENTICATION_DATA,
  payload: {
    user,
    justSignedUp: justSignedUp || false,
  },
});

const resetFormValidity = () => ({ type: CONSTANTS.RESET_FORM_VALIDITY });

const setFormValidity = (data) => ({ type: CONSTANTS.SET_FORM_VALIDITY, payload: data });

const setPhoneConfirmationResponse = (data) => ({ type: CONSTANTS.SET_PHONE_CONFIRMATION_RESPONSE, payload: data });

const resetPhoneConfirmationResponse = () => ({ type: CONSTANTS.RESET_PHONE_CONFIRMATION_RESPONSE });

const resetLoggedIn = () => ({ type: CONSTANTS.RESET_LOGGED_IN });

const setUserData = (data) => ({ type: CONSTANTS.SET_USER_DATA, payload: data });
const setConditionProfile = (data) => ({ type: CONSTANTS.SET_CONDITION_PROFILE, payload: data });

const setTokens = (tj_access_token, tj_refresh_token) => {
  localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, tj_access_token);
  localStorage.setItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, tj_refresh_token);
};

const setSession = (data) => {
  localStorage.setItem(LOCAL_STORAGE_KEYS.MASKED_EMAIL, data?.maskedEmail);
  localStorage.setItem(LOCAL_STORAGE_KEYS.MASKED_PHONE, data?.maskedPhone);
  localStorage.setItem(LOCAL_STORAGE_KEYS.ENC, JSON.stringify(data?.enc));
};

const clearMatches = () => ({ type: MATCHES_CONSTANTS.CLEAR_MATCHES });

const clearGeoLocation = () => ({ type: GEO_CONSTANTS.CLEAR_GEO_LOCATION });

export const setIsNewProfile = (payload) => ({
  type: MAIN_CONSTANTS.SET_IS_NEW_PROFILE,
  payload,
});

const setRedirectPathOnceQuestionnaireCompleted = (payload) => ({
  type: MAIN_CONSTANTS.SET_REDIRECT_PATH_ONCE_QUESTIONNAIRE_COMPLETED,
  payload,
});

const setNewProfile = (user) => ({ type: CONSTANTS.SET_NEW_PROFILE, payload: user });

const requestSignup = (data, type) => async (dispatch, getState) => {
  let result = null;
  const { last_updated_question } = data?.personal || {};
  data.personal.reached_required_question = JSON.parse(
    sessionStorage.getItem(sessionStorageKeys.REACHED_REQUIRED_QUESTION) || false,
  );
  dispatch(sendFormRequest());

  try {
    result = await authenticationService.signup(data, type);
  } catch (ex) {
    if (ex.response) {
      result = ex.response.data;
    }
    console.error('requestSignup error:', ex);
  }

  if (result && !result.success && result?.code === RESPONSE_ERROR_CONSTANTS.USER_EXISTS) {
    dispatch(userAuthenticationFailure(null));
    dispatch(sendFormSuccess());
    dispatch(setProfileId(null));
    // User already exists. Redirect to login
    const url = type
      ? `${ROUTE_PATH[ROUTE_NAME.LOGIN]}`
      : `${ROUTE_PATH[ROUTE_NAME.LOGIN]}?type=${data?.info?.password ? AuthenticationType.EMAIL : AuthenticationType.PHONE
      }`;
    if (last_updated_question) {
      Intercom.sendEvent(INTERCOM_EVENTS.QUICK_SIGNUP.QUICK_SIGNUP, { quickSignUp: 'returned user' });
    }
    dispatch(logout(url));
    return;
  }
  // if response has an error
  if (!result || !result.success) {
    console.error('Error occurred, while sending signing up request');
    const errDisplayMsg = result
      && typeof result.message === 'string'
      && result.message.startsWith('authentication.error')
      ? result.message
      : 'authentication.error.wrong_auth_data';
    dispatch(userAuthenticationFailure(errDisplayMsg));
    return;
  }

  if (!result.data.access_token || !result.data.refresh_token || !result.data.user) {
    console.error('Wrong response from the profile server');
    return result;
  }

  if (window.google?.accounts?.id) {
    window.google.accounts.id.cancel();
  }

  setTokens(result.data.access_token, result.data.refresh_token);
  localStorage.setItem(LOCAL_STORAGE_KEYS.USER, result.data.user);
  const user = JSON.parse(result?.data?.user);
  setSession(user);

  if (data?.personal) {
    user.personal = data.personal;
  }

  // This sets LOCAL_STORAGE_KEYS.JOINED_AT if doesn't exist
  setJoinedAt();

  dispatch(userAuthenticationSuccess(user, true));
  dispatch(setNewProfile(user));

  if (!last_updated_question) {
    dispatch(showBackdrop({ type: BACKDROP_TYPES.SPLASH }));
  }

  try {
    await dispatch(uploadQuestionnaireFiles());
  } catch (ex) {
    console.error('requestSignup uploadQuestionnaireFiles error', ex);
  }
  let userData;
  try {
    // WEBAPP-3801 will fill "profile.condition_profile" as result ->  will allow to edit existing profile
    userData = await dispatch(getUser());
    if (!userData) {
      throw new Error('empty user response');
    }
  } catch (ex) {
    console.error('after signup operation error.', ex);
  }

  sendAuthEvent(type);
  if (last_updated_question) {
    await dispatch(authenticationActions.updateUser(data, true));
    const {
      user_wix_id: userWixId,
      personal: {
        patient_id: patientId,
      },
      email,
    } = user;

    await dispatch(matchesActions.getTrialsMatches(
      {
        patientId,
        lat: userData.info?.country?.location?.lat ?? null,
        lng: userData.info?.country?.location?.lng ?? null,
        distance: userData.personal.travel_limit,
        lang: localStorage.getItem(LOCAL_STORAGE_KEYS.LANG) || '',
        matchFlow: MATCH_FLOW.BASIC,
      },
      true,
    ));

    const { matches } = getState();
    const { error: gettingTrialsMatchesError, result: userTrials, treatments: userTreatments } = matches;
    if (gettingTrialsMatchesError) {
      dispatch(logout());
      return;
    }

    app.trials = userTrials;
    app.treatments = userTreatments;

    app.signupTrials = userTrials;

    const token = await createEmailsToken();
    const _condition = data?.personal?.condition;

    const intercomData = {
      quick_signup: true,
      email,
      user_id: userWixId,
      token,
    };

    if (app.isIntakeFlow(_condition)) intercomData.intake_engagement = false;

    Intercom.update({
      user: data,
      data: intercomData,
      userTrials,
    });
    analytics.quickSignUpSucceed();

    const _event = !app.isIntakeFlow(_condition)
      ? `${GA_QUESTIONNAIRE_EVENTS.QUICK_SIGNUP_SUCCESS}_${_condition}`
      : `${GA_QUESTIONNAIRE_EVENTS.QUICK_SIGNUP_SUCCESS_INTAKE}_${_condition}`;
    app.sendGoogleAnalyticsEvent(GA_QUESTIONNAIRE_CATEGORY, _event);

    if (app.isIntakeFlow(data?.personal?.condition)) {
      if (INTAKE_REDIRECT_INDICATIONS.includes(data?.personal?.condition)) {
        dispatch(ReactRouter.push(`${ROUTE_PATH[ROUTE_NAME.INTAKE]}?unanswered=1`));
        return;
      }
      dispatch(ReactRouter.push(`${ROUTE_PATH[ROUTE_NAME.RESULTS]}?rematch=1&signup=1`));
      return;
    }
    dispatch(ReactRouter.push(getQuestionnaireRoute({ questionId: last_updated_question, popupType: 'quick_signup' })));
  } else {
    localStorage.setItem(LOCAL_STORAGE_KEYS.SHOULD_CREATE_EMAILS_TOKEN, true);
    dispatch(hideBackdrop({
      minDisplayTime: 5000,
      callback: () => {
        dispatch(ReactRouter.push(`${ROUTE_PATH[ROUTE_NAME.RESULTS]}?rematch=1&signup=1`));
      },
    }));
  }

  dispatch(sendFormSuccess());
};

const finishLogin = ({
  redirectPath,
  navigationPath,
  matchFlow = MATCH_FLOW.LATEST,
} = {}) => async (dispatch, getState) => {
  const questionnaireFilesToUpload = selectQuestionnaireFilesToUpload(getState());
  const isSplashNeeded = !!questionnaireFilesToUpload.length;
  sessionStorage.removeItem(sessionStorageKeys.MISSING_CRITICAL_DISPLAY);
  if (isSplashNeeded) {
    dispatch(showBackdrop({ type: BACKDROP_TYPES.SPLASH }));
  }

  try {
    await dispatch(uploadQuestionnaireFiles());
  } catch (ex) {
    // it is likely that files uploading error can be ignored
    console.error('requestLogin uploadQuestionnaireFiles error', ex);
  }
  let userData;
  try {
    userData = await dispatch(getUser());
    if (!userData) {
      throw new Error('empty user response');
    }
  } catch (ex) {
    console.error('after login operation error.', ex);
  }

  const { last_updated_question } = userData.personal || {};

  if (last_updated_question) {
    analytics.quickSignUpLoginSucceed();
    navigationPath = getQuestionnaireRoute({ questionId: last_updated_question, popupType: 'quick_login' });
    batch(() => {
      dispatch(ReactRouter.push(navigationPath));
    });

    return;
  }

  if (redirectPath) {
    batch(() => {
      dispatch(ReactRouter.push(redirectPath));
      if (isSplashNeeded) {
        dispatch(hideBackdrop({ minDisplayTime: 5000 }));
      }
    });

    return;
  }

  try {
    let redirectPath = navigationPath ?? preserveQueryParam(ROUTE_PATH[ROUTE_NAME.RESULTS]);
    if (matchFlow === MATCH_FLOW.BASIC) {
      redirectPath = redirectPath.includes('?') ? `${redirectPath}&rematch=1` : `${redirectPath}?rematch=1`;
    }
    batch(() => {
      dispatch(sendFormSuccess());
      dispatch(sendVerificationCodeSuccess());
      dispatch(ReactRouter.push(redirectPath));
    });
  } catch (ex) {
    console.error('after login operation error.', ex);
    return;
  } finally {
    if (isSplashNeeded) {
      dispatch(hideBackdrop({ minDisplayTime: 5000 }));
    }
  }
};

const requestLogin = (values, type, redirectPath) => async (dispatch, getState) => {
  const { profile } = getState();
  let result = null;

  const requestData = {
    ...values,
    personal: {
      profile_id: profile.personal.profile_id || null,
    },
  };

  // update medical profile case
  let isTempProfileLinking = !!profile.personal.profile_id;

  dispatch(sendFormRequest());

  try {
    result = await authenticationService.login(requestData, type);
  } catch (ex) {
    console.error(ex);
    return;
  }

  const navigationPath = ROUTE_PATH[ROUTE_NAME.RESULTS];

  if (result?.success && type === AuthenticationType.MAGIC_LINK.toLowerCase()) {
    app.sendGoogleAnalyticsEvent(GA_LOGIN_CATEGORY, GA_LOGIN_EVENTS.LOGIN_SUCCESS_TOKEN);
    const { user } = result.data;
    setTokens(result.data.access_token, result.data.refresh_token);
    setSession(user);
    localStorage.setItem(LOCAL_STORAGE_KEYS.USER, JSON.stringify(user));
    const redirect = window.location.search.replace('?redirect=', '');
    if (redirect) {
      window.location = redirect;
      return;
    }
    window.location = navigationPath;
    return;
  }

  if (result && !result.success && result?.data?.user_wix_id) {
    // Unable to update medical profile. Resend request without updating profile. Load last version.

    isTempProfileLinking = false;
    dispatch(resetUploadedQuestionnaireFiles());

    try {
      result = await authenticationService.login(values);
    } catch (ex) {
      console.error(ex);
      return;
    }
  }

  // if response has an error
  if (!result || !result.success) {
    if (
      AuthenticationType.GOOGLE === type?.toUpperCase()
    ) {
      dispatch(ReactRouter.push(
        `${ROUTE_PATH[ROUTE_NAME.LOGIN]}?type=email&email=${result?.response?.data?.email}`,
      ));
    }

    if (result.isExpiredPassword) {
      dispatch(userAuthenticationFailure('login.form.password.expired_message'));
      return dispatch(ReactRouter.push(`${ROUTE_PATH[ROUTE_NAME.NEW_PASSWORD]}?token=${result.token}`));
    }

    if (result.message === 'Too many requests') {
      dispatch(userAuthenticationFailure('login.form.lockout_message'));
      return;
    }

    console.error('Error occurred, while sending login request');
    dispatch(userAuthenticationFailure('authentication.error.wrong_auth_data'));
    return;
  }

  if (!values.info.email && !type) {
    dispatch(setPhoneConfirmationResponse(result.data));
    dispatch(sendFormSuccess());
    return result;
  }

  setTokens(result.data.access_token, result.data.refresh_token);
  localStorage.setItem(LOCAL_STORAGE_KEYS.USER, result.data.user);
  const user = JSON.parse(result.data.user);
  setSession(user);

  const responseData = {
    info: {
      user_wix_id: user.user_wix_id,
      email: user.email,
      family_name: user.family_name || null,
      phone: user.phone || null,
      user_type: user.user_type || null,
      country: user.country || null,
    },
    personal: {
      user_wix_id: user.user_wix_id,
    },
    condition_profile: {
      user_type: user?.user_type || null,
    },
  };

  dispatch(setUserData(responseData));
  dispatch(userAuthenticationSuccess(user));

  dispatch(finishLogin({
    navigationPath,
    redirectPath,
    matchFlow: isTempProfileLinking ? MATCH_FLOW.BASIC : MATCH_FLOW.LATEST,
  }));
};

// is used for sign up and sing in processes
const sendVerificationCode = (values, isSingUp = false, redirectPath) => async (dispatch, getState) => {
  const { profile } = getState();
  let result = null;

  const requestData = {
    ...values,
    profile_id: profile.personal.profile_id || null,
  };

  // update medical profile case
  let isTempProfileLinking = !!profile.personal.profile_id;

  dispatch(sendVerificationCodeRequest());

  try {
    result = await authenticationService.verify(requestData);
  } catch (ex) {
    console.error(ex);
  }

  if (result && !result.success && result?.data?.user_wix_id) {
    // Unable to update medical profile. Resend request without updating profile. Load last version.

    isTempProfileLinking = false;
    dispatch(resetUploadedQuestionnaireFiles());

    try {
      result = await authenticationService.verify(values);
    } catch (ex) {
      console.error(ex);
    }
  }

  // if response has an error
  if (!result || !result.success) {
    console.error('Error occurred, while sending confirmation code');
    dispatch(userAuthenticationFailure('authentication.error.wrong_code'));
    return result?.message || 'Something went wrong';
  }

  setTokens(result.data.access_token, result.data.refresh_token);
  localStorage.setItem(LOCAL_STORAGE_KEYS.USER, result.data.user);
  const user = JSON.parse(result?.data?.user);
  let usersData;

  try {
    usersData = await dispatch(getUser());
    if (!usersData) {
      throw new Error('empty user response');
    }
  } catch (ex) {
    console.error('after login operation error.', ex);
  }

  const {
    personal: {
      last_updated_question,
    },
  } = usersData;

  const responseData = {
    info: {
      user_wix_id: user.user_wix_id,
      email: user.email,
      family_name: user.family_name || null,
      phone: user.phone || null,
      user_type: user.user_type || null,
      country: user.country || null,
    },
    personal: {
      user_wix_id: user.user_wix_id,
      last_updated_question,
    },
    condition_profile: {
      user_type: user?.user_type || null,
    },
  };

  dispatch(setUserData(responseData));
  dispatch(userAuthenticationSuccess(user));
  sendAuthEvent(AuthenticationType.PHONE);

  app.sendGoogleAnalyticsEvent(GA_LOGIN_CATEGORY, GA_LOGIN_EVENTS.LOGIN_SUCCESS);

  let navigationPathOnFinish;
  if (isSingUp) {
    const queryParameters = queryString.parse(window.location.search);
    navigationPathOnFinish = `${ROUTE_PATH[ROUTE_NAME.RESULTS]}?${queryString.stringify({
      ...queryParameters,
      toSubmit: 1,
    })}`;
  } else {
    navigationPathOnFinish = preserveQueryParam(ROUTE_PATH[ROUTE_NAME.RESULTS]);
  }

  await dispatch(finishLogin({
    navigationPath: navigationPathOnFinish,
    redirectPath,
    matchFlow: isTempProfileLinking ? MATCH_FLOW.BASIC : MatchFlow.getMatchFlow(),
  }));
  setSession(user);
};

const requestPasswordRecovery = (email) => async (dispatch) => {
  let result = null;

  dispatch(sendFormRequest());

  try {
    result = await authenticationService.forgotPassword(email);
  } catch (ex) {
    console.error(ex);
  }

  // if response has an error
  if (!result || !result.success) {
    console.error('Error occurred, while sending request');
    // dispatch(userAuthenticationFailure('authentication.error.wrong_email'));
    dispatch(userAuthenticationFailure(null));
    return;
  }

  dispatch(userAuthenticationSuccess());
  dispatch(sendFormSuccess());

  return result;
};

const requestPasswordReset = (data) => async (dispatch) => {
  let result = null;

  dispatch(sendFormRequest());

  try {
    result = await authenticationService.resetPassword(data);
  } catch (ex) {
    console.error(ex);
  }

  // if response has an error
  if (!result || !result.success) {
    console.error('Error occurred, while sending request');
    dispatch(userAuthenticationFailure('authentication.error.password_reset_failure'));
    return;
  }

  dispatch(userAuthenticationSuccess());
  dispatch(sendFormSuccess());

  return result;
};

const setSuperUser = () => {
  localStorage.setItem(LOCAL_STORAGE_KEYS.SUPER_USER, 'true');
};

const setPartner = (partner) => {
  return (dispatch) => {
    dispatch(success(partner));
  };

  function success() {
    return { type: PARTNER_CONSTANTS.SET_PARTNER, payload: partner };
  }
};

const setInternal = () => {
  return (dispatch) => {
    dispatch(success());
  };

  function success() {
    return { type: INTERNAL_CONSTANTS.SET_INTERNAL };
  }
};

const getUser = () => {
  return async (dispatch, getState) => {
    let result = null;
    const { main } = getState();

    dispatch(requestUser());

    try {
      result = await authenticationService.getUser({
        lang: main?.lang,
      });
    } catch (ex) {
      console.error('Exception:', ex);
      return;
    } finally {
      if (!result || !result.info || !result.personal || !result.condition_profile) {
        console.error('Server response is invalid:', result);
        dispatch(failureUser(`Server response is invalid: ${JSON.stringify(result)}`));
        dispatch(ReactRouter.push(ROUTE_PATH[ROUTE_NAME.LOGIN]));
        return;
      }
    }

    const actionsToDispatch = [];
    const queueActionToDispatch = (action) => {
      actionsToDispatch.push(action);
    };

    const {
      info: {
        user_wix_id,
        email,
        family_name = null,
        phone = null,
        user_type = null,
        partner = null,
        organization = null,
        platform = null,
      },
      personal: { providerCode = null, condition },
    } = result;

    const user = {
      user_wix_id,
      email,
      family_name,
      phone,
      user_type,
      partner,
      condition,
      organization,
      platform,
    };

    const country = result.info.country || null;

    localStorage.setItem(LOCAL_STORAGE_KEYS.USER, JSON.stringify(user));

    if (!isInlineEditing()) {
      queueActionToDispatch(providerActions.setProvider(providerCode));
      if (getState().provider !== providerCode) {
        queueActionToDispatch(appConfigurationActions.getConfiguration({ provider: providerCode }));
      }
    }

    result.info = Object.assign(user, {
      country,
    });

    result.condition_profile.user_type = user_type;

    queueActionToDispatch(setUserData(result));

    if (country) {
      queueActionToDispatch(setUserGeoLocation(country || {}));
    }

    queueActionToDispatch(successUser(user));
    queueActionToDispatch(userAuthenticationSuccess(user));

    batch(() => {
      actionsToDispatch.forEach((action) => {
        dispatch(action);
      });
    });

    return result;
  };

  function requestUser() {
    return { type: CONSTANTS.GET_USER_REQUEST };
  }

  function successUser(user) {
    return { type: CONSTANTS.GET_USER_SUCCESS, payload: user };
  }

  function failureUser(error) {
    return { type: CONSTANTS.GET_USER_FAILURE, payload: error };
  }

  function setUserGeoLocation(country) {
    return { type: GEO_CONSTANTS.SET_USER_GEO_LOCATION, payload: country };
  }
};

const logout = (url = '/login') => {
  const _logout = () => ({ type: CONSTANTS.LOGOUT });
  const clearData = () => ({ type: CONSTANTS.CLEAR_DATA });

  return (dispatch) => {
    clearAuthDataInLocalStorage();

    dispatch(_logout());
    dispatch(clearData());
    dispatch(clearMatches());
    dispatch(clearMatchesMenu());
    dispatch(clearGeoLocation());
    dispatch(ReactRouter.replace(url));
  };
};

const softLogout = (userWixId) => (dispatch) => {
  if (userWixId) {
    clearAuthDataInLocalStorage();

    dispatch({ type: CONSTANTS.SOFT_LOGOUT });
    dispatch({ type: CONSTANTS.SOFT_CLEAR_DATA });
  }
};

const updateUser = (user = null, loggedIn = false, oldUser) => {
  return async (dispatch) => {
    if (!user) {
      return;
    }

    let response = null;
    if (
      loggedIn
      && user.personal
      && user.personal.user_wix_id
      && user.personal.patient_id
      && user.personal.profile_id
    ) {
      if (user.info.user_type) {
        let sessionData = localStorage.getItem(LOCAL_STORAGE_KEYS.USER);

        if (sessionData) {
          sessionData = JSON.parse(sessionData);
          if (!sessionData?.user_type) {
            sessionData.user_type = user.info.user_type;
            localStorage.setItem(LOCAL_STORAGE_KEYS.USER, JSON.stringify(sessionData));
          }
        }
      }

      if (user.info?.phone && !user.info?.phone.startsWith('+')) {
        user.info.phone = `+${user.info.phone}`;
        user.info.phone = phone(user.info.phone).phoneNumber;
      }

      dispatch(requestUserUpdate());

      if (user?.condition_profile) {
        delete user.condition_profile.drugs_received;
        const { qstack } = user.condition_profile;
        if (isArray(qstack) && qstack.find((a)=>a === 'drugs_received')) {
          qstack.splice(qstack.indexOf('drugs_received'), 1);
          user.condition_profile.qstack = qstack;
        }
        if(user?.condition_profile?.treatments?.length){
          user.condition_profile.treatments = user.condition_profile.treatments.filter((a)=>{
            return a?.drugs_received?.length;
          })
        }
      }

      if (oldUser) {
        console.log('updateUser :: old user', oldUser);
        if (!isEqual(user?.condition_profile?.treatments, oldUser?.condition_profile?.treatments)) {
          console.log('updateUser :: treatments updated');
          const lastTreatment = user?.condition_profile?.treatments[0];
          if (lastTreatment && app?.intercom?.isBooted) {
            if(isArray(lastTreatment.drugs_received)){
              app.intercom.updateUserAttributes({
                last_treatment: lastTreatment.drugs_received.join(' + '),
                last_treatment_updating_date: new Date().toLocaleDateString(),
                last_treatment_status: lastTreatment?.treatment_stop_reason,
              });
            }
          }
        }
      }

      try {
        response = await authenticationService.updateUser(user);
      } catch (ex) {
        console.error(`Error occurred, while updating user profile: ${(ex && ex.message) || ex}`);
        if (ex === RESPONSE_ERROR_CONSTANTS.INACTIVE_PROFILE_ERROR) {
          requestRefreshPage(dispatch, ex);
        }
        dispatch(failureUserUpdate(ex));
        return;
      }

      if (!response || !response.info) {
        const message = `Wrong response occurred, while updating user profile: ${response}`;
        console.error(message);
        dispatch(failureUserUpdate(message));
        return;
      }
    }
    dispatch(setUserData(user));
    dispatch(successUserUpdate());

    return response;
  };

  function requestUserUpdate() {
    return { type: CONSTANTS.UPDATE_USER_REQUEST };
  }

  function successUserUpdate() {
    return { type: CONSTANTS.UPDATE_USER_SUCCESS };
  }

  function failureUserUpdate(data) {
    return { type: CONSTANTS.UPDATE_USER_FAILURE, payload: data };
  }
};

const goToQuestionnaire = (condition, userData = null, selectedCancerType = null) => {
  
  return async (dispatch) => {
    const {
      user_wix_id, email, family_name, phone, partner, user_type,
    } = userData || {};

    if (condition === UNKNOWN_CANCER || condition === ALL_CANCER) {
      dispatch(ReactRouter.push(`${URI[PAGES.UPLOAD]}?action=email`));
      return;
    }
    const questionnaire = await indicationChooser(condition);
    const reRouteTo = createQuestionnaireRedirectURL(questionnaire);

    if (user_wix_id && email) {
      let result = null;
      const data = {
        info: {
          user_wix_id,
          country: null,
          email,
          family_name: family_name || null,
          phone: phone || null,
          partner: partner || null,
          user_type: user_type || null,
        },
        personal: {
          user_wix_id,
          profile_id: null,
          patient_id: null,
          condition,
          gender: null,
          dob: null,
          is_done: 'N',
          travel_limit: null,
        },
        condition_profile: {},
      };

      dispatch(requestCreateUser());

      try {
        result = await authenticationService.createFullUser(data);
      } catch (ex) {
        console.error('Exception:', ex);
        dispatch(failureCreateUser(`Error occurred, while creating user data: ${ex}`));
        return;
      }

      result = result.hasOwnProperty('result') ? result.result : result;

      if (
        !result
        || !(
          result.personal
          && result.personal.profile_id
          && result.personal.patient_id
          && result.condition_profile
          && Object.keys(result.condition_profile).length
        )
      ) {
        const message = 'Error occurred, while creating user data';
        console.error(message);
        dispatch(failureCreateUser(message));
        return;
      }

      const { profile_id, patient_id } = result.personal;

      batch(() => {
        dispatch(setProfileId(profile_id));
        dispatch(setPatientId(patient_id));
        dispatch(successCreateUser());
      });
    }

    // temp12
    dispatch(setCondition(condition, selectedCancerType));
    dispatch(ReactRouter.push(reRouteTo));
  };

  function setCondition(condition, selectedCancerType = null) {
    return { type: CONSTANTS.SET_CONDITION, payload: condition, subtype: selectedCancerType };
  }

  function setPatientId(value) {
    return { type: CONSTANTS.SET_PATIENT_ID, payload: value };
  }

  function requestCreateUser() {
    return { type: CONSTANTS.CREATE_USER_REQUEST };
  }

  function successCreateUser() {
    return { type: CONSTANTS.CREATE_USER_SUCCESS };
  }

  function failureCreateUser(error) {
    return { type: CONSTANTS.CREATE_USER_FAILURE, payload: error };
  }
};

const getPosition = (options) => new Promise(((resolve, reject) => {
  navigator.geolocation.getCurrentPosition(resolve, reject, options);
}));

const startUserRegistration = ({
  profileData,
  quickSignup = false,
  isModalSignup = false,
}) => async (dispatch, getState) => {
  dispatch({ type: CONSTANTS.CREATE_USER_REQUEST });
  const { appConfiguration } = getState();
  profileData.personal.providerId = appConfiguration.providerSpecific.id;
  // Create temp user data without "user_wix_id" for non-authorized user
  let tempUserCreationResult;
  try {
    tempUserCreationResult = await authenticationService.createTempUser(profileData);
  } catch (ex) {
    console.error('Exception:', ex);
    batch(() => {
      dispatch({
        type: CONSTANTS.CREATE_USER_FAILURE,
        payload: `Error occurred, while creating user data: ${ex}`,
      });
      dispatch(setIsFetching(false));
    });
    return;
  }

  dispatch({ type: CONSTANTS.CREATE_USER_SUCCESS });

  if (!tempUserCreationResult) {
    console.error('Error occurred, while creating/updating user data');
    batch(() => {
      dispatch(ReactRouter.push(ROUTE_PATH[ROUTE_NAME.HOME]));
      dispatch(setIsFetching(false));
    });
    return;
  }

  tempUserCreationResult = tempUserCreationResult.hasOwnProperty('result')
    ? tempUserCreationResult.result : tempUserCreationResult;

  if (
    !tempUserCreationResult
    || !(
      tempUserCreationResult.personal
      && tempUserCreationResult.personal.profile_id
      && tempUserCreationResult.personal.patient_id
      && tempUserCreationResult.condition_profile
      && Object.keys(tempUserCreationResult.condition_profile).length
    )
  ) {
    console.error('Error occurred, while creating/updating user data');
    dispatch(setIsFetching(false));
    return;
  }

  const {
    personal: {
      profile_id: profileId,
      patient_id: patientId,
    },
    trialsCount,
  } = tempUserCreationResult;
  const signUpPath = ROUTE_PATH[ROUTE_NAME.SIGN_UP] + (quickSignup ? '?type=quick_signup' : '');
  const modalSignUpPath = ROUTE_PATH[ROUTE_NAME.SIGN_UP] + (isModalSignup ? '?type=modal_signup' : '');
  batch(() => {
    dispatch({ type: CONSTANTS.SET_PROFILE_ID, payload: profileId });
    dispatch({ type: CONSTANTS.SET_TRIALS_COUNT, payload: trialsCount });
    dispatch({ type: CONSTANTS.SET_PATIENT_ID, payload: patientId });
    if (!isModalSignup) {
      dispatch(ReactRouter.push(signUpPath));
    } else if (isModalSignup) {
      dispatch(ReactRouter.push(modalSignUpPath));
    }
    dispatch(setIsFetching(false));
  });
};

const newProfile = (user) => (dispatch) => {
  batch(() => {
    dispatch(setNewProfile(user));
    dispatch(clearMatches());
    dispatch(clearMatchesMenu());
    dispatch(clearGeoLocation());
    dispatch(setIsNewProfile(true));
    dispatch(ReactRouter.push(`${ROUTE_PATH[ROUTE_NAME.HOME]}?usequestionnaire=true`));
  });
};

const editQuestionnaire = (condition, redirectPathOnceQuestionnaireCompleted) => async (dispatch) => {
  const questionnaire = await indicationChooser(condition);
  const reRouteTo = createQuestionnaireRedirectURL(questionnaire);

  batch(() => {
    dispatch(setRedirectPathOnceQuestionnaireCompleted(redirectPathOnceQuestionnaireCompleted));
    dispatch(setIsNewProfile(false));
    dispatch(clearMatches());
    dispatch(clearMatchesMenu());
    dispatch(ReactRouter.push(reRouteTo));
  });
};

const requestRefreshPage = (dispatch, exceptionCode, timeSec = 10) => {
  let reasonMessage;
  let solutionMessage;

  timeSec = Math.abs(Math.round(timeSec));

  if (exceptionCode === RESPONSE_ERROR_CONSTANTS.INACTIVE_PROFILE_ERROR) {
    reasonMessage = GLOBAL_CONSTANTS.errors.inactiveProfile.errorMessage;
    solutionMessage = GLOBAL_CONSTANTS.errors.inactiveProfile.solutionMessage;
  }

  const dispatchRefresh = (sec) => {
    dispatch({
      type: SERVER_STATUS_CONSTANTS.SERVER_STATUS_FAILURE,
      payload: {
        error: reasonMessage,
        reasonMessage,
        solutionMessage: solutionMessage && solutionMessage.replace('#time', sec),
      },
    });
  };

  const interval = setInterval(() => {
    dispatchRefresh(timeSec--);
    if (timeSec <= 0) {
      clearInterval(interval);
      window.location.reload();
    }
  }, 1000);
};

export const authenticationActions = {
  requestSignup,
  requestLogin,
  requestPasswordRecovery,
  requestPasswordReset,
  sendVerificationCode,
  userAuthenticationFailure,
  userAuthenticationSuccess,
  setFormValidity,
  resetFormValidity,
  resetPhoneConfirmationResponse,
  resetLoggedIn,
  getUser,
  setTokens,
  logout,
  softLogout,
  updateUser,
  goToQuestionnaire,
  startUserRegistration,
  newProfile,
  editQuestionnaire,
  setPartner,
  setInternal,
  setSuperUser,
  getPosition,
  setProfileDetails,
  finishLogin,
  setUserData,
  setConditionProfile,
};
