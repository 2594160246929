import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import app from 'new-ui/app';
import './CallUs.css';
import { ReactComponent as CallIcon } from 'new-ui/assets/icons/call_icon.svg';
import { ReactComponent as MenuIcon } from 'new-ui/assets/icons/menu_board.svg';
import { ReactComponent as MessageIcon } from 'new-ui/assets/icons/message_text.svg';
import Button from 'new-ui/Components/Button';
import { GA_CATEGORY_CALL_US_POPUP_NAME, callUsPopupEvents } from 'new-ui/Components/GoogleAnalytics/popups';
import { isCrmChatAllowed, isCurrentTimeInESTWorkingRange, isMobileWidth } from 'new-ui/functions';
import {
  CALL_SUPPORTED_INDICATIONS,
  POPUP_TYPES,
  CRM_EVENTS,
} from 'new-ui/constants';
import { openZendeskChat } from 'functions';
import Preloader from 'new-ui/Components/Preloader';
import { CrmChatAPI } from 'modules/crmChat';
import { authenticationService } from 'components/Authentication/api';
import { intercomSignupEvent } from 'new-ui/Components/Intercom';

const {
  talkToUsClicked,
  contactUsPoppedAuto,
  contactUsPopupViewed,
  callUsClicked,
} = callUsPopupEvents;

export const setCallUsPopup = () => {
  const params = new URLSearchParams(window.location.search);
  if (!app.popup && !app.callUsPopupPopped) {
    openCallUsPopup();
  } else if (!app.callUsPopupPopped) {
    app.intercom.hidden = false;
    app.intercom.createLauncher();
    app.intercom.showLauncher();
    if (params.get('signup')) {
      params.delete('signup');
      intercomSignupEvent();
    }
  }
};

export const openCallUsPopup = ()=>{
  app.callUsPopupPopped = true;
  app.setPopup(POPUP_TYPES.CALLUS, {
    v2: true,
    CSS: {
      width: `${document.querySelector('.item-box')?.getBoundingClientRect().width || 600}px`,
    },
  });
};

let userIntrractedCallUsPopup = false;

const CallUs = ({ automatically }) => {
  const { t } = useTranslation();
  const [phone, setPhone] = useState(null);
  const [popupVisible, setPopupVisability] = useState(false);
  const [crmVisible, setCrmVisible] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    userIntrractedCallUsPopup = false;
    app.intercom.hidden = true;
    if (app.callusTimerId) {
      clearTimeout(app.callusTimerId);
    }
    if (isCrmChatAllowed()) {
      CrmChatAPI.showLauncher();
      if (app.setCrmChatVisible) {
        app.setCrmChatVisible(true);
        const crmLauncher = document.getElementById('launcher');
        if (crmLauncher) {
          crmLauncher.style.zIndex = '-1';
        }
      }
    }
    app.intercom.hideLauncher();
    setTimeout(() => {
      setPopupVisability(true);
      setCrmVisible(app.crmVisibility || app.webWidgetVisibility);
    }, 500);

    if (automatically) {
      app.sendGoogleAnalyticsEvent(
        GA_CATEGORY_CALL_US_POPUP_NAME,
        contactUsPoppedAuto.eventName,
        contactUsPoppedAuto.getMetaData(),
      );
    } else {
      app.sendGoogleAnalyticsEvent(
        GA_CATEGORY_CALL_US_POPUP_NAME,
        talkToUsClicked.eventName,
        talkToUsClicked.getMetaData(),
      );
    }
    app.sendGoogleAnalyticsEvent(
      GA_CATEGORY_CALL_US_POPUP_NAME,
      contactUsPopupViewed.eventName,
      contactUsPopupViewed.getMetaData(),
    );
    return () => {
      if (!userIntrractedCallUsPopup && isCrmChatAllowed()) {
        CrmChatAPI.hideLauncher();
        app.intercom.hidden = true;
        app.callusTimerId = setTimeout(() => {
          if (!(app.crmVisibility || app.webWidgetVisibility)) {
            app.intercom.hidden = false;
            if (params.get('signup')) {
              params.delete('signup');
              intercomSignupEvent();
            }
          }
        }, 15000);
      }
      if (!userIntrractedCallUsPopup && !isCrmChatAllowed()) {
        app.callusTimerId = setTimeout(() => {
          app.intercom.hidden = false;
          app.intercom.createLauncher();
          app.intercom.showLauncher();
          if (params.get('signup')) {
            params.delete('signup');
            intercomSignupEvent();
          }
        }, 15000);
      }
    };
  }, [automatically]);

  const callUs = () => {
    userIntrractedCallUsPopup = true;
    app.sendGoogleAnalyticsEvent(
      GA_CATEGORY_CALL_US_POPUP_NAME,
      callUsClicked.eventName,
      callUsClicked.getMetaData(),
    );
    const phoneToCall = CALL_SUPPORTED_INDICATIONS[app.user.personal?.condition]?.tel;
    if (isMobileWidth() && phoneToCall) {
      window.location.href = `tel:${phoneToCall.replace(/[\s-]+/g, '')}`;
    } else {
      setPhone(phoneToCall);
    }

    const user = app.user.info;
    const { family_name, email } = user;
    authenticationService
      .registerUserRequests({
        user: {
          email,
          name: family_name ?? email,
        },
        type: CRM_EVENTS.CALL_US,
        content: 'The patient called us',
      }).catch((ex) => console.error(ex));

    authenticationService.updateIntercomUser({
      data: {
        tags: ['called_us'],
      },
    }).catch((ex) => console.error(ex));
  };

  const shaduleACall = () => {
    userIntrractedCallUsPopup = true;
    if (!app.user.info.phone) {
      app.setPopup(POPUP_TYPES.PHONE_NUMBER, {
        closePopup: () => {
          app.setPopup('consult_with_us');
        },
      });
    } else {
      app.setPopup('consult_with_us');
    }
  };

  const chatWithUs = () => {
    userIntrractedCallUsPopup = true;
    setTimeout(() => {
      if (app.crmVisibility || app.webWidgetVisibility) {
        const crmLauncher = document.getElementById('launcher');
        if (crmLauncher) {
          crmLauncher.style.zIndex = '9999';
        }
        openZendeskChat();
      }
    }, 0);

    app.setPopup(false);
    authenticationService.updateIntercomUser({
      data: {
        tags: ['zendesk_chat_started'],
      },
    }).catch((ex) => console.error(ex));
  };

  return !popupVisible ? <Preloader isVisible size={0.5} /> : (
    <div className="call-us">
      <h2>{t('call_us_popup.title')}</h2>
      <p>{t(isCurrentTimeInESTWorkingRange() ? 'call_us_popup.subtitle' : 'call_us_popup.outofwork.subtitle')}</p>
      <div className="call-us-buttons">
        {
          isCurrentTimeInESTWorkingRange()
        && (
          phone
            ? (
              <p className="phone-number">
                Tel:
                {' '}
                {phone}
                {' '}
              </p>
            ) : (
              <Button
                svg={(<CallIcon />)}
                className="call-us-btn"
                title={t('call_us_popup.call_us.btn_title')}
                action={callUs}
              />
            )
        )
        }
        <Button
          svg={(<MenuIcon />)}
          className="shadule-a-call-btn"
          title={t('call_us_popup.shadule_a_cal.btn_title')}
          action={shaduleACall}
        />
        {
          isCurrentTimeInESTWorkingRange()
        && isCrmChatAllowed() && crmVisible
        && (
          <Button
            svg={(<MessageIcon />)}
            className="chat-with-us-btn"
            title={t('call_us_popup.chat_with_us.btn_title')}
            action={chatWithUs}
          />
        )
        }
      </div>
    </div>
  );
};

CallUs.propTypes = {
  automatically: PropTypes.bool,
};

export default CallUs;
