import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'utils/modifiedTranslation';

export const QuestionTitle = (props) => {
  const { q } = props;
  const { t } = useTranslation();
  return (
    <>
      {q.title ? (
        <div className="question-title">
          {t(q.title)}
        </div>
      ) : null}
      {q.subtitle ? (
        <div className="question-subtitle">
          {t(q.subtitle)}
        </div>
      ) : null}
    </>
  );
};

QuestionTitle.propTypes = {
  q: PropTypes.any,
};
