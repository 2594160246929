import React from 'react';
import './style.css';
import { Button } from '@material-ui/core';
import { useTranslation } from 'utils/modifiedTranslation';
import IntInterventionalType from '../OptionTypes/IntInterventionalTypes';
import { plusCircle } from 'utils/static/icons';

const InterventionalTypeV2 = ({ CATEGORY, selectedOptions, handleOptionChange, interventional_types, onClickMore=()=>{}, isMobile = false }) => {
  const { t } = useTranslation();
  const optionsToShow = isMobile ? 1 : 5;

  return (
    <div className='interventional-type-wrapper'>
      <IntInterventionalType
          category={CATEGORY.INTERVENTIONAL_TYPES}
          selectedOptions={selectedOptions[CATEGORY.INTERVENTIONAL_TYPES]}
          onOptionChange={handleOptionChange}
          options={interventional_types.slice(0, optionsToShow)}
          comp={
            <Button onClick={onClickMore} className="more-btn">
              <img className="info-icon" src={plusCircle} alt="plus circle" />
              <span className="info-label">{t("treatment_options.action.more.button")}</span>
            </Button>
          }
      />
    </div>
  );
};

export default InterventionalTypeV2;