import React from 'react';
import './style.css';
import { t } from 'utils/modifiedTranslation';
import { CheckMarkIcon } from '../../icons';

const EligibleBtn = () => {
  return (
    <div className="eligible-btn">
      <CheckMarkIcon />
      <span>{t('general.eligible')}</span>
    </div>
  );
};

export default EligibleBtn;
