import React from 'react';
import './AboutYou.css';
import app from 'new-ui/app';
import Button from 'new-ui/Components/Button';
import { PAGES, ROUTES } from 'new-ui/constants';
import AboutYouGfx from 'new-ui/assets/gfx/about_you.svg';

const AboutYouPopup = ()=>{
  return (
    <div className="about-you-popup">
      <div className="about-you-popup-image">
        <img src={AboutYouGfx} alt="" />
      </div>
      <div className="padding-20">
        <div className="about-you-popup-title">
          Information About You
        </div>
        <div className="about-you-popup-text">
          Please update information about you to help us tailor our services to better suit your needs.
        </div>
        <div className="about-you-popup-button">
          <Button
            title="Update"
            action={()=>{
              app.setPopup(false);
              app.history.push(`${ROUTES[PAGES.INTAKE]}?section=about`);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AboutYouPopup;
