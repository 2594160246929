import React, { useState } from 'react';
import './style.css';
import ProgressBar from './ProgressBar';

const TreatmentSideEffects = ({sideEffects}) => {
  const [showMore, setShowMore] = useState(false);
  const itemsToShow = showMore ? sideEffects : sideEffects.slice(0, 5);

  return (
    <div className="treatment-side-effect">
      <h2 className='title'>Side effects</h2>
      <div className='side-effects'>
        {itemsToShow.map((option, i) => (
          <div key={i} className='side-effect'>
            <div className='label'>{option.sideEffectDescription}</div>
            <ProgressBar percentage={option.percent} />
          </div>
        ))}
        {sideEffects.length > 5 && (
          <button className='show-more-button' onClick={() => setShowMore(!showMore)}>
            {showMore ? 'Show less' : 'Show more'}
          </button>
        )}
      </div>
    </div>
  );
};

export default TreatmentSideEffects;