import React from 'react';
import app from 'new-ui/app';
import { useTranslation } from 'utils/modifiedTranslation';
import Button from 'new-ui/Components/Button';
import PropTypes from 'prop-types';

import { ITEM_TYPES, POPUP_TYPES } from 'new-ui/constants';

import './PointsToDiscuss.css';

const parseBullets = (text) => {
  const bullets = text.split(/\n+\s*-*\s*/).map((t) => t.replace(/^\s*-*\s*|\s*-*\s*$/g, '')).filter((t) => t);
  return bullets;
};

const PointsToDiscussWithOncologist = (props) => {
  const { treatment, isPublic, isTreatmentPage } = props;
  const { t } = useTranslation();

  const text = isTreatmentPage ? t('points_to_discuss_soc.desc') : treatment?.points_to_discuss_with_your_oncologist;

  const bullets = text ? parseBullets(text) : null;

  const buttonAction = () => {
    app.setPopup(POPUP_TYPES.SHARE_WITH_DOCTOR, {
      items: [treatment],
      showDownload: !isPublic,
      type: ITEM_TYPES.TREATMENTS,
    });
  };

  return (text ? (
    <div className="widget points-to-discuss">
      <h2>{isTreatmentPage ? t('points_to_discuss_soc.title') : !isPublic ? t('points_to_discuss.title') : t('points_to_discuss_phys.title')}</h2>
      <div className="widget-body">
        {bullets.map((bullet, idx) => (
          <div key={idx} className="bullet">
            <div>{bullet}</div>
          </div>
        ))}
        {/* { !isPublic ? (
          <div className="text-align-center">
            <Button
              className="consult-button"
              title={isTreatmentPage ? t('points_to_discuss_soc.button') : t('points_to_discuss.button')}
              action={buttonAction}
            />
          </div>
        ) : null} */}
      </div>
    </div>
  ) : null
  );
};

PointsToDiscussWithOncologist.propTypes = {
  text: PropTypes.string,
  treatment: PropTypes.any,
  isPublic: PropTypes.bool,
  isTreatmentPage: PropTypes.bool,
};

export default PointsToDiscussWithOncologist;
