import { cancerTypes } from 'utils/constants/cancerTypes';

const CANCER_TYPE_RENDER_CONDITION = {
  [cancerTypes.LUNG]: {
    nct03964727: false,
  },
  [cancerTypes.UTERINE]: {
    nct03964727: false,
  },
};

export const shouldShowTrialByCancerType = (cancerType, nctNumber) => {
  const adjustedNctNumber = nctNumber.toLowerCase();
  const cancerNcts = CANCER_TYPE_RENDER_CONDITION[cancerType?.toLowerCase()];

  return cancerNcts ? (adjustedNctNumber in cancerNcts ? cancerNcts[adjustedNctNumber] : true) : true;
};
