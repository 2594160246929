import { menuConstants } from './constants';

const { MENU } = menuConstants;

const getMatchesMenuTriggers = () =>
  Object.keys(MENU).reduce((_triggers, curTrigger) => {
    const triggers = _triggers;
    triggers[curTrigger] = false;

    return triggers;
  }, {});

const initialState = {
  menuTriggers: getMatchesMenuTriggers(),
};

export function matchesMenu(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case menuConstants.CLEAR_MATCHES_MENU:
      return {
        ...initialState,
        menuTriggers: state.menuTriggers,
      };
    case menuConstants.TOGGLE_MATCHES_MENU_TRIGGER:
      return {
        ...state,
        menuTriggers: {
          ...state.menuTriggers,
          ...payload,
        },
      };
    default:
      return state;
  }
}
