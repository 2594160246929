export const Profile = {
  // global questions from "user_personal_record" table
  user_type: 'user_type',
  dob: 'dob',
  gender: 'gender',

  // all the fields from api table
  race: 'race',
  disease_status: 'disease_status',
  current_stage: 'current_stage',
  type_of_crc: 'type_of_crc',
  type_of_familial_cancer: 'type_of_familial_cancer',
  sidedness: 'sidedness',
  tumors_resectable: 'tumors_resectable',
  tumor_location: 'tumor_location',
  metastasis_location_brain: 'metastasis_location_brain',
  is_brain_metastasis_active: 'is_brain_metastasis_active',
  disease_measurable: 'disease_measurable',
  tumor_size: 'tumor_size',
  distance_from_anal_verge: 'distance_from_anal_verge',
  had_procedure_for_crc_cancer: 'had_procedure_for_crc_cancer',
  procedures: 'procedures',
  have_biopsy: 'have_biopsy',
  braf_mutation: 'braf_mutation',
  msi: 'msi',
  pd_l1_carrier_status: 'pd_l1_carrier_status',
  pd_l1_expression: 'pd_l1_expression',
  ngs: 'ngs',
  kras_mut: 'kras_mut',
  treatment_for_crc_cancer: 'treatment_for_crc_cancer',
  treatment_for_advanced_metastatic_disease: 'treatment_for_advanced_metastatic_disease',
  treatments: 'treatments',
  current_treatment_systemic_steroids: 'current_treatment_systemic_steroids',
  systemic_steroids_treatment_end_date: 'systemic_steroids_treatment_end_date',
  ecog: 'ecog',
  other_medical_condition: 'other_medical_condition',
  omc_liver: 'omc_liver',
  omc_heart: 'omc_heart',
  omc_stroke_date: 'omc_stroke_date',
  omc_kidney: 'omc_kidney',
  omc_autoimmune: 'omc_autoimmune',
  omc_mental: 'omc_mental',
  omc_allergy: 'omc_allergy',
  omc_chronic_infection_inflammation: 'omc_chronic_infection_inflammation',
  omc_gastro_problem: 'omc_gastro_problem',
  omc_lung_disease: 'omc_lung_disease',
  omc_abnormal_blood_results: 'omc_abnormal_blood_results',
  other_medications: 'other_medications',
  is_pregnant: 'is_pregnant',
  is_breastfeeding: 'is_breastfeeding',
  have_insurance: 'have_insurance',
  insurances: 'insurances',

  // additional questions which are not listed in api table
  procedures_received: 'procedures_received',
  procedure_date: 'procedure_date',
  procedure_outcome: 'procedure_outcome',
  drugs_received: 'drugs_received',
  cycles: 'cycles',
  treatment_admin: 'treatment_admin',
  treatment_end_date: 'treatment_end_date',
  treatment_stop_reason: 'treatment_stop_reason',
};
