import React, { useState, useEffect } from "react";
import "./style.css";
import { Button } from '@material-ui/core';
import LabeledInput from "../LabelInput";
import { validateEmail } from "../utils";
import AscImg from '../images/asc.png';
import FreeImg from '../images/free.svg';
import HipaaImg from '../images/hipaa.png';
import RectangleImg from '../images/rectangle.svg';

const ShareWithPatientCard = ({patient_id}) => {
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    const { name, email } = formValues;
    if (name && email && validateEmail(email)) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [formValues]);

  const handleChange = (name, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    setSubmitted(true);
    if(isFormValid) {
      console.log('Form Values:', formValues);
    }
  };

  return (
    <div className="share-patient-card">
      <h2 className="title">Share with Your Patient</h2>
      <div className="description">
        Sharing with the patient ensures they receive a link to a platform where their personalized results are available. 
        This allows them to access and understand their specific health information conveniently.
      </div>
      <LabeledInput 
        required={true} 
        label={'Patient name'} 
        placeholder={'Add Patient name'} 
        onChange={(value)=>handleChange('name',value)} 
        submitted={submitted}
      />
      <LabeledInput 
        required={true} 
        label={'Patient email'} 
        placeholder={'Patient email'}
        validate={validateEmail}
        onChange={(value)=>handleChange('email',value)} 
        submitted={submitted}
      />
      <Button onClick={handleSubmit} className='share-btn'>
        <span className='btn-title'>Share</span>
      </Button>
      <div className="images">
        <img src={AscImg} alt="icon"/>
        <img src={FreeImg} alt="icon"/>
        <img src={HipaaImg} alt="icon"/>
        <img src={RectangleImg} alt="icon"/>
      </div>
    </div>
  );
};

export default ShareWithPatientCard;