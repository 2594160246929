import app from 'new-ui/app';
import { conditionCheckByType } from './functions/conditionCheckByType';

export const conditionChecker = (question = {}, user)=>{
  if (!question?.conditions?.length) return true;
  let f = true;
  question.conditions.forEach((condition)=>{
    if (!f) return;
    const s = conditionCheckByType(user.condition_profile, condition, {});
    if (!s) f = false;
  });
  return f;
};

export const getQstack = (user = app.user, questions = app.questions)=>{
  if (!user?.condition_profile) return;
  const qstack = [];
  questions.forEach((q)=>{
    const isShown = conditionChecker(q, user);
    if (!isShown) return;
    qstack.push(q.id);
  });
  return qstack;
};

window.getQstack = getQstack;
