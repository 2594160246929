import React from 'react';
import hideByCondition from '../../functions/getShowedByCondition';

const questionnaireManualOpenSteps = (params = {}) => {
  const { infoPopup = {}, openFaqInNewTab = () => {} } = params || {};

  return [
    {
      id: '1',
      message: 'Hi.  I’m Rachel a Clinical Trial Specialist with TrialJectory. How can I assist you today?',
      trigger: '2'
    },
    {
      id: '2',
      options: [
        {
          value: 1,
          label: 'I do not understand the question. Can you provide more information?',
          trigger: infoPopup?.content?.title ? '6' : '10'
        },
        {
          value: 2,
          label: 'Can I skip this question?',
          trigger: '3'
        },
        {
          value: 3,
          label: 'I understand the question, but I don’t have exact details at this time.',
          trigger: '3'
        }
      ]
    },

    {
      id: '3',
      message:
        'None of the survey questions are mandatory. The more you answer, the more accurate your results will be.',
      trigger: '4'
    },
    {
      id: '4',
      message:
        'If you don’t have the information you need to complete this question at this time, you can always come back later to provide an answer.',
      trigger: '5'
    },
    {
      id: '5',
      message: 'After you update and resubmit your survey, you will receive updated and more accurate match results.',
      trigger: '7'
    },

    {
      id: '6',
      message: 'An explanation window will open immediately',
      trigger: () => {
        infoPopup.open(infoPopup.content);
        return '7';
      }
    },
    {
      id: '7',
      message: 'Was this information helpful?',
      trigger: '8'
    },
    {
      id: '8',
      options: [
        {
          value: 1,
          label: 'Yes',
          trigger: '9'
        },
        {
          value: 2,
          label: 'No',
          trigger: '10'
        }
      ]
    },

    {
      id: '9',
      message: 'Ok. If you need anything else, you can also visit our FAQ.  Thanks for visiting TrialJectory.',
      end: true
    },

    {
      id: '10',
      component: (
        <p style={{ margin: 0 }}>
          For additional information please visit our{' '}
          <button
            style={{
              padding: 0,
              background: 'transparent',
              border: 'none',
              color: '#53bbbb',
              fontWeight: 700,
              cursor: 'pointer'
            }}
            type="button"
            onClick={openFaqInNewTab}
          >
            FAQ
          </button>
          . Thanks for visiting TrialJectory.
        </p>
      ),
      asMessage: true,
      end: true
    }
  ];
};

const questionnaireMissedAnswerSteps = (params = {}) => {
  const { wasSkippedQuestionFirst, infoPopup, setChatBotOpenState = () => {}, handleGoBackToQuestion = () => {} } =
    params || {};

  const conditionalGreetingSteps = wasSkippedQuestionFirst
    ? [
        {
          id: '1',
          message: 'Hi. I’m Rachel a Clinical Trial Specialist with TrialJectory.',
          trigger: '2'
        },
        {
          id: '2',
          message:
            'I noticed you skipped a critical survey question. In order for us to accurately match the right treatment for your medical condition, we need you to complete all the screener questions.',
          trigger: '3'
        }
      ]
    : [
        {
          id: '2',
          message:
            'I noticed you skipped a critical survey question. In order for us to accurately match the right treatment for your medical condition, we need you to complete all the screener questions.',
          trigger: '3'
        }
      ];

  return [
    ...conditionalGreetingSteps,
    {
      id: '2',
      message:
        'I noticed you skipped a critical survey question. In order for us to accurately match the right treatment for your medical condition, we need you to complete all the screener questions.',
      trigger: '3'
    },
    {
      id: '3',
      message: 'Is there something I can help you with? Are you interested in one of these options?',
      trigger: '4'
    },
    {
      id: '4',
      options: hideByCondition([
        {
          value: 1,
          label: 'Go back to question',
          trigger: () => {
            handleGoBackToQuestion();
            setChatBotOpenState(false);
            return false;
          }
        },
        {
          value: 2,
          label: 'I do not understand the question. Can you provide additional information?',
          trigger: '6',
          conditions: [infoPopup?.content?.title]
        },
        {
          value: 3,
          label: 'I want to skip this question',
          trigger: '5'
        }
      ])
    },

    {
      id: '5',
      message:
        'Skipping survey questions will impact the quality of your match results. Remember, you can always go back to update your responses. Then you will receive updated match results.',
      trigger: () => {
        setChatBotOpenState(false);
        return false;
      }
    },

    {
      id: '6',
      message: 'An explanation window will open immediately',
      trigger: () => {
        handleGoBackToQuestion();
        infoPopup.open(infoPopup.content);
        return false;
      }
    }
  ];
};

// const chooseSpeakWithSteps = (params = {}) => {
//   const {
//     isSponsored = false,
//     isTreatmentFinderAvailable = false,
//     handleSendToDoctor = () => {},
//     handleOpenCalendly = () => {},
//     openFaqInNewTab = () => {},
//     handleRedirectToTreatmentFinder = () => {},
//     handleEmailUs = () => {},
//     t = () => {}
//   } = params || {};
//   return [
//     {
//       id: 'sp1',
//       message: t('chat_bot.steps.choose_speak.sp1'),
//       trigger: 'sp2'
//     },
//     {
//       id: 'sp2',
//       message: t('chat_bot.steps.choose_speak.sp2'),
//       trigger: 'sp3'
//     },
//     {
//       id: 'sp3',
//       message: t('chat_bot.steps.choose_speak.sp3'),
//       trigger: 'sp4'
//     },
//     {
//       id: 'sp4',
//       message: t('chat_bot.steps.choose_speak.sp4'),
//       trigger: 'sp5'
//     },
//     {
//       id: 'sp5',
//       options: hideByCondition([
//         {
//           value: 1,
//           label: t('chat_bot.steps.choose_speak.sp5.options.1'),
//           trigger: 'sp6'
//         },
//         {
//           value: 2,
//           label: t('chat_bot.steps.choose_speak.sp5.options.2'),
//           trigger: 'sp9',
//           conditions: [isSponsored]
//         },
//         {
//           value: 3,
//           label: t('chat_bot.steps.choose_speak.sp5.options.3'),
//           trigger: 'sp12'
//         }
//       ])
//     },
//     {
//       id: 'sp6',
//       message: t('chat_bot.steps.choose_speak.sp6'),
//       trigger: () => {
//         handleSendToDoctor();
//         return 'sp7';
//       }
//     },
//     {
//       id: 'sp7',
//       message: t('chat_bot.steps.choose_speak.sp7'),
//       trigger: 'sp8'
//     },
//     {
//       id: 'sp8',
//       message: t('chat_bot.steps.choose_speak.sp8'),
//       end: true
//     },
//     {
//       id: 'sp9',
//       message: t('chat_bot.steps.choose_speak.sp9'),
//       trigger: 'sp10'
//     },
//     {
//       id: 'sp10',
//       options: [
//         {
//           value: 1,
//           label: t('chat_bot.steps.choose_speak.sp10.options.1'),
//           trigger: () => {
//             handleOpenCalendly();
//             return 'sp11';
//           }
//         }
//       ]
//     },
//     {
//       id: 'sp11',
//       message: t('chat_bot.steps.choose_speak.sp11'),
//       end: true
//     },
//     {
//       id: 'sp12',
//       message: t('chat_bot.steps.choose_speak.sp12'),
//       trigger: 'sp13'
//     },
//     {
//       id: 'sp13',
//       options: [
//         {
//           value: 1,
//           label: t('chat_bot.steps.choose_speak.sp13.options.1'),
//           trigger: () => {
//             openFaqInNewTab();
//           },
//           end: true
//         },
//         {
//           value: 2,
//           label: t('chat_bot.steps.choose_speak.sp13.options.2'),
//           trigger: isTreatmentFinderAvailable ? 'sp14' : 'sp17'
//         }
//       ]
//     },
//     {
//       id: 'sp14',
//       message: t('chat_bot.steps.choose_speak.sp14'),
//       trigger: 'sp15'
//     },
//     {
//       id: 'sp15',
//       message: t('chat_bot.steps.choose_speak.sp15'),
//       trigger: 'sp16'
//     },
//     {
//       id: 'sp16',
//       options: [
//         {
//           value: 1,
//           label: t('chat_bot.steps.choose_speak.sp16.options.1'),
//           trigger: () => {
//             handleRedirectToTreatmentFinder();
//           },
//           end: true
//         },
//         {
//           value: 2,
//           label: t('chat_bot.steps.choose_speak.sp16.options.2'),
//           trigger: 'sp17'
//         }
//       ]
//     },
//     {
//       id: 'sp17',
//       message: t('chat_bot.steps.choose_speak.sp17'),
//       trigger: 'sp18'
//     },
//     {
//       id: 'sp18',
//       message: t('chat_bot.steps.choose_speak.sp18'),
//       trigger: 'sp19'
//     },
//     {
//       id: 'sp19',
//       options: [
//         {
//           value: 1,
//           label: t('chat_bot.steps.choose_speak.sp19.options.1'),
//           trigger: 'sp20'
//         },
//         {
//           value: 2,
//           label: t('chat_bot.steps.choose_speak.sp19.options.2'),
//           trigger: 'sp22'
//         }
//       ]
//     },
//     {
//       id: 'sp20',
//       message: t('chat_bot.steps.choose_speak.sp20'),
//       trigger: 'sp21'
//     },
//     {
//       id: 'sp21',
//       options: [
//         {
//           value: 1,
//           label: t('chat_bot.steps.choose_speak.sp21.options.1'),
//           trigger: () => {
//             handleEmailUs();
//             return 'sp22';
//           }
//         }
//       ]
//     },
//     {
//       id: 'sp22',
//       message: t('chat_bot.steps.choose_speak.sp22'),
//       end: true
//     }
//   ];
// };

// const matchesSponsoredSteps = (params = {}) => {
//   const {
//     userName = params.t('chat_bot.steps.sponsored.user_name.default'),
//     isTreatmentFinderAvailable = false,
//     handleSendToDoctor = () => {},
//     handleOpenCalendly = () => {},
//     handleRedirectToTreatmentFinder = () => {},
//     openFaqInNewTab = () => {},
//     handleEmailUs = () => {},
//     t = () => {}
//   } = params || {};
//   const speakWithSteps = chooseSpeakWithSteps({
//     isSponsored: true,
//     isTreatmentFinderAvailable,
//     handleSendToDoctor,
//     handleOpenCalendly,
//     openFaqInNewTab,
//     handleRedirectToTreatmentFinder,
//     handleEmailUs,
//     t
//   });
//   return [
//     {
//       id: '1',
//       message: t('chat_bot.steps.sponsored.q1', {
//         userName
//       }),
//       trigger: '2'
//     },
//     {
//       id: '2',
//       message: t('chat_bot.steps.sponsored.q2'),
//       trigger: '3'
//     },
//     {
//       id: '3',
//       message: t('chat_bot.steps.sponsored.q3'),
//       trigger: '4'
//     },
//     {
//       id: '4',
//       message: t('chat_bot.steps.sponsored.q4'),
//       trigger: '5'
//     },
//     {
//       id: '5',
//       options: [
//         {
//           value: 1,
//           label: t('chat_bot.steps.sponsored.q5.options.1'),
//           trigger: '6'
//         },
//         {
//           value: 2,
//           label: t('chat_bot.steps.sponsored.q5.options.2'),
//           trigger: 'sp1'
//         }
//       ]
//     },
//     {
//       id: '6',
//       message: t('chat_bot.steps.sponsored.q6'),
//       trigger: () => {
//         handleOpenCalendly();
//         return '7';
//       }
//     },
//     {
//       id: '7',
//       options: [
//         {
//           value: 1,
//           label: t('chat_bot.steps.sponsored.q7.options.1'),
//           trigger: () => {
//             handleEmailUs();
//             return '8';
//           }
//         }
//       ]
//     },
//     {
//       id: '8',
//       message: t('chat_bot.steps.sponsored.q8'),
//       end: true
//     },
//     ...speakWithSteps
//   ];
// };

// const matchesNoSponsoredSteps = (params = {}) => {
//   const {
//     userName = 'chat_bot.steps.sponsored_scenario.user_name.default',
//     isTreatmentFinderAvailable = false,
//     handleSendToDoctor = () => {},
//     handleOpenCalendly = () => {},
//     handleRedirectToTreatmentFinder = () => {},
//     openFaqInNewTab = () => {},
//     handleEmailUs = () => {},
//     t = () => {}
//   } = params || {};
//   const speakWithSteps = chooseSpeakWithSteps({
//     isSponsored: false,
//     isTreatmentFinderAvailable,
//     handleSendToDoctor,
//     handleOpenCalendly,
//     openFaqInNewTab,
//     handleRedirectToTreatmentFinder,
//     handleEmailUs,
//     t
//   });
//   return [
//     {
//       id: '1',
//       message: t('chat_bot.steps.sponsored.q1', {
//         userName
//       }),
//       trigger: '2'
//     },
//     {
//       id: '2',
//       message: t('chat_bot.steps.sponsored.q2'),
//       trigger: '3'
//     },
//     {
//       id: '3',
//       message: t('chat_bot.steps.sponsored.q3'),
//       trigger: '4'
//     },
//     {
//       id: '4',
//       message: t('chat_bot.steps.sponsored.q4'),
//       trigger: '5'
//     },
//     {
//       id: '5',
//       options: [
//         {
//           value: 1,
//           label: t('chat_bot.steps.sponsored.q5.options.1'),
//           trigger: '6'
//         },
//         {
//           value: 2,
//           label: t('chat_bot.steps.sponsored.q5.options.2'),
//           trigger: '13'
//         }
//       ]
//     },
//     {
//       id: '6',
//       message: t('chat_bot.steps.sponsored.q6'),
//       trigger: '7'
//     },
//     {
//       id: '7',
//       message: t('chat_bot.steps.sponsored.q7'),
//       trigger: '8'
//     },
//     {
//       id: '8',
//       message: t('chat_bot.steps.sponsored.q8'),
//       trigger: '9'
//     },
//     {
//       id: '9',
//       message: t('chat_bot.steps.sponsored.q9'),
//       trigger: '10'
//     },
//     {
//       id: '10',
//       component: (
//         <ul style={{ margin: 0, paddingLeft: 23 }}>
//           <li style={{ marginBottom: 5 }}>{t('chat_bot.steps.sponsored.q10.component.line.1')}</li>
//           <li style={{ marginBottom: 5 }}>{t('chat_bot.steps.sponsored.q10.component.line.2')}</li>
//           <li>{t('chat_bot.steps.sponsored.q10.component.line.3')}</li>
//         </ul>
//       ),
//       asMessage: true,
//       trigger: '11'
//     },
//     {
//       id: '11',
//       message: t('chat_bot.steps.sponsored.q11'),
//       trigger: '12'
//     },
//     {
//       id: '12',
//       options: [
//         {
//           value: 1,
//           label: t('chat_bot.steps.sponsored.q12.options.1'),
//           trigger: '15'
//         },
//         {
//           value: 2,
//           label: t('chat_bot.steps.sponsored.q12.options.2'),
//           trigger: '13'
//         }
//       ]
//     },
//     {
//       id: '13',
//       component: (
//         <p style={{ margin: 0 }}>
//           {t('chat_bot.steps.sponsored.q13.component.line.1')}
//           <b>{t('chat_bot.steps.sponsored.q13.component.line.2')}</b>
//         </p>
//       ),
//       asMessage: true,
//       trigger: '14'
//     },
//     {
//       id: '14',
//       message: t('chat_bot.steps.sponsored.q14'),
//       end: true
//     },
//     {
//       id: '15',
//       message: t('chat_bot.steps.sponsored.q15'),
//       trigger: 'sp1'
//     },
//     ...speakWithSteps
//   ];
// };

// const chooseSpeakOptionSteps = (params = {}) => {
//   const {
//     handleSendToDoctor = () => {},
//     openFaqInNewTab = () => {},
//     handleShareTrial = () => {},
//     handleOpenYoutubeDialog = () => {}
//     // t = () => {
//     // }
//   } = params || {};
//
//   return [
//     {
//       id: 'sp1',
//       message: 'What do you have questions about?',
//       trigger: 'sp2'
//     },
//     {
//       id: 'sp2',
//       options: [
//         {
//           value: 1,
//           label: 'My Matched Trial Information',
//           trigger: () => {
//             //trial match result video
//             handleOpenYoutubeDialog('Trial match results', 'https://www.youtube.com/embed/cpKuBB1Zsmw');
//             return 'endMsg';
//           }
//         },
//         {
//           value: 2,
//           label: 'Sharing Information with my doctor',
//           trigger: () => {
//             //share with your doc video
//             handleOpenYoutubeDialog('Share with your doc', 'https://www.youtube.com/embed/bldn95DDuto');
//             return 'sp3';
//           }
//         },
//         {
//           value: 3,
//           label: 'Speaking with a Study doctor',
//           trigger: 'sp5'
//         },
//         {
//           value: 4,
//           label: 'Applying for one of my matched trials',
//           trigger: 'sp9'
//         }
//       ]
//     },
//
//     //Sharing Information with my doctor
//     {
//       id: 'sp3',
//       message: 'Would you like to share information with your doctor?',
//       trigger: 'sp4'
//     },
//     {
//       id: 'sp4',
//       options: [
//         {
//           value: 1,
//           label: 'Share information with my doctor',
//           trigger: () => {
//             // Open share popup
//             handleSendToDoctor();
//             return 'endMsg';
//           }
//         },
//         {
//           value: 2,
//           label: 'No, not right now',
//           trigger: 'endMsg'
//         }
//       ]
//     },
//
//     //Speaking with a Study doctor
//     {
//       id: 'sp5',
//       message: 'Would you like to speak with a study doctor?',
//       trigger: 'sp6'
//     },
//     {
//       id: 'sp6',
//       options: [
//         {
//           value: 1,
//           label: 'Contact Study Site',
//           trigger: () => {
//             // Open Select site popup
//             // If on match screen => Open Select site popup
//             // If on trial screen => Trial concat info
//             handleShareTrial();
//             return 'sp7';
//           }
//         },
//         {
//           value: 2,
//           label: 'No, not right now',
//           trigger: 'endMsg'
//         }
//       ]
//     },
//     {
//       id: 'sp7',
//       message: 'Ok, If you have any additional questions, here is a link to our FAQ.',
//       trigger: 'sp8'
//     },
//     {
//       id: 'sp8',
//       options: [
//         {
//           value: 1,
//           label: 'FAQ',
//           trigger: () => {
//             openFaqInNewTab();
//             return 'endMsg';
//           }
//         }
//       ]
//     },
//
//     //Applying for one of my matched trials
//     {
//       id: 'sp9',
//       message: 'Would you like to speak with a study doctor?',
//       trigger: 'sp10'
//     },
//     {
//       id: 'sp10',
//       options: [
//         {
//           value: 1,
//           label: 'Apply for Study',
//           trigger: () => {
//             //Open Select site popup
//             //If on match screen => Open Select site popup
//             //If on trial screen => Trial concat info
//             handleShareTrial();
//             return 'sp7';
//           }
//         },
//         {
//           value: 2,
//           label: 'No, not right now',
//           trigger: 'endMsg'
//         }
//       ]
//     }
//   ];
// };
//

const matchesNoSponsoredSteps = (params = {}) => {
  const {
    userName = 'chat_bot.steps.sponsored_scenario.user_name.default',
    handleSendToDoctor = () => {},
    handleShareTrial = () => {},
    handleOpenYoutubeDialog = () => {},
    openFaqInNewTab = () => {},
    t = () => {}
  } = params || {};

  return [
    {
      id: '1',
      message: `Hi ${userName}! I’m Rachel. It looks like you received your list of matched trials. Do you have any questions?`,
      trigger: '2'
    },
    {
      id: '2',
      options: [
        {
          value: 1,
          label: 'Need help understanding my results page',
          trigger: () => {
            //clinical trial matches video
            handleOpenYoutubeDialog('Clinical trial matches', 'https://www.youtube.com/embed/b63-IJVbTFE');
            return '4';
          }
        },
        {
          value: 2,
          label: 'No',
          trigger: '4'
        }
      ]
    },

    {
      id: '4',
      message: 'Would you like to speak with a study doctor?',
      trigger: '5'
    },
    {
      id: '5',
      options: [
        {
          value: 1,
          label: 'Contact Study Site',
          trigger: () => {
            // Open Select site popup
            // If on match screen => Open Select site popup
            // If on trial screen => Trial concat info
            handleShareTrial();
            return '6';
          }
        },
        {
          value: 2,
          label: 'No, not right now',
          trigger: '8'
        }
      ]
    },

    //Contact Study Site
    {
      id: '6',
      message: 'Ok. If questions do come up, you can click below for quick, helpful video or check out our FAQs.',
      trigger: '7'
    },
    {
      id: '7',
      options: [
        {
          value: 1,
          label: 'Watch Video',
          trigger: () => {
            //clinical trial matches video
            handleOpenYoutubeDialog('Clinical trial matches', 'https://www.youtube.com/embed/b63-IJVbTFE');
            return 'endMsg';
          }
        },
        {
          value: 2,
          label: 'FAQs',
          trigger: () => {
            openFaqInNewTab();
            return 'endMsg';
          }
        }
      ]
    },

    //No
    {
      id: '8',
      message: 'Would you like to share information with your doctor?',
      trigger: '9'
    },
    {
      id: '9',
      options: [
        {
          value: 1,
          label: 'Share information with my doctor',
          trigger: () => {
            // Open share popup
            handleSendToDoctor();
            return '6';
          }
        },
        {
          value: 2,
          label: 'No, not right now',
          trigger: '6'
        }
      ]
    },

    {
      id: 'endMsg',
      message: t('chat_bot.steps.choose_speak.sp22'),
      end: true
    }
  ];
};

const matchesSponsoredSteps = (params = {}) => {
  const {
    userName = params.t('chat_bot.steps.sponsored.user_name.default'),
    handleSendToDoctor = () => {},
    handleOpenCalendly = () => {},
    openFaqInNewTab = () => {},
    handleEmailUs = () => {},
    // handleShareTrial = () => {},
    handleOpenYoutubeDialog = () => {},
    t = () => {}
  } = params || {};

  // const speakOptionSteps = chooseSpeakOptionSteps({
  //   handleSendToDoctor,
  //   §,
  //   openFaqInNewTab,
  //   handleOpenYoutubeDialog,
  //   t
  // });

  return [
    {
      id: '1',
      message: `Hi ${userName}! I’m Rachel. It looks like you received your list of matched trials. Do you have any questions?`,
      trigger: '2'
    },
    {
      id: '2',
      options: [
        {
          value: 1,
          label: 'Yes',
          trigger: '3'
        },
        {
          value: 2,
          label: 'No',
          trigger: '5'
        }
      ]
    },

    //Yes
    {
      id: '3',
      message: 'I’d be happy to help.',
      trigger: '4'
    },
    {
      id: '4',
      options: [
        {
          value: 1,
          label: 'Need help understanding my results page',
          trigger: () => {
            //clinical trial matches video
            handleOpenYoutubeDialog('Clinical trial matches', 'https://www.youtube.com/embed/b63-IJVbTFE');
            return '5';
          }
        },
        {
          value: 2,
          label: 'Email Us',
          trigger: () => {
            handleEmailUs();
            return '9';
          }
        },
        {
          value: 3,
          label: 'Call Us',
          trigger: () => {
            handleOpenCalendly();
            return '9';
          }
        }
      ]
    },

    //Speaking with a Study doctor
    {
      id: '5',
      message: 'Would you like to speak with a study doctor?',
      trigger: '6'
    },
    {
      id: '6',
      options: [
        {
          value: 1,
          label: 'Contact Study Site',
          trigger: '7'
        },
        {
          value: 2,
          label: 'No, not right now',
          trigger: '9'
        }
      ]
    },
    {
      id: '7',
      message: 'How will you be comfortable to contact?',
      trigger: '8'
    },
    {
      id: '8',
      options: [
        {
          value: 1,
          label: 'Via phone call',
          trigger: () => {
            handleOpenCalendly();
            return '9';
          }
        },
        {
          value: 2,
          label: 'Via email',
          trigger: () => {
            handleEmailUs();
            return '9';
          }
        }
      ]
    },

    {
      id: '9',
      message: 'Would you like to share information with your doctor?',
      trigger: '10'
    },
    {
      id: '10',
      options: [
        {
          value: 1,
          label: 'Share information with my doctor',
          trigger: () => {
            // Open share popup
            handleSendToDoctor();
            return '11';
          }
        },
        {
          value: 2,
          label: 'No, not right now',
          end: true
        }
      ]
    },
    {
      id: '11',
      message: 'Ok. If questions do come up, you can click below for quick, helpful video or check out our FAQs.',
      trigger: '12'
    },
    {
      id: '12',
      options: [
        {
          value: 1,
          label: 'Watch Video',
          trigger: () => {
            //clinical trial matches video
            handleOpenYoutubeDialog('Clinical trial matches', 'https://www.youtube.com/embed/b63-IJVbTFE');
            return 'endMsg';
          }
        },
        {
          value: 2,
          label: 'FAQs',
          trigger: () => {
            openFaqInNewTab();
            return 'endMsg';
          }
        }
      ]
    },

    {
      id: 'endMsg',
      message: t('chat_bot.steps.choose_speak.sp22'),
      end: true
    }
  ];
};

export { matchesSponsoredSteps, matchesNoSponsoredSteps, questionnaireManualOpenSteps, questionnaireMissedAnswerSteps };
