import React from "react";
import "./style.css";
import {EditIcon} from "../../icons";

const MissingEligibilityBtn = ({onClick}) => {
  return (
    <div className="missing-eligibility-btn" onClick={onClick}>
      <span>Missing information</span>
      <div className="edit-icon"><EditIcon /></div>
    </div>
  );
};
export default MissingEligibilityBtn;