import flatten from 'lodash/flatten';

export const tryInferOptions = (question, optionsFromServer) => {
  if (question.options && typeof question.options === 'object') {
    return flatten(Object.values(question.options));
  }

  if (question.options) {
    return question.options;
  }

  if (typeof optionsFromServer?.[question.id]?.options === 'object') {
    return flatten(Object.values(optionsFromServer[question.id].options));
  }

  return [];
};
