import React from 'react';
import './ButtonItem.css';

import PropTypes from 'prop-types';

const ButtonItem = (props) => {
  const {
    icon,
    action,
    title,
    className,
    titleClassName,
    loading = false,
  } = props;
  return (
    <div
      onClick={loading ? () => { } : action}
      className={`button-item ${title ? 'text' : ''} ${className || ''} ${loading ? 'loading' : ''}`}
    >
      <div className="button-item-icon">
        <img alt="icon" src={icon} />
      </div>
      {title && !loading ? (
        <div className={`button-item-title ${titleClassName || ''}`}>
          { title }
        </div>
      ) : null}
      {loading ? <div className="button-item-loading" /> : null}
    </div>
  );
};

export default ButtonItem;

ButtonItem.propTypes = {
  icon: PropTypes.any,
  action: PropTypes.any,
  title: PropTypes.string,
  className: PropTypes.string,
  titleClassName: PropTypes.string,
  loading: PropTypes.bool,
  showSkeleton: PropTypes.bool,
};
