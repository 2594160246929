import React, { useState, useEffect, useRef } from 'react';
import './Login.css';

import app from 'new-ui/app';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useRecaptcha from 'utils/hooks/useRecaptcha';
import { useTranslation } from 'utils/modifiedTranslation';
import * as queryString from 'query-string';

import { authenticationActions } from 'components/Authentication/actions';
import AuthenticationType from 'utils/constants/AuthenticationType';
import { MATCH_FLOW } from 'components/Matches/constants';
import { ROUTE_PATH, ROUTE_NAME } from 'components/AppRoutes/constants';

import PhoneIcon from 'new-ui/Login/components/icons/phone.svg';
import EmailIcon from 'new-ui/Login/components/icons/email.svg';
import analytics from 'functions/analytics';
import { useGoogleOneTapAuth } from 'utils/hooks/useGoogleOneTapAuth';
import { GoogleOneTapAuth } from 'new-ui/Components/GoogleOneTapAuth/GoogleOneTapAuth';
import { PAGES, URI } from 'new-ui/constants';
import OTPLogin from './components/otpLogin';
import EmailLogin from './components/emailLogin';
import { GA_HCP } from 'new-ui/Search/GA';

const SIGNUP_URL = '/?newprofile=true';

const LOGIN_TYPES = {
  EMAIL: 'EMAIL',
  OTP: 'OTP',
};

const LOGIN_ICONS = {
  [LOGIN_TYPES.EMAIL]: {
    icon: PhoneIcon,
    title: 'phone',
  },
  [LOGIN_TYPES.OTP]: {
    icon: EmailIcon,
    title: 'email',
  },
};

const Login = () => {
  const params = new URLSearchParams(window.location.search);
  const type = params.get('type');
  const isFirstRender = useRef(true);
  const { t } = useTranslation();
  const [loginType, setLoginType] = useState(type === 'email' ? LOGIN_TYPES.EMAIL : LOGIN_TYPES.OTP);
  const { recaptcha, getRecaptchaTokenAsync } = useRecaptcha();
  const {
    error,
    profile,
  } = useSelector((state) => ({
    error: state?.authentication?.error,
    profile: state?.profile,
  }));

  const lastUpdatedQuestion = profile?.personal?.last_updated_question;

  const googleOneTapSignInCallback = async (tokenId) => {
    dispatch(
      authenticationActions.requestLogin(
        {
          info: {
            externalAuthParams: {
              tokenId,
            },
          },
          recaptchaToken: await getRecaptchaTokenAsync(),
        },
        AuthenticationType.GOOGLE.toLowerCase(),
      ),
    );
  };

  useGoogleOneTapAuth(googleOneTapSignInCallback);

  useEffect(() => {
    if (type === 'email' && isFirstRender.current) {
      setLoginType(LOGIN_TYPES.EMAIL);
      isFirstRender.current = null;
    }
  }, [type]);

  useEffect(() => {
    if (lastUpdatedQuestion) {
      analytics.quickSignUpLoginPageViewed();
    }
  }, [lastUpdatedQuestion]);

  const { icon: loginIcon, title: iconTitle } = LOGIN_ICONS[loginType];

  if (error) {
    app.hidePreloader();
  }

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    app.hidePreloader();
  }, []);

  useEffect(() => {
    app.intercom.shutdown();
    const {
      tj_access_token,
      tj_refresh_token,
      token,
    } = queryString.parse(window.location.search);

    const silentLogin = async () => {
      const recaptchaToken = await getRecaptchaTokenAsync();
      dispatch(authenticationActions.requestLogin({
        token,
        recaptchaToken,
      }, AuthenticationType.MAGIC_LINK.toLowerCase()));
    };

    if (token) {
      app.showPreloader();
      silentLogin();
    } else if (tj_access_token && tj_refresh_token) {
      app.showPreloader();
      const user = localStorage.getItem('user');
      if (user) {
        try {
          authenticationActions.softLogout(JSON.parse(user)?.user_wix_id);
        } catch (e) {
          console.error(e);
        }
      }
      authenticationActions.setTokens(tj_access_token, tj_refresh_token);
      authenticationActions.setSuperUser();
      dispatch(authenticationActions.finishLogin({
        matchFlow: MATCH_FLOW.LATEST,
        navigationPath: ROUTE_PATH[ROUTE_NAME.RESULTS],
      }));
    }
  }, [dispatch, getRecaptchaTokenAsync]);

  const loginBox = () => (loginType === LOGIN_TYPES.EMAIL ? <EmailLogin /> : <OTPLogin />);

  const loginButtonClick = () => {
    if (loginType === LOGIN_TYPES.EMAIL) return setLoginType(LOGIN_TYPES.OTP);
    return setLoginType(LOGIN_TYPES.EMAIL);
  };

  const loginButtonTitle = () => {
    if (loginType === LOGIN_TYPES.EMAIL) return t('login.login_with_one_time_code');
    return t('login.login_with_email_password');
  };

  const gotoSignup = () => {
    const params = app.getParams();
    if (params.get('redirect') === URI[PAGES.FINANCE]) {
      window.location = `${URI[PAGES.FINANCE]}?create=1`;
      return;
    }
    if (params.get('redirect') === URI[PAGES.SEARCH]) {
      app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.SIGNUP_CLICKED);
      window.location = `${URI[PAGES.SEARCH]}?add=1`;
      return;
    }
    history.push(SIGNUP_URL);
  };

  return (
    <div className="login" id="login">
      <GoogleOneTapAuth />
      {recaptcha}
      <div className="login-container">
        <div className="login-box">
          {
            loginBox()
          }
        </div>
        <div className="login-options">
          <div className="title">{t('login.or_continue_with')}</div>
          <div className="login-buttons">
            <div
              className="login-button"
              onClick={loginButtonClick}
            >
              <img className="icon" src={loginIcon} alt={iconTitle} />
              {
                loginButtonTitle()
              }
            </div>
          </div>
          <div className="no-account">
            {t('login.dont_have_an_account')}
            {' '}
            <span className="inline-button" onClick={gotoSignup}>{t('login.form.footer.signup')}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
