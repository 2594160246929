import React from 'react';
import PropTypes from 'prop-types';
import * as ReactRouterDom from 'react-router-dom';
import * as ReactRedux from 'react-redux';

class PrivateRoute extends React.Component {
  shouldComponentUpdate(nextProps) {
    return !(this.props.loggedIn === nextProps.loggedIn && this.props.component === nextProps.component);
  }

  render() {
    const { loggedIn, component: Component, ...rest } = this.props;
    return (
      <ReactRouterDom.Route
        {...rest}
        render={(props) => (loggedIn ? <Component {...props} /> : <ReactRouterDom.Redirect to="/" />)}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  loggedIn: !!(state.authentication && state.authentication.loggedIn && state.authentication.user),
});

PrivateRoute.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

export default ReactRouterDom.withRouter(ReactRedux.connect(mapStateToProps)(PrivateRoute));
