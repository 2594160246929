import React from 'react';
import './style.css';
import OptionCheckbox from '../../Checkbox';
import OptionRadio from '../../Radio';

const ClinicalTrials = ({ category, selectedOptions, onOptionChange, options, withRadio }) => {

    const handleOptionChange = (value) => {
        const isChecked = selectedOptions.includes(value);
        onOptionChange(category, value, !isChecked);
    };

    return (
        <div className={`clinical-trial-options ${withRadio ? 'radio-list' : ''}`}>
            <ul>
                {options.map((option) => (
                    <li key={option.value}>
                        {withRadio ? (
                            <OptionRadio
                                label={option.label}
                                checked={selectedOptions.includes(option.value)}
                                onChange={() => handleOptionChange(option.value)}
                            />
                        ):(
                            <OptionCheckbox
                                label={option.label}
                                checked={selectedOptions.includes(option.value)}
                                onChange={() => handleOptionChange(option.value)}
                            />
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ClinicalTrials;
