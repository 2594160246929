import React, { useState } from 'react';
import './style.css';
import Radio from 'new-ui/Components/Form/Radio';
import { useTranslation } from 'utils/modifiedTranslation';

const StepThree = ({ data, onChange }) => {
    const { t } = useTranslation();

    const [selectedOption, setSelectedOption] = useState(null);

    const handleSelect = (id) => {
        setSelectedOption(id);
        onChange(id);
    };

    return (
        <div className='step step-3'>
            {/* <h2 className='drug-title'>Cisplatin</h2> */}
            <h2 className='title'>{t('treatment_widget.steps.timing.title')}</h2>
            <div className='treatment-list'>
                <Radio
                    key={'timing'}
                    options={data}
                    value={selectedOption}
                    onChange={handleSelect}
                />
            </div>
        </div>
    );
};

export default StepThree;