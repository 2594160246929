import internalConstants from '../constants/internal';

const initialState = {
  isInternal: 0,
};

export function internal(state = initialState, action) {
  const { type } = action;

  switch (type) {
    case internalConstants.SET_INTERNAL:
      return {
        isInternal: 1,
      };
    default:
      return state;
  }
}
