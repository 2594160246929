import _ from 'lodash';
import { deepFind } from 'new-ui/functions';
import { flattenAnswers } from './flattenAnswers';

export function checkIfNotEqualTo(profile, condition, state) {
  const clonedProfile = flattenAnswers(profile);

  const { ifId, ifValue, stateId } = condition;

  if (stateId) {
    const stateValue = deepFind(state, stateId);
    return !_.isEqual(stateValue, ifValue);
  }

  const profileValue = clonedProfile[ifId];

  // Check in store if the condition not equals
  if (!_.hasIn(clonedProfile, ifId)) {
    // In case the ifId is not in profile
    return true;
  }
  if (_.isArray(profileValue) && !_.isArray(ifValue)) {
    // In case the attribute is array of strings
    return !_.includes(profileValue, ifValue);
  }
  if (!_.isArray(profileValue) && _.isArray(ifValue) && ifValue.length === 1) {
    return !_.includes([profileValue], ifValue[0]);
  }
  const checkIf = _.isEqual(clonedProfile[ifId], ifValue);

  return !checkIf;
}
