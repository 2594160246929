import React from 'react';
import './style.css';
import Icon from 'new-ui/assets/icons/dot.svg';

const AdministrationTypes = ({ options }) => {
  return (
    <ul className='administration-list'>
      <img src={Icon} alt='icon' />
      {options.map((option, index) => (
        <li key={option}>
          {option}
          {index < options.length - 1 && ','}
        </li>
      ))}
    </ul>
  );
};

export default AdministrationTypes;