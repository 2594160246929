export default {
  IS_FETCHING_SET: 'IS_FETCHING_SET',
  QUESTIONNAIRE_STEP: 'QUESTIONNAIRE_STEP',
  FORM_FIELD_VALUE_SET: 'FORM_FIELD_VALUE_SET',
  QUESTIONNAIRE_STATE_SET: 'QUESTIONNAIRE_STATE_SET',
  GET_QUESTION_OPTIONS_REQUEST: 'GET_QUESTION_OPTIONS_REQUEST',
  GET_QUESTION_OPTIONS_SUCCESS: 'GET_QUESTION_OPTIONS_SUCCESS',
  GET_QUESTION_OPTIONS_FAILURE: 'GET_MATCHES_FAILURE',
  IS_LAST_QUESTION: 'IS_LAST_QUESTION',
  IS_NOT_LAST_QUESTION: 'IS_NOT_LAST_QUESTION',
  SET_SIDEBAR_VISIBILITY: 'SET_SIDEBAR_VISIBILITY',
  QUESTIONNAIRE_SET_FILES: 'QUESTIONNAIRE_SET_FILES',
  QUESTIONNAIRE_ADD_FILES: 'QUESTIONNAIRE_ADD_FILES',
  QUESTIONNAIRE_REMOVE_FILES: 'QUESTIONNAIRE_REMOVE_FILES',
  QUESTIONNAIRE_UPDATE_FILES: 'QUESTIONNAIRE_UPDATE_FILES',
  GET_ACTIVE_DISEASE: 'GET_ACTIVE_DISEASE',
  QUESTIONNAIRE_SET_PSTACK: 'QUESTIONNAIRE_SET_PSTACK',
  QUESTIONNAIRE_UPDATE_BETWEEN_QUESTIONS: 'QUESTIONNAIRE_UPDATE_BETWEEN_QUESTIONS',
  QUESTIONNAIRE_SKIP_STEP: 'QUESTIONNAIRE_SKIP_STEP',
  QUESTIONNAIRE_NAVIGATION_DISABLED: 'QUESTIONNAIRE_NAVIGATION_DISABLED',
};
