import React from 'react';
import './style.css';
import Button from 'new-ui/Components/Button';

const WidgetBtn = ({title, onClick}) => {
    return (
        <Button
            backgroundColor='#213332' 
            color='#FFF'
            width='250px'
            className='widget-btn' 
            title={title}
            action={onClick}
        />
    );
};

export default WidgetBtn;