import { CancerFieldsByIndication } from 'new-ui/api/data/cancerTypes';
import app from 'new-ui/app';

export const getFinanceParams = (user = app.user, finance_profile = {}, aidTypes = [])=>{
  const userWixId = user?.info?.user_wix_id;
  const email = user?.info?.email;
  const dob = user?.personal?.dob;
  let gender = user?.personal?.gender || null;
  const indication = user?.personal?.condition;
  let race = user?.condition_profile.race;
  const stageField = CancerFieldsByIndication[indication].patientStage;
  const stage = stageField ? user?.condition_profile[stageField] : null;
  let age;
  try {
    age = new Date().getFullYear() - new Date(dob).getFullYear();
  } catch (e) {
    age = null;
  }
  Object.keys(finance_profile).forEach((f)=>{
    if (finance_profile[f] === 'yes') finance_profile[f] = true;
    else if (finance_profile[f] === 'no') finance_profile[f] = false;
  });
  const {
    helpReceivedBefore = null,
    hasSsn = null,
    hasTreatment = null,
    numOfHouseholdMembers = null,
    income = null,
    insurance = null,
  } = finance_profile;

  let location = null;
  if (Object.keys(user?.info?.country || {}).length) {
    location = user?.info?.country?.location;
    // location.label = user?.info?.country?.label;
    delete location.approximateLat;
    delete location.approximateLng;
  }

  if (Array.isArray(gender)) gender = gender[0];

  if (!race) race = null;

  return {
    userWixId,
    email,
    aidTypes,
    helpReceivedBefore,
    hasSsn,
    hasTreatment,
    numOfHouseholdMembers,
    income,
    insurance,
    age,
    gender,
    stage: Array.isArray(stage) ? stage[0] : stage,
    indication,
    race,
    location,
  };
};

window.__getFinanceParams = getFinanceParams;

export const getFinanceEligiblePrograms = (financePrograms = [])=>{
  const eligibles = financePrograms.filter((p)=>{
    if (!p.validatedFields) return true;
    const fields = Object.keys(p.validatedFields);
    if (!fields.length) return true;
    let flag = true;
    fields.forEach((field)=>{
      const d = p.validatedFields[field];
      if (!d) flag = false;
    });
    return flag;
  });
  return eligibles;
};

export const isProgramEligible = (program) => {
  if (!program.validatedFields) return true;
  const fields = Object.keys(program.validatedFields);
  if (!fields.length) return true;
  return fields.every((field) => program.validatedFields[field]);
};
