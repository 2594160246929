import React from 'react';

import { PopupTitle, PopupFooter } from 'new-ui/Components/Popup';
import Button from 'new-ui/Components/Button';
import { useTranslation } from 'utils/modifiedTranslation';
import app from 'new-ui/app';

import PropTypes from 'prop-types';
import { POPUP_TYPES } from 'new-ui/constants';

import './Questions.css';

const Questions = (props) => {
  const { questions } = props;
  const { t } = useTranslation();

  const onQuestionAnswered = async (questionDone) => {
    app.setShowPreloader(true);
    await app.update({ rematch: true });
    app.setShowPreloader(false);
    app.renderPage();
    if (questionDone) {
      questions.splice(questions.indexOf(questionDone), 1);
      if (!questions.length) return app.setPopup(false);
      app.setPopup(POPUP_TYPES.QUESTIONS, { questions });
    }
  };

  const editQuestion = (question) => {
    app.editQuestion(question, () => {
      onQuestionAnswered(question);
    });
  };

  const processQuestion = (qid = 0) => {
    const question = questions[qid];
    if (!question) {
      onQuestionAnswered();
      return;
    }
    app.setPopup(false);
    app.editQuestion(question, async () => {
      processQuestion(qid + 1);
    });
  };

  const updateAll = () => {
    processQuestion();
  };

  return (
    <div className="questions-popup">
      <PopupTitle title={t('questions_popup.title')} />
      {
        questions.map((q, i) => (
          <div key={i} className="question" onClick={() => { editQuestion(q); }}>
            <div className="question-circle">{i + 1}</div>
            <div className="question-title">{t(`missing_critical.question.${q}`)}</div>
          </div>
        ))
      }
      <PopupFooter>
        <Button title={t('questions_popup.button')} action={updateAll} />
      </PopupFooter>
    </div>
  );
};

Questions.propTypes = {
  questions: PropTypes.array,
};

export default Questions;
