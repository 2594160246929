import React from 'react';
import './ProviderFooter.css';
import LogoSmall from 'new-ui/assets/logo_small.svg';
import { useTranslation } from 'utils/modifiedTranslation';
import { MAIN_WEBSITE_LINKS } from 'new-ui/constants';

const ProviderFooter = () => {
  const { t } = useTranslation();
  return (
    <div className="provider-footer">
      <div>
        <img alt="logo" src={LogoSmall} />
        <span className="provider-middle">{t('leal_health_footer.powered_by')}</span>
      </div>
      <div>
        <span
          className="provider-middle link"
          onClick={() => {
            window.open(MAIN_WEBSITE_LINKS.PRIVACY_POLICY, '_blank');
          }}
        >
          {t('authentication.policy.text')}
        </span>
      </div>
    </div>
  );
};

export default ProviderFooter;
