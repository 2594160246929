import { ACTIONS } from './constants';

const initialState = {
  isProcessing: false,
  hasErrors: false,
  isSuccess: false
};

export const shareTrialsEmail = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case ACTIONS.SHARE_TRIALS_EMAIL_START:
      return {
        ...state,
        isProcessing: true
      };
    case ACTIONS.SHARE_TRIALS_EMAIL_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        isSuccess: true
      };
    case ACTIONS.SHARE_TRIALS_EMAIL_ERROR:
      return {
        ...state,
        isProcessing: false,
        hasErrors: true,
        isSuccess: false
      };
    case ACTIONS.SHARE_TRIALS_EMAIL_RESET_STATE:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
