import { applyMiddleware, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import ReduxThunk from 'redux-thunk';
// import { createLogger } from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// import { createLogger } from 'redux-logger';
import appReducer from './reducers';
import { history } from '../functions/history';
import { googleAnalytics } from './middlewares/reactGAMiddlewares';
import userConstants from '../components/Authentication/constants'; // defaults to localStorage for web and AsyncStorage for react-native
import configs from '../configs';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [],
  blacklist: ['router'],
};

const rootReducer = (state, action) => {
  if (action.type === userConstants.LOGOUT) {
    state = undefined;
    storage.removeItem('persist:root');
  }
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
// const loggerMiddleware = createLogger();
const middleware = [ReduxThunk, routerMiddleware(history)];

if (!configs.isProduction) {
  // middleware.push(loggerMiddleware);
}
if (configs.isProduction || configs.gaTrackingCode) {
  // add google Analytics in Production
  middleware.push(googleAnalytics);
}

// If production, disable trace
const composeEnhancer = composeWithDevTools({
  trace: !configs.isProduction,
  traceLimit: 25,
});

export const store = createStore(
  persistedReducer,
  !configs.isProduction ? composeEnhancer(applyMiddleware(...middleware)) : applyMiddleware(...middleware),
);
export const persistor = persistStore(store);
