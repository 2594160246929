import React, { useState, useEffect, useRef } from 'react';
import './UserIntent.css';

import app from 'new-ui/app';

import { useTranslation } from 'utils/modifiedTranslation';

import Form from 'new-ui/Components/Form';

import {
  PopupTitle, PopupContent, PopupButton,
} from 'new-ui/Components/Popup';
import PropTypes from 'prop-types';

import { USER_INTENT_TYPES, POPUP_TYPES } from 'new-ui/constants';
// import { removeUserIntentData } from 'new-ui/ActionFlows/user_intent';

export const setUserIntent = async (type, other = '') => {
  app.coniguration.data.userIntent = { type: type.toUpperCase() };
  app.coniguration.data.userIntent.other = other;
  await app.setConfiguration(app.coniguration.data);
};

const UserIntent = (props) => {
  const { closePopup } = props;
  const { t } = useTranslation();

  const [form, setForm] = useState(null);
  const [type, setType] = useState(null);
  const [showOther, setShowOther] = useState(null);

  const other = useRef(null);
  const _ = useRef(t);

  useEffect(() => {
    const userIntent = app.coniguration?.data?.userIntent || {};
    const f = {
      inputs: {
        type: {
          type: 'radio',
          options: [
            {
              value: USER_INTENT_TYPES.TREATMENT,
              title: _.current('user_intent.treatment'),
            },
            {
              value: USER_INTENT_TYPES.RESEARCH,
              title: _.current('user_intent.research'),
            },
            {
              value: USER_INTENT_TYPES.EDUCATE,
              title: _.current('user_intent.educate'),
            },
            {
              value: USER_INTENT_TYPES.OTHER,
              title: _.current('user_intent.other'),
            },
          ],
        },
      },
      data: {
        type: userIntent.type,
        other: userIntent.other || '',
      },
    };
    setForm(f);
    other.current.value = f.data.other || '';
    if (f.data.type === USER_INTENT_TYPES.OTHER) setShowOther(true);
  }, [_]);

  const onChange = () => {
    setType(form.data.type);
    if (form.data.type === USER_INTENT_TYPES.OTHER) {
      setShowOther(true);
      setTimeout(() => {
        other.current.focus();
        other.current.scrollIntoView();
      }, 10);
    } else {
      setShowOther(false);
    }
  };

  const save = async () => {
    await setUserIntent(type, form.data.other);
    closePopup();
    app.renderPage();
    app.alert([
      <div key="alert">
        {t('user_intent.thanks')}
        {' '}
        <span
          className="edit-button"
          onClick={() => {
            app.alertHide();
            app.setPopup(POPUP_TYPES.MEDICAL_PROFILE);
          }}
        >
          {t('medical_profile.edit')}
        </span>
      </div>,
    ]);
  };

  const onEdit = (e) => {
    form.data.other = e.target.value;
  };

  return (
    <div className="user-intent">
      <PopupTitle title={t('user_intent.title')} />
      <PopupContent>
        <div className="user-intent-title">
          {t('user_intent.description')}
        </div>
        {form && <Form form={form} onChange={onChange} />}
        <div hidden={!showOther}>
          <textarea ref={other} className="user-intent-other" onChange={onEdit} placeholder={t('user_intent.other_placeholder')} />
        </div>
      </PopupContent>
      {type && <PopupButton title={t('user_intent.save')} action={save} />}
    </div>
  );
};

UserIntent.propTypes = {
  closePopup: PropTypes.func,
};

export default UserIntent;
