const minimum1Uppercase = /[A-Z]/;
const minimum1Number = /\d/;

export default (password = '') => {
  const isValidLength = password.length >= 8;
  const hasMin1Number = minimum1Number.test(password);
  const hasMin1Uppercase = minimum1Uppercase.test(password);

  if (!hasMin1Number && !hasMin1Uppercase && !isValidLength) {
    return 'login.form.password.error_message'; // all
  }

  if (hasMin1Uppercase && isValidLength && !hasMin1Number) {
    return 'login.form.password.one_number'; // missing: minimum one number
  }

  if (hasMin1Uppercase && !isValidLength && hasMin1Number) {
    return 'login.form.password.length'; // missing: 8 chars
  }

  if (!hasMin1Uppercase && isValidLength && hasMin1Number) {
    return 'login.form.password.upper_letter'; // missing: one upppercase
  }

  if (!hasMin1Uppercase && !isValidLength) {
    return 'login.form.password.lenght_and_upper_letter'; // missing: minimum one upper and 8 chars
  }

  if (!hasMin1Uppercase && !hasMin1Number) {
    return 'login.form.password.upper_letter_and_number'; // missing: minimum one upper and minimum one number
  }

  if (!isValidLength && !hasMin1Number) {
    return 'login.form.password.lenght_and_number'; //  missing: minimum one number and 8 chars
  }

  return null;
};
