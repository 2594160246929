export default {
  int: 'int',
  fraction: 'fraction',
  percentage: 'percentage',
  gDL: 'gDL',
  cellUl: 'cellUl',
  uLCube: 'uLCube',
  exclude: 'exclude',
  intWithoutUnit: 'intWithoutUnit',
  fractionWithoutUnit: 'fractionWithoutUnit',
  uML: 'uML',
};
