import * as Sentry from '@sentry/browser';
import * as queryString from 'query-string';
import configs from 'configs';
import { authHeaders } from 'functions/authHeader';
import handleResponse from 'functions/handleResponse';

const apiUrl = configs.beApiUrl;

export const fetchSponsoredTrialTemplateData = async ({
  nctId, conditions, nctNumber, indication,
}) => {
  const requestOptions = {
    method: 'GET',
    headers: await authHeaders(),
  };

  const url = `${apiUrl}/public/sponsoredTrial/templateData/${nctId}?${queryString.stringify({
    conditions: JSON.stringify(conditions),
    nctNumber,
    indication,
  })}`;

  return fetch(url, requestOptions)
    .then(handleResponse)
    .catch((ex) => {
      Sentry.captureException(ex);
      console.error('Error occurred, while getting profile data:', ex);
    });
};

export const checkIfSponsoredTrialTemplateExists = async (nctId, conditions, requiredBlockName) => {
  const requestOptions = {
    method: 'GET',
    headers: await authHeaders(),
  };

  const url = `${apiUrl}/public/sponsoredTrial/checkIfTemplateExists/${nctId}?${queryString.stringify({
    conditions: JSON.stringify(conditions),
    requiredBlockName,
  })}`;

  return fetch(url, requestOptions)
    .then(handleResponse)
    .catch((ex) => {
      Sentry.captureException(ex);
      console.error('Error occurred, while getting profile data:', ex);
    });
};
