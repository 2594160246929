import React from 'react';
import { useTranslation as originalUseTranslation } from 'react-i18next';
import request from 'functions/api/request';
import { authHeaders } from 'functions/authHeader';
import getUrlWithQueryString from 'functions/getUrlWithQueryString';
import configs from 'configs';

const apiUrl = configs.beApiUrl;

let missingTranslationKeys = [];
let sentKeys = [];

export async function postKeysToDoc() {
  const url = getUrlWithQueryString(`${apiUrl}/translations/`, {});

  const requestOptions = {
    url,
    headers: await authHeaders(),
    data: missingTranslationKeys,
    json: true,
  };

  try {
    return await request.post(requestOptions);
  } catch (ex) {
    console.error('Error occurred, while getting Treatment Experience survey:', ex);
    throw ex;
  }
};

const sendMissingKeys = async (key) => {
  try {
    await postKeysToDoc(key);
  } catch (error) {
    console.error('Error posting missing key:', error);
  }
};

let timeout = null;

const resetTimeout = () => {
  clearTimeout(timeout);
  timeout = setTimeout(async () => {
    await sendMissingKeys();
    sentKeys = [...sentKeys, ...missingTranslationKeys];
    missingTranslationKeys = [];
  }, 3000);
};

export function useTranslation() {
  const { t: originalT, i18n } = originalUseTranslation();

  // Wrap the original `t` function
  const t = (key, ...args) => {
    // Check if the key exists in the translation file
    window._debug = {
      i18n,
      key,
    };
    if (!i18n.exists(key)) {
      if (![...sentKeys, ...missingTranslationKeys].includes(key)) {
        // console.warn(`Translation key "${key}" not found`, missingTranslationKeys);
        missingTranslationKeys.push(key);
        resetTimeout();
      }

      return key;
    }

    return originalT(key, ...args);
  };

  // Return the wrapped `t` function
  return { t };
}

const TransComp = ({ transKey }) => {
  const { t } = useTranslation();
  return t(transKey);
};

export const t = (k) => <TransComp transKey={k} />;