/* eslint-disable import/no-named-as-default */
/* eslint-disable no-unused-vars */
/* eslint-disable operator-linebreak */
/* eslint-disable react/button-has-type */
/* eslint-disable import/no-duplicates */
/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-trailing-spaces */
/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable no-console */
/* eslint-disable prefer-template */
/* eslint-disable arrow-parens */
/* eslint-disable array-callback-return */
import React from 'react';
import app from 'new-ui/app';
import { CHAT_ROLES } from 'new-ui/constants';
import Intake from 'new-ui/Components/Intake';
import AnswerPicker from './AnswerPicker/AnswerPicker';
import './Chat.css';
import { sendMessage, startChatSession, updateChatScore } from './api';

export const updateChatHistory = (setChatHistory, chatHistory, content, role, daynamicContent) => {
  setChatHistory(chatHistory => [
    ...chatHistory,
    {
      role: role ?? CHAT_ROLES.JANE,
      content: ` ${formatTextToUseNewLineBeforeNumbers(content)}`,
      daynamicContent: daynamicContent ?? null,
    },
  ]);
};
export const hoursDiff = (dt1, dt2) => {
  const date1 = new Date(dt1);
  const date2 = new Date(dt2);
  const millisecondsDiff = date2.getTime() - date1.getTime();
  const diffInHours = millisecondsDiff / (1000 * 60 * 60);

  return diffInHours;
};

export const isMoreThan29DaysApart = (dt1, dt2) => {
  const date1 = new Date(dt1);
  const date2 = new Date(dt2);
  const millisecondsDiff = date2.getTime() - date1.getTime();
  const diffInDays = millisecondsDiff / (1000 * 60 * 60 * 24);

  return diffInDays > 29;
};

export const returnChatUserOpenSentence = (
  showUpdateIntentPicker,
  setShowUpdateIntentPicker,
  setChatHistory,
  chatHistory,
  userIntention
) => {
  // Check if a selection has been made previously

  const currentStage = `you stated that your disease is stage ${app.user.condition_profile.current_stage}`;

  const handleIntentionSelection = async option => {
    setShowUpdateIntentPicker(false);
    switch (option) {
      case 'no':
        setChatHistory([
          {
            role: CHAT_ROLES.JANE,
            content: '',
            daynamicContent: (
              <div>
                <p>Hello, I am Jane. Its so nice to see you again after so much time.</p>
                <p>
                  {currentUserIntent}
                  <br /> Do you want to update you status?
                </p>
              </div>
            ),
          },
        ]);
        updateChatHistory(setChatHistory, '', " I won't like to update my intention status", CHAT_ROLES.ME);
        updateChatHistory(
          setChatHistory,
          chatHistory,
          " It's completely understandable if you don't want to share, for any other question you want I'm here for you\n let see what we know so for",
          CHAT_ROLES.JANE,
          <>
            <p>{currentStage}</p>
            {currentTreatment}
            <p>
              It is important to know, keeping details update will help us find the most suitable treatments or trails
              for you
              <br />
              remember you can always update your profile
            </p>
          </>
        );
        break;
      case 'yes':
        setChatHistory([
          {
            role: CHAT_ROLES.JANE,
            content: '',
            daynamicContent: (
              <div>
                <p>Hello, I am Jane. Its so nice to see you again after so much time.</p>
                <p>
                  {currentUserIntent}
                  <br /> Do you want to update you status?
                </p>
              </div>
            ),
          },
        ]);
        updateChatHistory(setChatHistory, '', ' I will like to update my intention status', CHAT_ROLES.ME);
        updateChatHistory(
          setChatHistory,
          chatHistory,
          ' What a great news!,This will help us help you get the most help from us on your journey',
          CHAT_ROLES.JANE,
          userIntention
        );
        break;
      default:
        break;
    }
    // Store the selection status in local storage
  };

  const updateUserIntention = showUpdateIntentPicker ? (
    <div id="user-intention-picker-container">
      <AnswerPicker
        key="userIntention"
        options={[
          {
            value: 'yes',
            title: 'I will like to update my intention status',
          },
          {
            value: 'no',
            title: "I won't like to update my intention status",
          },
        ]}
        onOptionSelected={option => handleIntentionSelection(option)}
      />
    </div>
  ) : null;

  let currentTreatment = '';
  if (app.user.condition_profile.treatments.length === 0) {
    currentTreatment = <p>You did not report any treatments</p>;
  } else {
    currentTreatment = (
      <table style={{ borderCollapse: 'collapse', width: '100%' }}>
        <thead>
          <tr style={{ backgroundColor: '#f2f2f2' }}>
            <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd' }}>Treatment</th>
            <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd' }}>Taking State</th>
            <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd' }}>End Date</th>
          </tr>
        </thead>
        <tbody>
          {app.user.condition_profile.treatments.map(treatment => {
            const endDate = treatment.treatment_end_date
              ? new Date(treatment.treatment_end_date).toLocaleDateString()
              : 'N/A';
            return (
              <tr key={treatment.id}>
                <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd' }}>
                  {treatment.drugs_received.join(', ')}
                </td>
                <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd' }}>
                  {treatment.taking_state}
                </td>
                <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd' }}>{endDate}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  let currentUserIntent = '';
  console.log('🚀 ~ app.coniguration.data.userIntent:', app.coniguration.data.userIntent);
  switch (app.coniguration.data.userIntent.type) {
    case 'need_treatment':
      currentUserIntent = 'In the last time we talked you said you are looking for a treatment';
      break;
    case 'in_treatment':
      currentUserIntent = 'In the last time we talked you said you are in a treatment';
      break;
    case 'waiting_for_scan':
      currentUserIntent = 'In the last time we talked you said you are waiting for a scan';
      break;
    default:
      currentUserIntent = null;
      break;
  }

  return (
    <div>
      <p>Hello, I am Jane. Its so nice to see you again after so much time.</p>
      <p>
        {currentUserIntent ? (
          <>
            {currentUserIntent}
            <br /> Do you want to update you status?
          </>
        ) : (
          <>
            Please tell us why you are looking for <br /> {userIntention}
          </>
        )}
      </p>
      {showUpdateIntentPicker && currentUserIntent ? updateUserIntention : null}
    </div>
  );
};

const nctFormatter = nct => {
  const parts = nct.split('-', 1);
  return parts[0];
};

export const formatTextToUseNewLineBeforeNumbers = text => {
  try {
    return text.replace('**', '').replace(/\n/g, '<br />');
  } catch (error) {
    return text;
  }
};

export const socTreatmentsCategoriesFormatter = socTreatments => {
  if (!socTreatments) {
    return [];
  }

  const haveSupportiveCare = socTreatments.some(treatment => {
    return treatment.treatment_category_key.some(key => key === 'supportive_care');
  });

  let formattedSocTreatments = socTreatments.map(treatment => ({
    treatment_administration_type: treatment.treatment_administration_type,
    drug_category_of_evidence: treatment.drug_category_of_evidence,
    drug_category_of_preference: treatment.drug_category_of_preference,
    treatment_category_key: treatment.treatment_category_key,
    treatment_settings: treatment.treatment_settings,
    drug_category_of_preference_key: treatment.drug_category_of_preference_key,
    drug_name: treatment.drug_commercial_name,
  }));
  if (!haveSupportiveCare) {
    return formattedSocTreatments;
  }
  formattedSocTreatments = formattedSocTreatments.filter(
    treatment => treatment.treatment_category_key !== 'supportive_care'
  );

  return formattedSocTreatments;
};

export const scheduleACallHandler = (
  pharseResponse,
  userHaveSponseredTrialList,
  setChatHistory,
  setUserChatMode,
  setShowChatNextScan,
  userChatMode
) => {
  setShowChatNextScan(false);
  try {
    if (userChatMode !== '1') {
      setUserChatMode('2');
    }
    if (userHaveSponseredTrialList) {
      pharseResponse += ` 
      <br/><a class="link" href="https://webapp.leal.health/dashboard/results?popup=consult_with_us"">Schedule a call</a>`;
    } else {
      pharseResponse += `
      We founded this company to provide patients with hope by offering a variety of treatment options. 
      Your oncologist and their clinic are eager to receive these treatment options from you.
      We strongly recommend discussing these options with your doctor.
      Simply click on the following link to send your doctor the treatment options: 
      <a class="link" href="https://webapp.leal.health/dashboard/results?popup=share_with_doctor">Share with your doctor</a>`;
    }
  } catch (error) {
    setChatHistory(chatHistory => [
      ...chatHistory,
      {
        role: 'Tina (patient navigator)',
        content: ` ${error}`,
      },
    ]);
  }
  setChatHistory(chatHistory => [
    ...chatHistory,
    {
      role: 'Tina (patient navigator)',
      content: ` ${formatTextToUseNewLineBeforeNumbers(pharseResponse)}`,
    },
  ]);
};

export const shareWithYourDoctorHandler = (pharseResponse, setChatHistory, setUserChatMode, setShowChatNextScan) => {
  setShowChatNextScan(false);
  try {
    pharseResponse += `
      We founded this company to provide patients with hope by offering a variety of treatment options. 
      Your oncologist and their clinic are eager to receive these treatment options from you.
      We strongly recommend discussing these options with your doctor.
      Simply click on the following link to send your doctor the treatment options: 
      <a class="link" href="https://webapp.leal.health/dashboard/results?popup=share_with_doctor">Share with your doctor</a>`;
  } catch (error) {
    setChatHistory(chatHistory => [
      ...chatHistory,
      {
        role: 'Tina (patient navigator)',
        content: ` ${error}`,
      },
    ]);
  }
  setChatHistory(chatHistory => [
    ...chatHistory,
    {
      role: 'Tina (patient navigator)',
      content: ` ${formatTextToUseNewLineBeforeNumbers(pharseResponse)}`,
    },
  ]);
  setUserChatMode('2');
};
// eslint-disable-next-line arrow-parens
export const formmatedTrials = trials => {
  const sortedTrials = trials
    .sort((a, b) => {
      if (a.score !== b.score) {
        return b.score - a.score;
      }
    })
    .map(a => ({
      nct_number: nctFormatter(a.nct_number),
      brief_summary: a.brief_summary,
      main_objectives: a.main_objectives,
      treatment_administration_type: a.treatment_administration_type,
      enrollment: a.enrollment,
      phases: a.phases,
      study_type: a.study_type,
      sponsorName: a.sponsorName,
      groups: a.groups,
      isSponsored: a.isSponsored,
      closest_facility: a.closest_facility,
    }));

  return sortedTrials;
};

export const updateNextScanHandler = (smartChatResponse, setShowChatNextScan, setChatHistory, shouldShow) => {
  let phraseResponse;
  try {
    phraseResponse = JSON.parse(smartChatResponse.msg)?.content;
    setShowChatNextScan(JSON.parse(smartChatResponse.msg)?.showChatNextScan ?? shouldShow);

    const $chatPopup = document.querySelector('.chat-popup');
    if ($chatPopup) {
      $chatPopup.scrollTop = $chatPopup.scrollHeight;
    }
  } catch (error) {
    phraseResponse = smartChatResponse?.msg ?? shouldShow;
    if (shouldShow) {
      phraseResponse = 'Please share with use your last scan date';
      setShowChatNextScan(true);
    }
  }
  setChatHistory(chatHistory => [
    ...chatHistory,
    {
      role: 'Tina (patient navigator)',
      content: ` ${formatTextToUseNewLineBeforeNumbers(phraseResponse)}`,
    },
  ]);
};

const userWantToApplyToTrialHandler = (setUserChatMode, setChatHistory, userHaveSponseredTrialList) => {
  if (userHaveSponseredTrialList) {
    setChatHistory(chatHistory => [
      ...chatHistory,
      {
        role: 'Tina (patient navigator)',
        content: `<br/> We understand that you are interested in information about how you can register for the trail <br/>
           Please click the link and let's have a call <br/><a class="link" href="https://webapp.leal.health/dashboard/results?popup=consult_with_us"">Schedule a call</a>
           `,
      },
    ]);
  } else {
    setChatHistory(chatHistory => [
      ...chatHistory,
      {
        role: 'Tina (patient navigator)',
        content:
          'We understand that you are interested in information about how you can register for the trail, the most efficient way is by clicking the Learn More button on the trail that interested you on your profile page',
      },
    ]);
  }

  setUserChatMode(2);
};

const handleCheckIntakeDoneOnClick = (setChatHistory, chatHistory, socTreatments) => {
  const newTrialList = app?.trials.length ?? 0;

  const chatIntakeElement = document.getElementById('chat-intake-warpper');
  if (chatIntakeElement) {
    chatIntakeElement.parentNode.removeChild(chatIntakeElement);
  }
  const buttonElement = document.getElementById('remove-button'); // Select the button
  if (buttonElement) {
    buttonElement.parentNode.removeChild(buttonElement);
  }

  updateChatHistory(
    setChatHistory,
    chatHistory,
    socTreatments.length === 0
      ? ` Thank you for the update. You have options for ${newTrialList} clinical trials, \nCould you let us know in what you're interested in?`
      : ` Thank you for the update. You have options for ${newTrialList} clinical trials and ${socTreatments.length} standard treatments.\n Could you let us know if you're interested in clinical trials, standard treatments, or both? Your preference will guide us in providing the right support for you.`,
    CHAT_ROLES.JANE
  );
};
export const handleSendMessage = async (
  prompt,
  chatHistory,
  setChatHistory,
  userTrialList,
  userWixId,
  treatmentCategories,
  missingCritical,
  indication,
  userChatMode,
  setUserChatMode,
  setLoading,
  setShowChatNextScan,
  userHaveSponseredTrialList,
  userLocation,
  userSponsoredTrials,
  sponsoredTrialsExaplained,
  setSponsoredTrialsExaplained,
  setShowThumbs,
  socTreatmentsCategories,
  socTreatments
) => {
  setLoading(true);
  if (prompt) {
    setChatHistory(chatHistory => [
      ...chatHistory,
      { role: CHAT_ROLES.ME, content: ` ${formatTextToUseNewLineBeforeNumbers(prompt)}` },
    ]);

    let response;

    try {
      if (userChatMode !== '-1') {
        response = await sendMessage(
          prompt,
          userTrialList,
          userWixId,
          treatmentCategories,
          missingCritical,
          indication,
          userChatMode,
          userHaveSponseredTrialList,
          userSponsoredTrials,
          sponsoredTrialsExaplained,
          socTreatmentsCategories,
          socTreatments,
          userLocation,
          setShowChatNextScan
        );
      } else {
        response = await startChatSession(
          prompt,
          userWixId,
          userTrialList,
          treatmentCategories,
          socTreatmentsCategories,
          socTreatments,
          userLocation,
          setShowChatNextScan
        );

        app.intercom.updateUserAttributes({ chat_intent: response.chat_intent });
        setUserChatMode(response.userChatMode);
      }

      if (response.userChatMode + '') {
        if (response.userChatMode !== undefined) {
          setUserChatMode(response.userChatMode);
        }
        setShowThumbs(true);
        switch (response.userChatMode + '') {
          case '0':
            updateChatHistory(setChatHistory, chatHistory, response.msg.text ?? response.msg);
            setUserChatMode('-1');
            break;
          case '2':
            let daynamicContent = null;
            if (missingCritical && response.showChatIntake) {
              console.log(missingCritical);
              daynamicContent = (
                <div className="chat-intake">
                  <div id="chat-intake-warpper">
                    <Intake
                      user={app.user}
                      showOnlyUnanswered
                      flatMode
                      onUpdate={async () => {
                        const doneButton = document.getElementById('remove-button');

                        if (doneButton) {
                          // Check if the button exists
                          doneButton.disabled = true;
                        }

                        await app.updateUser();
                        app.renderPage({ rematch: true });
                        setTimeout(() => {
                          if (doneButton) {
                            // Check again after updates
                            doneButton.disabled = false;
                          }
                        }, 0);
                      }}
                    />
                  </div>
                  <button
                    id="remove-button"
                    className="chat-intake-done-button"
                    onClick={() => handleCheckIntakeDoneOnClick(setChatHistory, chatHistory, socTreatments)}
                  >
                    Done
                  </button>
                </div>
              );
            }

            console.log('(response.msg: ', response.msg);
            setSponsoredTrialsExaplained(response.sponsoredTrialsExaplained ?? false);
            updateChatHistory(
              setChatHistory,
              chatHistory,
              response.msg.text ?? response.msg,
              CHAT_ROLES.JANE,
              daynamicContent
            );
            setShowChatNextScan(false);
            break;
          case '3':
            let showChatNextScan;
            try {
              showChatNextScan = JSON.parse(response.msg);
            } catch (error) {
              showChatNextScan = false;
            }
            if (showChatNextScan.showChatNextScan) {
              updateNextScanHandler(response, setShowChatNextScan, setChatHistory, true);
              setUserChatMode('2');
            } else {
              updateChatHistory(setChatHistory, chatHistory, showChatNextScan.content ?? '');
              setShowChatNextScan(false);
              setUserChatMode('2');
            }
            break;
          case '4':
            scheduleACallHandler(
              JSON.parse(response.msg).content,
              userHaveSponseredTrialList,
              setChatHistory,
              setUserChatMode,
              setShowChatNextScan,
              userChatMode
            );
            break;
          case '6':
            userWantToApplyToTrialHandler(setUserChatMode, setChatHistory, userHaveSponseredTrialList);
            break;
          default:
            break;
        }
      }
    } catch (error) {
      setChatHistory(chatHistory => [
        ...chatHistory,
        {
          role: 'Tina (patient navigator)',
          content: ' Our System is busy. <br> please again resubmit our question',
        },
      ]);
    } finally {
      setLoading(false);
    }
  }
};

export const updateChatScoreUsingThumb = async (userWixId, score, setShowThumbs) => {
  setShowThumbs(false);
  await updateChatScore(userWixId, score);
};

export const setUserIntent = async (
  type,
  setUserChatMode,
  chatHistory,
  setChatHistory,
  numOfSocTreatments,
  numOfTrials,
  setShowChatNextScan,
  missingCritical
) => {
  app.coniguration.data.treatmentType = { type };
  app.coniguration.data.userIntent = { type };
  // app.coniguration.data.userIntent.other = other;
  await app.setConfiguration(app.coniguration.data);
  const $chatPopup = document.querySelector('.chat-popup');

  switch (type) {
    case 'need_treatment':
      updateChatHistory(setChatHistory, chatHistory, 'I need a treatment', CHAT_ROLES.ME);
      updateChatHistory(
        setChatHistory,
        chatHistory,
        numOfSocTreatments === 0
          ? ` Thank you for the update. You have options for ${numOfTrials} clinical trials, \nCould you let us know in what you're interested in?`
          : ` Thank you for the update. You have options for ${numOfTrials} clinical trials and ${numOfSocTreatments} standard treatments.\n Could you let us know if you're interested in clinical trials, standard treatments, or both? Your preference will guide us in providing the right support for you.`,
        CHAT_ROLES.JANE
      );
      break;
    case 'in_treatment':
      updateChatHistory(setChatHistory, chatHistory, " I'm in a a treatment", CHAT_ROLES.ME);
      updateChatHistory(
        setChatHistory,
        chatHistory,
        " We understand you're currently going through your treatment process, and we want to make sure you have all the support you need. To help you prepare for your next oncologist appointment, when is your next scan?"
      );
      setShowChatNextScan(true);
      if ($chatPopup) {
        $chatPopup.scrollTop = $chatPopup.scrollHeight;
      }
      break;
    case 'waiting_for_scan':
      updateChatHistory(setChatHistory, chatHistory, " I'm waiting for a test result", CHAT_ROLES.ME);
      updateChatHistory(
        setChatHistory,
        chatHistory,
        " We understand you're currently waiting to your scan results, and we want to make sure you have all the support you need. If you're comfortable, could you share the date of your next scan with us? We're here to assist you with any decisions you need to make and answer any questions you might have. And remember, if you decide to sign up for the trial in the future, we're here to help with that too."
      );
      setShowChatNextScan(true);
      if ($chatPopup) {
        $chatPopup.scrollTop = $chatPopup.scrollHeight;
      }
      break;
    default:
  }
};
