/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import app from 'new-ui/app';
import { useTranslation } from 'utils/modifiedTranslation';
import './Treatment.css';
import { hcpApi } from 'new-ui/Search/api';
import AiAssistant from 'new-ui/Components/AiAssistent';
import Preloader from '../../Components/Preloader';
import PreferencesWidget from './PreferencesWidget';
import TreatmentOptions from './TreatmentOptions';
import QuotesWidget from './QuotesWidget';
import TreatmentWidget from './TreatmentWidget';
import ProgramsWidget from './ProgramsWidget';
import ExperienceWidget from './ExperienceWidget';
import ExperienceWidgetV2 from './ExperienceWidgetV2';
import EventsWidget from './EventsWidget';
import { getDrugs } from './api';
import { getFinanceCategorties, getFinancePrograms } from '../Finance/api';
import { arrangeCategories } from '../Finance/utils';

export const FEED_GA_CATEGORY = 'FEED';
export const FEED_GA_EVENTS = {
  WIDGET_VIEWED: 'WIDGET_VIEWED',
  WIDGET_INTERACTED: 'WIDGET_INTERACTED'
};

export const FEED_GA = {
  category: 'Feed',
  events: {
    programsViewed: 'Widget Support programs viewed',
    treatmentsViewed: 'Widget treatment options viewed',
    experienceViewed: 'Widget treatment experience viewed',
    surveyViewed: 'Widget treatment survey viewed',
    quotesViewed: 'Widget quotes viewed',
    treatmentViewed: 'Widget treatment viewed',

    programsInteracted: 'Widget Support programs interacted',
    treatmentsInteracted: 'Widget treatment options interacted',
    experienceInteracted: 'Widget treatment experience interacted',
    surveyInteracted: 'Widget treatment survey interacted',
    treatmentInteracted: 'Widget treatment interacted',

    fileUploadClicked: 'feed upload file clicked',
    answerManuallyClicked: 'feed answer manually clicked',
    feedViewed: 'Feed viewed'
  },
};

const FeedPage = ()=> {
  const hasMounted = useRef(false);
  const { t } = useTranslation();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTrials, setIsLoadingTrials] = useState(false);
  const [selectedDrug, setSelectedDrug] = useState(null);
  const [trials, setTrials] = useState(null);
  const [reviewedDrugs, setReviewedDrugs] = useState({});
  const [initialTreatments, setInitialTreatments] = useState([]);

  const showEventsWidget = new URLSearchParams(window.location.search).get('eventsWidget') === '1';

  const WIDGETS = {
    treatmentWidget: 'treatmentWidget',
    experienceWidget: 'experienceWidget',
    experienceWidgetV2: 'experienceWidgetV2',
  };

  const [changesNeed, setChangesNeed] = useState({
    [WIDGETS.treatmentWidget]: 0,
    [WIDGETS.experienceWidget]: 0,
    [WIDGETS.experienceWidgetV2]: 0,
  });

  const [show, setShow] = useState({
    [WIDGETS.treatmentWidget]: true,
    [WIDGETS.experienceWidget]: false,
    [WIDGETS.experienceWidgetV2]: false,
  });

  const triggerUpdateForWidget = (widgetName) => {
    setChangesNeed((prev) => ({
      ...prev,
      [widgetName]: (prev[widgetName] || 0) + 1,
    }));
  };

  const translate = (arr) => {
    return arr.map((el) => {
      return {
        label: t(el.label),
        value: el.value,
      };
    });
  };

  const updateReviewedDrugs = (drugId, reviewDetails) => {
    setReviewedDrugs((prevState) => ({
      ...prevState,
      [drugId]: {
        ...prevState[drugId],
        ...reviewDetails,
      },
    }));
  };

  useEffect(()=> {
    if (Array.isArray(app.user.condition_profile.treatments)) {
      setInitialTreatments(app.user.condition_profile.treatments);
    }
    if (initialTreatments?.length !== app.user.condition_profile.treatments?.length) {
      setSelectedDrug(null);
      triggerUpdateForWidget(WIDGETS.experienceWidget);
      setInitialTreatments(app.user.condition_profile.treatments);
    }
    // eslint-disable-next-line
  },[app.user.condition_profile.treatments]);

  useEffect(()=>{
    setIsLoading(true);
    setIsLoadingTrials(true);
    const fetch = async ()=>{
      const question = app.questions.find((q) => q.id === 'treatments');
      const cycles = question.subQuestions.find((q) => q.id === 'cycles');
      const stopReasons = question.subQuestions.find((q) => q.id === 'treatment_stop_reason');

      const data = await getDrugs();
      data.hasTreatment = !!app.user.condition_profile.treatments?.length;
      data.treatments = app.user.condition_profile.treatments || [];

      data.condition = app.user.personal.condition;
      data.topSelectedDrugs = data.topSelectedOptionsByDiseases[app.user.personal.condition];
      // data['topSelectedDrugs'] = data.topSelectedOptionsByDiseases['breast_2'];

      data.totalDrugs = data.groupedOptionsByDiseases[app.user.personal.condition]?.map((item) => item.options).flat().map((el) => {
        return {
          title: t(el.label),
          value: el.value,
        };
      });
      if (['rcc_kidney', 'mesothelioma', 'general'].includes(data.condition)) {
        data.totalDrugs = question.subQuestions.find((q) => q.id === 'drugs_received').options.map((el) => {
          return {
            title: t(el.label),
            value: el.value,
          };
        });
      }
      data.cycles = translate(cycles.options);
      data.stopReasons = translate(stopReasons.options).filter((el) => el.value !== 'treatment_ongoing');

      const categories = await getFinanceCategorties();
      data.financeCategories = arrangeCategories(categories, [], []);

      if(localStorage.getItem('finance_params')) {
        data.financePrograms = await hcpApi.financeMatch(JSON.parse(localStorage.getItem('finance_params')));
      }else {
        data.financePrograms = await getFinancePrograms();
      }

      console.log('FEED DATA ', data);

      if (data.hasTreatment) {
        toggleVisibility(WIDGETS.experienceWidget, true);
      }
      setData(data);
      setIsLoading(false);
    };
    fetch();

    app.getTrials().then((res) => {
      setTrials(res?.result);
      setIsLoadingTrials(false);

      app.sendGoogleAnalyticsEvent(FEED_GA.category, FEED_GA.events.feedViewed, {
        state: app.treatments?.length ? 'has treatment' : 'no treatment'
      })
    });
    
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true;
      return; // Skip the effect on the initial render
    }

    if (!app.user.condition_profile.treatments?.length) {
      toggleVisibility(WIDGETS.experienceWidgetV2, false);
      toggleVisibility(WIDGETS.experienceWidget, false);
      toggleVisibility(WIDGETS.treatmentWidget, true);
    }
    // triggerUpdateForWidget(WIDGETS.experienceWidget);
    // eslint-disable-next-line
  }, [app.treatments]);

  const treatmentSelectHandler = (widgetName) => {
    if (widgetName === WIDGETS.experienceWidgetV2) {
      setSelectedDrug(null);
      toggleVisibility(WIDGETS.experienceWidgetV2, true);
      toggleVisibility(WIDGETS.experienceWidget, false);
      toggleVisibility(WIDGETS.treatmentWidget, false);
    }
  };

  const welcome = () => {
    return `${t('feed.title.main')}${app.user.info.family_name ? `, ${app.user.info.family_name}!` : ''}`;
  };

  const onClickAddExperience = () => {
    toggleVisibility(WIDGETS.experienceWidgetV2, true);
    toggleVisibility(WIDGETS.experienceWidget, false);
    toggleVisibility(WIDGETS.treatmentWidget, false);
  };

  const onTreatmentAdd = () => {
    triggerUpdateForWidget(WIDGETS.experienceWidget);
    toggleVisibility(WIDGETS.experienceWidget, true);
    toggleVisibility(WIDGETS.treatmentWidget, false);
  };

  const onTreatmentCreate = () => {
    // triggerUpdateForWidget(WIDGETS.experienceWidget);
  };

  const onClickAddMyExperance = () => {
    toggleVisibility(WIDGETS.experienceWidget, false);
    toggleVisibility(WIDGETS.experienceWidgetV2, true);
  };

  const onAddExperance = (currentDrug) => {
    updateReviewedDrugs(currentDrug.name, currentDrug);
    toggleVisibility(WIDGETS.experienceWidget, true);
    toggleVisibility(WIDGETS.experienceWidgetV2, false);
  };

  const toggleVisibility = (key, value) => {
    setShow((prevShow) => ({
      ...prevShow,
      [key]: value,
    }));
  };

  const onChangeSelectedDrug = (drug) => {
    setSelectedDrug(drug);
  };

  const onClickPreviousTreatment = () => {
    toggleVisibility(WIDGETS.treatmentWidget, true);
    toggleVisibility(WIDGETS.experienceWidgetV2, false);
  };

  return (
    <div className="feed-page">
      <h2 className="welcome welcome-mobile">{welcome()}</h2>
      <div className="feed-page-container">
        <h2 className="welcome">{welcome()}</h2>
        {(isLoading || isLoadingTrials) ? <Preloader isVisible /> : (
          <>
            <div className="section"><span>{t('feed.sections.treatment.title')}</span></div>

            {showEventsWidget && <EventsWidget />}
            
            {data && trials && (
              <PreferencesWidget
                treatments={app.treatments?.length ? app.treatments : []}
                trials={app.trials.length ? app.trials : trials}
              />
            )}
            <br />
            {data && trials && (
              <TreatmentOptions
                // treatments={app.user.condition_profile.treatments?.length ? app.user.condition_profile.treatments : []}
                treatments={app.treatments?.length ? app.treatments : []}
                trials={app.trials.length ? app.trials : trials}
              />
            )}
            <br />
            {data && show[WIDGETS.treatmentWidget] && (
              <TreatmentWidget
                onTreatmentAdd={onTreatmentAdd}
                onTreatmentCreate={onTreatmentCreate}
                onClickAddExperience={onClickAddExperience}
                data={data}
                onTreatmentSelect={treatmentSelectHandler}
              />
            )}
            <br />
            {show[WIDGETS.experienceWidgetV2] && (
              <ExperienceWidgetV2
                onClickPreviousTreatment={onClickPreviousTreatment}
                selectedDrug={selectedDrug}
                onAddExperance={onAddExperance}
                onChangeSelectedDrug={onChangeSelectedDrug}
              />
            )}
            <br />
            {data && (show[WIDGETS.experienceWidget]) && (
              <ExperienceWidget
                reviewedDrugs={reviewedDrugs}
                changesNeed={changesNeed}
                selectedDrug={selectedDrug}
                onClickAddMyExperance={onClickAddMyExperance}
                onChangeSelectedDrug={onChangeSelectedDrug}
              />
            )}
            <br />
            <div className="section"><span>{t('feed.sections.programs.title')}</span></div>
            {data && (
              <ProgramsWidget
                categories={data.financeCategories}
                programs={data.financePrograms}
              />
            )}
            <br />
            {data && <QuotesWidget /> }
          </>
        )}
      </div>
      <div className="feed-sidebar">
        <AiAssistant
          onUploadClick={()=>{
            app.sendGoogleAnalyticsEvent(FEED_GA.category, FEED_GA.events.fileUploadClicked);
          }}
          onEditClick={()=>{
            app.sendGoogleAnalyticsEvent(FEED_GA.category, FEED_GA.events.answerManuallyClicked);
          }}
        />
      </div>
    </div>
  );
};

export default FeedPage;
