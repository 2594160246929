import React from 'react';
import './style.css';

const LimitedItemList = ({ items }) => {
  return (
    <div className="limited-list">
      {items.slice(0, 2).map((item, index) => (
        <div className="item" key={index}>
          {item.label || item}
        </div>
      ))}
      {items.length > 2 && (
        <div className="item">...</div>
      )}
    </div>
  );
};

export default LimitedItemList;