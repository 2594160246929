import React from 'react';
import './MissingCritical.css';

import app from 'new-ui/app';

// import rightArrowIcon from 'new-ui/assets/icons/right.svg';

import PropTypes from 'prop-types';

import { useTranslation } from 'utils/modifiedTranslation';

import { POPUP_TYPES } from 'new-ui/constants';
import { getItemType } from 'new-ui/functions';

const MissingCriticalQuestions = (props) => {
  const {
    item,
    data = {},
  } = props;
  const { t } = useTranslation();
  const hasMissingCritical = !!(item.missed_questions && item?.missed_questions?.length);

  if (!data.missingCriticalFlags) {
    data.missingCriticalFlags = {
      warning: false,
      good: false,
    };
  }

  data.missingCriticalFlags[hasMissingCritical ? 'warning' : 'good'] = true;

  const onMissingQuestionClick = () => {
    if (!hasMissingCritical) {
      if (item.isSponsored) {
        app.setPopup(POPUP_TYPES.CONSULT_WITH_US);
        return;
      }
      app.setPopup(POPUP_TYPES.SHARE_WITH_DOCTOR, { type: getItemType(item) });
      return;
    }
    app.setPopup(POPUP_TYPES.MISSING_CRITICAL_INFORMATION, {
      trial: item,
    });
  };

  return (
    <div
      role="none"
      title={t(`trial.${hasMissingCritical ? 'missing_critical_information' : `${!item.isSponsored ? 'speak_with_us' : 'speak_with_your_oncologist'}`}`)}
      className={`missing-questions missing-questions-${hasMissingCritical ? 'pending' : 'ready'}`}
      onClick={onMissingQuestionClick}
    >
      <div className="missing-questions-text">
        {/* <div className="missing-questions-text-title">{t(`trial.${hasMissingCritical ? 'pending' : 'ready'}`)}</div> */}
        <div className="missing-questions-text-subtitle">
          {t(`trial.${hasMissingCritical ? 'missing_critical_information' : `${!item.isSponsored ? 'speak_with_us' : 'speak_with_your_oncologist'}`}`)}
        </div>
      </div>
      {/* <div className="missing-questions-arrow">
        <img alt="right arrow" src={rightArrowIcon} />
      </div> */}
    </div>
  );
};

MissingCriticalQuestions.propTypes = {
  item: PropTypes.object,
  data: PropTypes.object,
};

export default MissingCriticalQuestions;
