import React, { useRef } from 'react';
import 'new-ui/Components/ItemBox/ItemBox.css';

import { useHistory } from 'react-router-dom';
import IconLine from 'new-ui/Components/IconLine';
import { ItemButton, ItemHeader, ItemTags } from 'new-ui/Components/ItemBox/ItemBox';
import PropTypes from 'prop-types';
import app from 'new-ui/app';

import { useTranslation } from 'utils/modifiedTranslation';

import { ROUTES, PAGES } from 'new-ui/constants';
import { statesCodes } from 'utils/constants/statesCodes';

import { getCyclesText } from './functions';

const TRIAL_URL = ROUTES[PAGES.TRIAL];

const PhaseRange = ({ from, to }) => {
  const { t } = useTranslation();
  return (
    <div className="phase-range">
      <IconLine inline icon={`phase_${from}`} />
      <IconLine inline icon="dots" />
      <IconLine inline icon={`phase_${to}`} title={t('trial.item.phase')} />
    </div>
  );
};

const Phases = ({ phases }) => {
  const { t } = useTranslation();
  if (!phases.length) return null;
  const [from] = phases;
  if (phases.length > 1) {
    const [to] = phases.reverse();
    return <PhaseRange from={from} to={to} />;
  }
  return <IconLine inline icon={`phase_${from}`} title={t('trial.item.phase')} />;
};

const TrialItem = (props) => {
  const {
    isFull,
    listItem,
    item,
    number = 0,
    popupItem,
    title,
    action,
    data,
    isSearchPage,
  } = props;

  const { t } = useTranslation();
  const history = useHistory();
  const $item = useRef(null);

  const navigate = (route) => {
    history.push(route);
  };

  const gotoTrial = () => {
    debugger;
    if (isFull) return;
    if (action) action(item);
    else navigate(`${TRIAL_URL}/${item.nct_number}`);
  };

  const getFacilityLabel = (facility, prefix = '') => {
    if (!facility || !facility.facility_name) {
      return null;
    }
    const {
      facility_name, facility_city, facility_state, facility_country,
    } = facility;
    return (
      prefix + [facility_name, facility_city, statesCodes[facility_state], facility_country].filter((i) => i).join(', ')
    );
  };

  const closest = getFacilityLabel(item.closest_facility);
  const promoted = getFacilityLabel(item.promoted_facility, 'This study also takes place in ');

  let cycleText = '';
  try {
    cycleText = getCyclesText(item, t);
  } catch (e) {
    cycleText = '';
  }

  return (
    <div className={`item-box-container ${app.user ? '' : 'public-item'} ${number === 0 ? 'collapsed' : ''} ${item.isSponsored ? 'sponsored' : ''}`} ref={$item}>
      {isFull ? <h2>{t('trial.title')}</h2> : null}
      <div className={`item-box ${isFull ? 'full-item' : 'list-item'} ${popupItem ? 'popup-item' : ''}`}>
        <ItemTags
          displayTags={false}
          item={item}
          number={isFull ? null : number}
          data={data}
          isFull={isFull}
          isSearchPage={isSearchPage}
        />
        <ItemHeader
          item={item}
          isFull={isFull}
          action={gotoTrial}
          title={title}
          isSearchPage={isSearchPage}
        />
        <div className="item-list">
          <Phases phases={item?._phases?.filter((i) => i) || []} />
          {item.treatment_administration_type?.length ? (
            <IconLine inline icon="dot" title={`${item.treatment_administration_type.join(', ')}`} />
          ) : null}
          {cycleText ? <IconLine inline icon="clock" title={cycleText} /> : null}
          <div>
            {closest && item.closest_facility?.facility_id ? <IconLine inline icon="maps" title={closest} /> : null}
          </div>
          {/* {item.groups?.length ? <IconLine inline icon="what" title={`${item.groups?.join(', ')}`} /> : null} */}
          {isFull ? (
            <div>
              {item.main_objectives?.length ? (
                <IconLine inline icon="type" title={`${item.main_objectives.join(', ')}`} />
              ) : null}
              <div>
                {promoted && item.promoted_facility?.facility_id ? <IconLine inline icon="map" title={promoted} /> : null}
              </div>
            </div>
          ) : null}
          {/* {item.nearest_facility_distance ? (
            <IconLine
              icon="location"
              title={`${Math.round(item.nearest_facility_distance)} ${t('trial.miles_from_you')}`}
            />
          ) : null} */}
        </div>
        <ItemButton
          popupItem={popupItem}
          listItem={listItem}
          action={gotoTrial}
          item={item}
          $item={$item}
        />
      </div>
    </div>
  );
};

TrialItem.propTypes = {
  isFull: PropTypes.bool,
  listItem: PropTypes.bool,
  item: PropTypes.object,
  number: PropTypes.number,
  popupItem: PropTypes.bool,
  title: PropTypes.string,
  action: PropTypes.func,
  data: PropTypes.object,
  isSearchPage: PropTypes.bool,
};

export default TrialItem;
