import React, { useState, useEffect, useCallback } from 'react';
import "./style.css";

const LabeledInput = ({ label, required, validate, placeholder, onChange, submitted }) => {
  const [value, setValue] = useState('');
  const [error, setError] = useState('');

  const validateInput = useCallback((inputValue) => {
    if (required && !inputValue) {
      setError(`${label} is required`);
    } else if (validate && !validate(inputValue)) {
      setError(`Invalid ${label}`);
    } else {
      setError('');
    }
  }, [label, required, validate]);

  useEffect(() => {
    if (submitted) {
      validateInput(value);
    }
  }, [submitted, validateInput, value]);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    onChange(newValue);
    validateInput(newValue);
  };

  return (
    <div className="labeled-input">
      <label>
        {label}
        {required && <span className="required">*</span>}
      </label>
      <input
        type="text"
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        className={error ? 'input-error' : ''}
      />
      {error && <span className="error-message">{error}</span>}
    </div>
  );
};

export default LabeledInput;
